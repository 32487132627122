import { Link, SpaceBetween, TextContent } from '@amzn/awsui-components-react';

import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

const AdminManageTemplatesHelpPanel = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;

    return (
        <SpaceBetween size='s'>
            <TextContent>{appLabels.manage_templates.table.help_panel.content}</TextContent>
            <Link href={appLabels.manage_templates.requester_guide_link} target='_blank' rel='noopener noreferrer' external={true}>
                {appLabels.manage_templates.table.help_panel.link_text}
            </Link>
        </SpaceBetween>
    );
};

export default withAppLabelsContext(AdminManageTemplatesHelpPanel);
