import './AssessmentDetails.scss';

import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Box } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { PromptsFilter, createOrUpdateCurrentPrompt, loadPreEventInfoSuccessful } from './CurrentAssessmentSlice';
import AssessmentDetailsBody from './body/AssessmentDetailsBody';
import AssessmentDetailsFooter from './footer/AssessmentDetailsFooter';
import AssessmentDetailsHeader from './header/AssessmentDetailsHeader';
import { QuestionAnswer, QuestionnaireAndAnswers, getPreEventAnswers } from '../../../api/data-collection/DataCollectionClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import { jumpToModule, jumpToPrompt, setTemplateLocale } from '../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import TemplateConstants from '../../administration/manage-templates/edit-template/TemplateConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createAnswerMapFromQuestionnaireAnswers } from '../pre-event/util';

/** This component can be reached by either the assessment view or the template view */
interface MatchParam {
    assessmentId?: string;
    snapshotId?: string;
    templateId?: string;
}

type AssessmentDetailsProps = AuthContextInterface & RouteComponentProps<MatchParam> & AppLabelsContextInterface & FlashContextInterface;

const AssessmentDetails: FunctionComponent<AssessmentDetailsProps> = ({ appLabels, match, flash }): JSX.Element => {
    /** Display the template and editing capabilities if this is true */
    const shouldDisplayTemplate = !!match.params.templateId;
    /**  Display live session only in assessment mode */
    const shouldDisplayLiveSession = !shouldDisplayTemplate;

    const dispatch = useAppDispatch();
    const location = useLocation();

    // Template state
    const currentTemplateModuleId: string = useAppSelector(
        (state) => state.currentTemplateState.currentPromptState.currentPrompt?.containingModuleId
    );
    const currentTemplatePromptId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt?.promptId);
    const templateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);

    const promptsFilter = useAppSelector((state) => state.currentAssessmentState.currentPromptState.promptsFilter);
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const currentPromptId = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt?.id);
    let preEventEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.userQuestionnaires?.preEvent?.enabled
    );
    if (selectedSnapshotId) {
        preEventEnabled = false;
    }
    const preEventInfo = useAppSelector((state) => state.currentAssessmentState.preEventInfo);
    const [currentFilter, setCurrentFilter] = useState<PromptsFilter>(promptsFilter);
    const [preEventAnswers, setPreEventAnswers] = useState<Map<string, QuestionAnswer> | undefined>(undefined);
    const [currentAssessmentId, setCurrentAssessmentId] = useState<string | undefined>(undefined);
    const [currentSnapshotId, setCurrentSnapshotId] = useState<string | undefined>(undefined);
    const [loadingPreEventInfo, setLoadingPreEventInfo] = useState<boolean>(false);

    const reloadPreEventAnswers = useCallback(async () => {
        if (preEventEnabled && !loadingPreEventInfo) {
            try {
                setLoadingPreEventInfo(true);
                const questionnaireWithAnswers: QuestionnaireAndAnswers = await getPreEventAnswers(currentAssessmentId);
                if (questionnaireWithAnswers) {
                    const answerMap = createAnswerMapFromQuestionnaireAnswers(questionnaireWithAnswers);
                    setPreEventAnswers(answerMap);
                    dispatch(loadPreEventInfoSuccessful(questionnaireWithAnswers));
                }
            } catch (err) {
                rumClient.recordError(err);
                const errorMsg = `Error reloading pre-event responses: ${err}`;
                flash.alert(FlashType.error, appLabels.assessment.pre_event.error_loading, errorMsg);
            }
            setLoadingPreEventInfo(false);
        }
    }, [preEventEnabled, loadingPreEventInfo, currentAssessmentId, dispatch, flash, appLabels.assessment.pre_event.error_loading]);

    if (preEventInfo && !preEventAnswers) {
        const answerMap = createAnswerMapFromQuestionnaireAnswers(preEventInfo);
        setPreEventAnswers(answerMap);
    }

    const updateAssessmentView = useCallback(() => {
        const assessmentId = match.params.assessmentId;
        if (assessmentId && assessmentId !== currentAssessmentId) {
            setCurrentAssessmentId(assessmentId);
        }
        const snapshotId = match.params.snapshotId;
        if (snapshotId && snapshotId !== currentSnapshotId) {
            setCurrentSnapshotId(snapshotId);
        }
        const urlParams = new URLSearchParams(window.location.search);
        const promptCategory = urlParams.get('promptCategory');

        if (promptCategory) {
            // Reached here via explicit promptCategory in url (e.g. from side-nav)
            dispatch(createOrUpdateCurrentPrompt(promptCategory));
        } else {
            const promptId = urlParams.get('promptId');
            if (promptId !== currentPromptId || currentFilter !== promptsFilter) {
                // Reached here via explicit promptId in url
                dispatch(createOrUpdateCurrentPrompt(promptId));
                setCurrentFilter(promptsFilter);
            }
        }
    }, [dispatch, currentPromptId, promptsFilter, currentFilter, match, currentAssessmentId, currentSnapshotId]);

    /**
     * Updates the locale/view/module displayed based on the URL params
     * Reacts to any clicks from the side nav
     */
    useEffect(() => {
        if (!shouldDisplayTemplate) {
            return;
        }

        // When URL changes, jump to the requested module/prompt
        const urlParams = new URLSearchParams(location.search);
        const routeLocale = urlParams.get(TemplateConstants.SEARCH_PARAM_LOCALE);
        const routeViewId = urlParams.get(TemplateConstants.SEARCH_PARAM_VIEW_ID);
        const routeModuleId = urlParams.get(TemplateConstants.SEARCH_PARAM_MODULE_ID);
        const routePromptId = urlParams.get(TemplateConstants.SEARCH_PARAM_PROMPT_ID);

        const didModuleChange: boolean = routeModuleId !== currentTemplateModuleId;
        const didPromptChange: boolean = routePromptId !== currentTemplatePromptId;
        const didLocaleChange: boolean = routeLocale !== templateLocale;

        // Jump to the module from the URL, if specified (applies to links from side nav)
        if (didModuleChange && routeViewId && routeModuleId) {
            dispatch(jumpToModule({ viewId: routeViewId, moduleId: routeModuleId }));
        }
        // Jump to the prompt from the URL
        if (didPromptChange && routeViewId && routePromptId) {
            dispatch(jumpToPrompt({ viewId: routeViewId, promptId: routePromptId }));
        }
        // Set the language in which the template should be displayed
        if (didLocaleChange) {
            dispatch(setTemplateLocale(routeLocale));
        }

        // Intentionally not watching `currentTemplatePromptId`, etc. We only want to adapt to changes from the URL
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shouldDisplayTemplate, location.search]);

    useEffect(() => {
        if (!shouldDisplayTemplate) {
            updateAssessmentView();
        }
    }, [shouldDisplayTemplate, dispatch, updateAssessmentView]);

    return (
        <Box className='awscat-assessment-details'>
            <AssessmentDetailsHeader
                showLiveSession={shouldDisplayLiveSession}
                readOnly={false}
                showSnapshots={false}
                shouldDisplayTemplate={shouldDisplayTemplate}
            />
            <AssessmentDetailsBody
                currentAssessmentId={currentAssessmentId}
                preEventAnswers={preEventAnswers}
                shouldDisplayTemplate={shouldDisplayTemplate}
            />
            <AssessmentDetailsFooter
                onRefreshClick={reloadPreEventAnswers}
                refreshLoading={loadingPreEventInfo}
                shouldDisplayTemplate={shouldDisplayTemplate}
            />
        </Box>
    );
};

export default withAppLabelsContext(withAuthContext(withFlashContext(AssessmentDetails)));
