import { SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import AssessmentCreatedBy from './AssessmentCreatedBy';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import AssessmentActions, { Action } from '../AssessmentActions';
import AssessmentStatusBar from '../AssessmentStatusBar';
import { AssessmentViewModel } from '../AssessmentViewModel';

type AssessmentCardBodyProps = AppLabelsContextInterface & {
    assessment: AssessmentViewModel;
    assessmentActionDispatch: React.Dispatch<Action>;
};

const AssessmentCardBody: FunctionComponent<AssessmentCardBodyProps> = ({ appLabels, assessment, assessmentActionDispatch }): JSX.Element => {
    const { type, version, targetSegmentId, status, createdBy, lastUpdated, workshopDate, readoutDate, percentComplete } = assessment;

    const displayTypeDetails = () => {
        return `${type} ${version} ${targetSegmentId || ''}`;
    };

    const displayLastUpdated = () => {
        return lastUpdated;
    };

    const displayWorkshopDate = () => {
        return `${appLabels.assessment.list.workshop_date}: ${workshopDate}`;
    };

    const displayReadoutDate = () => {
        return `${appLabels.assessment.list.readout_date}: ${readoutDate}`;
    };

    return (
        <div className='awscat-assessment-card-body'>
            <SpaceBetween direction='vertical' size='xs'>
                <div className='awscat-assessment-card-type'>{displayTypeDetails()}</div>
                <AssessmentCreatedBy createdBy={createdBy} />
                <div className='awscat-assessment-card-last-updated'>{displayLastUpdated()}</div>
                <div className='awscat-assessment-card-workshop-date'>{displayWorkshopDate()}</div>
                <div className='awscat-assessment-card-readyout-date'>{displayReadoutDate()}</div>
                <AssessmentStatusBar status={status} statusBarPercentComplete={percentComplete} />
                <AssessmentActions
                    assessment={assessment}
                    assessmentActionDispatch={assessmentActionDispatch}
                    actionsFloat={'right'}
                ></AssessmentActions>
            </SpaceBetween>
        </div>
    );
};

export default withAppLabelsContext(AssessmentCardBody);
