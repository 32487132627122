import { AssessmentDescriptor, UpdateDescriptorInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Textarea } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import templateManagementClient, { EditableDescriptorKey } from '../../api/templateManagement/TemplateManagementClient';
import { UPDATE_DESCRIPTOR } from '../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import rumClient from '../../common/monitoring/RumClient';
import {
    addInProgressRequest,
    removeInProgressRequest,
    updateDescriptor as updateDescriptorAction,
} from '../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { getEditableDescriptor } from '../administration/manage-templates/edit-template/TemplateUtils';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

/**
 * @param fieldName - the name of the field being edited (if set, adds a label)
 * @param adjacentIcon - an icon to put next to the fieldName label
 * @param descriptor - the descriptor being edited
 * @param keyToEdit - which part of the descriptor is being edited
 * @param numRows - how tall the editable text box should be
 * @param doesFieldSupportHtml - if true, shows a label that indicates that any HTML will be rendered
 * @param alwaysEditable - if true, the field is always editable, regardless of the edit toggle
 * @param disabled - if true, the textarea will be disabled when it's shown
 */
type TemplateEditableTextProps = AppLabelsContextInterface & {
    fieldName?: string;
    adjacentIcon?: ReactNode;
    descriptor: AssessmentDescriptor;
    keyToEdit: EditableDescriptorKey;
    numRows?: number;
    doesFieldSupportHtml?: boolean;
    alwaysEditable?: boolean;
    disabled?: boolean;
};

/**
 * Depending on whether the user is currently editing the template, this component will either render the children
 * or a field in which the text can be edited
 *
 * All editable text uses this component, but selections (single/multi select prompts) have a special wrapper around this componenent
 */
const TemplateEditableText: FunctionComponent<TemplateEditableTextProps> = ({
    appLabels,
    fieldName,
    adjacentIcon,
    descriptor,
    keyToEdit,
    numRows = 5,
    doesFieldSupportHtml,
    alwaysEditable,
    disabled,
    children,
}): JSX.Element => {
    const dispatch = useAppDispatch();

    const currentlyEditingTemplate = useAppSelector((state) => state.currentTemplateState.currentlyEditing);

    // Get the editable descriptor from the store
    const currentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const editableDescriptorsObject = useAppSelector((state) => state.currentTemplateState.descriptorIdLocaleToEditableDescriptorObject);
    const [descriptorBeingEdited, setDescriptorBeingEdited] = useState<AssessmentDescriptor>(
        getEditableDescriptor(currentTemplateState, descriptor?.descriptorId)
    );

    const [currentText, setCurrentText] = useState<string>(descriptorBeingEdited?.[keyToEdit]);
    const [lastSavedText, setLastSavedText] = useState<string>(currentText);

    const [updateDescriptorMutation] = useMutation(UPDATE_DESCRIPTOR, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (_, options) => {
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    const updateDescriptor = useCallback(
        (newValue: string) => {
            const updateDescriptorInput: UpdateDescriptorInput = {
                descriptorId: descriptorBeingEdited.descriptorId,
                locale: descriptorBeingEdited.locale,
                [keyToEdit]: newValue,
            };
            dispatch(updateDescriptorAction({ input: updateDescriptorInput, keyToUpdate: keyToEdit }));

            // Make the update request to the backend
            const requestId = uuid();
            updateDescriptorMutation({ variables: { input: updateDescriptorInput }, context: { requestId } });
            dispatch(addInProgressRequest(requestId));
            setLastSavedText(newValue);
        },
        [descriptorBeingEdited, dispatch, keyToEdit, updateDescriptorMutation]
    );

    // When the provided descriptor changes (e.g. by going to the next prompt or changing locale), reset currentText
    useEffect(() => {
        const didDescriptorChange = descriptor?.descriptorId !== descriptorBeingEdited?.descriptorId;
        const didLocaleChange = descriptor?.locale !== descriptorBeingEdited?.locale;
        if (didDescriptorChange || didLocaleChange) {
            // If the text is different, save it
            if (currentText !== lastSavedText) {
                updateDescriptor(currentText);
            }

            // Switch the local state to using the new descriptor
            const newDescriptor = editableDescriptorsObject[`${descriptor.descriptorId}${descriptor.locale}`];
            setDescriptorBeingEdited(newDescriptor);
            setCurrentText(newDescriptor?.[keyToEdit]);
            setLastSavedText(newDescriptor?.[keyToEdit]);
        }
    }, [
        descriptor,
        descriptorBeingEdited,
        setDescriptorBeingEdited,
        setCurrentText,
        currentText,
        editableDescriptorsObject,
        keyToEdit,
        lastSavedText,
        updateDescriptor,
    ]);

    // When the user leaves the page, save the text if it's different from the last saved text
    const onUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (currentText !== lastSavedText) {
                updateDescriptor(currentText);
                event.preventDefault();
                event.returnValue = '';
            }
        },
        [currentText, lastSavedText, updateDescriptor]
    );
    useEffect(() => {
        // These listeners will be added/removed whenever the text changes. Because this will be happening for only
        // 1 field at a time, it is tolerable performance-wise
        window.addEventListener('beforeunload', onUnload);
        return () => window.removeEventListener('beforeunload', onUnload);
    }, [onUnload]);

    if (alwaysEditable || (currentlyEditingTemplate && descriptorBeingEdited)) {
        return (
            <>
                {fieldName && (
                    <label>
                        {fieldName}
                        {adjacentIcon}
                    </label>
                )}
                <Textarea
                    disabled={disabled}
                    value={currentText}
                    onChange={({ detail }) => {
                        setCurrentText(detail.value);
                    }}
                    onBlur={() => updateDescriptor(currentText)}
                    spellcheck
                    autoComplete
                    rows={numRows}
                />
                {doesFieldSupportHtml && (
                    <div>
                        <label>{appLabels.manage_templates.editable_fields.supports_html}</label>
                    </div>
                )}
            </>
        );
    } else {
        // If not editing, just display the child components as-is
        return <>{children}</>;
    }
};

export default withAppLabelsContext(TemplateEditableText);
