/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';
import { IntlProvider } from 'react-intl';

import AppWithContexts from './AppWithContexts';
import Constants from './common/Constants';
import {
    LOCALIZED_APP_LABELS,
    SUPPORTED_LANGUAGES,
    isLocaleSupported,
    supportedLanguageToLocale,
    supportedLocaleToLanguage,
} from './common/Localization';
import { LocalizationContext } from './components/localization/LocalizationContext';

const IntlAppWithContexts = (): JSX.Element => {
    // Try reading the locale persisted from the last session. This is set in the language dropdown component
    const languageFromLocalStorage: string | undefined = localStorage.getItem(Constants.LOCAL_STORAGE_LANGUAGE_KEY);

    // Select the initial locale/language, depending on local storage, browser, and default settings
    const localeFromBrowser: string = (navigator.languages && navigator.languages[0]) || navigator.language;
    // The preferredLocale is set by the user, either through the browser or through the dropdown
    const preferredLocale: string =
        (languageFromLocalStorage && supportedLanguageToLocale(languageFromLocalStorage)) || // 1. The locale from the last session
        localeFromBrowser; // 2. The locale from the browser
    // supportedLocale is either preferredLocale if A2T supports it, or English
    const supportedLocale: string = isLocaleSupported(preferredLocale) ? preferredLocale : Constants.DEFAULT_LOCALE;
    const initialLanguage: string = supportedLocaleToLanguage(supportedLocale);

    const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
    const [locale, setLocale] = useState(supportedLocale);
    const [messages, setMessages] = useState(LOCALIZED_APP_LABELS[locale]);

    const setLanguage = useCallback((lang: string) => {
        const locale = supportedLanguageToLocale(lang);
        setLocale(locale);
        setMessages(LOCALIZED_APP_LABELS[locale]);
        setSelectedLanguage(lang);
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <LocalizationContext.Provider value={{ selectedLanguage, allLanguages: SUPPORTED_LANGUAGES, locale, setLanguage }}>
                <AppWithContexts />
            </LocalizationContext.Provider>
        </IntlProvider>
    );
};

export default IntlAppWithContexts;
