import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Button } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';

import { GET_SESSION_INFO, LIST_MY_SESSIONS, POST_SHARED_DOC_TO_SESSION, UPDATE_SESSION_STATE, isWbsSessionActive } from '../../../api/wbs/WbsClient';
import { generateSolicit } from '../../../api/wbs/WbsSolicitGenerator';
import { SessionState } from '../../../api/wbs/WbsTypes';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { assessmentIsReadOnly } from '../Utils';
import { updateLiveSessionSuccessful } from '../facilitate/CurrentAssessmentSlice';

type LiveSessionSuspendResumeProps = AppLabelsContextInterface & FlashContextInterface & AuthContextInterface;

const LiveSessionSuspendResume: FunctionComponent<LiveSessionSuspendResumeProps> = ({ appLabels, flash, auth }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const selectedLiveSessionId = useAppSelector((state) => state.currentAssessmentState.selectedLiveSessionId);
    const currentLiveSessionInfo = useAppSelector((state) => state.currentAssessmentState.currentLiveSessionInfo);
    const selectedLiveSessionIsActive = currentLiveSessionInfo ? isWbsSessionActive(currentLiveSessionInfo) : null;
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const promptConfig = currentAssessment?.template?.defaults?.questionnaireAnswers;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const myUserId = auth?.getUserInfo()?.userId;
    const isReadOnly = assessmentIsReadOnly(myUserId, currentAssessment, assessmentId);

    const [updateSessionState, updateSessionStateApiResult] = useMutation(UPDATE_SESSION_STATE, {
        refetchQueries: [LIST_MY_SESSIONS, GET_SESSION_INFO],
        onCompleted: (data) => {
            const updatedState = data.updateSessionState.state;
            dispatch(updateLiveSessionSuccessful(updatedState));
        },
    });

    const [postSharedDocToSession] = useMutation(POST_SHARED_DOC_TO_SESSION, {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted: (data) => {
            updateSessionState({
                variables: {
                    input: {
                        sessionId: selectedLiveSessionId,
                        state: SessionState.SUSPENDED,
                    },
                },
            });
        },
        onError: (error) => {
            updateSessionState({
                variables: {
                    input: {
                        sessionId: selectedLiveSessionId,
                        state: SessionState.SUSPENDED,
                    },
                },
            });

            // Unable to clear question for live session.
            // Simply log error and proceed to minimize live session interruptions.
            rumClient.recordError(
                `postSharedDocToSession() Error: unable to clear sharedDoc for selectedLiveSessionId=${selectedLiveSessionId}, error=${error}`
            );
        },
    });

    const [errorAlerted, setErrorAlerted] = useState<boolean>(false);

    const updateSessionStateTo = selectedLiveSessionIsActive ? SessionState.SUSPENDED : SessionState.ACTIVE;

    const onClick = () => {
        if (selectedLiveSessionIsActive && updateSessionStateTo === SessionState.SUSPENDED) {
            // best effort clear shared doc before suspending session.
            const emptyDoc = JSON.stringify(generateSolicit(null, promptConfig));
            postSharedDocToSession({
                variables: {
                    input: {
                        sessionId: selectedLiveSessionId,
                        sharedDoc: emptyDoc,
                    },
                },
            });
        } else {
            updateSessionState({
                variables: {
                    input: {
                        sessionId: selectedLiveSessionId,
                        state: updateSessionStateTo,
                    },
                },
            });
        }
    };

    useEffect(() => {
        if (!updateSessionStateApiResult.error) {
            setErrorAlerted(false);
            return;
        }

        if (!errorAlerted) {
            // alert once on api error
            flash.alert(FlashType.error, appLabels.assessment.live_session.error_api, `${updateSessionStateApiResult.error.message}`);
            setErrorAlerted(true);
        }
    }, [dispatch, appLabels, updateSessionStateApiResult.error, errorAlerted, flash]);

    if (!selectedLiveSessionId || isReadOnly) {
        return null;
    }

    return (
        <div className='awscat-assessment-update-session-state'>
            <Button loading={updateSessionStateApiResult.loading} onClick={onClick}>
                {selectedLiveSessionIsActive ? appLabels.assessment.live_session.suspend_session : appLabels.assessment.live_session.resume_session}
            </Button>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(withFlashContext(LiveSessionSuspendResume)));
