import '../reviewall/ResultsReviewAll.scss';

import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, Grid, Header, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import CreateSnapshotDialog from './CreateSnapshotDialog';
import ManageSnapshotsHelpPanel from './ManageSnapshotsHelpPanel';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import AssessmentPermissionManager from '../../../../common/auth/AssessmentPermissionManager';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { generateAssessmentResultsReviewAllUrl, generateAssessmentSnapshotReviewAllUrl } from '../../Utils';
import { updateCreateSnapshotModal } from '../../facilitate/CurrentAssessmentSlice';
import AssessmentDetailsHeader from '../../facilitate/header/AssessmentDetailsHeader';
import AssessmentRadarResults from '../reviewall/radar/AssessmentRadarResults';

const ManageSnapshots: FunctionComponent<AppLabelsContextInterface & AuthContextInterface> = ({ appLabels, auth }): JSX.Element => {
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);
    const myUserId = auth.getUserInfo().userId;
    const assessmentPermissionManager = new AssessmentPermissionManager(myUserId, currentAssessment?.assessmentPermissions);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const snapshots = currentAssessment?.snapshots;
    const snapshotMetaData = snapshots?.map((s) => {
        return {
            id: s.id,
            description: s.description,
            createdAt: s.createdAt,
            createdBy: s.createdBy,
        };
    });
    const dispatch = useAppDispatch();
    const [selectedItems, setSelectedItems] = useState([]);

    const helpPanel = useCallback(
        (open?: boolean) => ({
            header: appLabels.assessment.snapshot.compare_snapshots,
            content: <ManageSnapshotsHelpPanel />,
            open,
        }),
        [appLabels.assessment.snapshot.compare_snapshots]
    );

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(updateAppHelpPanel(helpPanel()));

        return cleanup;
    }, [dispatch, helpPanel]);

    let snapshotItems = [];
    if (snapshotMetaData) {
        snapshotItems = snapshotMetaData?.map((s) => {
            return {
                id: s.id,
                name: s.description,
                datecreated: s.createdAt ? new Date(s.createdAt * 1000).toString() : '',
            };
        });
    }

    snapshotItems.push({
        id: currentAssessment?.id,
        name: appLabels.assessment.current_assessment,
        datecreated: currentAssessment?.createdAt ? new Date(currentAssessment.createdAt * 1000).toString() : '',
    });

    const onAddSnapshot = () => {
        dispatch(updateCreateSnapshotModal({ showFlag: true }));
    };

    return (
        <div className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} />
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <SpaceBetween size='s' direction='horizontal'>
                <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                    {appLabels.assessment.snapshot.compare_snapshots}
                    <Box margin={{ left: 'xs' }} display='inline-block'>
                        <Link variant='info' onFollow={() => dispatch(updateAppHelpPanel(helpPanel(true)))}>
                            {appLabels.common.info}
                        </Link>
                    </Box>
                </Box>
            </SpaceBetween>

            <CreateSnapshotDialog requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Table
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                selectedItems={selectedItems}
                columnDefinitions={[
                    {
                        id: 'name',
                        header: 'Name',
                        cell: (e) =>
                            e.name === appLabels.assessment.current_assessment ? (
                                <a href={currentAssessment?.id ? generateAssessmentResultsReviewAllUrl(currentAssessment?.id) : null}>
                                    {appLabels.assessment.current_assessment}
                                </a>
                            ) : (
                                <a href={generateAssessmentSnapshotReviewAllUrl(currentAssessment?.id, e.id)}>{e.name}</a>
                            ),
                        sortingField: 'name',
                    },
                    {
                        id: 'datecreated',
                        header: 'Date created',
                        cell: (e) => e.datecreated,
                        sortingField: 'datecreated',
                    },
                ]}
                items={snapshotItems}
                selectionType='multi'
                trackBy='name'
                visibleColumns={['name', 'datecreated']}
                header={
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <Header
                            counter={
                                selectedItems?.length
                                    ? '(' + selectedItems?.length + '/' + snapshotItems?.length + ')'
                                    : '(' + snapshotItems?.length.toString() + ')'
                            }
                        >
                            {appLabels.assessment.snapshot.snapshots}
                        </Header>
                        <Box float='right'>
                            <Button onClick={onAddSnapshot} disabled={!assessmentPermissionManager.hasUpdatePemission}>
                                {appLabels.assessment.snapshot.add_snapshot}
                            </Button>
                        </Box>
                    </Grid>
                }
            />
            <AssessmentRadarResults assessmentOrSnapshotIds={selectedItems.map((item) => item.id)} />
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(ManageSnapshots));
