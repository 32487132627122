import { RatingBand } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { ScoredItem } from './ScoreUtils';
import { AppLabels } from '../AppLabels';
import Constants from '../Constants';

export type StratumFunction = (ratingBand: RatingBand) => ScoreStratum;

export const DefaultStratumColor = {
    INCOMPLETE: Constants.DEFAULT_STRATUM_COLOR_INCOMPLETE,
    GOOD: Constants.DEFAULT_STRATUM_COLOR_GOOD,
    CONCERN: Constants.DEFAULT_STRATUM_COLOR_CONCERN,
    BAD: Constants.DEFAULT_STRATUM_COLOR_BAD,
};

/**
 * Get default rating bands
 * @param appLabels
 * @returns
 */
export const getDefaultRatingBands = (appLabels: AppLabels): RatingBand[] => [
    {
        // good [4, 5.1)
        lowerBound: Constants.ASSESSMENT_GOOD_SCORE,
        upperBound: Constants.ASSESSMENT_DEFAULT_MAX_SCORE + 0.1,
        color: DefaultStratumColor.GOOD,
        title: appLabels.assessment.results.default_rating_bands.title_good,
        description: appLabels.assessment.results.default_rating_bands.description_good,
        __typename: 'RatingBand',
    },
    {
        // concern [2, 4)
        lowerBound: Constants.ASSESSMENT_BAD_SCORE,
        upperBound: Constants.ASSESSMENT_GOOD_SCORE,
        color: DefaultStratumColor.CONCERN,
        title: appLabels.assessment.results.default_rating_bands.title_concern,
        description: appLabels.assessment.results.default_rating_bands.description_concern,
        __typename: 'RatingBand',
    },
    {
        // bad [0, 2)
        lowerBound: Constants.ASSESSMENT_DEFAULT_MIN_SCORE,
        upperBound: Constants.ASSESSMENT_BAD_SCORE,
        color: DefaultStratumColor.BAD,
        title: appLabels.assessment.results.default_rating_bands.title_bad,
        description: appLabels.assessment.results.default_rating_bands.description_bad,
        __typename: 'RatingBand',
    },
];

export const getDefaultIncompleteScoreStratum = (appLabels: AppLabels): ScoreStratum => ({
    backgroundColor: DefaultStratumColor.INCOMPLETE,
    title: appLabels.assessment.results.default_rating_bands.title_incomplete,
    description: appLabels.assessment.results.default_rating_bands.description_incomplete,
    isScoreInStratum: (scored: ScoredItem | null | undefined) => {
        if (!scored) {
            return null;
        } else {
            return (
                scored?.score === null || scored?.score === undefined || scored?.score === 0 || scored.score === Constants.ASSESSMENT_DEFAULT_NA_SCORE
            );
        }
    },
});

/**
 * A range for score stratification
 */
export interface ScoreStratum {
    backgroundColor: string;
    fontColor?: string;
    title: string;
    description: string;
    isScoreInStratum: (scored: ScoredItem | null | undefined) => boolean | null;
}

/**
 * Convert rating band in the template into a score stratum
 * @param ratingBand
 * @returns
 */
export const getScoreStratum: StratumFunction = (ratingBand: RatingBand): ScoreStratum => {
    const upperBoundScore = ratingBand.upperBound || Constants.ASSESSMENT_DEFAULT_MAX_SCORE;
    const lowerBoundScore = ratingBand.lowerBound || Constants.ASSESSMENT_DEFAULT_MIN_SCORE;
    return {
        backgroundColor: ratingBand.color,
        title: ratingBand.title,
        description: ratingBand.description || ratingBand.title,
        isScoreInStratum: (scored: ScoredItem | null | undefined) => {
            if (scored?.score === null || scored?.score === undefined) {
                return false;
            }
            return scored?.score >= lowerBoundScore && scored?.score < upperBoundScore;
        },
    };
};
