import { Grid, TextContent } from '@amzn/awsui-components-react';
import React from 'react';

import { AppLabels } from '../../../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';

const RecommendedActionsLineHeader = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    return (
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 1 }, { colspan: 1 }, { colspan: 2 }, { colspan: 2 }, { colspan: 1 }, { colspan: 1 }]}>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_priority}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_effort}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_target_date}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_owner}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_delete}</TextContent>
            <TextContent>{appLabels.assessment.results.review_all.report.recommended_actions.grid.column_drag}</TextContent>
        </Grid>
    );
};

export default withAppLabelsContext(RecommendedActionsLineHeader);
