import React from 'react';

import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';

export interface CustomerFilterContextInterface {
    customer: string;
    setCustomer: (cust: string) => void;
}
export const CustomerFilterContext = React.createContext<CustomerFilterContextInterface>({
    customer: Constants.DEFAULT_CUSTOMER,
    setCustomer: (cust: string) => {
        rumClient.recordError(`setCustomer called with ${cust}; no setCustomer function set!`);
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withCustomerFilterContext = (Component: any) => {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (props: any): JSX.Element => {
        return (
            <CustomerFilterContext.Consumer>
                {(ctx) => <Component {...props} customer={ctx.customer} setCustomer={ctx.setCustomer} />}
            </CustomerFilterContext.Consumer>
        );
    };
};
