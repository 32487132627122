import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import ActionConfirmationDialog from '../../common/ActionConfirmationDialog';

type ShareInvitationConfirmationDialogProps = AppLabelsContextInterface & {
    inviteeEmail: string;
    assessmentDescription: string;
    confirmAction: () => Promise<any>;
    cancelAction: () => void;
};

const ShareInvitationConfirmationDialog: FunctionComponent<ShareInvitationConfirmationDialogProps> = ({
    appLabels,
    inviteeEmail,
    assessmentDescription,
    confirmAction,
    cancelAction,
}): JSX.Element => {
    if (!inviteeEmail) {
        return null;
    }

    return (
        <ActionConfirmationDialog
            header={`${inviteeEmail} ${appLabels.assessment.share.user_not_found}`}
            message={`${appLabels.assessment.share.invite_message} '${assessmentDescription}'?`}
            actionLabel={appLabels.assessment.share.invite}
            action={confirmAction}
            cancelAction={cancelAction}
        />
    );
};

export default withAppLabelsContext(ShareInvitationConfirmationDialog);
