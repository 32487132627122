import TemplateConstants from './TemplateConstants';
import Paths from '../../../../Paths';
import Constants from '../../../../common/Constants';

/**
 * Formats a URL that will direct a user to editing a specific prompt
 * @param templateId the template being edited
 * @param locale the locale in which the template should be loaded
 * @param viewId the view being edited
 * @param promptId the prompt to navigate to
 * @returns the URL that will load the corresponding template
 */

export const generateTemplatePromptUrl = (templateId: string, locale = Constants.DEFAULT_LOCALE, viewId = '', promptId = ''): string => {
    if (!templateId) {
        // If there's no template ID, just link back to the list page
        return Paths.MANAGE_TEMPLATES_PATH;
    }

    const params = new URLSearchParams();
    params.append(TemplateConstants.SEARCH_PARAM_LOCALE, locale);
    if (viewId) {
        params.append(TemplateConstants.SEARCH_PARAM_VIEW_ID, viewId);
    }
    if (promptId) {
        params.append(TemplateConstants.SEARCH_PARAM_PROMPT_ID, promptId);
    }
    const newUrl = `${Paths.EDIT_TEMPLATE_PATH}/${templateId}?${params.toString()}`;

    return newUrl;
};
/**
 * Formats a URL that will direct a user to editing a specific module
 * @param templateId the template being edited
 * @param locale the locale in which the template should be loaded
 * @param viewId the view being edited
 * @param moduleId the module to navigate to
 * @returns the URL that will load the corresponding template
 */

export const generateTemplateModuleUrl = (templateId: string, locale = Constants.DEFAULT_LOCALE, viewId = '', moduleId = ''): string => {
    if (!templateId) {
        // If there's no template ID, just link back to the list page
        return Paths.MANAGE_TEMPLATES_PATH;
    }

    const params = new URLSearchParams();
    params.append(TemplateConstants.SEARCH_PARAM_LOCALE, locale);
    if (viewId) {
        params.append(TemplateConstants.SEARCH_PARAM_VIEW_ID, viewId);
    }
    if (moduleId) {
        params.append(TemplateConstants.SEARCH_PARAM_MODULE_ID, moduleId);
    }
    const newUrl = `${Paths.EDIT_TEMPLATE_PATH}/${templateId}?${params.toString()}`;

    return newUrl;
};

/**
 * Generate a template-specific URL with a suffix (for sharing, editing assessment type metadata, etc.)
 * @param templateId the template being edited
 * @param suffix the suffix that identifies the component to route to
 * @param locale if this URL is the first one that the user loads, the template will be loaded in this locale
 * @returns a URL for the template, pointing to the component denoted by the suffix
 */
export const generateTemplateUrlWithSuffix = (templateId: string, suffix: string, locale = Constants.DEFAULT_LOCALE): string => {
    if (!templateId) {
        // If there's no template ID, just link back to the list page
        return Paths.MANAGE_TEMPLATES_PATH;
    }

    const params = new URLSearchParams();
    params.append(TemplateConstants.SEARCH_PARAM_LOCALE, locale);

    const newUrl = `${Paths.EDIT_TEMPLATE_PATH}/${templateId}${suffix}?${params.toString()}`;

    return newUrl;
};

/**
 * Replaces the promptId in the search params with the moduleId. Used for setting activeHref in side nav
 * @param allSearchParams the current search params
 * @param currentModuleId the current module ID, or undefined if there is none yet (such as when first loading a template)
 * @returns the same set of search params, but with the moduleId instead of the prompt
 */
export const replaceTemplatePromptSearchParamWithModule = (allSearchParams: string, currentModuleId: string | undefined): string => {
    const parsedSearchParams = new URLSearchParams(allSearchParams);

    // If module ID is already set, no need to set it again
    if (parsedSearchParams.has(TemplateConstants.SEARCH_PARAM_MODULE_ID)) {
        return `?${parsedSearchParams.toString()}`;
    }

    parsedSearchParams.delete(TemplateConstants.SEARCH_PARAM_PROMPT_ID);
    if (currentModuleId) {
        parsedSearchParams.append(TemplateConstants.SEARCH_PARAM_MODULE_ID, currentModuleId);
    }

    return `?${parsedSearchParams.toString()}`;
};
