import { CustomerAccountInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { ValidationError } from 'class-validator';
import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import { errorLookup } from '../../common/ValidatorUtils';

type OnChangeHandler = (subStateValue: Partial<CustomerAccountInput>) => void;

export type CustomerDetailsPanelProps = {
    customerAccountId?: string;
    state: CustomerAccountInput;
    onChange: OnChangeHandler;
    validationErrors: ValidationError[];
} & AppLabelsContextInterface;

const CustomerDetailsPanel: FunctionComponent<CustomerDetailsPanelProps> = ({
    customerAccountId,
    state,
    onChange,
    validationErrors,
    appLabels,
}): JSX.Element => {
    const detailPanelData = state;

    const onCustomerDetailsPanelChange = (subStateValue: Partial<CustomerAccountInput>) => {
        onChange(subStateValue);
    };

    const getValidationErrorText = errorLookup<CustomerAccountInput>(validationErrors);
    const getErrorText = (attribute: keyof CustomerAccountInput): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };

    return (
        <Container
            className='awscat-customer-details-panel'
            header={<Header variant='h2'>{appLabels.customer_selection.create.account_details}</Header>}
        >
            <SpaceBetween size='l'>
                {customerAccountId ? (
                    <FormField label={`${appLabels.customer_selection.customer_account_id}`} stretch={true}>
                        <Input value={customerAccountId} disabled={true} ariaRequired={true} data-testid='customer-account-id-input' />
                    </FormField>
                ) : null}
                <FormField label={`${appLabels.customer_selection.customer_account_name}`} stretch={true} errorText={getErrorText('accountName')}>
                    <Input
                        data-testid='customer-account-name-input'
                        value={detailPanelData.accountName || ''}
                        ariaRequired={true}
                        placeholder={appLabels.customer_selection.create.account_name_placeholder}
                        onChange={({ detail: { value } }) => onCustomerDetailsPanelChange({ accountName: value })}
                    />
                </FormField>
                <FormField label={`${appLabels.customer_selection.reference_id}`} stretch={true} errorText={getErrorText('referenceId')}>
                    <Input
                        data-testid='customer-account-reference-id-input'
                        value={detailPanelData.referenceId}
                        ariaRequired={true}
                        placeholder={appLabels.customer_selection.create.reference_id_placeholder}
                        onChange={({ detail: { value } }) => onCustomerDetailsPanelChange({ referenceId: value })}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default withAppLabelsContext(CustomerDetailsPanel);
