import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useState } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import dompurify from '../../../common/DomPurify';

type PublishTemplateModalProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: (newStatus: boolean) => void;
    onConfirm: () => Promise<void>;
};
/**
 * A simple modal that confirms the "publish template" action. It describes how the various levels of publication work
 */
const PublishTemplateModal: FunctionComponent<PublishTemplateModalProps> = ({ appLabels, visible, setVisible, onConfirm }): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);

    const sanitizedBody = dompurify.sanitize(appLabels.manage_templates.publish_template.confirmation.body);

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loading} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    await onConfirm();
                                } finally {
                                    setLoading(false);
                                    setVisible(false);
                                }
                            }}
                            data-testid='btn-publish'
                        >
                            {appLabels.user_actions.publish}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.publish_template.confirmation.header}
        >
            <div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
        </Modal>
    );
};

export default withAppLabelsContext(PublishTemplateModal);
