import { Checkbox } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback } from 'react';

/**
 * This checkbox is associated with a well-known principal ID (e.g. AWS-orgId). It updates the `setPrincipalsToAdd` and `setPrincipalsToRemove` arrays
 * based on the user interaction
 */
type SpecialPrincipalCheckboxProps = {
    specialPrincipalId: string;
    identifier: string;
    text: string;
    checked: boolean;
    setChecked: (value: React.SetStateAction<boolean>) => void;
    principalsArray: string[];
    setPrincipalsToAdd: (value: React.SetStateAction<string[]>) => void;
    setPrincipalsToRemove: (value: React.SetStateAction<string[]>) => void;
};
export const SpecialPrincipalCheckbox: FunctionComponent<SpecialPrincipalCheckboxProps> = ({
    specialPrincipalId,
    identifier,
    text,
    checked,
    setChecked,
    principalsArray,
    setPrincipalsToAdd,
    setPrincipalsToRemove,
}) => {
    const onChange = useCallback(
        (checked: boolean) => {
            setChecked(checked);
            if (checked) {
                // If newly checked and principal already has access, that means the user was previously trying to remove principal's access
                // Remove from the `principalsToRemove` array
                if (principalsArray.includes(specialPrincipalId)) {
                    setPrincipalsToRemove((principalsToRemove) => principalsToRemove.filter((principalId) => principalId !== specialPrincipalId));
                } else {
                    // Otherwise, add to the `setPrincipalsToAdd` array
                    setPrincipalsToAdd((principalsToAdd) => principalsToAdd.concat(specialPrincipalId));
                }
            } else {
                // If unchecked, remove principal from the `setPrincipalsToAdd` array, or prepare to remove principal from backend
                if (principalsArray.includes(specialPrincipalId)) {
                    setPrincipalsToRemove((principalsToRemove) => principalsToRemove.concat(specialPrincipalId));
                } else setPrincipalsToAdd((principalsToAdd) => principalsToAdd.filter((principalId) => principalId !== specialPrincipalId));
            }
        },
        [principalsArray, setPrincipalsToAdd, setPrincipalsToRemove, specialPrincipalId, setChecked]
    );

    return (
        <Checkbox data-testid={`${identifier}-checkbox`} checked={checked} onChange={({ detail: { checked } }) => onChange(checked)}>
            {text}
        </Checkbox>
    );
};
