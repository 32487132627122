import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Textarea } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { TextMarker } from '../../../common/TextMarker';
import rumClient from '../../../common/monitoring/RumClient';
import UpdateStatusIndicator from '../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { assessmentIsReadOnly } from '../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful } from '../facilitate/CurrentAssessmentSlice';

type FacilitatorCommentsProps = AuthContextInterface &
    AppLabelsContextInterface & {
        promptId: string;
        facilitatorComments: string | null | undefined;
        textMarker: TextMarker;
    };

const FacilitatorComments: FunctionComponent<FacilitatorCommentsProps> = ({
    appLabels,
    promptId,
    facilitatorComments,
    textMarker,
    auth,
}): JSX.Element => {
    let className = 'facilitator-comments-wrapper-unmarked';
    if (textMarker.contains(facilitatorComments)) {
        className = 'facilitator-comments-wrapper-marked';
    }

    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const myUserId = auth?.getUserInfo()?.userId;
    const isReadOnly = assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    interface FacilitatorCommentsState {
        id: string | null;
        comments: string;
        responseSaved: boolean;
    }

    const initialFacilitatorCommentsState: FacilitatorCommentsState = {
        id: promptId,
        comments: facilitatorComments,
        responseSaved: false,
    };

    const [state, setState] = useState<FacilitatorCommentsState>(initialFacilitatorCommentsState);
    useEffect(() => {
        setState({
            responseSaved: state.responseSaved,
            id: promptId,
            comments: facilitatorComments,
        });
    }, [facilitatorComments, promptId, state.responseSaved]);

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId, response: updatedResponse }));
                setState({ ...state, responseSaved: true });
            }
        },
    });

    const updateComments = useCallback(() => {
        if (isReadOnly) {
            return;
        }
        const comments = state.comments || null; // set to null to remove comment for all falsy values. e.g. ''
        if (comments !== facilitatorComments) {
            createOrUpdateAssessmentResponses({
                variables: {
                    input: {
                        assessmentId: assessmentId,
                        responses: [
                            {
                                promptId,
                                comments,
                            },
                        ],
                    },
                },
            });
        }
    }, [isReadOnly, state.comments, facilitatorComments, createOrUpdateAssessmentResponses, assessmentId, promptId]);

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.results.review_all.report.facilitator_comments_updating}
                updateConfirmationText={
                    !loading && !error && state.responseSaved ? appLabels.assessment.results.review_all.report.facilitator_comments_saved : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.results.review_all.report.facilitator_comments_error_api : null}
                errorMessageDetail={error?.message ? `[${error?.message}]` : null}
                tryAgainText={appLabels.assessment.results.review_all.report.facilitator_comments_try_update_again}
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [loading, appLabels, state.responseSaved, error]);

    return (
        <Box className='question-facilitator-comments-section'>
            <Box fontWeight='bold' fontSize='body-m' className='question-facilitator-comments-label'>
                {appLabels.assessment.results.review_all.report.facilitator_comments}
            </Box>
            {updateStatusIndicator()}
            <Box className={className}>
                <Textarea
                    placeholder={appLabels.assessment.results.review_all.report.facilitator_comments_placeholder}
                    className='question-facilitator-comments'
                    readOnly={isReadOnly}
                    value={state.comments}
                    onChange={({ detail }) => setState({ ...state, comments: detail.value })}
                    onBlur={updateComments}
                />
            </Box>
        </Box>
    );
};

export default withAuthContext(withAppLabelsContext(FacilitatorComments));
