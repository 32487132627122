import './AssessmentReportResults.scss';

import { Alert, Box, ColumnLayout, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useQuery } from '@apollo/client';
import React, { FunctionComponent, useMemo, useState } from 'react';

import { AssessmentFacetSections } from './AssessmentFacetSections';
import { FilteringBox } from './report-filters/FilteringBox';
import { getQuestionCounter } from './report-filters/QuestionCounter';
import { AssessmentFacetFilter, AssessmentQuestionVectorFilter } from './report-filters/ReportFilters';
import { LIST_MY_RESPONSE_ANALYSIS, extractPromptIdFromWbsSolicitId } from '../../../../../api/wbs/WbsClient';
import { AnalysisDoc, ResponseAnalysisResult } from '../../../../../api/wbs/WbsTypes';
import { AppLabels } from '../../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import Constants from '../../../../../common/Constants';
import dompurify from '../../../../../common/DomPurify';
import { getTextMarker } from '../../../../../common/TextMarker';
import { deduplicateArray, notEmpty, transformWbsAnalysisDocToComments } from '../../../../../common/Utils';
import rumClient from '../../../../../common/monitoring/RumClient';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { PromptId } from '../../../AssessmentPromptViewModel';
import { assigneeAnswerToComment } from '../../../facilitate/body/AssessmentDetailsBody';
import { Comment } from '../../../facilitate/body/CommentBox';
import { filterExcludedSections } from '../../Utils';
import {
    Assessment,
    AssessmentFacetSpace,
    convertAssessmentSectionToAssessmentFacet,
    convertAssessmentWorkstreamToAssessmentFacet,
    getAssessmentMetadataProvider,
} from '../../model/Assessment';
import LegendBoxes from '../legend/LegendBoxes';

type ParticipantsResponseMap = Map<PromptId, Comment[]>;

const InfoHeader = (appLabels: AppLabels): JSX.Element => {
    return <>{appLabels.assessment.results.review_all.report.info_header}</>;
};

const InfoContent = (appLabels: AppLabels, scoreScale: ScoreScale, contextHelp: string): JSX.Element => {
    const sanitizedContextHelp = dompurify.sanitize(contextHelp || appLabels.assessment.results.review_all.report.info_content);
    return (
        <>
            <div className='awscat-assessment-report-help-panel' dangerouslySetInnerHTML={{ __html: sanitizedContextHelp }}></div>
            <div>
                <h4>{appLabels.assessment.results.review_all.report.info_legend}</h4>
                <LegendBoxes scoreScale={scoreScale}></LegendBoxes>
            </div>
        </>
    );
};

const AssessmentReportResults: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const templateDefaults = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.template?.defaults);
    const title = templateDefaults?.report?.report?.title;
    const description = templateDefaults?.report?.report?.description;
    const purifiedHtmlDescription = dompurify.sanitize(description || '');

    // PreEvent participant responses
    const preEventParticipantsResponseMap: ParticipantsResponseMap = new Map();
    const preEventInfo = useAppSelector((state) => state.currentAssessmentState.preEventInfo);
    if (preEventInfo) {
        // Add preEvent participant comments to participantsResponseMap
        preEventInfo.answers?.forEach((answer) => {
            const promptId = answer.sourceResourceId;
            const comments = answer.assigneeAnswers.reduce((comments, answer) => {
                const comment = assigneeAnswerToComment(answer);
                comments.push(comment);
                return comments;
            }, []);
            preEventParticipantsResponseMap.set(promptId, comments);
        });
    }

    // Collect live session participant responses
    const liveSessionEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.questionnaireAnswers?.wbsEnabled
    );
    const selectedLiveSessionId = useAppSelector((state) => state.currentAssessmentState.selectedLiveSessionId);
    const [responseAnalysisResults, setResponseAnalysisResults] = useState<ResponseAnalysisResult[]>([]);
    const liveSessionParticipantsResponseMap: ParticipantsResponseMap = new Map();

    // Use Score Scale for workstreams, activities, sections, categories, and by default for questions too.
    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessment?.template?.defaults?.questionnaireAnswers, appLabels);
    }, [appLabels, currentAssessment?.template?.defaults?.questionnaireAnswers]);

    // Use Rating Scale for questions if template specifies to use RatingScale for questions.
    const ratingScale = useMemo(() => {
        const useRatingStratum = true;
        return new ScoreScale(templateDefaults?.questionnaireAnswers, appLabels, useRatingStratum);
    }, [appLabels, templateDefaults?.questionnaireAnswers]);

    let questionScoreScale: ScoreScale = scoreScale;
    if (['CSM', 'CMA'].includes(currentAssessment?.type)) {
        questionScoreScale = ratingScale;
    }

    useQuery(LIST_MY_RESPONSE_ANALYSIS, {
        skip: !liveSessionEnabled || !selectedLiveSessionId,
        variables: {
            input: {
                sessionId: selectedLiveSessionId,
            },
        },
        onCompleted: (data) => {
            if (data.listMyResponseAnalysis) {
                setResponseAnalysisResults(data.listMyResponseAnalysis);
            }
        },
        onError: (error) => {
            // Unable to retrieve live session results.
            // Simply log error and proceed to minimize live session interruptions.
            rumClient.recordError(`LIST_MY_RESPONSE_ANALYSIS selectedLiveSessionId=${selectedLiveSessionId}, error=${error}`);
        },
    });
    if (responseAnalysisResults.length > 0) {
        // Add live session comments to participantsResponseMap
        responseAnalysisResults.forEach((responseAnalysisResult) => {
            const { solicitId, analysisDoc } = responseAnalysisResult;
            const promptId = extractPromptIdFromWbsSolicitId(solicitId);
            try {
                const responseAnalysisDoc: AnalysisDoc = JSON.parse(analysisDoc);
                const participantComments = transformWbsAnalysisDocToComments(appLabels.assessment.facilitate.live_event, responseAnalysisDoc);
                liveSessionParticipantsResponseMap.set(promptId, participantComments);
            } catch (err) {
                // analysisDoc is malformed
                // Simply log error and proceed to minimize live session interruptions.
                rumClient.recordError(`analysisDoc=${analysisDoc}, error=${err}`);
            }
        });
    }

    // Merge participantsResponseMap
    const participantsResponseMap: ParticipantsResponseMap = new Map([...Array.from(liveSessionParticipantsResponseMap.entries())]);
    preEventParticipantsResponseMap.forEach((preEventParticipantResponse, promptId) => {
        const liveSessionParticipantResponse = participantsResponseMap.get(promptId);
        if (liveSessionParticipantResponse) {
            const combinedComments = liveSessionParticipantResponse.concat(preEventParticipantResponse);
            participantsResponseMap.set(promptId, combinedComments);
        } else {
            participantsResponseMap.set(promptId, preEventParticipantResponse);
        }
    });

    const promptMetadataProvider = getAssessmentMetadataProvider(currentAssessment);
    let vectorSpaceName = '';
    let assessment: Assessment | null = null;
    const workstreams = currentAssessment?.template?.workstreams;
    const excludeSections = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.report?.report?.excludeSections
    );
    const includedSections = filterExcludedSections(currentAssessment?.template?.sections, excludeSections, currentAssessment, null);

    if (workstreams?.length > 0) {
        vectorSpaceName = Constants.VECTOR_SPACE_TYPE_ACTIVITIES;
        const assessmentFacetSpace: AssessmentFacetSpace = {
            assessmentFacets: workstreams
                .filter(notEmpty)
                .map((workstream) =>
                    convertAssessmentWorkstreamToAssessmentFacet(
                        assessmentId,
                        selectedSnapshotId,
                        workstream,
                        promptMetadataProvider,
                        participantsResponseMap
                    )
                ),
            type: Constants.FACET_SPACE_TYPE_WORKSTREAMS,
        };
        assessment = {
            assessmentFacetSpace: assessmentFacetSpace,
        };
    } else if (includedSections?.length > 0) {
        vectorSpaceName = Constants.VECTOR_SPACE_TYPE_CATEGORIES;
        const assessmentFacetSpace: AssessmentFacetSpace = {
            assessmentFacets: includedSections
                .filter(notEmpty)
                .map((section) =>
                    convertAssessmentSectionToAssessmentFacet(
                        assessmentId,
                        selectedSnapshotId,
                        section,
                        promptMetadataProvider,
                        participantsResponseMap
                    )
                ),
            type: Constants.FACET_SPACE_TYPE_PERSPECTIVES,
        };
        assessment = {
            assessmentFacetSpace: assessmentFacetSpace,
        };
    }

    // filteringText is text from TextFilter at top of page.
    const [filteringText, setFilteringText] = React.useState<string | null>('');
    // Below are selections from multiselect filters
    const [selectedRatings, setSelectedRatings] = React.useState<string[] | null>([]);
    const [selectedQuestionVectorNames, setSelectedQuestionVectorNames] = React.useState<string[] | null>([]);
    const [selectedPhases, setSelectedPhases] = React.useState<string[] | null>([]);

    // Below are set of all options for each multiselect filter
    const allRatings = questionScoreScale.getAllStrataTitles();
    const allQuestionVectors =
        assessment?.assessmentFacetSpace.assessmentFacets.flatMap((facet) => {
            const vectorFilters = facet.questionVectors.map((questionVector) => {
                return {
                    name: questionVector.name,
                } as AssessmentQuestionVectorFilter;
            });
            return {
                name: facet.name,
                vectorFilters: vectorFilters,
            } as AssessmentFacetFilter;
        }) || [];
    const allPhases = deduplicateArray(
        currentAssessment?.template?.workstreams
            ?.filter(notEmpty)
            .flatMap((workstream) => workstream.activities)
            .filter((activity) => !!activity.phase)
            .map((activity) => activity.phase) || []
    );

    const questionCounter = getQuestionCounter();
    const filteredFacets = assessment?.assessmentFacetSpace.assessmentFacets || [];
    const [assessmentFacetSections, setAssessmentFacetSections] = React.useState<(JSX.Element | null)[]>(
        AssessmentFacetSections(
            appLabels,
            assessmentId,
            filteredFacets,
            questionCounter,
            getTextMarker(filteringText),
            scoreScale,
            questionScoreScale
        )
    );

    // Below filter functions are attached to onChange for multiselects and textfilter at top of page.
    // Each changes corresponding state, which will cause useEffect to call doFilter, which performs
    // filter and refreshes page.
    const filterText = (filterText: string) => {
        setFilteringText(filterText);
    };
    const filterRatings = (ratings: string[]) => {
        const stratumLabels = questionScoreScale?.getAllStrataTitles();
        const selectedStrata: string[] = [];
        stratumLabels?.forEach((label) => {
            if (ratings.includes(label.toString())) {
                selectedStrata.push(label);
            }
        });
        setSelectedRatings(selectedStrata);
    };
    const filterQuestionVectors = (questionVectorNames: string[]) => {
        setSelectedQuestionVectorNames(questionVectorNames);
    };
    const filterPhases = (phases: string[]) => {
        setSelectedPhases(phases);
    };
    const onClearFilters = () => {
        setFilteringText('');
        setSelectedRatings([]);
        setSelectedQuestionVectorNames([]);
        setSelectedPhases([]);
    };

    React.useEffect(() => {
        const doFilter = () => {
            /**
             * General algo:
             * Assume display is false, then using filtering, determine if given question will show.
             * Notes:
             * 1. if question is displayed, then its vector and facet will be displayed too, but no all questions/vectors within that grouping.
             * 2. Each level has different filtering present
             *      a. Facet is only matched with text filter (name)
             *      b. Vector is matched on text filter (name, summary obs, recommended actions), phase filter, and
             *          vector filter (either activities or categories, depending which of those is being used)
             *      c. Question is matched on text filter (text, facilitator comments, participant comments, index)
             *          and rating filter
             * 3. If facet matches, then underlying vectors and questions are said to match text filter as well, including all questions within. However
             *    They can still be filtered out by the multiselect filters. Same for vector
             */
            const searchText = filteringText?.trim().toLocaleLowerCase();
            filteredFacets.forEach((facet) => {
                if (facet) {
                    const facetTextMatch = !searchText || facet?.name?.trim().toLocaleLowerCase().includes(searchText);
                    facet.display = false; // default to false, but children may change this

                    facet.questionVectors?.forEach((questionVector) => {
                        if (questionVector) {
                            const questionVectorTextMatch =
                                !searchText ||
                                facetTextMatch ||
                                questionVector?.name?.trim().toLocaleLowerCase().includes(searchText) ||
                                questionVector?.summaryObservations?.trim().toLocaleLowerCase().includes(searchText) ||
                                questionVector?.recommendedActions?.some((action) => action.text.trim().toLocaleLowerCase()?.includes(searchText));
                            const questionVectorPhaseMatch =
                                selectedPhases?.length === 0 || (questionVector?.phase && selectedPhases?.includes(questionVector?.phase));
                            questionVector.display = false; // default to false, but children may change this

                            questionVector.questions?.forEach((question) => {
                                if (responseAnalysisResults.length > 0 || preEventInfo?.answers?.length > 0) {
                                    const participantComments = participantsResponseMap.get(question.promptId);
                                    question.participantComments = participantComments;
                                }
                                if (question) {
                                    const questionTextMatch =
                                        !searchText ||
                                        questionVectorTextMatch ||
                                        question?.promptText?.trim().toLocaleLowerCase().includes(searchText) ||
                                        question?.facilitatorComments?.trim().toLocaleLowerCase().includes(searchText) ||
                                        question?.participantComments?.some((comment) =>
                                            JSON.stringify(comment).toLocaleLowerCase()?.includes(searchText)
                                        ) ||
                                        question?.index.toString()?.trim().toLocaleLowerCase().includes(searchText);
                                    const stratumTitle = (question.type === 'scored' && questionScoreScale?.getStratificationTitle(question)) || null;
                                    const questionScoreMatch =
                                        selectedRatings?.length === 0 ||
                                        (question.type === 'scored' && stratumTitle && question?.score && selectedRatings?.includes(stratumTitle)) ||
                                        (question.type === 'scored' &&
                                            !question?.score &&
                                            selectedRatings?.includes(questionScoreScale.bands[0].title));
                                    const questionVectorMatch =
                                        selectedQuestionVectorNames?.length === 0 ||
                                        (questionVector?.name && selectedQuestionVectorNames?.includes(questionVector?.name));
                                    if (questionTextMatch && questionScoreMatch && questionVectorMatch && questionVectorPhaseMatch) {
                                        question.display = true;
                                        questionVector.display = true;
                                        facet.display = true;
                                    } else {
                                        question.display = false;
                                    }
                                }
                            });
                        }
                    });
                }
                // update rendered components. This will cause re-render
                setAssessmentFacetSections(
                    AssessmentFacetSections(
                        appLabels,
                        assessmentId,
                        filteredFacets,
                        questionCounter,
                        getTextMarker(filteringText),
                        scoreScale,
                        questionScoreScale
                    )
                );
            });
        };
        doFilter();

        dispatch(
            updateAppHelpPanel({
                header: InfoHeader(appLabels),
                content: InfoContent(appLabels, questionScoreScale, currentAssessment?.template?.defaults?.report?.report?.context),
                open: false,
            })
        );

        const cleanup = () => {
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
        // Must disable complaint about not all dependencies included (including all causes infinite re-render loop)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        appLabels,
        filteringText,
        selectedRatings,
        selectedQuestionVectorNames,
        currentAssessment?.template?.defaults?.report?.report?.context,
        currentAssessment?.template?.sections,
        selectedPhases,
        responseAnalysisResults,
        preEventInfo,
    ]);

    if (filteredFacets?.length < 1) {
        return (
            <Alert visible={true} dismissAriaLabel={appLabels.user_actions.close_alert} type='warning'>
                {appLabels.assessment.results.review_all.report.error_empty_report}
            </Alert>
        );
    }
    return (
        <Box>
            <SpaceBetween size='l'>
                <SpaceBetween size='xs' direction='horizontal'>
                    <Box variant='h3'>{title}</Box>
                    <Link variant='info' id='assessment-report-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </SpaceBetween>
                <div dangerouslySetInnerHTML={{ __html: purifiedHtmlDescription }}></div>
                {FilteringBox(
                    appLabels,
                    questionCounter,
                    filterText,
                    vectorSpaceName,
                    filteringText,
                    allRatings,
                    filterRatings,
                    selectedRatings,
                    allQuestionVectors,
                    filterQuestionVectors,
                    selectedQuestionVectorNames,
                    allPhases,
                    filterPhases,
                    selectedPhases,
                    onClearFilters
                )}
                <ColumnLayout columns={1}>{assessmentFacetSections}</ColumnLayout>
            </SpaceBetween>
        </Box>
    );
};

export default withAppLabelsContext(AssessmentReportResults);
