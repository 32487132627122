import { Container, Header } from '@amzn/awsui-components-react';

import OptionSendAllPrompts from './OptionSendAllPrompts';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

type ManageOptionsProp = {
    disabled: boolean;
} & AppLabelsContextInterface;

const ManageOptions = ({ appLabels, disabled }: ManageOptionsProp): JSX.Element => {
    return (
        <Container header={<Header variant='h2'>{appLabels.common.options}</Header>}>
            <OptionSendAllPrompts disabled={disabled} />
        </Container>
    );
};

export default withAppLabelsContext(ManageOptions);
