import { Box } from '@amzn/awsui-components-react';

export interface EmptyTableProps {
    title: string;
    subtitle: string;
}

const EmptyTable = ({ title, subtitle }: EmptyTableProps): JSX.Element => (
    <Box textAlign='center' color='inherit'>
        <b>{title}</b>
        <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
            {subtitle}
        </Box>
    </Box>
);

export default EmptyTable;
