import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { useQuery } from '@apollo/client';
import { FunctionComponent, useState } from 'react';

import { loadAuthorizedFeatures } from './AssessmentFeaturesSlice';
import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { GET_ASSESSMENT_FEATURES } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import { useAppDispatch } from '../../redux/hooks';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../RequestStatusFlashbar';

type AssessmentFeaturesProp = AuthContextInterface & AppLabelsContextInterface;
const AssessmentFeatures: FunctionComponent<AssessmentFeaturesProp> = ({ auth, appLabels }): JSX.Element => {
    // This component simply loads authorized assessment features from A2S and stores in redux store
    const dispatch = useAppDispatch();

    // Initialized as loading as useQuery will be triggered
    const [requestStatus, setRequestStatus] = useState<RequestStatus>({ ...defaultRequestStatus, loading: true });

    const isAuthenticated = auth.isAuthenticated();
    useQuery(GET_ASSESSMENT_FEATURES, {
        skip: !isAuthenticated || !requestStatus.loading, // Do not query if not authenticated or not loading
        client: a2sApolloClient,
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.features.error_loading_features,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            setRequestStatus(defaultRequestStatus);
            const authorizedFeatures = data.getAssessmentFeatures?.items;
            if (authorizedFeatures?.length > 0) {
                dispatch(loadAuthorizedFeatures(authorizedFeatures));
            }
        },
    });

    return <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />;
};

export default withAuthContext(withAppLabelsContext(AssessmentFeatures));
