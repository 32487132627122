import { FlashContextInterface, withFlashContext } from '@amzn/awscat-react-components';
import { Checkbox, Container, Header, Modal, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EventResponseFilter, PromptsFilter, updateEventResponseFilter, updatePromptsFilter } from '../CurrentAssessmentSlice';

type AssessmentFilterDialogProp = AppLabelsContextInterface &
    FlashContextInterface & {
        showDialog: boolean;
        setShowDialog: Dispatch<SetStateAction<boolean>>;
    };

const AssessmentFilterDialog: FunctionComponent<AssessmentFilterDialogProp> = ({ showDialog, setShowDialog, appLabels }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const filterState = useAppSelector((state) => state.currentAssessmentState.currentPromptState.promptsFilter);
    const eventResponseFilter = useAppSelector((state) => state.currentAssessmentState.currentPromptState.eventResponseFilter);
    const preEventEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.userQuestionnaires?.preEvent?.enabled
    );
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    let liveSessionEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.questionnaireAnswers?.wbsEnabled
    );
    if (selectedSnapshotId) {
        liveSessionEnabled = false;
    }

    const cleanup = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const confirmNewSelection = (newFilterState: PromptsFilter) => {
        dispatch(updatePromptsFilter(newFilterState));
    };

    const includePreLiveEventQuestionFilters = () => {
        if (preEventEnabled) {
            return (
                <SpaceBetween direction='vertical' size='xs'>
                    <Checkbox
                        onChange={({ detail }) => confirmNewSelection({ ...filterState, preEvent: detail.checked })}
                        checked={filterState.preEvent}
                    >
                        {appLabels.assessment.workshop_filters.pre_event}
                    </Checkbox>
                    <Checkbox
                        onChange={({ detail }) => confirmNewSelection({ ...filterState, liveEvent: detail.checked })}
                        checked={filterState.liveEvent}
                    >
                        {appLabels.assessment.workshop_filters.live_event}
                    </Checkbox>
                </SpaceBetween>
            );
        }
        return null;
    };

    const includePreLiveEventResponseFilters = () => {
        if (liveSessionEnabled && preEventEnabled) {
            // if both live session and pre-event are enabled, add polling filter
            return (
                <Container
                    header={
                        <Header description={appLabels.assessment.workshop_filters.polling_results_description}>
                            {appLabels.assessment.workshop_filters.polling_results_header}
                        </Header>
                    }
                >
                    <RadioGroup
                        onChange={({ detail }) => dispatch(updateEventResponseFilter(detail.value as EventResponseFilter))}
                        value={eventResponseFilter}
                        items={[
                            { value: EventResponseFilter.preEvent, label: appLabels.assessment.workshop_filters.pre_event },
                            { value: EventResponseFilter.liveEvent, label: appLabels.assessment.workshop_filters.live_event },
                        ]}
                    />
                </Container>
            );
        }
        return null;
    };

    return (
        <Modal
            onDismiss={cleanup}
            visible={showDialog}
            size='medium'
            header={appLabels.assessment.workshop_filters.header}
            data-testid='filter-dialog'
        >
            <SpaceBetween direction='horizontal' size='s'>
                <Container
                    header={
                        <Header description={appLabels.assessment.workshop_filters.questions_description} data-testid='filter-modal-header'>
                            {appLabels.assessment.workshop_filters.questions_header}
                        </Header>
                    }
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <Checkbox
                            onChange={({ detail }) => confirmNewSelection({ ...filterState, unAnswered: detail.checked })}
                            // So that at least one box is always checked, prevent un-checking this box if it is the only one checked
                            disabled={filterState.unAnswered && !filterState.answered}
                            checked={filterState.unAnswered}
                            data-testid='filter-modal-unanswered-checkbox'
                        >
                            {appLabels.assessment.workshop_filters.unanswered}
                        </Checkbox>
                        <Checkbox
                            onChange={({ detail }) => confirmNewSelection({ ...filterState, answered: detail.checked })}
                            disabled={filterState.answered && !filterState.unAnswered}
                            checked={filterState.answered}
                            data-testid='filter-modal-answered-checkbox'
                        >
                            {appLabels.assessment.workshop_filters.answered}
                        </Checkbox>
                        {includePreLiveEventQuestionFilters()}
                    </SpaceBetween>
                </Container>
                {includePreLiveEventResponseFilters()}
            </SpaceBetween>
        </Modal>
    );
};

export default withFlashContext(withAppLabelsContext(AssessmentFilterDialog));
