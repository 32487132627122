import { HelpPanel } from '@amzn/awsui-components-react';

import { useAppSelector } from '../../redux/hooks';

const AppHelpPanel = (): JSX.Element => {
    const appHelpPanelState = useAppSelector((state) => state.appHelpPanelState);

    return (
        <HelpPanel header={appHelpPanelState.header} footer={appHelpPanelState.footer}>
            {appHelpPanelState.content}
        </HelpPanel>
    );
};

export default AppHelpPanel;
