import { OptionalAssessmentMetadataKey } from '@amzn/aws-assessment-template-management-service-typescript-client';
import camelCase from 'camelcase';
import readXlsxFile, { Row, readSheetNames } from 'read-excel-file';
import convertToJson from 'read-excel-file/schema';

import { baseJsonTemplate } from './BaseTemplate';
import { excelTemplateSchema, recommendedActionsExcelSchema } from './ExcelTemplateSchema';
import rumClient from '../../../../../common/monitoring/RumClient';
import { Template } from '../CreateTemplateSlice';

// Remove non-alphanumeric/space characters that prevents proper working of converting phrase to single word camelCase
const DEFAULT_STRIP_REGEXP = /[^A-Z0-9 ]+/gi;
const pascalCase = (str: string) => camelCase(str.replace(DEFAULT_STRIP_REGEXP, ''), { pascalCase: true });

const supportDefaultRating = false;

/**
 * Converts the column names that are in the requester guide template to the names that the above schema can recognize
 * @param headers - the row with the column headers
 */
function transformColumnNames(headers: Row): void {
    for (let i = 0; i < headers.length; i++) {
        if (headers[i]) {
            // headers[i] can be undefined if empty header presents in a column followed by columns with headers
            const headerValue = headers[i].toString();
            if (headerValue.includes('Context') && headerValue.includes('category')) {
                headers[i] = 'Category Context';
            } else if (headerValue.includes('Context') && headerValue.includes('question')) {
                headers[i] = 'Question Context';
            } else if (headerValue.includes('Answer Type')) {
                headers[i] = 'Answer Type';
            } else if (headerValue.includes('Hint')) {
                headers[i] = 'Hint';
            }
        }
    }
}

export const convertExcelToJson = async (excelFile: File): Promise<Template> => {
    const allSheets = await readSheetNames(excelFile);

    // List sheets that don't have questions. Excel sheet names can't be more than 30 characters. -RA helps sections with long names
    const sheetNamesWithoutQuestions = ['Context', 'Combined', 'Configure Template', 'Recommended Actions', '-RA'];

    const sheetNamesWithQuestions = allSheets
        .map((name) => name.trim())
        // Use `some()` and `includes()` for cases like: `Category-Recommended Actions`, so we can match a substring
        .filter((name) => !sheetNamesWithoutQuestions.some((excludedSheetName) => name.includes(excludedSheetName)));

    // Make a deep copy of the template
    const baseTemplateDeepCopy = JSON.parse(JSON.stringify(baseJsonTemplate));
    const sections = [];
    const template: Template = {
        ...baseTemplateDeepCopy,
        section: {
            perspectives: sections,
        },
    };

    let numberOfQuestions = 0;

    // Read sections/categories/questions
    const questionsPromises = sheetNamesWithQuestions.map(async (sheetName) => {
        const sectionName = sheetName.replace('&amp;', '&').trim();
        const sectionId = pascalCase(sectionName);
        let section = sections.find((p: any) => p.id === sectionId);
        if (!section) {
            section = {
                id: sectionId,
                label: sectionName,
                categories: [],
            };
            sections.push(section);
        }
        const categories = section.categories;
        const data = await readXlsxFile(excelFile, { sheet: sheetName });

        if (!data || !data[0] || !data[0][0]) {
            throw new Error(
                `Worksheet ${sheetName} has an empty first cell (A1). Ensure there are no empty worksheets, and make sure you use the same template as the A2T requester guide`
            );
        }

        // If the first row doesn't include the column headings, remove it. Requesters often forget to remove this row
        if (data[0][0].toString().includes('EXAMPLES')) {
            data.shift();
        }

        // The convertToJson function expects the headers to match exactly. Need to remove any extra text from the headers
        try {
            transformColumnNames(data[0]);
        } catch (err) {
            rumClient.recordError(err);
            throw new Error(`Failed to parse headers on worksheet ${sheetName}. Make sure to follow the A2T requester guide`);
        }

        const results: any = convertToJson(data, excelTemplateSchema);
        const questions = results.rows;
        let categoryName = 'TBD';
        questions.forEach((q: any) => {
            const { categoryContext, question, type, questionContext, answerType, ratingsGuide, hint } = q;
            if (!question?.trim()) {
                // No question text, skip
                return;
            }

            if (q.category) {
                categoryName = q.category.trim();
            }

            const categoryId = pascalCase(categoryName);
            let category = categories.find((c: any) => c.id === categoryId);
            if (!category) {
                category = {
                    id: categoryId,
                    abbreviation: categoryName,
                    name: categoryName,
                    ...(categoryContext && { ability: { text: categoryContext?.trim() } }),
                    prescribedActions: [],
                    questions: [],
                };
                categories.push(category);
            }
            const questions = category.questions;
            const questionIndex = questions.length;
            const id = `${sectionId}-${categoryId}-${questionIndex}`;
            const questionAttribute: any = {
                id,
                question: question?.trim(),
                type,
                context: questionContext?.trim(),
                hint: hint?.trim(),
            };

            // Process question type (e.g. preEvent, liveEvent)
            if (type) {
                const questionType = camelCase(type);
                if (['preEvent', 'liveEvent'].includes(questionType)) {
                    questionAttribute.type = questionType;
                } else {
                    questionAttribute.type = 'liveEvent';
                }
            }

            // Process answerType
            if (answerType) {
                if (['yesno', 'number', 'ratings', 'text', 'singleSelection', 'multiSelection', 'dateValue'].includes(answerType)) {
                    questionAttribute.answerType = answerType;
                }
            } else {
                questionAttribute.answerType = 'text';
                if (!questionAttribute.hint) {
                    questionAttribute.hint = answerType;
                }
            }

            // Process rating guide
            if (ratingsGuide && questionAttribute.answerType === 'ratings') {
                const extractRatingGuide = (rating: '1' | '2' | '3' | '4' | '5' | 'NA', ratingsGuide: string): string | undefined => {
                    const ratingGuides = ratingsGuide.split('\n').map((guide) => guide.trim());
                    const ratingGuide = ratingGuides.find((guide) => guide.startsWith(rating)) || '';

                    // Check if the rating guide starts with a prefix like "1 -", "1-", or "1.". Start rating guide afterwards
                    let ratingGuideStartIndex = -1;
                    [`${rating} -`, `${rating}-`, `${rating}.`].some((prefix) => {
                        if (ratingGuide.startsWith(prefix)) {
                            ratingGuideStartIndex = prefix.length;
                            return true;
                        }
                        return false;
                    });
                    return ratingGuide.substring(ratingGuideStartIndex).trim();
                };
                questionAttribute.answerType = undefined;
                const rating1 = extractRatingGuide('1', ratingsGuide);
                if (rating1) {
                    if (supportDefaultRating && supportDefaultRating && !template.defaults.questionnaireAnswers.rating1) {
                        template.defaults.questionnaireAnswers.rating1 = rating1;
                    }

                    if (rating1 !== template.defaults.questionnaireAnswers.rating1) {
                        questionAttribute.rating1 = rating1;
                    }
                }
                const rating2 = extractRatingGuide('2', ratingsGuide);
                if (rating2) {
                    if (supportDefaultRating && !template.defaults.questionnaireAnswers.rating2) {
                        template.defaults.questionnaireAnswers.rating2 = rating2;
                    }

                    if (rating2 !== template.defaults.questionnaireAnswers.rating2) {
                        questionAttribute.rating2 = rating2;
                    }
                }
                const rating3 = extractRatingGuide('3', ratingsGuide);
                if (rating3) {
                    if (supportDefaultRating && !template.defaults.questionnaireAnswers.rating3) {
                        template.defaults.questionnaireAnswers.rating3 = rating3;
                    }

                    if (rating3 !== template.defaults.questionnaireAnswers.rating3) {
                        questionAttribute.rating3 = rating3;
                    }
                }
                const rating4 = extractRatingGuide('4', ratingsGuide);
                if (rating4) {
                    if (supportDefaultRating && !template.defaults.questionnaireAnswers.rating4) {
                        template.defaults.questionnaireAnswers.rating4 = rating4;
                    }

                    if (rating4 !== template.defaults.questionnaireAnswers.rating4) {
                        questionAttribute.rating4 = rating4;
                    }
                }
                const rating5 = extractRatingGuide('5', ratingsGuide);
                if (rating5) {
                    if (supportDefaultRating && !template.defaults.questionnaireAnswers.rating5) {
                        template.defaults.questionnaireAnswers.rating5 = rating5;
                    }

                    if (rating5 !== template.defaults.questionnaireAnswers.rating5) {
                        questionAttribute.rating5 = rating5;
                    }
                }
                const ratingNA = extractRatingGuide('NA', ratingsGuide);
                if (ratingNA) {
                    if (supportDefaultRating && !template.defaults.questionnaireAnswers.ratingNA) {
                        template.defaults.questionnaireAnswers.ratingNA = ratingNA;
                    }

                    if (ratingNA !== template.defaults.questionnaireAnswers.ratingNA) {
                        questionAttribute.ratingNA = ratingNA;
                    }
                }
            }

            // Process selections - currently using ratings guide column
            if (questionAttribute.answerType === 'singleSelection' || questionAttribute.answerType === 'multiSelection') {
                if (!ratingsGuide) {
                    throw new Error(
                        `For a selection question in ${sectionName}-${categoryName}, no selections provided. Make sure each selection is on a new line in the Rating Guides tab. Full question text: ${questionAttribute.question}`
                    );
                }

                const selections: string[] = ratingsGuide.split('\n');

                questionAttribute.responseSelections = {
                    selectionDescription: questionAttribute.answerType === 'singleSelection' ? 'Select one' : 'Select one or more',
                    selections: [],
                };
                selections.forEach((label) =>
                    questionAttribute.responseSelections.selections.push({
                        selectionLabel: label,
                        selectionId: pascalCase(label),
                    })
                );
            }

            numberOfQuestions++;
            questions.push(questionAttribute);
        });
    });

    await Promise.all(questionsPromises);

    if (sections.length === 0) {
        throw new Error('Failed to read the template (no sections loaded). Make sure your template follows the A2T requester guide');
    }

    sections.forEach((section) => {
        if (section.categories.length === 0) {
            throw new Error(
                `Failed to read the template (no categories in section ${section.label}). Make sure your template follows the A2T requester guide`
            );
        }

        section.categories.forEach((category) => {
            if (category.questions.length === 0) {
                throw new Error(
                    `Failed to read the template (no questions in category ${category.name}). Make sure your template follows the A2T requester guide`
                );
            }
        });
    });

    // Read recommended actions
    const recommendedActionsSheets = allSheets.filter((sheetName) => sheetName.includes('Recommended Actions') || sheetName.includes('-RA'));
    const recommendedActionsPromises = recommendedActionsSheets.map(async (sheetName) => {
        const recommendedActionsSheet = await readXlsxFile(excelFile, { sheet: sheetName });

        // If the first row doesn't include the column headings, remove it. Requesters often forget to remove this row
        if (recommendedActionsSheet[0][0].toString().includes('EXAMPLES')) {
            recommendedActionsSheet.shift();
        }

        const recommendedActionsJson: any = convertToJson(recommendedActionsSheet, recommendedActionsExcelSchema);
        const actionRows: any[] = recommendedActionsJson.rows;

        let currentSection: any = null;
        let currentCategory: any = null;

        actionRows.every((actionRow: any) => {
            const { section, category, action } = actionRow;

            if (section?.trim() === '<Workstream 1>' || category?.trim() === '<Category 1>') {
                console.warn('It seems that the default recommended actions worksheet was not changed. Skipping adding actions');
                return false;
            }

            if ((!currentSection && !section) || (!currentCategory && !category)) {
                // Couldn't parse a column correctly. Header is probably wrong
                throw new Error(
                    `In the ${sheetName} worksheet, ensure the column headers are spelled correctly: Workstream, Category, Recommended Actions. ` +
                        'Also make sure your workstream/category cells are merged, so that every action is associated with a workstream/category'
                );
            }

            // The section column is merged. It'll only be defined for the first row it appears in
            if (section && section !== currentSection?.label) {
                currentCategory = null;
                currentSection = sections.find((s: any) => s.label === section.trim());
                if (!currentSection) {
                    throw new Error(
                        `In the ${sheetName} worksheet, can't define a recommended action for the section ${section}, as it is not defined`
                    );
                }
            }

            if (category && category !== currentCategory?.name) {
                currentCategory = currentSection.categories?.find((c: any) => c.name === category.trim());
                if (!currentCategory) {
                    throw new Error(
                        `In the ${sheetName} worksheet, can't define a recommended action for the category ${category}, as ${currentSection.label} has no category with that name`
                    );
                }
            }

            // Some facilitators put the actions for this category in a numbered list. Parse that if applicable
            if (action.startsWith('1.') || action.startsWith('1-') || action.startsWith('1 -')) {
                let actions: string[] = action.split('\n');
                actions = actions.map((a: string, index: number) => {
                    if (a.includes(`${index + 1} -`) || a.includes(`${index + 1}-`)) {
                        return a.substring(a.indexOf('-') + 1).trim();
                    } else if (a.includes(`${index + 1}.`)) {
                        return a.substring(a.indexOf('.') + 1).trim();
                    } else {
                        // Inconsistent numbering. Don't try to parse/clean the action
                        return a.trim();
                    }
                });
                actions.forEach((a: string) => {
                    if (a.length) {
                        currentCategory.prescribedActions.push({
                            text: a,
                        });
                    }
                });
            } else {
                currentCategory.prescribedActions.push({
                    text: action.trim(),
                });
            }

            return true;
        });
    });

    await Promise.all(recommendedActionsPromises);

    // Read template metadata - if present
    try {
        await readConfigurationSheet(excelFile, template);
    } catch (err) {
        console.log(err);
    }

    template.deliveryGuidance = template.deliveryGuidance || { description: '', learnMore: '' };
    template.lastUpdated = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format(new Date());
    template.defaults.questionnaireAnswers.perspective = template.section.perspectives[0].id;
    template.defaults.questionnaireAnswers.minAnswersToScore = Math.floor(numberOfQuestions * 0.8).toString();

    return template;
};

/**
 * Reads the "Configure Template" sheet and makes appropriate adjustments to the provided template
 * @param excelFile the A2T assessment type onboarding Excel file, provided by the user
 * @param template the JSON template to be configured
 * @returns Promise<void>
 */
const readConfigurationSheet = async (excelFile: File, template: Template): Promise<void> => {
    const metadataSheet = await readXlsxFile(excelFile, { sheet: 'Configure Template' });

    // Find assessment details settings. "Opportunity Selection" is the first setting
    const opportunitySelectionRowIndex: number = metadataSheet.findIndex((row) => row[0] === 'Opportunity Selection');

    if (opportunitySelectionRowIndex === -1) {
        throw new Error(
            "Failed to read the Configure Template sheet. Couldn't find the Opportunity Selection setting. " +
                'Make sure your template follows the A2T requester guide'
        );
    }

    // Set the fields that the user wants included/excluded in their assessment creation
    [
        {
            name: 'Opportunity Selection',
            metadataKey: OptionalAssessmentMetadataKey.OpportunityId,
        },
        {
            name: 'Program Engagement Selection',
            metadataKey: OptionalAssessmentMetadataKey.MapProgramEngagementId,
        },
        {
            name: 'Internal Contact',
            metadataKey: OptionalAssessmentMetadataKey.InternalContact,
        },
        {
            name: 'Workshop Date',
            metadataKey: OptionalAssessmentMetadataKey.WorkshopDate,
        },
        {
            name: 'Readout Date',
            metadataKey: OptionalAssessmentMetadataKey.ReadoutDate,
        },
    ].forEach((setting, settingNumber) => {
        const settingRow = metadataSheet[opportunitySelectionRowIndex + settingNumber];

        if (settingRow[0] !== setting.name) {
            throw new Error(
                `Failed to read the Configure Template sheet. The setting ${setting.name} is not in the expected place. ` +
                    'Make sure to use the A2T requester guide, and do not modify the order of the settings'
            );
        }

        const settingValue = settingRow[1]?.toString().trim();
        switch (settingValue) {
            case 'Required':
                template.metadataConfig.requiredMetadataKeys.push(setting.metadataKey);
                break;
            case 'Excluded':
                template.metadataConfig.excludeOptionalMetadataKeys.push(setting.metadataKey);
                break;
            default:
                // If unrecognized value, do nothing. This falls back to the default
                break;
        }
    });
};
