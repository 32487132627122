import '../ResultsReviewAll.scss';

import {
    AssessmentActions,
    AssessmentActivity,
    AssessmentCategory,
    AssessmentObservations,
    AssessmentWorkstream,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { withAuthContext } from '@amzn/awscat-react-components';
import { Box, ColumnLayout, Container, Grid, GridProps, Header, Link, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import { CSSProperties, FunctionComponent, useEffect, useMemo } from 'react';

import HeatMapHelpPanelBody from './HeatMapHelpPanelBody';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import Constants from '../../../../../common/Constants';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import AwsCatIcon from '../../../../common/Icons/AwsCatIcon';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { filterExcludedSections } from '../../Utils';

const ACTIVITIES_GRID_DEFINITION = { colspan: 6 };
const WORKSTREAMS_GRID_DEFINITION = { colspan: 6 };
const ACCELERATORS_GRID_DEFINITION = { colspan: 6 };
const SECTIONS_GRID_DEFINITION = { colspan: 6 };

type HeatmapCellProps = AppLabelsContextInterface & {
    backgroundColor: string;
    fontColor: string;
    title: string;
    phase?: string;
    observations?: AssessmentObservations;
    actions?: AssessmentActions;
};

const HeatmapCell: FunctionComponent<HeatmapCellProps> = ({
    appLabels,
    backgroundColor,
    fontColor,
    title,
    phase,
    observations,
    actions,
}): JSX.Element => {
    return (
        <div className='heatmap-cell' style={{ backgroundColor }}>
            <div className='heatmap-cell-title' style={{ color: fontColor }}>
                {title || ''}
            </div>
            <footer>
                <small>{phase || ''}</small>
                <nav>
                    {(observations?.observations?.length || 0) !== 0 && (
                        <Popover
                            dismissAriaLabel='Close'
                            header={appLabels.assessment.results.review_all.report.summary_observations}
                            size='large'
                            triggerType='custom'
                            content={observations?.observations}
                        >
                            <AwsCatIcon name='note' fill='#222' width='20' />
                        </Popover>
                    )}
                    {(actions?.actions?.length || 0) !== 0 && (
                        <Popover
                            dismissAriaLabel='Close'
                            header={appLabels.assessment.results.review_all.report.recommended_actions.recommended_actions}
                            size='large'
                            triggerType='custom'
                            content={
                                <ul>
                                    {actions?.actions?.map((action) => {
                                        return <li>{action?.text}</li>;
                                    })}
                                </ul>
                            }
                        >
                            <AwsCatIcon name='list_bullet' fill='#222' width='20' />
                        </Popover>
                    )}
                </nav>
            </footer>
        </div>
    );
};

type HeatmapGridProps = {
    backgroundColor: string;
    fontColor: string;
    title: string;
    heatmapCells: JSX.Element[];
    gridDefinition: ReadonlyArray<GridProps.ElementDefinition>;
};

const HeatmapGrid: FunctionComponent<HeatmapGridProps> = ({ backgroundColor, fontColor, title, heatmapCells, gridDefinition }): JSX.Element => {
    const spanStyle: CSSProperties = {
        backgroundColor,
        color: fontColor,
    };
    return (
        <div className='awscat-assessment-heatmap-grid-element'>
            <h4>
                <span className='workstream-state-circular-indicator' style={spanStyle}></span>
                {title}
            </h4>
            <Grid gridDefinition={gridDefinition} id='activitygrid' disableGutters className='awscat-assessment-heatmap-activity-grid'>
                {heatmapCells}
            </Grid>
        </div>
    );
};

const AssessmentHeatMapResults: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const templateDefaults = currentAssessmentOrSelectedSnapshot?.template?.defaults;
    const description = templateDefaults?.report?.heatmap?.title || '';

    const gridDefs: any = [];
    const acceleratorGridDefs: any = [];
    const activitiesGridDefs: any = [];
    let workstreamGridDefs: any = [];
    const acceleratorItems: any = [];
    const heatmapConfig = templateDefaults?.report?.heatmap;
    let heatmapObj: JSX.Element = null;

    const scoreScale = useMemo(() => {
        return new ScoreScale(templateDefaults?.questionnaireAnswers, appLabels);
    }, [appLabels, templateDefaults?.questionnaireAnswers]);

    const findWorkstream = (workstreamName: string): AssessmentWorkstream | null => {
        let foundWorkstream = null;
        currentAssessmentOrSelectedSnapshot.template?.workstreams?.forEach((workstream) => {
            if (workstream?.name === workstreamName) {
                foundWorkstream = workstream;
            }
        });
        return foundWorkstream;
    };

    if (heatmapConfig?.type === Constants.ASSESSMENT_RESULTS_TYPE_WORKSTREAMS) {
        const worktreams = currentAssessmentOrSelectedSnapshot?.template?.workstreams;
        const acceleratorWorkstreamMap: Map<string, Array<AssessmentWorkstream>> = new Map();
        worktreams?.forEach((workstream) => {
            if (workstream) {
                const currentAssessmentWorkstream = findWorkstream(workstream.name);
                let accelerator = '';
                if (currentAssessmentWorkstream.accelerator !== null || currentAssessmentWorkstream.accelerator !== undefined) {
                    accelerator = currentAssessmentWorkstream.accelerator;
                }
                if (!acceleratorWorkstreamMap.get(accelerator)) {
                    acceleratorWorkstreamMap.set(accelerator, []);
                }
                acceleratorWorkstreamMap.get(accelerator)?.push(workstream);
            }
        });

        acceleratorWorkstreamMap.forEach((workstreams: Array<AssessmentWorkstream>, accelerator: string) => {
            acceleratorGridDefs.push(ACCELERATORS_GRID_DEFINITION);
            const workstreamItems: any = [];
            workstreamGridDefs = [];
            workstreams?.forEach((workstream: AssessmentWorkstream) => {
                workstreamGridDefs.push(WORKSTREAMS_GRID_DEFINITION);
                const activityItems = [];
                workstream.activities?.forEach((activity: AssessmentActivity) => {
                    activitiesGridDefs.push(ACTIVITIES_GRID_DEFINITION);
                    if (activity) {
                        const activityBackgroundColor = scoreScale.getStratificationBackgroundColor(activity);
                        const activityFontColor = scoreScale.getStratificationFontColor(activity);
                        activityItems.push(
                            <HeatmapCell
                                appLabels={appLabels}
                                backgroundColor={activityBackgroundColor}
                                fontColor={activityFontColor}
                                title={activity?.name}
                                phase={activity?.phase}
                                observations={activity?.observations}
                                actions={activity?.actions}
                            ></HeatmapCell>
                        );
                    }
                });
                workstreamItems.push(
                    <HeatmapGrid
                        backgroundColor={scoreScale.getStratificationBackgroundColor(workstream)}
                        fontColor={scoreScale.getStratificationFontColor(workstream)}
                        title={workstream?.name}
                        heatmapCells={activityItems}
                        gridDefinition={activitiesGridDefs}
                    ></HeatmapGrid>
                );
            });
            acceleratorItems.push(
                <Container
                    header={<Header variant='h2'>{accelerator}</Header>}
                    className='awscat-assessment-heatmap-accelerator-grid-element'
                    disableContentPaddings
                >
                    <Grid id='workstreamgrid' gridDefinition={workstreamGridDefs} disableGutters>
                        {workstreamItems}
                    </Grid>
                </Container>
            );
        });
        heatmapObj = (
            <ColumnLayout columns={1}>
                <SpaceBetween direction='horizontal' size='s'>
                    <Box variant='h3'>{description}</Box>
                    <Link variant='info' id='assessment-heatmap-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </SpaceBetween>
                <Grid gridDefinition={acceleratorGridDefs} id='accelerator-grid' className='awscat-assessment-heatmap-accelerator-grid'>
                    {acceleratorItems}
                </Grid>
            </ColumnLayout>
        );
    } else {
        // For assessment types that use sections
        const excludeSections = templateDefaults?.report?.heatmap?.excludeSections || [];
        const includedSections = filterExcludedSections(
            currentAssessmentOrSelectedSnapshot?.template?.sections,
            excludeSections,
            currentAssessmentOrSelectedSnapshot
        );
        const heatmapGrids = includedSections.map((section) => {
            gridDefs.push(SECTIONS_GRID_DEFINITION);
            const heatmapCells = section?.categories?.map((category: AssessmentCategory) => {
                return (
                    <HeatmapCell
                        appLabels={appLabels}
                        backgroundColor={scoreScale.getStratificationBackgroundColor(category)}
                        fontColor={scoreScale.getStratificationFontColor(category)}
                        title={category?.name}
                        observations={category?.observations}
                        actions={category?.actions}
                    ></HeatmapCell>
                );
            });
            return (
                <HeatmapGrid
                    backgroundColor={scoreScale.getStratificationBackgroundColor(section)}
                    fontColor={scoreScale.getStratificationFontColor(section)}
                    title={section?.label}
                    heatmapCells={heatmapCells}
                    gridDefinition={[SECTIONS_GRID_DEFINITION]}
                ></HeatmapGrid>
            );
        });
        heatmapObj = (
            <ColumnLayout columns={1}>
                <Grid gridDefinition={gridDefs}>{heatmapGrids}</Grid>
            </ColumnLayout>
        );
    }

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.results.review_all.heatmap.heatmap,
                content: <HeatMapHelpPanelBody scoreScale={scoreScale} />,
            })
        );
        return cleanup;
    }, [appLabels.assessment.results.review_all.heatmap.heatmap, dispatch, scoreScale]);

    return heatmapObj;
};

export default withAppLabelsContext(withAuthContext(AssessmentHeatMapResults));
