import { A2SClient } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

// A2SClient setup
const envConfig = GetEnvironmentConfig();
const region = 'us-west-2'; // TODO: A2SClient only support us-west-2 right now, use envConfig.region in the future

A2SClient.initialized = false;
const a2sClient = new A2SClient({
    domain: envConfig.backendEnvironment,
    region,
    webClientId: envConfig.portalClientId,
    getJwtToken,
});

export default a2sClient;
