import { IsNotEmpty, MaxLength, MinLength } from 'class-validator';
export class PartnerCustomerAccount {
    @MinLength(2, {
        message: 'customer_selection_create_account_name_validation_min_char',
    })
    @MaxLength(128, {
        message: 'customer_selection_create_account_name_validation_max_char',
    })
    accountName: string;

    @IsNotEmpty({
        message: 'customer_selection_create_account_reference_id_validation_min_char',
    })
    @MaxLength(128, {
        message: 'customer_selection_create_account_reference_id_validation_max_char',
    })
    referenceId: string;
}
