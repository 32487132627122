import { IsEmail, IsNotEmpty } from 'class-validator';

export class ManageSponsorUserInput {
    @IsEmail(undefined, {
        message: 'assessment_pre_event_sponsor_email_validation_message',
    })
    sponsorEmail: string;

    @IsNotEmpty({
        message: 'assessment_pre_event_due_date_validation_message',
    })
    dueDate: string;
}
