import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import AssessmentDetailsBody from './body/AssessmentDetailsBody';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { useAppSelector } from '../../redux/hooks';

interface AssessmentDetailsModalProps extends AppLabelsContextInterface {
    setVisible: (boolean) => void;
    visible: boolean;
    currentPromptTitle: string;
}

const AssessmentDetailsModal: FunctionComponent<AssessmentDetailsModalProps> = ({
    appLabels,
    setVisible,
    visible,
    currentPromptTitle,
}): JSX.Element => {
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            closeAriaLabel={appLabels.user_actions.close_dialog}
            size='large'
            className='assessment-details-modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='primary' onClick={() => setVisible(false)}>
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={currentPromptTitle}
        >
            <AssessmentDetailsBody currentAssessmentId={currentAssessmentOrSelectedSnapshot.id} changeUrl={false} updateHelpPanel={false} />
        </Modal>
    );
};

export default withAppLabelsContext(AssessmentDetailsModal);
