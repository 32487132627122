import {
    CreateRecommendationAssignmentsCommand,
    CreateRecommendationAssignmentsCommandOutput,
    RecommendationOutput,
    RecommendationResourceAssignment,
    RecommendationType,
    Status,
    UpdateRecommendationAssignmentsCommand,
    UpdateRecommendationAssignmentsCommandOutput,
} from '@amzn/aws-assessment-recommendation-service-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Alert, Box, Button, DatePicker, FormField, Input, Modal, Select, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getEffortOptions, getPriorityOptions, getProgressStatusOptions } from './RecommendationConstants';
import { createRecommendationsSuccess, updateRecommendationsSuccess } from './RecommendationsSlice';
import { getRecommendationServiceClient } from '../../../../../../api/recommendationService/RecommendationServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import Constants from '../../../../../../common/Constants';
import rumClient from '../../../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';

export interface CreateOrEditRecommendationProps {
    isCreateOrEdit: 'create' | 'edit';
    recommendationToCreateOrEdit: RecommendationOutput; // read-only: initial state of a recommendation
    onDismiss: () => void;
    assessmentId: string;
    assessmentType: string;
    categoryId: string;
}

const CreateOrEditRecommendationModal = (props: CreateOrEditRecommendationProps & AppLabelsContextInterface & AuthContextInterface): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const resourceId = `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${props.assessmentId}`;

    const [recommendationToSave, setRecommendationToSave] = useState<RecommendationResourceAssignment>({
        recommendationId: props.recommendationToCreateOrEdit?.recommendationId,
        resourceId,
        referenceId: props.categoryId,
        recommendationType: props.isCreateOrEdit === 'create' ? RecommendationType.CUSTOM : props.recommendationToCreateOrEdit?.recommendationType,
        verbiage: props.recommendationToCreateOrEdit?.verbiage || '',
        benefit: props.recommendationToCreateOrEdit?.benefit || '',
        effort: props.recommendationToCreateOrEdit?.effort,
        priority: props.recommendationToCreateOrEdit?.priority,
        targetDate: props.recommendationToCreateOrEdit?.targetDate,
        progressStatus: props.recommendationToCreateOrEdit?.progressStatus,
        actionOwner: props.recommendationToCreateOrEdit?.actionOwner,
    });

    const [verbiageErrorMessage, setVerbiageErrorMessage] = useState('');
    const [benefitErrorMessage, setBenefitErrorMessage] = useState('');
    const [actionOwnerErrorMessage, setActionOwnerErrorMessage] = useState('');

    const onConfirm = useCallback(async () => {
        setIsLoading(true);
        const rsClient = getRecommendationServiceClient();
        try {
            if (props.isCreateOrEdit === 'create') {
                const createRecommendationOutput: CreateRecommendationAssignmentsCommandOutput = await rsClient.send(
                    new CreateRecommendationAssignmentsCommand({
                        recommendationAssignments: [recommendationToSave],
                        resourceId,
                        targetId: `${Constants.RECOMMENDATIONS_TARGET_ID_PREFIX}${props.assessmentType}`,
                    })
                );
                const recommendationId = createRecommendationOutput?.recommendationIds?.[0];
                dispatch(
                    createRecommendationsSuccess({
                        recommendationsToCreate: [
                            {
                                ...recommendationToSave,
                                recommendationId,
                                status: Status.ACTIVE,
                                recommendationOwner: props.auth.getUserInfo()?.userId,
                            },
                        ],
                    })
                );
            } else {
                const updateRecommendationOutput: UpdateRecommendationAssignmentsCommandOutput = await rsClient.send(
                    new UpdateRecommendationAssignmentsCommand({
                        recommendationAssignments: [recommendationToSave],
                        resourceId,
                    })
                );
                const recommendationId = updateRecommendationOutput?.recommendationIds?.[0];
                dispatch(
                    updateRecommendationsSuccess({
                        recommendationsToUpdate: [{ ...recommendationToSave, recommendationId }],
                    })
                );
            }
            setErrorMessage('');
            setIsLoading(false);
            props.onDismiss();
        } catch (err) {
            rumClient.recordError(err);
            setErrorMessage((err as Error)?.message);
            setIsLoading(false);
        }
    }, [dispatch, props, recommendationToSave, resourceId]);
    const effortOptions = getEffortOptions(props.appLabels);
    const priorityOptions = getPriorityOptions(props.appLabels);
    const progressStatusOptions = getProgressStatusOptions(props.appLabels);
    return (
        <Modal
            onDismiss={props.onDismiss}
            size='large'
            visible
            header={`${
                props.isCreateOrEdit === 'create'
                    ? props.appLabels.assessment.results.review_all.report.recommended_actions.create_custom_recommendation
                    : props.appLabels.assessment.results.review_all.report.recommended_actions.edit_recommendation
            }`}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={props.onDismiss} loading={isLoading}>
                            {props.appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={onConfirm}
                            loading={isLoading}
                            disabled={!recommendationToSave.verbiage || !!verbiageErrorMessage || !!benefitErrorMessage || !!actionOwnerErrorMessage}
                        >
                            {props.appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='l'>
                <FormField label={`${props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action}*`}>
                    <Textarea
                        disabled={recommendationToSave.recommendationType !== RecommendationType.CUSTOM}
                        value={recommendationToSave.verbiage}
                        onChange={(event) => {
                            const value = event.detail.value;
                            setVerbiageErrorMessage(
                                value?.length > 0 && value?.length < Constants.RECOMMENDATION_VERBIAGE_MAX_LENGTH
                                    ? ''
                                    : `${props.appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_VERBIAGE_MIN_LENGTH} ${props.appLabels.common.and} ${Constants.RECOMMENDATION_VERBIAGE_MAX_LENGTH}`
                            );
                            setRecommendationToSave({ ...recommendationToSave, verbiage: value });
                        }}
                        invalid={!!verbiageErrorMessage}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_priority}>
                    <Select
                        options={priorityOptions}
                        selectedOption={priorityOptions.find((option) => option.value === recommendationToSave.priority)}
                        onChange={(event) => setRecommendationToSave({ ...recommendationToSave, priority: event.detail.selectedOption.value })}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_effort}>
                    <Select
                        options={effortOptions}
                        selectedOption={effortOptions.find((option) => option.value === recommendationToSave.effort)}
                        onChange={(event) => setRecommendationToSave({ ...recommendationToSave, effort: event.detail.selectedOption.value })}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_target_date}>
                    <DatePicker
                        value={recommendationToSave.targetDate}
                        onChange={(event) => {
                            setRecommendationToSave({ ...recommendationToSave, targetDate: event.detail.value });
                        }}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_benefit}>
                    <Textarea
                        value={recommendationToSave.benefit}
                        onChange={(event) => {
                            const value = event.detail.value;
                            setBenefitErrorMessage(
                                value?.length < Constants.RECOMMENDATION_BENEFIT_MAX_LENGTH
                                    ? ''
                                    : `${props.appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_BENEFIT_MIN_LENGTH} ${props.appLabels.common.and} ${Constants.RECOMMENDATION_BENEFIT_MAX_LENGTH}`
                            );
                            setRecommendationToSave({ ...recommendationToSave, benefit: value });
                        }}
                        invalid={!!benefitErrorMessage}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_progress}>
                    <Select
                        options={progressStatusOptions}
                        selectedOption={progressStatusOptions.find((option) => option.value === recommendationToSave.progressStatus)}
                        onChange={(event) => setRecommendationToSave({ ...recommendationToSave, progressStatus: event.detail.selectedOption.value })}
                    />
                </FormField>
                <FormField label={props.appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action_owner}>
                    <Input
                        value={recommendationToSave.actionOwner}
                        onChange={(event) => {
                            const value = event.detail.value;
                            setActionOwnerErrorMessage(
                                value?.length < Constants.RECOMMENDATION_ACTION_OWNER_MAX_LENGTH
                                    ? ''
                                    : `${props.appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_ACTION_OWNER_MIN_LENGTH} ${props.appLabels.common.and} ${Constants.RECOMMENDATION_ACTION_OWNER_MAX_LENGTH}`
                            );
                            setRecommendationToSave({ ...recommendationToSave, actionOwner: value });
                        }}
                        invalid={!!actionOwnerErrorMessage}
                    />
                </FormField>
                {verbiageErrorMessage ? <Alert type='error' header={verbiageErrorMessage} /> : null}
                {benefitErrorMessage ? <Alert type='error' header={benefitErrorMessage} /> : null}
                {actionOwnerErrorMessage ? <Alert type='error' header={actionOwnerErrorMessage} /> : null}
                {errorMessage ? (
                    <Alert
                        type='error'
                        header={
                            props.isCreateOrEdit === 'create'
                                ? props.appLabels.assessment.results.review_all.report.recommended_actions.failed_create_custom_recommendation
                                : props.appLabels.assessment.results.review_all.report.recommended_actions.failed_edit_recommendation
                        }
                    >
                        {errorMessage}
                    </Alert>
                ) : null}
            </SpaceBetween>
        </Modal>
    );
};

export default withAuthContext(withLocalizationContext(withAppLabelsContext(CreateOrEditRecommendationModal)));
