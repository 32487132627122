import './CustomerDetails.scss';

import { AssessmentFeature, CustomerAccountInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { ListAccountsCommand, UpdateAccountCommand } from '@amzn/customer-account-service-typescript-client';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ValidationError, validate } from 'class-validator';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import CustomerDetailsPanel from './CustomerDetailsPanel';
import { CustomerViewModel } from './CustomerViewModel';
import { selectCustomerForUpdate, updatePartnerCustomerSuccess } from './PartnerCustomersSlice';
import a2sApolloClient from '../../api/a2s/ApolloClient';
import { GET_PARTNER_CUSTOMER_ACCOUNTS, UPDATE_PARTNER_CUSTOMER_ACCOUNT } from '../../api/a2s/ApolloQueries';
import { getCustomerAccountServiceLambdaClient } from '../../api/cas/CustomerAccountServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import rumClient from '../../common/monitoring/RumClient';
import { PartnerCustomerAccount } from '../../models/PartnerCustomerAccount';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../common/RequestStatusFlashbar';
import { openAppHelpPanel } from '../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface MatchParam {
    customerAccountId: string;
}

type CustomerUpdateProp = AppLabelsContextInterface & AuthContextInterface & RouteComponentProps<MatchParam>;
const CustomerUpdate: FunctionComponent<CustomerUpdateProp> = ({ appLabels, match }): JSX.Element => {
    const history = useHistory();
    const { customerAccountId } = match.params;
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const dispatch = useAppDispatch();
    const selectedCustomerForUpdate = useAppSelector((state) => state.partnerCustomerState.selectedCustomerForUpdate);
    const [customerAccountInputState, setCustomerAccountInputState] = useState<CustomerAccountInput>({
        accountName: selectedCustomerForUpdate?.accountName || '',
        referenceId: selectedCustomerForUpdate?.referenceId || '',
    });

    const [casUpdateInProgress, setCasUpdateInProgress] = useState(false);

    const stateChanged = useCallback(() => {
        return (
            customerAccountInputState.accountName !== selectedCustomerForUpdate?.accountName ||
            customerAccountInputState.referenceId !== selectedCustomerForUpdate?.referenceId
        );
    }, [customerAccountInputState.accountName, customerAccountInputState.referenceId, selectedCustomerForUpdate]);

    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [a2sGetPartnerCustomerAccounts] = useLazyQuery(GET_PARTNER_CUSTOMER_ACCOUNTS, {
        client: a2sApolloClient,
        fetchPolicy: 'network-only',
        variables: {
            filter: { ids: [customerAccountId] },
        },
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.customer_selection.error_loading_customers,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const response = data.getCustomerAccounts;
            const customers = response?.items;
            if (customers?.length > 0) {
                const { accountName, referenceId } = customers[0];
                dispatch(selectCustomerForUpdate({ ...customers[0], accountName: customers[0].accountName }));
                setCustomerAccountInputState({ accountName, referenceId });
            }
        },
    });

    const getPartnerCustomerAccount = useCallback(async () => {
        if (customerAccountInputState.accountName) {
            // skip when we already have account for selected sustomer
            return;
        }
        if (!customerAccountId) {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.customer_selection.error_loading_customers,
                messageContent: appLabels.customer_selection.error_invalid_customer_id,
            });
            return;
        }
        if (cas2IsEnabled) {
            try {
                const casClient = await getCustomerAccountServiceLambdaClient();
                const response = await casClient.send(new ListAccountsCommand({ accountIdList: [customerAccountId] }));
                if (response?.accounts && response?.accounts.length) {
                    const customers = response.accounts.map((a) => {
                        return {
                            id: a.accountId,
                            organizationId: a.orgId,
                            referenceId: a.externalRefId,
                            accountName: a.accountName,
                            ownerId: a.ownerId,
                            createdBy: a.createdBy,
                            updatedBy: a.updatedBy,
                        } as CustomerViewModel;
                    });
                    const { accountName, referenceId } = customers[0];
                    dispatch(selectCustomerForUpdate({ ...customers[0], accountName: customers[0].accountName }));
                    setCustomerAccountInputState({ accountName, referenceId });
                }
            } catch (error: any) {
                setRequestStatus({
                    loading: false,
                    messageType: FlashType.error,
                    messageHeader: appLabels.customer_selection.error_loading_customers,
                    messageContent: error.message,
                });
                rumClient.recordError(error);
            }
        } else {
            a2sGetPartnerCustomerAccounts();
        }
    }, [
        a2sGetPartnerCustomerAccounts,
        appLabels.customer_selection.error_invalid_customer_id,
        appLabels.customer_selection.error_loading_customers,
        cas2IsEnabled,
        customerAccountId,
        customerAccountInputState.accountName,
        dispatch,
    ]);

    const [a2sUpdateCustomerAccount, { loading }] = useMutation(UPDATE_PARTNER_CUSTOMER_ACCOUNT, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const customer = data.updateCustomerAccount;
            if (customer) {
                dispatch(updatePartnerCustomerSuccess({ ...customer, accountName: customer.accountName }));
                // successfully updated customer, exit update page
                onCancel();
            }
        },
        onError: (error) => {
            setRequestStatus({
                ...requestStatus,
                loading,
                messageType: FlashType.error,
                messageHeader: appLabels.customer_selection.update.error_updating_customer,
                messageContent: error.message ?? '',
            });
            rumClient.recordError(error);
        },
    });

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    const onStateChange = useCallback(
        (subStateValue: Partial<CustomerAccountInput>) => {
            const newState = {
                ...customerAccountInputState,
                ...subStateValue,
            };
            setCustomerAccountInputState(newState);
        },
        [customerAccountInputState]
    );

    const updateCustomerAccount = useCallback(async () => {
        if (cas2IsEnabled) {
            try {
                const casClient = await getCustomerAccountServiceLambdaClient();
                setCasUpdateInProgress(true);
                const response = await casClient.send(
                    new UpdateAccountCommand({
                        accountId: customerAccountId,
                        externalRefId: customerAccountInputState.referenceId,
                        accountName: customerAccountInputState.accountName,
                    })
                );
                setCasUpdateInProgress(false);
                if (response?.$metadata && response?.$metadata.httpStatusCode === 200) {
                    const customer: CustomerViewModel = {
                        id: response?.accountId,
                        accountName: customerAccountInputState.accountName,
                        referenceId: customerAccountInputState.referenceId,
                        createdBy: '',
                        updatedBy: '',
                        website: '',
                        industry: '',
                        territory: '',
                        geo: '',
                        region: '',
                        segment: '',
                        organizationId: '',
                        ownerId: '',
                    };
                    dispatch(updatePartnerCustomerSuccess(customer));
                    // successfully updated customer, exit update page
                    onCancel();
                } else {
                    throw new Error(`Unable to update customer account httpStatusCode=${response?.$metadata.httpStatusCode}`);
                }
            } catch (error: any) {
                setCasUpdateInProgress(false);
                rumClient.recordError(error);
                setRequestStatus({
                    ...requestStatus,
                    loading,
                    messageType: FlashType.error,
                    messageHeader: appLabels.customer_selection.update.error_updating_customer,
                    messageContent: error.message ?? '',
                });
            }
        } else {
            a2sUpdateCustomerAccount({
                variables: {
                    id: customerAccountId,
                    input: customerAccountInputState,
                },
            });
        }
    }, [
        a2sUpdateCustomerAccount,
        appLabels.customer_selection.update.error_updating_customer,
        cas2IsEnabled,
        customerAccountId,
        customerAccountInputState,
        dispatch,
        loading,
        onCancel,
        requestStatus,
    ]);

    const onUpdate = useCallback(async () => {
        try {
            const customerAccountDetails: PartnerCustomerAccount = Object.assign(new PartnerCustomerAccount(), customerAccountInputState);
            const validationErrors = await validate(customerAccountDetails);
            setValidationErrors(validationErrors);
            if (!(validationErrors && validationErrors.length)) {
                updateCustomerAccount();
            }
        } catch (error: any) {
            rumClient.recordError(error);
            setRequestStatus({
                messageType: FlashType.error,
                loading: false,
                messageHeader: appLabels.customer_selection.update.error_updating_customer,
                messageContent: error.message ?? '',
            });
            setCasUpdateInProgress(false);
        }
    }, [appLabels.customer_selection.update.error_updating_customer, updateCustomerAccount, customerAccountInputState]);

    useEffect(() => {
        getPartnerCustomerAccount();
    }, [getPartnerCustomerAccount]);

    return (
        <div className='awscat-customer-details'>
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.customer_selection.update.title}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' id='customer-update-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <div className='customer-update-form'>
                <SpaceBetween direction='vertical' size='l'>
                    <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
                    <CustomerDetailsPanel
                        customerAccountId={customerAccountId}
                        validationErrors={validationErrors}
                        state={customerAccountInputState}
                        onChange={onStateChange}
                    />
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button variant='link' onClick={onCancel}>
                                {appLabels.user_actions.cancel}
                            </Button>
                            <Button variant='primary' disabled={!stateChanged()} loading={casUpdateInProgress || loading} onClick={onUpdate}>
                                {appLabels.user_actions.save}
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(CustomerUpdate));
