import { FlashContextInterface, withFlashContext } from '@amzn/awscat-react-components';
import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../common/AppLabelsContext';

function FlashMessages(props: FlashContextInterface & AppLabelsContextInterface) {
    const flashes = props.flash.flashes;
    const items: FlashbarProps.MessageDefinition[] = flashes.map((flash) => {
        const id = flash.id;
        const item: FlashbarProps.MessageDefinition = {
            type: flash.type,
            dismissible: true,
            dismissLabel: props.appLabels.user_actions.dismiss,
            onDismiss: () => {
                props.flash.remove(id);
            },
            header: flash.header,
            content: flash.content,
        };
        return item;
    });
    return <Flashbar items={items} />;
}

export default withFlashContext(withAppLabelsContext(FlashMessages));
