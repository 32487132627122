import { gql } from '@apollo/client';

export const UPLOAD_TEMPLATE = gql`
    mutation uploadTemplate($input: UploadTemplateInput!) {
        uploadTemplate(input: $input)
    }
`;

export const CREATE_ASSESSMENT_TYPE = gql`
    mutation createAssessmentType($input: CreateAssessmentTypeInput!) {
        createAssessmentType(input: $input) {
            templateId
        }
    }
`;

export const CREATE_DRAFT = gql`
    mutation createDraftMutation($input: CreateTemplateInput!) {
        createTemplate(input: $input) {
            authorizedAssessmentCreators
            coowners
            descriptors {
                name
            }
            isPublishedForType
            isPublishedForVersion
            localeManagers {
                locale
                localeManagers
            }
            owner
            templateId
            type
            version
        }
    }
`;

// Currently, return values aren't used
export const PUBLISH_TEMPLATE = gql`
    mutation publishTemplateMutation($input: PublishTemplateInput!) {
        publishTemplate(input: $input) {
            templateId
        }
    }
`;

export const CREATE_MODULE = gql`
    mutation createModuleMutation($input: CreateModuleInput!) {
        createModule(input: $input) {
            moduleId
            descriptors {
                name
                description
                helpContext
                hint
                locale
                descriptorId
            }
            parentModule {
                moduleId
            }
            prompts {
                promptId
            }
            childModules {
                moduleId
            }
        }
    }
`;

export const DELETE_MODULE = gql`
    mutation deleteModuleMutation($input: DeleteModuleInput!) {
        deleteModule(input: $input)
    }
`;

export const CREATE_PROMPT = gql`
    mutation createPromptMutation($input: CreatePromptInput!) {
        createPrompt(input: $input) {
            promptId
            templateId
            a2sId
            descriptors {
                name
                description
                helpContext
                hint
                descriptorId
                locale
            }
            responseType
        }
    }
`;

export const UPDATE_PROMPT = gql`
    mutation updatePromptMutation($input: UpdatePromptInput!) {
        updatePrompt(input: $input) {
            promptId
            templateId
            a2sId
            descriptors {
                descriptorId
                name
                description
                helpContext
                hint
                locale
            }
            responseType
            selectionDescription {
                descriptorId
                description
                locale
            }
            selections {
                selectionId
                selectionLabel {
                    descriptorId
                    description
                    locale
                }
            }
            ratingGuide {
                guide1 {
                    description
                    locale
                    descriptorId
                }
                guide2 {
                    description
                    locale
                    descriptorId
                }
                guide3 {
                    description
                    locale
                    descriptorId
                }
                guide4 {
                    description
                    locale
                    descriptorId
                }
                guide5 {
                    description
                    locale
                    descriptorId
                }
            }
        }
    }
`;

export const DELETE_PROMPT = gql`
    mutation deletePromptMutation($input: DeletePromptInput!) {
        deletePrompt(input: $input)
    }
`;

export const UPDATE_DESCRIPTOR = gql`
    mutation updateDescriptorMutation($input: UpdateDescriptorInput!) {
        updateDescriptor(input: $input) {
            descriptorId
            locale
            name
            description
            helpContext
            abbreviation
        }
    }
`;

export const ADD_LOCALE = gql`
    mutation addLocaleMutation($input: AddLocaleInput!) {
        addLocale(input: $input)
    }
`;

export const UNSHARE_TEMPLATE = gql`
    mutation unshareTemplateMutation($input: UnshareTemplateInput!) {
        unshareTemplate(input: $input)
    }
`;

export const SHARE_TEMPLATE = gql`
    mutation shareTemplateMutation($input: ShareTemplateInput!) {
        shareTemplate(input: $input)
    }
`;

export const UNSHARE_ASSESSMENT_TYPE = gql`
    mutation unshareAssessmentTypeMutation($input: UnshareAssessmentTypeInput!) {
        unshareAssessmentType(input: $input)
    }
`;

export const SHARE_ASSESSMENT_TYPE = gql`
    mutation shareAssessmentTypeMutation($input: ShareAssessmentTypeInput!) {
        shareAssessmentType(input: $input)
    }
`;

export const CREATE_OR_UPDATE_DEFAULT = gql`
    mutation createOrUpdateDefaultMutation($input: CreateOrUpdateDefaultInput!) {
        createOrUpdateDefault(input: $input) {
            key
            value
            valueType
            descriptors {
                abbreviation
                description
                descriptorId
                helpContext
                locale
                name
            }
        }
    }
`;
