import { RecommendationOutput, RecommendationType, Status } from '@amzn/aws-assessment-recommendation-service-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RecommendationsState {
    loading: boolean;
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] };
    errorMessage: string;
}

export const initialState: RecommendationsState = {
    loading: false,
    refIdToRecommendationsMap: {},
    errorMessage: '',
};

interface CreateRecommendationsSuccessPayload {
    recommendationsToCreate: RecommendationOutput[];
}

interface UpdateRecommendationsSuccessPayload {
    recommendationsToUpdate: RecommendationOutput[];
}

interface DeleteRecommendationsSuccessPayload {
    recommendationsToDelete: RecommendationOutput[];
}

export const recommendationsSlice = createSlice({
    name: 'recommendationsSlice',
    initialState,
    reducers: {
        loadRecommendationsSuccess: (state, action: PayloadAction<RecommendationOutput[]>) => {
            const refIdToRecommendationsMap = {};
            action.payload?.forEach((recommendation) => {
                const refId = recommendation.referenceId || ''; // nullish refId means that the recommendation is tied to the entire assessment
                refIdToRecommendationsMap[refId] = [...(refIdToRecommendationsMap[refId] || []), recommendation];
            });
            state.refIdToRecommendationsMap = refIdToRecommendationsMap;
            state.errorMessage = '';
            state.loading = false;
        },
        loadingRecommendations: (state) => {
            state.loading = true;
            state.errorMessage = '';
        },
        loadRecommendationsFailure: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.refIdToRecommendationsMap = {};
            state.loading = false;
        },
        createRecommendationsSuccess: (state, action: PayloadAction<CreateRecommendationsSuccessPayload>) => {
            const refIdToRecommendationsMap = { ...state.refIdToRecommendationsMap };
            action.payload?.recommendationsToCreate.forEach((recommendationToCreate) => {
                const refId = recommendationToCreate?.referenceId || '';
                if (recommendationToCreate.recommendationType === RecommendationType.CUSTOM) {
                    // For a custom recommendation, directly add it to the list
                    refIdToRecommendationsMap[refId] = [...(refIdToRecommendationsMap[refId] || []), recommendationToCreate];
                } else {
                    // For an in-tool recommendation, set status to active
                    const inToolRecommendation = refIdToRecommendationsMap[refId]?.find(
                        (recommendation) => recommendation.recommendationId === recommendationToCreate.recommendationId
                    );
                    if (inToolRecommendation) {
                        inToolRecommendation.status = Status.ACTIVE;
                    }
                }
            });
            state.refIdToRecommendationsMap = refIdToRecommendationsMap;
            state.loading = false;
        },
        updateRecommendationsSuccess: (state, action: PayloadAction<UpdateRecommendationsSuccessPayload>) => {
            const refIdToRecommendationsMap = { ...state.refIdToRecommendationsMap };
            action.payload?.recommendationsToUpdate.forEach((recommendationToUpdate) => {
                const refId = recommendationToUpdate?.referenceId || '';
                const recommendation = refIdToRecommendationsMap[refId]?.find(
                    (recommendation) => recommendation.recommendationId === recommendationToUpdate.recommendationId
                );
                if (recommendation) {
                    Object.assign(recommendation, { ...recommendationToUpdate });
                }
            });
            state.refIdToRecommendationsMap = refIdToRecommendationsMap;
            state.loading = false;
        },
        deleteRecommendationsSuccess: (state, action: PayloadAction<DeleteRecommendationsSuccessPayload>) => {
            const refIdToRecommendationsMap = { ...state.refIdToRecommendationsMap };
            action.payload?.recommendationsToDelete?.forEach((recommendationToDelete) => {
                const refId = recommendationToDelete?.referenceId || '';
                const recommendationToDeleteIndex = refIdToRecommendationsMap[refId]?.findIndex(
                    (recommendation) => recommendation.recommendationId === recommendationToDelete.recommendationId
                );
                if (recommendationToDeleteIndex >= 0 && recommendationToDelete.recommendationType === RecommendationType.CUSTOM) {
                    // For a custom recommendation, directly remove it from the list
                    refIdToRecommendationsMap[refId].splice(recommendationToDeleteIndex, 1);
                } else if (recommendationToDeleteIndex >= 0) {
                    // For an in-tool recommendation, set status to inactive
                    refIdToRecommendationsMap[refId][recommendationToDeleteIndex].status = Status.INACTIVE;
                }
            });
            state.refIdToRecommendationsMap = refIdToRecommendationsMap;
            state.loading = false;
        },
    },
});

export const {
    loadRecommendationsSuccess,
    loadRecommendationsFailure,
    loadingRecommendations,
    createRecommendationsSuccess,
    updateRecommendationsSuccess,
    deleteRecommendationsSuccess,
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
