import {
    AssessmentActivity,
    AssessmentCategory,
    AssessmentPrompt,
    AssessmentResponseBoolean,
    AssessmentResponseDate,
    AssessmentResponseMultiSelection,
    AssessmentResponseNumber,
    AssessmentResponseRating,
    AssessmentResponseSingleSelection,
    AssessmentResponseText,
    AssessmentResponseTypes,
    AssessmentSection,
    AssessmentWorkstream,
} from '@amzn/awscat-aws-assessment-service-typescript-client';

import Constants from '../../../../common/Constants';
import { notEmpty } from '../../../../common/Utils';
import { ScoredItem } from '../../../../common/score/ScoreUtils';
import { PromptId } from '../../AssessmentPromptViewModel';
import { AssessmentViewModel } from '../../AssessmentViewModel';
import { generateAssessmentPromptUrl } from '../../Utils';
import { Comment } from '../../facilitate/body/CommentBox';

export interface Named {
    name: string | null | undefined;
}

export interface Typed {
    type: string;
}

export interface YesOrNo {
    yes: boolean | null;
}

export interface NumberItem {
    value: number | null;
}

export interface SingleSelection {
    value: string | null;
}

export interface MultiSelection {
    value: string[] | null;
}

export interface DateSelection {
    value: string | null;
}

export interface SelectedOption {
    selectionLabel: string;
    selectionId: string;
}

export interface AssessmentPromptAndResponse {
    index: number;
    promptPrefix: string;
    promptId: string;
    promptText: string;
    promptUrl: string;
    type: string;
    categoryName: string | null;
    facilitatorComments: string;
    participantComments: Comment[];
    display: boolean;
}

export interface AssessmentScoredQuestion extends ScoredItem, AssessmentPromptAndResponse, Typed {
    type: 'scored';
}

export interface AssessmentBooleanQuestion extends AssessmentPromptAndResponse, YesOrNo, Typed {
    type: 'boolean';
}
export interface AssessmentNumberQuestion extends AssessmentPromptAndResponse, NumberItem, Typed {
    type: 'number';
}

export interface AssessmentSingleSelectQuestion extends AssessmentPromptAndResponse, SingleSelection, Typed {
    type: 'select';
    selectionOptions: SelectedOption[];
}

export interface AssessmentMultiSelectQuestion extends AssessmentPromptAndResponse, MultiSelection, Typed {
    type: 'multiSelect';
    selectionOptions: SelectedOption[];
}

export interface AssessmentDateQuestion extends AssessmentPromptAndResponse, DateSelection, Typed {
    type: 'date';
}

export interface AssessmentFreeQuestion extends AssessmentPromptAndResponse, Typed {
    type: 'free';
}

export interface RecommendedAction {
    id: string;
    text: string;
    priority: string | null;
    effort: string | null;
    targetDate: string | null;
    owner: string | null;
}
export interface PrescribedAction {
    text: string;
}
export interface AssessmentQuestionVector extends Named, ScoredItem {
    questions: (AssessmentScoredQuestion | AssessmentFreeQuestion | AssessmentBooleanQuestion)[];
    summaryObservations: string;
    recommendedActions: RecommendedAction[];
    phase: string | null | undefined;
    display: boolean;
    prescribedActions: PrescribedAction[];
    assessmentId: string;
    categoryId: string | null | undefined;
}

export interface AssessmentFacet extends Named, ScoredItem {
    questionVectors: AssessmentQuestionVector[];
    vectorSpaceName: string;
    display: boolean;
}

export interface AssessmentFacetSpace extends Typed {
    assessmentFacets: AssessmentFacet[];
}

export interface Assessment {
    assessmentFacetSpace: AssessmentFacetSpace;
}

export type ParticipantsResponseMap = Map<PromptId, Comment[]>;

// Helper functions to convert assessment prompt to question in report
export const convertAssessmentPromptToQuestion = (
    assessmentId: string,
    snapshotId: string,
    prompt: AssessmentPrompt,
    promptMetadataProvider: AssessmentMetadataProvider,
    participantComments: Comment[]
):
    | AssessmentFreeQuestion
    | AssessmentScoredQuestion
    | AssessmentBooleanQuestion
    | AssessmentNumberQuestion
    | AssessmentSingleSelectQuestion
    | AssessmentMultiSelectQuestion
    | AssessmentDateQuestion
    | null => {
    const promptId = prompt.id;
    const promptUrl = generateAssessmentPromptUrl(assessmentId, snapshotId, promptId || '');

    const index = promptMetadataProvider.getPromptNumber(prompt.id);
    const category = promptMetadataProvider.getCategoryForPrompt(prompt.id);
    const promptPrefix = promptMetadataProvider.getPromptPrefix();
    const text = prompt.label?.text;
    if (prompt.responseType === AssessmentResponseTypes.RATING) {
        const assessmentResponse = prompt.response as AssessmentResponseRating;
        const facilitatorComments = assessmentResponse?.comments;
        return {
            index: index,
            type: 'scored',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            score: assessmentResponse?.intValue,
            display: true,
        } as AssessmentScoredQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.TEXT) {
        const assessmentResponse = prompt.response as AssessmentResponseText;
        const facilitatorComments = assessmentResponse?.stringValue;
        return {
            index: index,
            type: 'free',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
        } as AssessmentFreeQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.NUMBER) {
        const assessmentResponse = prompt.response as AssessmentResponseNumber;
        const facilitatorComments = assessmentResponse?.comments;
        const value = assessmentResponse?.intValue;
        return {
            index: index,
            type: 'number',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
            value,
        } as AssessmentNumberQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.YES_NO) {
        const assessmentResponse = prompt.response as AssessmentResponseBoolean;
        const facilitatorComments = assessmentResponse?.comments;
        return {
            index: index,
            type: 'boolean',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            yes: assessmentResponse?.booleanValue,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
        } as AssessmentBooleanQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.SINGLE_SELECTION) {
        const assessmentResponse = prompt.response as AssessmentResponseSingleSelection;
        const facilitatorComments = assessmentResponse?.comments;
        const value = assessmentResponse?.singleSelectValue;
        return {
            index: index,
            type: 'select',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
            value,
            selectionOptions: prompt.responseSelections.selections,
        } as AssessmentSingleSelectQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.MULTI_SELECTION) {
        const assessmentResponse = prompt.response as AssessmentResponseMultiSelection;
        const facilitatorComments = assessmentResponse?.comments;
        const value = assessmentResponse?.multiSelectValues;
        return {
            index: index,
            type: 'multiSelect',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
            value,
            selectionOptions: prompt.responseSelections.selections,
        } as AssessmentMultiSelectQuestion;
    } else if (prompt.responseType === AssessmentResponseTypes.DATE_VALUE) {
        const assessmentResponse = prompt.response as AssessmentResponseDate;
        const facilitatorComments = assessmentResponse?.comments;
        const value = assessmentResponse?.dateValue;
        return {
            index: index,
            type: 'date',
            categoryName: category,
            promptPrefix,
            promptId: prompt.id,
            promptText: text,
            promptUrl: promptUrl,
            facilitatorComments: facilitatorComments,
            participantComments,
            display: true,
            value,
        } as AssessmentDateQuestion;
    }
    return null;
};

export const convertAssessmentActivityToQuestionVector = (
    assessmentId: string,
    snapshotId: string,
    activity: AssessmentActivity,
    promptMetadataProvider: AssessmentMetadataProvider,
    participantsResponseMap?: ParticipantsResponseMap
): AssessmentQuestionVector => {
    const name = activity?.name;
    const questions =
        activity?.prompts
            ?.filter(notEmpty)
            .map((prompt) =>
                convertAssessmentPromptToQuestion(assessmentId, snapshotId, prompt, promptMetadataProvider, participantsResponseMap?.get(prompt.id))
            ) || [];
    const summaryObservations = activity?.observations?.observations || '';
    const recommendedActions = activity?.actions?.actions?.map((action) => {
        return {
            text: action?.text,
            priority: action?.priority,
            targetDate: action?.targetDate,
            owner: action?.owner,
        } as RecommendedAction;
    });
    const phase = activity?.phase;
    const prescribedActions = activity?.prescribedActions?.map((action) => {
        return {
            text: action?.text,
        } as PrescribedAction;
    });
    const categoryId = activity.id;
    return {
        name: name,
        questions: questions,
        score: activity.score,
        booleanDistribution: activity.booleanDistribution,
        summaryObservations: summaryObservations,
        recommendedActions: recommendedActions,
        phase: phase,
        display: true,
        prescribedActions: prescribedActions,
        assessmentId: assessmentId,
        categoryId: categoryId,
    } as AssessmentQuestionVector;
};

export const convertAssessmentWorkstreamToAssessmentFacet = (
    assessmentId: string,
    snapshotId: string,
    workstream: AssessmentWorkstream,
    promptMetadataProvider: AssessmentMetadataProvider,
    participantsResponseMap?: ParticipantsResponseMap
): AssessmentFacet => {
    return {
        name: workstream.name,
        questionVectors:
            workstream.activities
                ?.filter(notEmpty)
                .map((activity) =>
                    convertAssessmentActivityToQuestionVector(assessmentId, snapshotId, activity, promptMetadataProvider, participantsResponseMap)
                ) || [],
        vectorSpaceName: Constants.VECTOR_SPACE_TYPE_ACTIVITIES,
        score: workstream.score,
        booleanDistribution: workstream.booleanDistribution,
        display: true,
    };
};

export const convertAssessmentCategoryToQuestionVector = (
    assessmentId: string,
    snapshotId: string,
    category: AssessmentCategory,
    promptMetadataProvider: AssessmentMetadataProvider,
    participantsResponseMap?: ParticipantsResponseMap
): AssessmentQuestionVector => {
    const name = category?.name;
    const questions =
        category?.prompts
            ?.filter(notEmpty)
            .map((prompt) =>
                convertAssessmentPromptToQuestion(assessmentId, snapshotId, prompt, promptMetadataProvider, participantsResponseMap?.get(prompt.id))
            ) || [];
    const summaryObservations = category?.observations?.observations || '';
    const recommendedActions = category?.actions?.actions?.map((action) => {
        return {
            text: action?.text,
            priority: action?.priority,
            targetDate: action?.targetDate,
        } as RecommendedAction;
    });
    const categoryId = category.id;
    const prescribedActions = category?.prescribedActions;
    return {
        name: name,
        questions: questions,
        score: category.score,
        booleanDistribution: category.booleanDistribution,
        summaryObservations: summaryObservations,
        recommendedActions: recommendedActions,
        phase: null,
        display: true,
        prescribedActions: prescribedActions,
        categoryId: categoryId,
        assessmentId: assessmentId,
    } as AssessmentQuestionVector;
};

export const convertAssessmentSectionToAssessmentFacet = (
    assessmentId: string,
    snapshotId: string,
    section: AssessmentSection,
    promptMetadataProvider: AssessmentMetadataProvider,
    participantsResponseMap?: ParticipantsResponseMap
): AssessmentFacet => {
    return {
        name: section.label,
        questionVectors:
            section.categories
                ?.filter(notEmpty)
                .map((category) =>
                    convertAssessmentCategoryToQuestionVector(assessmentId, snapshotId, category, promptMetadataProvider, participantsResponseMap)
                ) || [],
        vectorSpaceName: Constants.VECTOR_SPACE_TYPE_ACTIVITIES,
        score: section.score,
        booleanDistribution: section.booleanDistribution,
        display: true,
    };
};

// Helper interfaces and functions
export interface AssessmentMetadataProvider {
    getPromptPrefix: () => string;
    getPromptNumber: (id: string | null | undefined) => number;
    getCategoryForPrompt: (id: string | null | undefined) => string | null;
    getActivityForPrompt: (id: string | null | undefined) => string | null;
}

export const getAssessmentMetadataProvider: (currentAssessment: AssessmentViewModel | null) => AssessmentMetadataProvider = (
    currentAssessment: AssessmentViewModel | null
) => {
    let promptIndex = 0;
    const promptNumberMap = new Map<string | null | undefined, number>();
    const promptCategoryMap = new Map<string | null | undefined, string>();
    const promptActivityMap = new Map<string | null | undefined, string>();
    currentAssessment?.template?.sections
        ?.flatMap((section) => section?.categories)
        ?.forEach((category) =>
            category?.prompts?.forEach((prompt) => {
                prompt && promptNumberMap.set(prompt?.id, ++promptIndex);
                prompt && promptCategoryMap.set(prompt?.id, category?.name);
            })
        );

    currentAssessment?.template?.workstreams?.forEach((workstream) =>
        workstream?.activities?.forEach((activity) =>
            activity?.prompts?.forEach((prompt) => {
                prompt && promptActivityMap.set(prompt?.id, activity.name);
            })
        )
    );

    return {
        getPromptPrefix: () => {
            return currentAssessment?.template?.defaults?.questionnaireAnswers.questionLabel || 'Q';
        },
        getPromptNumber: (id: string | null | undefined) => {
            return promptNumberMap.get(id);
        },
        getActivityForPrompt: (id: string | null | undefined) => {
            return promptActivityMap.get(id);
        },
        getCategoryForPrompt: (id: string | null | undefined) => {
            return promptCategoryMap.get(id);
        },
    } as AssessmentMetadataProvider;
};
