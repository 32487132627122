import { ButtonDropdown } from '@amzn/awsui-components-react';
import { FunctionComponent, useMemo, useState } from 'react';

import AddCategoryModal from './AddCategoryModal';
import { useAddQuestionCallback } from './AddQuestionHook';
import AddSectionModal from './AddSectionModal';
import ChangeQuestionTypeModal from './ChangeQuestionTypeModal';
import DeleteCategoryModal from './DeleteCategoryModal';
import DeleteQuestionModal from './DeleteQuestionModal';
import DeleteSectionModal from './DeleteSectionModal';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { getModuleFromModuleId } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import { useAppSelector } from '../../../../redux/hooks';

const ModifyTemplateStructureButtons: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }) => {
    const currentlyEditing: boolean = useAppSelector((state) => state.currentTemplateState.currentlyEditing);
    const currentTemplatePrompt: TemplatePromptViewModel | undefined = useAppSelector(
        (state) => state.currentTemplateState.currentPromptState.currentPrompt
    );
    const currentTemplateModuleId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);

    const currentTemplateViewId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const viewIdToRootModuleIdsMap = useAppSelector((state) => state.currentTemplateState.currentPromptState.viewIdToRootModuleIdsMap);

    const [addSectionModalVisible, setAddSectionModalVisible] = useState(false);

    const [deleteSectionModalVisible, setDeleteSectionModalVisible] = useState(false);
    const [deleteSectionNotAllowedReason, setDeleteSectionNotAllowedReason] = useState('');
    const shouldAllowDeleteSection = useMemo(() => {
        if (!currentTemplateModuleId) {
            setDeleteSectionNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.no_section);
            return false;
        }

        const module = getModuleFromModuleId(currentTemplateModuleId);

        // If there's a parent for the current module, then it's a category, not a section
        if (module.parentModule) {
            setDeleteSectionNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.delete_categories_first);
            return false;
        }

        // If this is the very last module, forbid deletion
        if (viewIdToRootModuleIdsMap.get(currentTemplateViewId).length === 1) {
            setDeleteSectionNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.cannot_delete_last_section);
            return false;
        }

        return true;
    }, [
        currentTemplateModuleId,
        viewIdToRootModuleIdsMap,
        currentTemplateViewId,
        appLabels.manage_templates.edit.modify_structure.button_disabled_reasons,
    ]);

    const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false);

    const [deleteCategoryModalVisible, setDeleteCategoryModalVisible] = useState(false);
    const [deleteCategoryNotAllowedReason, setDeleteCategoryNotAllowedReason] = useState('');
    const shouldAllowDeleteCategory = useMemo(() => {
        if (!currentTemplateModuleId) {
            setDeleteCategoryNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.no_category);
            return false;
        }

        // If there is a prompt, forbid deletion
        if (currentTemplatePrompt) {
            setDeleteCategoryNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.delete_questions_first);
            return false;
        }

        const module = getModuleFromModuleId(currentTemplateModuleId);

        // If there's no parent for the current module, then it's not a category
        if (!module.parentModule) {
            setDeleteCategoryNotAllowedReason(appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.no_category);
            return false;
        }

        return true;
    }, [currentTemplateModuleId, currentTemplatePrompt, appLabels.manage_templates.edit.modify_structure.button_disabled_reasons]);

    const [changeQuestionTypeModalVisible, setChangeQuestionTypeModalVisible] = useState(false);
    const shouldAllowChangeQuestionType = !!currentTemplatePrompt;

    // Show "add question" button only on last prompt in module. This is because users can only append a question in a module
    const shouldAllowAddQuestion = useMemo(() => {
        if (!currentTemplateModuleId) {
            return false;
        }

        const containingModule = getModuleFromModuleId(currentTemplateModuleId);

        // Sections cannot have prompts
        if (!containingModule.parentModule) {
            return false;
        }

        // If there are no prompts, or we're on the last prompt, allow adding a question
        return containingModule.prompts.length === 0 || currentTemplatePrompt?.promptId === containingModule.prompts.at(-1).promptId;
    }, [currentTemplateModuleId, currentTemplatePrompt]);
    const onAddQuestion = useAddQuestionCallback();

    const [deleteQuestionModalVisible, setDeleteQuestionModalVisible] = useState(false);
    const shouldAllowDeleteQuestion = !!currentTemplatePrompt;

    if (!currentlyEditing) {
        return null;
    }

    return (
        <>
            <ButtonDropdown
                data-testid='modify-template-structure-dropdown'
                expandToViewport={true}
                variant='primary'
                items={[
                    {
                        id: 'sections',
                        text: appLabels.manage_templates.edit.modify_structure.sections_heading,
                        items: [
                            {
                                id: 'add-section',
                                text: appLabels.manage_templates.edit.modify_structure.add_section,
                            },
                            {
                                id: 'delete-section',
                                text: appLabels.manage_templates.edit.modify_structure.delete_section,
                                disabled: !shouldAllowDeleteSection,
                                disabledReason: deleteSectionNotAllowedReason,
                            },
                        ],
                    },
                    {
                        id: 'categories',
                        text: appLabels.manage_templates.edit.modify_structure.categories_heading,
                        items: [
                            {
                                id: 'add-category',
                                text: appLabels.manage_templates.edit.modify_structure.add_category,
                            },
                            {
                                id: 'delete-category',
                                text: appLabels.manage_templates.edit.modify_structure.delete_category,
                                disabled: !shouldAllowDeleteCategory,
                                disabledReason: deleteCategoryNotAllowedReason,
                            },
                        ],
                    },
                    {
                        id: 'questions',
                        text: appLabels.manage_templates.edit.modify_structure.questions_heading,
                        items: [
                            {
                                id: 'change-question-type',
                                text: appLabels.manage_templates.edit.modify_structure.change_question_type,
                                disabled: !shouldAllowChangeQuestionType,
                                disabledReason: appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.no_question,
                            },
                            {
                                id: 'add-question',
                                text: appLabels.manage_templates.edit.modify_structure.add_question,
                                disabled: !shouldAllowAddQuestion,
                                disabledReason: appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.navigate_to_last_question,
                            },
                            {
                                id: 'delete-question',
                                text: appLabels.manage_templates.edit.modify_structure.delete_question,
                                disabled: !shouldAllowDeleteQuestion,
                                disabledReason: appLabels.manage_templates.edit.modify_structure.button_disabled_reasons.no_question,
                            },
                        ],
                    },
                ]}
                onItemClick={({ detail: { id } }) => {
                    switch (id) {
                        case 'add-section':
                            setAddSectionModalVisible(true);
                            break;
                        case 'delete-section':
                            setDeleteSectionModalVisible(true);
                            break;
                        case 'add-category':
                            setAddCategoryModalVisible(true);
                            break;
                        case 'delete-category':
                            setDeleteCategoryModalVisible(true);
                            break;
                        case 'change-question-type':
                            setChangeQuestionTypeModalVisible(true);
                            break;
                        case 'add-question':
                            onAddQuestion();
                            break;
                        case 'delete-question':
                            setDeleteQuestionModalVisible(true);
                            return;
                    }
                }}
            >
                {appLabels.manage_templates.edit.modify_structure.button_label}
            </ButtonDropdown>
            <AddSectionModal visible={addSectionModalVisible} setVisible={setAddSectionModalVisible} />
            <DeleteSectionModal visible={deleteSectionModalVisible} setVisible={setDeleteSectionModalVisible} />
            <DeleteCategoryModal visible={deleteCategoryModalVisible} setVisible={setDeleteCategoryModalVisible} />
            <AddCategoryModal visible={addCategoryModalVisible} setVisible={setAddCategoryModalVisible} />
            <ChangeQuestionTypeModal visible={changeQuestionTypeModalVisible} setVisible={setChangeQuestionTypeModalVisible} />
            <DeleteQuestionModal visible={deleteQuestionModalVisible} setVisible={setDeleteQuestionModalVisible} />
        </>
    );
};

export default withAppLabelsContext(ModifyTemplateStructureButtons);
