import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Spinner } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { CookieConsentContext } from './common/context/CookieConsentContext';
import rumClient from './common/monitoring/RumClient';
import { persistReduxStore } from './components/redux/configureStore';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps & AuthContextInterface> = ({ component: Component, auth, ...rest }) => {
    const { setLocalStorage } = React.useContext(CookieConsentContext);

    return (
        <Route
            {...rest}
            render={(routeProps: any) => {
                if (auth.isAuthenticated()) {
                    return <Component {...routeProps} />;
                } else {
                    // Persist the redux store before redirecting to sign in
                    persistReduxStore(setLocalStorage);

                    rumClient.recordError('Not authenticated, redirecting to sign in');
                    auth.redirectToSignIn();
                    return <Spinner />;
                }
            }}
        />
    );
};

export default withAuthContext(PrivateRoute);
