import './AssessmentCardHeader.scss';

import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';

import { CustomerName } from '../../customers/CustomerDetails';
import { AssessmentViewModel } from '../AssessmentViewModel';

interface AssessmentCardHeaderProps {
    assessment: AssessmentViewModel;
}

const AssessmentCardHeader = (props: AssessmentCardHeaderProps): JSX.Element => {
    const history = useHistory();

    const displayByline = () => {
        if (props.assessment.accountCustomerName) {
            return props.assessment.accountCustomerName;
        }
        return CustomerName({ customerAccountId: props.assessment.customerAccountID, defaultCustomerName: props.assessment.customerAccountID });
    };

    const getCardTitle = () => {
        return (
            <Link
                data-testid='assessment-card-title-link'
                fontSize='heading-l'
                href={`/assessments/${props.assessment.id}`}
                onFollow={(event) => {
                    event.preventDefault();
                    if (event.detail.href) {
                        history.push(event.detail.href);
                    }
                }}
            >
                {props.assessment.description}
            </Link>
        );
    };

    return (
        <div className='awscat-assessment-card-header'>
            <SpaceBetween direction='vertical' size='xs'>
                <div className='awscat-assessment-card-title'>{getCardTitle()}</div>
                <div className='awscat-assessment-card-title-byline' data-testid='assessment-card-title-byline'>
                    {displayByline()}
                </div>
            </SpaceBetween>
        </div>
    );
};

export default AssessmentCardHeader;
