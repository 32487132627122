/* eslint-disable @typescript-eslint/no-empty-interface */
import './AssessmentRadarResults.scss';

import { Assessment, AssessmentSection, AssessmentSnapshot, AssessmentWorkstream } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Box, Container, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { ChartOptions } from 'chart.js';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Radar } from 'react-chartjs-2';

import AssessmentRadarResultsHelpPanel from './AssessmentRadarResultsHelpPanel';
import a2sClient from '../../../../../api/a2s/A2SClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { clamp, toFixedDigits } from '../../../../../common/Utils';
import rumClient from '../../../../../common/monitoring/RumClient';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { LocalizationContextInterface, withLocalizationContext } from '../../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentViewModelFromAssessment } from '../../../Assessments';
import { getAssessmentAllSnapshotsMetadata, getAssessmentSingleSnapshotContentAndTemplate } from '../../../SnapshotUtils';
import { assessmentViewModelFromAssessmentSnapshot, getMaxRatingInAssessment } from '../../../Utils';
import { loadSnapshotComparisonSuccess } from '../../../facilitate/CurrentAssessmentSlice';
import { filterExcludedSections } from '../../Utils';

interface RadarDataset {
    label: string;
    data: number[];
    backgroundColor: string;
}
interface RadarScores {
    labels: string[];
    datasets: RadarDataset[];
}

/**
 * Returns value between 0 and 255
 * @returns value between 0 and 255
 */
const randomRgbValue = () => {
    return Math.round(Math.random() * 255);
};

const generateRgbaString = (r: number, g: number, b: number, a: number) => {
    return `rgba(${clamp(0, 255, r)}, ${clamp(0, 255, g)}, ${clamp(0, 255, b)}, ${clamp(0, 1, a)})`;
};

/**
 * Generates a sequence function to cycle over background colors - after running out of presets, it will randomly generate more colors
 *
 * @returns
 */
const getBackgroundColorSeq = () => {
    let i = 0;
    const backgroundColors = [generateRgbaString(220, 100, 60, 0.5), generateRgbaString(0, 0, 100, 0.5), generateRgbaString(0, 100, 0, 0.5)];
    return () => {
        if (i < backgroundColors.length) {
            return backgroundColors[i++];
        } else {
            return generateRgbaString(randomRgbValue(), randomRgbValue(), randomRgbValue(), 0.5);
        }
    };
};

/**
 * Return workstream scores in format expected by Radar
 *
 * @param workstreams
 * @returns
 */
const computeWorkstreamsDataset = (
    datasetName: string,
    radarBackgroundColor: string,
    workstreams: (AssessmentWorkstream | null)[] | null | undefined
): RadarDataset => {
    const workstreamDataset: RadarDataset = {
        label: datasetName,
        data: [],
        backgroundColor: radarBackgroundColor,
    };
    workstreams?.forEach((workstream) => {
        if (workstream) {
            const score = toFixedDigits(workstream?.score);
            workstreamDataset.data.push(+(score || 0));
        }
    });
    return workstreamDataset;
};

/**
 * Return section scores in format expected by radar
 *
 * @param sections
 * @returns RadarDataset for sections
 */
const computeSectionsDataset = (
    datasetName: string,
    radarBackgroundColor: string,
    sections: (AssessmentSection | null)[] | null | undefined
): RadarDataset => {
    const sectionsDataset: RadarDataset = {
        label: datasetName,
        data: [],
        backgroundColor: radarBackgroundColor,
    };
    sections?.forEach((section) => {
        if (section) {
            const score = toFixedDigits(section?.score);
            sectionsDataset.data.push(+(score || 0));
        }
    });
    return sectionsDataset;
};

type AssessmentRadarResultsProps = {
    assessmentOrSnapshotIds: string[];
};

const AssessmentRadarResults: FunctionComponent<AppLabelsContextInterface & LocalizationContextInterface & AssessmentRadarResultsProps> = ({
    appLabels,
    locale,
    assessmentOrSnapshotIds,
}): JSX.Element => {
    const currentAssessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);
    const snapshotList: AssessmentSnapshot[] = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.snapshots);
    const templateDefaults = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.defaults);
    const maxScore = getMaxRatingInAssessment(currentAssessment);
    const description = templateDefaults?.report?.radar?.title || '';
    const dispatch = useAppDispatch();

    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessment?.template?.defaults?.questionnaireAnswers, appLabels);
    }, [appLabels, currentAssessment?.template?.defaults?.questionnaireAnswers]);

    const loadSnapshotsContentAsync = async (snapshotId: string, assessmentId: string, locale: string) => {
        let assessment: Assessment = null;
        let newSnapshotList: AssessmentSnapshot[] = [];
        let selectedSnapshot: AssessmentSnapshot = null;
        try {
            if (snapshotList) {
                newSnapshotList = [...snapshotList];
            } else {
                assessment = await getAssessmentAllSnapshotsMetadata(a2sClient, assessmentId, locale);
                newSnapshotList = assessment?.snapshotConnection?.snapshots || [];
            }
            selectedSnapshot = newSnapshotList.find((s) => s.id === snapshotId);
            if (!selectedSnapshot?.template) {
                assessment = await getAssessmentSingleSnapshotContentAndTemplate(a2sClient, assessmentId, snapshotId, locale);
                selectedSnapshot = assessment?.snapshotConnection?.snapshots?.[0];
                if (selectedSnapshot) {
                    const snapshotIndex = newSnapshotList?.findIndex((snapshot) => snapshot.id === snapshotId);
                    if (snapshotIndex >= 0) {
                        newSnapshotList[snapshotIndex] = selectedSnapshot;
                    }
                }
            }
            if (assessment && assessment.snapshotConnection && selectedSnapshot) {
                assessment.snapshotConnection.snapshots = newSnapshotList;
                const assessmentViewModel = assessmentViewModelFromAssessment(assessment);
                const snapshotViewModel = assessmentViewModelFromAssessmentSnapshot(assessmentViewModel, selectedSnapshot);
                dispatch(loadSnapshotComparisonSuccess({ assessment: assessmentViewModel, snapshot: snapshotViewModel }));
            }
        } catch (error: any) {
            rumClient.recordError(error);
            // Best effort to map unaffected assessments
            assessment = error.data?.getAssessments?.items ? error.data?.getAssessments?.items[0] : null;
        }
    };

    const radarOptions = {
        locale: locale,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {},
        },
        pointRadius: 3,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: maxScore,
                ticks: {
                    stepSize: 1,
                },
                pointLabels: {
                    font: {
                        size: 14,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        weight: 'bold',
                        lineHeight: '',
                    },
                },
            },
        },
        scale: {
            pointLabels: {
                fontSize: 20,
            },
        },
    };
    const sectionsRadarOptions = {
        ...radarOptions,
        layout: {
            padding: 10,
        },
    };

    const workstreamsRadarOptions = {
        ...radarOptions,
        aspectRatio: 1.5,
        layout: {
            padding: 10,
        },
    };

    const radarWorkstreamScores: RadarScores = {
        labels: [],
        datasets: [],
    };

    const radarSectionScores: RadarScores = {
        labels: [],
        datasets: [],
    };

    const backgroundSeq = getBackgroundColorSeq();
    const excludeSections = templateDefaults?.report?.radar?.excludeSections;
    assessmentOrSnapshotIds.forEach((id) => {
        const backgroundColor = backgroundSeq();
        if (id === currentAssessmentId) {
            const workstreams = currentAssessment?.template?.workstreams;
            const workstreamDataset = computeWorkstreamsDataset(appLabels.assessment.current_assessment, backgroundColor, workstreams);
            radarWorkstreamScores.datasets.push(workstreamDataset);

            const sections = currentAssessment?.template?.sections;
            const includedSections = filterExcludedSections(sections, excludeSections, currentAssessment);
            const sectionsDataset = computeSectionsDataset(appLabels.assessment.current_assessment, backgroundColor, includedSections);
            radarSectionScores.datasets.push(sectionsDataset);
        } else {
            const snapshot = currentAssessment.snapshots.find((s) => s.id === id);
            if (snapshot) {
                if (snapshot?.template) {
                    const workstreams = snapshot?.template?.workstreams;
                    const workstreamDataset = computeWorkstreamsDataset(snapshot?.description, backgroundColor, workstreams);
                    radarWorkstreamScores.datasets.push(workstreamDataset);

                    const sections = snapshot?.template?.sections;
                    const includedSections = filterExcludedSections(sections, excludeSections, currentAssessment);
                    const sectionsDataset = computeSectionsDataset(snapshot.description, backgroundColor, includedSections);
                    radarSectionScores.datasets.push(sectionsDataset);
                } else {
                    loadSnapshotsContentAsync(snapshot?.id, currentAssessment?.id, locale);
                }
            }
        }
    });

    const currentAssessmentWorkstreams = currentAssessment?.template?.workstreams;
    currentAssessmentWorkstreams?.forEach((workstream) => {
        if (workstream?.name) {
            radarWorkstreamScores.labels.push(workstream?.name);
        }
    });
    const currentAssessmentSections = currentAssessment?.template?.sections;
    const currentAssessmentIncludedSections = filterExcludedSections(currentAssessmentSections, excludeSections, currentAssessment);
    currentAssessmentIncludedSections?.forEach((section) => {
        if (section?.label) {
            radarSectionScores.labels.push(section?.label);
        }
    });

    const helpPanel = useCallback(
        (open?: boolean) => ({
            header: appLabels.assessment.results.review_all.radar.radar,
            content: <AssessmentRadarResultsHelpPanel scoreScale={scoreScale} />,
            footer: '',
            open,
        }),
        [appLabels.assessment.results.review_all.radar.radar, scoreScale]
    );

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(updateAppHelpPanel(helpPanel()));
        return cleanup;
    }, [
        appLabels.assessment.results.review_all.heatmap.heatmap,
        appLabels.assessment.results.review_all.radar.radar,
        dispatch,
        helpPanel,
        scoreScale,
    ]);

    const RadarChart = (title: string, scores: RadarScores, options: ChartOptions) => {
        if (scores?.labels?.length) {
            return (
                <Box>
                    <h2 className='radar-title'>{title}</h2>
                    <Radar data={scores} options={options} />
                </Box>
            );
        }
        return null;
    };

    return (
        <Box>
            <SpaceBetween size='xxs'>
                <SpaceBetween size='s' direction='horizontal'>
                    <Box variant='h3'>{description}</Box>
                    <Link variant='info' id='assessment-radar-info-link' onFollow={() => dispatch(updateAppHelpPanel(helpPanel(true)))}>
                        {appLabels.common.info}
                    </Link>
                </SpaceBetween>
                <Container>
                    <Grid gridDefinition={[{ colspan: { m: 5, xxs: 12 } }, { colspan: { m: 7, xxs: 12 } }]} id='radar-charts'>
                        {RadarChart(appLabels.assessment.perspectives, radarSectionScores, sectionsRadarOptions)}
                        {RadarChart(appLabels.assessment.workstreams, radarWorkstreamScores, workstreamsRadarOptions)}
                    </Grid>
                </Container>
            </SpaceBetween>
        </Box>
    );
};

export default withAppLabelsContext(withLocalizationContext(AssessmentRadarResults));
