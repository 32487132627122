import './PrescribedActions.scss';

import { FlashType } from '@amzn/awscat-react-components';
import { Button, ExpandableSection, GridProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FC, useCallback, useEffect, useState } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import DeletePrescribedActionConfirmationDialog from './DeletePrescribedActionConfirmationDialog';
import { PrescribedRecommendedAction, PrescribedRecommendedActionProps } from './PrescribedAction';
import PrescribedActionsHeader from './PrescribedActionsHeader';
import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_CUSTOMIZED_PRESCRIBED_ACTIONS } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import { PrescribedAction } from '../../assessments/results/model/Assessment';
import BadgedText from '../../common/BadgedText';
import { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import UpdateStatusIndicator from '../../common/UpdateStatusIndicator';

export const readOnlyGridDefinition: GridProps.ElementDefinition[] = [{ colspan: 12 }];

export const editGridDefinition: GridProps.ElementDefinition[] = [{ colspan: 10 }, { colspan: 1 }, { colspan: 1 }];

export type PrescribedActionsListProps = {
    assessmentType: string;
    actionId: string;
    actionName: string;
    customizedPrescribedActions: PrescribedAction[];
    defaultPrescribedActions: PrescribedAction[];
    isReadOnly: boolean;
    setSectionIsCustomized?: (boolean) => void;
} & AppLabelsContextInterface;

export const PrescribedActionsList: FC<PrescribedActionsListProps> = ({
    appLabels,
    assessmentType,
    actionId,
    actionName,
    customizedPrescribedActions,
    defaultPrescribedActions,
    isReadOnly,
    setSectionIsCustomized,
}): JSX.Element => {
    // set prescribed action state to customized prescribed actions if available, otherwise use system prescribed actions.
    const [prescribedActionsState, setPrescribedActionsState] = useState<PrescribedAction[]>(customizedPrescribedActions || defaultPrescribedActions);
    const [isCustomized, setIsCustomized] = useState<boolean>(!!customizedPrescribedActions);

    const [customizePrescribedActionsRequestStatus, setCustomizePrescribedActionsRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [customizedPrescribedActionToBeDeleted, setCustomizedPrescribedActionToBeDeleted] = useState<PrescribedAction | null>(null);

    const [createOrUpdateCustomizedPrescribedActions] = useMutation(CREATE_OR_UPDATE_CUSTOMIZED_PRESCRIBED_ACTIONS, {
        client: a2sApolloClient,
        onError: (error) => {
            setCustomizePrescribedActionsRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.prescribed_actions_customization.error_updating_customized_prescribed_actions,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const response = data.createOrUpdateCustomizedPrescribedActions;
            if (response) {
                setCustomizePrescribedActionsRequestStatus({
                    loading: false,
                    messageType: FlashType.success,
                    messageHeader: appLabels.prescribed_actions_customization.customized_prescribed_actions_saved,
                    messageContent: '',
                });
            }
        },
    });

    const updatePrescribedActions = useCallback(
        (updatedActions: PrescribedAction[]) => {
            createOrUpdateCustomizedPrescribedActions({
                variables: {
                    input: {
                        type: assessmentType,
                        customizedPrescribedActions: [
                            {
                                actionId,
                                prescribedActions: updatedActions.map((a) => ({ text: a.text })),
                            },
                        ],
                    },
                },
            });
            setPrescribedActionsState(updatedActions);
            setCustomizePrescribedActionsRequestStatus({
                loading: true,
                messageType: FlashType.info,
                messageHeader: '',
                messageContent: '',
            });
            setIsCustomized(true);
        },
        [actionId, assessmentType, createOrUpdateCustomizedPrescribedActions]
    );

    const SortableItem = SortableElement((props: PrescribedRecommendedActionProps) => (
        <li data-testid={`prescribed-action-li-${actionId}-${props.actionIndex}`}>{<PrescribedRecommendedAction {...props} />}</li>
    ));

    const SortableList = SortableContainer(({ children }): JSX.Element => {
        return (
            <ul className='prescribed-action-list-style' data-testid={`prescribed-action-ul-${actionId}`}>
                {' '}
                {children}{' '}
            </ul>
        );
    });

    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            if (isReadOnly) {
                return;
            }
            const reorderedPrescribedActionsState = arrayMove(prescribedActionsState, oldIndex, newIndex);
            updatePrescribedActions(reorderedPrescribedActionsState);
        },
        [isReadOnly, prescribedActionsState, updatePrescribedActions]
    );

    const onDeleteSingleAction = useCallback(
        (action: PrescribedAction) => {
            if (isReadOnly) {
                return;
            }
            setCustomizedPrescribedActionToBeDeleted(action);
        },
        [isReadOnly]
    );

    const onCancelDelete = useCallback(() => {
        setCustomizedPrescribedActionToBeDeleted(null);
    }, [setCustomizedPrescribedActionToBeDeleted]);

    const onConfirmDelete = useCallback(() => {
        if (isReadOnly || !customizedPrescribedActionToBeDeleted) {
            return;
        }
        const deletedPrescribedActionsState = prescribedActionsState.filter((a) => a.text !== customizedPrescribedActionToBeDeleted.text);
        updatePrescribedActions(deletedPrescribedActionsState);
        setCustomizedPrescribedActionToBeDeleted(null);
    }, [
        isReadOnly,
        customizedPrescribedActionToBeDeleted,
        prescribedActionsState,
        updatePrescribedActions,
        setCustomizedPrescribedActionToBeDeleted,
    ]);

    const onUpdate = useCallback(
        (updatedIndex: number, updatedAction: PrescribedAction) => {
            if (isReadOnly) {
                return;
            }
            const updatedPrescribedActionsState = prescribedActionsState.map((action, index) => (index === updatedIndex ? updatedAction : action));
            updatePrescribedActions(updatedPrescribedActionsState);
        },
        [isReadOnly, prescribedActionsState, updatePrescribedActions]
    );

    const buildUpdateStatusIndicator = useCallback(() => {
        if (isReadOnly) {
            return null;
        }
        return (
            <UpdateStatusIndicator
                loading={customizePrescribedActionsRequestStatus.loading}
                loadingText={appLabels.prescribed_actions_customization.updating_customized_prescribed_actions}
                updateConfirmationText={
                    customizePrescribedActionsRequestStatus?.messageType === FlashType.success
                        ? customizePrescribedActionsRequestStatus.messageHeader
                        : null
                }
                errorMessageSummary={
                    customizePrescribedActionsRequestStatus?.messageType === FlashType.error
                        ? customizePrescribedActionsRequestStatus.messageHeader
                        : null
                }
                errorMessageDetail={
                    customizePrescribedActionsRequestStatus?.messageType === FlashType.error
                        ? customizePrescribedActionsRequestStatus.messageContent
                        : null
                }
                tryAgainText={appLabels.user_actions.retry_update}
                tryAgainAction={() => updatePrescribedActions(prescribedActionsState)}
            />
        );
    }, [
        isReadOnly,
        customizePrescribedActionsRequestStatus,
        appLabels.prescribed_actions_customization.updating_customized_prescribed_actions,
        appLabels.user_actions.retry_update,
        updatePrescribedActions,
        prescribedActionsState,
    ]);

    const onAddCustomAction = useCallback(
        (event) => {
            const newPrescribedActionsState = prescribedActionsState.concat([{ text: '' }]);
            updatePrescribedActions(newPrescribedActionsState);
            event.preventDefault();
        },
        [prescribedActionsState, updatePrescribedActions]
    );

    const onMergeSystemActions = useCallback(() => {
        const newPrescribedActions = defaultPrescribedActions.filter(
            (defaultAction) => !prescribedActionsState.find((existingAction) => existingAction.text === defaultAction.text)
        );
        const newPrescribedActionsState = prescribedActionsState.concat(newPrescribedActions);
        updatePrescribedActions(newPrescribedActionsState);
    }, [prescribedActionsState, updatePrescribedActions, defaultPrescribedActions]);

    const CustomizeActions = useCallback(() => {
        if (isReadOnly) {
            return null;
        }
        return (
            <SpaceBetween direction='horizontal' size='xs'>
                <Button onClick={onAddCustomAction} iconName='add-plus'>
                    {appLabels.prescribed_actions_customization.add_custom_action}
                </Button>
                {isCustomized ? (
                    <Button onClick={onMergeSystemActions}>{appLabels.prescribed_actions_customization.merge_in_default_actions}</Button>
                ) : null}
            </SpaceBetween>
        );
    }, [
        isReadOnly,
        onAddCustomAction,
        appLabels.prescribed_actions_customization.add_custom_action,
        appLabels.prescribed_actions_customization.merge_in_default_actions,
        isCustomized,
        onMergeSystemActions,
    ]);

    const SectionHeader = useCallback(() => {
        if (isReadOnly) {
            return <Header variant='h2'>{actionName}</Header>;
        }
        return (
            <Header variant='h2'>
                <BadgedText disabled={!isCustomized} badgeText={appLabels.prescribed_actions_customization.customized}>
                    {actionName}
                </BadgedText>
            </Header>
        );
    }, [isReadOnly, isCustomized, appLabels.prescribed_actions_customization.customized, actionName]);

    useEffect(() => {
        if (isCustomized && setSectionIsCustomized) {
            // Update section if current list is customized
            setSectionIsCustomized(true);
        }
    }, [isCustomized, setSectionIsCustomized]);

    return (
        <ExpandableSection
            defaultExpanded={false}
            onChange={(eventDetails) => {
                eventDetails.detail.expanded || setCustomizePrescribedActionsRequestStatus(defaultRequestStatus);
            }}
            variant='container'
            data-testid='prescribed-actions-list-customization-panel'
            header={<SectionHeader />}
        >
            {buildUpdateStatusIndicator()}
            <PrescribedActionsHeader visible={!isReadOnly} />
            {
                <SortableList useDragHandle onSortEnd={onSortEnd}>
                    {prescribedActionsState.map((action, index) => (
                        <SortableItem
                            key={`item-${actionId}-${index}`}
                            index={index}
                            isReadOnly={isReadOnly}
                            action={action}
                            actionIndex={index}
                            deleteAction={onDeleteSingleAction}
                            updateAction={onUpdate}
                        />
                    ))}
                </SortableList>
            }
            <CustomizeActions />
            <DeletePrescribedActionConfirmationDialog
                prescribedAction={customizedPrescribedActionToBeDeleted}
                confirmAction={onConfirmDelete}
                cancelAction={onCancelDelete}
            />
        </ExpandableSection>
    );
};

export default withAppLabelsContext(PrescribedActionsList);
