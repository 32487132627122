import {
    AssessmentModule,
    AssessmentTemplate,
    AssessmentTemplateMetadata,
    GetTemplatesFilter,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Alert, SideNavigationProps, Spinner } from '@amzn/awsui-components-react';
import { useLazyQuery } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import {
    CurrentTemplateState,
    beginLoadingTemplate,
    completeLoadingTemplate,
    getModuleFromModuleId,
    jumpToModule,
    jumpToPrompt,
    loadTemplateFailure,
    loadTemplateMetadata,
    loadTemplateSuccess,
    setTemplateLocale,
} from './CurrentTemplateSlice';
import TemplateConstants from './TemplateConstants';
import { generateTemplateModuleUrl, generateTemplateUrlWithSuffix } from './TemplateUrlUtils';
import { getDescriptorForLocale, getEditableDescriptor } from './TemplateUtils';
import ConfigureTemplate from './metadata/ConfigureTemplate';
import ShareTemplate from './metadata/ShareTemplate';
import UpdateTemplateInfo from './metadata/UpdateTemplateInfo';
import AuthenticatedPrivateRoute from '../../../../AuthenticatedPrivateRoute';
import Paths from '../../../../Paths';
import templateManagementClient from '../../../../api/templateManagement/TemplateManagementClient';
import { GET_TEMPLATES_QUERY, LIST_EDITABLE_TEMPLATES_QUERY } from '../../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { LoadingStatus } from '../../../../common/RequestUtils';
import rumClient from '../../../../common/monitoring/RumClient';
import AssessmentDetails from '../../../assessments/facilitate/AssessmentDetails';
import { setAssessmentSectionInAppSideNavigationPanel } from '../../../common/side-navigation/AppSideNavigationSlice';
import { LocalizationContextInterface } from '../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

interface MatchParam {
    templateId: string;
}

type TemplateRouteProps = AuthContextInterface & RouteComponentProps<MatchParam> & AppLabelsContextInterface & LocalizationContextInterface;

const TemplateRoutes: FunctionComponent<TemplateRouteProps> = ({ appLabels, match }): JSX.Element => {
    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplate: AssessmentTemplate = useAppSelector((state) => state.currentTemplateState.currentTemplate);
    const currentTemplateId: string = useAppSelector((state) => state.currentTemplateState.currentTemplateId);
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);
    const currentTemplateRootModulesMap: Map<string, string[]> = useAppSelector(
        (state) => state.currentTemplateState.currentPromptState.viewIdToRootModuleIdsMap
    );
    const templateLoadingStatus: LoadingStatus = useAppSelector((state) => state.currentTemplateState.templateLoadingStatus);
    const templateLoadErrorMessage: string = useAppSelector((state) => state.currentTemplateState.templateLoadErrorMessage);
    const shouldRefreshTemplate: boolean = useAppSelector((state) => state.currentTemplateState.shouldRefreshTemplate);

    const [templateRetrievalLoadingStatus, setTemplateRetrievalLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotLoaded);
    const [metadataRetrievalLoadingStatus, setMetadataRetrievalLoadingStatus] = useState<LoadingStatus>(
        currentTemplateMetadata.templateId ? LoadingStatus.Loaded : LoadingStatus.NotLoaded
    );

    // Reset loading statuses when refresh is requested
    useEffect(() => {
        if (shouldRefreshTemplate) {
            setTemplateRetrievalLoadingStatus(LoadingStatus.NotLoaded);
            setMetadataRetrievalLoadingStatus(LoadingStatus.NotLoaded);
        }
    }, [shouldRefreshTemplate, setTemplateRetrievalLoadingStatus, setMetadataRetrievalLoadingStatus]);

    const dispatch = useAppDispatch();
    const location = useLocation();
    const { path } = useRouteMatch();

    // Route variables
    const { templateId: routeTemplateId } = match.params;
    const urlParams = new URLSearchParams(location.search);
    const routeLocale = urlParams.get(TemplateConstants.SEARCH_PARAM_LOCALE);
    const routeViewId = urlParams.get(TemplateConstants.SEARCH_PARAM_VIEW_ID);
    const routeModuleId = urlParams.get(TemplateConstants.SEARCH_PARAM_MODULE_ID);
    const routePromptId = urlParams.get(TemplateConstants.SEARCH_PARAM_PROMPT_ID);

    /** Need to use a specific type to get type safety for the `href` attribute */
    type TemplateSideNavItem = SideNavigationProps.ExpandableLinkGroup | SideNavigationProps.Link;

    const baseSideNavItems: SideNavigationProps.Item[] = useMemo(
        () =>
            [
                // Include link to homepage
                { type: 'link', text: appLabels.side_navigation.view_all_assessments, href: Paths.BASE_ASSESSMENTS_PATH },
                { type: 'divider' },
            ] as SideNavigationProps.Item[],
        [appLabels.side_navigation.view_all_assessments]
    );

    // Build side nav for editing templates
    const buildTemplateSideNavigationPanel = useCallback((): SideNavigationProps.Item[] => {
        /** Builds the side navigation for the modules in the template. Uses tail recursion to complete
         * the lowest level first
         * @param moduleId
         * @returns the side nav item for the given module
         */
        function buildSideNavForModule(moduleId: string, parentViewId: string): TemplateSideNavItem {
            const module: AssessmentModule = getModuleFromModuleId(moduleId);
            const subItems = module.childModules.map((childModule) => buildSideNavForModule(childModule.moduleId, parentViewId));

            const moduleItem: TemplateSideNavItem = {
                // If there are no subitems, just use a regular link
                type: subItems.length ? 'expandable-link-group' : 'link',
                // If there are child modules, link to the leftmost leaf module. Otherwise, link to the module
                href: module.childModules.length
                    ? subItems.at(0)?.href
                    : generateTemplateModuleUrl(currentTemplateId, routeLocale, parentViewId, module.moduleId),
                // If the module has no name, display it as "Missing name"
                text: getEditableDescriptor(currentTemplateState, module.descriptors[0].descriptorId)?.name || appLabels.side_navigation.missing_name,
                items: subItems,
            };

            return moduleItem;
        }

        const allSideNavItems: SideNavigationProps.Item[] = baseSideNavItems.slice(); // make a copy

        // If there are multiple views, add that to the hierarchy
        let viewItems: SideNavigationProps.Item[] = [];
        const numViews = currentTemplateRootModulesMap?.size;
        if (numViews > 1) {
            viewItems = Array.from(currentTemplateRootModulesMap.entries()).map(([viewId, rootModuleIds]) => {
                const subItems = rootModuleIds.map((moduleId) => buildSideNavForModule(moduleId, viewId));
                return {
                    type: 'expandable-link-group',
                    // Link to the first module with prompts
                    href: subItems.at(0)?.href,
                    // Currently, views aren't creatable/deletable. So can get views from original template
                    text: getDescriptorForLocale(currentTemplate.views.find((view) => view.viewId === viewId).descriptors, routeLocale)?.name || '',
                    items: subItems,
                };
            });
        } else if (numViews === 1) {
            // If there's only one view, just show the module hierarchy
            const [viewId, rootModuleIds] = Array.from(currentTemplateRootModulesMap.entries())[0];
            viewItems = rootModuleIds.map((moduleId) => buildSideNavForModule(moduleId, viewId));
        }
        // Put the following text at the root of the template content hierarchy: Edit template - <template name> <template version>
        const templateName: string = getEditableDescriptor(currentTemplateState, currentTemplate?.descriptors[0].descriptorId)?.name || '';
        const templateVersion: string = currentTemplate?.version || '';

        // Add the template-related items only if the template is loaded
        if (currentTemplate) {
            allSideNavItems.push({
                type: 'section',
                items: viewItems,
                text: `${appLabels.manage_templates.edit.template} - ${templateName} ${templateVersion}`,
                defaultExpanded: true,
            });
        }

        const metadataItems: SideNavigationProps.Item = {
            type: 'section',
            text: appLabels.manage_templates.edit.metadata,
            items: [
                {
                    type: 'link',
                    text: appLabels.manage_templates.update_template_info.link_text,
                    href: generateTemplateUrlWithSuffix(currentTemplateId, Paths.UPDATE_TEMPLATE_INFO_PATH_SUFFIX, routeLocale),
                },
                {
                    type: 'link',
                    text: appLabels.manage_templates.configure_template.link_text,
                    href: generateTemplateUrlWithSuffix(currentTemplateId, Paths.CONFIGURE_TEMPLATE_PATH_SUFFIX, routeLocale),
                },
                {
                    type: 'link',
                    text: appLabels.manage_templates.share.share_template,
                    href: generateTemplateUrlWithSuffix(currentTemplateId, Paths.SHARE_TEMPLATE_PATH_SUFFIX, routeLocale),
                },
            ],
        };
        if (currentTemplate) {
            allSideNavItems.push(metadataItems);
        }

        return allSideNavItems;
    }, [
        baseSideNavItems,
        currentTemplate,
        currentTemplateRootModulesMap,
        currentTemplateState,
        currentTemplateId,
        routeLocale,
        appLabels.manage_templates,
        appLabels.side_navigation,
    ]);

    const getTemplateFilter: GetTemplatesFilter = useMemo(
        () => ({
            templateIds: [routeTemplateId],
            // So that we don't need to completely reload the template when the locale is switched,
            // don't specify a locale. This gets the descriptors for all locales
        }),
        [routeTemplateId]
    );

    /**
     * Query for retrieving the template specified in the route. Filter is defined above
     */
    const [getTemplate] = useLazyQuery(GET_TEMPLATES_QUERY, {
        variables: {
            filter: getTemplateFilter,
        },
        client: templateManagementClient,
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            rumClient.recordError(error);
            dispatch(loadTemplateFailure(error.message));
            setTemplateRetrievalLoadingStatus(LoadingStatus.FailedToLoad);
        },
        onCompleted: (data) => {
            const template: AssessmentTemplate = data.getTemplates.at(0);
            setTemplateRetrievalLoadingStatus(LoadingStatus.Loaded);
            if (template) {
                try {
                    dispatch(loadTemplateSuccess(template));
                    dispatch(setTemplateLocale(routeLocale));
                    if (routeViewId && routeModuleId) {
                        dispatch(jumpToModule({ viewId: routeViewId, moduleId: routeModuleId }));
                    }
                    if (routeViewId && routePromptId) {
                        dispatch(jumpToPrompt({ viewId: routeViewId, promptId: routePromptId }));
                    }
                } catch (err) {
                    rumClient.recordError(err);
                    dispatch(loadTemplateFailure((err as Error)?.message));
                }
            } else {
                // No template --> show an error
                const noTemplateFoundError = new Error('No template matching that URL was found');
                rumClient.recordError(noTemplateFoundError);
                dispatch(loadTemplateFailure(noTemplateFoundError.message));
                setTemplateRetrievalLoadingStatus(LoadingStatus.FailedToLoad);
            }
        },
    });

    /**
     * Normally, template metadata (e.g. permissions) is retrieved from the AdminManageTemplates component,
     * but the user can navigate here via URL, bypassing that component. If the appropriate template metadata
     * is not loaded, retrieve it again
     */
    const [listEditableTemplates] = useLazyQuery(LIST_EDITABLE_TEMPLATES_QUERY, {
        client: templateManagementClient,
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            rumClient.recordError(error);
            dispatch(loadTemplateFailure(error.message));
            setMetadataRetrievalLoadingStatus(LoadingStatus.FailedToLoad);
        },
        onCompleted: (data) => {
            setMetadataRetrievalLoadingStatus(LoadingStatus.Loaded);
            const templateMetadata: AssessmentTemplateMetadata[] = data.listEditableTemplates;
            if (templateMetadata) {
                dispatch(loadTemplateMetadata(templateMetadata.find((metadata) => metadata.templateId === routeTemplateId)));
            }
        },
    });

    // Reload template if the templateId in the URL changed
    const shouldLoadTemplate: boolean = useMemo(
        () => templateRetrievalLoadingStatus === LoadingStatus.NotLoaded && currentTemplateId !== routeTemplateId,
        [currentTemplateId, templateRetrievalLoadingStatus, routeTemplateId]
    );
    useEffect(() => {
        if (shouldLoadTemplate) {
            dispatch(beginLoadingTemplate());
            setTemplateRetrievalLoadingStatus(LoadingStatus.Loading);

            getTemplate();
        }
    }, [shouldLoadTemplate, dispatch, getTemplate]);

    // Load the metadata for the current route if it wasn't already loaded
    const shouldLoadTemplateMetadata: boolean = useMemo(
        () => metadataRetrievalLoadingStatus === LoadingStatus.NotLoaded && currentTemplateMetadata?.templateId !== routeTemplateId,
        [currentTemplateMetadata, metadataRetrievalLoadingStatus, routeTemplateId]
    );
    useEffect(() => {
        if (shouldLoadTemplateMetadata) {
            setMetadataRetrievalLoadingStatus(LoadingStatus.Loading);
            listEditableTemplates();
        }
    }, [shouldLoadTemplateMetadata, listEditableTemplates]);

    // Once both APIs complete, indicate that the template retrieval is complete
    useEffect(() => {
        if (templateRetrievalLoadingStatus === LoadingStatus.Loaded && metadataRetrievalLoadingStatus === LoadingStatus.Loaded) {
            dispatch(completeLoadingTemplate());
        }
    }, [templateRetrievalLoadingStatus, metadataRetrievalLoadingStatus, dispatch]);

    // Rebuild side nav when template changes (dependency is in buildTemplateSideNavigationPanel)
    useEffect(() => {
        const items = buildTemplateSideNavigationPanel();
        dispatch(setAssessmentSectionInAppSideNavigationPanel(items));

        // Clean up side nav items when the user leaves the page
        return () => {
            dispatch(setAssessmentSectionInAppSideNavigationPanel(baseSideNavItems));
        };
    }, [dispatch, buildTemplateSideNavigationPanel, baseSideNavItems]);

    // This callback returns the component for this route:
    // 1. A spinner if the template is still loading
    // 2. The template if it was loaded successfully
    // 3. An error message if the template failed to load
    return useMemo(() => {
        if (templateLoadingStatus === LoadingStatus.NotLoaded || templateLoadingStatus === LoadingStatus.Loading) {
            return (
                <div className='spinner-wrapper'>
                    <Spinner size='big' data-testid='spinner-loading-template' />
                </div>
            );
        } else if (templateLoadingStatus === LoadingStatus.Loaded) {
            return (
                <Switch>
                    {/* Not sure why, but these paths need to use `path` that comes from useRouteMatch(). Other options didn't work */}
                    <AuthenticatedPrivateRoute path={`${path}${Paths.UPDATE_TEMPLATE_INFO_PATH_SUFFIX}`} component={UpdateTemplateInfo} />
                    <AuthenticatedPrivateRoute path={`${path}${Paths.CONFIGURE_TEMPLATE_PATH_SUFFIX}`} component={ConfigureTemplate} />
                    <AuthenticatedPrivateRoute path={`${path}${Paths.SHARE_TEMPLATE_PATH_SUFFIX}`} component={ShareTemplate} />
                    <AuthenticatedPrivateRoute path={path} component={AssessmentDetails} />
                </Switch>
            );
        } else if (templateLoadingStatus === LoadingStatus.FailedToLoad) {
            return (
                <Alert
                    dismissAriaLabel={appLabels.user_actions.close_alert}
                    type='error'
                    header={appLabels.manage_templates.edit.error_loading_template}
                >
                    {templateLoadErrorMessage}
                </Alert>
            );
        }
        return null;
    }, [
        appLabels.user_actions.close_alert,
        templateLoadErrorMessage,
        templateLoadingStatus,
        appLabels.manage_templates.edit.error_loading_template,
        path,
    ]);
};

export default withAppLabelsContext(withAuthContext(TemplateRoutes));
