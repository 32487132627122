export default {
    BASE_ASSESSMENTS_PATH: '/assessments',

    // Template management paths
    CREATE_TEMPLATE_PATH: '/admin-create-template',
    FINALIZE_TEMPLATE_PATH: '/admin-finalize-template',
    MANAGE_TEMPLATES_PATH: '/admin-manage-templates',
    EDIT_TEMPLATE_PATH: '/admin-manage-templates/edit/templates',
    UPDATE_TEMPLATE_INFO_PATH_SUFFIX: '/update-info',
    CONFIGURE_TEMPLATE_PATH_SUFFIX: '/configure',
    SHARE_TEMPLATE_PATH_SUFFIX: '/share',
};
