import '../ResultsReviewAll.scss';
import './AssessmentHeatMapResultsV2.scss';

import { AssessmentCategory } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AssessmentResponseRating } from '@amzn/awscat-aws-assessment-service-typescript-client/dist/graphql/types';
import { withAuthContext } from '@amzn/awscat-react-components';
import { Box, ButtonDropdown, ButtonDropdownProps, Container, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import HeatMapHelpPanelBody from './HeatMapHelpPanelBody';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { ScoredItem } from '../../../../../common/score/ScoreUtils';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { parseDrivers } from '../../../Utils';
import AssessmentDetailsModal from '../../../facilitate/AssessmentDetailsModal';
import { jumpToPrompt } from '../../../facilitate/CurrentAssessmentSlice';
import { filterExcludedSections } from '../../Utils';

const MAX_TITLE_LENGTH = 40;

type HeatmapCellData = {
    text: string;
    backgroundColor: string;
    fontColor: string;
    onClick: () => void;
};

type HeatmapCellProps = {
    drivers?: string[];
    title: string;
    cellData?: HeatmapCellData[];
};

type HeatmapGridProps = {
    title: string;
    cellProps: HeatmapCellProps[];
};

const trimPromptText = (text: string) => {
    return text ? text.split(':')[0] || text : '';
};

const trimTitleText = (text: string) => {
    if (!text) {
        return '';
    }
    return text.length < MAX_TITLE_LENGTH ? text : text.substr(0, MAX_TITLE_LENGTH) + '...';
};

const HeatmapCell: FunctionComponent<HeatmapCellProps> = ({ title, cellData }): JSX.Element => {
    return (
        <Box variant='div' className='heatmap-cell-outer'>
            <div className='heatmap-cell-category awscat-assessment-heatmap-grid-element'>
                <h4>{title || ''}</h4>
            </div>
            {cellData.map((cell) => (
                <div
                    className={'heatmap-cell-button heatmap-cell heatmap-cell-v2'}
                    style={{ backgroundColor: cell.backgroundColor, color: cell.fontColor }}
                    onClick={() => cell.onClick()}
                >
                    {cell.text}
                </div>
            ))}
        </Box>
    );
};

const HeatmapGrid: FunctionComponent<HeatmapGridProps> = ({ title, cellProps }): JSX.Element => {
    return (
        <Container disableContentPaddings={true} className='awscat-assessment-heatmap-container'>
            <Box textAlign='center' variant='h4' className='container-title'>
                {title}
            </Box>
            {cellProps.map((cellProp) => (
                <HeatmapCell title={cellProp.title} cellData={cellProp.cellData} />
            ))}
        </Container>
    );
};

const AssessmentHeatMapResultsV2: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();
    // currentPrompt can be null once we close the modal
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);

    const [visiblePrompt, setVisiblePrompt] = useState(false);
    const [currentPromptId, setCurrentPromptId] = useState(currentPrompt?.id);
    const currentPromptLabel = currentPrompt?.label;
    const [currentPromptTitle, setCurrentPromptTitle] = useState(trimTitleText(currentPromptLabel?.label ?? currentPromptLabel?.text));
    const [allDrivers] = useState<Set<string>>(new Set());
    const [selectedDriver, setSelectedDriver] = useState<string | null>(null);

    const openHelp = useAppSelector((state) => state.appHelpPanelState.open);

    useEffect(() => {
        dispatch(jumpToPrompt(currentPromptId));
    }, [currentPromptId, dispatch, selectedDriver]);

    const templateDefaults = currentAssessment?.template?.defaults;

    const ratingScale = useMemo(() => {
        const useRatingStratum = true;
        return new ScoreScale(templateDefaults?.questionnaireAnswers, appLabels, useRatingStratum);
    }, [appLabels, templateDefaults?.questionnaireAnswers]);

    const heatmapGridData = useMemo(() => {
        const gridData: HeatmapGridProps[] = [];
        const excludeSections = templateDefaults?.report?.heatmap?.excludeSections || [];
        const includedSections = filterExcludedSections(currentAssessment?.template?.sections, excludeSections, currentAssessment) || [];
        gridData.push(
            ...includedSections.map((section) => {
                const cellProps = section?.categories
                    ?.map((category: AssessmentCategory) => {
                        const drivers = parseDrivers(category.driver?.text);
                        drivers.forEach((driver) => allDrivers.add(driver));
                        return {
                            title: category.name,
                            drivers,
                            cellData: category.prompts.map((prompt) => {
                                const score = (prompt.response as AssessmentResponseRating)?.intValue ?? prompt.dependencyActivity?.score ?? 0;
                                const scoredItem: ScoredItem = { score };
                                return {
                                    text: trimPromptText(prompt.abbreviation?.text ?? prompt.label?.text),
                                    backgroundColor: ratingScale.getStratificationBackgroundColor(scoredItem),
                                    fontColor: ratingScale.getStratificationFontColor(scoredItem),
                                    onClick: () => {
                                        setCurrentPromptId(prompt.id);
                                        setCurrentPromptTitle(trimTitleText(prompt.abbreviation?.text ?? prompt.label.text));
                                        setVisiblePrompt(true);
                                    },
                                };
                            }),
                        };
                    })
                    .filter((cellProp) => selectedDriver === null || cellProp.drivers.includes(selectedDriver));
                return {
                    backgroundColor: ratingScale.getStratificationBackgroundColor(section),
                    fontColor: ratingScale.getStratificationFontColor(section),
                    title: section.label,
                    cellProps,
                };
            })
        );
        return gridData;
    }, [allDrivers, currentAssessment, ratingScale, selectedDriver, templateDefaults?.report?.heatmap?.excludeSections]);

    const dropdownItems: ButtonDropdownProps.ItemOrGroup[] = [
        {
            id: 'drivers',
            items: [
                {
                    text: appLabels.assessment.results.review_all.heatmap.all_drivers,
                    id: 'all',
                },
            ].concat(
                ...Array.from(allDrivers)
                    .map((driver) => {
                        return {
                            text: driver,
                            id: driver,
                        };
                    })
                    .sort((a, b) => (a.text > b.text ? 1 : -1))
            ),
        },
    ];

    const description = templateDefaults?.report?.heatmap?.title || '';
    const heatmapObj = (
        <>
            <SpaceBetween direction='horizontal' size='s'>
                <Box variant='h3'>{description}</Box>
                <Link variant='info' id='assessment-heatmap-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                    {appLabels.common.info}
                </Link>
            </SpaceBetween>
            {allDrivers.size > 1 && (
                <ButtonDropdown
                    expandToViewport
                    variant='normal'
                    items={dropdownItems}
                    onItemClick={(event) => {
                        if (event.detail.id === 'all') {
                            setSelectedDriver(null);
                        } else {
                            setSelectedDriver(event.detail.id);
                        }
                    }}
                    className='heatmap-driver-dropdown'
                >
                    {selectedDriver ?? appLabels.assessment.results.review_all.heatmap.all_drivers}
                </ButtonDropdown>
            )}
            <AssessmentDetailsModal setVisible={setVisiblePrompt} visible={visiblePrompt} currentPromptTitle={currentPromptTitle} />
            <Grid
                className='assessment-heatmap-v2'
                gridDefinition={heatmapGridData.map(() => {
                    return { colspan: 2 };
                })}
            >
                {heatmapGridData.map((gridData) => (
                    <HeatmapGrid key={gridData.title} title={gridData.title} cellProps={gridData.cellProps} />
                ))}
            </Grid>
        </>
    );

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.results.review_all.heatmap.heatmap,
                content: <HeatMapHelpPanelBody scoreScale={ratingScale} />,
                open: openHelp,
            })
        );
        return cleanup;
    }, [appLabels.assessment.results.review_all.heatmap.heatmap, dispatch, ratingScale, openHelp]);

    return heatmapObj;
};

export default withAppLabelsContext(withAuthContext(AssessmentHeatMapResultsV2));
