import { AssessmentResponseTypes, AssessmentStatus, AssessmentTemplate } from '@amzn/awscat-aws-assessment-service-typescript-client';

import Constants from '../../../../../common/Constants';
import { AssessmentViewModel } from '../../../../assessments/AssessmentViewModel';
import { Template } from '../CreateTemplateSlice';

const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

// A2S reference - https://code.amazon.com/packages/AwsAssessmentService/blobs/1b6ae573964e7e635aacaaeb69d7be95b49b1f51/--/src/repository/transformer/AssessmentTemplateTransformer.ts#L431
const answerTypeToResponseType = (answerType: string): AssessmentResponseTypes => {
    switch (answerType) {
        case 'text': {
            return AssessmentResponseTypes.TEXT;
        }
        case 'yesno': {
            return AssessmentResponseTypes.YES_NO;
        }
        case 'number': {
            return AssessmentResponseTypes.NUMBER;
        }
        case 'singleSelection': {
            return AssessmentResponseTypes.SINGLE_SELECTION;
        }
        case 'multiSelection': {
            return AssessmentResponseTypes.MULTI_SELECTION;
        }
        case 'dateValue': {
            return AssessmentResponseTypes.DATE_VALUE;
        }
        default: {
            return AssessmentResponseTypes.RATING;
        }
    }
};

const templateToAssessmentTemplate = (template: Template): AssessmentTemplate => {
    if (!template) {
        return null;
    }

    // Need to convert "questions" to "prompts". Fill in some sample answers too
    const sections = template.section.perspectives.map((section) => {
        const newSection = {
            ...section,
            section: undefined,
            categories: section.categories.map((category) => {
                const newCategory = {
                    ...category,
                    // Turn each prescribed action into a recommended action
                    actions: {
                        actions: category.prescribedActions?.map((action) => ({
                            text: action.text,
                            priority: 'Medium',
                            effort: 'Med',
                            targetDate: '2000-01-01',
                            owner: 'Example user',
                        })),
                    },
                    // Add an example observation
                    observations: {
                        observations: 'Example observation',
                        updatedBy: 'Example user',
                    },
                    // Convert each prompt to a question
                    prompts: category.questions.map((question) => ({
                        ...question,
                        context: {
                            text: question.context,
                        },
                        label: {
                            text: question.question,
                        },
                        responseType: answerTypeToResponseType(question.answerType),
                        // Response for any response type
                        response: {
                            stringValue: 'Response for text questions',
                            comments: 'Example comment',
                            singleSelectValue: question.responseSelections?.selections[0].selectionId, // select first one
                            multiSelectValues: question.responseSelections?.selections.map((selection) => selection.selectionId), // select all
                            booleanValue: true,
                            dateValue: '2000/01/01',
                            intValue: randomInt(1, 5),
                            priority: null,
                            updatedBy: 'test-user',
                        },
                    })),
                };
                // Add the average score
                const categoryRatingResponses = newCategory.prompts.filter((prompt) => prompt.responseType === AssessmentResponseTypes.RATING);
                newCategory['score'] =
                    categoryRatingResponses.reduce((previousTotal, prompt) => previousTotal + prompt.response.intValue, 0) /
                    (categoryRatingResponses.length || 1); // don't divide by 0
                delete newCategory['questions'];
                return newCategory;
            }),
        };
        // Add scores for the section
        newSection['score'] =
            newSection.categories.reduce((previousTotal, category) => previousTotal + category['score'], 0) / (newSection.categories.length || 1);
        return newSection;
    });

    return {
        ...template,
        sections,
        applicationTitle: template.title,
        locale: 'en-US',
    } as any;
};

export const templateToSampleAssessment = (template: Template): AssessmentViewModel => {
    if (!template) {
        return null;
    }

    const assessmentTemplate = templateToAssessmentTemplate(template);
    const now = Date.now();

    const assessmentViewModel: AssessmentViewModel = {
        id: Constants.SAMPLE_ASSESSMENT_ID,
        description: 'Sample assessment',
        targetSegmentId: 'ENT',
        createdAt: now,
        createdBy: 'test-user',
        accountCustomerName: 'Example customer account',
        status: AssessmentStatus.COMPLETED,
        type: assessmentTemplate.type,
        version: assessmentTemplate.version,
        deliveredBy: 'ProServe',
        customerAccountID: '1-2-3-4',
        updatedAt: now,
        lastUpdated: '02/28/2023',
        workshopDate: '02/28/2023',
        readoutDate: '02/28/2023',
        assessmentPermissions: [],
        isDemoTest: true,
        template: assessmentTemplate,
        mapProgramEngagementId: 'NA',
        internalContact: 'nobody@amazon.com',
        optOutSolutionsRecommendation: true,
        assessmentScores: null,
    } as AssessmentViewModel;

    return assessmentViewModel;
};
