import './rating/RatingTile.scss';

import { Box } from '@amzn/awsui-components-react';

export interface Comment {
    content: string;
    author: string;
    date: string;
    eventType?: string;
}

const CommentBox = ({ author, content, date, eventType }: Comment): JSX.Element => {
    return (
        <Box variant='div' className='comment'>
            <div className='comment-body'>
                <p>{content}</p>
                <div className='comment-meta'>
                    <span className='comment-author'>{author}</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span className='comment-date'>{date}</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {eventType}
                </div>
            </div>
        </Box>
    );
};

export default CommentBox;
