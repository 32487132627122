import { Button, DatePicker, Grid, Input, Select } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { ActionTypeRecommendedAction } from './RecommendedActions';
import { RecommendedActionEffort, RecommendedActionPriority } from '../../../../../../api/a2s/A2STypes';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';
import { RecommendedAction } from '../../../model/Assessment';

type RecommendedActionLineComponentProps = AppLabelsContextInterface & {
    action: RecommendedAction;
    persistedRecommendedActionsDispatch: any;
    isReadOnly: boolean;
};

const RecommendedActionLineComponent = (props: RecommendedActionLineComponentProps): JSX.Element => {
    const appLabels = props.appLabels;
    const dispatch = props.persistedRecommendedActionsDispatch;
    const [state, setState] = useState<RecommendedAction>(props.action);
    const isReadOnly = props.isReadOnly;

    const onCloseClick = () => {
        dispatch({
            type: ActionTypeRecommendedAction.DELETE_ACTION,
            payLoad: props.action,
        });
    };

    const onSaveAction = useCallback(() => {
        dispatch({
            type: ActionTypeRecommendedAction.UPDATE_ACTION,
            payLoad: state,
        });
    }, [dispatch, state]);

    const DragHandle = SortableHandle(() => <span>::</span>);

    return (
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 1 }, { colspan: 1 }, { colspan: 2 }, { colspan: 2 }, { colspan: 1 }, { colspan: 1 }]}>
            <Input
                name='actionName'
                value={state.text || ''}
                readOnly={isReadOnly}
                onChange={({ detail }) => setState({ ...state, text: detail.value })}
                onBlur={onSaveAction}
            />
            <Select
                disabled={isReadOnly}
                selectedOption={{ label: state.priority, value: state.priority }}
                onChange={({ detail }) => setState({ ...state, priority: detail.selectedOption.value })}
                onBlur={onSaveAction}
                options={[
                    { label: RecommendedActionPriority.NA, value: RecommendedActionPriority.NA },
                    { label: RecommendedActionPriority.LOW, value: RecommendedActionPriority.LOW },
                    { label: RecommendedActionPriority.MED, value: RecommendedActionPriority.MED },
                    { label: RecommendedActionPriority.HIGH, value: RecommendedActionPriority.HIGH },
                ]}
            />
            <Select
                disabled={isReadOnly}
                selectedOption={{ label: state.effort, value: state.effort }}
                onChange={({ detail }) => setState({ ...state, effort: detail.selectedOption.value })}
                onBlur={onSaveAction}
                options={[
                    { label: RecommendedActionEffort.NA, value: RecommendedActionEffort.NA },
                    { label: RecommendedActionEffort.SHORT, value: RecommendedActionEffort.SHORT },
                    { label: RecommendedActionEffort.MEDIUM, value: RecommendedActionEffort.MEDIUM },
                    { label: RecommendedActionEffort.LONG, value: RecommendedActionEffort.LONG },
                ]}
            />
            <DatePicker
                onChange={({ detail: { value } }) => setState({ ...state, targetDate: value })}
                value={state.targetDate || ''}
                openCalendarAriaLabel={(selectedDate) => {
                    return appLabels.common.choose_date + (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '');
                }}
                nextMonthAriaLabel={appLabels.common.next_month}
                placeholder={appLabels.common.date_placeholder}
                previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                todayAriaLabel={appLabels.assessment.create.today}
                disabled={isReadOnly}
                onBlur={onSaveAction}
            />
            <Input
                name='owner'
                value={state.owner}
                readOnly={isReadOnly}
                placeholder={appLabels.assessment.results.review_all.report.recommended_actions.grid.placeholder_owner}
                onChange={({ detail }) => setState({ ...state, owner: detail.value })}
                onBlur={onSaveAction}
            />
            <Button variant='icon' iconName='close' onClick={onCloseClick} disabled={isReadOnly}></Button>
            {<DragHandle />}
        </Grid>
    );
};

export default withLocalizationContext(withAppLabelsContext(RecommendedActionLineComponent));
