import { FlashType } from '@amzn/awscat-react-components';
import { Box, RadioGroup, Spinner } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { UPDATE_ASSESSMENT_BASE } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateAssessmentSendAllPromptsToPreEvent } from '../facilitate/CurrentAssessmentSlice';

enum SendPromptOptions {
    SEND_DESIGNATED = 'SEND_DESIGNATED', // Send questions designated as "preEvent" found in the assessment template
    SEND_ALL = 'SEND_ALL',
}

type OptionSendAllPromptsProp = {
    disabled: boolean;
} & AppLabelsContextInterface;

const OptionSendAllPrompts = ({ appLabels, disabled }: OptionSendAllPromptsProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = currentAssessment?.id;
    const sendAllPromptsToPreEvent = currentAssessment?.sendAllPromptsToPreEvent;
    const [sendPromptOption, setSendPromptOption] = useState<SendPromptOptions | null>(null);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [updateAssessmentBase, { loading }] = useMutation(UPDATE_ASSESSMENT_BASE, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const updatedAssessment = data.updateAssessment;
            if (updatedAssessment) {
                const sendAllPromptsToPreEvent = updatedAssessment.sendAllPromptsToPreEvent;
                dispatch(updateAssessmentSendAllPromptsToPreEvent({ sendAllPromptsToPreEvent }));
                setSendPromptOption(sendAllPromptsToPreEvent ? SendPromptOptions.SEND_ALL : SendPromptOptions.SEND_DESIGNATED);
                setRequestStatus({
                    ...requestStatus,
                    loading,
                    messageType: FlashType.success,
                });
            }
        },
        onError: (error) => {
            setRequestStatus({
                ...requestStatus,
                loading,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.update.error_updating_assessment,
                messageContent: error.message ?? '',
            });
            rumClient.recordError(error);
        },
    });

    const updateAssessment = useCallback(
        async (sendAllPromptsToPreEvent: boolean) => {
            try {
                setRequestStatus({
                    ...requestStatus,
                    messageHeader: appLabels.assessment.update.update_assessment_successful,
                });
                updateAssessmentBase({
                    variables: {
                        id: assessmentId,
                        input: { sendAllPromptsToPreEvent },
                    },
                });
            } catch (error: any) {
                rumClient.recordError(error);
                setRequestStatus({
                    messageType: FlashType.error,
                    loading: false,
                    messageHeader: appLabels.assessment.update.error_updating_assessment,
                    messageContent: error.message ?? '',
                });
            }
        },
        [
            appLabels.assessment.update.error_updating_assessment,
            appLabels.assessment.update.update_assessment_successful,
            assessmentId,
            requestStatus,
            updateAssessmentBase,
        ]
    );

    const onSelectionChange = useCallback(
        (data) => {
            const newSendPromptOption: SendPromptOptions = data?.detail?.value;
            const targetSendAllPromptsToPreEvent = newSendPromptOption === SendPromptOptions.SEND_ALL;
            updateAssessment(targetSendAllPromptsToPreEvent);
        },
        [updateAssessment]
    );

    useEffect(() => {
        if (sendPromptOption === null && assessmentId) {
            // Assessment loaded but sendPromptOption not initialized
            setSendPromptOption(sendAllPromptsToPreEvent ? SendPromptOptions.SEND_ALL : SendPromptOptions.SEND_DESIGNATED);
        }
    }, [assessmentId, sendAllPromptsToPreEvent, sendPromptOption]);

    if (!sendPromptOption) {
        // No options set. Wait for sendPromptOption to be initialized
        return null;
    }

    return (
        <Box>
            <RadioGroup
                onChange={onSelectionChange}
                value={sendPromptOption}
                items={[
                    {
                        disabled,
                        value: SendPromptOptions.SEND_DESIGNATED,
                        label: appLabels.assessment.pre_event.send_designated_questions,
                    },
                    {
                        disabled,
                        value: SendPromptOptions.SEND_ALL,
                        label: appLabels.assessment.pre_event.send_all_questions,
                        description: appLabels.assessment.pre_event.send_all_questions_description,
                    },
                ]}
            />
            {loading ? <Spinner /> : null}
            {requestStatus.messageType === FlashType.error ? (
                <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            ) : null}
        </Box>
    );
};

export default withAppLabelsContext(OptionSendAllPrompts);
