import { SideNavigationProps } from '@amzn/awsui-components-react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppSideNavigationPanelState {
    administrationItems: SideNavigationProps.Item[];
    assessmentItems: SideNavigationProps.Item[];
}

export const initialAssessmentItems: SideNavigationProps.Item[] = [
    {
        type: 'section',
        text: 'Assessments',
        items: [{ type: 'link', text: 'View', href: '/assessments' }],
    },
    {
        type: 'section',
        text: 'Customers',
        items: [{ type: 'link', text: 'List', href: '/customers' }],
    },
];

export const initialState: AppSideNavigationPanelState = {
    administrationItems: [],
    assessmentItems: initialAssessmentItems,
};

export const appSideNavigationPanelSlice = createSlice({
    name: 'appSideNavigationPanelState',
    initialState,
    reducers: {
        setAdministrationSectionInAppSideNavigationPanel: (state, action: PayloadAction<SideNavigationProps.Item[]>) => {
            Object.assign(state, { ...state, administrationItems: action.payload });
        },
        setAssessmentSectionInAppSideNavigationPanel: (state, action: PayloadAction<SideNavigationProps.Item[]>) => {
            Object.assign(state, { ...state, assessmentItems: action.payload });
        },
    },
});

export const { setAdministrationSectionInAppSideNavigationPanel, setAssessmentSectionInAppSideNavigationPanel } = appSideNavigationPanelSlice.actions;

export default appSideNavigationPanelSlice.reducer;
