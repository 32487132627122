import { ReportGenerationClient } from '@amzn/awscat-report-generation-service-typescript-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken, getProvider } from '../../common/auth/Authentication';

const envConfig = GetEnvironmentConfig();

const reportGenerationClient = new ReportGenerationClient(
    'us-west-2', // TODO: ReportGenerationClient only support us-west-2 right now, use envConfig.region in the future
    envConfig.reportGenerationEnvironment,
    getJwtToken,
    getProvider,
    undefined
);

/**
 * List of assessment types that have a template specifically for that assessment type
 */
export const ASSESSMENT_TYPES_WITH_TEMPLATES = ['MRA2', 'CMA', 'CFM'];

/**
 * List of assessment types that prefer to display long labels in full https://issues.amazon.com/A2T-1867
 * By default, longer labels are cut off to a fixed length so that the score barcharts are better aligned
 * Types in this list are the exceptions.
 */
export const ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS = ['CFM'];

export default reportGenerationClient;
