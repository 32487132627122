export const INPUT_COMMENT_MAX_LENGTH = 500;
export type SessionId = string;
export type ReferenceId = string;
export type SolicitId = string;

/** responseDoc related types **/
export interface ResponseDoc {
    content: Response[];
}

export interface Response {
    value: string;
    analysisActions: AnalysisActions[];
}

export enum AnalysisActions {
    AGGREGATION = 'aggregation',
    ARCHIVE = 'archive',
    DISTRIBUTION = 'distribution',
    DISTRIBUTION_DETAILS = 'distributionDetails',
}

export enum ResponseStatus {
    SUCCESS = 'success',
    LOADING = 'loading',
    FAILED = 'failed',
    INITIAL = '',
}

export type SaveResponseFunction = (response: Response) => Promise<void>;

/** sharedDoc related types **/
export interface SharedDoc {
    solicitId: string;
    content: SolicitInput[];
}

export interface SolicitInput {
    prompt?: string;
    inputDefinition?: MultipleChoiceInputDefinition | TextInputDefinition;
}

export interface InputDefinition {
    type: string;
}

export interface MultipleChoiceInputDefinition extends InputDefinition {
    options?: Option[];
}

export interface TextInputDefinition extends InputDefinition {
    placeholder?: string;
    maxLength?: number;
    hint?: string;
}

export interface Option {
    value: string;
    label: string;
    description?: string;
}

export enum SolicitInputTypes {
    MULTIPLE_CHOICE = 'multipleChoice',
    TEXT = 'text',
}

export interface SessionData {
    passcodeHash: string;
    username: string;
    role: string;
    prevResponses?: {
        [solicitId: string]: PrevResponse[];
    };
}

export enum SessionState {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    ENDED = 'ENDED',
}

export interface SessionInfo {
    activeUntil: string;
    createdAt: string;
    participantsCount: number;
    sessionId: string;
    sessionMetadata: string;
    sessionUrl: string;
    sharedDoc: string;
    state: SessionState;
}

export interface Sessions {
    [sessionId: string]: SessionData;
}

export interface PrevResponse {
    value: string;
}

export enum ResponseType {
    MULTIPLE_CHOICE = 'multipleChoice',
    TEXT = 'text',
}

export enum AnalysisAction {
    AGGREGATION = 'aggregation',
    ARCHIVE = 'archive',
    DISTRIBUTION = 'distribution',
    DISTRIBUTION_DETAILS = 'distributionDetails',
}

export enum AnswerType {
    YES_NO = 'yesno',
    NUMBER = 'number',
    TEXT = 'text',
    RATING_1_TO_5 = 'rating1to5',
}

export interface ResponseAnalysisResult {
    sessionId: SessionId;
    solicitId: SolicitId;
    analysisDoc: string; // from JSON.stringify(AnalysisDoc)
}

export interface AggregationAnalysisResult {
    count: number;
    sum: number;
}

export interface DistributionAnalysisResult {
    [response: string]: number;
}

export interface DistributionDetailsAnalysisResult {
    [participantId: string]: {
        username: string;
        role: string;
        timeJoined: string;
        value: string;
        updatedAt: string;
    };
}

export interface ParticipantToResponseRecordMap {
    [participantId: string]: ParticipantResponseRecord;
}

export interface ParticipantResponseRecord {
    comment?: string;
    commentedAt?: string;
    vote?: string;
    votedAt?: string;
    authorRole: string;
    authorName: string;
    authorJoinedAt: string;
}

export interface ParticipantContent {
    username: string;
    role: string;
    timeJoined: string; // e.g. 2021-12-10T22:29:59.712Z
    value: string;
    updatedAt: string; // e.g. 2021-12-11T13:48:32.770Z
}
export interface ArchiveAnalysisResult {
    participantContent: { [participantId: string]: ParticipantContent };
}

export type AnalysisResult = AggregationAnalysisResult | DistributionAnalysisResult | DistributionDetailsAnalysisResult | ArchiveAnalysisResult;

export interface AnalysisContent {
    analysisResults: { [value in AnalysisAction]?: AnalysisResult };
}
export interface AnalysisDoc {
    content: AnalysisContent[];
}

export type PromptIdToAnalysisDocMap = Map<string, AnalysisDoc>;

export const defaultAnalysisDoc: AnalysisDoc = { content: [] };

export const emptySharedDoc: string = JSON.stringify({});
