import { AuthContext, FlashProvider as FlashMessagesProvider } from '@amzn/awscat-react-components';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { useIntl } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';

import App from './App';
import wbsClient from './api/wbs/WbsClient';
import { AppLabelsContext } from './common/AppLabelsContext';
import Constants from './common/Constants';
import { getIntlAppLabels } from './common/IntlAppLabels';
import authentication from './common/auth/Authentication';
import { CookieConsentProvider } from './common/context/CookieConsentContext';
import { CustomerFilterContext } from './components/customers/CustomerFilterContext';
import { store } from './components/redux/configureStore';

const AppWithContexts = (): JSX.Element => {
    const [customer, setCustomer] = React.useState(Constants.DEFAULT_CUSTOMER);

    return (
        <ReduxProvider store={store}>
            <AppLabelsContext.Provider value={{ appLabels: getIntlAppLabels(useIntl()) }}>
                <FlashMessagesProvider>
                    <AuthContext.Provider value={{ auth: authentication }}>
                        <CustomerFilterContext.Provider value={{ customer, setCustomer }}>
                            <ApolloProvider client={wbsClient}>
                                <CookieConsentProvider>
                                    <App />
                                </CookieConsentProvider>
                            </ApolloProvider>
                        </CustomerFilterContext.Provider>
                    </AuthContext.Provider>
                </FlashMessagesProvider>
            </AppLabelsContext.Provider>
        </ReduxProvider>
    );
};

export default AppWithContexts;
