import './AssessmentDetailsHeader.scss';

import { AssessmentStatus, UpdateAssessmentInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Box, Button, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import AssessmentDetailsHeaderEditTemplateComponents from './AssessmentDetailsHeaderEditTemplateComponents';
import AssessmentDetailsStatusBar from './AssessmentDetailsStatusBar';
import Paths from '../../../../Paths';
import a2sApolloClient from '../../../../api/a2s/ApolloClient';
import { UPDATE_ASSESSMENT_BASE } from '../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import Constants from '../../../../common/Constants';
import rumClient from '../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../localization/LocalizationContext';
import { useAppSelector } from '../../../redux/hooks';
import { assessmentIsReadOnly } from '../../Utils';
import LiveSessionHeaderBar from '../../live-sessions/LiveSessionHeaderBar';
import { updateAssessmentBaseSuccessful } from '../CurrentAssessmentSlice';

type AssessmentDetailsHeaderProps = AppLabelsContextInterface &
    AuthContextInterface &
    FlashContextInterface & {
        readOnly: boolean;
        showLiveSession: boolean;
        showSnapshots: boolean;
        shouldDisplayTemplate: boolean;
        showCompleteAssessment: boolean;
    };

const AssessmentDetailsHeader: FunctionComponent<AssessmentDetailsHeaderProps> = ({
    appLabels,
    auth,
    flash,
    showCompleteAssessment,
    showLiveSession,
    readOnly,
    shouldDisplayTemplate,
}): JSX.Element => {
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const myUserId = auth?.getUserInfo()?.userId;
    const isReadOnly = assessmentIsReadOnly(myUserId, currentAssessment, assessmentId);
    const history = useHistory();
    const dispatch = useDispatch();

    const liveSessionHeader = useCallback(() => {
        return showLiveSession ? <LiveSessionHeaderBar readOnly={readOnly} /> : null;
    }, [showLiveSession, readOnly]);

    const [updateAssessmentBase, { loading }] = useMutation(UPDATE_ASSESSMENT_BASE, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const response = data.updateAssessment;
            if (response) {
                dispatch(updateAssessmentBaseSuccessful({ assessmentId, response }));
                if (response.status === AssessmentStatus.COMPLETED) {
                    history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/postcomplete`);
                }
            }
        },
        onError: (error) => {
            flash.alert(FlashType.error, appLabels.assessment.update.error_updating_assessment, error.message);
            rumClient.recordError(error);
        },
    });

    const onCompleteAssessment = useCallback(async () => {
        updateAssessmentBase({
            variables: {
                id: assessmentId,
                input: { status: AssessmentStatus.COMPLETED } as UpdateAssessmentInput,
            },
        });
    }, [updateAssessmentBase, assessmentId]);

    const completeAssessmentButton = useCallback(() => {
        return showCompleteAssessment ? (
            // align the button with the rest of the header bar components, e.g. live session dropdown buttons
            <div className='awscat-assessment-header-bar-components'>
                <Button
                    variant='primary'
                    disabled={isReadOnly}
                    onClick={() => onCompleteAssessment()}
                    loading={loading}
                    data-testid='btn-complete-assessment'
                >
                    {appLabels.assessment.complete.title}
                </Button>
            </div>
        ) : null;
    }, [showCompleteAssessment, onCompleteAssessment, loading, isReadOnly, appLabels]);

    const finalizeTemplateButton = useCallback(() => {
        return assessmentId === Constants.SAMPLE_ASSESSMENT_ID ? (
            <Button
                variant='primary'
                onClick={() => {
                    rumClient.recordClick(`${Paths.BASE_ASSESSMENTS_PATH}/${Constants.SAMPLE_ASSESSMENT_ID}/btn-finalize-template`);
                    history.push(Paths.FINALIZE_TEMPLATE_PATH);
                }}
                data-testid='btn-finalize-template'
            >
                {appLabels.manage_templates.actions.finalize_template}
            </Button>
        ) : null;
    }, [assessmentId, history, appLabels.manage_templates.actions.finalize_template]);

    return (
        <header className='assessment-header' data-testid='assessment-details-header'>
            <Box padding={{ left: 'm', right: 'm' }}>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <div>
                        <AssessmentDetailsStatusBar shouldDisplayTemplate={shouldDisplayTemplate} />
                    </div>
                    <div className='assessment-details-header-right-column'>
                        <SpaceBetween direction='horizontal' size='m'>
                            {liveSessionHeader()}
                            {finalizeTemplateButton()}
                            {shouldDisplayTemplate && <AssessmentDetailsHeaderEditTemplateComponents />}
                            {completeAssessmentButton()}
                        </SpaceBetween>
                    </div>
                </Grid>
            </Box>
        </header>
    );
};

export default withLocalizationContext(withAuthContext(withAppLabelsContext(withFlashContext(AssessmentDetailsHeader))));
