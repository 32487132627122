import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AssessmentFeaturesState {
    authorizedFeatures: AssessmentFeature[];
}

export const initialAssessmentFeaturesState: AssessmentFeaturesState = {
    authorizedFeatures: [],
};

export const AssessmentFeaturesSlice = createSlice({
    name: 'AssessmentFeaturesState',
    initialState: initialAssessmentFeaturesState,
    reducers: {
        loadAuthorizedFeatures: (state, action: PayloadAction<AssessmentFeature[]>) => {
            state.authorizedFeatures = action.payload;
        },
    },
});

export const { loadAuthorizedFeatures } = AssessmentFeaturesSlice.actions;

export default AssessmentFeaturesSlice.reducer;
