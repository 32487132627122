import { Box, ExpandableSection } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';
import { FormattedNumber } from 'react-intl';

import { AppLabels } from '../../../../../common/AppLabels';
import { Comment } from '../../../facilitate/body/CommentBox';
import CommentsThread from '../../../facilitate/body/CommentsThread';

export type PartipantCommentsProps = {
    appLabels: AppLabels;
    participantComments: Comment[] | null;
    questionNumber: number;
    numberOfQuestions: number;
};

const PartipantComments: FunctionComponent<PartipantCommentsProps> = ({ appLabels, participantComments, questionNumber, numberOfQuestions }) => {
    const isLastQuestion = questionNumber === numberOfQuestions - 1;
    const dividerClass = isLastQuestion ? '' : 'question-divider';
    const numberOfComments = participantComments?.length;
    if (!numberOfComments) {
        return (
            <Box className={`question-participant-comments ${dividerClass}`}>
                <FormattedNumber value={0} />
                {` ${appLabels.assessment.results.review_all.report.participant_comments}`}
            </Box>
        );
    } else {
        return (
            <ExpandableSection
                header={`${numberOfComments} ${appLabels.assessment.results.review_all.report.participant_comments}`}
                className={`question-participant-comments ${dividerClass}`}
            >
                <CommentsThread commentHeader='' comments={participantComments} usePopover={false} />
            </ExpandableSection>
        );
    }
};

export default PartipantComments;
