import './CustomerDetails.scss';

import { AssessmentFeature, CustomerAccountInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { CreateAccountCommand } from '@amzn/customer-account-service-typescript-client';
import { useMutation } from '@apollo/client';
import { ValidationError, validate } from 'class-validator';
import { FunctionComponent, useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import CustomerDetailsPanel from './CustomerDetailsPanel';
import { createPartnerCustomerSuccess } from './PartnerCustomersSlice';
import a2sApolloClient from '../../api/a2s/ApolloClient';
import { CREATE_PARTNER_CUSTOMER_ACCOUNT } from '../../api/a2s/ApolloQueries';
import { getCustomerAccountServiceLambdaClient } from '../../api/cas/CustomerAccountServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import rumClient from '../../common/monitoring/RumClient';
import { PartnerCustomerAccount } from '../../models/PartnerCustomerAccount';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../common/RequestStatusFlashbar';
import { openAppHelpPanel } from '../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const defaultState: CustomerAccountInput = {
    accountName: null,
    referenceId: null,
};

const CustomerCreate: FunctionComponent<AppLabelsContextInterface & AuthContextInterface> = ({ appLabels, auth }): JSX.Element => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [customerAccountInputState, setCustomerAccountInputState] = useState<CustomerAccountInput>(defaultState);
    const [stateChanged, setStateChanged] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const myUserId = auth?.getUserInfo()?.userId;

    const [createCustomerAccount, { loading }] = useMutation(CREATE_PARTNER_CUSTOMER_ACCOUNT, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const customer = data.createCustomerAccount;
            if (customer) {
                dispatch(createPartnerCustomerSuccess({ ...customer, accountName: customer.accountName }));
                // successfully created customer, exit create page
                onCancel();
            }
        },
        onError: (error) => {
            setRequestStatus({
                ...requestStatus,
                loading,
                messageType: FlashType.error,
                messageHeader: appLabels.customer_selection.create.error_creating_customer,
                messageContent: error.message ?? '',
            });
            rumClient.recordError(error);
        },
    });

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    const onStateChange = useCallback(
        (subStateValue: Partial<CustomerAccountInput>) => {
            const newState = {
                ...customerAccountInputState,
                ...subStateValue,
            };
            setCustomerAccountInputState(newState);
            setStateChanged(true);
        },
        [customerAccountInputState]
    );

    const onCreate = useCallback(async () => {
        try {
            const customerAccountDetails: PartnerCustomerAccount = Object.assign(new PartnerCustomerAccount(), customerAccountInputState);
            const validationErrors = await validate(customerAccountDetails);
            setValidationErrors(validationErrors);
            if (!(validationErrors && validationErrors.length)) {
                if (cas2IsEnabled) {
                    const { accountName, referenceId } = customerAccountInputState;
                    const casClient = await getCustomerAccountServiceLambdaClient();
                    const createAccountResults = await casClient.send(
                        new CreateAccountCommand({
                            accountName,
                            externalRefId: referenceId,
                        })
                    );
                    const responseMetadata = createAccountResults?.$metadata;
                    const accountId = createAccountResults?.accountId;
                    if (responseMetadata?.httpStatusCode === 200 && accountId) {
                        dispatch(
                            createPartnerCustomerSuccess({
                                id: accountId,
                                accountName: accountName,
                                referenceId: referenceId,
                                createdBy: myUserId,
                                updatedBy: '',
                                website: '',
                                industry: '',
                                territory: '',
                                organizationId: '',
                                ownerId: '',
                                geo: '',
                                region: '',
                                segment: '',
                            })
                        );
                        // successfully created customer, exit create page
                        onCancel();
                    } else {
                        const messageContent = `Failed to create customer. statusCode=${responseMetadata?.httpStatusCode} requestId=${responseMetadata?.requestId}`;
                        setRequestStatus({
                            ...requestStatus,
                            loading,
                            messageType: FlashType.error,
                            messageHeader: appLabels.customer_selection.create.error_creating_customer,
                            messageContent: messageContent,
                        });
                    }
                } else {
                    createCustomerAccount({
                        variables: {
                            input: customerAccountInputState,
                        },
                    });
                }
            }
        } catch (error: any) {
            rumClient.recordError(error);
            setRequestStatus({
                messageType: FlashType.error,
                loading: false,
                messageHeader: appLabels.customer_selection.create.error_creating_customer,
                messageContent: error.message ?? '',
            });
        }
    }, [
        appLabels.customer_selection.create.error_creating_customer,
        cas2IsEnabled,
        createCustomerAccount,
        customerAccountInputState,
        dispatch,
        loading,
        myUserId,
        onCancel,
        requestStatus,
    ]);

    return (
        <div className='awscat-customer-details'>
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.customer_selection.create.title}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' id='customer-create-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <div className='customer-create-form'>
                <SpaceBetween direction='vertical' size='l'>
                    <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
                    <CustomerDetailsPanel validationErrors={validationErrors} state={customerAccountInputState} onChange={onStateChange} />
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button variant='link' onClick={onCancel}>
                                {appLabels.user_actions.cancel}
                            </Button>
                            <Button variant='primary' disabled={!stateChanged} loading={loading} onClick={onCreate}>
                                {appLabels.user_actions.create}
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(CustomerCreate));
