import { Box, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useEffect, useReducer } from 'react';

import AssessmentsCards from './AssessmentsCards';
import AssessmentsHelpPanel from './AssessmentsHelpPanel';
import AssessmentsTable from './AssessmentsTable';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { isAllCustomerSelected } from '../../../common/Utils';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { initialAssessmentItems, setAssessmentSectionInAppSideNavigationPanel } from '../../common/side-navigation/AppSideNavigationSlice';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../../customers/CustomerFilterContext';
import { useAppDispatch } from '../../redux/hooks';
import { Action, ActionType } from '../AssessmentActions';
import Assessments from '../Assessments';
import { AssessmentsViewState } from '../AssessmentsViewState';
import DeleteAssessmentDialog from '../actions/DeleteAssessmentDialog';
import ShareAssessmentDialog from '../actions/ShareAssessmentDialog';

const assessmentReducer = (state: AssessmentsViewState, action: Action): AssessmentsViewState => {
    switch (action.type) {
        case ActionType.RELOAD_ASSESSMENTS:
            return { ...state, currentAssessmentAction: action, assessmentsUpdated: true };
        case ActionType.ASSESSMENTS_LOADED:
            return { ...state, currentAssessmentAction: action, assessmentsUpdated: false };
        case ActionType.SHARE_ASSESSMENT:
            return { ...state, currentAssessmentAction: action, selectedAssessment: action.assessment };

        case ActionType.DELETE_ASSESSMENT:
            return { ...state, currentAssessmentAction: action, selectedAssessment: action.assessment };

        case ActionType.CANCEL:
            return { ...state, currentAssessmentAction: action, selectedAssessment: null };
    }
};

const AssessmentsView = (props: CustomerFilterContextInterface & AppLabelsContextInterface) => {
    const appLabels: AppLabels = props.appLabels;

    const dispatch = useAppDispatch();

    const [assessmentsState, assessmentActionDispatch] = useReducer(assessmentReducer, {
        selectedAssessment: null,
        assessmentsUpdated: false,
        currentAssessmentAction: null,
    });

    const activateActionDialog = () => {
        switch (assessmentsState.currentAssessmentAction?.type) {
            case ActionType.DELETE_ASSESSMENT: {
                if (assessmentsState.selectedAssessment) {
                    return (
                        <DeleteAssessmentDialog
                            assessment={assessmentsState.selectedAssessment}
                            assessmentActionDispatch={assessmentActionDispatch}
                        />
                    );
                }
                break;
            }
            case ActionType.SHARE_ASSESSMENT: {
                if (assessmentsState.selectedAssessment) {
                    return (
                        <ShareAssessmentDialog assessment={assessmentsState.selectedAssessment} assessmentActionDispatch={assessmentActionDispatch} />
                    );
                }
                break;
            }
        }
        return undefined;
    };

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                content: <AssessmentsHelpPanel />,
            })
        );
        dispatch(openAppHelpPanel());
        dispatch(setAssessmentSectionInAppSideNavigationPanel(initialAssessmentItems));

        return cleanup;
    }, [assessmentsState.assessmentsUpdated, dispatch]);

    const displayHeader = () => {
        // ToDo: need a better way to be able to compose localized sentences with variables
        if (isAllCustomerSelected(props.customer)) {
            return `${appLabels.assessment.list.all_customers} ${appLabels.assessment.list.assessments}`;
        }
        return `${props.customer} ${appLabels.assessment.list.assessments}`;
    };

    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <Assessments />
            {activateActionDialog()}
            <SpaceBetween size='m'>
                <Box variant='h2'>
                    {displayHeader()}
                    <Box display='inline-block' margin={{ left: 'xs' }}>
                        <Link variant='info' id='assessment-list-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                            {appLabels.common.info}
                        </Link>
                    </Box>
                </Box>

                <AssessmentsCards assessmentActionDispatch={assessmentActionDispatch} />
                <AssessmentsTable assessmentActionDispatch={assessmentActionDispatch} />
            </SpaceBetween>
        </div>
    );
};

export default withAppLabelsContext(withCustomerFilterContext(AssessmentsView));
