/* eslint-disable @typescript-eslint/no-empty-interface */
import './AssessmentScoresResults.scss';

import { Box, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { FormattedNumber } from 'react-intl';

import AssessmentBarScore from './AssessmentBarScore';
import AssessmentScoresInfoPanel from './AssessmentScoresInfoPanel';
import { AppLabels } from '../../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { notEmpty } from '../../../../../common/Utils';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { filterExcludedSections } from '../../Utils';
import {
    AssessmentFacet,
    convertAssessmentSectionToAssessmentFacet,
    convertAssessmentWorkstreamToAssessmentFacet,
    getAssessmentMetadataProvider,
} from '../../model/Assessment';

/**
 * Generates Box for facet.
 * Box looks like:
 * ---------------------------------------------
 * | <facet_name>                       <score>|
 * |-------------------------------------------|
 * |                  |----------------        |
 * |     <activity_1> | <act_1_score> |        |
 * |                  |----------------        |
 * |                  |--------------------    |
 * |     <activity_2> |     <act_2_score> |    |
 * |                  |--------------------    |
 * |                  .                        |
 * |                  .                        |
 * |                  .                        |
 *
 * Notes:
 * 1) I tried using standard Grid, but the line separating the
 * activities/categories and scores would have gaps in it.
 * 2) I tried using Container to separate header and scores, but the padding
 * for the header made it unable to look like mock.
 * 3) 'transparent-bordered' helps the two sides line up. Without, the border for
 * the bar scores on the right makes them different sizes
 * @param assessmentFacet
 * @returns
 */
const getAssessmentFacetScores = (assessmentFacet: AssessmentFacet | null, scoreScale: ScoreScale, appLabels: AppLabels): JSX.Element | undefined => {
    const questionVectors = assessmentFacet?.questionVectors;
    const fontColor = scoreScale.getStratificationFontColor(assessmentFacet);
    const backgroundColor = scoreScale.getStratificationBackgroundColor(assessmentFacet);
    return (
        <Box className='facet-scores'>
            <div className='facet-header' style={{ backgroundColor }}>
                <Grid gridDefinition={[{ colspan: { m: 11, xxs: 8 } }, { colspan: { m: 1, xxs: 4 } }]}>
                    <div className='facet-name' style={{ color: fontColor }}>
                        {assessmentFacet?.name}
                    </div>
                    <div className='facet-score' style={{ color: fontColor }}>
                        {assessmentFacet?.score ? (
                            <FormattedNumber minimumSignificantDigits={2} maximumSignificantDigits={2} value={assessmentFacet?.score} />
                        ) : (
                            appLabels.assessment.results.review_all.scores.incomplete
                        )}
                    </div>
                </Grid>
            </div>
            <Box className='facets-and-scores-box'>
                <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]} disableGutters={true}>
                    <Box className='scored-items-box'>
                        {questionVectors?.map((vector) => (
                            <Box className='scored-item-box'>
                                <Box className='transparent-bordered scored-item'>{vector?.name || appLabels.common.unknown}</Box>
                            </Box>
                        ))}
                    </Box>
                    <Box className='scores-box'>
                        {questionVectors?.map((vector) => (
                            <Box className='score-box'>
                                <AssessmentBarScore
                                    score={vector?.score}
                                    maxScore={scoreScale.maxScore}
                                    backgroundColor={scoreScale.getStratificationBackgroundColor(vector)}
                                    fontColor={scoreScale.getStratificationFontColor(vector)}
                                ></AssessmentBarScore>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};

/**
 * Present scores as bars.
 * If workstreams are present, present them. Otherwise, present the sections.
 * Workstreams or sections will be converted into 'AssessmentFacets' for code reuse purposes
 * @param props
 * @returns
 */
const AssessmentScoresResults: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = currentAssessment?.id;
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    let assessmentFacets: AssessmentFacet[] | null | undefined = [];
    const templateDefaults = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.defaults);
    const description = templateDefaults?.report?.scoreBarChart?.title || '';
    // const maxScore = currentAssessment?.template?.defaults?.report?.scores?.maxRating; // later on, this can be used to select ScoreScale
    const workstreams = currentAssessment?.template?.workstreams;

    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessment?.template?.defaults?.questionnaireAnswers, appLabels);
    }, [appLabels, currentAssessment?.template?.defaults?.questionnaireAnswers]);

    /**
     * Takes each 'AssessmentFacet' (a Perspective or Workstream) and generates Boxes for them
     * @param facets
     * @returns
     */
    const getAssessmentFacetsScores = (
        facets: (AssessmentFacet | null)[] | null | undefined,
        description: string,
        scoreScale: ScoreScale,
        appLabels: AppLabels
    ): JSX.Element | undefined => {
        const facetsScores = facets?.map((facet) => getAssessmentFacetScores(facet, scoreScale, appLabels));
        return (
            <SpaceBetween size='m'>
                <SpaceBetween size='s' direction='horizontal'>
                    <Box variant='h3'>{description}</Box>
                    <Link variant='info' id='assessment-scores-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </SpaceBetween>
                <div>
                    <Grid gridDefinition={[{ colspan: { l: 6, m: 8, xxs: 12 } }, { colspan: { l: 6, m: 4, xxs: 0 } }]}>
                        <SpaceBetween size='xxl' direction='vertical'>
                            {facetsScores?.map((facetScores) => (
                                <div>{facetScores}</div>
                            ))}
                        </SpaceBetween>
                    </Grid>
                </div>
            </SpaceBetween>
        );
    };

    const promptMetadataProvider = getAssessmentMetadataProvider(currentAssessment);
    if (workstreams?.length > 0) {
        assessmentFacets = workstreams
            .filter(notEmpty)
            .map((workstream) => convertAssessmentWorkstreamToAssessmentFacet(assessmentId, selectedSnapshotId, workstream, promptMetadataProvider));
    } else {
        const excludeSections = templateDefaults?.report?.report?.excludeSections;
        const includedSections = filterExcludedSections(currentAssessment?.template?.sections, excludeSections, currentAssessment);
        assessmentFacets = includedSections
            ?.filter(notEmpty)
            .map((perspective) => convertAssessmentSectionToAssessmentFacet(assessmentId, selectedSnapshotId, perspective, promptMetadataProvider));
    }

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.results.review_all.scores.scores,
                content: <AssessmentScoresInfoPanel scoreScale={scoreScale} />,
                footer: '',
            })
        );
        return cleanup;
    }, [appLabels.assessment.results.review_all.scores.scores, dispatch, scoreScale]);

    return getAssessmentFacetsScores(assessmentFacets, description, scoreScale, appLabels) || null;
};

export default withAppLabelsContext(AssessmentScoresResults);
