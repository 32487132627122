import { FlashType } from '@amzn/awscat-react-components';
import { Box, Button } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';

import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { ACCEPT_ASSESSMENT_INVITATION } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import RequestStatusFlashbar, { RequestStatus } from '../../common/RequestStatusFlashbar';

type AcceptAssessmentInvitationProps = AppLabelsContextInterface & {
    invitationId: string;
};

const AcceptAssessmentInvitation: FunctionComponent<AcceptAssessmentInvitationProps> = ({ appLabels, invitationId }): JSX.Element => {
    const initialRequestStatus: RequestStatus = {
        loading: false,
        messageType: FlashType.info,
        messageHeader: appLabels.assessment.share.accept_invitation,
        messageContent: appLabels.assessment.share.accept_invitation_message,
    };
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(initialRequestStatus);

    const [acceptAssessmentInvitation, { called, loading }] = useMutation(ACCEPT_ASSESSMENT_INVITATION, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const result = data.acceptAssessmentInvitation;
            if (result) {
                setRequestStatus({
                    ...requestStatus,
                    loading,
                    messageType: FlashType.success,
                    messageHeader: appLabels.assessment.share.accept_invitation,
                    messageContent: appLabels.assessment.share.accept_invitation_successful,
                });
            }
        },
        onError: (error) => {
            setRequestStatus({
                ...requestStatus,
                loading,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.share.error_accept_invitation,
                messageContent: error.message ?? '',
            });
        },
    });

    useEffect(() => {
        if (!called && invitationId) {
            acceptAssessmentInvitation({
                variables: {
                    invitationId,
                },
            });
        }
    }, [acceptAssessmentInvitation, called, invitationId]);

    return (
        <div>
            <RequestStatusFlashbar dismissible={false} requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Box variant='h2' float='right' data-testid='btn-view-all-assessments'>
                <Button href='/assessments' variant='primary'>
                    {appLabels.side_navigation.view_all_assessments}
                </Button>
            </Box>
        </div>
    );
};

export default withAppLabelsContext(AcceptAssessmentInvitation);
