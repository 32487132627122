import { AssessmentPrompt, ResponseType, UpdatePromptInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Box, Button, Modal, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import { UPDATE_PROMPT } from '../../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import { removeInProgressRequest, updateTemplatePrompt } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { useAppSelector } from '../../../../redux/hooks';

type ChangeQuestionTypeModalProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

const ChangeQuestionTypeModal: FunctionComponent<ChangeQuestionTypeModalProps> = ({ appLabels, visible, setVisible }) => {
    const dispatch = useDispatch();

    const currentPrompt: AssessmentPrompt | undefined = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);

    const options: SelectProps.Option[] = [
        { label: appLabels.manage_templates.template.question_types.text, value: ResponseType.Text },
        { label: appLabels.manage_templates.template.question_types.rating, value: ResponseType.Rating },
        { label: appLabels.manage_templates.template.question_types.yes_no, value: ResponseType.YesNo },
        { label: appLabels.manage_templates.template.question_types.number, value: ResponseType.Number },
        { label: appLabels.manage_templates.template.question_types.single_selection, value: ResponseType.SingleSelection },
        { label: appLabels.manage_templates.template.question_types.multi_selection, value: ResponseType.MultiSelection },
        { label: appLabels.manage_templates.template.question_types.date, value: ResponseType.DateValue },
    ];
    const [selectedResponseType, setSelectedResponseType] = useState<ResponseType>(currentPrompt?.responseType);

    // Keep state in sync with changing prompts
    useEffect(() => {
        setSelectedResponseType(currentPrompt?.responseType);
    }, [currentPrompt?.responseType]);

    const [updatePromptMutation, { loading }] = useMutation(UPDATE_PROMPT, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (data, options) => {
            const updatedPrompt = data.updatePrompt as AssessmentPrompt;
            dispatch(updateTemplatePrompt(updatedPrompt));
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    return (
        <Modal
            data-testid='change-question-type-modal'
            onDismiss={() => setVisible(false)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loading} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            loading={loading}
                            onClick={async () => {
                                try {
                                    const updatePromptInput: UpdatePromptInput = {
                                        promptId: currentPrompt.promptId,
                                        responseType: selectedResponseType,
                                    };
                                    const requestId = uuid();
                                    await updatePromptMutation({ variables: { input: updatePromptInput }, context: { requestId } });
                                } finally {
                                    setVisible(false);
                                }
                            }}
                            data-testid='btn-confirm-change-question-type'
                        >
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.edit.modify_structure.change_question_type}
            visible={visible}
        >
            <SpaceBetween size='m'>
                <Select
                    data-testid='change-question-type-selection'
                    selectedOption={options.find(({ value }) => value === selectedResponseType)}
                    options={options}
                    onChange={({ detail }) => {
                        setSelectedResponseType(detail.selectedOption.value as ResponseType);
                    }}
                />
            </SpaceBetween>
        </Modal>
    );
};

export default withAppLabelsContext(ChangeQuestionTypeModal);
