import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Grid,
    Header,
    Input,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { Account, CustomerAccountServiceLambdaClient, ListAccountsCommand } from '@amzn/customer-account-service-typescript-client';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getCustomerAccountServiceLambdaClient } from '../../../api/cas/CustomerAccountServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import { isAWSUser, isAllCustomerSelected } from '../../../common/Utils';
import rumClient from '../../../common/monitoring/RumClient';
import EmptyTable from '../../common/EmptyTable';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { CustomerAccountOwner, CustomerCreatedBy, CustomerExternalReferenceId, CustomerName } from '../../customers/CustomerDetails';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../../customers/CustomerFilterContext';
import { CustomerViewModel } from '../../customers/CustomerViewModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const MAX_NUMBER_CUSTOMERS_TO_SEARCH = 40;
interface ResultError {
    message: string;
    path: Array<string | number>;
}

type ChangeHandler = (data: { account?: CustomerViewModel }) => void;

type AssessmentCustomerPanelProps = FlashContextInterface &
    AuthContextInterface &
    AppLabelsContextInterface &
    CustomerFilterContextInterface & {
        accounts: CustomerViewModel[];
        onChange: ChangeHandler;
        selectedAccount: CustomerViewModel;
        searchKeyword: string;
        setSearchKeyword: any;
        tableItems: CustomerViewModel[];
        setTableItems: any;
        requestStatus: RequestStatus;
        setRequestStatus: any;
    };

const AssessmentCustomerPanel: React.FunctionComponent<AssessmentCustomerPanelProps> = ({
    auth,
    selectedAccount,
    onChange,
    appLabels,
    customer,
    tableItems,
    setTableItems,
    searchKeyword,
    setSearchKeyword,
    requestStatus,
    setRequestStatus,
}): JSX.Element => {
    const customerList: CustomerViewModel[] = useAppSelector((state) => state.customerState.customers);
    const openHelp = useAppSelector((state) => state.appHelpPanelState.open);
    const partnerCustomerAccounts = useAppSelector((state) => state.partnerCustomerState.customers);
    const dispatch = useAppDispatch();
    const isAwsUser = isAWSUser(auth?.getUserInfo());
    const history = useHistory();

    /**
     * Convert an account in ListAccount response to CustomerViewModel
     * @param account an account in the ListAccount response
     * @returns CustomerViewModel
     */
    const getCustomerViewModelFromAccount = (account: Account): CustomerViewModel => {
        const territorySegment = account.territorySegment || '?';
        const territoryGeo = account.territoryGeo || '?';
        const territoryRegion = account.territoryRegion || '?';
        const territoryBusinessUnit = account.territoryBusinessUnit || '?';
        const territory = `${territorySegment}-${territoryGeo}-${territoryRegion}-${territoryBusinessUnit}`.replace('?-?-?-?', '');
        return {
            id: account.accountId,
            organizationId: account.orgId,
            referenceId: account.externalRefId,
            accountName: account.accountName,
            ownerId: account.accountOwnerName || '',
            createdBy: account.createdBy,
            updatedBy: account.updatedBy,
            territory,
        } as CustomerViewModel;
    };

    const searchCustomersById = useCallback(
        /**
         * List all customers with given ID (normally there should be only one customer for each ID)
         * @param casClient
         * @param accountSearchKeyword
         * @returns
         */
        async (
            casClient: CustomerAccountServiceLambdaClient,
            accountSearchKeyword: string
        ): Promise<{ customerAccounts: CustomerViewModel[]; errors: ResultError[] }> => {
            const customerAccounts: CustomerViewModel[] = [];
            const errors: ResultError[] = [];
            try {
                const accountIdResponse = await casClient.send(new ListAccountsCommand({ accountIdList: [accountSearchKeyword] }));
                if (accountIdResponse.accounts.length > 0) {
                    customerAccounts.push(...accountIdResponse.accounts.map(getCustomerViewModelFromAccount));
                }
            } catch (error: any) {
                // Mute validation errors that occurred during the searchById. It's normal that a user only enters the prefix of account name
                // to call searchByName, where the prefix is invalid in terms of length by the standard of account ID.
                // Log and display non-validation errors.
                if (!error.message?.includes('validation error')) {
                    rumClient.recordError(error);
                    errors.push(error);
                }
            }
            return { customerAccounts, errors };
        },
        []
    );

    const searchCustomersByName = useCallback(
        /**
         * List all customers whose names contain the given keyword
         * @param casClient
         * @param accountSearchKeyword
         */
        async (
            casClient: CustomerAccountServiceLambdaClient,
            accountSearchKeyword: string
        ): Promise<{ customerAccounts: CustomerViewModel[]; errors: ResultError[] }> => {
            const customerAccounts: CustomerViewModel[] = [];
            const errors: ResultError[] = [];
            let nextToken: string | undefined | null = undefined;
            do {
                try {
                    const response = await casClient.send(new ListAccountsCommand({ accountName: accountSearchKeyword, nextPageToken: nextToken }));
                    if (response?.accounts && response?.accounts.length) {
                        customerAccounts.push(...response.accounts.map(getCustomerViewModelFromAccount));
                    }
                    nextToken = response?.nextPageToken;
                } catch (error: any) {
                    rumClient.recordError(error);
                    if (error.message) {
                        errors.push(error);
                    }
                }
            } while (nextToken !== undefined && nextToken !== null && customerAccounts.length < MAX_NUMBER_CUSTOMERS_TO_SEARCH);
            return { customerAccounts, errors };
        },
        []
    );

    const getCustomerAccounts = useCallback(
        /**
         * List all accounts whose IDs or names match with the given keyword
         * @param accountSearchKeyword
         * @returns
         */
        async (accountSearchKeyword: string | null): Promise<{ errors: ResultError[]; customerAccounts: CustomerViewModel[] }> => {
            const casClient = await getCustomerAccountServiceLambdaClient();
            // CAS APIs have external SFDC dependencies and therefore could take longer to complete (~3s)
            // Reducing the A2T latency by parallelizing the calls to searchById and searchByName
            const [searchByIdResults, searchByNameResults] = await Promise.all([
                searchCustomersById(casClient, accountSearchKeyword),
                searchCustomersByName(casClient, accountSearchKeyword),
            ]);
            const customerAccounts: CustomerViewModel[] = [...searchByIdResults.customerAccounts, ...searchByNameResults.customerAccounts];
            const errors: ResultError[] = [...searchByIdResults.errors, ...searchByNameResults.errors];
            return { customerAccounts, errors };
        },
        [searchCustomersById, searchCustomersByName]
    );

    const columnDefinitions: TableProps.ColumnDefinition<CustomerViewModel>[] = [
        {
            id: 'customerAccount',
            header: appLabels.assessment.create.customer_account,
            cell: (e: CustomerViewModel) => <CustomerName defaultCustomerName={e.accountName} customerAccountId={e.id} />,
        },
        {
            id: 'accountId',
            header: appLabels.assessment.create.account_id,
            cell: (e: CustomerViewModel) => e.id,
        },
    ];

    if (isAwsUser) {
        columnDefinitions.push({
            id: 'owner',
            header: appLabels.assessment.create.owner,
            cell: (e: CustomerViewModel) => <CustomerAccountOwner customerAccountId={e.id} defaultCustomerAccountOwner={e.ownerId} />,
        });
        columnDefinitions.push({
            id: 'territory',
            header: appLabels.assessment.create.territory,
            cell: (e: CustomerViewModel) => e.territory,
        });
    } else {
        columnDefinitions.push({
            id: 'referenceId',
            header: appLabels.customer_selection.reference_id,
            cell: (e: CustomerViewModel) => (
                <CustomerExternalReferenceId customerAccountId={e.id} defaultCustomerExternalReferenceId={e.referenceId} />
            ),
        });
        columnDefinitions.push({
            id: 'createdBy',
            header: appLabels.customer_selection.created_by,
            cell: (e: CustomerViewModel) => <CustomerCreatedBy customerAccountId={e.id} defaultCreatedBy={e.createdBy} />,
        });
    }

    const filterItems = (item: CustomerViewModel, filteringText: string): boolean => {
        const rowItems = [item.accountName, item.id, item.referenceId, item.createdBy];
        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItems.some((searchContent) => {
            return searchContent?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
        });
    };

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_CUSTOMERS,
    });

    const { items, collectionProps, paginationProps } = useCollection(tableItems ?? [], {
        filtering: {
            empty: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            noMatch: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });

    const tableOptionsSetting = () => [
        { label: `10 ${appLabels.customer_selection.customers}`, value: 10 },
        { label: `25 ${appLabels.customer_selection.customers}`, value: 25 },
        { label: `50 ${appLabels.customer_selection.customers}`, value: 50 },
        { label: `100 ${appLabels.customer_selection.customers}`, value: 100 },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.customer_selection.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.customer_selection.preference_title}
        />
    );

    const loadCustomers = useCallback(
        async (refresh: boolean): Promise<void> => {
            if (searchKeyword && searchKeyword.length > 0) {
                // Search customer account if search keyword is provided
                if (refresh) {
                    let errorMessage = null;
                    try {
                        setRequestStatus({ ...defaultRequestStatus, loading: true });
                        const { customerAccounts, errors } = await getCustomerAccounts(searchKeyword);
                        setTableItems(customerAccounts);
                        if (errors.length > 0) {
                            errorMessage = `${errors.length} ${appLabels.assessment.create.load_customers_error_message} ${errors[0].message}`;
                        }
                    } catch (error) {
                        rumClient.recordError(error);
                        errorMessage = error;
                    }
                    if (errorMessage) {
                        setRequestStatus({
                            loading: false,
                            messageType: FlashType.error,
                            messageHeader: appLabels.assessment.create.error_getting_customer_accounts,
                            messageContent: errorMessage,
                        });
                    } else {
                        setRequestStatus({ ...defaultRequestStatus, loading: false });
                    }
                }
            } else {
                // No search keyword, use default list
                if (customer && !isAllCustomerSelected(customer)) {
                    // Specific customer selected
                    const selectedCustomer: CustomerViewModel = customerList.filter((c) => c.accountName === customer)?.at(0);
                    if (selectedAccount && selectedAccount !== tableItems?.at(0)) {
                        setTableItems([selectedCustomer]);
                    }
                    if (selectedCustomer) {
                        if (selectedAccount === null || selectedAccount?.accountName !== selectedCustomer.accountName) {
                            onChange({ account: selectedCustomer });
                        }
                    }
                } else if (isAllCustomerSelected(customer)) {
                    const numberOfCustomers = partnerCustomerAccounts.length + customerList.length;
                    // All customer selected
                    if (numberOfCustomers && tableItems?.length !== numberOfCustomers) {
                        setTableItems(partnerCustomerAccounts.concat(customerList));
                    }
                }
            }
        },
        [
            searchKeyword,
            setRequestStatus,
            getCustomerAccounts,
            setTableItems,
            appLabels.assessment.create.load_customers_error_message,
            appLabels.assessment.create.error_getting_customer_accounts,
            customer,
            customerList,
            selectedAccount,
            tableItems,
            onChange,
            partnerCustomerAccounts,
        ]
    );

    const includeCreateCustomerButtonIfApplicable = useCallback(() => {
        if (!isAwsUser) {
            return (
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        history.push('/customers/create');
                    }}
                >
                    {appLabels.customer_selection.create_customer}
                </Button>
            );
        }
        return null;
    }, [appLabels.customer_selection.create_customer, history, isAwsUser]);

    const getTableFilter = useCallback(() => {
        return (
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }]}>
                    <Input
                        value={searchKeyword}
                        placeholder={appLabels.assessment.create.select_customer.filter_placeholder}
                        onChange={(event) => setSearchKeyword(event.detail.value)}
                        type='search'
                        data-testid='account-search-keyword'
                    />
                    <Button onClick={() => loadCustomers(true)} variant='primary' disabled={!searchKeyword} loading={requestStatus.loading}>
                        {appLabels.user_actions.search}
                    </Button>
                </Grid>
            </form>
        );
    }, [
        appLabels.assessment.create.select_customer.filter_placeholder,
        appLabels.user_actions.search,
        loadCustomers,
        requestStatus.loading,
        searchKeyword,
        setSearchKeyword,
    ]);

    useEffect(() => {
        loadCustomers(false);
        if (!selectedAccount && tableItems) {
            onChange({ account: tableItems?.at(0) });
        }
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.create.select_customer.title,
                content: isAwsUser
                    ? appLabels.assessment.create.select_customer.description
                    : appLabels.assessment.create.select_customer.description_partner,
                open: openHelp,
            })
        );
    }, [
        isAwsUser,
        appLabels.assessment.create.select_customer.title,
        appLabels.assessment.create.select_customer.description,
        appLabels.assessment.create.select_customer.description_partner,
        openHelp,
        dispatch,
        loadCustomers,
        onChange,
        selectedAccount,
        tableItems,
        tableItems?.length,
    ]);

    return (
        <SpaceBetween direction='vertical' size='xxs'>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Table
                {...collectionProps}
                items={items}
                columnDefinitions={columnDefinitions}
                loading={requestStatus.loading}
                loadingText={appLabels.assessment.create.loading_customers}
                selectionType='single'
                stickyHeader={true}
                selectedItems={selectedAccount ? [selectedAccount] : []}
                onSelectionChange={({ detail }) => {
                    onChange({ account: detail.selectedItems[0] });
                }}
                header={
                    <Header variant='h1' actions={includeCreateCustomerButtonIfApplicable()}>
                        {appLabels.assessment.create.customer_accounts}
                    </Header>
                }
                filter={getTableFilter()}
                preferences={tablePreferences}
                pagination={<Pagination {...paginationProps} />}
                empty={
                    <Box textAlign='center' color='inherit'>
                        <b>{appLabels.assessment.create.no_customer_accounts}</b>
                        <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                            {appLabels.assessment.create.no_customer_accounts_to_display}
                        </Box>
                    </Box>
                }
            />
        </SpaceBetween>
    );
};

export default withCustomerFilterContext(withAppLabelsContext(withAuthContext(withFlashContext(AssessmentCustomerPanel))));
