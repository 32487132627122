import { Effort, Priority, ProgressStatus, RecommendationOutput, RecommendationType } from '@amzn/aws-assessment-recommendation-service-client';
import { DatePicker, Input, Select, TableProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

import { AppLabels } from '../../../../../../common/AppLabels';
import Constants from '../../../../../../common/Constants';

export const getEffortOptions = (appLabels: AppLabels) => [
    {
        value: Effort.SHORT,
        label: appLabels.common.short,
    },
    {
        value: Effort.MEDIUM,
        label: appLabels.common.medium,
    },
    {
        value: Effort.LONG,
        label: appLabels.common.long,
    },
    {
        value: Effort.NA,
        label: appLabels.common.na,
    },
];

export const getPriorityOptions = (appLabels: AppLabels) => [
    {
        value: Priority.HIGH,
        label: appLabels.common.high,
    },
    {
        value: Priority.MEDIUM,
        label: appLabels.common.medium,
    },
    {
        value: Priority.LOW,
        label: appLabels.common.low,
    },
    {
        value: Priority.NA,
        label: appLabels.common.na,
    },
];

export const getProgressStatusOptions = (appLabels: AppLabels) => [
    {
        value: ProgressStatus.COMPLETED,
        label: appLabels.assessment.results.review_all.report.recommended_actions.completed,
    },
    {
        value: ProgressStatus.IN_PROGRESS,
        label: appLabels.assessment.results.review_all.report.recommended_actions.in_progress,
    },
    {
        value: ProgressStatus.NOT_STARTED,
        label: appLabels.assessment.results.review_all.report.recommended_actions.not_started,
    },
];

type ValueField = 'effort' | 'priority' | 'progressStatus';

/**
 * Given a value, find corresponding label in a list of selection options
 * @param appLabels localization labels
 * @param valueField field name of the given value
 * @param value value
 * @returns
 */
export const getLabelFromValue = (appLabels: AppLabels, valueField: ValueField, value: string): string => {
    let options = [];
    if (valueField === 'effort') {
        options = getEffortOptions(appLabels);
    } else if (valueField === 'priority') {
        options = getPriorityOptions(appLabels);
    } else if (valueField === 'progressStatus') {
        options = getProgressStatusOptions(appLabels);
    }
    return options.find((option) => option.value === value)?.label || '';
};

const SEVEN_PERCENT_COLUMN_WIDTH = '7%';
const TEN_PERCENT_COLUMN_WIDTH = '10%';
const FORTY_PERCENT_COLUMN_WIDTH = '40%';

export const getSortingComparatorForSelect = (fieldName: string | number, options: OptionDefinition[]) => {
    return (a: any, b: any) => {
        if (a[fieldName] && !b[fieldName]) {
            return -1;
        } else if (!a[fieldName] && b[fieldName]) {
            return 1;
        } else if (!a[fieldName] && !b[fieldName]) {
            return 0;
        }
        return options.findIndex((option) => option.value === a[fieldName]) - options.findIndex((option) => option.value === b[fieldName]);
    };
};

const getSortingComparatorForTextAndDate = (fieldName: string) => {
    return (a: any, b: any) => {
        if (a[fieldName] && !b[fieldName]) {
            return 1;
        } else if (!a[fieldName] && b[fieldName]) {
            return -1;
        } else if (!a[fieldName] && !b[fieldName]) {
            return 0;
        }
        return a[fieldName] > b[fieldName] ? -1 : 1;
    };
};

export const getRecommendationTableColumnDefs = (appLabels: AppLabels, isReadOnly: boolean): TableProps.ColumnDefinition<RecommendationOutput>[] => {
    const effortOptions = getEffortOptions(appLabels);
    const priorityOptions = getPriorityOptions(appLabels);
    const progressStatusOptions = getProgressStatusOptions(appLabels);
    const inlineEditDisabledText = appLabels.assessment.results.review_all.report.recommended_actions.inline_edit_disabled;
    const verbiageValidationText = `${appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_VERBIAGE_MIN_LENGTH} ${appLabels.common.and} ${Constants.RECOMMENDATION_VERBIAGE_MAX_LENGTH}`;
    const benefitValidationText = `${appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_BENEFIT_MIN_LENGTH} ${appLabels.common.and} ${Constants.RECOMMENDATION_BENEFIT_MAX_LENGTH}`;
    const actionOwnerValidationText = `${appLabels.common.length_must_be_between} ${Constants.RECOMMENDATION_ACTION_OWNER_MIN_LENGTH} ${appLabels.common.and} ${Constants.RECOMMENDATION_ACTION_OWNER_MAX_LENGTH}`;
    return [
        {
            width: FORTY_PERCENT_COLUMN_WIDTH,
            id: 'verbiage',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action,
            cell: (item) => item.verbiage,
            sortingComparator: getSortingComparatorForTextAndDate('verbiage'),
            sortingField: 'verbiage',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return <Input value={currentValue ?? item.verbiage} onChange={(event) => setValue(event.detail.value)}></Input>;
                },
                validation: (item, value) =>
                    value !== '' && !(value?.length > Constants.RECOMMENDATION_VERBIAGE_MAX_LENGTH) ? undefined : verbiageValidationText,
                disabledReason: (item) => (item.recommendationType === RecommendationType.CUSTOM && !isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            width: SEVEN_PERCENT_COLUMN_WIDTH,
            id: 'priority',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_priority,
            cell: (item) => priorityOptions.find((option) => item.priority === option.value)?.label,
            sortingComparator: getSortingComparatorForSelect('priority', priorityOptions),
            sortingField: 'priority',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={priorityOptions.find((option) => option.value === (currentValue ?? item.priority)) ?? null}
                            onChange={(event) => setValue(event.detail.selectedOption.value)}
                            options={priorityOptions}
                        />
                    );
                },
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            width: SEVEN_PERCENT_COLUMN_WIDTH,
            id: 'effort',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_effort,
            cell: (item) => effortOptions.find((option) => item.effort === option.value)?.label,
            sortingComparator: getSortingComparatorForSelect('effort', effortOptions),
            sortingField: 'effort',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={effortOptions.find((option) => option.value === (currentValue ?? item.effort)) ?? null}
                            onChange={(event) => setValue(event.detail.selectedOption.value)}
                            options={effortOptions}
                        />
                    );
                },
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            width: TEN_PERCENT_COLUMN_WIDTH,
            id: 'targetDate',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_target_date,
            cell: (item) => item.targetDate,
            sortingComparator: getSortingComparatorForTextAndDate('targetDate'),
            sortingField: 'targetDate',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <DatePicker
                            value={currentValue ?? item.targetDate}
                            onChange={(event) => {
                                setValue(event.detail.value);
                            }}
                        />
                    );
                },
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            maxWidth: FORTY_PERCENT_COLUMN_WIDTH,
            id: 'benefit',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_benefit,
            cell: (item) => item.benefit,
            sortingComparator: getSortingComparatorForTextAndDate('benefit'),
            sortingField: 'benefit',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return <Input value={currentValue ?? item.benefit} onChange={(event) => setValue(event.detail.value)}></Input>;
                },
                validation: (item, value) => (!(value?.length > Constants.RECOMMENDATION_BENEFIT_MAX_LENGTH) ? undefined : benefitValidationText),
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            width: TEN_PERCENT_COLUMN_WIDTH,
            id: 'progressStatus',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_progress,
            cell: (item) => progressStatusOptions.find((option) => item.progressStatus === option.value)?.label,
            sortingComparator: getSortingComparatorForSelect('progressStatus', progressStatusOptions),
            sortingField: 'progressStatus',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={progressStatusOptions.find((option) => option.value === (currentValue ?? item.progressStatus)) ?? null}
                            onChange={(event) => setValue(event.detail.selectedOption.value)}
                            options={progressStatusOptions}
                        />
                    );
                },
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
        {
            width: SEVEN_PERCENT_COLUMN_WIDTH,
            id: 'actionOwner',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action_owner,
            cell: (item) => item.actionOwner,
            sortingComparator: getSortingComparatorForTextAndDate('actionOwner'),
            sortingField: 'actionOwner',
            editConfig: {
                editingCell: (item, { currentValue, setValue }) => {
                    return <Input value={currentValue ?? item.actionOwner} onChange={(event) => setValue(event.detail.value)}></Input>;
                },
                validation: (item, value) =>
                    !(value?.length > Constants.RECOMMENDATION_ACTION_OWNER_MAX_LENGTH) ? undefined : actionOwnerValidationText,
                disabledReason: () => (!isReadOnly ? undefined : inlineEditDisabledText),
            },
        },
    ];
};

export const getPrescribedRecommendationTableColumnDefs = (appLabels: AppLabels): TableProps.ColumnDefinition<RecommendationOutput>[] => {
    return [
        {
            id: 'verbiage',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_action,
            cell: (item) => item.verbiage,
            sortingComparator: (a, b) => (a.verbiage > b.verbiage ? -1 : 1),
            sortingField: 'verbiage',
        },
        {
            id: 'benefit',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_benefit,
            cell: (item) => item.benefit,
            sortingComparator: (a, b) => (a.benefit > b.benefit ? -1 : 1),
            sortingField: 'benefit',
        },
        {
            id: 'recommendationOwner',
            header: appLabels.assessment.results.review_all.report.recommended_actions.grid.column_recommendation_owner,
            cell: (item) => item.recommendationOwner,
            sortingComparator: (a, b) => (a.recommendationOwner > b.recommendationOwner ? -1 : 1),
            sortingField: 'recommendationOwner',
        },
    ];
};
