import { CustomerAccountServiceLambdaClient } from '@amzn/customer-account-service-typescript-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

const envConfig = GetEnvironmentConfig();

/**
 * Function to get Apn Authorized Client from the Service TS Client
 * @returns {CustomerAccountServiceLambdaClient} client
 */
export async function getCustomerAccountServiceLambdaClient(): Promise<CustomerAccountServiceLambdaClient> {
    const client = new CustomerAccountServiceLambdaClient({ endpoint: envConfig.casApiEndpoint, region: 'us-west-2' });
    await client.middlewareStack?.add(
        // eslint-disable-next-line
        (next: any, context: any) => async (args: any) => {
            args.request.headers['Authorization'] = await getJwtToken();
            const result = await next(args);
            return result;
        },
        {
            step: 'build',
        }
    );

    return client;
}
