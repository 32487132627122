import { Grid, TextContent } from '@amzn/awsui-components-react';
import { FC } from 'react';

import { editGridDefinition } from './PrescribedActionsList';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

export type PrescribedActionsHeaderProps = {
    visible?: boolean;
} & AppLabelsContextInterface;

const PrescribedActionsHeader: FC<PrescribedActionsHeaderProps> = ({ appLabels, visible }): JSX.Element => {
    if (visible) {
        return (
            <Grid gridDefinition={editGridDefinition}>
                <TextContent data-testid='prescribed-action-header-action'>
                    <strong>{appLabels.user_actions.action}</strong>
                </TextContent>
                <TextContent data-testid='prescribed-action-header-delete'>
                    <strong>{appLabels.user_actions.delete}</strong>
                </TextContent>
                <TextContent data-testid='prescribed-action-header-drag'>
                    <strong>{appLabels.user_actions.drag}</strong>
                </TextContent>
            </Grid>
        );
    }
    return null;
};

export default withAppLabelsContext(PrescribedActionsHeader);
