import './AssessmentStatusBar.scss';

import { AssessmentStatus } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Icon, IconProps, ProgressBarProps, SpaceBetween } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import { AppLabels } from '../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';

interface AssessmentStatusBarProps {
    status: AssessmentStatus | null;
    statusBarPercentComplete?: number | undefined;
}

interface AssessmentStatusBarState {
    statusText: string;
    statusIconName: IconProps.Name;
    statusIconVariant: IconProps.Variant;
    statusBarStatus: ProgressBarProps.Status;
    statusBarResultText?: string;
    statusBarPercentComplete?: number;
}

const AssessmentStatusBar = (props: AssessmentStatusBarProps & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const [statusState, setStatusState] = useState<AssessmentStatusBarState>();

    useEffect(() => {
        switch (props.status) {
            case AssessmentStatus.IN_PROGRESS:
                setStatusState({
                    statusIconName: 'status-pending',
                    statusIconVariant: 'link',
                    statusText: appLabels.assessment.status_bar.in_progress,
                    statusBarStatus: 'in-progress',
                    statusBarPercentComplete: props.statusBarPercentComplete,
                });
                break;
            case AssessmentStatus.COMPLETED:
                setStatusState({
                    statusIconName: 'status-positive',
                    statusIconVariant: 'success',
                    statusText: appLabels.assessment.status_bar.completed,
                    statusBarStatus: 'success',
                    statusBarResultText: appLabels.assessment.status_bar.completed,
                });
                break;
        }
    }, [props.status, props.statusBarPercentComplete, appLabels.assessment.status_bar.completed, appLabels.assessment.status_bar.in_progress]);

    return (
        <SpaceBetween direction='horizontal' size='xs'>
            <Icon name={statusState?.statusIconName} variant={statusState?.statusIconVariant}></Icon>
            <div className='awscat-assessment-status-text'>{statusState?.statusText || ''}</div>
        </SpaceBetween>
    );
};

export default withAppLabelsContext(AssessmentStatusBar);
