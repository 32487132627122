import { Badge, BadgeProps, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { FunctionComponent } from 'react';

interface BadgedTextProps {
    disabled: boolean;
    badgeText?: string;
    badgeColor?: BadgeProps['color'];
}

const BadgedText: FunctionComponent<BadgedTextProps> = ({ disabled, badgeText, badgeColor, children }): JSX.Element => {
    if (!disabled) {
        return (
            <SpaceBetween size={'s'} direction={'horizontal'}>
                {children}
                <Badge color={badgeColor ?? 'blue'}>{badgeText ?? ''}</Badge>
            </SpaceBetween>
        );
    }
    return <div>{children}</div>;
};

export default BadgedText;
