import { AssessmentDescriptor, AssessmentPrompt, UpdatePromptInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import templateManagementClient, { EditableDescriptorKey } from '../../../../../api/templateManagement/TemplateManagementClient';
import { UPDATE_PROMPT } from '../../../../../api/templateManagement/TemplateManagementMutations';
import rumClient from '../../../../../common/monitoring/RumClient';
import {
    addInProgressRequest,
    removeInProgressRequest,
    updateTemplatePrompt,
} from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import TemplateEditableText from '../../../../common/TemplateEditableText';

type AssessmentPromptEditableSelectGroupProps = {
    promptId: string;
    selectionDescriptionDescriptor: AssessmentDescriptor;
    selectionDescriptors: AssessmentDescriptor[];
    selectionIds: string[];
};

/**
 * This component displays all selections for editing
 */
const AssessmentPromptEditableSelectGroup: FunctionComponent<AssessmentPromptEditableSelectGroupProps> = ({
    promptId,
    selectionDescriptionDescriptor,
    selectionDescriptors,
    selectionIds,
}): JSX.Element => {
    const dispatch = useDispatch();

    const [updatePromptMutation, { loading }] = useMutation(UPDATE_PROMPT, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (data, options) => {
            const updatedPrompt = data.updatePrompt as AssessmentPrompt;
            dispatch(updateTemplatePrompt(updatedPrompt));
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    const updatePrompt = useCallback(
        (updatePromptInput: UpdatePromptInput) => {
            // Make the update request to the backend
            const requestId = uuid();
            updatePromptMutation({ variables: { input: updatePromptInput }, context: { requestId } });
            dispatch(addInProgressRequest(requestId));
        },
        [updatePromptMutation, dispatch]
    );

    return (
        <SpaceBetween size='s' direction='vertical'>
            <TemplateEditableText
                key={0}
                descriptor={selectionDescriptionDescriptor}
                keyToEdit={EditableDescriptorKey.Description}
                fieldName={'Selection description'}
                numRows={1}
            />
            {selectionDescriptors.map((descriptor, index) => {
                const selectionId = selectionIds[index];
                // Make a delete icon that, when clicked, opens a confirmation modal
                const onDeleteClick = () => {
                    const updatePromptInput: UpdatePromptInput = {
                        promptId,
                        selectionIdToRemove: selectionId,
                    };
                    updatePrompt(updatePromptInput);
                };
                // Disable button when loading. This is to allow only one deletion at a time, to prevent deleting the same selection
                // twice in a row, which would result in a validation error
                const deleteIcon = (
                    <Button disabled={loading} onClick={onDeleteClick} variant='icon' iconName='remove' data-testid='remove-selection' />
                );

                return (
                    <TemplateEditableText
                        key={index + 1}
                        descriptor={descriptor}
                        keyToEdit={EditableDescriptorKey.Description}
                        numRows={1}
                        fieldName={`Selection ${index + 1}`}
                        adjacentIcon={deleteIcon}
                    />
                );
            })}
            <Button
                iconName='add-plus'
                data-testid='add-selection'
                onClick={() => {
                    // Send add selection request
                    const updatePromptInput: UpdatePromptInput = {
                        promptId,
                        addSelection: true,
                    };
                    updatePrompt(updatePromptInput);
                }}
            />
        </SpaceBetween>
    );
};

export default AssessmentPromptEditableSelectGroup;
