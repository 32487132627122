import { AwsRum } from 'aws-rum-web';

import { filterPageView } from './RumManager';
import { EnvironmentConfig } from '../../Environments';
import { logger } from '../Logger';

const DEFAULT_APPLICATION_VERSION = '1.0.0';
const DEFAULT_COOKIES_ENABLED = true;

export enum RumUserType {
    AWS = 'AWS',
    APN = 'APN',
    Unknown = 'Unknown',
}

class RumClient {
    private rumClient: AwsRum;

    init = (env: EnvironmentConfig, allowCookies?: boolean, appVersion?: string) => {
        // Make sure we only ever have one instance
        if (this.rumClient !== undefined) return;

        // Rum config is optional per environment
        if (!env.rumConfig) {
            // RUM disabled
            return;
        }

        const { appId, identityPoolId, guestRoleArn, region } = env.rumConfig;

        try {
            this.rumClient = new AwsRum(appId, appVersion ?? DEFAULT_APPLICATION_VERSION, region, {
                disableAutoPageView: true,
                allowCookies: allowCookies ?? DEFAULT_COOKIES_ENABLED,
                identityPoolId,
                guestRoleArn,
                endpoint: `https://dataplane.rum.${region}.amazonaws.com`,
                telemetries: ['performance', 'errors', 'http'],
            });
        } catch (error) {
            // Failed to initialize RUM
            logger.error(error as object);
            return;
        }
    };

    /**
     * Adds custom metadata to future CloudWatch RUM events.
     *
     * Note: does not override attributes. Once added, attributes cannot be changed.
     * @param metadata the metadata to add
     * @returns void
     */
    addRumMetadata(metadata: Record<string, string | number>): void {
        this.rumClient?.addSessionAttributes(metadata);
    }

    enableCookies(enabled: boolean) {
        this.rumClient?.allowCookies(enabled);
    }

    recordError(error: any) {
        this.rumClient?.recordError(error);
    }

    recordPageView(pageId: string) {
        this.rumClient?.recordPageView(pageId);
    }

    /**
     * Records a click event to CloudWatch RUM
     * @param elementId - a descriptive ID of the element/component that was clicked
     * @param action - optional parameter providing information about the click. e.g. open/close, show/hide
     * @param context - useful information about the context in which the click happened
     */
    recordClick(elementId: string, action?: string, context?: Record<string, string | number>): void {
        this.rumClient?.recordEvent('click_event', {
            currentUrl: filterPageView(window.location.pathname),
            elementId,
            action,
            context,
        });
    }
}

export default new RumClient();
