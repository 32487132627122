import React from 'react';

import { AppLabels } from './AppLabels';

export interface AppLabelsContextInterface {
    appLabels: AppLabels;
}
export const AppLabelsContext = React.createContext<AppLabelsContextInterface>({
    appLabels: {} as AppLabels,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withAppLabelsContext = (Component: any) => {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (props: any): JSX.Element => {
        return <AppLabelsContext.Consumer>{(ctx) => <Component {...props} appLabels={ctx.appLabels} />}</AppLabelsContext.Consumer>;
    };
};
