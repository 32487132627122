import { Alert, Box, Tabs, TabsProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AssessmentHeatMapResults from './heatmap/AssessmentHeatMapResults';
import AssessmentHeatMapResultsV2 from './heatmap/AssessmentHeatMapResultsV2';
import HeatMapHelpPanelBody from './heatmap/HeatMapHelpPanelBody';
import AssessmentRadarResults from './radar/AssessmentRadarResults';
import AssessmentReportResults from './report/AssessmentReportResults';
import ResponseDistributionResults from './response-distribution/ResponseDistribution';
import AssessmentScoresResults from './scores/AssessmentScoresResults';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { ScoreScale } from '../../../../common/score/ScoreScale';
import { updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { requestReloadAssessmentScores } from '../../facilitate/CurrentAssessmentSlice';

const ASSESSMENT_SCORE_DEPENDENT_TAB_IDS = ['radar', 'scores', 'report'];
const DEFAULT_REVIEW_ALL_TAB_ID = 'report';

const ResultsReviewAllBody: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const errorMessage = useAppSelector((state) => state.currentAssessmentState.errorMessage);
    const reportConfig = useAppSelector((state) => state.currentAssessmentState?.currentAssessment?.template?.defaults?.report);
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentScoresRequireReload = useAppSelector((state) => state.currentAssessmentState.assessmentScoresRequireReload);
    const assessmentScoresReloadRequested = useAppSelector((state) => state.currentAssessmentState.assessmentScoresReloadRequested);
    const currentAssessmentOrSelectedSnapshotId = currentAssessmentOrSelectedSnapshot?.id;
    const currentAssessmentOrSelectedSnapshotType = currentAssessmentOrSelectedSnapshot?.type;
    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessmentOrSelectedSnapshot?.template?.defaults?.questionnaireAnswers, appLabels, true);
    }, [appLabels, currentAssessmentOrSelectedSnapshot?.template?.defaults?.questionnaireAnswers]);

    const reviewAllRootPathname = pathname.substring(0, pathname.lastIndexOf('reviewall'));
    const reviewallUrlParts = pathname.split('reviewall/');
    const targetTabId = reviewallUrlParts.length > 1 ? reviewallUrlParts[1] : null;
    const [activeTabId, setActiveTabId] = useState<string>(targetTabId || DEFAULT_REVIEW_ALL_TAB_ID);

    const buildReviewAllTabs = useCallback(() => {
        const reviewAllTabs: TabsProps.Tab[] = [];

        // by default, all tabs are enabled unless specifically disabled
        if (reportConfig?.heatmap?.disabled !== true) {
            if (['CSM', 'CMA'].includes(currentAssessmentOrSelectedSnapshotType)) {
                reviewAllTabs.push({
                    label: appLabels.assessment.results.review_all.heatmap.heatmap,
                    id: 'heatmap',
                    content: <AssessmentHeatMapResultsV2 />,
                });
            } else {
                reviewAllTabs.push({
                    label: appLabels.assessment.results.review_all.heatmap.heatmap,
                    id: 'heatmap',
                    content: <AssessmentHeatMapResults />,
                });
            }
        }

        if (reportConfig?.radar?.disabled !== true) {
            reviewAllTabs.push({
                label: appLabels.assessment.results.review_all.radar.radar,
                id: 'radar',
                content: <AssessmentRadarResults assessmentOrSnapshotIds={[currentAssessmentOrSelectedSnapshotId]} />,
            });
        }

        if (reportConfig?.scoreBarChart?.disabled !== true) {
            reviewAllTabs.push({
                label: appLabels.assessment.results.review_all.scores.scores,
                id: 'scores',
                content: <AssessmentScoresResults />,
            });
        }

        if (reportConfig?.responseDistribution) {
            reviewAllTabs.push({
                label: appLabels.assessment.results.review_all.responseDistribution.responseDistribution,
                id: 'responseDistribution',
                content: <ResponseDistributionResults />,
            });
        }

        if (reportConfig?.report?.disabled !== true) {
            reviewAllTabs.push({
                label: appLabels.assessment.results.review_all.report.report,
                id: 'report',
                content: <AssessmentReportResults />,
            });
        }
        return reviewAllTabs;
    }, [
        appLabels.assessment.results.review_all.heatmap.heatmap,
        appLabels.assessment.results.review_all.radar.radar,
        appLabels.assessment.results.review_all.report.report,
        appLabels.assessment.results.review_all.responseDistribution.responseDistribution,
        appLabels.assessment.results.review_all.scores.scores,
        currentAssessmentOrSelectedSnapshotId,
        currentAssessmentOrSelectedSnapshotType,
        reportConfig?.heatmap?.disabled,
        reportConfig?.radar?.disabled,
        reportConfig?.report?.disabled,
        reportConfig?.responseDistribution,
        reportConfig?.scoreBarChart?.disabled,
    ]);

    const onTabChange = useCallback(
        ({ detail }) => {
            setActiveTabId(detail.activeTabId);
            history.push(`${reviewAllRootPathname}reviewall/${detail.activeTabId}`);
        },
        [history, reviewAllRootPathname]
    );

    useEffect(() => {
        dispatch(
            updateAppHelpPanel({
                // ToDo: need to update help panel based on tab selected: heatmap, radar, scores, report
                header: <h2>{appLabels.assessment.results.review_all.heatmap.heatmap}</h2>,
                content: <HeatMapHelpPanelBody scoreScale={scoreScale} />,
            })
        );
        if (assessmentScoresRequireReload && !assessmentScoresReloadRequested && ASSESSMENT_SCORE_DEPENDENT_TAB_IDS.includes(activeTabId)) {
            // scores may have been invalidated by ratings update, request reload
            dispatch(requestReloadAssessmentScores());
        }
        if (targetTabId && targetTabId !== activeTabId) {
            setActiveTabId(targetTabId);
        }
    }, [activeTabId, appLabels, assessmentScoresReloadRequested, assessmentScoresRequireReload, dispatch, scoreScale, targetTabId]);

    if (errorMessage) {
        return (
            <Alert
                visible={true}
                dismissAriaLabel={appLabels.user_actions.close_alert}
                type='error'
                header={appLabels.assessment.facilitate.error_loading_question}
            >
                {errorMessage}
            </Alert>
        );
    } else {
        return (
            <div>
                <Box variant='h2' margin={{ top: 'm' }}>
                    Review all results
                </Box>
                <Tabs onChange={onTabChange} activeTabId={activeTabId} tabs={buildReviewAllTabs()} />
            </div>
        );
    }
};

export default withAppLabelsContext(ResultsReviewAllBody);
