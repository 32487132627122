import { DataCollectionClient, QuestionnaireAndAnswers } from '@amzn/awscat-data-collection-service-typescript-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken, getProvider } from '../../common/auth/Authentication';

const envConfig = GetEnvironmentConfig();

const dataCollectionClient = new DataCollectionClient(
    'us-west-2', // TODO: DataCollectionClient only support us-west-2 right now, use envConfig.region in the future
    envConfig.dataCollectionEnvironment,
    getJwtToken,
    getProvider,
    undefined
);

export default dataCollectionClient;

export type {
    AssigneeAnswer,
    EmailTemplate,
    ExistingQuestionnaire,
    NewQuestionnaire,
    PossibleAnswer,
    Question,
    QuestionAnswer,
    QuestionnaireAndAnswers,
} from '@amzn/awscat-data-collection-service-typescript-client';

export const getPreEventAnswers = async (assessmentId: string): Promise<QuestionnaireAndAnswers> => {
    const sourceResourceId = `awscat:a2s:${assessmentId}`;
    const questionnaires = await dataCollectionClient.getQuestionnaires(sourceResourceId);
    const existingQuestionnaire = questionnaires.length ? questionnaires[0] : undefined;
    if (existingQuestionnaire) {
        const questionnaireWithAnswers: QuestionnaireAndAnswers = await dataCollectionClient.getAnswers(existingQuestionnaire.id, true);
        return questionnaireWithAnswers;
    }
    return null;
};
