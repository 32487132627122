import {
    AssessmentTemplateMetadata,
    PrincipalIdToTemplateActorMapping,
    ShareTemplateInput,
    ShareableTemplateActor,
    UnshareTemplateInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Button, FormField, Grid, Input, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useState } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { addProviderIfAmazonOrPartner, getLanguageName, removeProviderFromPrincipalId } from '../../../../../common/Utils';
import { LocalizationContextInterface, withLocalizationContext } from '../../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateTemplatePermissions } from '../CurrentTemplateSlice';
import TemplateConstants from '../TemplateConstants';

type ShareWithLocaleManagersProps = AppLabelsContextInterface &
    LocalizationContextInterface & {
        canShare: boolean;
        shareTemplate: (options: { variables: { input: ShareTemplateInput } }) => Promise<void>;
        unshareTemplate: (options: { variables: { input: UnshareTemplateInput } }) => Promise<void>;
        saveClickCount: number;
        resetClickCount: number;
        setArePermissionsChanged: (val: boolean) => void;
    };
const ShareWithLocaleManagers: FunctionComponent<ShareWithLocaleManagersProps> = ({
    appLabels,
    canShare,
    locale: uiLocale,
    shareTemplate,
    unshareTemplate,
    saveClickCount,
    resetClickCount,
    setArePermissionsChanged,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);
    // Get the locales that the template is displayed in. Allow editing the permissions for each locale
    const localesToDisplay: string[] = Array.from(new Set(currentTemplateMetadata.descriptors.map(({ locale }) => locale)));

    const [localeToLocaleManagerToAddMap, setLocaleToLocaleManagerToAddMap] = useState<Map<string, string>>(new Map());
    const [localeManagersToRemove, setLocaleManagersToRemove] = useState<{ locale: string; principalId: string }[]>([]);
    const [localeManagersToAdd, setLocaleManagersToAdd] = useState<{ locale: string; principalId: string }[]>([]);

    const [localeToLocaleManagerItems, setLocaleToLocaleManagerItems] = useState<Map<string, TokenGroupProps.Item[]>>(
        // Convert the array of mappings to a plain map
        localesToDisplay.reduce((map, locale) => {
            const localeManagersForLocale: string[] =
                currentTemplateMetadata.localeManagers.find(({ locale: localeManagerLocale }) => localeManagerLocale === locale)?.localeManagers ||
                [];

            map.set(
                locale,
                localeManagersForLocale.map((localeManager) => ({
                    // Don't show `catportal-`
                    label: removeProviderFromPrincipalId(localeManager),
                }))
            );

            return map;
        }, new Map<string, TokenGroupProps.Item[]>())
    );

    const [lastResetClickCount, setLastResetClickCount] = useState<number>(0);
    const [lastSavedClickCount, setLastSavedClickCount] = useState<number>(0);

    // If there are any added/removed items, indicate to the parent component that the permissions have changed
    useEffect(() => {
        if (localeManagersToAdd.length || localeManagersToRemove.length) {
            setArePermissionsChanged(true);
        } else {
            setArePermissionsChanged(false);
        }
    }, [localeManagersToAdd, localeManagersToRemove, setArePermissionsChanged]);

    // This is an "on reset" useEffect
    useEffect(() => {
        if (lastResetClickCount === resetClickCount) {
            // Reset wasn't clicked
            return;
        }
        setLocaleManagersToAdd([]);
        setLocaleManagersToRemove([]);
        setLocaleToLocaleManagerToAddMap(new Map());

        // Un-delete any items and remove any items "pending save"
        setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
            localesToDisplay.forEach((locale) => {
                const currentItems = localeToLocaleManagerItems.get(locale);
                localeToLocaleManagerItems.set(
                    locale,
                    currentItems
                        .map((item) => ({ ...item, disabled: undefined }))
                        .filter((item) => item.description !== appLabels.manage_templates.share.pending_save)
                );
            });
            return new Map(localeToLocaleManagerItems);
        });

        setLastResetClickCount(resetClickCount);
    }, [localesToDisplay, appLabels.manage_templates.share.pending_save, lastResetClickCount, resetClickCount]);

    // This is the "on save" useEffect
    useEffect(() => {
        if (lastSavedClickCount === saveClickCount) {
            // Save wasn't clicked
            return;
        }

        const templatePermissionsToRemove: PrincipalIdToTemplateActorMapping[] = localeManagersToRemove.map(
            ({ principalId, locale }): PrincipalIdToTemplateActorMapping => ({
                // Backend expects that users have `catportal-` as a prefix. Add if this is a user ID
                principalId: addProviderIfAmazonOrPartner(principalId),
                templateActor: ShareableTemplateActor.LocaleManager,
                locale,
            })
        );

        if (templatePermissionsToRemove.length) {
            const unshareTemplateInput: UnshareTemplateInput = {
                templateId: currentTemplateMetadata.templateId,
                permissionsToRemove: templatePermissionsToRemove,
            };

            unshareTemplate({
                variables: {
                    input: unshareTemplateInput,
                },
            });

            setLocaleManagersToRemove([]);

            // Also remove from the current items
            setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
                localesToDisplay.forEach((locale) => {
                    const currentItems = localeToLocaleManagerItems.get(locale);
                    localeToLocaleManagerItems.set(
                        locale,
                        currentItems.filter(({ disabled }) => !disabled)
                    );
                });
                return new Map(localeToLocaleManagerItems);
            });
        }

        // Now handle added permissions
        const templatePermissionsToAdd: PrincipalIdToTemplateActorMapping[] = localeManagersToAdd.map(
            ({ principalId, locale }): PrincipalIdToTemplateActorMapping => ({
                principalId: addProviderIfAmazonOrPartner(principalId),
                templateActor: ShareableTemplateActor.LocaleManager,
                locale,
            })
        );

        if (templatePermissionsToAdd.length) {
            const shareTemplateInput: ShareTemplateInput = {
                templateId: currentTemplateMetadata.templateId,
                newPermissions: templatePermissionsToAdd,
            };

            shareTemplate({
                variables: {
                    input: shareTemplateInput,
                },
            });

            setLocaleManagersToAdd([]);

            // Remove the "pending save" text
            setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
                localesToDisplay.forEach((locale) => {
                    const currentItems = localeToLocaleManagerItems.get(locale);
                    localeToLocaleManagerItems.set(
                        locale,
                        currentItems.map(({ label }) => ({ label }))
                    );
                });
                return new Map(localeToLocaleManagerItems);
            });
        }

        // Update the Redux state
        dispatch(updateTemplatePermissions({ addedPermissions: templatePermissionsToAdd, removedPermissions: templatePermissionsToRemove }));

        setLastSavedClickCount(saveClickCount);
    }, [
        currentTemplateMetadata.templateId,
        localeManagersToAdd,
        localeManagersToRemove,
        localesToDisplay,
        shareTemplate,
        unshareTemplate,
        dispatch,
        saveClickCount,
        lastSavedClickCount,
    ]);

    return (
        <>
            {localesToDisplay.map((permissionLocale): JSX.Element => {
                const localeManagerToAdd: string = localeToLocaleManagerToAddMap.get(permissionLocale) || '';
                const localeManagerItems: TokenGroupProps.Item[] = localeToLocaleManagerItems.get(permissionLocale);

                return (
                    <FormField label={getLanguageName(permissionLocale, uiLocale)}>
                        <Grid gridDefinition={[{ colspan: 7 }, { colspan: 3 }]}>
                            <Input
                                data-testid={`${permissionLocale}-textbox`}
                                value={localeManagerToAdd}
                                onChange={({ detail: { value } }) => {
                                    // When setting React state, a completely new object is needed every time
                                    setLocaleToLocaleManagerToAddMap(
                                        (localeToLocaleManagerToAddMap) => new Map(localeToLocaleManagerToAddMap.set(permissionLocale, value))
                                    );
                                }}
                                placeholder={appLabels.manage_templates.share.add_locale_manager}
                                readOnly={!canShare}
                            />
                            <Button
                                data-testid={`${permissionLocale}-add-button`}
                                disabled={!canShare || !localeToLocaleManagerToAddMap.get(permissionLocale)}
                                onClick={() => {
                                    setLocaleToLocaleManagerToAddMap(
                                        (localeToLocaleManagerToAdd) => new Map(localeToLocaleManagerToAdd.set(permissionLocale, ''))
                                    );

                                    // If this item is already present, ignore
                                    if (localeManagerItems.some(({ label }) => label === localeManagerToAdd)) {
                                        return;
                                    }

                                    setLocaleManagersToAdd((localeManagersToAdd) =>
                                        localeManagersToAdd.concat({ locale: permissionLocale, principalId: localeManagerToAdd })
                                    );
                                    // Add the new locale manager to the list. Indicate that it's pending save
                                    setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
                                        const updatedItemsForLocale = localeManagerItems.concat({
                                            label: localeManagerToAdd,
                                            description: appLabels.manage_templates.share.pending_save,
                                        });
                                        return new Map(localeToLocaleManagerItems.set(permissionLocale, updatedItemsForLocale));
                                    });
                                }}
                            >
                                {appLabels.user_actions.add}
                            </Button>
                        </Grid>
                        <TokenGroup
                            data-testid={`${permissionLocale}-token-group`}
                            items={localeManagerItems}
                            limit={TemplateConstants.SHARE_TEMPLATE_TOKEN_GROUP_LIMIT}
                            onDismiss={({ detail: { itemIndex } }) => {
                                if (!canShare) {
                                    return;
                                }

                                if (localeManagerItems[itemIndex].description === appLabels.manage_templates.share.pending_save) {
                                    // If this item is pending save, remove it from the list
                                    setLocaleManagersToAdd((localeManagersToAdd) =>
                                        localeManagersToAdd.filter(
                                            (localeManagerToAdd) => localeManagerToAdd.principalId !== localeManagerItems[itemIndex].label
                                        )
                                    );
                                    setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
                                        const updatedItemsForLocale = localeManagerItems.filter((_, index) => index !== itemIndex);
                                        return new Map(localeToLocaleManagerItems.set(permissionLocale, updatedItemsForLocale));
                                    });
                                } else {
                                    // Otherwise, add it to the list of items to remove
                                    setLocaleManagersToRemove(
                                        localeManagersToRemove.concat({ locale: permissionLocale, principalId: localeManagerItems[itemIndex].label })
                                    );
                                    setLocaleToLocaleManagerItems((localeToLocaleManagerItems) => {
                                        localeManagerItems[itemIndex].disabled = true;
                                        return new Map(localeToLocaleManagerItems.set(permissionLocale, localeManagerItems));
                                    });
                                }
                            }}
                        />
                    </FormField>
                );
            })}
        </>
    );
};

export default withLocalizationContext(withAppLabelsContext(ShareWithLocaleManagers));
