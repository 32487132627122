import {
    AssessmentDescriptor,
    AssessmentModule,
    AssessmentPrompt,
    AssessmentTemplate,
    AssessmentTemplateDefault,
    AssessmentTemplateDefaultNamespace,
    AssessmentTemplateMetadata,
    AssessmentView,
    PrincipalIdToAssessmentTypeActionMapping,
    PrincipalIdToTemplateActorMapping,
    ShareableTemplateActor,
    UpdateDescriptorInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TemplateDefaultWithNamespace } from './TemplateDefaults';
import { TemplatePromptViewModel } from './TemplateModels';
import { validateTemplate } from './TemplateUtils';
import { EditableDescriptorKey } from '../../../../api/templateManagement/TemplateManagementClient';
import Constants from '../../../../common/Constants';
import { LoadingStatus } from '../../../../common/RequestUtils';

/* These maps are outside the Redux state, so helper functions can access them */
const moduleIdToFirstLeafModuleId: Map<string, string> = new Map();
const moduleIdToModuleMap: Map<string, AssessmentModule> = new Map();
/** Key is: `${viewId}${promptId}` */
const viewIdPromptIdToPromptMap: Map<string, TemplatePromptViewModel> = new Map();

export interface CurrentPromptState {
    /** Each view presents the prompts in a different order */
    viewIdToPromptsMap: Map<string, TemplatePromptViewModel[]>;
    /** View id --> the IDs of the sequence of modules that should be displayed to the user (the leaf modules of the template)
     * The edit template flow needs to allow users to navigate through modules as well as prompts
     */
    viewIdToLeafModuleIdsMap: Map<string, string[]>;
    viewIdToRootModuleIdsMap: Map<string, string[]>;
    /** Number of prompts is used in the status bar */
    viewIdToNumberOfPrompts: Map<string, number>;
    currentViewId: string;
    currentModuleId: string;
    // TODO: change navigation to navigate between modules rather than between prompts - https://i.amazon.com/issues/A2T-1964
    // Will turn this into `currentPromptIndexWithinModule`. If undefined, means there's no prompt in the module
    currentPromptIndex: number;
    currentLeafModuleIndex: number;
    currentPrompt: TemplatePromptViewModel | undefined;
}

export const initialCurrentPromptState: CurrentPromptState = {
    viewIdToPromptsMap: null,
    viewIdToLeafModuleIdsMap: null,
    viewIdToRootModuleIdsMap: null,
    viewIdToNumberOfPrompts: null,
    currentViewId: null,
    currentModuleId: null,
    currentLeafModuleIndex: null,
    currentPromptIndex: null,
    currentPrompt: null,
};

export const initialCurrentTemplateMetadataState: AssessmentTemplateMetadata = {
    templateId: null,
    authorizedAssessmentCreators: null,
    coowners: null,
    createdAt: null,
    descriptors: [],
    isPublishedForType: null,
    isPublishedForVersion: null,
    localeManagers: null,
    owner: null,
    type: null,
    version: null,
};

export interface CurrentTemplateState {
    currentTemplateId: string;
    /** The template that is currently being edited. Note: it is not updated with any edits. Use other variables to get updated data */
    currentTemplate: AssessmentTemplate;
    currentTemplateMetadata: AssessmentTemplateMetadata;
    templateLocale: string;
    supportedLocales: string[]; // use array instead of set, to preserve locale creation order
    currentlyEditing: boolean;
    currentPromptState: CurrentPromptState;
    /** Key is: `${descriptorId}${locale}`. The values of all editable descriptors should be retrieved from this map, as
     * this is the object that is kept up-to-date with any descriptor edits
     * Although more complex, this indexing is recommended by Redux for complex objects
     * https://redux.js.org/tutorials/essentials/part-6-performance-normalization#normalized-state-structure
     */
    descriptorIdLocaleToEditableDescriptorObject: { [key: string]: AssessmentDescriptor };
    templateLoadingStatus: LoadingStatus;
    templateLoadErrorMessage: string;
    /* To show the status of the requests, maintain a set of in-progress requests */
    editTemplateInProgressRequests: Set<string>;
    /* If any requests fail, this boolean will indicate that a failed autosave status should be shown to the user */
    editTemplateShowError: boolean;
    /** If true, template should be retrieved again */
    shouldRefreshTemplate: boolean;
}
export const initialCurrentTemplateState: CurrentTemplateState = {
    currentTemplateId: null,
    currentTemplate: null,
    currentTemplateMetadata: initialCurrentTemplateMetadataState,
    templateLocale: Constants.DEFAULT_LOCALE,
    supportedLocales: [Constants.DEFAULT_LOCALE],
    currentlyEditing: false,
    currentPromptState: initialCurrentPromptState,
    descriptorIdLocaleToEditableDescriptorObject: null,
    templateLoadingStatus: LoadingStatus.NotLoaded,
    templateLoadErrorMessage: '',
    editTemplateInProgressRequests: null,
    editTemplateShowError: false,
    shouldRefreshTemplate: false,
};

export const currentTemplateSlice = createSlice({
    name: 'currentTemplateSlice',
    initialState: initialCurrentTemplateState,
    reducers: {
        /**
         * Call once the new template is received
         * @param state current Redux state
         * @param action the Redux payload, with the template to load
         */
        loadTemplateSuccess: (state, action: PayloadAction<AssessmentTemplate>) => {
            state.currentTemplateId = action.payload.templateId;
            state.currentTemplate = action.payload;

            // Validate the template. Allow the dispatcher to handle any errors
            validateTemplate(state.currentTemplate);

            const viewIdToPromptsMap: Map<string, TemplatePromptViewModel[]> = new Map();
            const viewIdToLeafModuleIdsMap: Map<string, string[]> = new Map();
            const viewIdToRootModuleIdsMap: Map<string, string[]> = new Map();
            const viewIdToNumberOfPrompts: Map<string, number> = new Map();
            const descriptorIdLocaleToEditableDescriptorObject: { [key: string]: AssessmentDescriptor } = {};
            /**
             * Pre-order traversal of the view. E.g. view -> module -> prompts in module -> childModule -> childModule -> prompt. Once a prompt is found:
             * 1. Add it to the `prompts` array (specific to this view)
             * 2. Add the all parent module ids to the `moduleIdToFirstPromptIndexMap` map
             * @param view the view to process
             */
            function addPromptsInView(view: AssessmentView): void {
                const prompts: TemplatePromptViewModel[] = [];
                const idsOfLeafModules: string[] = [];
                let numberOfPromptsInView = 0;

                // This stack contains all parent modules of the prompt
                const moduleIdsStack: string[] = [];

                /**
                 * Given a module, add all its prompts to the `prompts` array
                 * Additionally checks each child module
                 * @param module the current module
                 */
                function addPromptsInModule(module: AssessmentModule): void {
                    moduleIdsStack.push(module.moduleId);

                    // No parent module --> it's a root module. Add this module
                    if (!module.parentModule) {
                        const rootModuleIds = viewIdToRootModuleIdsMap.get(view.viewId);
                        if (rootModuleIds) {
                            rootModuleIds.push(module.moduleId);
                        } else {
                            viewIdToRootModuleIdsMap.set(view.viewId, [module.moduleId]);
                        }
                    }

                    // Add each of the prompts to the prompts array. If we're dealing with the first prompt,
                    // add it to the `moduleIdToFirstPromptIndexMap`
                    module.prompts.forEach((prompt) => {
                        const promptViewModel: TemplatePromptViewModel = {
                            ...prompt,
                            containingModuleId: module.moduleId,
                        };
                        prompts.push(promptViewModel);

                        // Increment `numberOfPromptsInView`
                        numberOfPromptsInView++;

                        // Since prompt descriptors are editable, add them here
                        prompt.descriptors.forEach((descriptor) => {
                            descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
                        });

                        // Add any rating guide descriptors too
                        if (prompt.ratingGuide) {
                            Object.values(prompt.ratingGuide).forEach((value) => {
                                // Ignore __typename
                                if (value === 'RatingGuide') {
                                    return;
                                }

                                value?.forEach((ratingGuideDescriptor) => {
                                    descriptorIdLocaleToEditableDescriptorObject[
                                        `${ratingGuideDescriptor.descriptorId}${ratingGuideDescriptor.locale}`
                                    ] = ratingGuideDescriptor;
                                });
                            });
                        }

                        // Add selections to the editable descriptor object
                        prompt.selectionDescription?.forEach((descriptor) => {
                            descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
                        });
                        prompt.selections?.forEach((selection) => {
                            selection.selectionLabel.forEach((selectionDescriptor) => {
                                descriptorIdLocaleToEditableDescriptorObject[`${selectionDescriptor.descriptorId}${selectionDescriptor.locale}`] =
                                    selectionDescriptor;
                            });
                        });

                        viewIdPromptIdToPromptMap.set(`${view.viewId}${prompt.promptId}`, promptViewModel);
                    });

                    module.childModules.forEach(addPromptsInModule);

                    // Handle leaf module case
                    if (module.childModules.length === 0) {
                        idsOfLeafModules.push(module.moduleId);

                        // For each module among the modules's ancestors, set the corresponding leaf module ID
                        // But if a prompt has already been set, skip it (so we only have first leaf modules set)
                        moduleIdsStack.forEach((ancestorModuleId) => {
                            if (!moduleIdToFirstLeafModuleId.has(ancestorModuleId)) {
                                moduleIdToFirstLeafModuleId.set(ancestorModuleId, module.moduleId);
                            }
                        });
                    }

                    // So we don't have to iterate through the template tree, allow easy retrieval of the module
                    // given the module ID
                    moduleIdToModuleMap.set(module.moduleId, module);

                    // Module names and help context are editable
                    module.descriptors.forEach((descriptor) => {
                        descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
                    });

                    moduleIdsStack.pop();
                }

                view.modules.forEach(addPromptsInModule);

                viewIdToPromptsMap.set(view.viewId, prompts);
                viewIdToLeafModuleIdsMap.set(view.viewId, idsOfLeafModules);
                viewIdToNumberOfPrompts.set(view.viewId, numberOfPromptsInView);
            }

            state.currentTemplate.views.forEach(addPromptsInView);

            // Template descriptor/delivery guidance is editable
            state.currentTemplate.descriptors.forEach((descriptor) => {
                descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
            });
            state.currentTemplate.deliveryGuidance.forEach((descriptor) => {
                descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
            });

            const firstViewId = state.currentTemplate.views[0].viewId;
            const initialLeafModuleIndex = 0;
            const initialModuleId = viewIdToLeafModuleIdsMap.get(firstViewId)[initialLeafModuleIndex];
            const firstPrompt = viewIdToPromptsMap.get(firstViewId).at(0);

            state.currentPromptState = {
                currentPromptIndex: firstPrompt ? 0 : -1,
                currentViewId: firstViewId,
                currentModuleId: initialModuleId,
                currentLeafModuleIndex: initialLeafModuleIndex,
                currentPrompt: firstPrompt,
                viewIdToPromptsMap,
                viewIdToNumberOfPrompts,
                viewIdToLeafModuleIdsMap,
                viewIdToRootModuleIdsMap,
            };

            state.descriptorIdLocaleToEditableDescriptorObject = descriptorIdLocaleToEditableDescriptorObject;

            state.editTemplateInProgressRequests = new Set();
            state.shouldRefreshTemplate = false;
            state.supportedLocales = action.payload.descriptors.map((descriptor) => descriptor.locale);
        },
        /**
         * Loads the template metadata, retrieved by calling the listEditableTemplates API
         * @param state current Redux state
         * @param action the template metadata
         */
        loadTemplateMetadata: (state, action: PayloadAction<AssessmentTemplateMetadata>) => {
            state.currentTemplateMetadata = action.payload;
        },
        /**
         * Indicate that the template retrieval request has been made. Also resets any error message
         * @param state current Redux state
         */
        beginLoadingTemplate: (state) => {
            state.templateLoadingStatus = LoadingStatus.Loading;
            state.templateLoadErrorMessage = initialCurrentTemplateState.templateLoadErrorMessage;
        },
        /**
         * Indicate that the template and its metadata have finished loading
         * @param state current Redux state
         */
        completeLoadingTemplate: (state) => {
            state.templateLoadingStatus = LoadingStatus.Loaded;
        },
        /**
         * Unloads the current template. Used when loading a new template
         * @param state current Redux state
         */
        unloadCurrentTemplate: (state) => {
            moduleIdToFirstLeafModuleId.clear();
            moduleIdToModuleMap.clear();
            viewIdPromptIdToPromptMap.clear();
            Object.assign(state, initialCurrentTemplateState);
        },
        /**
         * Requests that the template be retrieved again. Logic for handling this is in TemplateRoutes.tsx
         * @param state current Redux state
         */
        requestRefreshTemplate: (state) => {
            currentTemplateSlice.caseReducers.unloadCurrentTemplate(state);

            state.shouldRefreshTemplate = true;
        },
        /**
         * Called when a template fails to load
         * @param state current Redux state
         * @param action parameter with error message
         */
        loadTemplateFailure: (state, { payload: errorMessage }: PayloadAction<string>) => {
            currentTemplateSlice.caseReducers.unloadCurrentTemplate(state);

            state.templateLoadErrorMessage = errorMessage;
            state.templateLoadingStatus = LoadingStatus.FailedToLoad;
        },
        /**
         * Jump to the first prompt in the specified module
         * @param state current Redux state
         * @param action  the view/module to jump to
         */
        jumpToModule: (state, action: PayloadAction<{ viewId: string; moduleId: string }>) => {
            state.currentPromptState.currentViewId = action.payload.viewId;
            const firstLeafModuleId: string = moduleIdToFirstLeafModuleId.get(action.payload.moduleId);
            state.currentPromptState.currentModuleId = firstLeafModuleId;

            const currentLeafModules = state.currentPromptState.viewIdToLeafModuleIdsMap.get(action.payload.viewId);
            state.currentPromptState.currentLeafModuleIndex = currentLeafModules.findIndex(
                (moduleId) => moduleId === state.currentPromptState.currentModuleId
            );

            // Leaf module may have no prompts
            const firstPromptIdInModule: string = moduleIdToModuleMap.get(firstLeafModuleId).prompts.at(0)?.promptId;
            state.currentPromptState.currentPrompt = viewIdPromptIdToPromptMap.get(`${action.payload.viewId}${firstPromptIdInModule}`);

            const currentPrompts = state.currentPromptState.viewIdToPromptsMap.get(state.currentPromptState.currentViewId);

            state.currentPromptState.currentPromptIndex =
                currentPrompts.findIndex((prompt) => prompt.promptId === state.currentPromptState.currentPrompt?.promptId) || 0;
        },
        /**
         * Jump to the first prompt in the specified module
         * @param state current Redux state
         * @param action  the view/promptId to jump to
         */
        jumpToPrompt: (state, action: PayloadAction<{ viewId: string; promptId: string }>) => {
            state.currentPromptState.currentViewId = action.payload.viewId;
            const currentPrompts = state.currentPromptState.viewIdToPromptsMap.get(state.currentPromptState.currentViewId);
            const currentLeafModules = state.currentPromptState.viewIdToLeafModuleIdsMap.get(action.payload.viewId);

            // If the prompt can't be found, go to the first prompt
            state.currentPromptState.currentPromptIndex = currentPrompts.findIndex((prompt) => prompt.promptId === action.payload.promptId) || 0;
            state.currentPromptState.currentPrompt = currentPrompts[state.currentPromptState.currentPromptIndex];

            // Update module indexing
            state.currentPromptState.currentModuleId = state.currentPromptState.currentPrompt.containingModuleId;
            state.currentPromptState.currentLeafModuleIndex = currentLeafModules.findIndex(
                (moduleId) => moduleId === state.currentPromptState.currentModuleId
            );
        },
        /**
         * Go to the next prompt/module within the same view
         * @param state current Redux state
         */
        goToNextPromptOrModule: (state) => {
            const currentViewId = state.currentPromptState.currentViewId;
            const currentModuleId = state.currentPromptState.currentModuleId;
            const currentPrompt = state.currentPromptState.currentPrompt;
            const currentPrompts = state.currentPromptState.viewIdToPromptsMap.get(currentViewId);
            const currentLeafModules = state.currentPromptState.viewIdToLeafModuleIdsMap.get(currentViewId);
            const currentLeafModuleId = currentLeafModules.find((moduleId) => moduleId === currentModuleId);
            const currentLeafModule = moduleIdToModuleMap.get(currentLeafModuleId);

            // If there are other prompts left in the current leaf module, just go to the next prompt
            const promptIndexWithinLeafModule = currentLeafModule.prompts.findIndex((prompt) => prompt.promptId === currentPrompt?.promptId);
            if (promptIndexWithinLeafModule < currentLeafModule.prompts.length - 1) {
                state.currentPromptState.currentPromptIndex++;
                state.currentPromptState.currentPrompt = currentPrompts[state.currentPromptState.currentPromptIndex];

                // Update module indexing
                state.currentPromptState.currentModuleId = state.currentPromptState.currentPrompt.containingModuleId;
                state.currentPromptState.currentLeafModuleIndex = currentLeafModules.findIndex(
                    (moduleId) => moduleId === state.currentPromptState.currentModuleId
                );
            } else {
                // Otherwise, go to the next leaf module
                const nextLeafModuleIndex = state.currentPromptState.currentLeafModuleIndex + 1;

                if (nextLeafModuleIndex === currentLeafModules.length) {
                    // At end of template
                    return;
                }

                const nextModuleId = currentLeafModules[nextLeafModuleIndex];
                currentTemplateSlice.caseReducers.jumpToModule(state, { payload: { moduleId: nextModuleId, viewId: currentViewId }, type: '' });
            }
        },
        /**
         * Go to the previous prompt/module within the same view
         * @param state
         */
        goToPreviousPromptOrModule: (state) => {
            const currentViewId = state.currentPromptState.currentViewId;
            const currentModuleId = state.currentPromptState.currentModuleId;
            const currentPrompts = state.currentPromptState.viewIdToPromptsMap.get(currentViewId);
            const currentLeafModules = state.currentPromptState.viewIdToLeafModuleIdsMap.get(currentViewId);
            const currentLeafModule = moduleIdToModuleMap.get(currentModuleId);

            // If there are other prompts left in the current leaf module, just go to the previous prompt
            const previousPromptIndex = state.currentPromptState.currentPromptIndex - 1;
            const previousPrompt = currentPrompts.at(previousPromptIndex);
            const previousPromptIndexWithinLeafModule = currentLeafModule.prompts.findIndex((prompt) => prompt.promptId === previousPrompt?.promptId);
            if (previousPromptIndexWithinLeafModule >= 0) {
                state.currentPromptState.currentPromptIndex--;
                state.currentPromptState.currentPrompt = currentPrompts[state.currentPromptState.currentPromptIndex];

                // Update module indexing
                state.currentPromptState.currentModuleId = state.currentPromptState.currentPrompt.containingModuleId;
                state.currentPromptState.currentLeafModuleIndex = currentLeafModules.findIndex(
                    (moduleId) => moduleId === state.currentPromptState.currentModuleId
                );
            } else {
                // Otherwise, go to the previous leaf module
                const previousLeafModuleIndex = state.currentPromptState.currentLeafModuleIndex - 1;

                if (previousLeafModuleIndex < 0) {
                    // At beginning of template. Jump to the first module
                    const firstModuleId = currentLeafModules[0];
                    currentTemplateSlice.caseReducers.jumpToModule(state, { payload: { moduleId: firstModuleId, viewId: currentViewId }, type: '' });
                    return;
                }

                const previousModuleId = currentLeafModules[previousLeafModuleIndex];
                const previousModule = moduleIdToModuleMap.get(previousModuleId);
                // If the module has prompts, jump to the last one
                if (previousModule.prompts.length > 0) {
                    const lastPromptId = previousModule.prompts.at(-1).promptId;
                    currentTemplateSlice.caseReducers.jumpToPrompt(state, { payload: { viewId: currentViewId, promptId: lastPromptId }, type: '' });
                } else {
                    // Otherwise, just jump to the module
                    currentTemplateSlice.caseReducers.jumpToModule(state, {
                        payload: { viewId: currentViewId, moduleId: previousModuleId },
                        type: '',
                    });
                }
            }
        },
        /**
         * Sets the locale for the template. Not used in the reducer, but helpful for components to know/share
         * @param state current Redux state
         * @param action the new locale
         */
        setTemplateLocale: (state, action: PayloadAction<string>) => {
            state.templateLocale = action.payload;
        },
        /**
         * Sets whether the user is viewing or editing a template
         * @param state current Redux state
         * @param action the new value of `currentlyEditing`
         */
        setCurrentlyEditing: (state, action: PayloadAction<boolean>) => {
            state.currentlyEditing = action.payload;
        },
        /**
         * Adds a new module to the store. The module can be either a root, middle, or leaf module.
         * @param state current Redux state
         * @param action viewId, the module that should contain the new module, and the new module
         */
        createTemplateModule: (
            state,
            action: PayloadAction<{ viewId: string; containingModuleId: string | undefined; newModule: AssessmentModule }>
        ) => {
            const { viewId, containingModuleId, newModule } = action.payload;

            moduleIdToModuleMap.set(newModule.moduleId, newModule);
            moduleIdToFirstLeafModuleId.set(newModule.moduleId, newModule.moduleId);

            // Make module descriptors editable
            newModule.descriptors.forEach((descriptor) => {
                state.descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
            });

            // If there is a containing module, update it to include the new module
            if (containingModuleId) {
                const parentModule = moduleIdToModuleMap.get(containingModuleId);
                const newContainingModule: AssessmentModule = {
                    ...parentModule,
                    childModules: [...parentModule.childModules, newModule],
                };
                moduleIdToModuleMap.set(containingModuleId, newContainingModule);

                // Also change the parent module's first leaf, if the parent was a leaf
                if (moduleIdToFirstLeafModuleId.get(parentModule.moduleId) === parentModule.moduleId) {
                    moduleIdToFirstLeafModuleId.set(parentModule.moduleId, newModule.moduleId);
                }
            } else {
                // Otherwise, we're adding a root module
                const rootModuleIdsArray = state.currentPromptState.viewIdToRootModuleIdsMap.get(viewId);
                rootModuleIdsArray.push(newModule.moduleId);
                state.currentPromptState.viewIdToRootModuleIdsMap.set(viewId, rootModuleIdsArray);
            }

            /* Insert the new module into the leaf modules array */
            const leafModulesArray = state.currentPromptState.viewIdToLeafModuleIdsMap.get(viewId);

            // Find where to put the new module and insert it
            const previousModule = findPreviousLeafForNewlyAddedModule(state.currentPromptState.viewIdToRootModuleIdsMap, viewId, newModule.moduleId);
            const previousModuleIndex = leafModulesArray.indexOf(previousModule?.moduleId);
            leafModulesArray.splice(previousModuleIndex + 1, 0, newModule.moduleId);

            // If containingModule is in the array, remove it, as the containing module is no longer a leaf
            const containingModuleIndex = leafModulesArray.indexOf(containingModuleId);
            if (containingModuleIndex >= 0) {
                leafModulesArray.splice(containingModuleIndex, 1);
            }

            state.currentPromptState.viewIdToLeafModuleIdsMap.set(viewId, leafModulesArray);

            // Finally, jump to the new module
            currentTemplateSlice.caseReducers.jumpToModule(state, { payload: { viewId, moduleId: newModule.moduleId }, type: '' });
        },
        /**
         * Deletes a module from the store. Module must be a leaf and have no prompts
         * @param state current Redux state
         * @param action viewId / moduleId to delete
         */
        deleteTemplateModule: (state, action: PayloadAction<{ viewId: string; moduleId: string }>) => {
            const { viewId, moduleId } = action.payload;

            let moduleIdToJumpTo: string;

            const moduleToDelete = moduleIdToModuleMap.get(moduleId);

            // Remove the module from the module maps
            moduleIdToModuleMap.delete(moduleId);
            moduleIdToFirstLeafModuleId.delete(moduleId);

            /* The module is guaranteed to be a leaf module, as modules with child modules cannot be deleted */

            // Remove the module from the leaf modules array
            const leafModulesArray = state.currentPromptState.viewIdToLeafModuleIdsMap.get(viewId);
            const deletedModuleLeafIndex = leafModulesArray.indexOf(moduleId);
            leafModulesArray.splice(deletedModuleLeafIndex, 1);

            if (moduleToDelete.parentModule) {
                const parentModule = moduleIdToModuleMap.get(moduleToDelete.parentModule.moduleId);

                // If the deleted module was the parent module's firstLeaf, then update the firstLeaf
                if (moduleIdToFirstLeafModuleId.get(parentModule.moduleId) === moduleToDelete.moduleId) {
                    // If the parent module has another child module, adopt the leaf of the next child module
                    if (parentModule.childModules.length > 1) {
                        const secondChildLeafModuleId = moduleIdToFirstLeafModuleId.get(parentModule.childModules[1].moduleId);
                        moduleIdToFirstLeafModuleId.set(parentModule.moduleId, secondChildLeafModuleId);
                    } else {
                        // Otherwise, there are no more child modules in this parent, so it becomes a leaf
                        moduleIdToFirstLeafModuleId.set(parentModule.moduleId, parentModule.moduleId);

                        // Add the parent to be a leaf too, in place of the deleted module
                        leafModulesArray.splice(deletedModuleLeafIndex, 0, parentModule.moduleId);
                    }
                }

                // Update parent module's children - need to create a copy because directly modifying `parent.childModules` is forbidden by Redux
                const updatedParentModule = {
                    ...parentModule,
                    childModules: parentModule.childModules.filter((childModule) => childModule.moduleId !== moduleToDelete.moduleId),
                };
                moduleIdToModuleMap.set(parentModule.moduleId, updatedParentModule);

                // Jump to parent once done
                moduleIdToJumpTo = parentModule.moduleId;
            } else {
                // Otherwise, remove the root module
                const rootModuleIdsArray = state.currentPromptState.viewIdToRootModuleIdsMap.get(viewId);
                const deletedModuleIndex = rootModuleIdsArray.indexOf(moduleId);
                rootModuleIdsArray.splice(deletedModuleIndex, 1);
                state.currentPromptState.viewIdToRootModuleIdsMap.set(viewId, rootModuleIdsArray);

                // Jump to previous module once done. Assume that there's always at least one root module
                moduleIdToJumpTo = rootModuleIdsArray.at(deletedModuleIndex) || rootModuleIdsArray.at(deletedModuleIndex - 1);
            }

            state.currentPromptState.viewIdToLeafModuleIdsMap.set(viewId, leafModulesArray);

            // Jump to previous module
            currentTemplateSlice.caseReducers.jumpToModule(state, { payload: { viewId, moduleId: moduleIdToJumpTo }, type: '' });
        },
        /**
         * Adds `newPrompt` at the end of `containingModuleId` in the Redux store
         * @param state current Redux state
         * @param action viewId, the module that should contain the new prompt, the previous prompt, and newly created prompt
         */
        createTemplatePrompt: (state, action: PayloadAction<{ viewId: string; containingModuleId: string; newPrompt: AssessmentPrompt }>) => {
            const { viewId, containingModuleId, newPrompt } = action.payload;

            newPrompt.descriptors.forEach((descriptor) => {
                state.descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
            });

            const newPromptViewModel: TemplatePromptViewModel = {
                ...newPrompt,
                containingModuleId,
            };

            // Update the containing module to include the new prompt
            const containingModule = moduleIdToModuleMap.get(containingModuleId);
            const newContainingModule: AssessmentModule = {
                ...containingModule,
                prompts: [...containingModule.prompts, newPromptViewModel],
            };
            moduleIdToModuleMap.set(containingModuleId, newContainingModule);

            // Find the previous prompt, so we know where to insert the new prompt in the prompts array
            let previousPromptId = '';
            if (containingModule.prompts.length > 0) {
                previousPromptId = containingModule.prompts.at(-1).promptId;
            } else {
                // The containing module doesn't have any prompts yet. Need to grab the previousPromptId from the previous module
                const modulesArray = state.currentPromptState.viewIdToLeafModuleIdsMap.get(viewId);
                const containingModuleIndex = modulesArray.findIndex((moduleId) => moduleId === containingModuleId);
                let previousModuleIdIndex = containingModuleIndex - 1;

                // Keep going to previous modules, until we find a previous prompt or we reach the very first module
                while (previousModuleIdIndex >= 0 && !previousPromptId) {
                    const previousModuleId = modulesArray[previousModuleIdIndex];
                    const previousModule = moduleIdToModuleMap.get(previousModuleId);
                    previousPromptId = previousModule.prompts.at(-1)?.promptId;

                    previousModuleIdIndex--;
                }
            }

            // Insert the new prompt into the prompts array. If there's no previousPromptId, the new prompt will be inserted at the beginning of the array
            const prompts = state.currentPromptState.viewIdToPromptsMap.get(viewId);
            const promptIndex = prompts.findIndex((prompt) => prompt.promptId === previousPromptId);
            prompts.splice(promptIndex + 1, 0, newPromptViewModel); // splice modifies inline
            state.currentPromptState.viewIdToPromptsMap.set(viewId, prompts);

            // Add the new prompt to the viewId-promptId map
            viewIdPromptIdToPromptMap.set(`${viewId}${newPrompt.promptId}`, newPromptViewModel);

            // Update the number of prompts in the view
            const numberOfPromptsInView = state.currentPromptState.viewIdToNumberOfPrompts.get(viewId);
            state.currentPromptState.viewIdToNumberOfPrompts.set(viewId, numberOfPromptsInView + 1);

            // Navigate to the newly created prompt
            currentTemplateSlice.caseReducers.jumpToPrompt(state, { payload: { viewId, promptId: newPrompt.promptId }, type: '' });
        },
        /**
         * Updates a prompt in the local template store
         * @param state current Redux state
         * @param action the modified prompt
         */
        updateTemplatePrompt: (state, action: PayloadAction<AssessmentPrompt>) => {
            const updatedPrompt = action.payload;

            // Add any new descriptors to the store: check selections, selection description, and ratings
            updatedPrompt.selections?.forEach((selection) => {
                selection.selectionLabel?.forEach((descriptor) => {
                    state.descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
                });
            });
            updatedPrompt.selectionDescription?.forEach((descriptor) => {
                state.descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
            });
            if (updatedPrompt.ratingGuide) {
                const ratingGuide = updatedPrompt.ratingGuide;
                [ratingGuide.guide1, ratingGuide.guide2, ratingGuide.guide3, ratingGuide.guide4, ratingGuide.guide5].forEach(
                    (ratingGuideDescriptors) => {
                        ratingGuideDescriptors?.forEach((descriptor) => {
                            state.descriptorIdLocaleToEditableDescriptorObject[`${descriptor.descriptorId}${descriptor.locale}`] = descriptor;
                        });
                    }
                );
            }

            // Update current prompt, if applicable
            const currentPrompt = state.currentPromptState.currentPrompt;
            if (currentPrompt.promptId === updatedPrompt.promptId) {
                state.currentPromptState.currentPrompt = {
                    ...updatedPrompt,
                    containingModuleId: currentPrompt.containingModuleId,
                };
            }

            // Update the prompt in any views that use the prompt
            state.currentPromptState.viewIdToPromptsMap.forEach((prompts) => {
                const promptIndex = prompts.findIndex((prompt) => prompt.promptId === prompt.promptId);
                if (promptIndex !== -1) {
                    prompts[promptIndex] = {
                        ...updatedPrompt,
                        containingModuleId: prompts[promptIndex].containingModuleId,
                    };
                }
            });
        },
        /**
         * Deletes the current template prompt
         * @param state current Redux state
         */
        deleteCurrentTemplatePrompt: (state) => {
            const promptIdToDelete = state.currentPromptState.currentPrompt.promptId;

            // Delete prompt from the current module
            const currentModule = moduleIdToModuleMap.get(state.currentPromptState.currentPrompt.containingModuleId);
            const newCurrentModule: AssessmentModule = {
                ...currentModule,
                prompts: currentModule.prompts.filter((prompt) => prompt.promptId !== promptIdToDelete),
            };
            moduleIdToModuleMap.set(state.currentPromptState.currentPrompt.containingModuleId, newCurrentModule);

            // For each view, if the prompt is in the view, remove it from any indexing
            Array.from(state.currentPromptState.viewIdToPromptsMap.keys()).forEach((viewId) => {
                if (!getPromptFromViewIdPromptId(viewId, promptIdToDelete)) {
                    return; // prompt not in view
                }

                // Remove from viewIdToPromptsMap
                const prompts = state.currentPromptState.viewIdToPromptsMap.get(viewId);
                prompts.splice(
                    prompts.findIndex((prompt) => prompt.promptId === promptIdToDelete),
                    1
                );
                state.currentPromptState.viewIdToPromptsMap.set(viewId, prompts);

                // Remove from viewIdToNumberOfPrompts
                const numberOfPromptsInView = state.currentPromptState.viewIdToNumberOfPrompts.get(viewId);
                state.currentPromptState.viewIdToNumberOfPrompts.set(viewId, numberOfPromptsInView - 1);

                // Remove from viewIdPromptIdToPromptMap
                viewIdPromptIdToPromptMap.delete(`${viewId}${promptIdToDelete}`);
            });

            // Jump to the previous prompt
            currentTemplateSlice.caseReducers.goToPreviousPromptOrModule(state);
        },
        /**
         * Updates a descriptor in the current template (only in the Redux store)
         * @param state current Redux state
         * @param action the descriptor update
         */
        updateDescriptor: (state, action: PayloadAction<{ input: UpdateDescriptorInput; keyToUpdate: EditableDescriptorKey }>) => {
            const { input, keyToUpdate } = action.payload;

            const descriptor = state.descriptorIdLocaleToEditableDescriptorObject[`${input.descriptorId}${input.locale}`];
            if (descriptor) {
                descriptor[keyToUpdate] = input[keyToUpdate];
            }
        },
        /**
         * Updates the locally stored template permissions
         * @param state current Redux state
         * @param action the added and removed permissions
         */
        updateTemplatePermissions: (
            state,
            action: PayloadAction<{ addedPermissions: PrincipalIdToTemplateActorMapping[]; removedPermissions: PrincipalIdToTemplateActorMapping[] }>
        ) => {
            const currentCoowners = state.currentTemplateMetadata.coowners;
            const currentLocaleManagers = state.currentTemplateMetadata.localeManagers;

            const coownersToAdd = action.payload.addedPermissions.filter((permission) => permission.templateActor === ShareableTemplateActor.CoOwner);
            const coownersToRemove = action.payload.removedPermissions.filter(
                (permission) => permission.templateActor === ShareableTemplateActor.CoOwner
            );
            const localeManagersToAdd = action.payload.addedPermissions.filter(
                (permission) => permission.templateActor === ShareableTemplateActor.LocaleManager
            );
            const localeManagersToRemove = action.payload.removedPermissions.filter(
                (permission) => permission.templateActor === ShareableTemplateActor.LocaleManager
            );

            const newCoowners = currentCoowners
                .filter((coowner) => !coownersToRemove.some((permission) => permission.principalId === coowner))
                .concat(coownersToAdd.map(({ principalId }) => principalId));

            const updatedLocaleManagers = currentLocaleManagers;
            // For each addition, update the locale managers
            localeManagersToAdd.forEach(({ principalId, locale }) => {
                const localeManagersForLocale = updatedLocaleManagers.find(({ locale: existingLocale }) => existingLocale === locale);
                // Array with permissions for this locale already exists
                if (localeManagersForLocale) {
                    localeManagersForLocale.localeManagers.push(principalId);
                } else {
                    // Create permissions array for this locale
                    updatedLocaleManagers.push({ locale, localeManagers: [principalId] });
                }
            });
            // For each removal, update
            localeManagersToRemove.forEach(({ principalId, locale }) => {
                const localeManagersForLocale = updatedLocaleManagers.find(({ locale: existingLocale }) => existingLocale === locale);
                if (localeManagersForLocale) {
                    localeManagersForLocale.localeManagers = localeManagersForLocale.localeManagers.filter(
                        (existingPrincipalId) => existingPrincipalId !== principalId
                    );
                }
            });

            state.currentTemplateMetadata.coowners = newCoowners;
            state.currentTemplateMetadata.localeManagers = updatedLocaleManagers;
        },
        /**
         * Updates the locally stored assessment type permissions
         * @param state current Redux state
         * @param action the added and removed permissions
         */
        updateAssessmentTypePermissions: (
            state,
            action: PayloadAction<{
                addedPermissions: PrincipalIdToAssessmentTypeActionMapping[];
                removedPermissions: PrincipalIdToAssessmentTypeActionMapping[];
            }>
        ) => {
            const currentAssessmentCreators = state.currentTemplateMetadata.authorizedAssessmentCreators;

            const assessmentTypePermissionsToAdd: string[] = action.payload.addedPermissions.map(({ principalId }) => principalId);
            const assessmentTypePermissionsToRemove: PrincipalIdToAssessmentTypeActionMapping[] = action.payload.removedPermissions;

            const newAssessmentCreators = currentAssessmentCreators
                .filter(
                    (authorizedPrincipal) =>
                        !assessmentTypePermissionsToRemove.some((removedPermission) => removedPermission.principalId === authorizedPrincipal)
                )
                .concat(assessmentTypePermissionsToAdd);

            state.currentTemplateMetadata.authorizedAssessmentCreators = newAssessmentCreators;
        },
        /**
         * Updates a default
         * @param state current Redux state
         * @param action the modified default, as well as the containing namespace
         */
        createOrUpdateDefault: (state, action: PayloadAction<TemplateDefaultWithNamespace>) => {
            const { key, namespace, value, valueType, descriptors } = action.payload;

            // Get the descriptor objects
            const descriptorObjects: AssessmentDescriptor[] | undefined = descriptors?.map(
                (descriptorId) => state.descriptorIdLocaleToEditableDescriptorObject[`${descriptorId}${state.templateLocale}`]
            );

            const defaultNamespace: AssessmentTemplateDefaultNamespace | undefined = state.currentTemplate?.config.defaultNamespaces.find(
                (templateNamespace) => templateNamespace.namespace === namespace
            );

            // If the namespace doesn't already exist, create it
            if (!defaultNamespace) {
                state.currentTemplate.config.defaultNamespaces.push({
                    namespace,
                    defaults: [{ key, value, valueType, descriptors: descriptorObjects }],
                });
                return;
            }

            const templateDefault: AssessmentTemplateDefault | undefined = state.currentTemplate?.config.defaultNamespaces
                .find((templateNamespace) => templateNamespace.namespace === namespace)
                ?.defaults.find((templateDefault) => templateDefault.key === key);

            // If the default doesn't already exist, create it
            if (!templateDefault) {
                defaultNamespace.defaults.push({ key, value, valueType, descriptors: descriptorObjects });
                return;
            }

            templateDefault.value = value;
            templateDefault.descriptors = descriptorObjects;
        },
        /**
         * Adds a request to the currently in-progress requests set
         * @param state current Redux state
         * @param action a UUID identifying the request
         */
        addInProgressRequest: (state, action: PayloadAction<string>) => {
            state.editTemplateInProgressRequests.add(action.payload);
        },
        /**
         * Indicates that a request has completed. If the request failed, set `didRequestFail` to `true`
         * @param state current Redux state
         * @param action the ID of the request that just completed, and if that request failed
         */
        removeInProgressRequest: (state, action: PayloadAction<{ requestId: string; didRequestFail?: boolean }>) => {
            state.editTemplateInProgressRequests.delete(action.payload.requestId);
            if (action.payload.didRequestFail) {
                state.editTemplateShowError = true;
            }
        },
    },
});

/**
 * Returns any module in the template, given its ID
 * @param moduleId the module ID
 * @returns the corresponding module
 */
export const getModuleFromModuleId = (moduleId: string): AssessmentModule | undefined => {
    return moduleIdToModuleMap.get(moduleId);
};

/**
 * Returns the first prompt ID in the specified module
 * @param moduleId the ID of the module
 * @returns the first prompt ID in that module (or its children)
 */
export const getFirstPromptIdInModule = (moduleId: string): string | undefined => {
    const firstLeafModuleId: string = moduleIdToFirstLeafModuleId.get(moduleId);
    const promptId: string = getModuleFromModuleId(firstLeafModuleId).prompts.at(0)?.promptId;
    return promptId;
};

/**
 * Returns the prompt with the given ID
 * @param viewId the view within which the prompt exists
 * @param promptId the prompt ID to retrieve
 * @returns the corresponding prompt
 */
export const getPromptFromViewIdPromptId = (viewId: string, promptId: string): TemplatePromptViewModel => {
    return viewIdPromptIdToPromptMap.get(`${viewId}${promptId}`);
};

/**
 * The timeline in the facilitate page shows the modules that are at the same level as
 * the current module. This function returns the modules that are at that same level
 * @param moduleId the module, or undefined if no module is loaded
 * @returns the modules that are at the same level as the provided module
 */
export const getSiblingModulesOfModule = (moduleId: string | undefined): AssessmentModule[] => {
    const containingModule: AssessmentModule = moduleIdToModuleMap.get(moduleId);
    // There may be no parent module, as the provided module might be a root module
    const parentModuleId: string = containingModule?.parentModule?.moduleId || '';
    const parentModuleOfSiblings: AssessmentModule | undefined = moduleIdToModuleMap.get(parentModuleId);
    return parentModuleOfSiblings?.childModules || [];
};

/**
 * Navigates through a module's descendents to find the rightmost leaf
 * @param module the module whose rightmost leaf should be found
 * @returns the rightmost leaf module
 */
const findRightmostLeafModuleInDescendents = (module: AssessmentModule): AssessmentModule => {
    // If the module is a leaf module, return it
    if (module.childModules.length === 0) {
        return module;
    }

    // Recursively search in the sibling module's child modules
    return findRightmostLeafModuleInDescendents(module.childModules.at(-1));
};

/**
 * Finds the leaf module right before the newly added `moduleId`. Helpful for finding where a new module should go in
 * the sequence of modules that is displayed to the user
 *
 * Whiteboard explaining how this function works. Small mistake: `i` should not be in the leaf array
 * https://maxis-file-service-prod-iad.iad.proxy.amazon.com/issues/36cff062-38bd-46a6-b436-d6e3ca743ab0/attachments/d787f93c0ffd31c7b306c9015e7254b727ebaf87b1267c6a67492c8c0f5efb2a_8133898d-ce8a-44c1-a33c-c3aabbe3a06b
 *
 * @pre the specified module has no childModules, as it was just added
 *
 * @param viewIdToRootModuleIdsMap the root modules in each view
 * @param viewId the view to find the previous leaf in
 * @param moduleId the ID of the newly added module
 * @returns the previous leaf module, or null if there is no previous leaf
 */
const findPreviousLeafForNewlyAddedModule = (
    viewIdToRootModuleIdsMap: Map<string, string[]>,
    viewId: string,
    moduleId: string
): AssessmentModule | null => {
    const module: AssessmentModule = moduleIdToModuleMap.get(moduleId);

    // If the module has no parent, then we've added a root module. Try to find the rightmost leaf of the previous root module
    if (!module.parentModule) {
        const modulesInView = viewIdToRootModuleIdsMap.get(viewId);
        const indexOfPreviousModule = modulesInView.indexOf(moduleId) - 1;

        if (indexOfPreviousModule >= 0) {
            const previousRootModuleId = modulesInView[indexOfPreviousModule];
            const previousRootModule = moduleIdToModuleMap.get(previousRootModuleId);
            return findRightmostLeafModuleInDescendents(previousRootModule);
        }

        // If there is no previous root module, then we're adding a module in the very first place. Return `null` to indicate this
        return null;
    }

    const parentModule = moduleIdToModuleMap.get(module.parentModule.moduleId);

    // If the parent module has only this module as a child, then the parent module was previously a leaf
    if (parentModule.childModules.length === 1) {
        return parentModule;
    }

    // Finally, check this module's closest sibling
    const moduleDirectSiblingIndex = parentModule.childModules.indexOf(module) - 1;
    return findRightmostLeafModuleInDescendents(parentModule.childModules[moduleDirectSiblingIndex]);
};

export const {
    loadTemplateSuccess,
    loadTemplateMetadata,
    beginLoadingTemplate,
    completeLoadingTemplate,
    unloadCurrentTemplate,
    requestRefreshTemplate,
    loadTemplateFailure,
    jumpToModule,
    jumpToPrompt,
    goToNextPromptOrModule,
    goToPreviousPromptOrModule,
    setTemplateLocale,
    setCurrentlyEditing,
    createTemplateModule,
    deleteTemplateModule,
    createTemplatePrompt,
    updateTemplatePrompt,
    deleteCurrentTemplatePrompt,
    updateDescriptor,
    updateTemplatePermissions,
    updateAssessmentTypePermissions,
    createOrUpdateDefault,
    addInProgressRequest,
    removeInProgressRequest,
} = currentTemplateSlice.actions;

export default currentTemplateSlice.reducer;
