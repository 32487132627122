import { AddLocaleInput, AssessmentTemplateMetadata } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import {
    Box,
    Button,
    Modal,
    NonCancelableCustomEvent,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    Toggle,
} from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ModifyTemplateStructureButtons from './modifyTemplateStructureButtons/ModifyTemplateStructureButtons';
import templateManagementClient from '../../../../api/templateManagement/TemplateManagementClient';
import { ADD_LOCALE } from '../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { SUPPORTED_LOCALES } from '../../../../common/Localization';
import { getLanguageName } from '../../../../common/Utils';
import rumClient from '../../../../common/monitoring/RumClient';
import { requestRefreshTemplate, setCurrentlyEditing } from '../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { canAddLocale, canEditLocale } from '../../../administration/manage-templates/edit-template/TemplatePermissionsUtils';
import { generateTemplateModuleUrl } from '../../../administration/manage-templates/edit-template/TemplateUrlUtils';
import { isTemplatePublished } from '../../../administration/manage-templates/edit-template/TemplateUtils';
import { LocalizationContextInterface, withLocalizationContext } from '../../../localization/LocalizationContext';
import { useAppSelector } from '../../../redux/hooks';

type AddLocaleModalProps = AppLabelsContextInterface & {
    uiLocale: string;
    visible: boolean;
    setVisible: (visible: boolean) => void;
};
const AddLocaleModal: FunctionComponent<AddLocaleModalProps> = ({ appLabels, uiLocale, visible, setVisible }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const templateId: string = useAppSelector((state) => state.currentTemplateState.currentTemplateId);
    const localesAlreadySupportedInTemplate: string[] = useAppSelector((state) => state.currentTemplateState.supportedLocales);

    const [newLocale, setNewLocale] = useState('');
    const [loading, setLoading] = useState(false);

    // Calculate the list of locales that the user can add. Use arrays to preserve order
    const localesToSelect: string[] = useMemo(() => {
        return SUPPORTED_LOCALES.filter((locale) => !localesAlreadySupportedInTemplate.includes(locale));
    }, [localesAlreadySupportedInTemplate]);

    const options: SelectProps.Option[] = useMemo(() => {
        return localesToSelect.map((locale): SelectProps.Option => {
            return {
                value: locale,
                label: getLanguageName(locale, uiLocale),
            };
        });
    }, [uiLocale, localesToSelect]);

    const [addLocaleMutation] = useMutation(ADD_LOCALE, {
        client: templateManagementClient,
        onError: (error) => {
            rumClient.recordError(error);
        },
        onCompleted: () => {
            // After locale addition is done, navigate to it. Need to refresh current template to get new descriptors
            dispatch(requestRefreshTemplate());
            history.push(generateTemplateModuleUrl(templateId, newLocale));
        },
    });

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loading} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    const addLocaleInput: AddLocaleInput = {
                                        templateId,
                                        locale: newLocale,
                                    };
                                    await addLocaleMutation({ variables: { input: addLocaleInput } });
                                } finally {
                                    setLoading(false);
                                    setVisible(false);
                                }
                            }}
                            data-testid='btn-confirm-add-locale'
                        >
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.edit.add_locale}
            visible={visible}
        >
            <SpaceBetween size='m'>
                <Select
                    data-testid='add-locale-selection'
                    placeholder={appLabels.manage_templates.edit.select_locale_placeholder}
                    selectedOption={options.find(({ value }) => value === newLocale)}
                    options={options}
                    onChange={({ detail }) => {
                        setNewLocale(detail.selectedOption.value);
                    }}
                />
                {appLabels.manage_templates.edit.request_language}
            </SpaceBetween>
        </Modal>
    );
};

type EditTemplateComponentInHeaderProps = AppLabelsContextInterface & LocalizationContextInterface & AuthContextInterface;

/**
 * All header components for the "edit template" feature are in this component
 *
 * Should be rendered only if a template is being displayed
 */
const EditTemplateComponentInHeader: FunctionComponent<EditTemplateComponentInHeaderProps> = ({ locale: uiLocale, appLabels, auth }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showAddLocaleModal, setShowAddLocaleModal] = useState<boolean>(false);

    // Template state
    const templateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);
    const supportedLocalesInTemplate: string[] = useAppSelector((state) => state.currentTemplateState.supportedLocales);
    const templateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);
    const currentlyEditingTemplate: boolean = useAppSelector((state) => state.currentTemplateState.currentlyEditing);
    const editTemplateInProgressRequests: Set<string> = useAppSelector((state) => state.currentTemplateState.editTemplateInProgressRequests);
    const shouldShowEditTemplateError: boolean = useAppSelector((state) => state.currentTemplateState.editTemplateShowError);
    const currentTemplateId = useAppSelector((state) => state.currentTemplateState.currentTemplateId);
    const currentViewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentModuleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt?.containingModuleId);

    const canEditTemplate = useMemo(() => {
        // Can edit if:
        // 1. The template is not published
        // 2. The user has permission to edit the current locale
        return !isTemplatePublished(templateMetadata) && canEditLocale(templateLocale, templateMetadata, auth);
    }, [auth, templateLocale, templateMetadata]);

    const canAddLocaleToTemplate = useMemo(() => {
        // Can add locale if:
        // 1. The template is not published
        // 2. User has permissions to add a locale
        return !isTemplatePublished(templateMetadata) && canAddLocale(templateMetadata, auth);
    }, [auth, templateMetadata]);

    const editTemplateToggle = useMemo(() => {
        return canEditTemplate ? (
            <Toggle checked={currentlyEditingTemplate} onChange={({ detail }) => dispatch(setCurrentlyEditing(detail.checked))}>
                {appLabels.manage_templates.edit.toggle}
            </Toggle>
        ) : null;
    }, [canEditTemplate, currentlyEditingTemplate, dispatch, appLabels.manage_templates.edit.toggle]);

    /**
     * A status indicator for editing a template
     */
    const editTemplateSaveStatus = useMemo(() => {
        if (!canEditTemplate) {
            return null;
        }

        if (shouldShowEditTemplateError) {
            return <StatusIndicator type='error'>{appLabels.statuses.failed_to_save_refresh}</StatusIndicator>;
        }

        if (editTemplateInProgressRequests?.size > 0) {
            return <StatusIndicator type='loading'>{appLabels.statuses.saving}</StatusIndicator>;
        } else {
            return <StatusIndicator type='success'>{appLabels.statuses.saved}</StatusIndicator>;
        }
    }, [editTemplateInProgressRequests, shouldShowEditTemplateError, canEditTemplate, appLabels.statuses]);

    /**
     * The locale options for viewing the template
     */
    const templateLocaleOptions: SelectProps.Option[] = useMemo(() => {
        // Show the supported languages
        const options = supportedLocalesInTemplate.map(
            (locale): SelectProps.Option => ({
                value: locale,
                label: getLanguageName(locale, uiLocale),
            })
        );

        // Show the "add locale" option if user has permissions
        if (canAddLocaleToTemplate) {
            options.push({
                value: 'add-locale',
                label: appLabels.manage_templates.edit.add_locale,
                iconName: 'add-plus',
            });
        }

        return options;
    }, [canAddLocaleToTemplate, supportedLocalesInTemplate, uiLocale, appLabels.manage_templates.edit.add_locale]);

    /**
     * When the user selects a different locale for the template, change the URL to that locale
     *
     * When the user wants to add a locale, show the "add locale" modal
     */
    const onTemplateLocaleSelection = useCallback(
        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
            const newLocale = event.detail.selectedOption.value;

            // If a supported locale, just change the URL to that locale
            if (supportedLocalesInTemplate.includes(newLocale)) {
                history.push(generateTemplateModuleUrl(currentTemplateId, newLocale, currentViewId, currentModuleId));
            } else {
                // Otherwise, open the "add locale" modal
                setShowAddLocaleModal(true);
            }
        },
        [supportedLocalesInTemplate, currentTemplateId, currentViewId, currentModuleId, history]
    );

    /**
     * The dropdown for selecting the current locale
     */
    const templateLocaleDropdown = useMemo(() => {
        return (
            <Select
                data-testid='template-locale-selection'
                selectedOption={templateLocaleOptions.find(({ value }) => value === templateLocale)}
                options={templateLocaleOptions}
                onChange={onTemplateLocaleSelection}
            />
        );
    }, [templateLocaleOptions, templateLocale, onTemplateLocaleSelection]);

    return (
        <>
            <SpaceBetween direction='horizontal' size='l' alignItems='center'>
                <ModifyTemplateStructureButtons />
                {templateLocaleDropdown}
                {editTemplateSaveStatus}
                {editTemplateToggle}
            </SpaceBetween>
            <AddLocaleModal visible={showAddLocaleModal} setVisible={setShowAddLocaleModal} appLabels={appLabels} uiLocale={uiLocale} />
        </>
    );
};

export default withAuthContext(withAppLabelsContext(withLocalizationContext(EditTemplateComponentInHeader)));
