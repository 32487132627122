import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import rumClient from './RumClient';
import { EnvironmentConfig } from '../../Environments';
import { CookieConsentContext } from '../context/CookieConsentContext';

export const RumManager = (env: EnvironmentConfig): void => {
    const { cookie } = useContext(CookieConsentContext);
    const [hasPerfCookieConsent, setHasPerfCookieConsent] = useState(cookie.performance);
    rumClient.init(env);

    // Only enable cookies if perf cookie enabled
    useEffect(() => {
        if (cookie.performance !== hasPerfCookieConsent) {
            setHasPerfCookieConsent(cookie.performance);
            rumClient.enableCookies(cookie.performance);
        }
    }, [cookie, hasPerfCookieConsent]);

    // Manually record page views to merge out unique pages
    const location = useLocation();
    useEffect(() => {
        rumClient.recordPageView(filterPageView(location.pathname));
    }, [location]);
};

// Create list of filters that remove unique IDs in the URL path
// Ensure new filters are placed in order otherwise pattern matching may not work
// due to previous substitutions
export const filterPageView = (path: string): string => {
    let result = path;

    // Filter UIDs from the URL path
    result = result.replace(RegExp('/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', 'gi'), '/UUID');

    return result;
};
