import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CustomerViewModel } from './CustomerViewModel';

export interface PartnerCustomersState {
    customers: CustomerViewModel[];
    selectedCustomerForUpdate: CustomerViewModel | null;
}

export const initialState: PartnerCustomersState = {
    customers: [],
    selectedCustomerForUpdate: null,
};

export const partnerCustomersSlice = createSlice({
    name: 'partnerCustomersState',
    initialState,
    reducers: {
        loadPartnerCustomersSuccess: (state, action: PayloadAction<CustomerViewModel[]>) => {
            state.customers = [];
            action.payload.forEach((a) => {
                state.customers.push(a);
            });
        },
        createPartnerCustomerSuccess: (state, action: PayloadAction<CustomerViewModel>) => {
            state.customers = [action.payload, ...state.customers];
        },
        selectCustomerForUpdate: (state, action: PayloadAction<CustomerViewModel>) => {
            state.selectedCustomerForUpdate = action.payload;
        },
        updatePartnerCustomerSuccess: (state, action: PayloadAction<CustomerViewModel>) => {
            const updatedCustomer = action.payload;
            const updatedCustomers = state.customers.map((c) => (c.id === updatedCustomer.id ? updatedCustomer : c));
            state.customers = [...updatedCustomers];
        },
        resetPartnerCustomers: (state) => {
            state.customers = [];
        },
    },
});

export const {
    loadPartnerCustomersSuccess,
    createPartnerCustomerSuccess,
    updatePartnerCustomerSuccess,
    selectCustomerForUpdate,
    resetPartnerCustomers,
} = partnerCustomersSlice.actions;

export default partnerCustomersSlice.reducer;
