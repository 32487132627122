import './Footer.scss';

import { CookieConsentManager, LegalFooter } from '@amzn/awscat-cookie-management';

import CookieManager from '../../../common/CookieManager';

const Footer = (): JSX.Element => {
    const getBuildVersion = () => {
        return '0.x'; // ToDo: implement build version
    };

    const cookieManager: CookieConsentManager = CookieManager.create();

    return (
        <LegalFooter
            privacyText='Privacy'
            siteTermText='Site terms'
            cookiePreferenceText='Cookie preferences'
            onCookiePreferenceClick={cookieManager.customizeCookies}
            buildVersion={getBuildVersion()}
            contactEmail='proserve-assessmenttool@amazon.com'
        />
    );
};

export default Footer;
