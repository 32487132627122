import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { CancelableEventHandler, ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { FunctionComponent, useMemo, useState } from 'react';

import AssessmentFilterDialog from './AssessmentFilterDialog';
import AssessmentPromptNavButton, { AssessmentPromptNavButtonDirection, AssessmentPromptNavButtonTarget } from './AssessmentPromptNavButton';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { useAppSelector } from '../../../redux/hooks';

type AssessmentDetailsFooterProps = AppLabelsContextInterface & {
    refreshLoading: boolean;
    onRefreshClick: CancelableEventHandler<ClickDetail>;
    enableNavButtons: boolean;
    shouldDisplayTemplate: boolean;
};

const AssessmentDetailsFooter: FunctionComponent<AssessmentDetailsFooterProps> = ({
    appLabels,
    refreshLoading,
    onRefreshClick,
    enableNavButtons = true,
    shouldDisplayTemplate = false,
}): JSX.Element => {
    const numberOfPrompts = useAppSelector((state) => state.currentAssessmentState.currentPromptState.prompts)?.length;
    const numberOfResponses = useAppSelector((state) => state.currentAssessmentState.currentPromptState.numberOfResponses);

    const currentTemplateViewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const viewIdToLeafModuleIdsMap = useAppSelector((state) => state.currentTemplateState.currentPromptState.viewIdToLeafModuleIdsMap);
    const viewIdToNumberOfPromptsMap = useAppSelector((state) => state.currentTemplateState.currentPromptState.viewIdToNumberOfPrompts);
    const currentTemplateLeafModuleIndex = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentLeafModuleIndex);
    const currentTemplatePromptIndex = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPromptIndex);

    const atStartOfTemplate: boolean = useMemo(() => {
        if (!shouldDisplayTemplate) {
            return false;
        }

        const onFirstLeafModule = currentTemplateLeafModuleIndex === 0;
        const isValidPromptIndex = currentTemplatePromptIndex > -1;
        const onFirstPrompt = currentTemplatePromptIndex === 0;

        // If on the first leaf module but there's no prompt, we're at the beginning of the template
        if (onFirstLeafModule && !isValidPromptIndex) {
            return true;
        }

        return onFirstLeafModule && onFirstPrompt;
    }, [shouldDisplayTemplate, currentTemplateLeafModuleIndex, currentTemplatePromptIndex]);

    const atEndOfTemplate: boolean = useMemo(() => {
        if (!shouldDisplayTemplate) {
            return false;
        }

        const numberOfPromptsInCurrentView = viewIdToNumberOfPromptsMap.get(currentTemplateViewId);
        const leafModulesForCurrentView = viewIdToLeafModuleIdsMap.get(currentTemplateViewId);

        const onLastLeafModule = currentTemplateLeafModuleIndex === leafModulesForCurrentView.length - 1;
        const isValidPromptIndex = currentTemplatePromptIndex > -1;
        const onLastPrompt = currentTemplatePromptIndex === numberOfPromptsInCurrentView - 1;

        // If on the last leaf module but there's no prompt, we're at the end of the template
        if (onLastLeafModule && !isValidPromptIndex) {
            return true;
        }

        return onLastLeafModule && onLastPrompt;
    }, [
        shouldDisplayTemplate,
        currentTemplateLeafModuleIndex,
        currentTemplatePromptIndex,
        currentTemplateViewId,
        viewIdToLeafModuleIdsMap,
        viewIdToNumberOfPromptsMap,
    ]);

    // Disable the next/previous unanswered nav buttons if there are no unanswered questions, or if we're in template mode
    const disableUnansweredNavButtons: boolean = numberOfResponses === numberOfPrompts || shouldDisplayTemplate;

    const [showAssessmentFilterDialog, setShowAssessmentFilterDialog] = useState(false);

    return (
        <footer className='assessment-footer'>
            <Box textAlign='center'>
                <Box float='left' padding={{ horizontal: 'xxxl' }}>
                    {enableNavButtons && (
                        <SpaceBetween size='xs' direction='horizontal'>
                            <AssessmentPromptNavButton
                                direction={AssessmentPromptNavButtonDirection.PREVIOUS}
                                target={AssessmentPromptNavButtonTarget.UNANSWERED}
                                verbose={false}
                                disabled={disableUnansweredNavButtons}
                            />
                            <AssessmentPromptNavButton
                                direction={AssessmentPromptNavButtonDirection.PREVIOUS}
                                target={AssessmentPromptNavButtonTarget.SEQUENTIAL}
                                verbose={false}
                                disabled={atStartOfTemplate}
                                shouldDisplayTemplate={shouldDisplayTemplate}
                            />
                        </SpaceBetween>
                    )}
                </Box>
                {/* Disable refreshing polling results and filtering when in template mode */}
                <Button
                    iconName='filter'
                    onClick={() => setShowAssessmentFilterDialog(true)}
                    disabled={shouldDisplayTemplate}
                    data-testid='btn-filter'
                >
                    {appLabels.assessment.facilitate.filter}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    iconName='refresh'
                    onClick={onRefreshClick}
                    loading={refreshLoading}
                    disabled={shouldDisplayTemplate}
                    data-testid='btn-refresh'
                />
                <AssessmentFilterDialog showDialog={showAssessmentFilterDialog} setShowDialog={setShowAssessmentFilterDialog} />
                <Box float='right' padding={{ horizontal: 'xxxl' }}>
                    {enableNavButtons && (
                        <SpaceBetween size='xs' direction='horizontal'>
                            <AssessmentPromptNavButton
                                direction={AssessmentPromptNavButtonDirection.NEXT}
                                target={AssessmentPromptNavButtonTarget.SEQUENTIAL}
                                verbose={false}
                                disabled={atEndOfTemplate}
                                shouldDisplayTemplate={shouldDisplayTemplate}
                            />
                            <AssessmentPromptNavButton
                                direction={AssessmentPromptNavButtonDirection.NEXT}
                                target={AssessmentPromptNavButtonTarget.UNANSWERED}
                                verbose={false}
                                disabled={disableUnansweredNavButtons}
                            />
                        </SpaceBetween>
                    )}
                </Box>
            </Box>
        </footer>
    );
};

export default withAppLabelsContext(AssessmentDetailsFooter);
