import { AssessmentTemplate } from '@amzn/awscat-aws-assessment-service-typescript-client';
import {
    EmailTemplate,
    PossibleAnswer,
    Question,
    QuestionAnswer,
    QuestionnaireAndAnswers,
} from '@amzn/awscat-data-collection-service-typescript-client';

import { AppLabels } from '../../../common/AppLabels';
import Constants from '../../../common/Constants';
import { AssessmentViewModel } from '../AssessmentViewModel';
import { filterExcludedSections } from '../results/Utils';

interface QuestionnaireAnswers {
    perspective: string | null;
    questionContextLabel: string | null;
    questionLabel: string | null;
    rating1: string | null;
    rating2: string | null;
    rating3: string | null;
    rating4: string | null;
    rating5: string | null;
    rating2Value: number | null;
    rating3Value: number | null;
    rating4Value: number | null;
    rating5Value: number | null;
    rating1Disabled: boolean | null;
    ratingNA: string | null;
    ratingNATitle: string | null;
    ratingNAValue: number | null;
    ratingNAEnabled: boolean | null;
    showGuidance: boolean | null;
    useQuestionIndex: boolean | null;
    minAnswersToScore: string | null;
    wbsEnabled: boolean | null;
    questionTypeEnabled: boolean | null;
}

export interface AssessmentQuestionnaireEmailTemplate {
    subject: string | null;
    body: string | null;
}

interface PreEventQuestionnaire {
    enabled: boolean | null;
    questions: {
        questionType: string | null;
    } | null;
    sponsorEmailTemplate: AssessmentQuestionnaireEmailTemplate | null;
    participantEmailTemplate: AssessmentQuestionnaireEmailTemplate | null;
}

export interface Template {
    defaults: {
        userQuestionnaires: {
            preEvent: PreEventQuestionnaire | null;
        } | null;
        questionnaireAnswers: QuestionnaireAnswers | null;
    } | null;
    sections: Array<{
        label: string;
        categories: Array<{
            prompts: Array<{
                id: string;
                label: {
                    text: string;
                } | null;
                questionType: string;
                responseType: string;
                rating1: string | null;
                rating2: string | null;
                rating3: string | null;
                rating4: string | null;
                rating5: string | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
}

export interface Assessment {
    id: string | null;
    accountCustomerName: string | null;
    description: string | null;
    mapProgramEngagementId: string | null;
    internalContact: string | null;
    workshopDate: string | null;
    readoutDate: string | null;
    deliveredBy: string | null;
    isDemoTest: boolean | null;
    type: string | null;
    template: Template | null;
    optOutSolutionsRecommendation: boolean | null;
}

export function getParticipantEmailTemplate(assessmentTemplate: AssessmentTemplate): EmailTemplate | null {
    const participantEmailTemplate = assessmentTemplate?.defaults?.userQuestionnaires?.preEvent?.participantEmailTemplate;
    const result =
        participantEmailTemplate?.body && participantEmailTemplate.subject
            ? { body: participantEmailTemplate.body, subject: participantEmailTemplate.subject }
            : null;
    return result;
}

export function getOwnersList(sponsor: string, facilitator: string, sharedEmails: string[]): string[] {
    const additionalOwners = new Set(sharedEmails);
    additionalOwners.delete(sponsor);
    additionalOwners.delete(facilitator);
    // Add sponsor and facilitator back and remove any falsy owners
    const list = [sponsor, facilitator].concat(Array.from(additionalOwners.values())).filter((owner) => !!owner);
    return list;
}

/**
 * Convert the pre-event questionnaire to MDC format
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getQuestionnaireFormatedQuestions(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    sendAllPromptsToPreEvent: boolean,
    excludeSections: string[]
): Question[] | null {
    const assessmentTemplate = assessment?.template;
    const promptConfig = assessmentTemplate?.defaults?.questionnaireAnswers;
    const questionTemplate: Question[] = [];
    const includedSections = filterExcludedSections(assessmentTemplate?.sections, excludeSections, assessment, null);
    // We use the || [] to nicely deal with all the possible null cases. See the type for reference
    for (const section of includedSections) {
        for (const category of section?.categories || []) {
            for (const prompt of category?.prompts || []) {
                if (prompt?.questionType === 'preEvent' || sendAllPromptsToPreEvent) {
                    const answerType: string = (prompt?.responseType || 'rating').toLowerCase();
                    const possibleAnswers: PossibleAnswer[] = [];

                    switch (answerType) {
                        case 'yes_no':
                            possibleAnswers.push(
                                { label: appLabels.common.yes, value: 'yes' },
                                { label: appLabels.common.no, value: 'no' },
                                { label: appLabels.common.i_dont_know, value: 'idk' }
                            );
                            break;
                        case 'rating':
                            // We don't do this in a loop because then we'd be accessing properties using string accessors
                            if (!promptConfig?.rating5Disabled) {
                                const label = prompt.rating5 || promptConfig?.rating5 || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = promptConfig?.rating5Value ? `${promptConfig?.rating5Value}` : '5';
                                possibleAnswers.push({ label, value });
                            }
                            if (!promptConfig?.rating4Disabled) {
                                const label = prompt.rating4 || promptConfig?.rating4 || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = promptConfig?.rating4Value ? `${promptConfig?.rating4Value}` : '4';
                                possibleAnswers.push({ label, value });
                            }
                            if (!promptConfig?.rating3Disabled) {
                                const label = prompt.rating3 || promptConfig?.rating3 || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = promptConfig?.rating3Value ? `${promptConfig?.rating3Value}` : '3';
                                possibleAnswers.push({ label, value });
                            }
                            if (!promptConfig?.rating2Disabled) {
                                const label = prompt.rating2 || promptConfig?.rating2 || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = promptConfig?.rating2Value ? `${promptConfig?.rating2Value}` : '2';
                                possibleAnswers.push({ label, value });
                            }
                            if (!promptConfig?.rating1Disabled) {
                                const label = prompt.rating1 || promptConfig?.rating1 || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = promptConfig?.rating1Value ? `${promptConfig?.rating1Value}` : '1';
                                possibleAnswers.push({ label, value });
                            }
                            if (promptConfig?.ratingNAEnabled) {
                                const label = promptConfig?.ratingNA || Constants.ASSESSMENT_DEFAULT_NA_LABEL;
                                const value = 'NA';
                                possibleAnswers.push({ label, value });
                            }
                            possibleAnswers.push({ label: appLabels.common.i_dont_know, value: 'idk' });
                            break;
                        case 'text':
                            possibleAnswers.push({ label: 'Text', value: 'text' });
                            break;
                    }

                    const question: Question = {
                        label: section?.label,
                        sourceResourceId: prompt.id,
                        questionText: prompt.label?.text ?? '',
                        answerType: answerType === 'yes_no' || answerType === 'rating' ? 'select' : answerType,
                        possibleAnswers: possibleAnswers,
                        // questionId: prompt.id,
                        required: answerType === 'text' ? false : true,
                        allowComment: answerType === 'text' ? false : true,
                    };
                    questionTemplate.push(question);
                }
            }
        }
    }
    if (questionTemplate.length) {
        return questionTemplate;
    } else {
        return null;
    }
}

/**
 * Creates a map from question ID (scope-goals-0) to pre-event questions/answers
 * @param questionnaire the pre-event questionnaire returned from MDC
 * @returns a map from question ID (scope-goals-0) to pre-event questions/answers
 */
export const createAnswerMapFromQuestionnaireAnswers = (questionnaire: QuestionnaireAndAnswers): Map<string, QuestionAnswer> => {
    const map: Map<string, QuestionAnswer> = new Map<string, QuestionAnswer>();
    questionnaire.answers.forEach((answer) => {
        map.set(answer.sourceResourceId, answer);
    });
    return map;
};
