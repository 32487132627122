import { AssessmentTemplateDefaultsAnswers, RatingBand } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { ScoreStratum } from './ScoreStratumUtils';
import { ScoredItem } from './ScoreUtils';
import { AppLabels } from '../AppLabels';
import Constants from '../Constants';

export const DefaultRatingColor = {
    RATING_1: Constants.DEFAULT_RATING_COLOR_1,
    RATING_2: Constants.DEFAULT_RATING_COLOR_2,
    RATING_3: Constants.DEFAULT_RATING_COLOR_3,
    RATING_4: Constants.DEFAULT_RATING_COLOR_4,
    RATING_5: Constants.DEFAULT_RATING_COLOR_5,
    RATING_NA: Constants.DEFAULT_RATING_COLOR_NA,
    RATING_INCOMPLETE: Constants.DEFAULT_RATING_COLOR_INCOMPLETE,
};

/**
 * Get default rating bands for rating 1 - 5
 * @param appLabels
 * @param defaultAnswers
 * @returns
 */
export const getDefaultRatingConfigurations = (appLabels: AppLabels, defaultAnswers: AssessmentTemplateDefaultsAnswers): RatingBand[] => [
    {
        // rating value 1
        lowerBound: 0,
        upperBound: 2,
        color: DefaultRatingColor.RATING_1,
        title: `${appLabels.assessment.results.review_all.report.rating} 1`,
        description: defaultAnswers?.rating1,
        __typename: 'RatingBand',
    },
    {
        // rating value 2
        lowerBound: 2,
        upperBound: 3,
        color: DefaultRatingColor.RATING_2,
        title: `${appLabels.assessment.results.review_all.report.rating} 2`,
        description: defaultAnswers?.rating2,
        __typename: 'RatingBand',
    },
    {
        // rating value 3
        lowerBound: 3,
        upperBound: 4,
        color: DefaultRatingColor.RATING_3,
        title: `${appLabels.assessment.results.review_all.report.rating} 3`,
        description: defaultAnswers?.rating3,
        __typename: 'RatingBand',
    },
    {
        // rating value 4
        lowerBound: 4,
        upperBound: 5,
        color: DefaultRatingColor.RATING_4,
        title: `${appLabels.assessment.results.review_all.report.rating} 4`,
        description: defaultAnswers?.rating4,
        __typename: 'RatingBand',
    },
    {
        // rating value 5
        lowerBound: 5,
        upperBound: 6,
        color: DefaultRatingColor.RATING_5,
        title: `${appLabels.assessment.results.review_all.report.rating} 5`,
        description: defaultAnswers?.rating5,
        __typename: 'RatingBand',
    },
];

/**
 * Get default stratum for rating NA
 * @param appLabels
 * @returns
 */
export const getDefaultNARatingStratum = (appLabels: AppLabels): ScoreStratum => ({
    backgroundColor: DefaultRatingColor.RATING_NA,
    title: appLabels.assessment.results.review_all.scores.na,
    description: appLabels.assessment.results.review_all.scores.not_applicable,
    isScoreInStratum: (scored: ScoredItem | null | undefined) => {
        if (!scored) {
            return null;
        } else {
            return scored.score === Constants.ASSESSMENT_DEFAULT_NA_SCORE;
        }
    },
});

/**
 * Get default stratum for incomplete rating
 * @param appLabels
 * @returns
 */
export const getDefaultIncompleteRatingStratum = (appLabels: AppLabels): ScoreStratum => ({
    backgroundColor: DefaultRatingColor.RATING_INCOMPLETE,
    title: appLabels.assessment.results.review_all.scores.incomplete,
    description: appLabels.assessment.results.review_all.scores.incomplete,
    isScoreInStratum: (scored: ScoredItem | null | undefined) => {
        if (!scored) {
            return null;
        } else {
            return scored?.score === null || scored?.score === undefined || scored?.score === 0;
        }
    },
});
