import './AppSideNavigationPanel.scss';

import { SideNavigation } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AdminSideNavigationSection from './AdminSideNavigationSection';
import Paths from '../../../Paths';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { replaceTemplatePromptSearchParamWithModule } from '../../administration/manage-templates/edit-template/TemplateUrlUtils';
import { convertSearchParamPromptIdToFirstPromptIdInCategory } from '../../assessments/Utils';
import { useAppSelector } from '../../redux/hooks';

const AppSideNavigationPanel = (props: AppLabelsContextInterface): JSX.Element => {
    const appSideNavigationPanelState = useAppSelector((state) => state.appSideNavigationPanelState);
    const currentTemplateModuleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);
    const items = appSideNavigationPanelState.administrationItems.concat(appSideNavigationPanelState.assessmentItems);

    const appLabels: AppLabels = props.appLabels;
    const history = useHistory();
    const location = useLocation();
    const [activeHref, setActiveHref] = useState('/');

    useEffect(() => {
        // Active href determines which side nav items are highlighted
        // When on assessment page, it should be based on prompt category
        // When on template page, it should be based on module
        const currentPath = location.pathname;

        let search: string;

        if (currentPath.includes(Paths.BASE_ASSESSMENTS_PATH)) {
            search = convertSearchParamPromptIdToFirstPromptIdInCategory(location.search);
        } else {
            search = replaceTemplatePromptSearchParamWithModule(location.search, currentTemplateModuleId);
        }

        setActiveHref(currentPath + search);
    }, [location, currentTemplateModuleId]);

    const headerText = appLabels.landing.title;
    return (
        <div>
            <SideNavigation
                activeHref={activeHref}
                header={{ text: headerText, href: '/' }}
                items={items}
                onFollow={(event) => {
                    event.preventDefault();
                    history.push(event.detail.href);
                }}
                id='awscat-assessment-side-navigation'
            />
            <AdminSideNavigationSection />
        </div>
    );
};

export default withAppLabelsContext(AppSideNavigationPanel);
