import { ValidationError } from 'class-validator';

const buildValidationErrorMap = (validationErrors: ValidationError[]): Map<string, ValidationError> => {
    return validationErrors.reduce((map, error) => {
        map.set(error.property, error);
        return map;
    }, new Map<string, ValidationError>());
};

type getError<T> = (property: keyof T) => string | undefined;

export function errorLookup<T>(validationErrors: ValidationError[]): getError<T> {
    const errorMap = buildValidationErrorMap(validationErrors);
    return (property: keyof T): string | undefined => {
        const constraints = errorMap.get(property.toString())?.constraints;
        // Get the first error value to display
        return constraints ? constraints[Object.getOwnPropertyNames(constraints)[0]] : undefined;
    };
}
