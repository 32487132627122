import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

type ResourceDropdownPros = AuthContextInterface & AppLabelsContextInterface & { isAuthenticated: boolean };

const ResourcesDropdown: FunctionComponent<ResourceDropdownPros> = ({ isAuthenticated, appLabels }): JSX.Element | null => {
    const dropdownItems: ButtonDropdownProps.ItemOrGroup[] = [
        {
            id: 'faq',
            text: appLabels.landing.moreResources.items[1].linkText,
            href: appLabels.landing.moreResources.items[1].linkLocation,
            external: true,
        },
    ];

    return isAuthenticated ? (
        <ButtonDropdown expandToViewport variant='normal' items={dropdownItems}>
            {appLabels.header.resources.title}
        </ButtonDropdown>
    ) : null;
};

export default withAuthContext(withAppLabelsContext(ResourcesDropdown));
