import './AssessmentPromptYesNo.scss';

import { ResponseType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentResponseBoolean, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useState } from 'react';

import YesNoTile from './YesNoTile';
import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';
import { Comment } from '../CommentBox';

interface AssessmentPromptYesNoState {
    id: string | null;
    yesSelected: boolean | null;
    responseSaved: boolean;
}

const initialAssessmentPromptYesNoState: AssessmentPromptYesNoState = {
    id: null,
    yesSelected: null,
    responseSaved: false,
};

export interface ParticipantRatingResponse {
    yesSelected: boolean;
    votes: number;
    participantComments: Comment[];
}

export type ParticipantRatingResponseMap = Map<string, ParticipantRatingResponse>;

const totalVotesFromParticipantRatingResponseMap = (participantRatingResponseMap: ParticipantRatingResponseMap): number => {
    let totalVotes = 0;
    for (const rating of participantRatingResponseMap.keys()) {
        totalVotes += participantRatingResponseMap.get(rating).votes;
    }
    return totalVotes;
};

type AssessmentPromptYesNoProps = AppLabelsContextInterface &
    AuthContextInterface & {
        participantRatingResponseMap: ParticipantRatingResponseMap;
        shouldDisplayTemplate: boolean;
    };

const AssessmentPromptYesNo: FunctionComponent<AssessmentPromptYesNoProps> = ({
    auth,
    appLabels,
    participantRatingResponseMap,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const assessmentResponseType = currentPrompt?.responseType ?? null;
    const yesSelected = (currentPrompt?.response as AssessmentResponseBoolean)?.booleanValue ?? null;
    const totalVotes = totalVotesFromParticipantRatingResponseMap(participantRatingResponseMap);
    const iDontKnowResponse = participantRatingResponseMap.get('idk');

    // Template state
    const currentTemplatePrompt: TemplatePromptViewModel = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const templateResponseType: ResponseType = currentTemplatePrompt?.responseType;

    const isReadOnly = shouldDisplayTemplate ? true : assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse }));
                setState({ ...state, responseSaved: true });
            }
        },
    });

    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);

    const [state, setState] = useState<AssessmentPromptYesNoState>(initialAssessmentPromptYesNoState);

    const onToggle = useCallback(
        (yesSelected: boolean) => {
            return () => {
                if (!isReadOnly) {
                    let newYesSelected: boolean | null = yesSelected;
                    if (yesSelected === state.yesSelected) {
                        newYesSelected = null;
                    }
                    dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
                    createOrUpdateAssessmentResponses({
                        variables: {
                            input: {
                                assessmentId,
                                responses: [
                                    {
                                        promptId,
                                        booleanQuestion: newYesSelected,
                                    },
                                ],
                            },
                        },
                    });
                    setState({ ...state, yesSelected: newYesSelected });
                }
            };
        },
        [isReadOnly, state, dispatch, promptId, promptIndex, createOrUpdateAssessmentResponses, assessmentId]
    );

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_response}
                updateConfirmationText={
                    !loading && !error && state.responseSaved ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.response_saved}` : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                tryAgainAction={() => {
                    state.yesSelected && onToggle(state.yesSelected);
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_response,
        appLabels.assessment.facilitate.response_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        state.responseSaved,
        state.yesSelected,
        promptIndexBeingUpdated,
        onToggle,
    ]);

    if (assessmentIdPromptId && state.id !== assessmentIdPromptId) {
        // Prompt or assessment has changed, update state
        setState({
            ...initialAssessmentPromptYesNoState,
            yesSelected: yesSelected,
            id: assessmentIdPromptId,
        });
        return null;
    }

    const ratingTileGroup = [
        <YesNoTile
            isSelected={state.yesSelected === true}
            onToggle={onToggle(true)}
            groupName={promptId}
            yesNoLabel={appLabels.common.yes}
            voteCount={participantRatingResponseMap.get('yes')?.votes ?? null}
            totalVotes={totalVotes}
            participantComments={participantRatingResponseMap.get('yes')?.participantComments}
        />,
        <YesNoTile
            isSelected={state.yesSelected === false}
            onToggle={onToggle(false)}
            groupName={promptId}
            yesNoLabel={appLabels.common.no}
            voteCount={participantRatingResponseMap.get('no')?.votes ?? null}
            totalVotes={totalVotes}
            participantComments={participantRatingResponseMap.get('no')?.participantComments}
        />,
    ];
    if (iDontKnowResponse) {
        ratingTileGroup.push(
            <YesNoTile
                isSelected={false}
                groupName={promptId}
                yesNoLabel={appLabels.common.i_dont_know}
                voteCount={participantRatingResponseMap.get('idk')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('idk')?.participantComments}
            />
        );
    }

    if (shouldDisplayTemplate ? templateResponseType === ResponseType.YesNo : assessmentResponseType === AssessmentResponseTypes.YES_NO) {
        return (
            <div className='yes-no-tiles-wrapper' data-testid='yes-no-tiles-wrapper'>
                {ratingTileGroup}
                {updateStatusIndicator()}
            </div>
        );
    }

    return null;
};

export default withAuthContext(withAppLabelsContext(withFlashContext(AssessmentPromptYesNo)));
