import '@amzn/awsui-global-styles/polaris.css';
import './App.css';
import './Header.scss';

import { CookieConsentManager } from '@amzn/awscat-cookie-management';
import { AppLayout } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, BrowserRouter as Router } from 'react-router-dom';

import Routes from './Routes';
import CookieManager from './common/CookieManager';
import { isAmazonUser, isApnUser } from './common/Utils';
import authentication from './common/auth/Authentication';
import { useCookieConsentCheck } from './common/context/CookieConsentContext';
import rumClient, { RumUserType } from './common/monitoring/RumClient';
import Footer from './components/common/footer/Footer';
import AppHeader from './components/common/header/AppHeader';
import AppHelpPanel from './components/common/help-panel/AppHelpPanel';
import { closeAppHelpPanel, openAppHelpPanel } from './components/common/help-panel/AppHelpPanelSlice';
import AppSideNavigationPanel from './components/common/side-navigation/AppSideNavigationPanel';
import { useAppDispatch, useAppSelector } from './components/redux/hooks';
import FlashMessages from './flash/messages';

function App(): JSX.Element {
    const [navigationOpen, setNavigationOpen] = React.useState(true); // nav open by default
    const title = (
        <Link to='/'>
            <FormattedMessage id='landing_title' />
        </Link>
    );
    const dispatch = useAppDispatch();
    const appHelpPanelHide = useAppSelector((state) => state.appHelpPanelState.hide);
    const appHelpPanelOpen = useAppSelector((state) => state.appHelpPanelState.open);

    const [isAuthenticated, setAuthenticated] = React.useState(false);
    const cookieManager: CookieConsentManager = CookieManager.create();
    useCookieConsentCheck(cookieManager);

    useEffect(() => {
        authentication.setCallback((authenticated: boolean) => {
            setAuthenticated(authenticated);
        });
    }, []);

    useEffect(() => {
        // If not authenticated, don't set the RUM user type yet
        if (!isAuthenticated) {
            return;
        }

        // Track user type: AWS, APN, or Unknown. If we see any Unknown users, we should fix this logic
        let userType: RumUserType;
        const userId = authentication.getUserInfo().userId;
        if (isAmazonUser(userId)) {
            userType = RumUserType.AWS;
        } else if (isApnUser(userId)) {
            userType = RumUserType.APN;
        } else {
            userType = RumUserType.Unknown;
        }
        rumClient.addRumMetadata({ userType });
    }, [isAuthenticated]);

    const openHelpPanel = useCallback(() => {
        rumClient.recordClick('app-help-panel', 'open');
        dispatch(openAppHelpPanel());
    }, [dispatch]);

    const closeHelpPanel = useCallback(() => {
        rumClient.recordClick('app-help-panel', 'close');
        dispatch(closeAppHelpPanel());
    }, [dispatch]);

    return (
        <Router>
            <div className='awsui' id='b'>
                <AppHeader isAuthenticated={isAuthenticated} title={title} />
                <div id='app'>
                    <AppLayout
                        disableContentPaddings={true}
                        content={<Routes />}
                        footerSelector={'#b #page-footer'}
                        headerSelector={'#b #h'}
                        navigation={<AppSideNavigationPanel />}
                        navigationOpen={navigationOpen}
                        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
                        notifications={<FlashMessages />}
                        tools={<AppHelpPanel />}
                        onToolsChange={({ detail }) => {
                            detail.open ? openHelpPanel() : closeHelpPanel();
                        }}
                        toolsHide={appHelpPanelHide}
                        toolsOpen={appHelpPanelOpen}
                    />
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
