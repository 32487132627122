import './AssessmentPromptSelect.scss';

import { AssessmentDescriptor, ResponseType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentResponseMultiSelection, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Multiselect, SelectProps } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';

import AssessmentPromptEditableSelectGroup from './AssessmentPromptEditableSelectGroup';
import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import { CurrentTemplateState } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import { getEditableDescriptor } from '../../../../administration/manage-templates/edit-template/TemplateUtils';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';

interface AssessmentPromptMultiSelectState {
    id: string | null;
    responseSaved: boolean;
}

const initialAssessmentPromptMultiSelectState: AssessmentPromptMultiSelectState = {
    id: null,
    responseSaved: false,
};

type AssessmentPromptMultiSelectProps = AppLabelsContextInterface &
    AuthContextInterface & {
        shouldDisplayTemplate: boolean;
    };

const AssessmentPromptMultiSelect: FunctionComponent<AssessmentPromptMultiSelectProps> = ({
    auth,
    appLabels,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const responseType = currentPrompt?.responseType ?? null;
    const response = currentPrompt?.response ?? null;
    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);
    const [multiSelectState, setMultiSelectState] = useState<AssessmentPromptMultiSelectState>(initialAssessmentPromptMultiSelectState);
    const [selectedOptions, setSelectedOptions] = useState<ReadonlyArray<SelectProps.Option>>([]);

    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplatePrompt: TemplatePromptViewModel = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const templateResponseType: ResponseType = currentTemplatePrompt?.responseType;
    const currentlyEditingTemplate: boolean = useAppSelector((state) => state.currentTemplateState.currentlyEditing);

    const selectionDescriptionDescriptor: AssessmentDescriptor = useMemo(
        () => getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.selectionDescription?.[0]?.descriptorId),
        [currentTemplateState, currentTemplatePrompt]
    );
    const selectionDescription = shouldDisplayTemplate
        ? selectionDescriptionDescriptor?.description
        : currentPrompt?.responseSelections?.selectionDescription ?? null;

    const isReadOnly = shouldDisplayTemplate ? true : assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse }));
                setMultiSelectState({ ...multiSelectState, responseSaved: true });
            }
        },
    });

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_response}
                updateConfirmationText={
                    !loading && !error && multiSelectState.responseSaved
                        ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.response_saved}`
                        : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                // TODO: Add the logic for try again action: SIM: https://issues.amazon.com/issues/A2T-OE-49
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_response,
        appLabels.assessment.facilitate.response_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        multiSelectState.responseSaved,
        promptIndexBeingUpdated,
    ]);

    const initialResponseValues: string[] = (() => {
        switch (responseType) {
            case AssessmentResponseTypes.MULTI_SELECTION:
                if (response) {
                    return (response as AssessmentResponseMultiSelection)?.multiSelectValues || [];
                }
                return null;
            default:
                return null;
        }
    })();

    const updateValue = useCallback(() => {
        if (isReadOnly) {
            return;
        }
        dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
        if (selectedOptions.length !== initialResponseValues?.length) {
            createOrUpdateAssessmentResponses({
                variables: {
                    input: {
                        assessmentId: assessmentId,
                        responses: [
                            {
                                promptId,
                                multiSelectValues: selectedOptions.map((option) => option.value),
                            },
                        ],
                    },
                },
            });
        }
    }, [isReadOnly, dispatch, promptId, promptIndex, selectedOptions, initialResponseValues, createOrUpdateAssessmentResponses, assessmentId]);

    if (responseType === AssessmentResponseTypes.MULTI_SELECTION || templateResponseType === ResponseType.MultiSelection) {
        const selectionIdsForEditing: string[] = [];
        const selectionDescriptors: AssessmentDescriptor[] = [];
        const selectionItems = [];

        if (shouldDisplayTemplate) {
            const promptSelectionItems = currentTemplatePrompt?.selections ?? [];
            selectionIdsForEditing.push(...promptSelectionItems.map((selection) => selection.selectionId));
            selectionDescriptors.push(
                ...promptSelectionItems.map((selection) => getEditableDescriptor(currentTemplateState, selection.selectionLabel[0].descriptorId))
            );

            selectionItems.push(
                ...promptSelectionItems.map((selectionItem, index) => ({
                    label: selectionDescriptors[index].description,
                    value: selectionItem.selectionId,
                }))
            );
        } else {
            const selections = currentPrompt?.responseSelections?.selections ?? [];
            selectionItems.push(...selections.map((selection) => ({ label: selection.selectionLabel, value: selection.selectionId })));
            if (assessmentIdPromptId && multiSelectState.id !== assessmentIdPromptId) {
                // Prompt or assessment has changed, update state
                setMultiSelectState({
                    ...initialAssessmentPromptMultiSelectState,
                    id: assessmentIdPromptId,
                });
                const initialSelectedOptions = selectionItems.filter((item) => initialResponseValues?.includes(item.value));
                setSelectedOptions(initialSelectedOptions);
                return null;
            }
        }
        return (
            <div className='awscat-assessment-select-input'>
                {shouldDisplayTemplate && currentlyEditingTemplate ? (
                    <AssessmentPromptEditableSelectGroup
                        promptId={currentTemplatePrompt.promptId}
                        selectionDescriptionDescriptor={selectionDescriptionDescriptor}
                        selectionDescriptors={selectionDescriptors}
                        selectionIds={selectionIdsForEditing}
                    />
                ) : (
                    <Multiselect
                        placeholder={selectionDescription}
                        options={selectionItems}
                        onChange={({ detail }) => {
                            if (!isReadOnly) {
                                setSelectedOptions(detail.selectedOptions);
                            }
                        }}
                        selectedOptions={selectedOptions}
                        expandToViewport
                        selectedAriaLabel={appLabels.user_actions.selected}
                        onBlur={updateValue}
                        data-testid='multi-select-input'
                    />
                )}
                {updateStatusIndicator()}
            </div>
        );
    }
    return null;
};

export default withAuthContext(withAppLabelsContext(withFlashContext(AssessmentPromptMultiSelect)));
