import React from 'react';

import a2sClient from '../../../api/a2s/A2SClient';
import dataCollectionClient from '../../../api/data-collection/DataCollectionClient';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import rumClient from '../../../common/monitoring/RumClient';
import ActionConfirmationDialog from '../../common/ActionConfirmationDialog';
import { useAppDispatch } from '../../redux/hooks';
import { Action, ActionType } from '../AssessmentActions';
import { AssessmentViewModel } from '../AssessmentViewModel';
import { reloadAssessments } from '../list/AssessmentsSlice';

interface DeleteAssessmentDialogProps {
    assessment: AssessmentViewModel;
    assessmentActionDispatch: React.Dispatch<Action>;
}

const DeleteAssessmentDialog = (props: DeleteAssessmentDialogProps & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const dispatch = useAppDispatch();

    const deleteAssessmentAndQuestionnaire = async () => {
        try {
            if (props.assessment) {
                await a2sClient?.deleteAssessment(props.assessment.id);

                // delete the questionnaire if it exists
                const sourceResourceId = `awscat:a2s:${props.assessment.id}`;
                const questionnaires = await dataCollectionClient?.getQuestionnaires(sourceResourceId);
                const questionnaire = questionnaires?.length ? questionnaires[0] : undefined;
                if (questionnaire) {
                    await dataCollectionClient?.deleteQuestionnaires([questionnaire.id]);
                }

                // successfully delete assessment, reload tables
                dispatch(reloadAssessments());
                cancelAction();
            }
        } catch (e: any) {
            rumClient.recordError(e);
            throw new Error(`${e.errors[0]?.message || appLabels.assessment.delete.error_delete_assessment}`);
        }
    };

    const cancelAction = () => {
        props.assessmentActionDispatch({ type: ActionType.CANCEL });
    };

    return (
        <ActionConfirmationDialog
            header={`${appLabels.assessment.delete.delete} '${props.assessment.description}'?`}
            message={`${appLabels.assessment.delete.delete_this_assessment_permanently}`}
            frictionPrompt={appLabels.assessment.delete.confirm_prompt}
            frictionExpected={appLabels.assessment.delete.confirm_input_expected}
            actionLabel={appLabels.assessment.delete.delete}
            action={deleteAssessmentAndQuestionnaire}
            cancelAction={cancelAction}
            messageAlertType='error'
            errorAlertHeader={appLabels.assessment.delete.error_delete_assessment}
        />
    );
};

export default withAppLabelsContext(DeleteAssessmentDialog);
