import { AssessmentStatus } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { IsEnum, IsNotEmpty, Matches, MaxLength, ValidateNested } from 'class-validator';

export enum DeliveredByValue {
    MIGRATION_BDM = 'Migration BDM',
    PROSERVE = 'ProServe',
    TAM = 'TAM',
    ACCOUNT_SA = 'Account SA',
    MIGRATION_SA = 'Migration SA',
    WWSO_SPECIALIST = 'WWSO Specialist',
    CSM = 'CSM',
    AWS_PARTNER_ORGANIZATION = 'AWS Partner Organization',
    AWS_PARNTER_NETWORK = 'AWS Partner Network',
    MIGRATION_COMPENTENCY_PARTNER = 'Migration Competency Partner',
    OTHER = 'Other',
}

class DeliveredBy {
    label: string;

    @IsEnum(DeliveredByValue, {
        message: `Delivered by must be one of [${Object.keys(DeliveredByValue).map((k) => DeliveredByValue[k])}]`,
    })
    value: string;
}
export class Assessment {
    @IsNotEmpty({
        message: 'Account cannot be empty',
    })
    account: any;

    @IsNotEmpty({
        message: 'Description cannot be empty',
    })
    description: string;

    @IsNotEmpty({
        message: 'MAP Program engagement ID cannot be empty',
    })
    @Matches(/^[A-Z0-9]{10}$|^NA$/, {
        message: 'MAP Program engagement ID must be 10-character word consisting of uppercase letters and numbers or NA',
    })
    mapProgramEngagementId: string;

    @Matches(/(.|\s)*\S(.|\s)*/, {
        message: 'Customer Contact must contain at least one non whitespace character',
    })
    @MaxLength(128, {
        message: 'Customer Contact cannot be greater than 128 characters',
    })
    internalContact: string;

    @IsNotEmpty({
        message: 'Workshop date cannot be empty',
    })
    workshopDate: string;

    @IsNotEmpty({
        message: 'Readout date cannot be empty',
    })
    readoutDate: string;

    @IsNotEmpty({
        message: 'Delivered by cannot be empty',
    })
    @ValidateNested()
    deliveredBy: DeliveredBy;

    @IsNotEmpty({
        message: 'IsDemoTest by cannot be empty',
    })
    isDemoTest: boolean;

    @IsNotEmpty({
        message: 'Must select assessment type',
    })
    type: string;

    @IsNotEmpty({
        message: 'Assessment status can not be empty',
    })
    status: AssessmentStatus;
    optOutSolutionsRecommendation: boolean;
}
