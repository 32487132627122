import { PartnerReportCustomizationClient, UserDataInput } from '@amzn/awscat-a2t-partner-report-customization-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken, getProvider } from '../../common/auth/Authentication';

const envConfig = GetEnvironmentConfig();

// The data we store in PRC - this aligns with the placeholders in PowerPoint/Docx templates
export const companyNamePlaceholder = '[Company Name]';
export const companyLogoPlaceholder = '[IMAGE-Company Logo]';

// Exports that we need
export type { UserDataInput, ValueTypes } from '@amzn/awscat-a2t-partner-report-customization-client';
export { mimeTypeToValueType } from '@amzn/awscat-a2t-partner-report-customization-client';

export const acceptMimeTypes = ['image/png', 'image/jpeg'];

export const partnerReportCustomizationClient = envConfig.partnerReportCustomizationEnvironment
    ? new PartnerReportCustomizationClient('us-west-2', envConfig.partnerReportCustomizationEnvironment, getJwtToken, getProvider, undefined)
    : undefined;

/**
 * Get partners' data from PRC
 * @param params - the data that will be sent to RGS
 */
export const getPartnerData = async (params: { [key: string]: any }): Promise<{ [key: string]: any }> => {
    if (partnerReportCustomizationClient) {
        const userData: { [key: string]: UserDataInput } = await partnerReportCustomizationClient.getOrgData();
        Object.entries(userData).forEach((entry) => {
            const key = entry[0];
            const value = entry[1];
            params[key] = value.value;
        });
    }

    return params;
};
