export enum QuestionTypes {
    LIVE_EVENT = 'liveEvent',
    PRE_EVENT = 'preEvent',
}

export enum RecommendedActionPriority {
    HIGH = 'High',
    MED = 'Med',
    LOW = 'Low',
    NA = 'N/A',
}

export enum YesNoValue {
    YES = 'Y',
    NO = 'N',
}

// Makes a dictionary with int values for each recommended action priority. NA = 1, Low = 2, Med = 3...
// These are meant for comparison between action priorities (see which priority is higher)
export const RecommendedActionPriorityIntValues: { [key: string]: number } = Object.assign(
    {},
    ...Object.values(RecommendedActionPriority).map((value, index) => ({ [value]: index + 1 }))
);

export enum RecommendedActionEffort {
    NA = 'N/A',
    SHORT = 'Short',
    MEDIUM = 'Medium',
    LONG = 'Long',
}

export interface AssessmentTemplateTypeVersion {
    type: string;
    version?: string;
}
