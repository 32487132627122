import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';

export interface RequestStatus {
    loading: boolean;
    messageType: FlashbarProps.Type | null;
    messageHeader: string | null;
    messageContent: string | null;
}

export const defaultRequestStatus: RequestStatus = {
    loading: false,
    messageType: null,
    messageHeader: null,
    messageContent: null,
};

type RequestStatusFlashbarProps = AppLabelsContextInterface & {
    requestStatus: RequestStatus;
    setRequestStatus: any;
    dismissible?: boolean;
};

const RequestStatusFlashbar: FunctionComponent<RequestStatusFlashbarProps> = ({
    appLabels,
    requestStatus,
    setRequestStatus,
    dismissible,
}): JSX.Element | null => {
    if (requestStatus.messageType) {
        return (
            <Flashbar
                items={[
                    {
                        type: requestStatus.messageType,
                        header: requestStatus.messageHeader ?? '',
                        content: requestStatus.messageContent ?? '',
                        dismissible: dismissible || true,
                        dismissLabel: appLabels.user_actions.dismiss,
                        onDismiss: () => {
                            setRequestStatus(defaultRequestStatus);
                        },
                    },
                ]}
                data-testid='request-status-flashbar'
            />
        );
    }
    return null;
};

export default withAppLabelsContext(RequestStatusFlashbar);
