import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
    TextFilter,
} from '@amzn/awsui-components-react';
import { useState } from 'react';

import AssessmentCreatedBy from './AssessmentCreatedBy';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import { isAllCustomerSelected, isCustomerSelected } from '../../../common/Utils';
import EmptyTable from '../../common/EmptyTable';
import AwsCatIcon from '../../common/Icons/AwsCatIcon';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../../customers/CustomerFilterContext';
import { useAppSelector } from '../../redux/hooks';
import AssessmentActions, { Action } from '../AssessmentActions';
import AssessmentStatusBar from '../AssessmentStatusBar';
import { AssessmentViewModel } from '../AssessmentViewModel';

type AssessmentTableProps = {
    assessmentActionDispatch: React.Dispatch<Action>;
} & CustomerFilterContextInterface;

const AssessmentsTable = (props: AssessmentTableProps & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const { loading, completedAssessments } = useAppSelector((state) => state.assessmentsState);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_COMPLETED_ASSESSMENTS,
    });
    const [favoritesOnly, SetFavoritesOnly] = useState<boolean>(false);
    const columnDefs: TableProps.ColumnDefinition<AssessmentViewModel>[] = [
        {
            id: 'description',
            header: appLabels.assessment.description,
            cell: (e) => <Link href={`/assessments/${e.id}/reviewall`}>{e.description}</Link>,
        },
        {
            id: 'accountCustomerName',
            header: appLabels.assessment.customer_account_name,
            cell: (e) => e.accountCustomerName,
        },
        {
            id: 'type',
            header: appLabels.assessment.type,
            cell: (e) => e.type,
        },
        {
            id: 'status',
            header: appLabels.assessment.status,
            cell: (e) => <AssessmentStatusBar status={e.status}></AssessmentStatusBar>,
        },
        {
            id: 'createdBy',
            header: appLabels.assessment.created_by,
            cell: (e) => <AssessmentCreatedBy createdBy={e.createdBy} />,
        },
        {
            id: 'lastUpdated',
            header: appLabels.assessment.last_updated,
            cell: (e) => e.lastUpdated || '',
        },
        {
            id: 'actions',
            header: appLabels.assessment.list.actions,
            cell: (e) => <AssessmentActions assessment={e} assessmentActionDispatch={props.assessmentActionDispatch}></AssessmentActions>,
        },
    ];

    const filterItems = (item: AssessmentViewModel, filteringText: string): boolean => {
        const rowItems = [
            item.accountCustomerName,
            item.description,
            item.createdBy,
            item.id,
            item.type,
            item.status,
            item.customerAccountID,
            item.workshopDate,
            item.readoutDate,
        ];
        if (item.lastUpdated) {
            rowItems.push(item.lastUpdated.toString());
        }
        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItems.some((rowItem) => {
            let match = rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
            if (match && !isAllCustomerSelected(props.customer)) {
                // try customer filter match
                match = isCustomerSelected(props.customer, item.accountCustomerName);
            }
            if (match && favoritesOnly) {
                match = item.isfavorite;
            }
            return match;
        });
    };

    const { items, collectionProps, filterProps, paginationProps } = useCollection(completedAssessments, {
        filtering: {
            empty: (
                <EmptyTable
                    title={appLabels.assessment.list.assessments_table_no_assessments}
                    subtitle={appLabels.assessment.list.assessments_table_no_assessments_to_display}
                />
            ),
            noMatch: (
                <EmptyTable
                    title={appLabels.assessment.list.assessments_table_no_assessments}
                    subtitle={appLabels.assessment.list.assessments_table_no_assessments_to_display}
                />
            ),
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });

    const tableOptionsSetting = () => [
        {
            label: '10',
            value: 10,
        },
        {
            label: '25',
            value: 25,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value: 100,
        },
        {
            label: '200',
            value: 200,
        },
        {
            label: '500',
            value: 500,
        },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.assessment.list.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.assessment.list.preference_title}
        />
    );

    const tableHeader = (
        <Header counter={`(${items.length}/${completedAssessments.length})`}>{appLabels.assessment.list.completed_assessments}</Header>
    );

    return (
        <Table
            {...collectionProps}
            trackBy='id'
            header={tableHeader}
            columnDefinitions={columnDefs}
            items={items}
            loadingText={appLabels.assessment.list.loading_assessments}
            loading={loading}
            filter={
                <SpaceBetween direction='horizontal' size='m'>
                    <TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.assessment.list.filter_placeholder} />
                    <div onClick={() => SetFavoritesOnly(!favoritesOnly)} className='icon-button secondary'>
                        {favoritesOnly ? (
                            <AwsCatIcon name='favorite on' width='20' height='20' fill='#D13212' />
                        ) : (
                            <AwsCatIcon name='favorite' width='20' height='20' fill='#000' />
                        )}
                    </div>
                </SpaceBetween>
            }
            pagination={<Pagination {...paginationProps} />}
            preferences={tablePreferences}
        />
    );
};

export default withAppLabelsContext(withCustomerFilterContext(AssessmentsTable));
