import { AssessmentResponseTypes, AssessmentSection } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { AssessmentViewModel } from '../AssessmentViewModel';
import { QuestionResults, doesSectionHaveResponseType, isSectionShown } from '../Utils';

/**
 * Get rid of sections excluded from reports. For a section to be retained, it must:
 * 1. Be shown to the user
 * 2. Have a response type in the `keepSectionsWithResponseTypes` array
 * 3. Not be explicitly excluded in `sectionExcludes`
 * @param sections
 * @param sectionExcludes - Explicitly exclude certain sections using their IDs
 * @param assessment
 * @param keepSectionsWithResponseTypes - sections without these response types will be excluded. ['RATING'] by default.
 *  Pass `null` to exclude no sections based on response type
 * @returns All sections that should be included in the report
 */
export const filterExcludedSections = (
    sections: (AssessmentSection | null)[] | null | undefined,
    sectionExcludes: (string | null)[] | null | undefined,
    assessment: AssessmentViewModel,
    keepSectionsWithResponseTypes: AssessmentResponseTypes[] | null = [AssessmentResponseTypes.RATING]
): (AssessmentSection | null)[] | null | undefined => {
    return sections?.filter((section) => {
        if (section) {
            const removeLastChar = (s: string) => {
                return s.slice(0, -1);
            };
            /**
             * Check if exclude matches via wildcard or directly
             */
            const excludeMatchesSection = (exclude: string | null): boolean => {
                if (exclude && section?.id) {
                    if (exclude.endsWith('*')) {
                        return section.id.startsWith(removeLastChar(exclude));
                    } else {
                        return section.id === exclude;
                    }
                } else {
                    return false;
                }
            };

            const isShownSection: boolean = isSectionShown(section, assessment);
            const hasIncludedResponseType: boolean =
                keepSectionsWithResponseTypes === null ||
                keepSectionsWithResponseTypes.some((responseType) => doesSectionHaveResponseType(section, responseType));
            const isExcludedSection: boolean | undefined = sectionExcludes?.some(excludeMatchesSection);

            // Keep the section if it is shown to the user, has an included response type, AND is not excluded
            return isShownSection && hasIncludedResponseType && !isExcludedSection;
        } else {
            return false;
        }
    });
};

/**
 * Precede comments with rating guides in the downloaded reports
 * @param question
 * @returns
 */
export function getCommentsWithRatingGuide(question: QuestionResults): string {
    if (!question) {
        return '';
    }
    return [question.ratingGuide || '', question.comments || ''].filter((str) => !!str).join('\n\n');
}
