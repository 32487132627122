import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    SpaceBetween,
    TextFilter,
} from '@amzn/awsui-components-react';
import Cards, { CardsProps } from '@amzn/awsui-components-react/polaris/cards';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AssessmentCardBody from './AssessmentCardBody';
import AssessmentCardHeader from './AssessmentCardHeader';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import { isAllCustomerSelected, isCustomerSelected } from '../../../common/Utils';
import EmptyTable from '../../common/EmptyTable';
import AwsCatIcon from '../../common/Icons/AwsCatIcon';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../../customers/CustomerFilterContext';
import { useAppSelector } from '../../redux/hooks';
import { Action } from '../AssessmentActions';
import { AssessmentViewModel } from '../AssessmentViewModel';

type AssessmentsCardProps = {
    assessmentActionDispatch: React.Dispatch<Action>;
} & CustomerFilterContextInterface;

const AssessmentsCards = (props: AssessmentsCardProps & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const history = useHistory();
    const { loading, inProgressAssessments } = useAppSelector((state) => state.assessmentsState);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_IN_PROGRESS_ASSESSMENTS,
    });
    const [favoritesOnly, SetFavoritesOnly] = useState<boolean>(false);

    const sectionDefs: CardsProps.SectionDefinition<AssessmentViewModel>[] = [
        {
            id: 'id',
            header: '',
            content: (e) => <AssessmentCardBody assessment={e} assessmentActionDispatch={props.assessmentActionDispatch}></AssessmentCardBody>,
        },
    ];

    const filterItems = (item: AssessmentViewModel, filteringText: string): boolean => {
        const rowItems = [item.accountCustomerName, item.description, item.id, item.type, item.createdBy];
        if (item.lastUpdated) {
            rowItems.push(item.lastUpdated.toString());
        }
        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItems.some((rowItem) => {
            let match = rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
            if (match && !isAllCustomerSelected(props.customer)) {
                // try customer filter match
                match = isCustomerSelected(props.customer, item.accountCustomerName);
            }
            if (match && favoritesOnly) {
                match = item.isfavorite;
            }
            return match;
        });
    };

    const { items, collectionProps, filterProps, paginationProps } = useCollection(inProgressAssessments, {
        filtering: {
            empty: (
                <EmptyTable
                    title={appLabels.assessment.list.assessments_table_no_assessments}
                    subtitle={appLabels.assessment.list.assessments_table_no_assessments_to_display}
                />
            ),
            noMatch: (
                <EmptyTable
                    title={appLabels.assessment.list.assessments_table_no_assessments}
                    subtitle={appLabels.assessment.list.assessments_table_no_assessments_to_display}
                />
            ),
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });

    const tableOptionsSetting = () => [
        {
            label: `10 ${appLabels.assessment.list.assessments}`,
            value: 10,
        },
        {
            label: `25 ${appLabels.assessment.list.assessments}`,
            value: 25,
        },
        {
            label: `50 ${appLabels.assessment.list.assessments}`,
            value: 50,
        },
        {
            label: `100 ${appLabels.assessment.list.assessments}`,
            value: 100,
        },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.assessment.list.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.assessment.list.preference_title}
        />
    );

    const tableHeader = (
        <Header
            counter={`(${items.length}/${inProgressAssessments.length})`}
            actions={
                <Button
                    variant='primary'
                    onClick={(event) => {
                        event.preventDefault();
                        history.push('/assessments/create');
                    }}
                >
                    {appLabels.assessment.list.create_assessments}
                </Button>
            }
        >
            {appLabels.assessment.list.active_and_favorite_assessments}
        </Header>
    );

    return (
        <Cards
            {...collectionProps}
            trackBy='id'
            cardDefinition={{
                header: (e) => <AssessmentCardHeader assessment={e}></AssessmentCardHeader>,
                sections: sectionDefs,
            }}
            cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 5 }]}
            items={items}
            loadingText={appLabels.assessment.list.loading_assessments}
            loading={loading}
            filter={
                <SpaceBetween direction='horizontal' size='m'>
                    <TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.assessment.list.filter_placeholder} />
                    <div onClick={() => SetFavoritesOnly(!favoritesOnly)} className='icon-button secondary'>
                        {favoritesOnly ? (
                            <AwsCatIcon name='favorite on' width='20' height='20' fill='#D13212' />
                        ) : (
                            <AwsCatIcon name='favorite' width='20' height='20' fill='#000' />
                        )}
                    </div>
                </SpaceBetween>
            }
            header={tableHeader}
            pagination={<Pagination {...paginationProps} />}
            preferences={tablePreferences}
        />
    );
};

export default withAppLabelsContext(withCustomerFilterContext(AssessmentsCards));
