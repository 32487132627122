import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Box, Button, FormField, Input, Link, Modal, Select, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { MaxLength, ValidationError, validate } from 'class-validator';
import { FunctionComponent, useCallback, useState } from 'react';

import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from './RequestStatusFlashbar';
import feedbackServiceClient, { FeedbackType, ReportFeedbackData, a2tSimUUIDMapping } from '../../api/feedback/FeedbackServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import ExternalLinks from '../../common/ExternalLinks';
import { logger } from '../../common/Logger';
import { extractAmazonAliasFromUserId, extractAmazonEmailFromUserId, getAppId, isAmazonUser } from '../../common/Utils';
import { errorLookup } from '../../common/ValidatorUtils';
import rumClient from '../../common/monitoring/RumClient';
import { getDescriptorForLocale } from '../administration/manage-templates/edit-template/TemplateUtils';
import { getAssessmentTemplates } from '../assessments/Utils';
import { LocalizationContextInterface, withLocalizationContext } from '../localization/LocalizationContext';
import { useAppSelector } from '../redux/hooks';

type CreateBugDialogProps = AppLabelsContextInterface &
    AuthContextInterface &
    FlashContextInterface &
    LocalizationContextInterface & {
        showCreateBugModal: boolean;
        setShowCreateBugModal: (boolean) => void;
    };

export enum IssueTypeEnum {
    GENERAL = 'General',
    ASSESSMENTRELATED = 'Assessment related',
}

export class CreateBugType {
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_200, {
        message: 'common_max_char_200',
    })
    title: string;
    issueType: OptionDefinition;
    assessmentTypesOptions: OptionDefinition[];
    assessmentType: OptionDefinition;
    assessmentTypeRequired: boolean;
    assessmentDescription: string;
    assessmentDescriptionRequired: boolean;
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_2000, {
        message: 'common_max_char_2000',
    })
    description: string;
    descriptionRequired: boolean;
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_2000, {
        message: 'common_max_char_2000',
    })
    expectedBehavior: string;
    expectedBehaviorRequired: boolean;
    enableSubmitButton: boolean;
}

const bugInitState: CreateBugType = {
    title: '',
    issueType: null,
    assessmentTypesOptions: [],
    assessmentType: null,
    assessmentTypeRequired: false,
    assessmentDescription: null,
    assessmentDescriptionRequired: false,
    description: '',
    descriptionRequired: false,
    expectedBehavior: '',
    expectedBehaviorRequired: false,
    enableSubmitButton: true,
};

const CreateBugDialog: FunctionComponent<CreateBugDialogProps> = ({
    appLabels,
    auth,
    showCreateBugModal,
    setShowCreateBugModal,
    flash,
    locale,
}): JSX.Element | null => {
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState?.currentAssessmentOrSelectedSnapshot);
    const [state, setState] = useState<CreateBugType>(bugInitState);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const getValidationErrorText = errorLookup<CreateBugType>(validationErrors);
    const getErrorText = (attribute: keyof CreateBugType): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };
    let assessmentTypes: OptionDefinition[] = null;

    const onIssueTypeChange = async (issueType: OptionDefinition) => {
        if (issueType.value === IssueTypeEnum.GENERAL) {
            onChange({
                issueType: issueType,
                assessmentTypeRequired: false,
                assessmentDescriptionRequired: false,
                descriptionRequired: true,
                expectedBehaviorRequired: true,
            });
        } else {
            setRequestStatus({
                loading: true,
                messageType: FlashType.info,
                messageHeader: appLabels.feedback.loading_assessment_types,
                messageContent: null,
            });
            const { errors, templates } = await getAssessmentTemplates();
            if (errors.length > 0) {
                setRequestStatus({
                    loading: false,
                    messageType: templates.length === 0 ? FlashType.error : FlashType.warning,
                    messageHeader: appLabels.assessment.create.error_retrieving_all_templates,
                    messageContent: `${errors.length} ${appLabels.assessment.create.errors_retrieving_templates}`,
                });
            } else {
                assessmentTypes = templates.map((t) => {
                    const templateName = getDescriptorForLocale(t.descriptors, locale).name;
                    return { label: templateName, value: t.type };
                });
                onChange({
                    issueType: issueType,
                    assessmentTypesOptions: assessmentTypes,
                    assessmentTypeRequired: true,
                    assessmentType: assessmentTypes.filter((t) => t.value === currentAssessment?.type)?.[0],
                    assessmentDescriptionRequired: true,
                    assessmentDescription: currentAssessment?.description,
                    descriptionRequired: true,
                    expectedBehaviorRequired: true,
                });
                setRequestStatus(defaultRequestStatus);
            }
        }
    };

    const onChange = useCallback(
        async (newSubState: Partial<CreateBugType>) => {
            setState({ ...state, ...newSubState });
        },
        [state]
    );

    const clearForm = useCallback(() => {
        setState(bugInitState);
        setRequestStatus(defaultRequestStatus);
        setShowCreateBugModal(false);
    }, [setShowCreateBugModal]);

    const onCreateBug = useCallback(async () => {
        setState({ ...state, enableSubmitButton: false });
        const createBugState: CreateBugType = Object.assign(new CreateBugType(), state);
        const validationErrors = await validate(createBugState);
        setValidationErrors(validationErrors);
        if (!(validationErrors && validationErrors.length)) {
            setRequestStatus({
                loading: true,
                messageType: FlashType.info,
                messageHeader: appLabels.feedback.submit_request,
                messageContent: null,
            });
            const labels: string[] = [];
            labels.push(a2tSimUUIDMapping.A2T); // always push A2T in the labels

            if (a2tSimUUIDMapping[FeedbackType.BUG]) {
                labels.push(a2tSimUUIDMapping[FeedbackType.BUG]);
            }

            if (a2tSimUUIDMapping[state.assessmentType?.value]) {
                labels.push(a2tSimUUIDMapping[state.assessmentType.value]);
            }

            let userEmail = null;
            let userId = auth.getUserInfo().userId;
            let apiStatus = false;

            if (isAmazonUser(userId)) {
                userId = extractAmazonAliasFromUserId(userId);
                userEmail = extractAmazonEmailFromUserId(userId);
            }

            const title =
                state.issueType?.value === IssueTypeEnum.GENERAL
                    ? `A2T Bug (General) - ${state.title}`
                    : `A2T Bug (${state.assessmentType?.value ?? 'Unknown type'}) - ${state.title}`;

            let description = `Description:\n${state.description}\n\n` + `Expected Behavior:\n${state.expectedBehavior}\n\n`;
            if (state.assessmentDescription) {
                description += `Assessment Description: ${state.assessmentDescription}\n\n`;
            }
            description += `Submitter User ID: ${userId}`; // Include case sensitive userId for easy lookup of partner users

            const reportFeedbackData: ReportFeedbackData = {
                title,
                description,
                type: FeedbackType.BUG,
                appId: getAppId(),
                userEmail: userEmail,
                requester: userId,
                labelIds: labels,
            };

            try {
                const result = await feedbackServiceClient.reportFeedback(reportFeedbackData);
                if (result?.status === 200) {
                    apiStatus = true;
                }
            } catch (error: any) {
                rumClient.recordError(error);
                logger.error(error);
            }

            if (apiStatus) {
                flash.alert(FlashType.success, appLabels.feedback.submit_bug_success, '');
            } else {
                flash.alert(FlashType.error, appLabels.feedback.submit_bug_fail, '');
            }

            clearForm();
        }
    }, [appLabels.feedback.submit_bug_fail, appLabels.feedback.submit_bug_success, appLabels.feedback.submit_request, auth, clearForm, flash, state]);

    const onCancel = useCallback(() => {
        clearForm();
    }, [clearForm]);

    return (
        <Modal
            visible={showCreateBugModal}
            closeAriaLabel={appLabels.user_actions.close_dialog}
            header={appLabels.feedback.submit_bug_dialog_header}
            onDismiss={onCancel}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onCancel}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={onCreateBug}
                            disabled={!(state?.issueType && state.description?.length && state.enableSubmitButton)}
                        >
                            {appLabels.feedback.submit_bug_dialog_submit_button}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <SpaceBetween size='l'>
                <FormField
                    label={`${appLabels.feedback.submit_bug_dialog_title} (${state.title.length}/${Constants.TEXT_MAX_CHAR_LIMIT_200} chars)`}
                    errorText={getErrorText('title')}
                >
                    <Input
                        value={state.title}
                        placeholder={appLabels.feedback.dialog_title_placeholder}
                        onChange={({ detail: { value } }) => onChange({ title: value })}
                    />
                </FormField>
                <FormField label={appLabels.feedback.submit_bug_dialog_issue_type}>
                    <Select
                        onChange={(event) => onIssueTypeChange(event.detail.selectedOption)}
                        placeholder={appLabels.feedback.dialog_bug_issue_type_placeholder}
                        selectedOption={state.issueType}
                        options={[
                            { label: IssueTypeEnum.ASSESSMENTRELATED, value: IssueTypeEnum.ASSESSMENTRELATED },
                            { label: IssueTypeEnum.GENERAL, value: IssueTypeEnum.GENERAL },
                        ]}
                    />
                </FormField>
                {state.assessmentTypeRequired && (
                    <FormField label={appLabels.feedback.submit_bug_dialog_assessment_type}>
                        <Select
                            placeholder={appLabels.feedback.dialog_assessment_type_placeholder}
                            selectedOption={state.assessmentType}
                            options={state.assessmentTypesOptions}
                            onChange={(event) => onChange({ assessmentType: event.detail.selectedOption })}
                        />
                    </FormField>
                )}
                {state.assessmentDescriptionRequired && (
                    <FormField label={appLabels.feedback.submit_bug_dialog_assessment_name}>
                        <Input
                            value={state.assessmentDescription}
                            placeholder={appLabels.feedback.dialog_assessment_description_placeholder}
                            onChange={({ detail: { value } }) => onChange({ assessmentDescription: value })}
                        />
                    </FormField>
                )}
                {state.descriptionRequired && (
                    <FormField
                        label={
                            `${appLabels.feedback.submit_bug_dialog_description_of_issue} ` +
                            `(${state.description.length}/${Constants.TEXT_MAX_CHAR_LIMIT_2000} chars)`
                        }
                        errorText={getErrorText('description')}
                    >
                        <Textarea
                            value={state.description}
                            placeholder={appLabels.feedback.dialog_request_description_placeholder}
                            onChange={({ detail: { value } }) => onChange({ description: value })}
                        />
                    </FormField>
                )}
                {state.expectedBehaviorRequired && (
                    <FormField
                        label={
                            `${appLabels.feedback.submit_bug_dialog_expected_behavior} ` +
                            `(${state.expectedBehavior.length}/${Constants.TEXT_MAX_CHAR_LIMIT_2000} chars)`
                        }
                        errorText={getErrorText('expectedBehavior')}
                    >
                        <Textarea
                            value={state.expectedBehavior}
                            placeholder={appLabels.feedback.dialog_expected_behavior_placeholder}
                            onChange={({ detail: { value } }) => onChange({ expectedBehavior: value })}
                        />
                    </FormField>
                )}
                <Box>
                    {appLabels.feedback.submit_bug_dialog_troubleshooting_hint}
                    <Link external href={ExternalLinks.APG_MRA_TROUBLESHOOTING}>
                        {appLabels.feedback.submit_bug_dialog_troubleshooting_label}
                    </Link>
                </Box>
            </SpaceBetween>
        </Modal>
    );
};

export default withLocalizationContext(withFlashContext(withAuthContext(withAppLabelsContext(CreateBugDialog))));
