import { CustomerCloudMaturity } from '@amzn/awscat-aws-assessment-service-typescript-client';

export enum CustomerPlanAndAlignmentExistsValue {
    YES = 'YES',
    NO = 'NO',
}

export enum CustomerSegmentId {
    ENT = 'ENT',
    DNB = 'DNB',
    ISV = 'ISV',
    SMB = 'SMB',
    SUP = 'SUP',
    ADV = 'ADV',
}

export interface CustomerProfile {
    targetSegmentId: CustomerSegmentId;
    customerCloudMaturity: CustomerCloudMaturity;
    customerPlanAndAlignmentExists: boolean;
}
