import './PrescribedActions.scss';

import { Box, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import { FC, useState } from 'react';

import PrescribedActionsList, { PrescribedActionsListProps } from './PrescribedActionsList';
import BadgedText from '../../common/BadgedText';

export type PrescribedActionsSectionProps = {
    sectionName: string;
    prescribedActionsLists: PrescribedActionsListProps[];
};

export const PrescribedActionsSection: FC<PrescribedActionsSectionProps> = ({ sectionName, prescribedActionsLists }): JSX.Element => {
    const [sectionIsCustomized, setSectionIsCustomized] = useState<boolean>(false);

    return (
        <ExpandableSection
            defaultExpanded={false}
            variant='container'
            header={
                <Box fontSize='heading-m' fontWeight='bold' className='prescribed-actions-section-name'>
                    <BadgedText disabled={!sectionIsCustomized} badgeText={'Customized'}>
                        {sectionName}
                    </BadgedText>
                </Box>
            }
            className='prescribed-actions-section'
        >
            <SpaceBetween size='m' direction='vertical'>
                {prescribedActionsLists.map((props) => (
                    <PrescribedActionsList key={props.actionId} {...props} setSectionIsCustomized={setSectionIsCustomized} />
                ))}
            </SpaceBetween>
        </ExpandableSection>
    );
};

export default PrescribedActionsSection;
