import { UserInfo } from '@amzn/awscat-portal-authentication-library';
import { Checkbox, Table, TableProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useState } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import ActionConfirmationDialog from '../../common/ActionConfirmationDialog';

interface SelectUserInfo extends UserInfo {
    selected?: boolean;
}

type ShareMultiUserSelectDialogProps = AppLabelsContextInterface & {
    usersToSelectFrom: UserInfo[];
    assessmentDescription: string;
    addSelectedUsersAction: (selectedUsers: UserInfo[]) => Promise<any>;
    cancelAction: () => void;
};

const ShareMultiUserSelectDialog: FunctionComponent<ShareMultiUserSelectDialogProps> = ({
    appLabels,
    assessmentDescription,
    usersToSelectFrom,
    addSelectedUsersAction,
    cancelAction,
}): JSX.Element => {
    const [usersToSelect, setUsersToSelect] = useState<SelectUserInfo[]>(usersToSelectFrom);

    const multipleUserColDefs: TableProps.ColumnDefinition<SelectUserInfo>[] = [
        {
            header: '',
            id: 'selected',
            cell: (e) => {
                return (
                    <Checkbox
                        onChange={(event) => {
                            const updatedUsersToSelect = [...usersToSelect];
                            updatedUsersToSelect[updatedUsersToSelect.indexOf(e)].selected = event.detail.checked;
                            setUsersToSelect(updatedUsersToSelect);
                        }}
                        checked={e.selected || false}
                    ></Checkbox>
                );
            },
        },
        {
            header: appLabels.assessment.share.name,
            id: 'name',
            cell: (e) => e.displayName || e.email || e.userId,
        },
        {
            header: appLabels.common.organization_name,
            id: 'organization',
            cell: (e) => e.organizationName || e.organizationId,
        },
    ];

    useEffect(() => {
        if (usersToSelectFrom.length !== usersToSelect.length) {
            setUsersToSelect(usersToSelectFrom);
        }
    }, [usersToSelectFrom, usersToSelect, setUsersToSelect]);

    if (!usersToSelect?.length) {
        return null;
    }

    return (
        <ActionConfirmationDialog
            header={appLabels.assessment.share.multiple_users_found}
            message={`${appLabels.assessment.share.select_users} '${assessmentDescription}'?`}
            actionLabel={appLabels.assessment.share.action_add}
            action={() => addSelectedUsersAction(usersToSelect.filter((u) => u.selected))}
            cancelAction={cancelAction}
        >
            <Table columnDefinitions={multipleUserColDefs} items={usersToSelect}></Table>
        </ActionConfirmationDialog>
    );
};

export default withAppLabelsContext(ShareMultiUserSelectDialog);
