import { AssessmentTemplateDefaultsAnswers, RatingBand } from '@amzn/awscat-aws-assessment-service-typescript-client';
import fontColorContrast from 'font-color-contrast';

import { getDefaultIncompleteRatingStratum, getDefaultNARatingStratum, getDefaultRatingConfigurations } from './RatingStratumUtils';
import { DefaultStratumColor, ScoreStratum, getDefaultIncompleteScoreStratum, getDefaultRatingBands, getScoreStratum } from './ScoreStratumUtils';
import { ScoredItem } from './ScoreUtils';
import { AppLabels } from '../AppLabels';
import Constants from '../Constants';

/**
 * This class contructs a color scale that is used to infer color code based on any scoring value,
 * including score of workstream, score of category, score of a question, etc.
 */
export class ScoreScale {
    readonly maxScore: number;
    readonly bands: ScoreStratum[];

    constructor(defaultAnswers: AssessmentTemplateDefaultsAnswers, appLabels: AppLabels, useRatingStratum = false) {
        if (useRatingStratum && defaultAnswers?.useQuestionRatingStratum) {
            /**
             * Contruct a rating stratum that is used to infer color strictly based on a question's rating score
             * rather than a question vector. Currently this ratingScale is only used in the CMA assessment type,
             * where each rating value, including both numeric values and ratingNA, can have custom color configurations.
             *
             * For most assessment types, it should be sufficient to use scoreScale for both questions and questionVectors.
             * Both ScoreScale and RatingScale need to be configured for an assessment type that requires
             * different scales for workstreams/categories and for questions.
             */
            const ratingConfigurations: RatingBand[] = getDefaultRatingConfigurations(appLabels, defaultAnswers);
            const ratingStratums: ScoreStratum[] = [
                // Incomplete
                getDefaultIncompleteRatingStratum(appLabels),
                // NA
                getDefaultNARatingStratum(appLabels),
                // Rating 1-5
                ...ratingConfigurations.map(getScoreStratum),
            ];

            // override default ratingConfigurations with template rating configurations
            if (defaultAnswers?.ratingIncompleteColor) {
                ratingStratums[0].backgroundColor = defaultAnswers.ratingIncompleteColor;
            }
            if (defaultAnswers?.ratingNAColor) {
                ratingStratums[1].backgroundColor = defaultAnswers.ratingNAColor;
            }
            if (defaultAnswers?.rating1Color) {
                ratingStratums[2].backgroundColor = defaultAnswers.rating1Color;
            }
            if (defaultAnswers?.rating2Color) {
                ratingStratums[3].backgroundColor = defaultAnswers.rating2Color;
            }
            if (defaultAnswers?.rating3Color) {
                ratingStratums[4].backgroundColor = defaultAnswers.rating3Color;
            }
            if (defaultAnswers?.rating4Color) {
                ratingStratums[5].backgroundColor = defaultAnswers.rating4Color;
            }
            if (defaultAnswers?.rating5Color) {
                ratingStratums[6].backgroundColor = defaultAnswers.rating5Color;
            }
            if (defaultAnswers?.ratingNAEnabled === false) {
                // remove the NA stratum at index 1
                ratingStratums.splice(1, 1);
            }
            ratingStratums.forEach((band) => {
                band.fontColor = fontColorContrast(band.backgroundColor, 0.3); // adjust default threshold in favor of black fonts
            });
            this.bands = ratingStratums;
        } else {
            /**
             * Contruct a generic score stratum that is used to infer color based on any scoring value,
             * including score of a questionVector or a question.
             */
            this.maxScore = Constants.ASSESSMENT_DEFAULT_MAX_SCORE;
            let ratingBands = getDefaultRatingBands(appLabels);

            // override default ratingBands with template ratingBands
            if (defaultAnswers?.ratingBands?.length) {
                ratingBands = defaultAnswers.ratingBands;
            }
            // Need to make a copy of defaultAnswers?.ratingBands since it's a state in redux and cannot be modified directly
            const ratingBandsCopy = [...ratingBands];
            ratingBandsCopy.sort((band1, band2) => band1.lowerBound - band2.lowerBound);

            // override default incompleteColor with template incompleteColor
            const defaultIncompleteStratum = getDefaultIncompleteScoreStratum(appLabels);
            defaultIncompleteStratum.backgroundColor = defaultAnswers?.ratingIncompleteColor || DefaultStratumColor.INCOMPLETE;
            const scoreStratums = [defaultIncompleteStratum, ...ratingBandsCopy.map((band) => getScoreStratum(band))];
            scoreStratums.forEach((band) => {
                band.fontColor = fontColorContrast(band.backgroundColor, 0.8); // adjust default threshold in favor of white fonts
            });
            this.bands = scoreStratums;
        }
    }

    getStratificationTitle = (scored: ScoredItem | null | undefined): string | null | undefined => {
        return this.bands.find((b) => b.isScoreInStratum(scored))?.title || null;
    };

    getStratificationBackgroundColor = (scored: ScoredItem | null | undefined): string | null | undefined => {
        return this.bands.find((b) => b.isScoreInStratum(scored))?.backgroundColor || '#ffffff'; // set default background color to white
    };

    getStratificationFontColor = (scored: ScoredItem | null | undefined): string | null | undefined => {
        return this.bands.find((b) => b.isScoreInStratum(scored))?.fontColor || '#000000'; // set default font color to black
    };

    getAllStrataTitles = (): string[] => {
        return this.bands.map((band) => band.title);
    };
}
