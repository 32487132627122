import { getTemplateManagementEnvironment } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { GetEnvironmentConfig } from '../../Environments';
import { Environment } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

// Mostly copied from: https://code.amazon.com/packages/AwsAssessmentTemplateManagementServiceTests/blobs/aca546fdcabf9ea52ed78301487b0d47125f33d6/--/lib/client.ts#L1-L69
// Only differences are the getJwtToken function, the import from @apollo/client instead of @apollo/client/core, and replacing a class with a function

let cachedJwtToken: string = undefined;

/**
 * Create a new Apollo client to interact with Template Management
 * @param region - the region of the template management endpoint
 * @param environment - the stage of the template management endpoint
 * @param getJwtToken - an async function that returns a valid JWT from the cat-users pool, with which the client can send authenticated requests
 */
function getApolloClient(region: string, environment: Environment, getJwtToken: () => Promise<string>): ApolloClient<NormalizedCacheObject> {
    const templateManagementEnvironment = getTemplateManagementEnvironment(environment, region);

    // Include the token in the request
    // https://www.apollographql.com/docs/react/api/link/introduction
    // https://www.apollographql.com/docs/react/api/link/apollo-link-context/
    const getToken: ApolloLink = setContext(async () => {
        // If token isn't cached, get it
        if (!cachedJwtToken) {
            cachedJwtToken = await getJwtToken();
        }
    });

    const setAuthHeader: ApolloLink = setContext(() => ({
        headers: {
            authorization: cachedJwtToken,
        },
    }));

    // An http link makes the request to the GraphQL server (the template management backend)
    const httpLink: ApolloLink = createHttpLink({
        uri: templateManagementEnvironment.endpoint,
    });

    return new ApolloClient({
        link: ApolloLink.from([getToken, setAuthHeader, httpLink]),
        cache: new InMemoryCache(),
    });
}

const templateManagementClient = (() => {
    const { region, templateManagementEnvironment } = GetEnvironmentConfig();

    return templateManagementEnvironment ? getApolloClient(region, templateManagementEnvironment, getJwtToken) : null;
})();

/**
 * A key of a descriptor that can be edited
 */
export enum EditableDescriptorKey {
    Name = 'name',
    Description = 'description',
    HelpContext = 'helpContext',
    Hint = 'hint',
}

export default templateManagementClient;
