import { ActionInput, CreateOrUpdateAssessmentActionsInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import PrescribedActionsForm from './PrescribedActionsForm';
import { RecommendedActionListComponent } from './RecommendedActionListComponent';
import { RecommendedActionsViewState } from './RecommendedActionViewState';
import a2sApolloClient from '../../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_OBSERVATIONS_ACTIONS } from '../../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import { isAWSUser } from '../../../../../../common/Utils';
import rumClient from '../../../../../../common/monitoring/RumClient';
import UpdateStatusIndicator from '../../../../../common/UpdateStatusIndicator';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { reportIsReadOnly } from '../../../../Utils';
import { createOrUpdateAssessmentRecommendedActionsSuccessful } from '../../../../facilitate/CurrentAssessmentSlice';
import { filterExcludedSections } from '../../../Utils';
import { PrescribedAction, RecommendedAction } from '../../../model/Assessment';

export enum ActionTypeRecommendedAction {
    ADD_ACTION,
    DELETE_ACTION,
    UPDATE_ACTION,
    REORDER_ACTION,
    RELOAD_STATE,
}

interface RecommendedActionsProps {
    categoryId: string;
    prescribedActions: PrescribedAction[];
}

const RecommendedActionsComponent = (props: RecommendedActionsProps & AppLabelsContextInterface & AuthContextInterface): JSX.Element => {
    const appDispatch = useAppDispatch();

    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const myUserId = props.auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const isReadOnly = reportIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);
    const isAwsUser = isAWSUser(props.auth?.getUserInfo());

    let perspectiveName = null;
    let categoryName = null;
    const categoryId = props.categoryId;
    const prescribedActions = props.prescribedActions;
    let recommendedActions: RecommendedAction[] = null;
    const workstreams = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.workstreams);
    const excludeSections = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.report?.report?.excludeSections
    );
    const allSections = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.sections);
    const includedSections = filterExcludedSections(allSections, excludeSections, currentAssessmentOrSelectedSnapshot, null);

    let initRecommendedActionsViewState: RecommendedActionsViewState = useMemo(
        () => ({
            assessmentId: assessmentId,
            workstreamName: undefined,
            activityName: undefined,
            categoryId: undefined,
            recommendedActions: undefined,
            prescribedActions,
            responseSaved: false,
        }),
        [assessmentId, prescribedActions]
    );

    if (categoryId !== initRecommendedActionsViewState.categoryId) {
        if (workstreams?.length > 0) {
            workstreams.forEach((workstream) => {
                workstream.activities.forEach((activity) => {
                    if (activity.id === categoryId) {
                        perspectiveName = workstream.name;
                        categoryName = activity.name;
                        recommendedActions = activity?.actions?.actions?.map((action) => {
                            return {
                                id: action?.text,
                                text: action?.text,
                                priority: action?.priority,
                                effort: action?.effort,
                                targetDate: action?.targetDate,
                                owner: action?.owner,
                            } as RecommendedAction;
                        });
                    }
                });
            });
        } else {
            includedSections.forEach((section) => {
                section.categories.forEach((category) => {
                    if (category.id === categoryId) {
                        perspectiveName = section.label;
                        categoryName = category.name;
                        recommendedActions = category.actions?.actions?.map((action) => {
                            return {
                                id: action?.text,
                                text: action?.text,
                                priority: action?.priority,
                                effort: action?.effort,
                                targetDate: action?.targetDate,
                                owner: action?.owner,
                            } as RecommendedAction;
                        });
                    }
                });
            });
        }

        initRecommendedActionsViewState = {
            assessmentId: assessmentId,
            workstreamName: perspectiveName,
            activityName: categoryName,
            categoryId,
            recommendedActions,
            prescribedActions,
            responseSaved: false,
        };
    }

    const [CreateOrUpdateAssessmentObservationsActions, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_OBSERVATIONS_ACTIONS, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentObservationsActions;
            if (responses) {
                const updatedResponse = responses?.find((r) => !!r.actions);
                if (updatedResponse) {
                    appDispatch(
                        createOrUpdateAssessmentRecommendedActionsSuccessful({
                            assessmentId: assessmentId,
                            categoryId: categoryId,
                            updatedRecommendedActions: updatedResponse.actions,
                        })
                    );
                } else {
                    rumClient.recordError('Unable to retrieve actions from CreateOrUpdateAssessmentObservationsActions response.');
                }
            }
        },
    });

    const updateRecommendedActionsServer = async (state: RecommendedActionsViewState) => {
        let actionInput: Array<ActionInput> = [];
        if (state.recommendedActions) {
            actionInput = state.recommendedActions.map((x) => {
                return {
                    text: x.text,
                    priority: x.priority,
                    effort: x.effort,
                    targetDate: x.targetDate,
                    owner: x.owner,
                } as ActionInput;
            });
        }
        try {
            CreateOrUpdateAssessmentObservationsActions({
                variables: {
                    input: {
                        assessmentId: state.assessmentId,
                        actions: Array<CreateOrUpdateAssessmentActionsInput>({
                            categoryId: state.categoryId,
                            actions: actionInput,
                            tags: null,
                        }),
                    },
                },
            });
        } catch (error: any) {
            rumClient.recordError(error);
        }
    };

    const recommendedActionsReducer = (state: RecommendedActionsViewState, action: any): RecommendedActionsViewState => {
        if (action.type === ActionTypeRecommendedAction.ADD_ACTION) {
            const newRecommendedActions = action.payLoad;
            if (newRecommendedActions) {
                newRecommendedActions.forEach((recAction: RecommendedAction) => {
                    recAction.id = recAction.text;
                });
                if (state.recommendedActions) {
                    newRecommendedActions.forEach((recAction: RecommendedAction) => {
                        if (state.recommendedActions.findIndex((t) => t.id === recAction.id) < 0) {
                            state.recommendedActions = state.recommendedActions?.concat(recAction);
                        }
                    });
                } else {
                    state.recommendedActions = newRecommendedActions;
                }
                updateRecommendedActionsServer(state);
            }
            return { ...state };
        } else if (action.type === ActionTypeRecommendedAction.DELETE_ACTION) {
            const toDeleteRecommendedActions = action.payLoad;
            if (toDeleteRecommendedActions) {
                if (state.recommendedActions) {
                    state.recommendedActions = state.recommendedActions?.filter((act) => act.text !== toDeleteRecommendedActions.text);
                    updateRecommendedActionsServer(state);
                }
            }
            return { ...state };
        } else if (action.type === ActionTypeRecommendedAction.UPDATE_ACTION) {
            const updatedAction = action.payLoad;
            if (updatedAction) {
                if (state.recommendedActions) {
                    state.recommendedActions = state.recommendedActions.map((act) => {
                        if (act.id === updatedAction.id) {
                            return { ...updatedAction, id: updatedAction.text }; // action text may have changed. update id accordingly
                        }
                        return act;
                    });
                    updateRecommendedActionsServer(state);
                }
            }
            return { ...state, responseSaved: true };
        } else if (action.type === ActionTypeRecommendedAction.REORDER_ACTION) {
            const updatedActions = action.payLoad;
            if (updatedActions) {
                state.recommendedActions = updatedActions;
                if (state.recommendedActions) {
                    updateRecommendedActionsServer(state);
                }
            }
            return { ...state };
        } else if (action.type === ActionTypeRecommendedAction.RELOAD_STATE) {
            const updatedState = action.payLoad;
            if (updatedState) {
                return { ...state, ...updatedState };
            }
            return { ...state };
        }
        return { ...state };
    };

    const [viewState, dispatchViewState] = useReducer(recommendedActionsReducer, initRecommendedActionsViewState);

    const [presvisible, setPresVisible] = useState(false);
    const appLabels = props.appLabels;

    const updateStatusIndicator = useCallback(() => {
        // Temporarily disable updates on the old RS feature for AWS users during MCM https://mcm.amazon.com/cms/MCM-102767629#steps
        // The code related to the old RS feature is planned to be removed in phase two of Recommendation Service, when partner users are also migrated to the new RS feature
        if (isAwsUser) {
            return null;
        }
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.results.review_all.report.recommended_actions.grid.updating_actions}
                updateConfirmationText={
                    !loading && !error && viewState.responseSaved
                        ? appLabels.assessment.results.review_all.report.recommended_actions.grid.actions_saved
                        : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.results.review_all.report.recommended_actions.grid.error_api : null}
                errorMessageDetail={error?.message ? `[${error?.message}]` : null}
                tryAgainText={appLabels.assessment.results.review_all.report.recommended_actions.grid.try_update_again}
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [isAwsUser, loading, appLabels, viewState.responseSaved, error]);

    useEffect(() => {
        if (categoryId !== viewState.categoryId) {
            dispatchViewState({ type: ActionTypeRecommendedAction.RELOAD_STATE, payLoad: initRecommendedActionsViewState });
        }
    }, [categoryId, initRecommendedActionsViewState, viewState.categoryId]);

    return (
        <Box className='recommended-actions-section'>
            <SpaceBetween size='xxs' direction='vertical'>
                <Box fontSize='heading-s' fontWeight='bold'>
                    {appLabels.assessment.results.review_all.report.recommended_actions.grid.header}
                </Box>
                {updateStatusIndicator()}
                {(recommendedActions === undefined || recommendedActions === null || recommendedActions?.length === 0) && (
                    <Box fontSize='body-s'>{appLabels.assessment.results.review_all.report.recommended_actions.grid.nodata_message}</Box>
                )}
                {recommendedActions !== undefined && recommendedActions !== null && recommendedActions?.length !== 0 && (
                    <RecommendedActionListComponent
                        dispatch={dispatchViewState}
                        state={initRecommendedActionsViewState}
                        isReadOnly={isAwsUser || isReadOnly}
                    />
                )}
                <Box float='left'>
                    <Button
                        disabled={isAwsUser || isReadOnly}
                        onClick={() => {
                            setPresVisible(true);
                        }}
                    >
                        {appLabels.assessment.results.review_all.report.recommended_actions.grid.button_add_action}
                    </Button>
                </Box>
                <PrescribedActionsForm
                    recommendedActionsViewState={initRecommendedActionsViewState}
                    visible={presvisible}
                    setVisible={setPresVisible}
                    dispatchViewState={dispatchViewState}
                    isReadOnly={isAwsUser || isReadOnly}
                />
            </SpaceBetween>
        </Box>
    );
};

export default withAuthContext(withLocalizationContext(withAppLabelsContext(RecommendedActionsComponent)));
