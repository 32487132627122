import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, ButtonDropdown, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { LinkAttributes } from './LandingPage';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import { isAllCustomerSelected, isCustomerSelected } from '../../common/Utils';
import Assessments from '../assessments/Assessments';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../customers/CustomerFilterContext';
import { useAppSelector } from '../redux/hooks';

export interface GettingStartedInfo {
    title: string;
    links: LinkAttributes[];
}

export type GettingStartedProps = AppLabelsContextInterface & CustomerFilterContextInterface & AuthContextInterface & GettingStartedInfo;

const GettingStarted: FunctionComponent<GettingStartedProps> = (props: GettingStartedProps) => {
    const history = useHistory();

    const { inProgressAssessments } = useAppSelector((state) => state.assessmentsState);

    const links = props.links.map((link: LinkAttributes, index: number) => {
        return (
            <Button
                variant='primary'
                key={index}
                onClick={(event) => {
                    event.preventDefault();
                    history.push(link.linkLocation);
                }}
            >
                {link.linkText}
            </Button>
        );
    });
    const recentAssessmentItems = inProgressAssessments
        .filter((assessment) => isCustomerSelected(props.customer, assessment.accountCustomerName))
        .slice(0, 3) // Only list top 3
        .map((assessment) => ({
            id: assessment.id,
            text: assessment.description,
            href: `/assessments/${assessment.id}`,
        }));
    const items = [
        {
            items: recentAssessmentItems,
        },
        { text: props.appLabels.landing.gettingStarted.pulldown_footer, id: 'assessments', href: '/assessments' },
    ];
    const dropdown = (
        <ButtonDropdown
            items={items}
            onItemFollow={(event) => {
                event.preventDefault();
                if (event.detail.href) {
                    history.push(event.detail.href);
                }
            }}
        >
            {`${!isAllCustomerSelected(props.customer) ? `${props.customer} ` : ''}${props.appLabels.landing.gettingStarted.pulldown_header}`}
        </ButtonDropdown>
    );

    const includeAssessments = useCallback(() => {
        if (props.auth?.isAuthenticated()) {
            return <Assessments />;
        }
        return null;
    }, [props.auth]);

    return (
        <Container className='custom-home__actions'>
            {includeAssessments()}
            <Box variant='h2' padding='n' margin={{ bottom: 'm' }}>
                {props.title}
            </Box>
            <SpaceBetween size='xs'>
                {links}
                {dropdown}
            </SpaceBetween>
        </Container>
    );
};

export default withAuthContext(withAppLabelsContext(withCustomerFilterContext(GettingStarted)));
