import { AssessmentTypeRecommendationEngine, RecommendationAlert } from './AssessmentTypeRecommendationEngine';
import { AppLabels } from '../../common/AppLabels';
import { CustomerProfile, CustomerSegmentId } from '../../models/CustomerProfile';

/**
 * AssessmentTypeRecommendationEngineRegistry provides a registry of assessment type recommendation
 * engines based on the target assessment type.
 *
 * The registry is also the entry point for the client to get recommendation alert.
 *
 */
export class AssessmentTypeRecommendationEngineRegistry {
    readonly engines = new Map<string, AssessmentTypeRecommendationEngine>();

    /**
     * Entry point to get assessment type recommendation alert for the selected assessment type.
     *
     * @param assessmentType Selected assessment type
     * @param customerProfile Customer profile
     * @returns
     */
    getRecommendationAlert(assessmentType: string, customerProfile: CustomerProfile, appLabels: AppLabels): RecommendationAlert | null {
        const engine = this.engines.get(assessmentType);
        if (engine) {
            return engine.getRecommendationAlert(customerProfile, appLabels);
        }

        return null;
    }

    /**
     * Get overridden customer segment ID for the selected assessment type.
     * @param assessmentType Selected assessment type
     * @param customerProfile Customer profile
     * @returns overridden or original target segment ID
     */
    getOverriddenTargetSegmentId(assessmentType: string, customerProfile: CustomerProfile): CustomerSegmentId {
        const engine = this.engines.get(assessmentType);
        if (engine) {
            return engine.getOverriddenTargetSegmentId(customerProfile);
        }

        return null;
    }

    /**
     * Registers assessment type recommendation engine.
     * @param engine recommendation engine implementation
     */
    register(engine: AssessmentTypeRecommendationEngine): boolean {
        if (!engine) {
            throw new Error('Engine missing.');
        }
        const targetAssessmentType = engine.getTargetAssessmentType();
        if (targetAssessmentType) {
            if (this.engines.get(targetAssessmentType)) {
                throw new Error(`Engine for assessment type ${targetAssessmentType} already exists.`);
            }
            this.engines.set(targetAssessmentType, engine);
            return true;
        }
        throw new Error('Engine missing target assessment type.');
    }
}
