/* eslint-disable @typescript-eslint/no-empty-interface */
import './LandingPage.scss';

import { Box, ColumnLayout, Container, Grid, Header, Icon, Link as PolarisLink, SpaceBetween } from '@amzn/awsui-components-react';

import GettingStarted, { GettingStartedInfo } from './GettingStarted';
import { hideAppHelpPanel } from '../common/help-panel/AppHelpPanelSlice';
import { initialAssessmentItems, setAssessmentSectionInAppSideNavigationPanel } from '../common/side-navigation/AppSideNavigationSlice';
import { useAppDispatch } from '../redux/hooks';

export const externalLinkProps = {
    external: true,
    externalIconAriaLabel: 'Opens in a new tab',
};

const ExternalLink = (props: { href: string; text: string; className?: string }) => {
    return (
        <a href={props.href} className={props.className}>
            {props.text}
        </a>
    );
};

interface TitleAndDescription {
    title: string;
    description: string;
}

interface TitleDescriptionAndLearnMore extends TitleAndDescription {
    learnMoreLinkLocation: string;
}

export interface LinkAttributes {
    linkLocation: string;
    linkText: string;
    external?: boolean;
}

interface BenefitsAndFeaturesInfo {
    title: string;
    items: BenefitOrFeatureInfo[];
}

interface BenefitOrFeatureInfo extends TitleAndDescription {}

interface UseCaseInfo extends TitleDescriptionAndLearnMore {}

interface PricingInfo {
    item: string;
    price: string;
}

interface MoreResourcesInfo {
    title: string;
    items: ResourceInfo[];
}
interface ResourceInfo extends LinkAttributes {}

interface RelatedServicesInfo {
    title: string;
    items: ServiceInfo[];
}
interface ServiceInfo extends TitleAndDescription {
    serviceLinkLocation: string;
}

export interface LandingProps {
    title: string;
    serviceName: string;
    majorDescription: string;
    minorDescription: string;
    whatItDoes: TitleAndDescription;
    gettingStarted: GettingStartedInfo;
    benefitsAndFeatures: BenefitsAndFeaturesInfo;
    pricing?: PricingInfo[];
    moreResources: MoreResourcesInfo;
    useCases?: UseCaseInfo[];
    relatedServices: RelatedServicesInfo;
}

const Title = (props: { title: string }) => {
    return (
        <Box fontWeight='light' padding={{ top: 'xs' }}>
            <span className='custom-home__category'>{props.title}</span>
        </Box>
    );
};

const Name = (props: { name: string }) => {
    return (
        <Box variant='h1' fontWeight='bold' padding='n' fontSize='display-l' color='inherit'>
            {props.name}
        </Box>
    );
};

const MinorDescription = (props: { text: string }) => {
    return (
        <Box variant='p' fontWeight='light'>
            <span className='custom-home__header-sub-title'>{props.text}</span>
        </Box>
    );
};

const MajorDescription = (props: { text: string }) => {
    return (
        <Box fontWeight='light' padding={{ bottom: 's' }} fontSize='display-l' color='inherit'>
            {props.text}
        </Box>
    );
};

const WhatItDoes = (props: TitleAndDescription) => {
    return (
        <div>
            <Box fontSize='heading-xl' fontWeight='normal' variant='h2'>
                {props.title}
            </Box>
            <Container>{props.description}</Container>
        </div>
    );
};

const BenefitsAndFeatures = (props: { benefitsAndFeatures: BenefitsAndFeaturesInfo }) => {
    return (
        <div>
            <Box fontSize='heading-xl' fontWeight='normal' variant='h2'>
                {props.benefitsAndFeatures.title}
            </Box>
            <Container>
                <ColumnLayout columns={2} variant='text-grid'>
                    {props.benefitsAndFeatures.items.map((bAndF: BenefitOrFeatureInfo, index: number) => (
                        <div key={index}>
                            <Box variant='h3' padding={{ top: 'n' }}>
                                {bAndF.title}
                            </Box>
                            <Box variant='p'>{bAndF.description}</Box>
                        </div>
                    ))}
                </ColumnLayout>
            </Container>
        </div>
    );
};

const UseCases = (props: { useCases: UseCaseInfo[] }) => {
    return (
        <div>
            <Box fontSize='heading-xl' fontWeight='normal' variant='h2'>
                Use cases
            </Box>
            <Container header={<Header variant='h2'>Use Cases</Header>}>
                <ColumnLayout columns={2} variant='text-grid'>
                    {props.useCases.map((u: UseCaseInfo, index: number) => (
                        <div key={index}>
                            <Box variant='h3' padding={{ top: 'n' }}>
                                {u.title}
                            </Box>
                            <Box variant='p'>{u.description}</Box>
                            <ExternalLink href={u.learnMoreLinkLocation} text='Learn More' />
                        </div>
                    ))}
                </ColumnLayout>
            </Container>
        </div>
    );
};
const RelatedServices = (props: { relatedServices: RelatedServicesInfo }) => {
    return (
        <Container header={<Header variant='h2'>{props.relatedServices.title}</Header>}>
            <ColumnLayout columns={2} variant='text-grid'>
                {props.relatedServices.items.map((s: ServiceInfo, index: number) => (
                    <div key={index}>
                        <Box variant='h3' padding={{ top: 'n' }}>
                            <PolarisLink external fontSize='heading-s' href={s.serviceLinkLocation}>
                                {s.title}
                            </PolarisLink>
                        </Box>
                        <Box variant='p'>{s.description}</Box>
                    </div>
                ))}
            </ColumnLayout>
        </Container>
    );
};

const Pricing = (props: { pricing: PricingInfo[] }) => {
    return (
        <Container header={<Header variant='h2'>Pricing (US)</Header>}>
            <ul aria-label='Pricing details' className='custom-list-separator'>
                {props.pricing.map((p: PricingInfo, index: number) => (
                    <li key={index}>
                        <span>{p.item}</span>
                        <span>{p.price}</span>
                    </li>
                ))}
            </ul>
        </Container>
    );
};

const MoreResources = (props: { moreResources: MoreResourcesInfo }) => {
    const links = props.moreResources.items.map((r: ResourceInfo, index: number) => {
        const link = (
            <li key={index}>
                <PolarisLink key={index} href={r.linkLocation} external={r.external ?? true}>
                    {r.linkText}
                </PolarisLink>
            </li>
        );
        return link;
    });
    return (
        <Container
            header={
                <Header variant='h2'>
                    {props.moreResources.title} <Icon name='external' />
                </Header>
            }
        >
            <ul aria-label='Additional resource links' className='custom-list-separator'>
                {links}
            </ul>
        </Container>
    );
};

const LandingPage = (props: LandingProps): JSX.Element => {
    const dispatch = useAppDispatch();
    dispatch(hideAppHelpPanel());

    // Clear sidenav of any assessment content
    dispatch(setAssessmentSectionInAppSideNavigationPanel(initialAssessmentItems));

    return (
        <Box margin={{ bottom: 'l' }}>
            <div className='custom-home__header'>
                <Box>
                    <Grid
                        gridDefinition={[
                            { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
                        ]}
                    >
                        <Title title={props.title} />
                        <div className='custom-home__header-title'>
                            <Name name={props.serviceName} />
                            <MajorDescription text={props.majorDescription} />
                            <MinorDescription text={props.minorDescription} />
                        </div>
                        <GettingStarted {...props.gettingStarted} />
                    </Grid>
                </Box>
            </div>

            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                        { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
                    ]}
                >
                    <div className='custom-home-main-content-area'>
                        <SpaceBetween size='l'>
                            <WhatItDoes title={props.whatItDoes.title} description={props.whatItDoes.description} />
                            <BenefitsAndFeatures benefitsAndFeatures={props.benefitsAndFeatures} />
                            {props.useCases && <UseCases useCases={props.useCases} />}
                            <RelatedServices relatedServices={props.relatedServices} />
                        </SpaceBetween>
                    </div>
                    <div className='custom-home__sidebar'>
                        <SpaceBetween size='xxl'>
                            {props.pricing && props.pricing.length > 0 && <Pricing pricing={props.pricing} />}
                            <MoreResources moreResources={props.moreResources} />
                        </SpaceBetween>
                    </div>
                </Grid>
            </Box>
        </Box>
    );
};

export default LandingPage;
