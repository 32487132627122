import './RatingTile.scss';

import { AssessmentDescriptor } from '@amzn/aws-assessment-template-management-service-typescript-client';

import RatingPercentageBar from './RatingTilePercentageBar';
import { EditableDescriptorKey } from '../../../../../api/templateManagement/TemplateManagementClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import Constants from '../../../../../common/Constants';
import { generatePercentageString } from '../../../../../common/Utils';
import TemplateEditableText from '../../../../common/TemplateEditableText';
import { Comment } from '../CommentBox';
import CommentsThread from '../CommentsThread';

export type RatingTileProps = AppLabelsContextInterface & {
    groupName: string;
    rating?: string | null;
    ratingGuide?: string;
    ratingGuideDescriptor?: AssessmentDescriptor;
    voteCount?: number | null;
    totalVotes?: number | null;
    error?: boolean;
    participantComments?: Array<Comment>;
    onToggle?: (event: any) => void;
    isSelected?: boolean;
};

const RatingTile = (props: RatingTileProps): JSX.Element => {
    const votePercentage = generatePercentageString(props.voteCount, props.totalVotes);
    const isNACssClass = props.rating === Constants.ASSESSMENT_DEFAULT_NA_LABEL ? 'is-NA' : '';
    const isIdkCssClass = props.rating === Constants.ASSESSMENT_DEFAULT_INCOMPLETE_LABEL ? 'is-IDK' : '';
    const tileWrapperClassName = `tile-wrapper ${isNACssClass} ${isIdkCssClass}`;

    return (
        <div className={tileWrapperClassName}>
            <div
                onClick={props.onToggle}
                className={`tile-${props.isSelected ? 'selected' : 'unselected'}`}
                data-testid={`rating-tile-${props.rating}`}
            >
                <div className='rating'>
                    <div className='rating-align-middle'>
                        <div>{props.rating}</div>
                    </div>
                </div>
                <div className='guide-text'>
                    <RatingPercentageBar
                        voteCount={props.voteCount}
                        barWidth={votePercentage}
                        barLabel={props.appLabels.assessment.facilitate.votes}
                    />
                    {/* Rating guide is editable */}
                    <p className='guide-content'>
                        <TemplateEditableText descriptor={props.ratingGuideDescriptor} keyToEdit={EditableDescriptorKey.Description} numRows={2}>
                            <span>{props.ratingGuide}</span>
                        </TemplateEditableText>
                    </p>
                </div>
            </div>
            <CommentsThread
                commentHeader={props.appLabels.assessment.facilitate.participant_comments}
                comments={props.participantComments}
                usePopover={true}
            />
        </div>
    );
};

export default withAppLabelsContext(RatingTile);
