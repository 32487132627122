import './CustomColorBadge.scss';

/**
 * A2T version of <Badge> in Polaris library, with additional support for custom color
 */
export const CustomColorBadge = (text: string, backgroundColor: string, fontColor: string): JSX.Element => {
    const spanStyle = {
        backgroundColor,
        color: fontColor,
    };
    return (
        <span className='custom-color-badge' style={spanStyle}>
            {text}
        </span>
    );
};
