import { AssessmentTemplateMetadata } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, ExpandableSection, FormField, Input, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { ApolloError, useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import ShareWithAssessmentCreators from './ShareWithAssessmentCreators';
import ShareWithCoowners from './ShareWithCoowners';
import ShareWithLocaleManagers from './ShareWithLocaleManagers';
import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import {
    SHARE_ASSESSMENT_TYPE,
    SHARE_TEMPLATE,
    UNSHARE_ASSESSMENT_TYPE,
    UNSHARE_TEMPLATE,
} from '../../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import dompurify from '../../../../../common/DomPurify';
import { removeProviderFromPrincipalId } from '../../../../../common/Utils';
import rumClient from '../../../../../common/monitoring/RumClient';
import AssessmentDetailsHeader from '../../../../assessments/facilitate/header/AssessmentDetailsHeader';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../../common/RequestStatusFlashbar';
import { openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { canShareTemplate } from '../TemplatePermissionsUtils';

/**
 * Component for sharing a template. Listed in the side nav
 */
const ShareTemplate: FunctionComponent<AppLabelsContextInterface & AuthContextInterface> = ({ appLabels, auth }): JSX.Element => {
    const dispatch = useAppDispatch();
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    // Child components watch these variables to recognize when to reset/save
    const [resetClickCount, setResetClickCount] = useState<number>(0);
    const [saveClickCount, setSaveClickCount] = useState<number>(0);

    // Template state
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);

    // Check with child components if any permissions have changed. The setter is passed to child
    const [areCoownerPermissionsChanged, setAreCoownerPermissionsChanged] = useState<boolean>(false);
    const [areLocaleManagerPermissionsChanged, setAreLocaleManagerPermissionsChanged] = useState<boolean>(false);
    const [areAssessmentCreatorPermissionsChanged, setAreAssessmentCreatorPermissionsChanged] = useState<boolean>(false);
    const havePermissionsChanged = areCoownerPermissionsChanged || areLocaleManagerPermissionsChanged || areAssessmentCreatorPermissionsChanged;

    const canShare = canShareTemplate(currentTemplateMetadata, auth);

    const owner = currentTemplateMetadata.owner;

    // Set help panel
    useEffect(() => {
        const purifiedContent = dompurify.sanitize(appLabels.manage_templates.share.share_template_help_panel.content);
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.share.share_template_help_panel.header,
                content: <div dangerouslySetInnerHTML={{ __html: purifiedContent }} />,
            })
        );
    }, [dispatch, appLabels.manage_templates.share.share_template_help_panel]);

    const onShareError = useCallback(
        (error: ApolloError) => {
            // If 401, reauth
            if (error.message.includes('401')) {
                auth.redirectToSignIn();
            }

            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.share.error_sharing_template,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        [appLabels.manage_templates.share.error_sharing_template, auth]
    );

    const [unshareTemplate] = useMutation(UNSHARE_TEMPLATE, {
        client: templateManagementClient,
        onError: onShareError,
    });

    const [shareTemplate] = useMutation(SHARE_TEMPLATE, {
        client: templateManagementClient,
        onError: onShareError,
    });

    const [unshareAssessmentType] = useMutation(UNSHARE_ASSESSMENT_TYPE, {
        client: templateManagementClient,
        onError: onShareError,
    });

    const [shareAssessmentType] = useMutation(SHARE_ASSESSMENT_TYPE, {
        client: templateManagementClient,
        onError: onShareError,
    });

    /**
     * Indicate to child components that they should reset
     */
    const reset = useCallback(() => {
        // Indicate to child components that they should reset
        setResetClickCount((resetClickCount) => resetClickCount + 1);
    }, []);

    /**
     * Indicate to child components that they should save
     */
    const save = useCallback(() => {
        setSaveClickCount((saveClickCount) => saveClickCount + 1);
    }, []);

    return (
        <div className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} shouldDisplayTemplate={true} />
            {/* Header that says "Share template" */}
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.manage_templates.share.share_template}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            {/* Form that displays owner and allows editing co-owners, locale managers, and assessment creators */}
            <div className='assessment-form'>
                <SpaceBetween direction='vertical' size='l'>
                    <ExpandableSection variant='container' headerText={appLabels.manage_templates.share.owner} defaultExpanded={true}>
                        <FormField>
                            <Input
                                data-testid='owner-textbox'
                                value={removeProviderFromPrincipalId(owner)}
                                // Owner cannot be changed
                                readOnly={true}
                            />
                        </FormField>
                    </ExpandableSection>
                    <ExpandableSection variant='container' headerText={appLabels.manage_templates.share.coowners} defaultExpanded={true}>
                        <ShareWithCoowners
                            canShare={canShare}
                            shareTemplate={shareTemplate}
                            unshareTemplate={unshareTemplate}
                            resetClickCount={resetClickCount}
                            saveClickCount={saveClickCount}
                            setArePermissionsChanged={setAreCoownerPermissionsChanged}
                        />
                    </ExpandableSection>
                    {/* Don't have locale managers expanded by default. There may be lots of languages in the list */}
                    <ExpandableSection variant='container' headerText={appLabels.manage_templates.share.locale_managers}>
                        <ShareWithLocaleManagers
                            canShare={canShare}
                            shareTemplate={shareTemplate}
                            unshareTemplate={unshareTemplate}
                            resetClickCount={resetClickCount}
                            saveClickCount={saveClickCount}
                            setArePermissionsChanged={setAreLocaleManagerPermissionsChanged}
                        />
                    </ExpandableSection>
                    <ExpandableSection variant='container' headerText={appLabels.manage_templates.share.assessment_creators} defaultExpanded={true}>
                        <ShareWithAssessmentCreators
                            canShare={canShare}
                            shareTemplate={shareTemplate}
                            unshareTemplate={unshareTemplate}
                            shareAssessmentType={shareAssessmentType}
                            unshareAssessmentType={unshareAssessmentType}
                            resetClickCount={resetClickCount}
                            saveClickCount={saveClickCount}
                            setArePermissionsChanged={setAreAssessmentCreatorPermissionsChanged}
                        />
                    </ExpandableSection>
                    <Box float='right'>
                        <SpaceBetween size='s' direction='horizontal'>
                            <Button data-testid='reset' onClick={reset}>
                                {appLabels.user_actions.reset}
                            </Button>
                            <Button data-testid='save' variant='primary' onClick={save} disabled={!havePermissionsChanged}>
                                {appLabels.user_actions.save}
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(ShareTemplate));
