import { Box, Button, Modal } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import dompurify from '../../../../../common/DomPurify';

type TemplatePublicationInfoModalProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: (newStatus: boolean) => void;
};
/**
 * A simple modal that describes how to publish a template for the first time
 */
const TemplatePublicationInfoModal: FunctionComponent<TemplatePublicationInfoModalProps> = ({ appLabels, visible, setVisible }): JSX.Element => {
    const sanitizedBody = dompurify.sanitize(appLabels.manage_templates.template_publication_info_modal.content);

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            footer={
                <Box float='right'>
                    <Button
                        variant='primary'
                        onClick={() => {
                            setVisible(false);
                        }}
                        data-testid='btn-close'
                    >
                        {appLabels.user_actions.close}
                    </Button>
                </Box>
            }
            header={appLabels.manage_templates.template_publication_info_modal.header}
        >
            <div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
        </Modal>
    );
};

export default withAppLabelsContext(TemplatePublicationInfoModal);
