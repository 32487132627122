import { FlashContextInterface, FlashType, withFlashContext } from '@amzn/awscat-react-components';
import { Button, Spinner } from '@amzn/awsui-components-react';
import { useQuery } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';

import { GET_SESSION_INFO, isWbsSessionActive } from '../../../api/wbs/WbsClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import CopyLineToClipboard from '../../common/CopyLineToClipboard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loadLiveSessionSuccessful } from '../facilitate/CurrentAssessmentSlice';

const LiveSessionInfo: FunctionComponent<AppLabelsContextInterface & FlashContextInterface> = ({ flash, appLabels }): JSX.Element | null => {
    const dispatch = useAppDispatch();

    const currentLiveSessionInfo = useAppSelector((state) => state.currentAssessmentState.currentLiveSessionInfo);
    const selectedLiveSessionId = useAppSelector((state) => state.currentAssessmentState.selectedLiveSessionId);
    const selectedLiveSessionIsActive = currentLiveSessionInfo ? isWbsSessionActive(currentLiveSessionInfo) : null;
    const sessionUrl = currentLiveSessionInfo?.sessionUrl ?? null;
    const participantsCount = currentLiveSessionInfo?.participantsCount;

    const { loading, error, data } = useQuery(GET_SESSION_INFO, {
        variables: {
            sessionId: selectedLiveSessionId,
        },
        skip: !selectedLiveSessionId,
        pollInterval: selectedLiveSessionIsActive ? 10000 : 0, // 10 sec polling for participants for active session
    });

    const [errorAlerted, setErrorAlerted] = useState<boolean>(false);

    useEffect(() => {
        if (!error) {
            setErrorAlerted(false);
            return;
        }

        if (!errorAlerted) {
            // alert once on api error
            flash.alert(FlashType.error, appLabels.assessment.live_session.error_api, `${error.message}`);
            setErrorAlerted(true);
        }
    }, [flash, appLabels, errorAlerted, error]);

    if (!selectedLiveSessionId) {
        return null;
    }

    if (loading) {
        return (
            <div className='awscat-assessment-live-session-info'>
                <Spinner size='normal' />
            </div>
        );
    } else if (data) {
        const sessionInfo = data.getSessionInfo;
        dispatch(loadLiveSessionSuccessful(sessionInfo));
    }

    if (selectedLiveSessionIsActive) {
        return (
            <div className='awscat-assessment-live-session-info'>
                <CopyLineToClipboard title={appLabels.assessment.live_session.session_link} content={sessionUrl} />
                <Button variant='link' iconName='user-profile'>
                    {participantsCount ?? '?'}
                </Button>
            </div>
        );
    }
    return null;
};

export default withAppLabelsContext(withFlashContext(LiveSessionInfo));
