import './UploadTemplatePanel.scss';

import { Alert, Box, FileUpload, Flashbar, FlashbarProps, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Template, loadTemplate, unloadTemplate } from './CreateTemplateSlice';
import { convertExcelToJson } from './utils/ConvertExcelToJson';
import Paths from '../../../../Paths';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import rumClient from '../../../../common/monitoring/RumClient';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppSelector } from '../../../redux/hooks';

const EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const JSON_MIME_TYPE = 'application/json';

type UploadTemplatePanelProps = AppLabelsContextInterface;
const UploadTemplatePanel: FunctionComponent<UploadTemplatePanelProps> = ({ appLabels }): JSX.Element => {
    const [uploadedFile, setUploadedFile] = useState([]);
    const [errors, setErrors] = useState<FlashbarProps.MessageDefinition[]>([]);
    const isTemplateUploaded = !!useAppSelector((state) => state.createTemplateState.template);
    const dispatch = useDispatch();

    const handleUpload = useCallback(
        async (files: File[]) => {
            // Clear errors
            setErrors([]);

            // Unset file and reset template if 'x' is clicked
            if (files[0] === undefined) {
                setUploadedFile([]);
                dispatch(unloadTemplate());
                return;
            }

            let jsonTemplate: Template;
            try {
                // If the uploaded file is an Excel, convert it
                if (files[0].type === EXCEL_MIME_TYPE) {
                    jsonTemplate = await convertExcelToJson(files[0]);
                } else {
                    // Read the file and parse to JSON
                    jsonTemplate = JSON.parse(await files[0].text());
                }
                dispatch(loadTemplate(jsonTemplate));
                setUploadedFile(files);
            } catch (error) {
                // Show error message if there are any problems
                rumClient.recordError(error);
                setErrors([
                    {
                        header: appLabels.manage_templates.wizard.upload_template.failed_to_parse_template,
                        content: (error as Error).message,
                        type: 'error',
                        dismissible: true,
                        onDismiss: () => setErrors([]),
                    },
                ]);
                return;
            }
        },
        [dispatch, appLabels.manage_templates.wizard.upload_template.failed_to_parse_template]
    );

    const uploadSuccessAlert = useCallback(() => {
        return isTemplateUploaded ? (
            <Alert type='success' data-testid='template-upload-success-alert'>
                {appLabels.manage_templates.wizard.upload_template.template_successfully_uploaded}
            </Alert>
        ) : null;
    }, [isTemplateUploaded, appLabels.manage_templates.wizard.upload_template.template_successfully_uploaded]);

    useEffect(() => {
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.wizard.upload_template.help_panel.header,
                content: appLabels.manage_templates.wizard.upload_template.help_panel.content,
            })
        );

        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
    }, [dispatch, appLabels.manage_templates.wizard.upload_template.help_panel]);

    return (
        <div className='upload-template-panel'>
            <SpaceBetween size='m'>
                <Flashbar items={errors} data-testid='template-upload-error-flashbar' />
                <FileUpload
                    onChange={({ detail }) => {
                        rumClient.recordClick(`${Paths.CREATE_TEMPLATE_PATH}/btn-template-upload`);
                        handleUpload(detail.value);
                    }}
                    value={uploadedFile}
                    accept={`${EXCEL_MIME_TYPE}, ${JSON_MIME_TYPE}`}
                    i18nStrings={{
                        uploadButtonText: () => appLabels.manage_templates.wizard.upload_template.upload_button_text,
                        dropzoneText: () => appLabels.manage_templates.wizard.upload_template.dropzone_text,
                        removeFileAriaLabel: () => '',
                        limitShowFewer: '',
                        limitShowMore: '',
                        errorIconAriaLabel: '',
                    }}
                    data-testid='btn-template-upload'
                />
                {uploadSuccessAlert()}
                <Box color='text-body-secondary'>
                    {appLabels.manage_templates.wizard.upload_template.upload_info_text1}
                    <Link href={appLabels.manage_templates.requester_guide_link} target='_blank' rel='noopener noreferrer' external={true}>
                        {appLabels.manage_templates.wizard.upload_template.upload_info_linked_text}
                    </Link>
                    {appLabels.manage_templates.wizard.upload_template.upload_info_text2}
                </Box>
            </SpaceBetween>
        </div>
    );
};

export default withAppLabelsContext(UploadTemplatePanel);
