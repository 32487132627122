import { RecommendationOutput, Status } from '@amzn/aws-assessment-recommendation-service-client';
import {
    AssessmentActions,
    AssessmentActivity,
    AssessmentCategory,
    AssessmentSection,
    AssessmentWorkstream,
} from '@amzn/awscat-aws-assessment-service-typescript-client';

import { AppLabels } from '../../../common/AppLabels';
import {
    getLabelFromValue,
    getPriorityOptions,
    getSortingComparatorForSelect,
} from '../../../components/assessments/results/reviewall/report/new-recommended-actions/RecommendationConstants';
import { Data as ExcelerateData } from '../../../models/Excelerate';

/**
 * Declaration of a recommended action in assessment.
 * The type declaration can be cleaned up after rs feature is enabled to all in Prod.
 */
export type AssessmentAction = AssessmentActions['actions'][number];

/**
 * A temporary function to differentiate an action in RS (of type RecommendationOutput) from an action in assessment (of type AssessmentAction).
 * This function can be cleaned up after the recommendation service feature is enabled to all in Prod.
 * @param input any
 * @returns whether input is of type AssessmentAction
 */
export function isAssessmentAction(input: AssessmentAction | RecommendationOutput): input is AssessmentAction {
    return input && 'text' in input;
}

/**
 * Differentiate a workstream from a section. Used to determine the Excel report output format
 * @param input workstream or section
 * @returns whether input is of type AssessmentWorkstream
 */
function isAssessmentWorkstream(input: AssessmentWorkstream | AssessmentSection): input is AssessmentWorkstream {
    return input && 'name' in input;
}

/**
 * Differentiate an activity from a category. Used to determine the Excel report output format
 * @param input activity or category
 * @returns whether input is of type AssessmentActivity
 */
function isAssessmentActivity(input: AssessmentActivity | AssessmentCategory): input is AssessmentActivity {
    return input && 'phase' in input;
}

/**
 * By default, sort actions by name in ascending order. This is to mimic the current drag and drop ability, where facilitators can number the verbiages 1. top priority 2. second priority ...
 * Future enhancement: allow user to select sorting column and order in the downloaded report. e.g. targetDate, priority, name could all be the valid sortBy fields
 * @param actionA
 * @param actionB
 * @returns
 */
function sortActionsByVerbiage(actionA: RecommendationOutput, actionB: RecommendationOutput): number {
    return actionA.verbiage > actionB.verbiage ? 1 : -1;
}

function sortActionsByPriority(actionA: RecommendationOutput, actionB: RecommendationOutput, appLabels: AppLabels): number {
    return getSortingComparatorForSelect('priority', getPriorityOptions(appLabels))(actionA, actionB);
}

function sortActions(appLabels: AppLabels, actionA: RecommendationOutput, actionB: RecommendationOutput): number {
    const sortByPriority = sortActionsByPriority(actionA, actionB, appLabels);
    if (sortByPriority !== 0) {
        return sortByPriority;
    }
    return sortActionsByVerbiage(actionA, actionB);
}

/* DOCX helpers */

/**
 * Generate a DOCX recommendation entry from the given action
 * @param action
 * @returns
 */
function getDocxEntryFromAction(action: AssessmentAction | RecommendationOutput, appLabels: AppLabels): string[] {
    const actionPriority = getLabelFromValue(appLabels, 'priority', action?.priority) || '';
    const actionEffort = getLabelFromValue(appLabels, 'effort', action?.effort) || '';
    const actionDate = action?.targetDate || '';
    if (isAssessmentAction(action)) {
        const actionText = action?.text || '';
        const actionOwner = action?.owner || '';
        return [actionText, actionPriority, actionEffort, actionDate, '', actionOwner];
    } else {
        const actionVerbiage = action?.verbiage || '';
        const actionOwner = action?.actionOwner || '';
        const actionBenefit = action?.benefit || '';
        return [actionVerbiage, actionPriority, actionEffort, actionDate, actionBenefit, actionOwner];
    }
}

/**
 * Generate actions for the given category to put into the DOCX consolidated recommendation table
 * @param category
 * @param rsIsEnabled
 * @param rsRecommendationsForCategory
 * @returns
 */
export function generateDocxConsolidatedActionsForCategory(
    category: AssessmentActivity | AssessmentCategory,
    rsIsEnabled: boolean,
    rsRecommendationsForCategory: RecommendationOutput[],
    appLabels: AppLabels
): string[][] {
    const tableData: string[][] = [];
    if (rsIsEnabled) {
        rsRecommendationsForCategory
            ?.filter((action) => action.status === Status.ACTIVE)
            .sort(sortActions.bind(null, appLabels))
            .forEach((action) => {
                const entry: string[] = getDocxEntryFromAction(action, appLabels);
                tableData.push(entry);
            });
    } else {
        for (const action of category?.actions?.actions || []) {
            const entry: string[] = getDocxEntryFromAction(action, appLabels);
            tableData.push(entry);
        }
    }
    return tableData;
}

/**
 * Generate actions for the given category to put into the DOCX assessment detail table
 * @param category
 * @param rsIsEnabled
 * @param rsRecommendationsForCategory
 * @returns
 */
export function generateDocxDetailedActionsForCategory(
    category: AssessmentActivity | AssessmentCategory,
    rsIsEnabled: boolean,
    rsRecommendationsForCategory: RecommendationOutput[],
    appLabels: AppLabels
): string[][] {
    if (rsIsEnabled) {
        return (
            rsRecommendationsForCategory
                ?.filter((action) => action.status === Status.ACTIVE)
                .sort(sortActions.bind(null, appLabels))
                .map((action) => {
                    return [action?.verbiage || '', action?.targetDate || '', action?.benefit || '', action?.actionOwner || ''];
                }) || []
        );
    } else {
        return (
            category?.actions?.actions?.map((action) => {
                return [action?.text || '', action?.targetDate || '', '', action?.owner || ''];
            }) || []
        );
    }
}

/* Excel helpers */

/**
 * Generate an Excel row from the given action
 * @param action
 * @param section
 * @param category
 * @returns
 */
function getExcelRowFromObsAction(
    action: AssessmentAction | RecommendationOutput,
    section: AssessmentSection | AssessmentWorkstream,
    category: AssessmentCategory | AssessmentActivity,
    appLabels
): string[] {
    const actionText = (isAssessmentAction(action) ? action?.text : action?.verbiage) || '';
    const actionOwner = (isAssessmentAction(action) ? action?.owner : action?.actionOwner) || '';
    const actionPriority = getLabelFromValue(appLabels, 'priority', action?.priority) || '';
    const actionEffort = getLabelFromValue(appLabels, 'effort', action?.effort) || '';
    const actionBenefit = (isAssessmentAction(action) ? '' : action?.benefit) || '';
    const actionDate = action?.targetDate || '';
    const actionStatus = (isAssessmentAction(action) ? '' : getLabelFromValue(appLabels, 'progressStatus', action?.progressStatus)) || '';

    const sectionName = isAssessmentWorkstream(section) ? section?.name : section?.label ?? '';
    const categoryName = category?.name;
    const categoryPhase = isAssessmentActivity(category) ? category?.phase : '';
    const categoryObservations = category.observations?.observations || '';

    if (isAssessmentWorkstream(section)) {
        return [
            sectionName,
            categoryName,
            categoryPhase,
            categoryObservations,
            actionText,
            actionPriority,
            actionEffort,
            actionDate,
            actionBenefit,
            actionOwner,
            actionStatus,
        ];
    } else {
        return [
            sectionName,
            categoryName,
            categoryObservations,
            actionText,
            actionPriority,
            actionEffort,
            actionDate,
            actionBenefit,
            actionOwner,
            actionStatus,
        ];
    }
}

/**
 * Generate observations and actions for the given category to put into the Excel Actions sheet
 * @param startRowIndex
 * @param section
 * @param category
 * @param rsIsEnabled
 * @param rsRecommendationsForCategory
 * @returns
 */
export function generateExcelObsActionsForCategory(
    startRowIndex: number,
    section: AssessmentWorkstream | AssessmentSection,
    category: AssessmentActivity | AssessmentCategory,
    rsIsEnabled: boolean,
    rsRecommendationsForCategory: RecommendationOutput[],
    appLabels: AppLabels
): ExcelerateData[] {
    let i = startRowIndex;
    const entries: ExcelerateData[] = [];
    let actions = [];
    if (rsIsEnabled) {
        actions = rsRecommendationsForCategory?.filter((action) => action.status === Status.ACTIVE).sort(sortActions.bind(null, appLabels));
    } else {
        actions = category?.actions?.actions;
    }
    // Add a row for observations if there are no actions for the current category
    if (!actions?.length && category?.observations?.observations) {
        entries.push({
            type: 'row',
            loc: `A${2 + i++}`,
            data: getExcelRowFromObsAction(null, section, category, appLabels),
        });
    } else {
        (actions || []).forEach((action) => {
            entries.push({
                type: 'row',
                loc: `A${2 + i++}`,
                data: getExcelRowFromObsAction(action, section, category, appLabels),
            });
        });
    }
    return entries;
}

/* PPTX helpers */

/**
 * Generate actions string for the given category to put into the PPTX details table
 * @param category
 * @param rsIsEnabled
 * @param rsRecommendationsForCategory
 * @returns
 */
export function getPptxActionsStringForCategory(
    category: AssessmentActivity | AssessmentCategory,
    rsIsEnabled: boolean,
    rsRecommendationsForCategory: RecommendationOutput[],
    appLabels: AppLabels
): string {
    let actionsText: string[] = [];
    if (rsIsEnabled) {
        actionsText = rsRecommendationsForCategory
            ?.filter((action) => action.status === Status.ACTIVE)
            .sort(sortActions.bind(null, appLabels))
            .map((action) => {
                return action.verbiage;
            });
    } else {
        actionsText = category?.actions?.actions?.map((action) => action?.text);
    }
    return (actionsText || []).join('\n');
}

/**
 * Generate a PPTX entry from the given action
 * @param action
 * @returns
 */
function getPptxEntryFromAction(action: AssessmentAction | RecommendationOutput, appLabels: AppLabels): string[] {
    const actionPriority = getLabelFromValue(appLabels, 'priority', action?.priority) || '';
    const actionEffort = getLabelFromValue(appLabels, 'effort', action?.effort) || '';
    const actionDate = action?.targetDate || '';
    if (isAssessmentAction(action)) {
        const actionText = action?.text || '';
        const actionOwner = action?.owner || '';
        return [actionText, actionPriority, actionEffort, actionDate, actionOwner];
    } else {
        const actionVerbiage = action?.verbiage || '';
        const actionOwner = action?.actionOwner || '';
        return [actionVerbiage, actionPriority, actionEffort, actionDate, actionOwner];
    }
}

/**
 * Generate actions for the given category to put into the PPTX recommendation table
 * @param tableData
 * @param category
 * @param rsIsEnabled
 * @param rsRecommendationsForCategory
 * @returns
 */
export function generatePptxActionsForCategory(
    category: AssessmentActivity | AssessmentCategory,
    rsIsEnabled: boolean,
    rsRecommendationsForCategory: RecommendationOutput[],
    appLabels: AppLabels
): string[][] {
    const tableData: string[][] = [];
    if (rsIsEnabled) {
        rsRecommendationsForCategory
            ?.filter((action) => action.status === Status.ACTIVE)
            .sort(sortActions.bind(null, appLabels))
            .forEach((action) => {
                const entry: string[] = getPptxEntryFromAction(action, appLabels);
                tableData.push(entry);
            });
    } else {
        for (const action of category?.actions?.actions || []) {
            const entry: string[] = getPptxEntryFromAction(action, appLabels);
            tableData.push(entry);
        }
    }
    return tableData;
}
