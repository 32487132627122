export interface QuestionCounter {
    addDisplayedQuestion: () => void;
    addHiddenQuestion: () => void;
    getDisplayedQuestionCount: () => number;
    getHiddenQuestionCount: () => number;
    getTotalQuestionCount: () => number;
}

export const getQuestionCounter = (): QuestionCounter => {
    let displayedCount = 0;
    let hiddenCount = 0;
    return {
        addDisplayedQuestion: () => displayedCount++,
        addHiddenQuestion: () => hiddenCount++,
        getDisplayedQuestionCount: () => displayedCount,
        getHiddenQuestionCount: () => hiddenCount,
        getTotalQuestionCount: () => hiddenCount + displayedCount,
    };
};
