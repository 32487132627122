import LegendBox from './LegendBox';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { ScoreScale } from '../../../../../common/score/ScoreScale';

interface LegendBoxesProps {
    scoreScale: ScoreScale;
}

const LegendBoxes: (props: LegendBoxesProps & AppLabelsContextInterface) => JSX.Element = ({ scoreScale }) => {
    const legendBoxes: JSX.Element[] = scoreScale?.bands?.map((band, index) => {
        let premobilize = false;
        if (index === 0) {
            premobilize = true;
        }
        return <LegendBox color={band.backgroundColor} description={band.description || band.title} premobilize={premobilize}></LegendBox>;
    });

    return <div data-testid='legend-boxes'>{legendBoxes}</div>;
};

export default withAppLabelsContext(LegendBoxes);
