import { DefaultValueType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Select, TableProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

import { AppLabels } from '../../../../../common/AppLabels';
import TemplateConstants from '../TemplateConstants';
import TemplateDefaults, { TemplateDefaultWithNamespace } from '../TemplateDefaults';

export type TemplateDefaultTableItem = TemplateDefaultWithNamespace & {
    /** A user-intelligible name that describes the default */
    name: string;
    /** A description that briefly explains how the default is used */
    description: string;
};

export const getTemplateDefaultTableItems = (appLabels: AppLabels): TemplateDefaultTableItem[] => {
    return [
        {
            // Enable pre-event
            name: appLabels.manage_templates.configure_template.editableDefaults.enable_pre_event.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.enable_pre_event.description,
            ...TemplateDefaults.PRE_EVENT_ENABLED,
        },
        {
            // Enable live polling
            name: appLabels.manage_templates.configure_template.editableDefaults.enable_live_polling.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.enable_live_polling.description,
            ...TemplateDefaults.LIVE_SESSION_ENABLED,
        },
        {
            // Rating NA enabled
            name: appLabels.manage_templates.configure_template.editableDefaults.rating_na_enabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.rating_na_enabled.description,
            ...TemplateDefaults.RATING_NA_ENABLED,
        },
        {
            // Snapshots disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.snapshots_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.snapshots_disabled.description,
            ...TemplateDefaults.SNAPSHOTS_DISABLED,
        },
        {
            // Radar disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.radar_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.radar_disabled.description,
            ...TemplateDefaults.RADAR_DISABLED,
        },
        {
            // Heatmap disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.heatmap_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.heatmap_disabled.description,
            ...TemplateDefaults.HEATMAP_DISABLED,
        },
        {
            // Score bar chart disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.score_bar_chart_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.score_bar_chart_disabled.description,
            ...TemplateDefaults.SCORE_BAR_CHART_DISABLED,
        },
        {
            // PPT disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.ppt_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.ppt_disabled.description,
            ...TemplateDefaults.PPT_DISABLED,
        },
        {
            // Word disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.word_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.word_disabled.description,
            ...TemplateDefaults.WORD_DISABLED,
        },
        {
            // Excel disabled
            name: appLabels.manage_templates.configure_template.editableDefaults.excel_disabled.name,
            description: appLabels.manage_templates.configure_template.editableDefaults.excel_disabled.description,
            ...TemplateDefaults.EXCEL_DISABLED,
        },
    ];
};

export const getTemplateDefaultTableColumnDefs = (
    appLabels: AppLabels,
    canEdit: boolean
): TableProps.ColumnDefinition<TemplateDefaultTableItem>[] => {
    return [
        {
            id: 'name',
            header: appLabels.manage_templates.configure_template.name,
            cell: (item) => item.name,
        },
        {
            id: 'description',
            header: appLabels.manage_templates.configure_template.description,
            cell: (item) => item.description,
        },
        {
            id: 'value',
            header: appLabels.manage_templates.configure_template.value,
            cell: (item) => {
                // Currently, no editable defaults use descriptors
                if (item.descriptors) {
                    return '';
                }
                // Depending on the type of value, display something different in this column
                switch (item.valueType) {
                    case DefaultValueType.Boolean:
                        return item.value === TemplateConstants.TRUE_STRING_VALUE ? appLabels.common.yes : appLabels.common.no;
                    case DefaultValueType.String:
                        return item.value;
                    case DefaultValueType.Number:
                        return item.value;
                }
            },
            // Restrict editing if specified
            ...(canEdit && {
                editConfig: {
                    // Cloudscape defines `currentValue` only after you set the value with `setValue`
                    editingCell: (item, { currentValue, setValue }) => {
                        // Depending on the type of value, display a different editor
                        const selectedValue = currentValue ?? item.value;
                        const booleanOptions: OptionDefinition[] = [
                            {
                                value: TemplateConstants.TRUE_STRING_VALUE,
                                label: appLabels.common.yes,
                            },
                            {
                                value: TemplateConstants.FALSE_STRING_VALUE,
                                label: appLabels.common.no,
                            },
                        ];
                        switch (item.valueType) {
                            // For a boolean, use a select
                            case DefaultValueType.Boolean:
                                return (
                                    <Select
                                        expandToViewport={true}
                                        autoFocus={true}
                                        selectedOption={booleanOptions.find((option) => {
                                            if (option.value === selectedValue) {
                                                return true;
                                            }

                                            // If the selected value is undefined, show "No" as default
                                            if (selectedValue === undefined && option.value === TemplateConstants.FALSE_STRING_VALUE) {
                                                return true;
                                            }

                                            return false;
                                        })}
                                        options={booleanOptions}
                                        onChange={(event) => {
                                            const newValue = event.detail.selectedOption.value;
                                            setValue(newValue ?? selectedValue);
                                            item.value = newValue ?? selectedValue;
                                        }}
                                    />
                                );
                            default:
                                // Other options not implemented yet
                                return null;
                        }
                    },
                },
            }),
        },
    ];
};
