import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AssessmentViewModel } from '../AssessmentViewModel';

export interface AssessmentsState {
    completedAssessments: AssessmentViewModel[];
    inProgressAssessments: AssessmentViewModel[];
    loading: boolean;
    requireReload: boolean;
}

export const initialState: AssessmentsState = {
    completedAssessments: [],
    inProgressAssessments: [],
    loading: false,
    requireReload: false,
};

export const assessmentsSlice = createSlice({
    name: 'assessmentsState',
    initialState,
    reducers: {
        loadAssessmentsSuccess: (state, action: PayloadAction<AssessmentViewModel[]>) => {
            state.completedAssessments = [];
            state.inProgressAssessments = [];
            state.loading = false;
            state.requireReload = false;
            action.payload.forEach((a) => {
                if (a.status === 'COMPLETED') {
                    state.completedAssessments.push(a);
                    return;
                }
                state.inProgressAssessments.push(a);
            });
        },
        loadingAssessments: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
            if (state.loading) {
                state.requireReload = false;
            }
        },
        reloadAssessments: (state) => {
            state.requireReload = true;
        },
        updateFavoriteSuccess: (state, action: PayloadAction<{ assessmentId: string; isfavorite: boolean }>) => {
            const { assessmentId, isfavorite } = action.payload;
            state?.completedAssessments?.filter((a) => a.id === assessmentId).map((x) => (x.isfavorite = isfavorite));
            state?.inProgressAssessments?.filter((a) => a.id === assessmentId).map((x) => (x.isfavorite = isfavorite));
        },
    },
});

export const { loadAssessmentsSuccess, loadingAssessments, reloadAssessments, updateFavoriteSuccess } = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
