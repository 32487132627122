import { Authentication } from '@amzn/awscat-portal-authentication-library';

import { GetEnvironmentConfig } from '../../Environments';
import { logger } from '../../common/Logger';

const environmentConfig = GetEnvironmentConfig();
export const authentication = new Authentication(environmentConfig.authEndpoint.url, environmentConfig.portalClient, undefined);

export const getJwtToken = async (): Promise<string> => {
    try {
        const token = await authentication.getToken(true);
        return token;
    } catch (err) {
        logger.error('getJwtToken() - error {err}');
        throw err;
    }
};

export const getProvider = (): string => {
    return authentication.getProvider();
};

export default authentication;
