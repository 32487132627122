import { gql } from '@apollo/client';

/* Query to list all the templates that a user can create an assessment from */
export const LIST_TEMPLATES_QUERY = gql`
    query listTemplates {
        listTemplates {
            config {
                applicabilityPrompts {
                    a2sId
                    applicability {
                        applicabilityConditions {
                            applicabilityPromptKey
                            applicableResponses
                        }
                    }
                    descriptors {
                        description
                        abbreviation
                        helpContext
                        descriptorId
                        locale
                        hint
                        name
                        templateId
                    }
                    metadata {
                        descriptors {
                            description
                            descriptorId
                            hint
                            locale
                            name
                            templateId
                        }
                        key
                        value
                    }
                    promptId
                    responseType
                    templateId
                    selectionDescription {
                        description
                        locale
                        descriptorId
                    }
                    selections {
                        selectionId
                        selectionLabel {
                            description
                            locale
                            descriptorId
                        }
                    }
                }
                assessmentMetadataConfig {
                    excludedMetadataPrompts
                    metadataPrompts {
                        a2sId
                        applicability {
                            applicabilityConditions {
                                applicabilityPromptKey
                                applicableResponses
                            }
                        }
                        descriptors {
                            description
                            hint
                            locale
                            helpContext
                            name
                        }
                        responseType
                        selectionDescription {
                            description
                            hint
                            locale
                        }
                        selections {
                            selectionId
                            selectionLabel {
                                description
                                locale
                            }
                        }
                        metadata {
                            descriptors {
                                locale
                                name
                                description
                                helpContext
                                hint
                            }
                            key
                            value
                        }
                    }
                }
            }
            deliveryGuidance {
                description
                helpContext
                hint
                locale
            }
            descriptors {
                name
                locale
                helpContext
                description
                abbreviation
            }
            type
            version
        }
    }
`;

export const LIST_EDITABLE_TEMPLATES_QUERY = gql`
    query listEditableTemplates {
        listEditableTemplates {
            type
            authorizedAssessmentCreators
            coowners
            descriptors {
                abbreviation
                description
                descriptorId
                helpContext
                locale
                name
                templateId
            }
            isPublishedForType
            isPublishedForVersion
            localeManagers {
                locale
                localeManagers
            }
            owner
            templateId
            version
            createdAt
        }
    }
`;

// Currently allows for templates with three levels of modules
export const GET_TEMPLATES_QUERY = gql`
    query getTemplates($filter: GetTemplatesFilter!) {
        getTemplates(filter: $filter) {
            templateId
            type
            version
            deliveryGuidance {
                description
                helpContext
                locale
                descriptorId
            }
            descriptors {
                abbreviation
                description
                descriptorId
                locale
                name
            }
            config {
                defaultNamespaces {
                    namespace
                    defaults {
                        key
                        value
                        valueType
                        descriptors {
                            name
                            locale
                        }
                    }
                }
                promptMetadataConfig {
                    metadataPrompts {
                        promptId
                        a2sId
                        descriptors {
                            name
                            description
                            helpContext
                            hint
                            locale
                        }
                        responseType
                        selectionDescription {
                            description
                            locale
                        }
                        selections {
                            selectionId
                            selectionLabel {
                                description
                                locale
                            }
                        }
                    }
                }
            }
            views {
                viewId
                descriptors {
                    name
                    locale
                }
                modules {
                    moduleId
                    descriptors {
                        name
                        helpContext
                        hint
                        locale
                        descriptorId
                    }
                    prompts {
                        promptId
                        a2sId
                        descriptors {
                            name
                            description
                            helpContext
                            hint
                            locale
                            descriptorId
                        }
                        applicability {
                            applicabilityConditions {
                                applicabilityPromptKey
                                applicableResponses
                            }
                        }
                        responseType
                        ratingGuide {
                            guide1 {
                                description
                                locale
                                descriptorId
                            }
                            guide2 {
                                description
                                locale
                                descriptorId
                            }
                            guide3 {
                                description
                                locale
                                descriptorId
                            }
                            guide4 {
                                description
                                locale
                                descriptorId
                            }
                            guide5 {
                                description
                                locale
                                descriptorId
                            }
                        }
                        selectionDescription {
                            description
                            locale
                            descriptorId
                        }
                        selections {
                            selectionId
                            selectionLabel {
                                description
                                locale
                                descriptorId
                            }
                        }
                    }
                    childModules {
                        moduleId
                        descriptors {
                            name
                            helpContext
                            hint
                            locale
                            descriptorId
                        }
                        # Also get the parent of this module, for use in timeline
                        # This should be added to every layer of childModules, once we have
                        # templates with only 1 or 3 levels of modules
                        parentModule {
                            moduleId
                        }
                        prompts {
                            promptId
                            a2sId
                            descriptors {
                                name
                                description
                                helpContext
                                hint
                                locale
                                descriptorId
                            }
                            applicability {
                                applicabilityConditions {
                                    applicabilityPromptKey
                                    applicableResponses
                                }
                            }
                            responseType
                            ratingGuide {
                                guide1 {
                                    description
                                    locale
                                    descriptorId
                                }
                                guide2 {
                                    description
                                    locale
                                    descriptorId
                                }
                                guide3 {
                                    description
                                    locale
                                    descriptorId
                                }
                                guide4 {
                                    description
                                    locale
                                    descriptorId
                                }
                                guide5 {
                                    description
                                    locale
                                    descriptorId
                                }
                            }
                            selectionDescription {
                                description
                                locale
                                descriptorId
                            }
                            selections {
                                selectionId
                                selectionLabel {
                                    description
                                    locale
                                    descriptorId
                                }
                            }
                        }
                        childModules {
                            moduleId
                            descriptors {
                                name
                                helpContext
                                hint
                                locale
                                descriptorId
                            }
                            prompts {
                                promptId
                                a2sId
                                descriptors {
                                    name
                                    description
                                    helpContext
                                    hint
                                    locale
                                    descriptorId
                                }
                                applicability {
                                    applicabilityConditions {
                                        applicabilityPromptKey
                                        applicableResponses
                                    }
                                }
                                responseType
                                ratingGuide {
                                    guide1 {
                                        description
                                        locale
                                        descriptorId
                                    }
                                    guide2 {
                                        description
                                        locale
                                        descriptorId
                                    }
                                    guide3 {
                                        description
                                        locale
                                        descriptorId
                                    }
                                    guide4 {
                                        description
                                        locale
                                        descriptorId
                                    }
                                    guide5 {
                                        description
                                        locale
                                        descriptorId
                                    }
                                }
                                selectionDescription {
                                    description
                                    locale
                                    descriptorId
                                }
                                selections {
                                    selectionId
                                    selectionLabel {
                                        description
                                        locale
                                        descriptorId
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const IS_ACRONYM_AVAILABLE = gql`
    query isAcronymAvailable($input: IsAcronymAvailableInput!) {
        isAcronymAvailable(input: $input)
    }
`;
