import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { AUTH_TYPE } from 'aws-appsync';
import { createAuthLink } from 'aws-appsync-auth-link';

import {
    AnalysisDoc,
    ArchiveAnalysisResult,
    DistributionAnalysisResult,
    DistributionDetailsAnalysisResult,
    ParticipantContent,
    ParticipantResponseRecord,
    ParticipantToResponseRecordMap,
    PromptIdToAnalysisDocMap,
    ReferenceId,
    SessionInfo,
    SessionState,
    SolicitId,
} from './WbsTypes';
import { GetEnvironmentConfig } from '../../Environments';
import Constants from '../../common/Constants';
import { getJwtToken } from '../../common/auth/Authentication';
import rumClient from '../../common/monitoring/RumClient';
import { PromptId } from '../../components/assessments/AssessmentPromptViewModel';

const envConfig = GetEnvironmentConfig();

export const LIST_MY_SESSIONS_DEFAULT_LIMIT = 10;
export const LIST_MY_SESSIONS = gql`
    query listMySessions($input: ListMySessionsInput!) {
        listMySessions(input: $input) {
            sessionId
            state
            activeUntil
            sessionMetadata
        }
    }
`;

export const GET_SESSION_INFO = gql`
    query getSessionInfo($sessionId: ID!) {
        getSessionInfo(sessionId: $sessionId) {
            activeUntil
            createdAt
            participantsCount
            sessionId
            sessionMetadata
            sessionUrl
            sharedDoc
            state
        }
    }
`;

export const CREATE_SESSION = gql`
    mutation createSession($input: CreateSessionInput!) {
        createSession(input: $input) {
            sessionId
            activeUntil
            sessionMetadata
            sessionUrl
            state
            referenceId
        }
    }
`;

export const UPDATE_SESSION_STATE = gql`
    mutation updateSessionState($input: UpdateSessionStateInput!) {
        updateSessionState(input: $input) {
            sessionId
            state
        }
    }
`;

export const POST_SHARED_DOC_TO_SESSION = gql`
    mutation postSharedDocToSession($input: PostSharedDocToSesionInput!) {
        postSharedDocToSession(input: $input) {
            sessionId
            sharedDoc
        }
    }
`;

export const GET_RESPONSE_ANALYSIS = gql`
    query getResponseAnalysis($input: ResponseAnalysisInput!) {
        getResponseAnalysis(input: $input) {
            sessionId
            solicitId
            analysisDoc
        }
    }
`;

export const LIST_MY_RESPONSE_ANALYSIS = gql`
    query listMyResponseAnalysis($input: ListMyResponseAnalysisInput!) {
        listMyResponseAnalysis(input: $input) {
            sessionId
            solicitId
            analysisDoc
        }
    }
`;

export const generateWbsSolicitId = (promptId: PromptId | null): SolicitId | null => {
    if (!promptId) {
        return null;
    }
    return `${Constants.ASSESSMENT_TOOL_MRA_PREFIX}${promptId}`;
};

export const extractPromptIdFromWbsSolicitId = (solicitId: string | null): PromptId | null => {
    if (!solicitId) {
        return null;
    }
    return solicitId.replace(Constants.ASSESSMENT_TOOL_MRA_PREFIX, '');
};

export const generateWbsReferenceId = (assessmentId: string | null): ReferenceId => {
    if (!assessmentId) {
        throw new Error('Invalid assessmentId');
    }
    return `${Constants.ASSESSMENT_TOOL_MRA_PREFIX}${assessmentId}`;
};

export const isWbsSessionActive = (sessionInfo: SessionInfo): boolean => {
    const currentTime = new Date().toISOString();
    const expired = sessionInfo.activeUntil < currentTime;
    const isStateActive = sessionInfo.state === SessionState.ACTIVE;
    return !expired && isStateActive;
};

export const getAnalysisResultDistribution = (analysisDoc: AnalysisDoc): DistributionAnalysisResult | null => {
    const distributionAnalysisContent = analysisDoc.content.find((r) => !!r.analysisResults.distribution);
    const distributionAnalysisResult = distributionAnalysisContent?.analysisResults.distribution;
    return (distributionAnalysisResult as DistributionAnalysisResult) ?? null;
};

/**
 * Used to generate live-polling participant records in Excel reports
 * @param analysisDoc analysis doc for a solicitId
 * @returns list participant response records
 */
export const getParticipantRecordsInReport = (analysisDoc: AnalysisDoc): ParticipantResponseRecord[] => {
    const participantToResponseMap: ParticipantToResponseRecordMap = {};

    // Add votes to the participant records map
    const distributionDetailsAnalysisContent = analysisDoc.content.find((r) => !!r.analysisResults.distributionDetails);
    const participantVoteMap = distributionDetailsAnalysisContent?.analysisResults?.distributionDetails as DistributionDetailsAnalysisResult;
    if (participantVoteMap) {
        Object.keys(participantVoteMap).forEach((participantId) => {
            const participantVote = participantVoteMap[participantId];
            participantToResponseMap[participantId] = Object.assign({}, participantToResponseMap[participantId], {
                vote: participantVote?.value,
                votedAt: participantVote?.updatedAt,
                authorRole: participantVote?.role,
                authorName: participantVote?.username,
                authorJoinedAt: participantVote?.timeJoined,
            } as ParticipantResponseRecord);
        });
    }
    // Add comments to the participant records map
    const archiveAnalysisResult = analysisDoc.content.find((r) => !!r.analysisResults.archive);
    const participantContentMap = (archiveAnalysisResult?.analysisResults?.archive as ArchiveAnalysisResult)?.participantContent;
    if (participantContentMap) {
        Object.keys(participantContentMap).forEach((participantId) => {
            const participantContent = participantContentMap[participantId];
            participantToResponseMap[participantId] = Object.assign({}, participantToResponseMap[participantId], {
                comment: participantContent?.value,
                commentedAt: participantContent?.updatedAt,
                authorRole: participantContent?.role,
                authorName: participantContent?.username,
                authorJoinedAt: participantContent?.timeJoined,
            });
        });
    }
    return Object.values(participantToResponseMap);
};

export const getAllParticipantsContent = (analysisDoc: AnalysisDoc): ParticipantContent[] => {
    const archiveAnalysisResult = analysisDoc.content.find((r) => !!r.analysisResults.archive);
    const participantContentMap = (archiveAnalysisResult?.analysisResults?.archive as ArchiveAnalysisResult)?.participantContent;
    if (participantContentMap) {
        const participantsContent = Object.values(participantContentMap);
        return participantsContent;
    }
    return [];
};

/**
 * Gets the live session responses for a single prompt ID
 * @param sessionId the current live session ID
 * @param promptId the prompt ID
 * @returns the analysis doc for the prompt
 */
const getAnalysisDocForPrompt = async (sessionId: string, promptId: PromptId): Promise<AnalysisDoc> => {
    const getResponseAnalysisResponse = await wbsClient.query({
        query: GET_RESPONSE_ANALYSIS,
        variables: {
            input: {
                sessionId,
                solicitId: generateWbsSolicitId(promptId || ''),
            },
        },
    });
    if (getResponseAnalysisResponse.error) {
        // If an error occurs, don't throw any errors. This function is used to retrieve potentially hundreds of docs in parallel,
        // and if one or two fail, we can still return most of the needed data to the user
        rumClient.recordError(
            `getAnalysisDocForPrompt() error - sessionId=${sessionId} promptId=${promptId}, error=${getResponseAnalysisResponse.error}`
        );
    }

    try {
        const responseAnalysisDoc: AnalysisDoc = JSON.parse(getResponseAnalysisResponse.data.getResponseAnalysis.analysisDoc);
        return responseAnalysisDoc;
    } catch (err) {
        // analysisDoc is malformed
        rumClient.recordError(
            `getAnalysisDocForPrompt() error - sessionId=${sessionId} promptId=${promptId}, error=${getResponseAnalysisResponse.error}`
        );
        return null;
    }
};

/**
 * Retrieves existing live event responses for the given prompt IDs
 * @param sessionId the current live session ID
 * @param promptIds The prompts to retrieve responses for
 * @returns A map from prompt ID to the corresponding analysis doc
 */
export const getAnalysisDocsForPrompts = async (sessionId: string, promptIds: string[]): Promise<PromptIdToAnalysisDocMap> => {
    const promptIdToAnalysisDocMap: PromptIdToAnalysisDocMap = new Map<string, AnalysisDoc>();

    // Get analysis doc for each prompt in parallel
    const getAnalysisDocsForPromptsPromises: Promise<void>[] = promptIds.map(async (promptId) => {
        promptIdToAnalysisDocMap.set(promptId, await getAnalysisDocForPrompt(sessionId, promptId));
    });
    await Promise.all(getAnalysisDocsForPromptsPromises);

    return promptIdToAnalysisDocMap;
};

// Configuring client
const myAppConfig = {
    graphqlEndpoint: envConfig.wbsApiEndpoint,
    region: 'us-west-2',
    authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as 'AMAZON_COGNITO_USER_POOLS' | 'OPENID_CONNECT',
};

const url = myAppConfig.graphqlEndpoint;
const region = myAppConfig.region;
const auth = {
    type: myAppConfig.authenticationType,
    jwtToken: async () => await getJwtToken(),
};

const link = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })]);
const wbsClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

export default wbsClient;
