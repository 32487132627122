import dompurify from 'dompurify';

// Blocklist: https://github.com/cure53/DOMPurify/wiki/Default-TAGs-ATTRIBUTEs-allow-list-&-blocklist

dompurify.addHook('afterSanitizeAttributes', (node: Element) => {
    // set all elements owning target to target='_blank' and rel= 'noopener noreferrer'
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

export default dompurify;
