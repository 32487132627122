import './AdminManageTemplates.scss';

import {
    AssessmentTemplateMetadata,
    CreateTemplateInput,
    PublishTemplateInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    CollectionPreferencesProps,
    FormField,
    Header,
    Icon,
    Link,
    Pagination,
    Popover,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    Table,
    TableProps,
    TextFilter,
} from '@amzn/awsui-components-react';
import { BaseNavigationDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AdminManageTemplatesHelpPanel from './AdminManageTemplatesHelpPanel';
import CreateAssessmentTypeModal from './CreateAssessmentTypeModal';
import PublishTemplateModal from './PublishTemplateModal';
import { loadTemplateMetadata, unloadCurrentTemplate } from './edit-template/CurrentTemplateSlice';
import { generateTemplateModuleUrl, generateTemplateUrlWithSuffix } from './edit-template/TemplateUrlUtils';
import { getDescriptorForLocale } from './edit-template/TemplateUtils';
import Paths from '../../../Paths';
import templateManagementClient from '../../../api/templateManagement/TemplateManagementClient';
import { CREATE_DRAFT, PUBLISH_TEMPLATE } from '../../../api/templateManagement/TemplateManagementMutations';
import { LIST_EDITABLE_TEMPLATES_QUERY } from '../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import rumClient from '../../../common/monitoring/RumClient';
import EmptyTable from '../../common/EmptyTable';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';

interface TemplateTableItem {
    id: string;
    type: string;
    name: string;
    version: string;
    isPublishedForType: boolean;
    isPublishedForVersion: boolean;
    createdAt: number;
}

/**
 * User can filter for:
 * 1. All templates
 * 2. Published templates only
 * 3. Published templates and latest drafts
 */
enum TemplateStatusFilterOption {
    All,
    Published,
    PublishedAndLatestDrafts,
}

type AdminManageTemplatesProps = AuthContextInterface & AppLabelsContextInterface & LocalizationContextInterface;
const AdminManageTemplates: FunctionComponent<AdminManageTemplatesProps> = ({ appLabels, auth, locale }): JSX.Element => {
    const isAuthenticated = auth.isAuthenticated();
    const dispatch = useDispatch();
    const history = useHistory();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [retrievedTemplateMetadata, setRetrievedTemplateMetadata] = useState<AssessmentTemplateMetadata[]>(null);
    const [templateTableItems, setTemplateTableItems] = useState<TemplateTableItem[]>([]);
    const [selectedTemplateItem, setSelectedTemplateItem] = useState<TemplateTableItem>(null);

    const [selectedTemplateStatus, setSelectedTemplateStatus] = useState<string>(TemplateStatusFilterOption.PublishedAndLatestDrafts.toString());
    const templateStatusFilterOptions: SelectProps.Option[] = useMemo(
        () => [
            {
                value: TemplateStatusFilterOption.All.toString(),
                label: appLabels.manage_templates.table.all_templates,
            },
            {
                value: TemplateStatusFilterOption.Published.toString(),
                label: appLabels.manage_templates.template.statuses.published,
            },
            {
                value: TemplateStatusFilterOption.PublishedAndLatestDrafts.toString(),
                label: appLabels.manage_templates.table.published_and_latest_drafts,
            },
        ],
        [appLabels.manage_templates]
    );

    const [publishTemplateModalVisible, setPublishTemplateModalVisible] = useState<boolean>(false);
    const [publishTemplateInput, setPublishTemplateInput] = useState<PublishTemplateInput>(null);

    const [tableLoading, setTableLoading] = useState<boolean>(true);

    const [createAssessmentTypeModalVisible, setCreateAssessmentTypeModalVisible] = useState(false);

    // A set of draft IDs, that are the latest (i.e. most recently created) drafts for their types/versions
    const [latestDraftSet, setLatestDraftSet] = useState<Set<string>>(null);

    // Whenever the loaded templates change, recalculate the latest draft set
    useEffect(() => {
        // Map of the type-version (MRA2-V3.6) to the most recently created draft ID (uuid) and its `createdAt` epoch timestamp (in seconds)
        const typeVersionToLatestDraftIdMap = new Map<string, { id: string; createdAt: number }>();
        templateTableItems.forEach((currentDraft) => {
            const { type, version, id, createdAt, isPublishedForType, isPublishedForVersion } = currentDraft;
            // If not a draft, skip
            if (isPublishedForType || isPublishedForVersion) {
                return;
            }

            // Compare the current draft with the one that we currently believe is the latest draft
            const typeVersion = `${type}-${version}`;
            const existingLatestDraft = typeVersionToLatestDraftIdMap.get(typeVersion);
            if (!existingLatestDraft || existingLatestDraft.createdAt < currentDraft.createdAt) {
                typeVersionToLatestDraftIdMap.set(typeVersion, { id, createdAt });
            }
        });

        const latestDraftIdsArray: string[] = Array.from(typeVersionToLatestDraftIdMap.values()).map((draft) => draft.id);
        setLatestDraftSet(new Set(latestDraftIdsArray));
    }, [templateTableItems, setLatestDraftSet]);

    /**
     * Formats the response from template management to be presentable in the table in A2T
     */
    const templateMetadataToTableItem = useCallback(
        (templateMetadata: AssessmentTemplateMetadata): TemplateTableItem => ({
            ...templateMetadata,
            id: templateMetadata.templateId,
            name: getDescriptorForLocale(templateMetadata.descriptors, locale)?.name || '',
        }),
        [locale]
    );

    /**
     * Get variables for the "Create draft" button. This button is disabled if `!canCreateDraft`,
     * and the reason `reasonIfCannotCreateDraft` is shown if the button is disabled
     */
    const { canCreateDraft, reasonIfCannotCreateDraft } = useMemo((): {
        canCreateDraft: boolean;
        reasonIfCannotCreateDraft?: string;
    } => {
        if (selectedTemplateItem?.isPublishedForType === undefined) {
            return { canCreateDraft: false, reasonIfCannotCreateDraft: appLabels.manage_templates.actions.errors.no_template_selected };
        }
        // Can't create a draft from a non-published template
        if (!selectedTemplateItem.isPublishedForType && !selectedTemplateItem.isPublishedForVersion) {
            return { canCreateDraft: false, reasonIfCannotCreateDraft: appLabels.manage_templates.actions.errors.cannot_create_draft_from_draft };
        }
        return { canCreateDraft: true };
    }, [selectedTemplateItem?.isPublishedForType, selectedTemplateItem?.isPublishedForVersion, appLabels.manage_templates.actions.errors]);

    /**
     * Get variables for the "Set primary template" button. This button is disabled if `!canPublishSelectedTemplateForVersion`,
     * and the reason `reasonIfCannotPublishForVersion` is shown if the button is disabled
     */
    const { canPublishSelectedTemplateForType, reasonIfCannotPublishForType } = useMemo((): {
        canPublishSelectedTemplateForType: boolean;
        reasonIfCannotPublishForType?: string;
    } => {
        if (selectedTemplateItem?.isPublishedForType === undefined) {
            return {
                canPublishSelectedTemplateForType: false,
                reasonIfCannotPublishForType: appLabels.manage_templates.actions.errors.no_template_selected,
            };
        }
        // Can't publish a template for type, if it's already published for type
        if (selectedTemplateItem.isPublishedForType) {
            return {
                canPublishSelectedTemplateForType: false,
                reasonIfCannotPublishForType: appLabels.manage_templates.actions.errors.already_primary_template,
            };
        }
        return { canPublishSelectedTemplateForType: true };
    }, [selectedTemplateItem?.isPublishedForType, appLabels.manage_templates.actions.errors]);

    /**
     * Get variables for the "Publish for version" button. This button is disabled if `!canPublishSelectedTemplateForVersion`,
     * and the reason `reasonIfCannotPublishForVersion` is shown if the button is disabled
     */
    const { canPublishSelectedTemplateForVersion, reasonIfCannotPublishForVersion } = useMemo((): {
        canPublishSelectedTemplateForVersion: boolean;
        reasonIfCannotPublishForVersion?: string;
    } => {
        if (selectedTemplateItem?.isPublishedForType === undefined) {
            return {
                canPublishSelectedTemplateForVersion: false,
                reasonIfCannotPublishForVersion: appLabels.manage_templates.actions.errors.no_template_selected,
            };
        }
        // Can't publish a template for version, if it's already published at all
        if (selectedTemplateItem.isPublishedForType || selectedTemplateItem.isPublishedForVersion) {
            return {
                canPublishSelectedTemplateForVersion: false,
                reasonIfCannotPublishForVersion: appLabels.manage_templates.actions.errors.template_already_published,
            };
        }
        return { canPublishSelectedTemplateForVersion: true };
    }, [selectedTemplateItem?.isPublishedForType, selectedTemplateItem?.isPublishedForVersion, appLabels.manage_templates.actions.errors]);

    const [listEditableTemplates] = useLazyQuery(LIST_EDITABLE_TEMPLATES_QUERY, {
        client: templateManagementClient,
        fetchPolicy: 'network-only',
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.table.error_loading_templates,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const templateMetadata: AssessmentTemplateMetadata[] = data.listEditableTemplates;
            if (templateMetadata) {
                setRetrievedTemplateMetadata(templateMetadata);
                setTemplateTableItems(templateMetadata.map(templateMetadataToTableItem));
            }
            setTableLoading(false);
        },
    });

    const [createDraft] = useMutation(CREATE_DRAFT, {
        client: templateManagementClient,
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.actions.errors.error_creating_draft,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
    });

    const [publishTemplate] = useMutation(PUBLISH_TEMPLATE, {
        client: templateManagementClient,
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.actions.errors.error_publishing_template,
                messageContent: error.message,
            });
            rumClient.recordError(error);
            // Trickle up the error so that it closes out the PublishTemplateModal
            throw error;
        },
    });

    /**
     * Reloads templates
     */
    const reloadTemplates = useCallback(async () => {
        setTableLoading(true);

        if (!auth.isAuthenticated()) {
            await auth.authenticate();
        }

        // Re-list the templates, as there were multiple template publication status changes
        setTemplateTableItems([]);
        setSelectedTemplateItem(null);

        await listEditableTemplates();
    }, [listEditableTemplates, auth]);

    /**
     * Creates a draft. Once that is done, reloads the templates
     *
     * Precondition: a template should be selected before this is called
     */
    const createDraftAndReloadTemplates = useCallback(async () => {
        setTableLoading(true);

        if (!auth.isAuthenticated()) {
            await auth.authenticate();
        }

        const createDraftInput: CreateTemplateInput = {
            baseTemplateId: selectedTemplateItem.id,
        };
        await createDraft({ variables: { input: createDraftInput } });

        // Opt not to wait for this call to finish. This lets the table show a "loading" icon
        reloadTemplates();
    }, [createDraft, selectedTemplateItem, reloadTemplates, auth]);

    /**
     * Calls publish template with the state defined in the component. Once the publication is done,
     * reloads all templates. Used as the "onConfirm" action for the "PublishTemplateModal"
     *
     * Precondition: `publishTemplateInput` should be set before this is called
     */
    const publishTemplateAndReloadTemplates = useCallback(async () => {
        setTableLoading(true);

        if (!auth.isAuthenticated()) {
            await auth.authenticate();
        }

        await publishTemplate({ variables: { input: publishTemplateInput } });

        reloadTemplates();
    }, [publishTemplate, publishTemplateInput, reloadTemplates, auth]);

    useEffect(() => {
        if (isAuthenticated) {
            listEditableTemplates();
        }
        // Do this just on the first render. If we include `listEditableTemplates` as a dep, then the unit tests will result in
        // the function being called on every render, since the mocked function is apparently different on every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Allows searching by type (MRA2), name (Migration Readiness...), version (V3.5), or status (draft, published, primary)
     * @param item - the row in the table
     * @param filteringText - the text that the user is searching
     * @returns whether this item meets the search criteria
     */
    const filterFunction = (item: TemplateTableItem, filteringText: string): boolean => {
        // Checks status filter
        if (selectedTemplateStatus === TemplateStatusFilterOption.Published.toString()) {
            if (!item.isPublishedForType && !item.isPublishedForVersion) {
                return false;
            }
        }

        if (selectedTemplateStatus === TemplateStatusFilterOption.PublishedAndLatestDrafts.toString()) {
            const isPublished = item.isPublishedForType || item.isPublishedForVersion;
            const isLatestDraft = latestDraftSet.has(item.id);

            if (!isPublished && !isLatestDraft) {
                return false;
            }
        }

        // Check search text
        const rowItem = [item.type, item.name, item.version];
        // Use the published status to determine additional text associated with the template
        if (item.isPublishedForType) {
            rowItem.push(appLabels.manage_templates.template.statuses.primary);
        }
        if (item.isPublishedForVersion) {
            rowItem.push(appLabels.manage_templates.template.statuses.published_for_version + item.version);
        }
        if (latestDraftSet.has(item.id)) {
            rowItem.push(appLabels.manage_templates.template.statuses.latest_draft_for_version + item.version);
        }
        if (!item.isPublishedForType && !item.isPublishedForVersion) {
            rowItem.push(appLabels.manage_templates.template.statuses.draft);
        }
        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItem.some((rowValue) => rowValue?.toLocaleLowerCase().trim().includes(filteringTextLowerCase));
    };

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_LIST_TEMPLATES,
    });

    /**
     * Callback for all links in the manage templates table
     */
    const onFollow = useCallback(
        (event: CustomEvent<BaseNavigationDetail>, item: TemplateTableItem) => {
            // Unload any current template
            dispatch(unloadCurrentTemplate());

            // Load the retrieved metadata
            dispatch(loadTemplateMetadata(retrievedTemplateMetadata.find((metadata) => metadata.templateId === item.id)));

            // preventDefault means that React won't reload the whole webpage. It'll use `Routes.tsx` to just
            // reload the component
            event.preventDefault();
            if (event.detail.href) {
                history.push(event.detail.href);
            }
        },
        [dispatch, history, retrievedTemplateMetadata]
    );

    const columnDefs: TableProps.ColumnDefinition<TemplateTableItem>[] = [
        {
            id: 'name',
            header: appLabels.manage_templates.template.name,
            cell: (e) => (
                <Link
                    href={generateTemplateModuleUrl(e.id, locale)}
                    onFollow={(event) => {
                        onFollow(event, e);
                    }}
                >
                    {e.name || appLabels.side_navigation.missing_name}
                </Link>
            ),
            width: '25%',
        },
        {
            id: 'type',
            header: appLabels.manage_templates.template.type,
            cell: (e) => e.type,
            width: '10%',
        },
        {
            id: 'version',
            header: appLabels.manage_templates.template.version,
            cell: (e) => e.version,
            width: '10%',
        },
        {
            id: 'status',
            header: appLabels.manage_templates.template.status,
            cell: (e) => {
                if (e.isPublishedForType) {
                    return (
                        <Popover
                            header={appLabels.manage_templates.template.statuses.primary}
                            content={appLabels.manage_templates.template.statuses.primary_popover_content}
                        >
                            <StatusIndicator type='success'>{appLabels.manage_templates.template.statuses.primary}</StatusIndicator>
                        </Popover>
                    );
                }
                if (e.isPublishedForVersion) {
                    return (
                        <Popover
                            header={appLabels.manage_templates.template.statuses.published_for_version + e.version}
                            content={appLabels.manage_templates.template.statuses.published_for_version_popover_content}
                        >
                            <StatusIndicator type='success'>
                                {appLabels.manage_templates.template.statuses.published_for_version + e.version}
                            </StatusIndicator>
                        </Popover>
                    );
                }
                if (latestDraftSet.has(e.id)) {
                    return (
                        <StatusIndicator type='pending'>
                            {appLabels.manage_templates.template.statuses.latest_draft_for_version + e.version}
                        </StatusIndicator>
                    );
                }
                return <StatusIndicator type='in-progress'>{appLabels.manage_templates.template.statuses.draft}</StatusIndicator>;
            },
            width: '20%',
        },
        {
            id: 'createdAt',
            header: appLabels.manage_templates.template.createdAt,
            // TODO: make timestamp match Polaris standards: https://cloudscape.aws.dev/patterns/general/timestamps/
            // Need to find library/implementation that returns the correctly formatted timestamp
            cell: (e) => new Date(e.createdAt * 1000).toLocaleString(locale, { dateStyle: 'long', timeStyle: 'long' }), // timestamp in seconds. convert to ms
            sortingComparator: (a, b) => (a.createdAt > b.createdAt ? -1 : 1),
            sortingField: 'createdAt',
            width: '20%',
        },
        {
            id: 'actions',
            header: appLabels.common.actions,
            cell: (e) => (
                // Three icons for: editing template name, configuring, and sharing
                <SpaceBetween direction='horizontal' size='xs'>
                    <Link
                        href={generateTemplateUrlWithSuffix(e.id, Paths.UPDATE_TEMPLATE_INFO_PATH_SUFFIX, locale)}
                        onFollow={(event) => {
                            onFollow(event, e);
                        }}
                        data-testid='update-template-info-icon-link'
                    >
                        <Icon name='edit' variant='link' />
                    </Link>
                    <Link
                        href={generateTemplateUrlWithSuffix(e.id, Paths.CONFIGURE_TEMPLATE_PATH_SUFFIX, locale)}
                        onFollow={(event) => {
                            onFollow(event, e);
                        }}
                        data-testid='configure-template-icon-link'
                    >
                        <Icon name='settings' variant='link' />
                    </Link>
                    <Link
                        href={generateTemplateUrlWithSuffix(e.id, Paths.SHARE_TEMPLATE_PATH_SUFFIX, locale)}
                        onFollow={(event) => {
                            onFollow(event, e);
                        }}
                        data-testid='share-template-icon-link'
                    >
                        <Icon name='share' variant='link' />
                    </Link>
                </SpaceBetween>
            ),
            width: '10%',
        },
    ];
    const { items, collectionProps, filterProps, paginationProps } = useCollection(templateTableItems, {
        filtering: {
            empty: <EmptyTable title={appLabels.manage_templates.table.empty} subtitle={''} />,
            noMatch: <EmptyTable title={appLabels.manage_templates.table.no_match} subtitle={''} />,
            filteringFunction: filterFunction,
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columnDefs.find((column) => column.id === 'createdAt'),
            },
        },
    });

    const tableOptionsSetting = () => [
        { label: `10 ${appLabels.manage_templates.template.templates}`, value: 10 },
        { label: `25 ${appLabels.manage_templates.template.templates}`, value: 25 },
        { label: `50 ${appLabels.manage_templates.template.templates}`, value: 50 },
        { label: `100 ${appLabels.manage_templates.template.templates}`, value: 100 },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.manage_templates.table.templates_per_page, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.manage_templates.table.preferences}
        />
    );

    const onCreateTemplateClick = useCallback(() => {
        rumClient.recordClick(`${Paths.MANAGE_TEMPLATES_PATH}/btn-create-template`);
        history.push(Paths.CREATE_TEMPLATE_PATH);
    }, [history]);

    const onCreateAssessmentTypeClick = useCallback(() => {
        rumClient.recordClick(`${Paths.MANAGE_TEMPLATES_PATH}/btn-create-assessment-type`);
        setCreateAssessmentTypeModalVisible(true);
    }, []);

    const tableHeader = (
        <Header
            counter={`(${templateTableItems.length})`}
            actions={
                <Box float='right'>
                    {/* https://cloudscape.aws.dev/foundation/visual-foundation/spacing/#spacing-scale */}
                    <SpaceBetween size='xs' direction='horizontal'>
                        <Button
                            variant='normal'
                            iconName='refresh'
                            onClick={() => {
                                rumClient.recordClick(`${Paths.MANAGE_TEMPLATES_PATH}/btn-refresh-templates`);
                                reloadTemplates();
                            }}
                            data-testid='btn-refresh-templates'
                        />
                        <ButtonDropdown
                            items={[
                                {
                                    text: appLabels.manage_templates.actions.create_draft,
                                    id: 'create-draft',
                                    disabled: !canCreateDraft,
                                    disabledReason: reasonIfCannotCreateDraft,
                                },
                                {
                                    text: appLabels.manage_templates.actions.publish_for_version,
                                    id: 'publish-for-version',
                                    disabled: !canPublishSelectedTemplateForVersion,
                                    disabledReason: reasonIfCannotPublishForVersion,
                                },
                                {
                                    text: appLabels.manage_templates.actions.set_primary_template,
                                    id: 'set-primary-template',
                                    disabled: !canPublishSelectedTemplateForType,
                                    disabledReason: reasonIfCannotPublishForType,
                                },
                            ]}
                            onItemClick={({ detail }) => {
                                switch (detail.id) {
                                    case 'create-draft': {
                                        createDraftAndReloadTemplates();
                                        break;
                                    }
                                    case 'publish-for-version': {
                                        const publishForVersionInput: PublishTemplateInput = {
                                            templateId: selectedTemplateItem.id,
                                        };
                                        setPublishTemplateInput(publishForVersionInput);
                                        setPublishTemplateModalVisible(true);
                                        break;
                                    }
                                    case 'set-primary-template': {
                                        const setPrimaryTemplateInput: PublishTemplateInput = {
                                            templateId: selectedTemplateItem.id,
                                            publishForType: true,
                                        };
                                        setPublishTemplateInput(setPrimaryTemplateInput);
                                        setPublishTemplateModalVisible(true);
                                        break;
                                    }
                                }
                            }}
                            data-testid='btn-dropdown-actions'
                        >
                            {appLabels.common.actions}
                        </ButtonDropdown>
                        <ButtonDropdown
                            variant='primary'
                            mainAction={{ text: appLabels.manage_templates.create_assessment_type.label, onClick: onCreateAssessmentTypeClick }}
                            items={[
                                {
                                    text: appLabels.manage_templates.actions.create_template,
                                    id: 'create-template',
                                },
                            ]}
                            onItemClick={({ detail: { id } }) => {
                                switch (id) {
                                    case 'create-template': {
                                        onCreateTemplateClick();
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            }}
                            data-testid='btn-create-template'
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            {templateTableItems.length === 1 ? appLabels.manage_templates.template.template : appLabels.manage_templates.template.templates}
        </Header>
    );

    useEffect(() => {
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.table.help_panel.header,
                content: <AdminManageTemplatesHelpPanel />,
            })
        );

        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
    }, [dispatch, appLabels.manage_templates.table.help_panel.header]);

    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <SpaceBetween direction='vertical' size='m'>
                <Header variant='h1'>{appLabels.manage_templates.table.page_title}</Header>
                <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
                <Table
                    {...collectionProps}
                    trackBy='id'
                    header={tableHeader}
                    columnDefinitions={columnDefs}
                    items={items}
                    loadingText={appLabels.manage_templates.table.loading_templates}
                    loading={tableLoading}
                    filter={
                        <div className='filter-container'>
                            <div className='text-filter'>
                                <TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.manage_templates.actions.find_template} />
                            </div>
                            <div className='select-filter'>
                                <FormField label={appLabels.manage_templates.table.filter_status}>
                                    <Select
                                        data-testid='status-filter'
                                        options={templateStatusFilterOptions}
                                        selectedOption={templateStatusFilterOptions.find((option) => option.value === selectedTemplateStatus)}
                                        onChange={(event) => {
                                            setSelectedTemplateStatus(event.detail.selectedOption.value);
                                        }}
                                    />
                                </FormField>
                            </div>
                        </div>
                    }
                    onSelectionChange={({ detail }) => setSelectedTemplateItem(detail.selectedItems.at(0))}
                    selectedItems={selectedTemplateItem ? [selectedTemplateItem] : []}
                    preferences={tablePreferences}
                    pagination={<Pagination {...paginationProps} />}
                    resizableColumns={true}
                    selectionType='single'
                    data-testid='templates-table'
                />
            </SpaceBetween>
            <PublishTemplateModal
                visible={publishTemplateModalVisible}
                setVisible={setPublishTemplateModalVisible}
                onConfirm={publishTemplateAndReloadTemplates}
            />
            <CreateAssessmentTypeModal visible={createAssessmentTypeModalVisible} setVisible={setCreateAssessmentTypeModalVisible} />
        </div>
    );
};

export default withLocalizationContext(withAuthContext(withAppLabelsContext(AdminManageTemplates)));
