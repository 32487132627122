import { RecommendationOutput, Status, UpdateRecommendationAssignmentsCommand } from '@amzn/aws-assessment-recommendation-service-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Alert, Box, Button, Header, SpaceBetween, Spinner, Table } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import CreateOrEditCustomRecommendationsModal from './CreateOrEditRecommendationModal';
import DeleteRecommendationsModal from './DeleteRecommendationsModal';
import { getRecommendationTableColumnDefs } from './RecommendationConstants';
import { updateRecommendationsSuccess } from './RecommendationsSlice';
import SelectPrescribedRecommendationsModal from './SelectPrescribedRecommendationsModal';
import { getRecommendationServiceClient } from '../../../../../../api/recommendationService/RecommendationServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import Constants from '../../../../../../common/Constants';
import rumClient from '../../../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';
import { useAppSelector } from '../../../../../redux/hooks';
import { reportIsReadOnly } from '../../../../Utils';

export interface RecommendationsTableProps {
    assessmentId: string;
    categoryId: string;
    recommendations: RecommendationOutput[];
    loading: boolean;
}

interface CreateOrEditRecommendationsModalState {
    visible: boolean;
    isCreateOrEdit: 'create' | 'edit';
}

const RecommendationsTable = (props: RecommendationsTableProps & AppLabelsContextInterface & AuthContextInterface): JSX.Element => {
    const errorMessage = useAppSelector((state) => state.recommendationsState.errorMessage);
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const assessmentType = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.type);

    const myUserId = props.auth?.getUserInfo()?.userId;
    const isReadOnly = reportIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);
    const recommendations = props.recommendations;
    const [selectPrescribedRecommendationsModalVisible, setSelectPrescribedRecommendationsModalVisible] = useState(false);
    const [createOrEditRecommendationModalState, setCreateOrEditRecommendationModalState] = useState<CreateOrEditRecommendationsModalState>({
        visible: false,
        isCreateOrEdit: 'create',
    });
    const [deleteRecommendationsModalVisible, setDeleteRecommendationsModalVisible] = useState(false);

    const activeRecommendations = recommendations?.filter((recommendation) => recommendation.status === Status.ACTIVE) || [];
    const inactiveRecommendations = recommendations?.filter((recommendation) => recommendation.status === Status.INACTIVE) || [];

    const columnDefinitions = getRecommendationTableColumnDefs(props.appLabels, isReadOnly);
    const { items, collectionProps, actions } = useCollection<RecommendationOutput>(activeRecommendations, {
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[1], // By default, sort by priority
                isDescending: false,
            },
        },
        selection: {},
    });
    const setSelectedItems = actions.setSelectedItems;
    const selectedItems = collectionProps.selectedItems;
    const setSorting = actions.setSorting;
    const dispatch = useDispatch();
    const [inlineEditingErrorMessage, setInlineEditingErrorMessage] = useState('');

    const selectPrescribedRecommendationsModal = selectPrescribedRecommendationsModalVisible ? (
        <SelectPrescribedRecommendationsModal
            prescribedRecommendations={inactiveRecommendations}
            onDismiss={() => setSelectPrescribedRecommendationsModalVisible(false)}
            assessmentId={props.assessmentId}
            assessmentType={assessmentType}
            categoryId={props.categoryId}
        />
    ) : null;
    const createOrEditRecommendationModal = createOrEditRecommendationModalState.visible ? (
        <CreateOrEditCustomRecommendationsModal
            isCreateOrEdit={createOrEditRecommendationModalState.isCreateOrEdit}
            recommendationToCreateOrEdit={createOrEditRecommendationModalState.isCreateOrEdit === 'create' ? null : selectedItems[0]}
            onDismiss={() => {
                setCreateOrEditRecommendationModalState({ ...createOrEditRecommendationModalState, visible: false });
            }}
            assessmentId={props.assessmentId}
            assessmentType={assessmentType}
            categoryId={props.categoryId}
        />
    ) : null;
    const deleteRecommendationsModal = deleteRecommendationsModalVisible ? (
        <DeleteRecommendationsModal
            recommendationsToDelete={selectedItems}
            onDismiss={() => {
                setDeleteRecommendationsModalVisible(false);
                setSelectedItems([]);
            }}
            assessmentId={props.assessmentId}
            categoryId={props.categoryId}
        />
    ) : null;
    if (props.loading) {
        return <Spinner size='normal'></Spinner>;
    }
    if (activeRecommendations.length === 0) {
        return (
            <SpaceBetween direction='vertical' size='s'>
                <Box className='recommended-actions-section'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <Button
                            iconName='add-plus'
                            onClick={() => setCreateOrEditRecommendationModalState({ visible: true, isCreateOrEdit: 'create' })}
                            disabled={isReadOnly}
                        >
                            {props.appLabels.assessment.results.review_all.report.recommended_actions.grid.button_add_custom_action}
                        </Button>
                        <Button
                            iconName='add-plus'
                            onClick={() => setSelectPrescribedRecommendationsModalVisible(true)}
                            disabled={isReadOnly || !inactiveRecommendations.length}
                        >
                            {props.appLabels.assessment.results.review_all.report.recommended_actions.grid.button_add_prescribed_action}
                        </Button>
                    </SpaceBetween>
                    {selectPrescribedRecommendationsModal}
                    {createOrEditRecommendationModal}
                </Box>
                {errorMessage ? (
                    <Alert type='error' header={props.appLabels.assessment.results.review_all.report.recommended_actions.failed_load_recommendations}>
                        {errorMessage}
                    </Alert>
                ) : null}
            </SpaceBetween>
        );
    }
    return (
        <Box>
            <Table
                columnDefinitions={columnDefinitions}
                items={items}
                resizableColumns
                selectionType='multi'
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                selectedItems={selectedItems}
                isItemDisabled={() => isReadOnly}
                footer={
                    inlineEditingErrorMessage ? (
                        <Alert
                            type='error'
                            header={inlineEditingErrorMessage}
                            dismissible={true}
                            onDismiss={() => setInlineEditingErrorMessage('')}
                        ></Alert>
                    ) : null
                }
                submitEdit={async (item, column, newValue) => {
                    const rsClient = getRecommendationServiceClient();
                    const resourceId = `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${props.assessmentId}`;
                    const recommendationToUpdate = {
                        resourceId,
                        recommendationId: item.recommendationId,
                        [column.id]: newValue,
                    };
                    try {
                        await rsClient.send(
                            new UpdateRecommendationAssignmentsCommand({
                                recommendationAssignments: [recommendationToUpdate],
                                resourceId,
                            })
                        );
                        setInlineEditingErrorMessage('');
                        dispatch(
                            updateRecommendationsSuccess({
                                recommendationsToUpdate: [
                                    {
                                        ...item,
                                        ...recommendationToUpdate,
                                    },
                                ],
                            })
                        );
                    } catch (err) {
                        setInlineEditingErrorMessage((err as Error).message);
                        rumClient.recordError(err);
                        throw err;
                    }
                }}
                onSortingChange={({ detail }) => {
                    setSorting(detail);
                }}
                sortingColumn={collectionProps.sortingColumn}
                sortingDescending={collectionProps.sortingDescending}
                header={
                    <Header
                        actions={
                            <SpaceBetween direction='horizontal' size='s'>
                                <Button
                                    onClick={() => setDeleteRecommendationsModalVisible(true)}
                                    disabled={isReadOnly || selectedItems?.length === 0}
                                >
                                    {props.appLabels.user_actions.delete}
                                </Button>
                                <Button
                                    onClick={() => setCreateOrEditRecommendationModalState({ visible: true, isCreateOrEdit: 'edit' })}
                                    disabled={isReadOnly || selectedItems?.length !== 1}
                                >
                                    {props.appLabels.user_actions.edit}
                                </Button>
                                <Button
                                    iconName='add-plus'
                                    onClick={() => setCreateOrEditRecommendationModalState({ visible: true, isCreateOrEdit: 'create' })}
                                    disabled={isReadOnly}
                                >
                                    {props.appLabels.assessment.results.review_all.report.recommended_actions.grid.button_add_custom_action}
                                </Button>
                                <Button
                                    iconName='add-plus'
                                    onClick={() => setSelectPrescribedRecommendationsModalVisible(true)}
                                    disabled={isReadOnly || !inactiveRecommendations.length}
                                >
                                    {props.appLabels.assessment.results.review_all.report.recommended_actions.grid.button_add_prescribed_action}
                                </Button>
                            </SpaceBetween>
                        }
                        counter={
                            selectedItems?.length
                                ? `(${selectedItems?.length}/${activeRecommendations?.length})`
                                : `(${activeRecommendations?.length})`
                        }
                    >
                        {props.appLabels.assessment.results.review_all.report.recommended_actions.recommended_actions}
                    </Header>
                }
                stickyColumns={{ first: 1, last: 0 }}
            />
            {selectPrescribedRecommendationsModal}
            {deleteRecommendationsModal}
            {createOrEditRecommendationModal}
        </Box>
    );
};

export default withAuthContext(withLocalizationContext(withAppLabelsContext(RecommendationsTable)));
