import { RecommendationOutput } from '@amzn/aws-assessment-recommendation-service-client';
import { AssessmentActivity, AssessmentSection, AssessmentWorkstream } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { generatePptxActionsForCategory, getPptxActionsStringForCategory } from './ReportHelpers';
import { ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS, ASSESSMENT_TYPES_WITH_TEMPLATES } from '../../../api/rgs/RgsClient';
import { AppLabels } from '../../../common/AppLabels';
import Constants from '../../../common/Constants';
import { toFixedDigits } from '../../../common/Utils';
import { compareScores } from '../../../common/score/ScoreUtils';
import { AssessmentViewModel } from '../../../components/assessments/AssessmentViewModel';
import { QuestionResults, getMaxRatingInAssessment, getQuestionResults } from '../../../components/assessments/Utils';
import { filterExcludedSections, getCommentsWithRatingGuide } from '../../../components/assessments/results/Utils';
import { Colorizer } from '../Colorizer';
import { addCustomizations, generateReport } from '../GenerateReport';

export interface FitScores {
    amsFitScore: number;
}

// TODO: Add unit tests for the GenerateXXXReport files. SIM: https://issues.amazon.com/issues/A2T-2108

function workstreamsActivitiesHeatmap(workstreams: AssessmentWorkstream[], appLabels: AppLabels, colorizer: Colorizer) {
    const params: { [key: string]: any } = {};
    for (const workstream of workstreams) {
        const workstreamData = {
            color: colorizer.colorize(workstream),
            // replace_text replaces the text placeholder with provided text.
            // E.g. "[FILL-business-case]" --> "Business Case"
            replace_text: `${appLabels.assessment.results.generate_report.parameters.average_score}`,
            fontSize: 11,
            bold: true,
            // Group activities by workstream
            group: workstream?.name,
        };
        if (workstream?.id) params[`[FILL-${workstream?.id}]`] = workstreamData;
        // Generic templates can use placeholders with any language
        else params[`[FILL-${workstream?.name}]`] = workstreamData;

        for (const activity of workstream?.activities || []) {
            const activityColor = colorizer.colorize(activity);
            const activityName = activity?.name;
            const data = {
                color: activityColor,
                replace_text: activityName,
                fontSize: 11,
                group: workstream?.name || activityName,
            };
            if (activity?.id) params[`[FILL-${activity?.id}]`] = data;
            else params[`[FILL-${activity?.name}]`] = data;
        }
    }

    return params;
}

function sectionsCategoriesHeatmap(sections: AssessmentSection[], appLabels: AppLabels, colorizer: Colorizer) {
    // Section aka Perspective
    const params: { [key: string]: any } = {};
    for (const section of sections) {
        const sectionData = {
            color: colorizer.colorize(section),
            replace_text: `${appLabels.assessment.results.generate_report.parameters.average_score}`,
            fontSize: 11,
            bold: true,
            // Group categories by section
            group: section?.label,
        };
        if (section?.id) params[`[FILL-${section?.id}]`] = sectionData;
        else params[`[FILL-${section?.label}]`] = sectionData;

        for (const category of section?.categories || []) {
            const categoryColor = colorizer.colorize(category);
            const categoryName = category?.name;
            const data = {
                color: categoryColor,
                replace_text: categoryName,
                fontSize: 11,
                group: section?.label || categoryName,
            };
            if (category?.id) params[`[FILL-${category?.id}]`] = data;
            else params[`[FILL-${category?.name}]`] = data;
        }
    }

    return params;
}

function workstreamsRecommendedActionsTable(
    workstreams: AssessmentWorkstream[],
    appLabels: AppLabels,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    // Generic templates need tables showing action/owner/target date
    const params: { [key: string]: any } = {};

    // Tables - one for each workstream
    for (const workstream of workstreams) {
        const tableParams = {
            specifier: 'RecommendedActions',
            title: workstream?.name,
            columns: [
                appLabels.assessment.results.generate_report.parameters.action,
                appLabels.assessment.results.generate_report.parameters.priority,
                appLabels.assessment.results.generate_report.parameters.effort,
                appLabels.assessment.results.generate_report.parameters.target_date,
                appLabels.assessment.results.generate_report.parameters.owner,
            ],
            fontSize: 14,
            // Table dimensions
            colWidth: [7, 2, 2, 2, 2],
            rowHeight: 0.75,
        };
        const tableData: string[][] = [];
        for (const activity of workstream?.activities || []) {
            tableData.push(...generatePptxActionsForCategory(activity, rsIsEnabled, refIdToRecommendationsMap[activity.id], appLabels));
        }
        tableParams['data'] = tableData;

        if (workstream?.id) params[`[TABLE-${workstream?.id}-RecommendedActions]`] = tableParams;
        else params[`[TABLE-${workstream?.name}-RecommendedActions]`] = tableParams;
    }

    return params;
}

function sectionsRecommendedActionsTable(
    sections: AssessmentSection[],
    appLabels: AppLabels,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    // Generic templates need tables showing action/owner/target date
    const params: { [key: string]: any } = {};

    // Tables - one for each section
    for (const section of sections) {
        const tableParams = {
            specifier: 'RecommendedActions',
            title: section?.label,
            columns: [
                appLabels.assessment.results.generate_report.parameters.action,
                appLabels.assessment.results.generate_report.parameters.priority,
                appLabels.assessment.results.generate_report.parameters.effort,
                appLabels.assessment.results.generate_report.parameters.target_date,
                appLabels.assessment.results.generate_report.parameters.owner,
            ],
            fontSize: 14,
            // Table dimensions
            colWidth: [7, 2, 2, 2, 2],
            rowHeight: 0.75,
        };
        const tableData: string[][] = [];
        for (const category of section?.categories || []) {
            tableData.push(...generatePptxActionsForCategory(category, rsIsEnabled, refIdToRecommendationsMap[category.id], appLabels));
        }
        tableParams['data'] = tableData;

        if (section?.id) params[`[TABLE-${section?.id}-RecommendedActions]`] = tableParams;
        else params[`[TABLE-${section?.label}-RecommendedActions]`] = tableParams;
    }

    return params;
}

function workstreamsObservationsActionsTable(
    workstreams: AssessmentWorkstream[],
    appLabels: AppLabels,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    const categoryLabel = appLabels.assessment.results.generate_report.parameters.category;
    const summaryObservationsLabel = appLabels.assessment.results.generate_report.parameters.summary_observations;
    const actionsAndNextStepsLabel = appLabels.assessment.results.generate_report.parameters.actions_and_next_steps;

    // Assessment-specific templates use text placeholders, while generic templates use tables
    const params: { [key: string]: any } = {};

    // Text placeholders
    for (const workstream of workstreams) {
        for (const activity of workstream?.activities || []) {
            params[`[${activity?.id} Observations]`] = activity?.observations?.observations || '';
            // Get all text from Actions and join it
            const actionsString = getPptxActionsStringForCategory(activity, rsIsEnabled, refIdToRecommendationsMap[activity.id], appLabels);
            params[`[${activity?.id} Actions]`] = actionsString;
        }
    }

    // Table. One table per section, one entry per category
    for (const workstream of workstreams) {
        const tableParams = {
            specifier: 'ObservationsActions',
            title: workstream?.name,
            columns: [categoryLabel, summaryObservationsLabel, actionsAndNextStepsLabel],
            fontSize: 14,
            // Table dimensions
            colWidth: [3.4, 4.6, 6.5],
            rowHeight: 0.55,
        };
        const data: string[][] = [];
        for (const activity of workstream?.activities || []) {
            const activityName = activity?.name || '';
            const observations = activity?.observations?.observations || '';

            const actionsText = getPptxActionsStringForCategory(activity, rsIsEnabled, refIdToRecommendationsMap[activity.id], appLabels);
            const entry: string[] = [activityName, observations, actionsText];
            data.push(entry);
        }
        tableParams['data'] = data;

        if (workstream?.id) params[`[TABLE-${workstream?.id}-ActionsObservations]`] = tableParams;
        else params[`[TABLE-${workstream?.name}-ActionsObservations]`] = tableParams;
    }

    return params;
}

function sectionsObservationsActionsTable(
    sections: AssessmentSection[],
    appLabels: AppLabels,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    const categoryLabel = appLabels.assessment.results.generate_report.parameters.category;
    const summaryObservationsLabel = appLabels.assessment.results.generate_report.parameters.summary_observations;
    const actionsAndNextStepsLabel = appLabels.assessment.results.generate_report.parameters.actions_and_next_steps;

    // Assessment-specific templates use text placeholders, while generic templates use tables
    const params: { [key: string]: any } = {};

    // Text placeholders
    for (const section of sections) {
        for (const category of section?.categories || []) {
            params[`[${category?.id} Observations]`] = category?.observations?.observations || '';
            // Get all text from Actions and join it
            const actionsString = getPptxActionsStringForCategory(category, rsIsEnabled, refIdToRecommendationsMap[category.id], appLabels);
            params[`[${category?.id} Actions]`] = actionsString;
        }
    }

    // Table. One table per section, one entry per category
    for (const section of sections) {
        const tableParams = {
            specifier: 'ObservationsActions',
            title: section?.label,
            columns: [categoryLabel, summaryObservationsLabel, actionsAndNextStepsLabel],
            fontSize: 14,
            // Table dimensions
            colWidth: [3.4, 4.6, 6.5],
            rowHeight: 0.55,
        };
        const data: string[][] = [];
        for (const category of section?.categories || []) {
            const categoryName = category?.name || '';
            const observations = category?.observations?.observations || '';
            const actionsString = getPptxActionsStringForCategory(category, rsIsEnabled, refIdToRecommendationsMap[category.id], appLabels);
            const entry: string[] = [categoryName, observations, actionsString];
            data.push(entry);
        }
        tableParams['data'] = data;

        if (section?.id) params[`[TABLE-${section?.id}-ActionsObservations]`] = tableParams;
        else params[`[TABLE-${section?.label}-ActionsObservations]`] = tableParams;
    }

    return params;
}

function questionsCommentsTable(assessment: AssessmentViewModel, appLabels: AppLabels) {
    const questionLabelIntl = appLabels.assessment.results.generate_report.parameters.question;
    const commentsLabel = appLabels.assessment.results.generate_report.parameters.comments;
    const responseLabel = appLabels.assessment.results.generate_report.parameters.response;

    const label = 'Q';
    const params: { [key: string]: any } = {};

    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels);
    // Text placeholders
    for (const question of questionResults) {
        const questionLabel = `${question?.questionNumber}`;
        params[`[${question.promptId}]`] = `Q${questionLabel}`;
        params[`[${question.promptId} Question]`] = question?.questionText;
        params[`[${question.promptId} Comments]`] = getCommentsWithRatingGuide(question);
        params[`[${question.promptId} Rating]`] = question?.response;
    }

    // Table - make a table per category
    for (const section of assessment.template?.sections || []) {
        for (const category of section?.categories || []) {
            const tableParams = {
                specifier: 'QuestionsComments',
                title: `${section?.label} - ${category?.name}`,
                columns: ['#', questionLabelIntl, responseLabel, commentsLabel],
                fontSize: 14,
                // Table dimensions
                colWidth: [0.75, 5, 1.1, 8],
                rowHeight: 0.55,
            };
            const tableData = [];

            // Get entries for each question in this category
            const categoryQuestions = questionResults.filter(
                (question) => question?.sectionLabel === section?.label && question?.categoryName === category?.name
            );
            for (const question of categoryQuestions) {
                tableData.push([
                    `${label}${question.questionNumber}`,
                    question.questionText,
                    question.response,
                    getCommentsWithRatingGuide(question),
                ]);
            }
            tableParams['data'] = tableData;

            if (section?.id && category?.id) {
                params[`[TABLE-${section?.id}-${category?.id}-QuestionsComments]`] = tableParams;
            } else {
                params[`[TABLE-${section?.label}-${category?.name}-QuestionsComments]`] = tableParams;
            }
        }
    }

    return params;
}

function workstreamsQuestionsCommentsTable(assessment: AssessmentViewModel, appLabels: AppLabels) {
    const questionLabelIntl = appLabels.assessment.results.generate_report.parameters.question;
    const commentsLabel = appLabels.assessment.results.generate_report.parameters.comments;
    const responseLabel = appLabels.assessment.results.generate_report.parameters.response;

    const label = 'Q';
    const params: { [key: string]: any } = {};

    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels);
    // Text placeholders
    for (const question of questionResults) {
        const questionLabel = `${question?.questionNumber}`;
        params[`[Q${questionLabel}]`] = `Q${questionLabel}`;
        params[`[Q${questionLabel} Question]`] = question?.questionText;
        params[`[Q${questionLabel} Comments]`] = getCommentsWithRatingGuide(question);
        params[`[Q${questionLabel} Rating]`] = question?.response;
    }

    // Table - make a table per category
    for (const workstream of assessment.template?.workstreams || []) {
        for (const activity of workstream?.activities || []) {
            const tableParams = {
                specifier: 'QuestionsComments',
                title: `${workstream?.name} - ${activity?.name}`,
                columns: ['#', questionLabelIntl, responseLabel, commentsLabel],
                fontSize: 14,
                // Table dimensions
                colWidth: [0.75, 5, 1.1, 8],
                rowHeight: 0.55,
            };
            const tableData = [];

            // Get entries for each question in this category
            const activityQuestions = questionResults.filter(
                (question) => question?.workstreamName === workstream?.name && question?.activityName === activity?.name
            );
            for (const question of activityQuestions) {
                tableData.push([
                    `${label}${question.questionNumber}`,
                    question.questionText,
                    question.response,
                    getCommentsWithRatingGuide(question),
                ]);
            }
            tableParams['data'] = tableData;

            if (workstream?.id && activity?.id) params[`[TABLE-${workstream?.id}-${activity?.id}-QuestionsComments]`] = tableParams;
            else params[`[TABLE-${workstream?.name}-${activity?.name}-QuestionsComments]`] = tableParams;
        }
    }

    return params;
}

const getAMSParameters = (fitScore: number, appLabels: AppLabels) => {
    const amazonOrange = '0xFF9B00';
    const orangeDial = {
        color: amazonOrange,
        replace_text: '',
    };
    const gray = '0xD9D9D9';
    const grayDial = {
        color: gray,
        replace_text: '',
    };

    const params = {};
    params['[AMS Fit Score]'] = fitScore.toLocaleString(undefined, { style: 'percent' });

    // The dial has three bars. Round up when determining which bars should be filled. E.g. Score = 10% --> fill in first bar
    if (fitScore >= 0 && fitScore < 1 / 3) {
        params['[FILL-AMS 1]'] = orangeDial;
        params['[FILL-AMS 2]'] = grayDial;
        params['[FILL-AMS 3]'] = grayDial;
        params['[AMS Explanation]'] = appLabels.assessment.results.generate_report.parameters.low_ams_score;
    } else if (fitScore > 1 / 3 && fitScore < 2 / 3) {
        params['[FILL-AMS 1]'] = orangeDial;
        params['[FILL-AMS 2]'] = orangeDial;
        params['[FILL-AMS 3]'] = grayDial;
        params['[AMS Explanation]'] = appLabels.assessment.results.generate_report.parameters.medium_ams_score;
    } else if (fitScore > 2 / 3) {
        params['[FILL-AMS 1]'] = orangeDial;
        params['[FILL-AMS 2]'] = orangeDial;
        params['[FILL-AMS 3]'] = orangeDial;
        params['[AMS Explanation]'] = appLabels.assessment.results.generate_report.parameters.high_ams_score;
    }

    return params;
};

const getOfferingParameters = (fitScores: FitScores, appLabels: AppLabels) => {
    const params = {
        ...getAMSParameters(fitScores.amsFitScore, appLabels),
    };

    return params;
};

const basicAssessmentData = (
    presenter: string,
    organizationName: string,
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
): { [key: string]: any } => {
    let params: { [key: string]: any } = {};
    params['[Customer Name]'] = assessment.accountCustomerName;
    params['[Organization Name]'] = organizationName;
    params['[Assessment Type]'] = assessment.template?.title || '';
    params['[Assessment Code]'] = assessment.type;
    params['[Date]'] = new Date().toDateString();
    params['[Year]'] = new Date().getFullYear();
    params['[Presenter]'] = presenter;

    const workstreams: AssessmentWorkstream[] = assessment.template?.workstreams || [];
    const sections: AssessmentSection[] = assessment.template?.sections || [];

    if (workstreams.length > 0) {
        params = {
            ...params,
            // Add recommended actions
            ...workstreamsRecommendedActionsTable(workstreams, appLabels, rsIsEnabled, refIdToRecommendationsMap),
            // Add actions/observations
            ...workstreamsObservationsActionsTable(workstreams, appLabels, rsIsEnabled, refIdToRecommendationsMap),
            // Add questions/comments - for sections and workstreams
            ...questionsCommentsTable(assessment, appLabels),
            ...workstreamsQuestionsCommentsTable(assessment, appLabels),
        };

        // Add heatmap if not disabled
        if (!assessment.template?.defaults?.report?.heatmap?.disabled) {
            params = {
                ...params,
                // Add activities heatmap
                ...workstreamsActivitiesHeatmap(workstreams, appLabels, colorizer),
            };
        }
    } else {
        params = {
            ...params,
            // Add recommended actions
            ...sectionsRecommendedActionsTable(sections, appLabels, rsIsEnabled, refIdToRecommendationsMap),
            // Add actions/observations
            ...sectionsObservationsActionsTable(sections, appLabels, rsIsEnabled, refIdToRecommendationsMap),
            // Add questions/comments
            ...questionsCommentsTable(assessment, appLabels),
        };

        // Add heatmap if not disabled
        if (!assessment.template?.defaults?.report?.heatmap?.disabled) {
            params = {
                ...params,
                // Add categories heatmap
                ...sectionsCategoriesHeatmap(sections, appLabels, colorizer),
            };
        }
    }

    return params;
};

const workstreamBarGraph = (
    assessment: AssessmentViewModel,
    workstream: AssessmentWorkstream,
    appLabels: AppLabels,
    colorizer: Colorizer
): { [key: string]: any } => {
    const params: { [key: string]: any } = {};
    const rgsBarChartParams: any = {
        title: `${workstream?.name || ''}`,
        minValue: 0,
        maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
        gapWidth: 0,
        showTicks: false,
        showDataLabels: true,
        dataLabelColor: '0x000000',
        outlineColor: '0x000000',
        fontSize: 12,
        textLength: ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS.includes(assessment.type) ? 1000 : 20,
        // Bar chart dimensions for dynamic generation
        barHeight: 0.25,
        titleHeight: 0.65,
    };
    const barValues = [];
    const barCategories = [];
    const barColors = [];
    const averageScore = workstream?.score || 0;
    barValues.push(parseFloat(toFixedDigits(averageScore, 1)));
    barCategories.push(appLabels.assessment.results.generate_report.parameters.average_score);
    barColors.push(colorizer.colorize({ score: averageScore }));
    for (const activity of workstream?.activities || []) {
        // Values need to have 1 fraction digit
        const score = activity?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
        barValues.push(parseFloat(score));
        barCategories.push(activity.name);
        barColors.push(colorizer.colorize(activity));
    }

    rgsBarChartParams['categories'] = barCategories;
    rgsBarChartParams['values'] = barValues;
    rgsBarChartParams['barColors'] = barColors;
    if (workstream?.id) params[`[BAR-${workstream?.id}]`] = rgsBarChartParams;
    else params[`[BAR-${workstream?.name}]`] = rgsBarChartParams;
    return params;
};

/**
 * Sets up parameters for a bar graph with workstream averages displayed
 * @param assessment The assessment for which the bar graph is being generated
 * @param appLabels Localized labels
 * @param colorizer An object that returns red/yellow/green hex colors based on a score
 * @returns A dict with a key/value pair for a bar graph with all average workstream scores
 */
const averageWorkstreamBarGraph = (assessment: AssessmentViewModel, appLabels: AppLabels, colorizer: Colorizer): { [key: string]: any } => {
    const params: { [key: string]: any } = {};

    // Don't generate this bar chart for generic templates
    if (!ASSESSMENT_TYPES_WITH_TEMPLATES.includes(assessment.type)) return params;

    const rgsBarChartParams = {
        title: appLabels.assessment.results.generate_report.parameters.average_workstream_score,
        minValue: 0,
        maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
        gapWidth: 0,
        showTicks: false,
        showDataLabels: true,
        dataLabelColor: '0x000000',
        outlineColor: '0x000000',
        fontSize: 24,
        textLength: ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS.includes(assessment.type) ? 1000 : 30,
        // Bar chart dimensions for dynamic generation
        barHeight: 0.25,
        titleHeight: 0.65,
    };
    const barValues = [];
    const barCategories = [];
    const barColors = [];
    for (const workstream of assessment.template?.workstreams || []) {
        // Values need to have 1 fraction digit
        const score = workstream?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
        barValues.push(parseFloat(score));
        barCategories.push(workstream?.name);
        barColors.push(colorizer.colorize(workstream));
    }

    rgsBarChartParams['values'] = barValues;
    rgsBarChartParams['categories'] = barCategories;
    rgsBarChartParams['barColors'] = barColors;

    params['[BAR-AverageWorkstreamScores]'] = rgsBarChartParams;

    return params;
};

const workstreamBarGraphs = (
    assessment: AssessmentViewModel,
    workstreams: AssessmentWorkstream[],
    appLabels: AppLabels,
    colorizer: Colorizer
): { [key: string]: any } => {
    let params: { [key: string]: any } = {};
    // Bar charts for workstreams
    for (const workstream of workstreams) {
        params = {
            ...params,
            ...workstreamBarGraph(assessment, workstream, appLabels, colorizer),
            ...averageWorkstreamBarGraph(assessment, appLabels, colorizer),
        };
    }
    return params;
};

const sectionBarGraph = (
    assessment: AssessmentViewModel,
    section: AssessmentSection,
    appLabels: AppLabels,
    colorizer: Colorizer
): { [key: string]: any } => {
    const params: { [key: string]: any } = {};
    const rgsBarChartParams: any = {
        title: `${section?.label || ''}`,
        minValue: 0,
        maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
        gapWidth: 0,
        showTicks: false,
        showDataLabels: true,
        dataLabelColor: '0x000000',
        outlineColor: '0x000000',
        fontSize: 12,
        textLength: ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS.includes(assessment.type) ? 1000 : 20,
        // Bar chart dimensions for dynamic generation
        barHeight: 0.25,
        titleHeight: 0.65,
    };
    const barValues = [];
    const barCategories = [];
    const barColors = [];
    const averageScore = section?.score || 0;
    barValues.push(parseFloat(toFixedDigits(averageScore, 1)));
    barCategories.push(appLabels.assessment.results.generate_report.parameters.average_score);
    barColors.push(colorizer.colorize({ score: averageScore }));
    for (const category of section?.categories || []) {
        // Values need to have 1 fraction digit
        const score = category?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
        barValues.push(parseFloat(score));
        barCategories.push(category?.name);
        barColors.push(colorizer.colorize(category));
    }

    rgsBarChartParams['categories'] = barCategories;
    rgsBarChartParams['values'] = barValues;
    rgsBarChartParams['barColors'] = barColors;
    if (section?.id) params[`[BAR-${section?.id}]`] = rgsBarChartParams;
    else params[`[BAR-${section?.label}]`] = rgsBarChartParams;
    return params;
};

/**
 * Sets up parameters for a bar graph with section averages displayed
 * @param assessment The assessment for which the bar graph is being generated
 * @param appLabels Localized labels
 * @param colorizer An object that returns red/yellow/green hex colors based on a score
 * @returns A dict with a key/value pair for a bar graph with all average section scores
 */
const averageSectionBarGraph = (assessment: AssessmentViewModel, appLabels: AppLabels, colorizer: Colorizer): { [key: string]: any } => {
    const params: { [key: string]: any } = {};

    // Don't generate this bar chart for generic templates
    if (!ASSESSMENT_TYPES_WITH_TEMPLATES.includes(assessment.type)) return params;

    const rgsBarChartParams = {
        title: appLabels.assessment.results.generate_report.parameters.average_score,
        minValue: 0,
        maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
        gapWidth: 0,
        showTicks: false,
        showDataLabels: true,
        dataLabelColor: '0x000000',
        outlineColor: '0x000000',
        fontSize: 24,
        textLength: ASSESSMENT_TYPES_NO_CUT_OFF_ON_LONG_LABELS.includes(assessment.type) ? 1000 : 30,
        // Bar chart dimensions for dynamic generation
        barHeight: 0.25,
        titleHeight: 0.65,
    };
    const barValues = [];
    const barCategories = [];
    const barColors = [];
    for (const section of assessment.template?.sections || []) {
        // Values need to have 1 fraction digit
        const score = section?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
        barValues.push(parseFloat(score));
        barCategories.push(section?.label);
        barColors.push(colorizer.colorize(section));
    }

    rgsBarChartParams['values'] = barValues;
    rgsBarChartParams['categories'] = barCategories;
    rgsBarChartParams['barColors'] = barColors;

    params['[BAR-AverageSectionScores]'] = rgsBarChartParams;

    return params;
};

const sectionBarGraphs = (
    assessment: AssessmentViewModel,
    sections: AssessmentSection[],
    appLabels: AppLabels,
    colorizer: Colorizer
): { [key: string]: any } => {
    let params: { [key: string]: any } = {};
    // Bar charts for workstreams
    for (const section of sections) {
        params = {
            ...params,
            ...sectionBarGraph(assessment, section, appLabels, colorizer),
            ...averageSectionBarGraph(assessment, appLabels, colorizer),
        };
    }
    return params;
};

/**
 * Add params specific to the MRA2 assessment type:
 * 1. slide-specific calculations
 * 2. offering parameters
 * @param assessment the assessment from which a report is to be generated
 * @param appLabels A2T localization
 * @param fitScores the processed fit scores for an assessment
 * @returns params for the MRA2 PPTX report
 */
const getMra2Params = (assessment: AssessmentViewModel, appLabels: AppLabels, fitScores: FitScores): { [key: string]: any } => {
    let params: { [key: string]: any } = {};

    // MRA displays workstreams and notes the highest 2 / lowest 3 activities
    // Bar charts with observations won't fit in one slide. Need to split these workstreams into two slides
    const workstreams = assessment.template?.workstreams || [];

    const slideOneWorkstreamIDs = [
        'BusinessCase',
        'CustomerMigrationProjectPlan',
        'SkillsCOE',
        'LandingZone',
        'ApplicationPortfolioDiscoveryPlanning',
        'MigrationProcessExperience',
    ];
    const slideOneWorkstreams = [];
    const slideTwoWorkstreams = [];
    const slideOneCategories = [];
    const slideTwoCategories = [];
    // Separate by slide 1 and slide 2 workstreams
    for (const workstream of workstreams) {
        if (workstream?.id && slideOneWorkstreamIDs.includes(workstream.id)) slideOneWorkstreams.push(workstream);
        else slideTwoWorkstreams.push(workstream);

        for (const activity of workstream?.activities || []) {
            if (workstream?.id && slideOneWorkstreamIDs.includes(workstream.id)) slideOneCategories.push(activity);
            else slideTwoCategories.push(activity);
        }
    }
    // Sort categories by score
    slideOneCategories.sort(compareScores);
    slideTwoCategories.sort(compareScores);
    // Get lowest 3 and highest 2 for each slide
    for (let i = 0; i < 3; i++) {
        params[`[slide1LowestCategory${i}]`] = slideOneCategories[i]?.name;
        params[`[slide2LowestCategory${i}]`] = slideTwoCategories[i]?.name;
    }
    for (let i = 0; i < 2; i++) {
        params[`[slide1HighestCategory${i}]`] = slideOneCategories[slideOneCategories.length - 1 - i]?.name;
        params[`[slide2HighestCategory${i}]`] = slideTwoCategories[slideTwoCategories.length - 1 - i]?.name;
    }

    // Additionally, get offering parameters for MRA2. TODO: do this for all assessment types once more permanent solution is in place
    params = {
        ...params,
        ...getOfferingParameters(fitScores, appLabels),
    };

    return params;
};

/**
 * Adds params specific to the CFM assessment type:
 * 1. context for "what the highest cloud maturity looks like"
 * @param assessment the assessment from which a report is to be generated
 * @returns params for the CFM PPTX report
 */
const getCfmParams = (assessment: AssessmentViewModel): { [key: string]: any } => {
    const params: { [key: string]: any } = {};

    // Get a description of what the highest maturity looks like from the context of each question
    const highestMaturityContextText = 'At highest level of maturity:';

    let questionNumber = 1;
    assessment?.template?.sections?.forEach((section) =>
        section?.categories?.forEach((category) =>
            category?.prompts?.forEach((prompt) => {
                const promptContext: string = prompt?.context?.text || '';
                const fullHighestMaturityText: string = promptContext.substring(promptContext.indexOf(highestMaturityContextText));

                // Replace HTML breaks with newlines
                const plainMaturityText: string = fullHighestMaturityText.replace('<br/>', '\n');

                // Use "Q" in the PPTX template
                params[`[Q${questionNumber} Context]`] = `Q${questionNumber}: ${plainMaturityText}`;
                questionNumber++;
            })
        )
    );

    return params;
};

/**
 * Adds assessment type-specific data to a report request
 * @param assessment the assessment from which a report is to be generated
 * @param appLabels A2T localization
 * @param fitScores the processed fit scores for an assessment
 * @returns params that are specific to the provided assessment type
 */
const getTypeSpecificParams = (assessment: AssessmentViewModel, appLabels: AppLabels, fitScores: FitScores): { [key: string]: any } => {
    switch (assessment.type) {
        case 'MRA2': {
            return getMra2Params(assessment, appLabels, fitScores);
        }
        case 'CFM': {
            return getCfmParams(assessment);
        }
        default:
            return {};
    }
};

interface TypeSpecificConfig {
    tickColor: string;
    tickFontSize: number;
}
/**
 * Adds assessment type-specific configuration to a report request
 * @param assessment the assessment from which a report is to be generated
 * @returns configuration that are specific to the provided assessment type
 */
const getTypeSpecificConfig = (assessment: AssessmentViewModel): TypeSpecificConfig => {
    switch (assessment.type) {
        case 'CFM': {
            return {
                tickColor: '0x000000',
                tickFontSize: 10,
            };
        }
        default:
            return {
                tickColor: '0xFFFFFF',
                tickFontSize: 13,
            };
    }
};

const getReportParams = (
    presenter: string,
    organization: string,
    fitScores: FitScores,
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
): { [key: string]: any } => {
    let params: { [key: string]: any } = basicAssessmentData(
        presenter,
        organization,
        appLabels,
        assessment,
        colorizer,
        rsIsEnabled,
        refIdToRecommendationsMap
    );

    const workstreams = assessment.template?.workstreams || [];
    const sections = assessment.template?.sections || [];
    // Add bar charts if not disabled
    if (!assessment.template?.defaults?.report?.scoreBarChart?.disabled) {
        if (workstreams?.length > 0) {
            params = {
                ...params,
                ...workstreamBarGraphs(assessment, workstreams, appLabels, colorizer),
            };
        } else {
            params = {
                ...params,
                ...sectionBarGraphs(assessment, sections, appLabels, colorizer),
            };
        }
    }

    const currentAssessmentSections = assessment?.template?.sections;
    const excludeSections = assessment.template?.defaults?.report?.radar?.excludeSections;
    const includedSections = filterExcludedSections(currentAssessmentSections, excludeSections, assessment) || [];
    const scoreMultiplier: number = 100 / getMaxRatingInAssessment(assessment);
    const sectionScores = includedSections.map((p) => (p.score ? p.score * scoreMultiplier : 0));
    const sectionScoreNames = includedSections.map((p) => p.label);

    // Add radar if not disabled
    if (!assessment.template?.defaults?.report?.radar?.disabled) {
        if (includedSections.length > 0) {
            const sectionsRadarParams = {
                title: assessment?.template?.defaults?.report?.sectionLabel || appLabels.assessment.results.generate_report.parameters.perspectives,
                labels: sectionScoreNames,
                values: sectionScores,
                fillColor: '0xFF9B00',
                maxValue: 100,
                // Other formatting
                guidePercent: 0.8,
                guideColor: '0x00FF00',
                showTicks: true,
                numTickMarks: 5,
                ticksAsPercent: true,
                tickColor: getTypeSpecificConfig(assessment).tickColor,
                tickFontSize: getTypeSpecificConfig(assessment).tickFontSize,
                showTitle: false,
            };

            // Get observations of scores
            // 1. Get highest and lowest section scores
            const sectionScoresAndNames = [];
            for (let i = 0; i < sectionScores.length; i++) {
                sectionScoresAndNames.push({ name: sectionScoreNames[i], score: sectionScores[i] });
            }
            sectionScoresAndNames.sort(compareScores);
            const lowestSection = sectionScoresAndNames[0];
            const secondLowestSection = sectionScoresAndNames[1];
            const highestSection = sectionScoresAndNames[sectionScoresAndNames.length - 1];

            // 2. Get highest and lowest categories for the above scores
            let lowestSectionCategory: any = '';
            let secondLowestSectionCategory: any = '';
            let highestSectionCategory: any = '';

            const sections = assessment.template?.sections || [];
            const sectionsToDisplay = sections.filter((section) => sectionScoreNames.includes(section?.label));

            sectionsToDisplay.forEach((section) => {
                const categories = [...(section?.categories || [])];
                categories.sort(compareScores);
                // Get lowest category
                if (section?.label === lowestSection?.name) {
                    lowestSectionCategory = categories[0];
                }
                // Get second lowest category
                else if (section?.label === secondLowestSection?.name) {
                    secondLowestSectionCategory = categories[0];
                }
                // Get highest category
                else if (section?.label === highestSection?.name) {
                    highestSectionCategory = categories[categories.length - 1];
                }
            });

            const highestSectionName: string = highestSection?.name ?? 'TBD';
            const lowestSectionName: string = lowestSection?.name ?? 'TBD';
            const secondLowestSectionName: string = secondLowestSection?.name ?? 'TBD';

            const highestCategoryName: string = highestSectionCategory?.name ?? 'TBD';
            const lowestCategoryName: string = lowestSectionCategory?.name ?? 'TBD';
            const secondLowestCategoryName: string = secondLowestSectionCategory?.name ?? 'TBD';

            const highExplanation = appLabels.assessment.results.generate_report.parameters.perspective_high_explanation
                .replace('{}', highestSectionName)
                .replace('{}', highestCategoryName);
            const lowExplanation = appLabels.assessment.results.generate_report.parameters.perspective_low_explanation
                .replace('{}', lowestSectionName)
                .replace('{}', lowestCategoryName);
            const secondLowExplanation = appLabels.assessment.results.generate_report.parameters.perspective_second_low_explanation
                .replace('{}', secondLowestSectionName)
                .replace('{}', secondLowestCategoryName);

            // Some templates will prefer text replacement (MRA template):
            params['[PerspectivesExplanation0]'] = highExplanation;
            params['[PerspectivesExplanation1]'] = lowExplanation;
            params['[PerspectivesExplanation2]'] = secondLowExplanation;

            // Others use observations within RADAR object
            const observationList: string[] = [highExplanation, lowExplanation, secondLowExplanation];
            sectionsRadarParams['observations'] = observationList;

            params['[RADAR-Perspectives]'] = sectionsRadarParams;
        }

        if (workstreams.length > 0) {
            const workstreamNames: string[] = workstreams.map((w) => w.name);
            const workstreamScores: number[] = workstreams.map((w) => (w.score ? w.score * scoreMultiplier : 0));

            const workstreamRadarParams = {
                title: appLabels.assessment.results.generate_report.parameters.workstreams,
                labels: workstreamNames,
                values: workstreamScores,
                fillColor: '0xFF9B00',
                maxValue: 100,
                // Other formatting
                guidePercent: 0.8,
                guideColor: '0x00FF00',
                showTicks: true,
                numTickMarks: 5,
                ticksAsPercent: true,
                tickColor: getTypeSpecificConfig(assessment).tickColor,
                tickFontSize: getTypeSpecificConfig(assessment).tickFontSize,
                showTitle: false,
            };

            // Get highest and lowest workstream scores
            const workstreamScoresAndNames = [];
            for (let i = 0; i < workstreamScores.length; i++) {
                workstreamScoresAndNames.push({ name: workstreamNames[i], score: workstreamScores[i] });
            }
            workstreamScoresAndNames.sort(compareScores);
            const highestWorkstreamName: string = workstreamScoresAndNames[workstreamScoresAndNames.length - 1].name;
            const lowestWorkstreamName: string = workstreamScoresAndNames[0].name;
            const secondLowestWorkstreamName: string = workstreamScoresAndNames[1].name;

            const highExplanation = appLabels.assessment.results.generate_report.parameters.workstream_high_explanation.replace(
                '{}',
                highestWorkstreamName
            );
            const lowExplanation = appLabels.assessment.results.generate_report.parameters.workstream_low_explanation.replace(
                '{}',
                lowestWorkstreamName
            );
            const secondLowExplanation = appLabels.assessment.results.generate_report.parameters.workstream_second_low_explanation.replace(
                '{}',
                secondLowestWorkstreamName
            );

            params['[WorkstreamsExplanation0]'] = highExplanation;
            params['[WorkstreamsExplanation1]'] = lowExplanation;
            params['[WorkstreamsExplanation2]'] = secondLowExplanation;

            workstreamRadarParams['observations'] = [highExplanation, lowExplanation, secondLowExplanation];
            params['[RADAR-Workstreams]'] = workstreamRadarParams;
        }
    }

    // Get top 4 and bottom 4 readiness activities
    const allActivities: AssessmentActivity[] = [];
    workstreams.forEach((w) => w?.activities?.forEach((a) => allActivities.push(a)));

    // Some assessments (MRA) will want only certain activities to be considered here
    function filterReadinessActivities(activites: AssessmentActivity[], assessmentType: string) {
        if (assessmentType === 'MRA2') return activites.filter((activity) => activity?.phase === 'Pre-Mobilize');
        else return activites;
    }

    // Get 4 highest/lowest readiness activities
    allActivities.sort(compareScores);
    // Filter for categories that are marked as pre-mobilize
    const filteredActivities = filterReadinessActivities(allActivities, assessment.type);
    for (let i = 0; i < 4; i++) {
        // High must be >= 4, while low must be < 4
        if (filteredActivities.length - 1 - i > 0) {
            const highCategory = filteredActivities[filteredActivities.length - 1 - i];
            params[`[highReadinessActivity${i}]`] = (highCategory?.score || 0) >= 4 ? highCategory?.name : '';
        } else params[`[highReadinessActivity${i}]`] = '';
        if (i < filteredActivities.length) {
            const lowCategory = filteredActivities[i];
            params[`[lowReadinessActivity${i}]`] = (lowCategory?.score || 0) < 4 ? lowCategory?.name : '';
        } else params[`[lowReadinessActivity${i}]`] = '';
    }

    // Finally, add type-specific params
    params = {
        ...params,
        ...getTypeSpecificParams(assessment, appLabels, fitScores),
    };

    return params;
};

/**
 * Generates report using assessment. Returns signed url of generated report
 * @param presenter
 * @param organization
 * @param appLabels
 * @param assessment
 * @param colorizer
 * @returns
 */
export const generatePptxSummaryReport = async (
    presenter: string,
    organization: string,
    fitScores: FitScores,
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] },
    locale: string,
    templateId: string,
    isPartner: boolean
): Promise<string> => {
    // Get report parameters and any AWS/partner customizations
    let params: { [key: string]: any } = getReportParams(
        presenter,
        organization,
        fitScores,
        appLabels,
        assessment,
        colorizer,
        rsIsEnabled,
        refIdToRecommendationsMap
    );
    params = await addCustomizations(params, isPartner, appLabels);
    // Generate report using params data and template
    return generateReport(templateId, params);
};
