import './rating/RatingTile.scss';

import { Box, Popover, SpaceBetween } from '@amzn/awsui-components-react';

import CommentBox, { Comment } from './CommentBox';
import AwsCatIcon from '../../../common/Icons/AwsCatIcon';

interface CommentsThreadsProps {
    commentHeader: string;
    comments: Comment[];
    usePopover: boolean;
}

const CommentsThread = ({ commentHeader, comments, usePopover }: CommentsThreadsProps): JSX.Element | null => {
    const numberOfComments = comments?.length ?? 0;

    if (numberOfComments > 0) {
        const CommentsThreadInBox = (
            <Box variant='div' className='comment-thread'>
                <SpaceBetween size='l'>
                    <h3>{commentHeader ?? ''}</h3>
                    <Box variant='div' className='comment-thread-scroll'>
                        <SpaceBetween size='m'>
                            {comments.map((comment) => (
                                <CommentBox content={comment.content} author={comment.author} date={comment.date} eventType={comment.eventType} />
                            ))}
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </Box>
        );

        if (usePopover) {
            return (
                <div className='has-comments'>
                    <Popover dismissButton={false} position='right' triggerType='custom' content={CommentsThreadInBox}>
                        <div className='live-poll-comments'>
                            <AwsCatIcon name='communication' width='40' fill='#0073BB' />
                            <span>{numberOfComments}</span>
                        </div>
                    </Popover>
                </div>
            );
        } else {
            return CommentsThreadInBox;
        }
    }
    return null;
};

export default CommentsThread;
