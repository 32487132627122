import { AssessmentTemplate } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import dompurify from '../../../common/DomPurify';
import { getDescriptorForLocale } from '../../administration/manage-templates/edit-template/TemplateUtils';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';

type AssessmentTypeCardProps = AppLabelsContextInterface &
    LocalizationContextInterface & {
        template: AssessmentTemplate;
    };

const AssessmentTypeCard: FunctionComponent<AssessmentTypeCardProps> = ({ template, locale }): JSX.Element => {
    const deliveryGuidanceForCurrentLocale = getDescriptorForLocale(template.deliveryGuidance, locale).description || '';
    const purifiedHtml = dompurify.sanitize(deliveryGuidanceForCurrentLocale);

    return (
        <div className='awscat-assessment-type-card-body'>
            <SpaceBetween direction='vertical' size='xs'>
                <div>
                    <div>
                        {template.type} - {template.version}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: purifiedHtml }}></div>
                </div>
            </SpaceBetween>
        </div>
    );
};

export default withLocalizationContext(withAppLabelsContext(AssessmentTypeCard));
