import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Alert, Box, Button, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Paths from '../../../Paths';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import dompurify from '../../../common/DomPurify';
import { useAppSelector } from '../../redux/hooks';
import AssessmentDetailsHeader from '../facilitate/header/AssessmentDetailsHeader';

type PostCompleteConfirmationProps = AuthContextInterface & AppLabelsContextInterface & {};

type RouteParams = {
    assessmentId: string;
};

const PostCompleteConfirmation: FunctionComponent<PostCompleteConfirmationProps> = ({ appLabels }): JSX.Element => {
    const history = useHistory();
    const { assessmentId } = useParams<RouteParams>();

    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const promptConfig = currentAssessment?.template?.defaults?.questionnaireAnswers;
    const postCompletionGuide = promptConfig?.postCompletionGuide || appLabels.assessment.complete.status_constraint;
    const purifiedHtml = dompurify.sanitize(postCompletionGuide);
    return (
        <Box className='awscat-assessment-results assessment-form'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} />
            <Box variant='h2' margin={{ top: 'l', bottom: 'l' }}>
                <SpaceBetween size='s' direction='vertical'>
                    <Alert header={appLabels.assessment.complete.complete_assessment_successful}>
                        {
                            <TextContent>
                                <div dangerouslySetInnerHTML={{ __html: purifiedHtml }} />
                            </TextContent>
                        }
                    </Alert>
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button
                                variant='primary'
                                onClick={(event) => {
                                    event.preventDefault();
                                    history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/reviewall`);
                                }}
                                data-testid='btn-redirect-viewreport'
                            >
                                View Report
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </Box>
        </Box>
    );
};

export default withAuthContext(withAppLabelsContext(PostCompleteConfirmation));
