import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useState } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import CreateBugDialog from '../../common/CreateBugDialog';
import CreateFeatureRequestDialog from '../../common/CreateFeatureRequestDialog';

type ResourceDropdownPros = AuthContextInterface & AppLabelsContextInterface & { isAuthenticated: boolean };

const FeedbackDropdown: FunctionComponent<ResourceDropdownPros> = ({ isAuthenticated, appLabels }): JSX.Element | null => {
    const [showCreateBugModal, setShowCreateBugModal] = useState(false);
    const [showCreateFeatureRequestModal, setShowCreateFeatureRequestModal] = useState(false);
    const dropdownItems: ButtonDropdownProps.ItemOrGroup[] = [
        { id: 'bug', text: appLabels.feedback.dropdown_submit_issue },
        { id: 'feature', text: appLabels.feedback.dropdown_feature_request },
    ];

    return isAuthenticated ? (
        <div>
            <ButtonDropdown
                expandToViewport
                variant='normal'
                items={dropdownItems}
                onItemClick={(event) => {
                    if (event.detail.id === 'bug') {
                        setShowCreateBugModal(true);
                    } else if (event.detail.id === 'feature') {
                        setShowCreateFeatureRequestModal(true);
                    }
                }}
            >
                {appLabels.header.feedback.title}
            </ButtonDropdown>
            <CreateBugDialog showCreateBugModal={showCreateBugModal} setShowCreateBugModal={setShowCreateBugModal} />
            <CreateFeatureRequestDialog
                showCreateFeatureRequestModal={showCreateFeatureRequestModal}
                setShowCreateFeatureRequestModal={setShowCreateFeatureRequestModal}
            />
        </div>
    ) : null;
};

export default withAuthContext(withAppLabelsContext(FeedbackDropdown));
