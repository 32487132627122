import './RecommendedActions.scss';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import RecommendedActionLineComponent from './RecommendedActionLineComponent';
import { RecommendedActionsViewState } from './RecommendedActionViewState';
import { ActionTypeRecommendedAction } from './RecommendedActions';
import RecommendedActionsLineHeader from './RecommendedActionsLineHeader';
import { withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
type RecommendedActionListComponentProps = {
    state: RecommendedActionsViewState;
    dispatch: any;
    isReadOnly: boolean;
};

export const RecommendedActionListComponent = (props: RecommendedActionListComponentProps): JSX.Element => {
    const dispatch = props.dispatch;
    const state = props.state;
    const SortableItem = SortableElement((act: any) => (
        <li>{<RecommendedActionLineComponent action={act.value} persistedRecommendedActionsDispatch={dispatch} isReadOnly={props.isReadOnly} />}</li>
    ));

    const SortableList = SortableContainer(({ children }): JSX.Element => {
        return <ul className='recommended-action-list-style'>{children}</ul>;
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (props.isReadOnly) {
            return;
        }
        const reorderedItems = arrayMove(state.recommendedActions, oldIndex, newIndex);
        dispatch({
            type: ActionTypeRecommendedAction.REORDER_ACTION,
            payLoad: reorderedItems,
        });
    };

    return (
        <div>
            {<RecommendedActionsLineHeader />}
            {
                <SortableList useDragHandle onSortEnd={onSortEnd} shouldCancelStart={() => props.isReadOnly}>
                    {state.recommendedActions.map((value, index) => (
                        <SortableItem key={`item-${value.id}`} index={index} value={value} />
                    ))}
                </SortableList>
            }
        </div>
    );
};

export default withAppLabelsContext(RecommendedActionListComponent);
