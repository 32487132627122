import { AssessmentTemplateMetadata } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Authenticator } from '@amzn/awscat-portal-authentication-library';

/**
 * Gets the user ID format used by template managaement
 * @param auth the authenticator instance of the application
 * @returns the user ID
 */
const getUserId = (auth: Authenticator) => `${auth.getProvider()}-${auth.getUserInfo().userId}`;

/**
 * Returns whether a user can edit the provided locale
 * @param locale the locale the user is trying to edit
 * @param templateMetadata the metadata for the current template, including permissions
 * @param auth the authenticator instance of the application
 * @returns whether the user can edit the provided locale
 */
export const canEditLocale = (locale: string, templateMetadata: AssessmentTemplateMetadata, auth: Authenticator) => {
    const userId = getUserId(auth);

    // If the user:
    // 1. Is the owner of the template
    // 2. Is a coowner of the template
    // 3. Is a locale manager of the template for the specified locale
    // then they can edit the current locale
    if (
        templateMetadata.owner === userId ||
        templateMetadata.coowners.includes(userId) ||
        templateMetadata.localeManagers.find((localePermission) => localePermission.locale === locale)?.localeManagers?.includes(userId)
    ) {
        return true;
    }

    return false;
};

/**
 * Returns whether a user can add a locale
 * @param templateMetadata the metadata for the current template, including permissions
 * @param auth the authenticator instance of the application
 * @returns whether the user can add a locale
 */
export const canAddLocale = (templateMetadata: AssessmentTemplateMetadata, auth: Authenticator) => {
    const userId = getUserId(auth);

    // If the user:
    // 1. Is the owner of the template
    // 2. Is a coowner of the template
    // then they can add a new locale
    if (templateMetadata.owner === userId || templateMetadata.coowners.includes(userId)) {
        return true;
    }

    return false;
};

/**
 * Returns whether the current user can share a template. Must be co-owner or owner
 * @param templateMetadata the current template's metadata
 * @param auth the authenticator instance of the application
 * @returns whether the current user can share the template
 */
export const canShareTemplate = (templateMetadata: AssessmentTemplateMetadata | null, auth: Authenticator) => {
    const userId = getUserId(auth);

    return templateMetadata?.owner === userId || templateMetadata?.coowners?.includes(userId);
};

/**
 * Returns whether the user can configure a template. Must be co-owner or owner
 * @param templateMetadata the current template's metadata
 * @param auth the authenticator instance of the application
 * @returns whether the current user can configure the template
 */
export const canConfigureTemplate = (templateMetadata: AssessmentTemplateMetadata | null, auth: Authenticator) => {
    const userId = getUserId(auth);

    return templateMetadata?.owner === userId || templateMetadata?.coowners?.includes(userId);
};
