export const excelTemplateSchema = {
    Category: {
        prop: 'category',
        type: String,
        required: true,
    },
    'Category Context': {
        prop: 'categoryContext',
        type: String,
    },
    Question: {
        prop: 'question',
        type: String,
        required: true,
    },
    'Pre-workshop/Workshop': {
        prop: 'type',
        type: String,
    },
    'Question Context': {
        prop: 'questionContext',
        type: String,
    },
    'Answer Type': {
        prop: 'answerType',
        type: String,
        required: true,
    },
    'Ratings Guide': {
        prop: 'ratingsGuide',
        type: String,
    },
    Hint: {
        prop: 'hint',
        type: String,
    },
};

export const recommendedActionsExcelSchema = {
    Workstream: {
        prop: 'section', // it's called "workstream" in the requester guide/video, but it should be section
        type: String,
        required: true,
    },
    Category: {
        prop: 'category',
        type: String,
        required: true,
    },
    'Recommended Actions': {
        prop: 'action',
        type: String,
        required: true,
    },
};
