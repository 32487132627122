import { AssessmentModule, CreateModuleInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import dompurify from 'dompurify';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import { CREATE_MODULE } from '../../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import {
    createTemplateModule,
    getModuleFromModuleId,
    removeInProgressRequest,
} from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

type AddCategoryModalProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

const AddCategoryModal: FunctionComponent<AddCategoryModalProps> = ({ appLabels, visible, setVisible }) => {
    const dispatch = useAppDispatch();

    const currentViewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentModuleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);
    /* Navigate up the module tree until we find the root module */
    const currentSectionId = useMemo(() => {
        let currentModule = getModuleFromModuleId(currentModuleId);
        while (currentModule?.parentModule?.moduleId) {
            currentModule = getModuleFromModuleId(currentModule.parentModule.moduleId);
        }
        return currentModule?.moduleId;
    }, [currentModuleId]);

    const [createCategoryMutation, { loading }] = useMutation(CREATE_MODULE, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (data, options) => {
            const newCategory: AssessmentModule = data.createModule;
            dispatch(createTemplateModule({ viewId: currentViewId, containingModuleId: currentSectionId, newModule: newCategory }));
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    const onConfirm = useCallback(async () => {
        try {
            const addCategoryInput: CreateModuleInput = {
                parentModuleId: currentSectionId,
            };

            const requestId = uuid();
            await createCategoryMutation({ variables: { input: addCategoryInput }, context: { requestId } });
        } finally {
            setVisible(false);
        }
    }, [currentSectionId, createCategoryMutation, setVisible]);

    // Render HTML app label
    const addCategoryModalContent = dompurify.sanitize(appLabels.manage_templates.edit.modify_structure.add_category_modal_content);

    return (
        <Modal
            data-testid='add-category-modal'
            onDismiss={() => setVisible(false)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loading} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button variant='primary' loading={loading} onClick={onConfirm} data-testid='btn-confirm-add-category'>
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.edit.modify_structure.add_category}
            visible={visible}
        >
            <div dangerouslySetInnerHTML={{ __html: addCategoryModalContent }} />
        </Modal>
    );
};

export default withAppLabelsContext(AddCategoryModal);
