import { gql } from '@apollo/client';

// GraphQL queries/mutations definitions
export const CREATE_OR_UPDATE_ASSESSMENT_RESPONSES = gql`
    mutation createOrUpdateAssessmentResponses($input: CreateOrUpdateAssessmentResponsesInput!) {
        createOrUpdateAssessmentResponses(input: $input) {
            ... on AssessmentResponseText {
                stringValue
                updatedBy
            }
            ... on AssessmentResponseRating {
                intValue
                comments
                updatedBy
            }
            ... on AssessmentResponseBoolean {
                booleanValue
                comments
                updatedBy
            }
            ... on AssessmentResponseNumber {
                intValue
                comments
                updatedBy
            }
            ... on AssessmentResponseSingleSelection {
                singleSelectValue
                comments
                updatedBy
            }
            ... on AssessmentResponseMultiSelection {
                multiSelectValues
                comments
                updatedBy
            }
            ... on AssessmentResponseDate {
                dateValue
                comments
                updatedBy
            }
        }
    }
`;

export const CREATE_OR_UPDATE_ASSESSMENT_OBSERVATIONS_ACTIONS = gql`
    mutation CreateOrUpdateAssessmentObservationsActions($input: CreateOrUpdateAssessmentObservationsActionsInput!) {
        createOrUpdateAssessmentObservationsActions(input: $input) {
            ... on AssessmentObservations {
                observations
                updatedBy
                tags {
                    key
                    value
                }
            }
            ... on AssessmentActions {
                actions {
                    text
                    priority
                    effort
                    targetDate
                    owner
                }
                updatedBy
                tags {
                    key
                    value
                }
            }
        }
    }
`;

export const UPDATE_ASSESSMENT_BASE = gql`
    mutation UpdateAssessment($id: ID!, $input: UpdateAssessmentInput!) {
        updateAssessment(id: $id, input: $input) {
            id
            status
            description
            isDemoTest
            workshopDate
            readoutDate
            mapProgramEngagementId
            deliveredBy
            internalContact
            isfavorite
            optOutSolutionsRecommendation
            sendAllPromptsToPreEvent
        }
    }
`;

export const GET_ASSESSMENT_TEMPLATES_METADATA = gql`
    query getAssessmentTemplates($filter: AssessmentTemplateFilter, $limit: Int, $nextToken: AWSJSON) {
        getAssessmentTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                title
                type
                version
                locale
                lastUpdated
            }
            nextToken
        }
    }
`;

export const GET_ASSESSMENTS = gql`
    query getAssessments {
        getAssessments(filter: {}) {
            items {
                id
                organizationID
                status
                type
                version
                description
                isDemoTest
                workshopDate
                readoutDate
                mapProgramEngagementId
                deliveredBy
                internalContact
                createAt
                createdBy
                updatedAt
                updatedBy
                isfavorite
                optOutSolutionsRecommendation
                targetSegmentId
                customerAccount {
                    id
                    accountName
                }
                permissions {
                    isPending
                    invitationId
                    userId
                    action
                    inviteeEmail
                }
                assessmentScores {
                    scoreType
                    scoreDetails
                }
            }
        }
    }
`;

export const GET_ASSESSMENT_FEATURES = gql`
    query GetAssessmentFeatures($limit: Int, $nextToken: AWSJSON) {
        getAssessmentFeatures(limit: $limit, nextToken: $nextToken) {
            items
            nextToken
        }
    }
`;

export const CREATE_PARTNER_CUSTOMER_ACCOUNT = gql`
    mutation CreateCustomerAccount($input: CustomerAccountInput!) {
        createCustomerAccount(input: $input) {
            id
            organizationId
            accountName
            referenceId
            createdBy
        }
    }
`;

export const UPDATE_PARTNER_CUSTOMER_ACCOUNT = gql`
    mutation UpdateCustomerAccount($id: ID!, $input: CustomerAccountInput!) {
        updateCustomerAccount(id: $id, input: $input) {
            id
            organizationId
            accountName
            referenceId
            createdBy
        }
    }
`;

export const GET_PARTNER_CUSTOMER_ACCOUNTS = gql`
    query GetCustomerAccounts($filter: CustomerAccountFilter!, $limit: Int, $nextToken: AWSJSON) {
        getCustomerAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                organizationId
                referenceId
                accountName
                createdBy
                updatedBy
            }
            nextToken
        }
    }
`;

export const GET_ORG_ASSESSMENT_SUMMARY = gql`
    query GetOrgAssessmentSummary {
        getOrgAssessmentSummary
    }
`;

export const ACCEPT_ASSESSMENT_INVITATION = gql`
    mutation AcceptAssessmentInvitation($invitationId: ID!) {
        acceptAssessmentInvitation(invitationId: $invitationId)
    }
`;

export const GET_PRESCRIBED_ACTIONS_FROM_TEMPLATE = gql`
    query getAssessmentTemplates($filter: AssessmentTemplateFilter, $limit: Int, $nextToken: AWSJSON) {
        getAssessmentTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                title
                type
                version
                locale
                lastUpdated
                defaults {
                    report {
                        sectionLabel
                        report {
                            type
                        }
                    }
                }
                sections {
                    id
                    label
                    categories {
                        id
                        name
                        abbreviation
                        prescribedActions {
                            text
                        }
                    }
                }
                workstreams {
                    name
                    activities {
                        id
                        name
                        prescribedActions {
                            text
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const CREATE_OR_UPDATE_CUSTOMIZED_PRESCRIBED_ACTIONS = gql`
    mutation CreateOrUpdateCustomizedPrescribedActions($input: CreateOrUpdateCustomizedPrescribedActionsInput!) {
        createOrUpdateCustomizedPrescribedActions(input: $input) {
            type
            actionId
            prescribedActions {
                label
                text
            }
        }
    }
`;

export const GET_CUSTOMIZED_PRESCRIBED_ACTIONS = gql`
    query getCustomizedPrescribedActions($filter: CustomizedPrescribedActionsInputFilter!, $nextToken: AWSJSON) {
        getCustomizedPrescribedActions(filter: $filter, nextToken: $nextToken) {
            allPrescribedActions {
                type
                actionId
                prescribedActions {
                    text
                }
            }
            nextToken
        }
    }
`;
