import {
    AssessmentTemplateDefault,
    CreateOrUpdateDefaultInput,
    DefaultValueType,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Box, Container, Header, Link, SpaceBetween, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import { ApolloError, useMutation } from '@apollo/client';
import dompurify from 'dompurify';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import {
    TemplateDefaultTableItem,
    TemplateDefaultTableItem as TemplateDefaultsTableItem,
    getTemplateDefaultTableColumnDefs,
    getTemplateDefaultTableItems,
} from './ConfigureTemplateTableDefs';
import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import { CREATE_OR_UPDATE_DEFAULT } from '../../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import AssessmentDetailsHeader from '../../../../assessments/facilitate/header/AssessmentDetailsHeader';
import EmptyTable from '../../../../common/EmptyTable';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../../common/RequestStatusFlashbar';
import { openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createOrUpdateDefault } from '../CurrentTemplateSlice';
import TemplateConstants from '../TemplateConstants';
import { getTemplateDefault } from '../TemplateDefaults';
import { canConfigureTemplate } from '../TemplatePermissionsUtils';
import { isTemplatePublished } from '../TemplateUtils';

type ConfigureTemplateProps = AppLabelsContextInterface & AuthContextInterface & {};
const ConfigureTemplate: FunctionComponent<ConfigureTemplateProps> = ({ appLabels, auth }) => {
    const dispatch = useAppDispatch();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const currentTemplate = useAppSelector((state) => state.currentTemplateState.currentTemplate);
    const currentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);

    // Allow editing only if the user has permissions, and if the template isn't published
    const canEdit: boolean = useMemo(
        () => canConfigureTemplate(currentTemplateMetadata, auth) && !isTemplatePublished(currentTemplateMetadata),
        [currentTemplateMetadata, auth]
    );

    // Set help panel
    useEffect(() => {
        const purifiedContent = dompurify.sanitize(appLabels.manage_templates.configure_template.help_panel.content);
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.configure_template.help_panel.header,
                content: <div dangerouslySetInnerHTML={{ __html: purifiedContent }} />,
            })
        );
    }, [dispatch, appLabels.manage_templates.configure_template.help_panel]);

    const templateDefaultsTableItems: TemplateDefaultsTableItem[] = useMemo(() => {
        const initialDefaults = getTemplateDefaultTableItems(appLabels);
        // Populate the table with the values from the current template
        if (currentTemplate) {
            initialDefaults.forEach((item) => {
                const templateDefault = getTemplateDefault(item, currentTemplate);
                if (templateDefault) {
                    item.value = templateDefault?.value;
                    item.descriptors = templateDefault?.descriptors;
                }
            });
        }
        return initialDefaults;
        // Intentionally leave out most deps. We want to do this only as part of the initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLabels]);
    const templateDefaultsTableColumnDefs: TableProps.ColumnDefinition<TemplateDefaultsTableItem>[] = useMemo(
        () => getTemplateDefaultTableColumnDefs(appLabels, canEdit),
        [appLabels, canEdit]
    );

    const [updateDefault] = useMutation(CREATE_OR_UPDATE_DEFAULT, {
        client: templateManagementClient,
        onError: (error: ApolloError) => {
            // If 401, reauth
            if (error.message.includes('401')) {
                auth.redirectToSignIn();
            }

            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.configure_template.error_updating_configuration,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data, options) => {
            // Update Redux state once done. Get the namespace and key from the request context
            const { namespace } = options.variables.input as CreateOrUpdateDefaultInput;
            const updatedDefault: AssessmentTemplateDefault = data.createOrUpdateDefault;
            dispatch(
                createOrUpdateDefault({
                    ...updatedDefault,
                    namespace,
                })
            );
        },
    });

    /**
     * When an edit is made, update the backend
     */
    const onSubmit = useCallback(
        async (editedItem: TemplateDefaultTableItem) => {
            const updateDefaultInput: CreateOrUpdateDefaultInput = {
                namespace: editedItem.namespace,
                templateId: currentTemplate.templateId,
                key: editedItem.key,
                value: editedItem.value,
                valueType: editedItem.valueType,
                // Input uses the descriptor ID instead of the descriptor object
                descriptors: editedItem.descriptors?.map((descriptor) => descriptor.descriptorId),
            };

            updateDefault({ variables: { input: updateDefaultInput } });
        },
        [currentTemplate?.templateId, updateDefault]
    );

    /**
     * Filtering function for template defaults
     * @param item the item in the table
     * @param filteringText the text that the user inputs
     * @returns whether the item matches the text
     */
    const filterItems = useCallback(
        (item: TemplateDefaultsTableItem, filteringText: string): boolean => {
            const rowItems = [item.name, item.description, item.value];
            // If the item is a boolean, add "yes" or "no" to the search list
            if (item.valueType === DefaultValueType.Boolean) {
                rowItems.push(item.value === TemplateConstants.TRUE_STRING_VALUE ? appLabels.common.yes : appLabels.common.no);
            }
            const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
            return rowItems.some((rowItem) => {
                return rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
            });
        },
        [appLabels.common]
    );

    const { items, collectionProps, filterProps } = useCollection(templateDefaultsTableItems, {
        filtering: {
            empty: <EmptyTable title={appLabels.manage_templates.table.empty} subtitle='' />,
            noMatch: <EmptyTable title={appLabels.manage_templates.table.no_match} subtitle='' />,
            filteringFunction: filterItems,
        },
    });

    return (
        <div className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} shouldDisplayTemplate={true} />
            {/* Header that says "Edit metadata". This is shared among all template metadata forms */}
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.manage_templates.edit.metadata}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            {/* Form that allow editing selected template defaults */}
            <SpaceBetween direction='vertical' size='m'>
                <Container header={<Header variant='h2'>{appLabels.manage_templates.configure_template.link_text}</Header>}>
                    <Table
                        {...collectionProps}
                        columnDefinitions={templateDefaultsTableColumnDefs}
                        items={items}
                        filter={
                            <TextFilter
                                {...filterProps}
                                countText={''}
                                filteringPlaceholder={appLabels.manage_templates.configure_template.find_setting}
                            />
                        }
                        stickyHeader={true}
                        submitEdit={onSubmit}
                    />
                </Container>
            </SpaceBetween>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(ConfigureTemplate));
