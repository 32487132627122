/* eslint-disable react/no-unescaped-entities */
import './AwsDisclaimer.scss';

import { Box, ExpandableSection } from '@amzn/awsui-components-react';
import React from 'react';

export const AwsDisclaimer = (): JSX.Element => {
    return (
        <div className='disclaimer-box'>
            <Box padding={{ top: 'xs', right: 'l', bottom: 'l', left: 'l' }}>
                <ExpandableSection header='AWS disclaimer'>
                    <div className='max-width'>
                        <p>
                            This disclaimer applies to: the AWS Assessment Tool (the “Tool”); all other documents, information, data, and responses
                            (written or verbal) provided by Amazon Web Services, Inc. ("AWS") in connection with the Tool; and your use of the Tool.
                        </p>
                        <p>
                            All use of AWS's Service Offerings will be governed by the AWS Customer Agreement available at{' '}
                            <a href='https://aws.amazon.com/agreement/' target='_blank' rel='noopener noreferrer'>
                                https://aws.amazon.com/agreement/
                            </a>
                            or other written agreement by and between AWS and you governing your access to and use of the Services (the "Agreement").
                            Nothing in this Tool will modify or supplement the terms of the Agreement or any other agreement between AWS and you
                            (including without limitation any Non-Disclosure Agreement). No part of the Tool may be disclosed by you without AWS's
                            prior written consent.
                        </p>
                    </div>
                </ExpandableSection>
            </Box>
        </div>
    );
};

export default AwsDisclaimer;
