import { AssessmentDescriptor } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback } from 'react';

import { EditableDescriptorKey } from '../../../../api/templateManagement/TemplateManagementClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import dompurify from '../../../../common/DomPurify';
import TemplateEditableText from '../../../common/TemplateEditableText';
import { useAppSelector } from '../../../redux/hooks';

type AssessmentPromptHelpPanelBodyProps = AppLabelsContextInterface & {
    categoryContext: string | null;
    categoryDescriptor: AssessmentDescriptor | undefined;
    promptIndex: number | null;
    promptContext: string | null;
    promptDescriptor: AssessmentDescriptor | undefined;
    shouldDisplayTemplate?: boolean | undefined;
};

const AssessmentPromptHelpPanelBody: FunctionComponent<AssessmentPromptHelpPanelBodyProps> = ({
    appLabels,
    categoryContext,
    categoryDescriptor,
    promptIndex,
    promptContext,
    promptDescriptor,
    shouldDisplayTemplate,
}): JSX.Element => {
    const questionLabel =
        useAppSelector((state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.questionnaireAnswers.questionLabel) || 'Q';

    const buildCategoryContext = useCallback(() => {
        const purifiedHtml = dompurify.sanitize(categoryContext);
        return (
            <TemplateEditableText
                fieldName={appLabels.manage_templates.editable_fields.assessment_help_panel.category_context}
                descriptor={categoryDescriptor}
                keyToEdit={EditableDescriptorKey.HelpContext}
                numRows={5}
                doesFieldSupportHtml={true}
            >
                {categoryContext && (
                    <TextContent>
                        <div dangerouslySetInnerHTML={{ __html: purifiedHtml }} />
                    </TextContent>
                )}
            </TemplateEditableText>
        );
    }, [categoryContext, categoryDescriptor, appLabels.manage_templates.editable_fields.assessment_help_panel.category_context]);

    const buildPromptConext = useCallback(() => {
        // If there's no descriptor, just return null
        if (shouldDisplayTemplate && !promptDescriptor) {
            return null;
        }

        const purifiedHtml = dompurify.sanitize(promptContext);
        return (
            <div>
                <h4>{`${questionLabel} ${promptIndex}`}</h4>
                <TemplateEditableText
                    fieldName={appLabels.manage_templates.editable_fields.assessment_help_panel.prompt_context}
                    descriptor={promptDescriptor}
                    keyToEdit={EditableDescriptorKey.HelpContext}
                    numRows={5}
                    doesFieldSupportHtml={true}
                >
                    {promptContext && (
                        <TextContent>
                            <div dangerouslySetInnerHTML={{ __html: purifiedHtml }} />
                        </TextContent>
                    )}
                </TemplateEditableText>
            </div>
        );
    }, [
        shouldDisplayTemplate,
        promptContext,
        promptIndex,
        questionLabel,
        promptDescriptor,
        appLabels.manage_templates.editable_fields.assessment_help_panel.prompt_context,
    ]);

    return (
        <SpaceBetween direction='vertical' size={'s'}>
            {buildCategoryContext()}
            {buildPromptConext()}
        </SpaceBetween>
    );
};

export default withAppLabelsContext(AssessmentPromptHelpPanelBody);
