import { Link } from '@amzn/awsui-components-react';
import { useEffect } from 'react';

import { clearAppHelpPanel, updateAppHelpPanel } from './help-panel/AppHelpPanelSlice';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import { useAppDispatch } from '../redux/hooks';

export interface InfoLinkProps {
    header?: JSX.Element;
    content?: JSX.Element;
    footer?: JSX.Element;
}

const InfoLink = (props: InfoLinkProps & AppLabelsContextInterface) => {
    const dispatch = useAppDispatch();
    const onFollow = () => {
        dispatch(updateAppHelpPanel({ header: props.header ?? null, content: props.content, footer: props.footer, open: true }));
    };

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
    }, [dispatch]);

    return (
        <Link variant='info' onFollow={onFollow}>
            {props.appLabels.common.info}
        </Link>
    );
};

export default withAppLabelsContext(InfoLink);
