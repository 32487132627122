import { FormattedNumber } from 'react-intl';

interface AssessmentBarScoreProps {
    score?: number | null | undefined;
    maxScore?: number;
    backgroundColor?: string;
    fontColor?: string;
}

const AssessmentBarScore = (props: AssessmentBarScoreProps): JSX.Element => {
    const score = props.score || 1;
    const maxScore = props.maxScore || 1;
    const width = (score / maxScore) * 100;
    const hidden = !props.score;
    return (
        <div style={{ width: `${width}%`, visibility: hidden ? 'hidden' : 'visible' }}>
            <div style={{ backgroundColor: props.backgroundColor, color: props.fontColor }} className='barscore'>
                <FormattedNumber minimumSignificantDigits={2} maximumSignificantDigits={2} value={score} />
            </div>
        </div>
    );
};

export default AssessmentBarScore;
