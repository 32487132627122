import pino, { Logger } from 'pino';

import { Environment, GetEnvironment } from '../Environments';

let logLevel = 'error';
const browser = { asObject: false };
const env = GetEnvironment();

switch (env) {
    case Environment.gamma:
    case Environment.prod:
        logLevel = 'info';
        break;
    default:
        logLevel = 'debug';
}

const logger: Logger = pino({
    browser,
    level: logLevel,
});

export { logger };
