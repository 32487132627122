import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Box, Button, FormField, Input, Modal, Select, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { MaxLength, ValidationError, validate } from 'class-validator';
import { FunctionComponent, useCallback, useState } from 'react';

import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from './RequestStatusFlashbar';
import { FeedbackServiceClient, FeedbackType, ReportFeedbackData, a2tSimUUIDMapping } from '../../api/feedback/FeedbackServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import { logger } from '../../common/Logger';
import { extractAmazonAliasFromUserId, extractAmazonEmailFromUserId, getAppId, isAmazonUser } from '../../common/Utils';
import { errorLookup } from '../../common/ValidatorUtils';
import rumClient from '../../common/monitoring/RumClient';
import { getDescriptorForLocale } from '../administration/manage-templates/edit-template/TemplateUtils';
import { getAssessmentTemplates } from '../assessments/Utils';
import { LocalizationContextInterface, withLocalizationContext } from '../localization/LocalizationContext';
import { useAppSelector } from '../redux/hooks';

interface CreateFeatureRequestDialogProps {
    showCreateFeatureRequestModal: boolean;
    setShowCreateFeatureRequestModal: (boolean) => void;
}

export enum RequestTypeEnum {
    ASSESSMENT_SPECIFIC = 'Assessment specific',
    A2T_PLATFORM = 'A2T platform',
}

export class FeatureRequestType {
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_200, {
        message: 'common_max_char_200',
    })
    title: string;
    requestType: OptionDefinition;
    assessmentTypesOptions: OptionDefinition[];
    assessmentType: OptionDefinition;
    assesmentTypeEnable: boolean;
    assessmentDescription: string;
    assessmentDescriptionEnable: boolean;
    category: OptionDefinition;
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_2000, {
        message: 'common_max_char_2000',
    })
    description: string;
    descriptionRequired: boolean;
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_2000, {
        message: 'common_max_char_2000',
    })
    expectedBehavior: string;
    expectedBehaviorRequired: boolean;
    @MaxLength(Constants.TEXT_MAX_CHAR_LIMIT_2000, {
        message: 'common_max_char_2000',
    })
    furtherContext: string;
    enableSubmitButton: boolean;
}

const featureRequestInitState: FeatureRequestType = {
    title: '',
    requestType: null,
    assessmentTypesOptions: [],
    assessmentType: null,
    assesmentTypeEnable: false,
    assessmentDescription: null,
    assessmentDescriptionEnable: false,
    category: null,
    description: '',
    descriptionRequired: false,
    expectedBehavior: '',
    expectedBehaviorRequired: false,
    furtherContext: '',
    enableSubmitButton: true,
};

const CreateFeatureRequestDialog: FunctionComponent<
    AppLabelsContextInterface & AuthContextInterface & FlashContextInterface & LocalizationContextInterface & CreateFeatureRequestDialogProps
> = ({ appLabels, auth, showCreateFeatureRequestModal, setShowCreateFeatureRequestModal, flash, locale }): JSX.Element | null => {
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState?.currentAssessmentOrSelectedSnapshot);
    const [state, setState] = useState<FeatureRequestType>(featureRequestInitState);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    let assessmentTypes: OptionDefinition[] = [];
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const getValidationErrorText = errorLookup<FeatureRequestType>(validationErrors);
    const getErrorText = (attribute: keyof FeatureRequestType): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };

    const categoryOptions = [
        {
            label: appLabels.feedback.submit_feature_request_category_newfeature,
            value: 'New feature',
            description: appLabels.feedback.submit_feature_request_category_newfeature_description,
        },
        {
            label: appLabels.feedback.submit_feature_request_category_content,
            value: 'Content',
            description: appLabels.feedback.submit_feature_request_category_content_description,
        },
        {
            label: appLabels.feedback.submit_feature_request_category_access,
            value: 'Access',
            description: appLabels.feedback.submit_feature_request_category_access_description,
        },
        {
            label: appLabels.feedback.submit_feature_request_category_assistance,
            value: 'Assistance',
            description: appLabels.feedback.submit_feature_request_category_assistance_description,
        },
        {
            label: appLabels.feedback.submit_feature_request_category_onboarding,
            value: 'Onboarding',
            description: appLabels.feedback.submit_feature_request_category_onboarding_description,
        },
        {
            label: appLabels.feedback.submit_feature_request_category_feedback,
            value: 'Feedback',
            description: appLabels.feedback.submit_feature_request_category_feedback_description,
        },
    ];

    const onRequestTypeChange = async (requestType: OptionDefinition) => {
        if (requestType?.value === RequestTypeEnum.A2T_PLATFORM) {
            onChange({
                requestType: requestType,
                assesmentTypeEnable: false,
                assessmentDescriptionEnable: false,
                descriptionRequired: true,
                expectedBehaviorRequired: true,
            });
        } else {
            setRequestStatus({
                loading: true,
                messageType: FlashType.info,
                messageHeader: appLabels.feedback.loading_assessment_types,
                messageContent: null,
            });
            const { errors, templates } = await getAssessmentTemplates();
            if (errors.length > 0) {
                setRequestStatus({
                    loading: false,
                    messageType: templates.length === 0 ? FlashType.error : FlashType.warning,
                    messageHeader: appLabels.assessment.create.error_retrieving_all_templates,
                    messageContent: `${errors.length} ${appLabels.assessment.create.errors_retrieving_templates}`,
                });
                rumClient.recordError(errors);
            } else {
                assessmentTypes = templates?.map((t) => {
                    const templateTitle = getDescriptorForLocale(t.descriptors, locale).name;
                    return { label: templateTitle, value: t.type };
                });
                onChange({
                    requestType: requestType,
                    assesmentTypeEnable: true,
                    assessmentTypesOptions: assessmentTypes,
                    assessmentType: assessmentTypes?.filter((t) => t.value === currentAssessment?.type)?.[0],
                    assessmentDescriptionEnable: true,
                    assessmentDescription: currentAssessment?.description,
                    descriptionRequired: true,
                    expectedBehaviorRequired: true,
                });
                setRequestStatus(defaultRequestStatus);
            }
        }
    };

    const onChange = useCallback(
        (newSubState: Partial<FeatureRequestType>) => {
            setState({ ...state, ...newSubState });
        },
        [state]
    );

    const clearForm = useCallback(() => {
        setState(featureRequestInitState);
        setRequestStatus(defaultRequestStatus);
        setShowCreateFeatureRequestModal(false);
    }, [setShowCreateFeatureRequestModal]);

    const onCreateFeatureRequest = useCallback(async () => {
        setState({ ...state, enableSubmitButton: false });
        const featureRequestState: FeatureRequestType = Object.assign(new FeatureRequestType(), state);
        const validationErrors = await validate(featureRequestState);
        setValidationErrors(validationErrors);
        if (!(validationErrors && validationErrors.length)) {
            setRequestStatus({
                loading: true,
                messageType: FlashType.info,
                messageHeader: appLabels.feedback.submit_request,
                messageContent: null,
            });
            const labels: string[] = [];
            if (a2tSimUUIDMapping[FeedbackType.FEATURE]) labels.push(a2tSimUUIDMapping[FeedbackType.FEATURE]);
            if (a2tSimUUIDMapping[state.category.value]) labels.push(a2tSimUUIDMapping[state.category.value]);
            if (a2tSimUUIDMapping[state.assessmentType?.value]) labels.push(a2tSimUUIDMapping[state.assessmentType?.value]);
            let userEmail = null;
            let userId = auth.getUserInfo().userId;
            if (isAmazonUser(userId)) {
                userId = extractAmazonAliasFromUserId(userId);
                userEmail = extractAmazonEmailFromUserId(userId);
            }

            const title =
                state.requestType?.value === RequestTypeEnum.A2T_PLATFORM
                    ? `A2T Feature Request (A2T Platform ${state.category.label}) - ${state.title}`
                    : `A2T Feature Request (${state?.assessmentType?.value} ${state.category.label}) - ${state.title}`;

            let description =
                `As a user I want to:\n${state.description}\n\n` +
                `So that I can:\n${state.expectedBehavior}\n\n` +
                `Further Context:\n${state.furtherContext}\n\n`;
            if (state.assessmentDescription) {
                description += `Assessment Description: ${state.assessmentDescription}\n\n`;
            }
            description += `Submitter User ID: ${userId}`; // Include case sensitive userId for easy lookup of partner users

            const reportFeedbackData: ReportFeedbackData = {
                title,
                description,
                type: FeedbackType.FEATURE,
                appId: getAppId(),
                userEmail: userEmail,
                requester: userId,
                labelIds: labels,
            };
            let apiStatus = false;
            try {
                const feedbackServiceClient = new FeedbackServiceClient();
                const result = await feedbackServiceClient.reportFeedback(reportFeedbackData);
                if (result?.status === 200) {
                    apiStatus = true;
                }
            } catch (error: any) {
                rumClient.recordError(error);
                logger.error(error);
            }
            if (apiStatus) {
                flash.alert(FlashType.success, appLabels.feedback.submit_feature_request_success, '');
            } else {
                flash.alert(FlashType.error, appLabels.feedback.submit_feature_request_fail, '');
            }
            clearForm();
        }
    }, [
        appLabels.feedback.submit_feature_request_fail,
        appLabels.feedback.submit_feature_request_success,
        appLabels.feedback.submit_request,
        auth,
        clearForm,
        flash,
        state,
    ]);

    const onCancel = useCallback(() => {
        clearForm();
    }, [clearForm]);

    return (
        <Modal
            visible={showCreateFeatureRequestModal}
            closeAriaLabel='Close modal'
            header={appLabels.feedback.submit_feature_request_dialog_header}
            onDismiss={onCancel}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onCancel}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={onCreateFeatureRequest}
                            disabled={
                                !(
                                    state?.requestType &&
                                    state.description?.length &&
                                    ((state.requestType?.value === RequestTypeEnum.ASSESSMENT_SPECIFIC && state.assessmentType) ||
                                        state.requestType?.value === RequestTypeEnum.A2T_PLATFORM) &&
                                    state.category &&
                                    state.description &&
                                    state.expectedBehavior &&
                                    state.enableSubmitButton
                                )
                            }
                        >
                            {appLabels.feedback.submit_feature_request_dialog_submit_button}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <SpaceBetween size='l'>
                <FormField
                    label={`${appLabels.feedback.submit_feature_request_dialog_title} (${state.title.length}/${Constants.TEXT_MAX_CHAR_LIMIT_200} chars)`}
                    errorText={getErrorText('title')}
                >
                    <Input
                        value={state.title}
                        placeholder={appLabels.feedback.dialog_title_placeholder}
                        onChange={({ detail: { value } }) => onChange({ title: value })}
                    />
                </FormField>
                <FormField label={appLabels.feedback.submit_feature_request_dialog_type_of_request}>
                    <Select
                        onChange={(event) => onRequestTypeChange(event.detail.selectedOption)}
                        placeholder={appLabels.feedback.dialog_feature_request_type_placeholder}
                        selectedOption={state.requestType}
                        options={[
                            { label: RequestTypeEnum.ASSESSMENT_SPECIFIC, value: RequestTypeEnum.ASSESSMENT_SPECIFIC },
                            { label: RequestTypeEnum.A2T_PLATFORM, value: RequestTypeEnum.A2T_PLATFORM },
                        ]}
                    />
                </FormField>
                {state.assesmentTypeEnable && (
                    <FormField label={appLabels.feedback.submit_feature_request_dialog_assessment_type}>
                        <Select
                            placeholder={appLabels.feedback.dialog_assessment_type_placeholder}
                            selectedOption={state.assessmentType}
                            options={state.assessmentTypesOptions}
                            onChange={(event) => onChange({ assessmentType: event.detail.selectedOption })}
                        />
                    </FormField>
                )}
                {state.assessmentDescriptionEnable && (
                    <FormField label={appLabels.feedback.submit_feature_request_dialog_assessment_name}>
                        <Input
                            value={state.assessmentDescription}
                            placeholder={appLabels.feedback.dialog_assessment_description_placeholder}
                            onChange={({ detail: { value } }) => onChange({ assessmentDescription: value })}
                        />
                    </FormField>
                )}
                <FormField label={appLabels.feedback.submit_feature_request_dialog_category}>
                    <Select
                        placeholder={appLabels.feedback.dialog_feature_request_category_placeholder}
                        selectedOption={state.category}
                        options={categoryOptions}
                        onChange={(event) => onChange({ category: event.detail.selectedOption })}
                    />
                </FormField>
                {state.descriptionRequired && (
                    <FormField
                        label={
                            `${appLabels.feedback.submit_feature_request_dialog_request_description} ` +
                            `(${state.description.length}/${Constants.TEXT_MAX_CHAR_LIMIT_2000} chars)`
                        }
                        errorText={getErrorText('description')}
                    >
                        <Textarea
                            value={state.description}
                            placeholder={appLabels.feedback.dialog_request_description_placeholder}
                            onChange={({ detail: { value } }) => onChange({ description: value })}
                        />
                    </FormField>
                )}
                {state.expectedBehaviorRequired && (
                    <FormField
                        label={
                            `${appLabels.feedback.submit_feature_request_dialog_request_outcome} ` +
                            `(${state.description.length}/${Constants.TEXT_MAX_CHAR_LIMIT_2000} chars)`
                        }
                        errorText={getErrorText('expectedBehavior')}
                    >
                        <Textarea
                            value={state.expectedBehavior}
                            placeholder={appLabels.feedback.dialog_request_description_placeholder}
                            onChange={({ detail: { value } }) => onChange({ expectedBehavior: value })}
                        />
                    </FormField>
                )}
                <FormField
                    label={
                        `${appLabels.feedback.submit_feature_request_dialog_request_more_details} ` +
                        `(${state.furtherContext.length}/${Constants.TEXT_MAX_CHAR_LIMIT_2000} chars)`
                    }
                    errorText={getErrorText('furtherContext')}
                >
                    <Textarea
                        value={state.furtherContext}
                        placeholder={appLabels.feedback.dialog_further_context_placeholder}
                        onChange={({ detail: { value } }) => onChange({ furtherContext: value })}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default withLocalizationContext(withFlashContext(withAuthContext(withAppLabelsContext(CreateFeatureRequestDialog))));
