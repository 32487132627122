import { Assessment } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box } from '@amzn/awsui-components-react';
import { useCallback, useEffect, useReducer } from 'react';

import { CustomerFilterContextInterface } from './CustomerFilterContext';
import { CustomerViewModel } from './CustomerViewModel';
import { loadCustomersSuccess } from './CustomersSlice';
import CustomersTable from './CustomersTable';
import { CustomersViewState } from './CustomersViewState';
import a2sClient from '../../api/a2s/A2SClient';
import { AppLabels } from '../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import { logger } from '../../common/Logger';
import { customerNameCompare, deduplicateArray } from '../../common/Utils';
import rumClient from '../../common/monitoring/RumClient';
import { useAppDispatch } from '../redux/hooks';

interface LoadAction {
    customers: CustomerViewModel[];
}

const customerViewModelFromAssessment = (assessment: Assessment): CustomerViewModel | null => {
    const customer = assessment.customerAccount;
    if (customer) {
        const viewModel: CustomerViewModel = {
            id: customer.id || '',
            accountName: customer.accountName,
            createdBy: customer.createdBy || '',
            updatedBy: customer.updatedBy || '',
            website: customer.website || '',
            industry: customer.industry || '',
            territory: customer.territory || '',
            organizationId: customer.organizationId || '',
            referenceId: customer.referenceId || '',
            ownerId: customer.ownerId || '',
            geo: customer.geo || '',
            region: customer.region || '',
            segment: customer.segment || '',
        };
        return viewModel;
    } else {
        return null;
    }
};

export const mapAssessmentsToCustomerViewModel = (assessments: Assessment[]): CustomerViewModel[] => {
    if (assessments) {
        const mapped: CustomerViewModel[] = [];
        for (const assessment of assessments) {
            try {
                if (assessment && assessment.customerAccount) {
                    const customerViewModel = customerViewModelFromAssessment(assessment);
                    if (customerViewModel) {
                        mapped.push(customerViewModel);
                    }
                }
            } catch (error) {
                rumClient.recordError(error);
                logger.error('Exception while decoding customer: ', error);
            }
        }
        return mapped.sort(customerNameCompare);
    }
    return [];
};

const customerReducer = (state: CustomersViewState, action: LoadAction): CustomersViewState => {
    return { ...state, customers: action.customers, loading: false };
};

export const customersEqual = (c1: CustomerViewModel, c2: CustomerViewModel): boolean => {
    return c1.id === c2.id;
};

export const loadCustomersAsync = async (): Promise<CustomerViewModel[]> => {
    if (a2sClient) {
        try {
            const response = await a2sClient.getAssessments(
                {},
                {
                    customerAccount: true,
                }
            );
            const results = response.data?.getAssessments;
            return deduplicateArray(mapAssessmentsToCustomerViewModel(results?.items as Assessment[]), customersEqual);
        } catch (error: any) {
            rumClient.recordError(error);
            logger.error(error);
            // best effort to salvage customers that came through
            return deduplicateArray(mapAssessmentsToCustomerViewModel(error.data?.getAssessments?.items as Assessment[]), customersEqual);
        }
    }
    return [];
};

const Customers = (props: AuthContextInterface & CustomerFilterContextInterface & AppLabelsContextInterface) => {
    const appLabels: AppLabels = props.appLabels;
    const [customersState, customersDispatch] = useReducer(customerReducer, {
        customers: [],
        loading: true,
        dataUpdated: false,
    });
    const dispatch = useAppDispatch();
    const loadCustomers = useCallback(async (): Promise<void> => {
        try {
            const customers = await loadCustomersAsync();
            customersDispatch({ customers: customers });
            dispatch(loadCustomersSuccess(customers));
        } catch (err) {
            rumClient.recordError(err);
            logger.error(`loadCustomers error: ${err}`);
        }
    }, [dispatch]);

    useEffect(() => {
        loadCustomers();
    }, [props.auth, customersState.dataUpdated, loadCustomers]);

    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <Box variant='h2' margin={{ bottom: 's' }}>
                {appLabels.customer_selection.all_customers}
            </Box>

            <CustomersTable state={customersState}></CustomersTable>
        </div>
    );
};

export default withAppLabelsContext(withAuthContext(Customers));
