import './RatingTile.scss';

interface RatingPercentageProps {
    barWidth?: string;
    voteCount?: number | null;
    barLabel?: string;
}

const RatingPercentageBar = (props: RatingPercentageProps): JSX.Element | null => {
    const spanClassName = `${props.barWidth === '100%' || props.barWidth >= '90%' ? 'above-90' : 'below-90'}`;

    if (props.voteCount > 0) {
        return (
            <figure style={{ width: props.barWidth || 3 }}>
                <figcaption>
                    <span className={spanClassName}>
                        {props.voteCount} {props.barLabel}
                    </span>
                </figcaption>
            </figure>
        );
    }
    return null;
};

export default RatingPercentageBar;
