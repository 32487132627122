import { CustomerCloudMaturity } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Container, FormField, Header, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { ValidationError, isDefined } from 'class-validator';
import { useCallback, useEffect } from 'react';

import { AssessmentCreateState } from './AssessmentCreateState';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import dompurify from '../../../common/DomPurify';
import { CustomerPlanAndAlignmentExistsValue, CustomerSegmentId } from '../../../models/CustomerProfile';
import { TextContentWithInfoLink } from '../../common/TextContentWithInfoLink';
import { updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

type OnChangeHandler = (subStateValue: Partial<AssessmentCreateState>) => void;

export type AssessmentCustomerProfilePanelProps = {
    state: AssessmentCreateState;
    onChange: OnChangeHandler;
    validationErrors: ValidationError[];
    isReadOnly: boolean;
} & AppLabelsContextInterface;

const AssessmentCustomerProfilePanel = ({ state, onChange, appLabels }: AssessmentCustomerProfilePanelProps): JSX.Element => {
    const { customerProfile } = state;
    const dispatch = useAppDispatch();
    const openHelp = useAppSelector((state) => state.appHelpPanelState.open);

    const onAssessmentCustomerProfilePanelChange = useCallback(
        (subStateValue: Partial<AssessmentCreateState>) => {
            onChange(subStateValue);
        },
        [onChange]
    );

    // Target Segment Infomation
    const targetSegmentInfoHeader = null;
    const targetSegmentInfoContent = (
        <div>
            <h3>{appLabels.assessment.create.customer_profile.segments.description}</h3>
            <h4>{appLabels.assessment.create.customer_profile.segments.ent.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.segments.ent.description}</p>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.segments.dnb.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.segments.dnb.description}</p>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.segments.isv.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.segments.isv.description}</p>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.segments.smb.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.segments.smb.description}</p>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.segments.sup.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.segments.sup.description}</p>
            <br />
        </div>
    );

    const targetSegmentItems = [
        {
            value: CustomerSegmentId.ENT,
            label: appLabels.assessment.create.customer_profile.segments.ent.label,
        },
        {
            value: CustomerSegmentId.DNB,
            label: appLabels.assessment.create.customer_profile.segments.dnb.label,
        },
        {
            value: CustomerSegmentId.ISV,
            label: appLabels.assessment.create.customer_profile.segments.isv.label,
        },
        {
            value: CustomerSegmentId.SMB,
            label: appLabels.assessment.create.customer_profile.segments.smb.label,
        },
        {
            value: CustomerSegmentId.SUP,
            label: appLabels.assessment.create.customer_profile.segments.sup.label,
        },
    ];

    // Cloud Maturity Infomation
    const sanitizedCustomerCloudMaturityHighDescriptionHtml = dompurify.sanitize(
        appLabels.assessment.create.customer_profile.customer_cloud_maturity.high.description || ''
    );
    const sanitizedCustomerCloudMaturityLowDescriptionHtml = dompurify.sanitize(
        appLabels.assessment.create.customer_profile.customer_cloud_maturity.low.description || ''
    );

    const customerCloudMaturityInfoHeader = null;
    const customerCloudMaturityInfoContent = (
        <div>
            <h3>{appLabels.assessment.create.customer_profile.customer_cloud_maturity.description}</h3>
            <h4>{appLabels.assessment.create.customer_profile.customer_cloud_maturity.high.label}</h4>
            <div dangerouslySetInnerHTML={{ __html: sanitizedCustomerCloudMaturityHighDescriptionHtml }}></div>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.customer_cloud_maturity.low.label}</h4>
            <div dangerouslySetInnerHTML={{ __html: sanitizedCustomerCloudMaturityLowDescriptionHtml }}></div>
        </div>
    );

    const customerCloudMaturityItems = [
        {
            value: CustomerCloudMaturity.HIGH,
            label: appLabels.assessment.create.customer_profile.customer_cloud_maturity.high.label,
        },
        {
            value: CustomerCloudMaturity.LOW,
            label: appLabels.assessment.create.customer_profile.customer_cloud_maturity.low.label,
        },
    ];

    // Plan and Alignment Infomation
    const planAndAlignmentExistsInfoHeader = null;
    const planAndAlignmentExistsInfoContent = (
        <div>
            <h3>{appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.description}</h3>
            <h4>{appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.yes.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.yes.description}</p>
            <br />
            <h4>{appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.no.label}</h4>
            <p>{appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.no.description}</p>
        </div>
    );

    const planAndAlignmentExistsItems = [
        {
            value: CustomerPlanAndAlignmentExistsValue.YES,
            label: appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.yes.label,
        },
        {
            value: CustomerPlanAndAlignmentExistsValue.NO,
            label: appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.no.label,
        },
    ];

    const getPlanAndAlignmentExistsValue = useCallback(() => {
        if (!isDefined(customerProfile?.customerPlanAndAlignmentExists)) {
            return '';
        }
        return customerProfile.customerPlanAndAlignmentExists ? CustomerPlanAndAlignmentExistsValue.YES : CustomerPlanAndAlignmentExistsValue.NO;
    }, [customerProfile?.customerPlanAndAlignmentExists]);

    useEffect(() => {
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.create.customer_profile.title,
                content: appLabels.assessment.create.customer_profile.description,
                open: openHelp,
            })
        );
    }, [appLabels.assessment.create.customer_profile.description, appLabels.assessment.create.customer_profile.title, dispatch, openHelp]);

    return (
        <Container header={<Header variant='h2'>{appLabels.assessment.create.customer_profile.header}</Header>}>
            <SpaceBetween size='l'>
                <FormField
                    label={
                        <TextContentWithInfoLink
                            textContent={`${appLabels.assessment.create.customer_profile.segments.prompt}*`}
                            header={targetSegmentInfoHeader}
                            content={targetSegmentInfoContent}
                        />
                    }
                    description={appLabels.assessment.create.customer_profile.segments.description}
                    stretch={true}
                >
                    <RadioGroup
                        data-testid='target-segment-radio-group'
                        onChange={({ detail: { value } }) =>
                            onAssessmentCustomerProfilePanelChange({
                                customerProfile: { ...customerProfile, targetSegmentId: value as CustomerSegmentId },
                            })
                        }
                        value={customerProfile?.targetSegmentId}
                        items={targetSegmentItems}
                    />
                </FormField>

                <FormField
                    label={
                        <TextContentWithInfoLink
                            textContent={`${appLabels.assessment.create.customer_profile.customer_cloud_maturity.prompt}*`}
                            header={customerCloudMaturityInfoHeader}
                            content={customerCloudMaturityInfoContent}
                        />
                    }
                    description={appLabels.assessment.create.customer_profile.customer_cloud_maturity.description}
                    stretch={true}
                >
                    <RadioGroup
                        data-testid='customer-cloud-maturity-radio-group'
                        onChange={({ detail: { value } }) =>
                            onAssessmentCustomerProfilePanelChange({
                                customerProfile: { ...customerProfile, customerCloudMaturity: value as CustomerCloudMaturity },
                            })
                        }
                        value={customerProfile?.customerCloudMaturity || ''}
                        items={customerCloudMaturityItems}
                    />
                </FormField>

                <FormField
                    label={
                        <TextContentWithInfoLink
                            textContent={`${appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.prompt}*`}
                            header={planAndAlignmentExistsInfoHeader}
                            content={planAndAlignmentExistsInfoContent}
                        />
                    }
                    description={appLabels.assessment.create.customer_profile.customer_plan_and_alignment_exists.description}
                    stretch={true}
                >
                    <RadioGroup
                        data-testid='plan-and-alignment-exists-radio-group'
                        onChange={({ detail: { value } }) =>
                            onAssessmentCustomerProfilePanelChange({
                                customerProfile: {
                                    ...customerProfile,
                                    customerPlanAndAlignmentExists: value === CustomerPlanAndAlignmentExistsValue.YES,
                                },
                            })
                        }
                        value={getPlanAndAlignmentExistsValue()}
                        items={planAndAlignmentExistsItems}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default withAppLabelsContext(AssessmentCustomerProfilePanel);
