import { Header, SignInButton } from '@amzn/awscat-react-components';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { useLocation } from 'react-router-dom';

import FeedbackDropdown from './FeedbackDropdown';
import HeaderUserName from './HeaderUserName';
import LanguageSelectionDropdown from './LanguageSelectionDropdown';
import ResourcesDropdown from './ResourcesDropdown';

type AppHeaderProps = AuthContextInterface & { isAuthenticated: boolean; title: string };

const AppHeader = (props: AppHeaderProps) => {
    const location = useLocation();

    let header: JSX.Element;
    if (props.isAuthenticated && location.pathname !== '/signin') {
        header = (
            <Header title={props.title}>
                <li className='dropdown'>
                    <LanguageSelectionDropdown isAuthenticated={props.isAuthenticated} />
                </li>
                <li className='dropdown separated'>
                    <FeedbackDropdown isAuthenticated={props.isAuthenticated} />
                </li>
                <li className='dropdown separated'>
                    <ResourcesDropdown isAuthenticated={props.isAuthenticated} />
                </li>
                <li className='separated'>
                    <HeaderUserName isAuthenticated={props.isAuthenticated} />
                </li>
                <li className='separated'>
                    <SignInButton isAuthenticated={props.isAuthenticated} />
                </li>
            </Header>
        );
    } else {
        header = (
            <Header title={props.title}>
                <li>
                    <SignInButton isAuthenticated={props.isAuthenticated} />
                </li>
            </Header>
        );
    }

    return header;
};

export default withAuthContext(AppHeader);
