import { SpaceBetween } from '@amzn/awsui-components-react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedPrivateRoute from './AuthenticatedPrivateRoute';
import { GetEnvironmentConfig } from './Environments';
import Paths from './Paths';
import AdminCustomerAccounts from './components/administration/AdminCustomerAccounts';
import AdminOverview from './components/administration/AdminOverview';
import AdminReportTemplates from './components/administration/AdminReportTemplates';
import AdminManageTemplates from './components/administration/manage-templates/AdminManageTemplates';
import CreateTemplateWizard from './components/administration/manage-templates/create-template/CreateTemplateWizard';
import FinalizeTemplatePanel from './components/administration/manage-templates/create-template/FinalizeTemplatePanel';
import TemplateRouter from './components/administration/manage-templates/edit-template/TemplateRoutes';
import AdminRecommendedActions from './components/administration/prescribed-actions/AdminPrescribedActions';
import AssessmentRouter from './components/assessments/AssessmentRoutes';
import AssessmentsCreate from './components/assessments/create/AssessmentCreate';
import AssessmentsView from './components/assessments/list/AssessmentsView';
import SignIn from './components/common/SignIn';
import SignOut from './components/common/SignOut';
import AssessmentFeatures from './components/common/features/AssessmentFeatures';
import AwsDisclaimer from './components/common/footer/AwsDisclaimer';
import CustomerCreate from './components/customers/CustomerCreate';
import CustomerUpdate from './components/customers/CustomerUpdate';
import Customers from './components/customers/Customers';
import Landing from './components/landing/Landing';
import { RumManager } from '../src/common/monitoring/RumManager';

function Routes(): JSX.Element {
    // Enable Rum Monitoring for clientside telemetry
    RumManager(GetEnvironmentConfig());
    return (
        <SpaceBetween direction='vertical' size='xxxs'>
            <AssessmentFeatures />
            <Switch>
                <Route exact path='/' component={Landing} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signout' component={SignOut} />
                <AuthenticatedPrivateRoute exact path={Paths.BASE_ASSESSMENTS_PATH} component={AssessmentsView} />
                <AuthenticatedPrivateRoute exact path={`${Paths.BASE_ASSESSMENTS_PATH}/create`} component={AssessmentsCreate} />
                <AuthenticatedPrivateRoute exact path='/customers/create' component={CustomerCreate} />
                <AuthenticatedPrivateRoute exact path='/customers/:customerAccountId/update' component={CustomerUpdate} />
                <AuthenticatedPrivateRoute path={`${Paths.BASE_ASSESSMENTS_PATH}/:assessmentId/snapshots/:snapshotId`} component={AssessmentRouter} />
                <AuthenticatedPrivateRoute path={`${Paths.BASE_ASSESSMENTS_PATH}/:assessmentId`} component={AssessmentRouter} />
                <AuthenticatedPrivateRoute exact path='/customers' component={Customers} />
                <AuthenticatedPrivateRoute exact path='/admin-customer-accounts' component={AdminCustomerAccounts} />
                <AuthenticatedPrivateRoute exact path='/admin-overview' component={AdminOverview} />
                <AuthenticatedPrivateRoute exact path='/admin-recommended-actions' component={AdminRecommendedActions} />
                <AuthenticatedPrivateRoute exact path='/admin-report-templates' component={AdminReportTemplates} />
                <AuthenticatedPrivateRoute exact path={Paths.MANAGE_TEMPLATES_PATH} component={AdminManageTemplates} />
                <AuthenticatedPrivateRoute path={`${Paths.EDIT_TEMPLATE_PATH}/:templateId`} component={TemplateRouter} />
                <AuthenticatedPrivateRoute exact path={Paths.CREATE_TEMPLATE_PATH} component={CreateTemplateWizard} />
                <AuthenticatedPrivateRoute exact path={Paths.FINALIZE_TEMPLATE_PATH} component={FinalizeTemplatePanel} />
            </Switch>
            <AwsDisclaimer />
        </SpaceBetween>
    );
}

export default Routes;
