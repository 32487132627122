import { SpaceBetween, TextContent } from '@amzn/awsui-components-react';

import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

const ManageSponsorHelpPanel = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;

    return (
        <SpaceBetween size='s' direction='vertical'>
            <TextContent>{appLabels.assessment.pre_event.info_panel_text_1}</TextContent>
            <TextContent>{appLabels.assessment.pre_event.info_panel_text_2}</TextContent>
            <TextContent>{appLabels.assessment.pre_event.info_panel_text_3}</TextContent>
        </SpaceBetween>
    );
};

export default withAppLabelsContext(ManageSponsorHelpPanel);
