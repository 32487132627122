import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';

type HeaderUserNameProps = AuthContextInterface & { isAuthenticated: boolean };

function HeaderUserName(props: HeaderUserNameProps): JSX.Element | null {
    const userInfo = props.isAuthenticated ? props.auth.getUserInfo() : null;
    return props.isAuthenticated ? (
        <div className='text'>{`${userInfo?.displayName} (${userInfo?.organizationName ?? userInfo?.organizationId})`}</div>
    ) : null;
}

export default withAuthContext(HeaderUserName);
