import { Assessment } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import { AssessmentViewModel } from './AssessmentViewModel';
import { loadAssessmentsSuccess, loadingAssessments } from './list/AssessmentsSlice';
import a2sApolloClient from '../../api/a2s/ApolloClient';
import { GET_ASSESSMENTS } from '../../api/a2s/ApolloQueries';
import { withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import { logger } from '../../common/Logger';
import { displayDateString, displayUSDateString, epochToDate } from '../../common/Utils';
import rumClient from '../../common/monitoring/RumClient';
import { withCustomerFilterContext } from '../customers/CustomerFilterContext';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

export const assessmentViewModelFromAssessment = (assessment: Assessment): AssessmentViewModel => {
    const extractUserId = (createdBy: string) => {
        // createdBy format
        // AmazonCorporate_chnui
        // AmazonCorporate_chnui:chnui
        // APN_0056u000000OwMIAA0
        let userId = '';
        if (createdBy) {
            userId = createdBy.split(':')[0];
            userId = userId.replace(Constants.AMAZON_CORPORATE_USER_PREFIX, '');
        }
        return userId;
    };

    const viewModel: AssessmentViewModel = {
        id: assessment?.id,
        accountCustomerName: assessment.customerAccount?.accountName || '',
        description: assessment.description || '',
        targetSegmentId: assessment.targetSegmentId,
        createdAt: assessment.createAt,
        createdBy: extractUserId(assessment.createdBy || ''),
        deliveredBy: assessment.deliveredBy || '',
        workshopDate: displayUSDateString(assessment.workshopDate || ''),
        readoutDate: displayUSDateString(assessment.readoutDate || ''),
        updatedAt: assessment.updatedAt,
        lastUpdated: displayDateString(epochToDate(assessment.updatedAt)),
        status: assessment.status,
        type: assessment.type || '',
        version: assessment.version || '',
        customerAccountID: assessment.customerAccount?.id || '',
        isDemoTest: !!assessment.isDemoTest,
        template: assessment.template || null,
        assessmentPermissions: assessment.permissions ?? [],
        percentComplete: null, // TODO: pass in from backend or compute
        isfavorite: assessment.isfavorite,
        mapProgramEngagementId: assessment.mapProgramEngagementId,
        internalContact: assessment.internalContact,
        snapshots: assessment?.snapshotConnection?.snapshots,
        optOutSolutionsRecommendation: !!assessment.optOutSolutionsRecommendation,
        assessmentScores: assessment?.assessmentScores,
        sendAllPromptsToPreEvent: assessment?.sendAllPromptsToPreEvent,
        metadata: assessment?.metadata,
    };
    return viewModel;
};

export const mapAssessmentsToViewModel = (assessments: Assessment[]): AssessmentViewModel[] => {
    if (assessments) {
        const assessmentViews: AssessmentViewModel[] = [];
        for (const assessment of assessments) {
            try {
                if (assessment && assessment.customerAccount && assessment.description && assessment.createdBy && assessment.status) {
                    const assessmentViewModel = assessmentViewModelFromAssessment(assessment);
                    if (assessmentViewModel) {
                        assessmentViews.push(assessmentViewModel);
                    }
                }
            } catch (error) {
                rumClient.recordError(error);
                // simply log error on proceed with best effort
                logger.error('Exception while decoding Assessment: ', error);
            }
        }
        return assessmentViews.sort((a, b) => {
            if (!b.updatedAt) {
                // sort a before null b
                return -1;
            } else if (!a.updatedAt) {
                // keep same a, b order
                return 0;
            }
            // most recent first
            return b.updatedAt - a.updatedAt;
        });
    }
    return [];
};

const Assessments = () => {
    const [getAssessments, { called, loading, data }] = useLazyQuery(GET_ASSESSMENTS, {
        client: a2sApolloClient,
        errorPolicy: 'all', // allows partial result in data when error
        fetchPolicy: 'network-only',
        onError: (error) => {
            // Error encountered retrieving full results.
            // Simply log error and proceed with best efforts.
            rumClient.recordError(error);
        },
    });

    const dispatch = useAppDispatch();
    const { requireReload } = useAppSelector((state) => state.assessmentsState);

    useEffect(() => {
        if (loading) {
            dispatch(loadingAssessments(loading));
        } else if (!called || requireReload) {
            getAssessments();
        } else if (data) {
            const assessments = data.getAssessments?.items;
            const assessmentViewModels = mapAssessmentsToViewModel(assessments);
            dispatch(loadAssessmentsSuccess(assessmentViewModels));
        }
    }, [called, data, dispatch, getAssessments, loading, requireReload]);

    return null;
};

export default withAppLabelsContext(withCustomerFilterContext(Assessments));
