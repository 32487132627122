import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CustomerViewModel } from './CustomerViewModel';

export interface CustomersState {
    customers: CustomerViewModel[];
}

export const initialState: CustomersState = {
    customers: [],
};

export const customersSlice = createSlice({
    name: 'customersState',
    initialState,
    reducers: {
        loadCustomersSuccess: (state, action: PayloadAction<CustomerViewModel[]>) => {
            state.customers = [];
            action.payload.forEach((a) => {
                state.customers.push(a);
            });
        },
    },
});

export const { loadCustomersSuccess } = customersSlice.actions;

export default customersSlice.reducer;
