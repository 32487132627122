import {
    ASSESSMENT_TYPE_ACRONYM_REGEX,
    CreateAssessmentTypeInput,
    IsAcronymAvailableInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import {
    Box,
    Button,
    FormField,
    Input,
    Modal,
    NonCancelableCustomEvent,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { generateTemplateModuleUrl } from './edit-template/TemplateUrlUtils';
import templateManagementClient from '../../../api/templateManagement/TemplateManagementClient';
import { CREATE_ASSESSMENT_TYPE } from '../../../api/templateManagement/TemplateManagementMutations';
import { IS_ACRONYM_AVAILABLE } from '../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { SUPPORTED_LOCALES } from '../../../common/Localization';
import { getLanguageName } from '../../../common/Utils';
import rumClient from '../../../common/monitoring/RumClient';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';

const DEFAULT_ACRONYM_PLACEHOLDER = 'MRA2';

type CreateAssessmentTypeModalProps = AppLabelsContextInterface &
    LocalizationContextInterface & {
        visible: boolean;
        setVisible: (visible: boolean) => void;
    };

const CreateAssessmentTypeModal: FunctionComponent<CreateAssessmentTypeModalProps> = ({ appLabels, locale: uiLocale, visible, setVisible }) => {
    const history = useHistory();

    const [acronym, setAcronym] = useState('');
    const [isAcronymInvalid, setIsAcronymInvalid] = useState(null);
    const [isAcronymAvailable, setIsAcronymAvailable] = useState<boolean>(null);
    const [localeToCreate, setLocaleToCreate] = useState(uiLocale);

    // Allow user to select initial language
    const templateLocaleOptions: SelectProps.Option[] = useMemo(() => {
        const options = SUPPORTED_LOCALES.map(
            (locale): SelectProps.Option => ({
                value: locale,
                label: getLanguageName(locale, uiLocale),
            })
        );

        return options;
    }, [uiLocale]);

    const [isAcronymAvailableQuery, { loading: loadingIsAcronymAvailable }] = useLazyQuery(IS_ACRONYM_AVAILABLE, {
        client: templateManagementClient,
        onError: (error) => {
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            setIsAcronymAvailable(data.isAcronymAvailable);
        },
    });

    const onInputAcronym: NonCancelableEventHandler<BaseChangeDetail> = useCallback(({ detail }) => {
        // Reset availability indicator
        setIsAcronymAvailable(null);
        setAcronym(detail.value);

        // Check if input is valid
        if (!ASSESSMENT_TYPE_ACRONYM_REGEX.test(detail.value)) {
            setIsAcronymInvalid(true);
        } else {
            setIsAcronymInvalid(false);
        }
    }, []);

    const onCheckAcronymAvailability = useCallback(() => {
        if (!isAcronymInvalid) {
            setIsAcronymAvailable(null);

            const input: IsAcronymAvailableInput = {
                acronym,
            };
            isAcronymAvailableQuery({ variables: { input } });
        }
    }, [acronym, isAcronymAvailableQuery, isAcronymInvalid]);

    const onTemplateLocaleSelectionChange = useCallback(
        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
            const newLocale = event.detail.selectedOption.value;
            setLocaleToCreate(newLocale);
        },
        [setLocaleToCreate]
    );

    const [createAssessmentTypeMutation, { loading: loadingCreateAssessmentType }] = useMutation(CREATE_ASSESSMENT_TYPE, {
        client: templateManagementClient,
        onError: (error) => {
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const newTemplateId: string = data.createAssessmentType.templateId;
            const urlToTemplate = generateTemplateModuleUrl(newTemplateId, localeToCreate);
            history.push(urlToTemplate);
        },
    });

    const onConfirm = useCallback(async () => {
        try {
            const createAssessmentTypeInput: CreateAssessmentTypeInput = {
                acronym,
                locale: localeToCreate,
            };

            await createAssessmentTypeMutation({ variables: { input: createAssessmentTypeInput } });
        } finally {
            setVisible(false);
        }
    }, [acronym, localeToCreate, createAssessmentTypeMutation, setVisible]);

    return (
        <Modal
            data-testid='create-assessment-type-modal'
            onDismiss={() => setVisible(false)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loadingCreateAssessmentType} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            loading={loadingCreateAssessmentType}
                            onClick={onConfirm}
                            disabled={isAcronymInvalid || !isAcronymAvailable}
                            data-testid='btn-confirm-create-assessment-type'
                        >
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.create_assessment_type.label}
            visible={visible}
        >
            <SpaceBetween direction='vertical' size='m'>
                <FormField
                    label={appLabels.manage_templates.create_assessment_type.acronym_field}
                    errorText={isAcronymInvalid && appLabels.manage_templates.create_assessment_type.invalid_acronym}
                >
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Input
                            data-testid='acronym-input'
                            // Placeholder acronym is same for all languages
                            placeholder={DEFAULT_ACRONYM_PLACEHOLDER}
                            value={acronym}
                            onChange={onInputAcronym}
                        />
                        <Button
                            data-testid='btn-check-acronym-availability'
                            onClick={onCheckAcronymAvailability}
                            disabled={isAcronymInvalid === true || isAcronymInvalid === null}
                            loading={loadingIsAcronymAvailable}
                        >
                            Check availability
                        </Button>
                        {isAcronymAvailable !== null && (
                            <StatusIndicator type={isAcronymAvailable ? 'success' : 'error'}>
                                {isAcronymAvailable
                                    ? appLabels.manage_templates.create_assessment_type.available
                                    : appLabels.manage_templates.create_assessment_type.not_available}
                            </StatusIndicator>
                        )}
                    </SpaceBetween>
                </FormField>
                <FormField label={appLabels.manage_templates.create_assessment_type.initial_locale_field}>
                    <Select
                        data-testid='template-locale-selection'
                        selectedOption={templateLocaleOptions.find(({ value }) => value === localeToCreate)}
                        options={templateLocaleOptions}
                        onChange={onTemplateLocaleSelectionChange}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default withLocalizationContext(withAppLabelsContext(CreateAssessmentTypeModal));
