import '../ResultsReviewAll.scss';

import { TextContent } from '@amzn/awsui-components-react';

import { AppLabels } from '../../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import LegendBoxes from '../legend/LegendBoxes';

const ResponseDistributionInfoPanel = (props: AppLabelsContextInterface & { scoreScale: ScoreScale }): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    return (
        <div>
            <TextContent>{appLabels.assessment.results.review_all.responseDistribution.info_panel_text}</TextContent>
            <div>
                <h4>{appLabels.common.legend}</h4>
                <LegendBoxes scoreScale={props.scoreScale}></LegendBoxes>
            </div>
        </div>
    );
};

export default withAppLabelsContext(ResponseDistributionInfoPanel);
