import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken, getProvider } from '../../common/auth/Authentication';
import rumClient from '../../common/monitoring/RumClient';

export interface Feedback {
    appContext?: Record<string, any>;
    comment: string;
    createdBy: string;
    rating: number;
    ratingSystem: string;
    srcApp: string;
    srcURL: string;
}

// This interface should be in sync with AWSCAT-FeedbackService/src/models/SIMTicketData
export interface ReportFeedbackData {
    title: string;
    description: string;
    type: string;
    appId: string;
    requester: string;
    userEmail: string;
    labelIds: string[];
}

export enum FeedbackType {
    BUG = 'bug',
    FEATURE = 'feature',
}

// this should map UUID for label in SIM under A2T folder.
export const a2tSimUUIDMapping = {
    A2T: 'd359620e-54ce-4953-87c5-bdc9f99bb32b',
    // Issue Types: Bug/Feature Request
    bug: '637c0122-b453-4737-8085-4b6ec8ac25ab',
    feature: 'b0b75e70-09e6-438a-b58a-22f915b985b1',
    // Category Types
    'New feature': '3327a9c7-0e2f-4c7c-884d-484517b16eba',
    Feedback: 'e274e686-0006-4802-b25f-717d33ff430d',
    Onboarding: '04ceee3a-e15e-4475-ad8f-88a271acdd0a',
    Content: '0296009e-8d45-491a-8a09-ba4aec825672',
    Access: 'cbf960b0-3b83-4e47-af2e-d657a2f703a1',
    Assistance: 'd4141be3-4e16-4821-a72a-702f00d12278',

    // Assessment Types
    CCR: '0be1b94c-9d4b-42c2-99c2-55041305da2f',
    MRA: 'b021e3c-3baa-45c9-bcc6-1d89e81fb83e',
    MRA2: '558484b5-fcbd-416e-8053-dd8944a4a4d0',
    DevOps: 'f21e61c2-8582-40f2-b745-c4b38b26efa3',
    MF: 'd8413233-4551-48b7-a940-48d675db6d3a',
    Ops: '11b0ed56-1fb2-4770-8597-ed3780bd5328',
    SaaS: '5d82ba25-5780-4063-97c3-fdb125eb6649',
    SWIFT: 'bac8faa6-b1d4-4476-8990-463498f78ad1',
    WAFSAP: '5bb1503e-c9ab-4a39-b3e4-74d4cf1e6da4',
    LZA: '7d9abc6c-35b7-4008-bbda-c3ec5cb56364',
    ECSA: '08d292b4-c4c6-436a-bff3-c49be9e3f05a',
    CFM: '25c717a8-6aa8-49cc-9efa-7d4ed85e7072',
    PSA: '94d077d0-e5ae-4b4e-816d-1cb61b0aba56',
    EVR: '1224e371-7d04-4ecb-82d3-67c3d3431a35',
    LHA: '0a0afa76-6d96-4060-ab86-6f4f4af4cf0e',
};

const envConfig = GetEnvironmentConfig();
const HEADERS = {
    AUTH_PROVIDER: 'x-auth-provider',
    X_REQUESTED_WITH: 'x-requested-with',
    X_CSRF_TOKEN: 'x-csrf-token',
};

// declare _csrf at global scope so it can be added to the header in enrichConfig
declare global {
    interface Window {
        _csrf: any;
    }
}

export class FeedbackServiceClient {
    private service: AxiosInstance;
    private readonly serviceUrl: string;

    constructor() {
        this.serviceUrl = envConfig.feedbackApiEndpoint;
        this.service = axios.create({
            baseURL: this.serviceUrl,
            withCredentials: true,
        });

        this.service.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const authToken = await getJwtToken();
            if (config && config.headers) {
                config.headers.Authorization = `Bearer ${authToken}`;
                config.headers[HEADERS.AUTH_PROVIDER] = getProvider();
                config.headers[HEADERS.X_REQUESTED_WITH] = 'XMLHttpRequest';
                config.headers[HEADERS.X_CSRF_TOKEN] = window._csrf;
            } else {
                rumClient.recordError('Axios config not available to set auth header');
            }
            return config;
        }, undefined);

        this.service.interceptors.response.use(undefined, async (err: any) => {
            rumClient.recordError('FeedbackService response error intercepted.' + err);
            if (err && err.config && err.response && err.response.status && err.response.status === 401) {
                const authToken = await getJwtToken();
                err.config.headers.Authorization = `Bearer ${authToken}`;
                err.config.headers[HEADERS.AUTH_PROVIDER] = getProvider();

                return axios.request(err.config);
            } else {
                throw err; // throwing will return a rejected promise since this is an async function
            }
        });
    }

    public reportFeedback = async (reportFeedbackData: ReportFeedbackData): Promise<any> => {
        const result = await this.service.post('reportFeedback', reportFeedbackData);
        return result;
    };
}

const feedbackServiceClient = new FeedbackServiceClient();
export default feedbackServiceClient;
