import { GenerateRecommendationsCommand } from '@amzn/aws-assessment-recommendation-service-client';
import { Assessment, AssessmentSnapshot } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Alert, Button, SideNavigationProps, Spinner } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { assessmentViewModelFromAssessment } from './Assessments';
import { getAssessmentAllSnapshotsMetadata, getAssessmentSingleSnapshotContentAndTemplate } from './SnapshotUtils';
import { assessmentViewModelFromAssessmentSnapshot, generateAssessmentPromptUrl } from './Utils';
import AcceptAssessmentInvitation from './actions/AcceptAssessmentInvitation';
import AssessmentDetails from './facilitate/AssessmentDetails';
import {
    beginLoadingAssessmentOrSnapshot,
    createOrUpdateCurrentPrompt,
    loadAssessmentSuccess,
    loadAssessmentWithSnapshotListSuccess,
    loadPreEventInfoSuccessful,
    onLoadFailure,
    unloadAssessment,
    unloadCurrentSnapshot,
} from './facilitate/CurrentAssessmentSlice';
import AssessmentPreEventManageSponsor from './pre-event/ManageSponsor';
import PostCompleteConfirmation from './results/PostCompleteConfirmation';
import GenerateReportMenu from './results/generate-report/GenerateReportMenu';
import ResultsReviewAll from './results/reviewall/ResultsReviewAll';
import {
    loadRecommendationsFailure,
    loadRecommendationsSuccess,
    loadingRecommendations,
} from './results/reviewall/report/new-recommended-actions/RecommendationsSlice';
import ManageSnapshots from './results/snapshots/ManageSnapshots';
import AssessmentUpdate from './update/AssessmentUpdate';
import AuthenticatedPrivateRoute from '../../AuthenticatedPrivateRoute';
import Paths from '../../Paths';
import a2sClient from '../../api/a2s/A2SClient';
import { QuestionnaireAndAnswers, getPreEventAnswers } from '../../api/data-collection/DataCollectionClient';
import { getRecommendationServiceClient } from '../../api/recommendationService/RecommendationServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import { LoadingStatus } from '../../common/RequestUtils';
import rumClient from '../../common/monitoring/RumClient';
import { unloadCurrentTemplate } from '../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { setAssessmentSectionInAppSideNavigationPanel } from '../common/side-navigation/AppSideNavigationSlice';
import { LocalizationContextInterface, withLocalizationContext } from '../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface MatchParam {
    assessmentId: string;
    snapshotId: string;
}

type AssessmentRouteProps = AuthContextInterface &
    RouteComponentProps<MatchParam> &
    AppLabelsContextInterface &
    FlashContextInterface &
    LocalizationContextInterface;

const AssessmentRoutes: FunctionComponent<AssessmentRouteProps> = ({ appLabels, auth, locale, match, flash }): JSX.Element => {
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const assessmentType = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.type);
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const snapshotList: AssessmentSnapshot[] = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.snapshots);
    const assessmentDescription = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.description);
    const assessmentLocale = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.locale);

    let preEventEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.userQuestionnaires?.preEvent?.enabled
    );
    if (selectedSnapshotId) {
        preEventEnabled = false;
    }
    const currentPrompts = useAppSelector((state) => state.currentAssessmentState.currentPromptState.prompts);
    const preEventInfo = useAppSelector((state) => state.currentAssessmentState.preEventInfo);
    const assessmentOrSnapshotLoadingStatus = useAppSelector((state) => state.currentAssessmentState.assessmentOrSnapshotLoadingStatus);

    const [loadingPreEventInfo, setLoadingPreEventInfo] = useState<boolean>(false);
    const [errorAlerted, setErrorAlerted] = useState<boolean>(false);
    const [urlLastAssessmentLoaded, setUrlLastAssessmentLoaded] = useState<string>('');
    const history = useHistory();
    const assessmentScoresRequireReload = useAppSelector((state) => state.currentAssessmentState.assessmentScoresRequireReload);
    const assessmentScoresReloadRequested = useAppSelector((state) => state.currentAssessmentState.assessmentScoresReloadRequested);
    const inReviewAllPage = history.location.pathname.includes('/reviewall');
    const isUrlLastLoadedInReviewAllPage = urlLastAssessmentLoaded.includes('/reviewall');
    const { path } = useRouteMatch();
    const { assessmentId: routeAssessmentId, snapshotId: routeSnapshotId } = match.params;
    const dispatch = useAppDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const promptCategory = urlParams.get('promptCategory');
    const promptId = urlParams.get('promptId');
    const promptIdOrCategory = promptId ? promptId : promptCategory;
    const radarDisabled = currentAssessment?.template?.defaults?.report?.radar?.disabled;
    const invitationId = urlParams.get('invitationId');
    const [localeRequested, setLocaleRequested] = useState<string | null>(null);

    // User may be navigating from "Edit template" section to modify an assessment. In this case, need to unload template
    useEffect(() => {
        dispatch(unloadCurrentTemplate());
    }, [dispatch]);

    const buildAssessmentSideNavigationPanel = useCallback((): SideNavigationProps.Item[] => {
        // update side nav for new activeHrel
        const sectionItems: SideNavigationProps.Item[] = [];
        currentPrompts.forEach((prompt) => {
            const assessmentId = prompt.assessmentId;
            const promptIdForSection = prompt.id;
            const promptCategoryUrl = generateAssessmentPromptUrl(assessmentId, selectedSnapshotId, promptIdForSection || '');
            const sectionName = prompt.sectionName;
            const categoryName = prompt.categoryName;
            let sectionItem = sectionItems.find((i) => (i as SideNavigationProps.ExpandableLinkGroup).text === sectionName);
            if (!sectionItem) {
                sectionItem = {
                    type: 'expandable-link-group',
                    href: promptCategoryUrl,
                    text: sectionName,
                    items: [],
                };
                sectionItems.push(sectionItem);
            }
            const categoryItems = (sectionItem as SideNavigationProps.ExpandableLinkGroup).items;
            const categoryItem = categoryItems.find((i) => (i as SideNavigationProps.Link).text === categoryName);
            if (!categoryItem) {
                (sectionItem as SideNavigationProps.ExpandableLinkGroup).items = [
                    ...categoryItems,
                    {
                        type: 'link',
                        href: promptCategoryUrl,
                        text: categoryName,
                    },
                ];
            }
        });

        const resultItem: SideNavigationProps.Item = {
            type: 'section',
            text: appLabels.side_navigation.results.results,
            items: [],
        };
        // Add Update Assessment Info section, unless it's a sample assessment
        if (assessmentId !== Constants.SAMPLE_ASSESSMENT_ID) {
            resultItem.items = resultItem.items.concat([
                {
                    type: 'link',
                    text: appLabels.side_navigation.results.update_assessment_info,
                    href: `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/update`,
                },
            ]);
        }
        // Add review all page
        resultItem.items = resultItem.items.concat([
            {
                type: 'link',
                text: appLabels.side_navigation.results.review_all,
                href: selectedSnapshotId
                    ? `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/snapshots/${selectedSnapshotId}/reviewall`
                    : `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/reviewall`,
            },
        ]);
        // Add compare snapshot page, unless radar is disabled or on a sample assessment
        if (!(radarDisabled || assessmentId === Constants.SAMPLE_ASSESSMENT_ID)) {
            resultItem.items = resultItem.items.concat([
                {
                    type: 'link',
                    text: appLabels.assessment.snapshot.compare_snapshots,
                    href: `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/comparesnapshots`,
                },
            ]);
        }
        // Add generate report page
        resultItem.items = resultItem.items.concat([
            {
                type: 'link',
                text: appLabels.side_navigation.results.generate_report,
                href: selectedSnapshotId
                    ? `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/snapshots/${selectedSnapshotId}/generatereport`
                    : `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/generatereport`,
            },
        ]);

        // Add pre-event page
        const preEventItems: SideNavigationProps.Item = {
            type: 'section',
            text: appLabels.side_navigation.pre_event.pre_event,
            items: [
                {
                    type: 'link',
                    text: appLabels.side_navigation.pre_event.manage_sponsor,
                    href: `${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/preevent`,
                },
            ],
        };

        let items: SideNavigationProps.Item[] = [
            { type: 'link', text: appLabels.side_navigation.view_all_assessments, href: Paths.BASE_ASSESSMENTS_PATH },
            { type: 'divider' },
        ];
        if (preEventEnabled) {
            items.push(preEventItems);
        }

        // Make the assessment section only once the assessment has loaded. sectionItems will be [] unless it's loaded
        if (sectionItems.length) {
            items = items.concat([
                {
                    type: 'section',
                    text: assessmentDescription || appLabels.side_navigation.assessment,
                    items: sectionItems,
                },
                resultItem,
            ]);
        }

        return items;
    }, [
        appLabels.side_navigation.assessment,
        appLabels.side_navigation.view_all_assessments,
        appLabels.side_navigation.results.results,
        appLabels.side_navigation.results.generate_report,
        appLabels.side_navigation.results.review_all,
        appLabels.side_navigation.results.update_assessment_info,
        appLabels.side_navigation.pre_event.pre_event,
        appLabels.side_navigation.pre_event.manage_sponsor,
        appLabels.assessment.snapshot.compare_snapshots,
        assessmentId,
        assessmentDescription,
        currentPrompts,
        preEventEnabled,
        radarDisabled,
        selectedSnapshotId,
    ]);

    const loadAssessment = useCallback(
        async (assessmentId: string, promptIdOrCategory: string, locale: string = Constants.DEFAULT_LOCALE): Promise<void> => {
            let assessment = null;
            dispatch(unloadAssessment());
            dispatch(beginLoadingAssessmentOrSnapshot());
            if (a2sClient) {
                try {
                    setLocaleRequested(locale);
                    assessment = await getAssessmentAllSnapshotsMetadata(a2sClient, assessmentId, locale);
                } catch (error: any) {
                    rumClient.recordError(error);
                    // Best effort to map unaffected assessments
                    assessment = error.data?.getAssessments?.items ? error.data?.getAssessments?.items[0] : null;
                }
                if (assessment) {
                    const assessmentViewModel = assessmentViewModelFromAssessment(assessment);
                    dispatch(loadAssessmentSuccess(assessmentViewModel));
                    dispatch(createOrUpdateCurrentPrompt(promptIdOrCategory));
                } else {
                    dispatch(onLoadFailure());
                }
            }
        },
        [dispatch]
    );

    const loadRecommendations = useCallback(
        /**
         * Retrieve recommendations from RS and update data in redux store
         * @param assessmentId assessmentId of current path
         * @param assessmentType type of current assessment
         * @param locale current locale
         * @returns
         */
        async (assessmentId: string, assessmentType: string, locale: string) => {
            dispatch(loadingRecommendations());
            const rsClient = getRecommendationServiceClient();
            try {
                // Both partner and AWS users retrieve the AWS prescribed actions from RS for the target assessment type
                // In RS phase 2, partner users will retrieve their org's customized prescribed actions from RS
                const { recommendations } = await rsClient.send(
                    new GenerateRecommendationsCommand({
                        resourceId: `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${assessmentId}`,
                        targetId: `${Constants.RECOMMENDATIONS_TARGET_ID_PREFIX}${assessmentType}`,
                        locale,
                    })
                );
                dispatch(loadRecommendationsSuccess(recommendations));
            } catch (error) {
                rumClient.recordError(error);
                dispatch(loadRecommendationsFailure((error as Error)?.message));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        loadRecommendations(assessmentId, assessmentType, locale);
    }, [loadRecommendations, assessmentId, assessmentType, locale]);

    const loadSnapshotsContent = useCallback(
        async (assessmentId: string, snapshotId: string, promptIdOrCategory: string, locale: string = Constants.DEFAULT_LOCALE): Promise<void> => {
            let assessment: Assessment = null;
            let selectedSnapshot: AssessmentSnapshot = null;
            let newSnapshotList: AssessmentSnapshot[] = [];
            dispatch(beginLoadingAssessmentOrSnapshot());
            try {
                if (snapshotList) {
                    newSnapshotList = [...snapshotList];
                } else {
                    assessment = await getAssessmentAllSnapshotsMetadata(a2sClient, assessmentId, locale);
                    newSnapshotList = assessment?.snapshotConnection?.snapshots || [];
                }
                selectedSnapshot = newSnapshotList.find((s) => s.id === snapshotId);

                assessment = await getAssessmentSingleSnapshotContentAndTemplate(a2sClient, assessmentId, snapshotId, locale);
                selectedSnapshot = assessment?.snapshotConnection?.snapshots?.[0];
                if (selectedSnapshot) {
                    const snapshotIndex = newSnapshotList?.findIndex((snapshot) => snapshot.id === snapshotId);
                    if (snapshotIndex >= 0) {
                        newSnapshotList[snapshotIndex] = selectedSnapshot;
                    }
                    assessment.snapshotConnection.snapshots = newSnapshotList;
                    const assessmentViewModel = assessmentViewModelFromAssessment(assessment);
                    const snapshotViewModel = assessmentViewModelFromAssessmentSnapshot(assessmentViewModel, selectedSnapshot);
                    dispatch(loadAssessmentWithSnapshotListSuccess({ assessment: assessmentViewModel, snapshot: snapshotViewModel }));
                    dispatch(createOrUpdateCurrentPrompt(promptIdOrCategory));
                } else {
                    // No snapshot found for this id
                    dispatch(onLoadFailure());
                }
            } catch (error: any) {
                rumClient.recordError(error);
                // Best effort to map unaffected assessments
                assessment = error.data?.getAssessments?.items ? error.data?.getAssessments?.items[0] : null;
                if (!selectedSnapshot) {
                    dispatch(onLoadFailure());
                }
            }
        },
        [dispatch, snapshotList]
    );

    const loadPreEventAnswers = useCallback(async () => {
        if (preEventEnabled && preEventInfo === undefined && !loadingPreEventInfo) {
            try {
                setLoadingPreEventInfo(true);
                const questionnaireWithAnswers: QuestionnaireAndAnswers = await getPreEventAnswers(assessmentId);
                dispatch(loadPreEventInfoSuccessful(questionnaireWithAnswers));
            } catch (err) {
                rumClient.recordError(err);
                const errorMsg = `Error loading pre-event responses: ${err}`;
                if (!errorAlerted) {
                    // alert once on api error
                    flash.alert(FlashType.error, appLabels.assessment.pre_event.error_loading, errorMsg);
                    setErrorAlerted(true);
                }
            }
            setLoadingPreEventInfo(false);
        }
    }, [
        appLabels.assessment.pre_event.error_loading,
        assessmentId,
        dispatch,
        preEventInfo,
        loadingPreEventInfo,
        errorAlerted,
        flash,
        preEventEnabled,
    ]);

    // Load assessment/snapshot using API
    useEffect(() => {
        // pathname is of the form : /assessments/:assessmentId/snapshots:/snapshotId

        // Don't try to load the assessment using the API, if a sample assessment is already loaded
        if (routeAssessmentId === Constants.SAMPLE_ASSESSMENT_ID) {
            return;
        }

        // Unload the snapshot if we navigated to a URL without a snapshot
        if (!routeSnapshotId && selectedSnapshotId) {
            dispatch(unloadCurrentSnapshot());
        }

        // Reload assessment template/score details if:
        if (
            !assessmentId || // 1) current assessment not loaded
            assessmentId !== routeAssessmentId || // 2) assessment has changed
            (routeSnapshotId && selectedSnapshotId !== routeSnapshotId) || // 3) snapshot has changed
            localeRequested !== locale || // 4) locale selection has changed
            (!isUrlLastLoadedInReviewAllPage && inReviewAllPage && assessmentScoresRequireReload) || // 5) we switched into reviewall page and scores require reload
            (inReviewAllPage && assessmentScoresReloadRequested) // 6) ratings changed within reviewall page that require reload (e.g. CMA heatmap ratings update)
        ) {
            // If loading is in progress or the assessment failed to load, do not attempt to reload
            if (assessmentOrSnapshotLoadingStatus === LoadingStatus.Loading || assessmentOrSnapshotLoadingStatus === LoadingStatus.FailedToLoad) {
                return;
            }
            const loadAssessmentAsync = async () => {
                try {
                    if (routeSnapshotId) {
                        if (selectedSnapshotId !== routeSnapshotId) {
                            await loadSnapshotsContent(routeAssessmentId, routeSnapshotId, promptIdOrCategory, locale);
                        }
                    } else {
                        await loadAssessment(routeAssessmentId, promptIdOrCategory, locale);
                    }
                } catch (err) {
                    rumClient.recordError(err);
                    dispatch(onLoadFailure());
                }
            };
            loadAssessmentAsync();
            setUrlLastAssessmentLoaded(history.location.pathname);
        }
    }, [
        assessmentId,
        assessmentLocale,
        auth,
        dispatch,
        history.location.pathname,
        loadAssessment,
        loadSnapshotsContent,
        locale,
        match.params,
        selectedSnapshotId,
        promptIdOrCategory,
        routeAssessmentId,
        routeSnapshotId,
        assessmentOrSnapshotLoadingStatus,
        localeRequested,
        assessmentScoresReloadRequested,
        inReviewAllPage,
        isUrlLastLoadedInReviewAllPage,
        assessmentScoresRequireReload,
    ]);

    // Load pre-event answers if the assessment/snapshot is loaded
    useEffect(() => {
        if (assessmentOrSnapshotLoadingStatus === LoadingStatus.Loaded) {
            loadPreEventAnswers();
        }
    }, [assessmentOrSnapshotLoadingStatus, loadPreEventAnswers]);

    // Rebuild side nav when assessment/snapshot loading status changes
    useEffect(() => {
        const items = buildAssessmentSideNavigationPanel();
        dispatch(setAssessmentSectionInAppSideNavigationPanel(items));
    }, [dispatch, buildAssessmentSideNavigationPanel, assessmentOrSnapshotLoadingStatus]);

    // This callback returns the component for this route:
    // 1. An invitation is the user doesn't have access and has an invitation to the assessment
    // 2. A spinner if the assessment is still loading
    // 3. The assessment if it was loaded successfully
    // 4. An error message if the assessment failed to load
    const onAssessmentLoadChange = useCallback(() => {
        if (invitationId && assessmentOrSnapshotLoadingStatus === LoadingStatus.FailedToLoad) {
            // if user does not have access to assessment and there's an invitation id, process the invitation.
            return <AcceptAssessmentInvitation invitationId={invitationId} />;
        } else if (assessmentOrSnapshotLoadingStatus === LoadingStatus.Loading) {
            return (
                <div className='spinner-wrapper'>
                    <Spinner size='normal' data-testid='spinner-loading-assessment' />
                </div>
            );
        } else if (assessmentOrSnapshotLoadingStatus === LoadingStatus.Loaded) {
            return (
                <div>
                    <Switch>
                        {preEventEnabled ? (
                            <AuthenticatedPrivateRoute exact path={`${path}/preevent`} component={AssessmentPreEventManageSponsor} />
                        ) : null}
                        <AuthenticatedPrivateRoute exact path={`${path}/postcomplete`} component={PostCompleteConfirmation} />
                        <AuthenticatedPrivateRoute path={`${path}/reviewall`} component={ResultsReviewAll} />
                        <AuthenticatedPrivateRoute exact path={`${path}/generatereport`} component={GenerateReportMenu} />
                        <AuthenticatedPrivateRoute exact path={`${path}/update`} component={AssessmentUpdate} />
                        <AuthenticatedPrivateRoute exact path={`${path}/comparesnapshots`} component={ManageSnapshots} />
                        <AuthenticatedPrivateRoute exact path={`${path}`} component={AssessmentDetails} />
                    </Switch>
                </div>
            );
        } else if (assessmentOrSnapshotLoadingStatus === LoadingStatus.FailedToLoad) {
            return (
                <Alert
                    dismissAriaLabel={appLabels.user_actions.close_alert}
                    type='error'
                    header={appLabels.assessment.facilitate.error_loading_assessment}
                    action={
                        <Button href={Paths.BASE_ASSESSMENTS_PATH} variant='primary'>
                            {appLabels.side_navigation.view_all_assessments}
                        </Button>
                    }
                ></Alert>
            );
        }
        return null;
    }, [
        invitationId,
        assessmentOrSnapshotLoadingStatus,
        path,
        preEventEnabled,
        appLabels.assessment.facilitate.error_loading_assessment,
        appLabels.side_navigation.view_all_assessments,
        appLabels.user_actions.close_alert,
    ]);

    return onAssessmentLoadChange();
};

export default withLocalizationContext(withAppLabelsContext(withAuthContext(withFlashContext(AssessmentRoutes))));
