import { CookieCategory } from '@amzn/awscat-cookie-management';
import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { SetLocalStorage } from '../../common/context/CookieConsentContext';
import createTemplateReducer from '../administration/manage-templates/create-template/CreateTemplateSlice';
import currentTemplateReducer from '../administration/manage-templates/edit-template/CurrentTemplateSlice';
import currentAssessmentReducer from '../assessments/facilitate/CurrentAssessmentSlice';
import assessmentsReducer from '../assessments/list/AssessmentsSlice';
import recommendationsReducer from '../assessments/results/reviewall/report/new-recommended-actions/RecommendationsSlice';
import assessmentFeaturesReducer from '../common/features/AssessmentFeaturesSlice';
import appHelpPanelReducer from '../common/help-panel/AppHelpPanelSlice';
import appSideNavigationPanelReducer from '../common/side-navigation/AppSideNavigationSlice';
import customersReducer from '../customers/CustomersSlice';
import partnerCustomersReducer from '../customers/PartnerCustomersSlice';

// So that reducers can use Maps and Sets in their state, call `enableMapSet`
// Redux does not call this because these cannot be serialized. So need to exclude any
// reducers that use Maps or Sets from being serialized in `persistReduxStore`
enableMapSet();

// Check if there's a Redux store cached. Delete it after loading, so we don't have inconsistent state
const REDUX_LOCAL_STORAGE_KEY = 'reduxState';
let persistedState = {};
if (localStorage.getItem(REDUX_LOCAL_STORAGE_KEY)) {
    persistedState = JSON.parse(localStorage.getItem(REDUX_LOCAL_STORAGE_KEY));
    localStorage.removeItem(REDUX_LOCAL_STORAGE_KEY);
}

export const store = configureStore({
    reducer: {
        assessmentsState: assessmentsReducer,
        currentAssessmentState: currentAssessmentReducer,
        currentTemplateState: currentTemplateReducer,
        appHelpPanelState: appHelpPanelReducer,
        appSideNavigationPanelState: appSideNavigationPanelReducer,
        customerState: customersReducer,
        partnerCustomerState: partnerCustomersReducer,
        createTemplateState: createTemplateReducer,
        assessmentFeaturesState: assessmentFeaturesReducer,
        recommendationsState: recommendationsReducer,
    },
    preloadedState: persistedState,
    // A2T uses many non-serializable values with Redux (React components, maps, etc.). Suppress this warning
    // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

/** Persists the whole Redux store in local storage. This is read whenever the Redux store is created again.
   This should be used only if you're certain the user isn't going to make any changes before the Redux store is loaded again. */
export const persistReduxStore = (setLocalStorage: SetLocalStorage) => {
    const currentState = { ...store.getState() };
    // Since the Help Panel is a React element, it can't be serialized properly
    delete currentState.appHelpPanelState;
    // currentTemplateState uses a Map, which can't be serialized to local storage / de-serialized when loaded
    delete currentState.currentTemplateState;
    setLocalStorage(REDUX_LOCAL_STORAGE_KEY, JSON.stringify(currentState), [CookieCategory.Functional]);
};

/* Deletes the Redux store persisted in local storage */
export const deletePersistedReduxStore = () => {
    localStorage.removeItem(REDUX_LOCAL_STORAGE_KEY);
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: from rootReducer Reducer<CombinedState<{}>, Action>
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
