import '../reviewall/ResultsReviewAll.scss';

import { AppLabels } from '../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import dompurify from '../../../../common/DomPurify';
import { useAppSelector } from '../../../redux/hooks';

const ManageSnapshotsHelpPanel = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const contextHelp = currentAssessment?.template?.defaults?.report?.snapshots?.context;
    const sanitizedContextHelp = dompurify.sanitize(contextHelp || appLabels.assessment.results.manage_snapshots.info_panel_text);

    return (
        <div>
            <div className='awscat-assessment-report-help-panel' dangerouslySetInnerHTML={{ __html: sanitizedContextHelp }}></div>
        </div>
    );
};

export default withAppLabelsContext(ManageSnapshotsHelpPanel);
