import { companyLogoPlaceholder, companyNamePlaceholder, getPartnerData } from '../../api/prc/PrcClient';
import reportGenerationClient from '../../api/rgs/RgsClient';
import { AppLabels } from '../../common/AppLabels';
import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';

/**
 * Adds AWS/partner-specific data
 */
export const addCustomizations = async (
    params: { [key: string]: any },
    isPartner: boolean,
    appLabels: AppLabels
): Promise<{ [key: string]: any }> => {
    if (isPartner) {
        try {
            params = await getPartnerData(params);
            params['[Facilitated By]'] = appLabels.assessment.results.generate_report.parameters.facilitated_by;
        } catch (err) {
            rumClient.recordError("Failed to retrieve your organization's customizations");
            rumClient.recordError(JSON.stringify(err));
            // Default to using AWS assets. TODO: Remove
            params['[Facilitated By]'] = '';
            params[companyLogoPlaceholder] = '';
            params[companyNamePlaceholder] = Constants.AWS_COMPANY_NAME;
        }
    } else {
        // If not a partner, remove the "Facilitated By" placeholder, as we won't be displaying another logo side-by-side
        params['[Facilitated By]'] = '';
        // Set customizations for AWS users -- this skips a network call
        params[companyLogoPlaceholder] = ''; // AWS logo is already in the report
        params[companyNamePlaceholder] = Constants.AWS_COMPANY_NAME; // Japanese and other languages also use the English "Amazon Web Services"
    }

    return params;
};

// All types of report use this client to call RGS
export const generateReport = async (templateId: string, params: { [key: string]: any }): Promise<string> => {
    try {
        const url = await reportGenerationClient.generateReport(templateId, params);
        return url;
    } catch (error: any) {
        rumClient.recordError('Error generateReport() : ' + error);
        throw Error('Error generateReport() : ' + error);
    }
};

export const downloadUrl: (url: string) => void = (url: string) => {
    if (url && url.startsWith('https://')) {
        window.location.href = url;
    } else {
        rumClient.recordError('Failed to download generated file url=' + url);
        throw new Error('Failed to download generated file');
    }
};

/**
 * Opens a URL in a new tab. For instance, used for opening a locally-created file in a separate tab
 * @param url the URL to open
 * @returns void
 */
export const openUrlInNewTab = (url: string): void => {
    window.open(url, '_blank');
};
