import { FlashType } from '@amzn/awscat-react-components';
import { Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useState } from 'react';

import a2sClient from '../../../../api/a2s/A2SClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { logger } from '../../../../common/Logger';
import rumClient from '../../../../common/monitoring/RumClient';
import { RequestStatus } from '../../../common/RequestStatusFlashbar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createSnapshotSuccess, updateCreateSnapshotModal } from '../../facilitate/CurrentAssessmentSlice';

interface CreateSnapshotDialogProps {
    requestStatus: RequestStatus;
    setRequestStatus: any;
}

const CreateSnapshotDialog: FunctionComponent<AppLabelsContextInterface & CreateSnapshotDialogProps> = ({
    appLabels,
    requestStatus,
    setRequestStatus,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const showSnapshotModal = useAppSelector((state) => state.currentAssessmentState.showCreateSnapshotModal);
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const [snapshotName, setSnapshotName] = useState('');
    const existingSnapshots = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.snapshots);
    const snapshotList = existingSnapshots?.map((s) => s.description);

    const onCreateSnapshot = async () => {
        let newSnapshot = null;
        let errorDescription = null;
        try {
            if (snapshotList?.includes(snapshotName)) {
                errorDescription = appLabels.assessment.snapshot.create_snapshot_error_same_name;
            } else {
                newSnapshot = await a2sClient.createAssessmentSnapshot({
                    assessmentId: assessmentId,
                    description: snapshotName,
                });
            }
        } catch (error) {
            rumClient.recordError(error);
            errorDescription = appLabels.assessment.snapshot.create_snapshot_error;
            logger.error(`Snapshot creation failed for AssessmentId: ${assessmentId} with Error ${error}`);
        }
        dispatch(updateCreateSnapshotModal({ showFlag: false }));
        if (newSnapshot) {
            dispatch(createSnapshotSuccess(newSnapshot.data.createAssessmentSnapshot));
            setRequestStatus({
                ...requestStatus,
                messageType: FlashType.success,
                messageContent: appLabels.assessment.snapshot.create_snapshot_success,
            });
        } else {
            setRequestStatus({
                ...requestStatus,
                messageType: FlashType.error,
                messageContent: errorDescription,
            });
        }
        setSnapshotName('');
    };

    const onCancel = () => {
        setSnapshotName('');
        dispatch(updateCreateSnapshotModal({ showFlag: false }));
    };

    return (
        <Modal
            visible={showSnapshotModal}
            closeAriaLabel='Close modal'
            header={appLabels.assessment.snapshot.create_snapshot}
            onDismiss={onCancel}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onCancel}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button variant='primary' onClick={onCreateSnapshot}>
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <FormField label={`${appLabels.common.description}*`}>
                <Input value={snapshotName} onChange={(event) => setSnapshotName(event.detail.value)} />
            </FormField>
        </Modal>
    );
};

export default withAppLabelsContext(CreateSnapshotDialog);
