import {
    AssessmentTemplateMetadata,
    PrincipalIdToAssessmentTypeActionMapping,
    ShareAssessmentTypeInput,
    ShareableAssessmentTypeAction,
    UnshareAssessmentTypeInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Button, FormField, Grid, Input, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useState } from 'react';

import { SpecialPrincipalCheckbox } from './SpecialPrincipalCheckbox';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { addProviderIfAmazonOrPartner, removeProviderFromPrincipalId } from '../../../../../common/Utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateAssessmentTypePermissions } from '../CurrentTemplateSlice';
import TemplateConstants from '../TemplateConstants';

type ShareWithAssessmentCreatorsProps = AppLabelsContextInterface & {
    canShare: boolean;
    shareAssessmentType: (options: { variables: { input: ShareAssessmentTypeInput } }) => Promise<void>;
    unshareAssessmentType: (options: { variables: { input: UnshareAssessmentTypeInput } }) => Promise<void>;
    saveClickCount: number;
    resetClickCount: number;
    setArePermissionsChanged: (val: boolean) => void;
};
const ShareWithAssessmentCreators: FunctionComponent<ShareWithAssessmentCreatorsProps> = ({
    appLabels,
    canShare,
    shareAssessmentType,
    unshareAssessmentType,
    saveClickCount,
    resetClickCount,
    setArePermissionsChanged,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);

    const [assessmentCreatorToAdd, setAssessmentCreatorToAdd] = useState<string>('');
    const [assessmentCreatorsToAdd, setAssessmentCreatorsToAdd] = useState<string[]>([]);
    const [assessmentCreatorsToRemove, setAssessmentCreatorsToRemove] = useState<string[]>([]);

    // Special checkbox state for AWS and partners
    const [awsChecked, setAwsChecked] = useState<boolean>(
        currentTemplateMetadata.authorizedAssessmentCreators.includes(TemplateConstants.AWS_PRINCIPAL_ID)
    );
    const [partnersChecked, setPartnersChecked] = useState<boolean>(
        currentTemplateMetadata.authorizedAssessmentCreators.includes(TemplateConstants.ALL_PARTNERS_PRINCIPAL_ID)
    );

    // When listing authorized assessment type creators, leave out AWS and all partners, as those have separate checkmarks
    const [assessmentCreatorItems, setAssessmentCreatorItems] = useState<TokenGroupProps.Item[]>(
        currentTemplateMetadata.authorizedAssessmentCreators
            .filter(
                (assessmentTypePrincipalId) =>
                    ![TemplateConstants.AWS_PRINCIPAL_ID, TemplateConstants.ALL_PARTNERS_PRINCIPAL_ID].includes(assessmentTypePrincipalId)
            )
            .map(
                (assessmentTypePrincipalId): TokenGroupProps.Item => ({
                    label: removeProviderFromPrincipalId(assessmentTypePrincipalId),
                })
            )
    );

    const [lastResetClickCount, setLastResetClickCount] = useState<number>(0);
    const [lastSavedClickCount, setLastSavedClickCount] = useState<number>(0);

    // If there are any added/removed items, indicate to the parent component that the permissions have changed
    useEffect(() => {
        if (assessmentCreatorsToAdd.length || assessmentCreatorsToRemove.length) {
            setArePermissionsChanged(true);
        } else {
            setArePermissionsChanged(false);
        }
    }, [assessmentCreatorsToAdd, assessmentCreatorsToRemove, setArePermissionsChanged]);

    // This is an "on reset" useEffect
    useEffect(() => {
        if (lastResetClickCount === resetClickCount) {
            // Reset wasn't clicked
            return;
        }
        setAssessmentCreatorToAdd('');
        setAssessmentCreatorsToAdd([]);
        setAssessmentCreatorsToRemove([]);
        setAwsChecked(currentTemplateMetadata.authorizedAssessmentCreators.includes(TemplateConstants.AWS_PRINCIPAL_ID));
        setPartnersChecked(currentTemplateMetadata.authorizedAssessmentCreators.includes(TemplateConstants.ALL_PARTNERS_PRINCIPAL_ID));

        // Un-delete any items and remove any items "pending save"
        setAssessmentCreatorItems((assessmentCreatorItems) =>
            assessmentCreatorItems
                .map((item) => ({ ...item, disabled: undefined }))
                .filter((item) => item.description !== appLabels.manage_templates.share.pending_save)
        );

        setLastResetClickCount(resetClickCount);
    }, [appLabels.manage_templates.share.pending_save, lastResetClickCount, resetClickCount, currentTemplateMetadata.authorizedAssessmentCreators]);

    // This is the "on save" useEffect
    useEffect(() => {
        if (lastSavedClickCount === saveClickCount) {
            // Save wasn't clicked
            return;
        }

        const assessmentTypePermissionsToRemove: PrincipalIdToAssessmentTypeActionMapping[] = assessmentCreatorsToRemove.map(
            (assessmentTypeUserToRemove): PrincipalIdToAssessmentTypeActionMapping => ({
                principalId: addProviderIfAmazonOrPartner(assessmentTypeUserToRemove),
                assessmentTypeAction: ShareableAssessmentTypeAction.Access,
            })
        );

        const assessmentTypePermissionsToAdd: PrincipalIdToAssessmentTypeActionMapping[] = assessmentCreatorsToAdd.map(
            (assessmentTypeUserToAdd): PrincipalIdToAssessmentTypeActionMapping => ({
                principalId: addProviderIfAmazonOrPartner(assessmentTypeUserToAdd),
                assessmentTypeAction: ShareableAssessmentTypeAction.Access,
            })
        );

        if (assessmentTypePermissionsToRemove.length) {
            const unshareAssessmentTypeInput: UnshareAssessmentTypeInput = {
                assessmentTypeAcronym: currentTemplateMetadata.type,
                permissionsToRemove: assessmentTypePermissionsToRemove,
            };

            unshareAssessmentType({
                variables: {
                    input: unshareAssessmentTypeInput,
                },
            });

            setAssessmentCreatorsToRemove([]);
            // Also remove from the current items
            setAssessmentCreatorItems((assessmentCreatorItems) => assessmentCreatorItems.filter(({ disabled }) => !disabled));
        }

        if (assessmentTypePermissionsToAdd.length) {
            const shareAssessmentTypeInput: ShareAssessmentTypeInput = {
                assessmentTypeAcronym: currentTemplateMetadata.type,
                newPermissions: assessmentTypePermissionsToAdd,
            };

            shareAssessmentType({
                variables: {
                    input: shareAssessmentTypeInput,
                },
            });

            setAssessmentCreatorsToAdd([]);
            // New assessment type users are already in this list. Remove the "pending save" text
            setAssessmentCreatorItems((assessmentCreatorItems) => assessmentCreatorItems.map(({ label }) => ({ label })));
        }

        // Update Redux state
        dispatch(
            updateAssessmentTypePermissions({
                addedPermissions: assessmentTypePermissionsToAdd,
                removedPermissions: assessmentTypePermissionsToRemove,
            })
        );

        setLastSavedClickCount(saveClickCount);
    }, [
        assessmentCreatorItems,
        assessmentCreatorsToAdd,
        assessmentCreatorsToRemove,
        currentTemplateMetadata.templateId,
        currentTemplateMetadata.type,
        dispatch,
        shareAssessmentType,
        unshareAssessmentType,
        saveClickCount,
        lastSavedClickCount,
    ]);

    return (
        <FormField>
            <Grid>
                <SpecialPrincipalCheckbox
                    identifier='aws'
                    setPrincipalsToAdd={setAssessmentCreatorsToAdd}
                    setPrincipalsToRemove={setAssessmentCreatorsToRemove}
                    specialPrincipalId={TemplateConstants.AWS_PRINCIPAL_ID}
                    // "AWS" doesn't need to be localized
                    text='AWS'
                    checked={awsChecked}
                    setChecked={setAwsChecked}
                    principalsArray={currentTemplateMetadata.authorizedAssessmentCreators}
                />
                <SpecialPrincipalCheckbox
                    identifier='partners'
                    setPrincipalsToAdd={setAssessmentCreatorsToAdd}
                    setPrincipalsToRemove={setAssessmentCreatorsToRemove}
                    specialPrincipalId={TemplateConstants.ALL_PARTNERS_PRINCIPAL_ID}
                    text={appLabels.manage_templates.share.all_partners}
                    checked={partnersChecked}
                    setChecked={setPartnersChecked}
                    principalsArray={currentTemplateMetadata.authorizedAssessmentCreators}
                />
            </Grid>
            <Grid gridDefinition={[{ colspan: 7 }, { colspan: 3 }]}>
                <Input
                    data-testid='assessment-creators-textbox'
                    value={assessmentCreatorToAdd}
                    onChange={({ detail: { value } }) => setAssessmentCreatorToAdd(value)}
                    placeholder={appLabels.manage_templates.share.add_assessment_creator}
                    readOnly={!canShare}
                />
                <Button
                    data-testid='assessment-creators-add-button'
                    disabled={!canShare || !assessmentCreatorToAdd}
                    onClick={() => {
                        setAssessmentCreatorToAdd('');

                        // If this item is already present, ignore
                        if (assessmentCreatorItems.some(({ label }) => label === assessmentCreatorToAdd)) {
                            return;
                        }

                        setAssessmentCreatorsToAdd((assessmentTypeUsersToAdd) => assessmentTypeUsersToAdd.concat(assessmentCreatorToAdd));
                        // Add this coowner to the list. Indicate that it's pending save
                        setAssessmentCreatorItems((assessmentCreatorItems) =>
                            assessmentCreatorItems.concat({
                                label: assessmentCreatorToAdd,
                                description: appLabels.manage_templates.share.pending_save,
                            })
                        );
                    }}
                >
                    {appLabels.user_actions.add}
                </Button>
            </Grid>
            <TokenGroup
                data-testid='assessment-creators-token-group'
                items={assessmentCreatorItems}
                limit={TemplateConstants.SHARE_TEMPLATE_TOKEN_GROUP_LIMIT}
                onDismiss={({ detail: { itemIndex } }) => {
                    if (!canShare) {
                        return;
                    }

                    if (assessmentCreatorItems[itemIndex].description === appLabels.manage_templates.share.pending_save) {
                        // If this item is pending save, remove it from the list
                        setAssessmentCreatorsToAdd(
                            assessmentCreatorsToAdd.filter(
                                (assessmentTypeUserToAdd) => assessmentTypeUserToAdd !== assessmentCreatorItems[itemIndex].label
                            )
                        );
                        setAssessmentCreatorItems(assessmentCreatorItems.filter((_, index) => index !== itemIndex));
                    } else {
                        // If it's not pending save, then prepare it for removal
                        setAssessmentCreatorsToRemove(assessmentCreatorsToRemove.concat(assessmentCreatorItems[itemIndex].label));
                        setAssessmentCreatorItems((assessmentCreatorItems) => {
                            assessmentCreatorItems[itemIndex].disabled = true;
                            return assessmentCreatorItems;
                        });
                    }
                }}
            />
        </FormField>
    );
};

export default withAppLabelsContext(ShareWithAssessmentCreators);
