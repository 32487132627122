import { CustomerCloudMaturity } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { AssessmentTypeRecommendationEngine, RecommendationAlert } from './AssessmentTypeRecommendationEngine';
import { AppLabels } from '../../common/AppLabels';
import { CustomerProfile, CustomerSegmentId } from '../../models/CustomerProfile';

export class MraRecommendationEngine implements AssessmentTypeRecommendationEngine {
    readonly targetAssessmentType = 'MRA2';

    getTargetAssessmentType(): string {
        return this.targetAssessmentType;
    }

    /**
     * Returns assessment type recommendation alert based on provided customer profile.
     * Returns null if no specific recommendation alert is available.
     *
     * @param customerProfile customer profile for recommendation consideration
     */
    getRecommendationAlert(customerProfile: CustomerProfile, appLabels: AppLabels): RecommendationAlert | null {
        if (!customerProfile) {
            return null;
        }

        // MRA not recommended when customer cloud maturity is HIGH and already exists plan and alignment
        if (customerProfile.customerCloudMaturity === CustomerCloudMaturity.HIGH && customerProfile.customerPlanAndAlignmentExists === true) {
            // This warning should not be shown for SUP - https://i.amazon.com/issues/A2T-1360?selectedConversation=b0230a46-347e-426e-b7c0-3d13d4132c6a
            if (customerProfile.targetSegmentId !== CustomerSegmentId.SUP) {
                return {
                    alertType: 'warning',
                    description: appLabels.assessment.create.recommendation_alerts.mra_not_recommended,
                };
            }
        }

        return null;
    }

    /**
     * Returns overridden targetSegmentId based on provided customer profile. See https://i.amazon.com/A2T-1961
     * @param customerProfile customer profile for recommendation consideration
     * @returns overridden customer segment id if criteria are met, otherwise returns the original target segment id
     */
    getOverriddenTargetSegmentId(customerProfile: CustomerProfile): CustomerSegmentId {
        // Recommend [ADV - Advanced] type when customer cloud maturity is HIGH and already exists plan and alignment
        if (customerProfile?.customerCloudMaturity === CustomerCloudMaturity.HIGH && customerProfile?.customerPlanAndAlignmentExists === true) {
            return CustomerSegmentId.ADV;
        }
        // Otherwise, keep the original targetSegment or set default targetSegment to ENT
        return customerProfile?.targetSegmentId || CustomerSegmentId.ENT;
    }
}
