import { IsNotEmpty, Matches, MaxLength, MinLength } from 'class-validator';

export class CreateSessionUserInput {
    @IsNotEmpty({
        message: 'assessment_live_session_create_session_title_validation_min_char',
    })
    @MaxLength(50, {
        message: 'assessment_live_session_create_session_title_validation_max_char',
    })
    sessionTitle: string;

    @MinLength(8, {
        message: 'assessment_live_session_create_password_validation_min_char',
    })
    @MaxLength(20, {
        message: 'assessment_live_session_create_password_validation_max_char',
    })
    @Matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/, {
        message: 'assessment_live_session_create_password_validation_mix_char',
    })
    sessionPasscode: string;
}
