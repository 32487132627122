import { AssessmentResponseTypes, AssessmentTemplate } from '@amzn/awscat-aws-assessment-service-typescript-client';
import {
    Alert,
    Checkbox,
    ColumnLayout,
    Container,
    DatePicker,
    FormField,
    Header,
    Input,
    RadioGroup,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { ValidationError } from 'class-validator';
import { useCallback, useEffect } from 'react';

import { AssessmentCreateState } from './AssessmentCreateState';
import { YesNoValue } from '../../../api/a2s/A2STypes';
import { assessmentTypeRecommendationEngineRegistry } from '../../../assessments/recommendation/RecommendationEngines';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { errorLookup } from '../../../common/ValidatorUtils';
import rumClient from '../../../common/monitoring/RumClient';
import { DeliveredByValue } from '../../../models/Assessment';

type OnChangeHandler = (subStateValue: Partial<AssessmentCreateState>) => void;

type DetailPanelProps = {
    assessmentTemplates: AssessmentTemplate[];
    state: AssessmentCreateState;
    onChange: OnChangeHandler;
    validationErrors: ValidationError[];
    isReadOnly: boolean;
} & AppLabelsContextInterface;

const DetailPanel = ({ assessmentTemplates, state, onChange, validationErrors, appLabels, isReadOnly }: DetailPanelProps): JSX.Element => {
    const detailPanelData = state;
    const { customerProfile } = detailPanelData;

    useEffect(() => {
        // At initialization, set the default targetSegmentId based on customer profile setup on the previous page
        const defaultTargetSegmentId = assessmentTypeRecommendationEngineRegistry.getOverriddenTargetSegmentId(detailPanelData.type, customerProfile);
        onChange({ customerProfile: { ...customerProfile, targetSegmentId: defaultTargetSegmentId } });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const metadataIsExcluded = useCallback(
        (metadataKey: string) => {
            const assessmentTemplate = assessmentTemplates?.find((t) => t.type === detailPanelData.type);
            const excludeOptionalMetadataKeys = assessmentTemplate?.metadataConfig?.excludeOptionalMetadataKeys ?? [];
            return excludeOptionalMetadataKeys.some((k: string) => k === metadataKey);
        },
        [assessmentTemplates, detailPanelData.type]
    );

    const onDetailPanelChange = useCallback(
        (subStateValue: Partial<AssessmentCreateState>) => {
            onChange(subStateValue);
        },
        [onChange]
    );

    const getErrorText = errorLookup<AssessmentCreateState>(validationErrors);

    const getAssessmentTypeRecommendation = useCallback(() => {
        const recommendation = assessmentTypeRecommendationEngineRegistry.getRecommendationAlert(detailPanelData.type, customerProfile, appLabels);
        if (!recommendation) {
            return null;
        }

        return (
            <Alert data-testid='alert-type-not-recommended' type={recommendation.alertType}>
                {recommendation.description}
            </Alert>
        );
    }, [appLabels, customerProfile, detailPanelData.type]);

    const includeApplicabilitySelections = useCallback(() => {
        // Check if assessment template has defined applicability criteria.
        // If defined, create selections based on the configured prompts.
        const applicabilityPrompts = detailPanelData.applicability?.prompts;
        if (!applicabilityPrompts || !applicabilityPrompts.length) {
            return null;
        }

        return applicabilityPrompts.map((applicabilityPrompt) => {
            if (applicabilityPrompt.responseType !== AssessmentResponseTypes.SINGLE_SELECTION || !applicabilityPrompt.id) {
                // For now, only support single selection response type.
                // Applicability prompt id (e.g. targetSegmentId) defines the attribute to be saved to assessment metadata.
                // ToDo: make detailPanelData state support dynamic attributes instead of hardcoding targetSegmentId
                return null;
            }
            const selectionItems = applicabilityPrompt.responseSelections.selections.map((selection) => {
                return { label: selection.selectionLabel, value: selection.selectionId };
            });

            const selectedOption = selectionItems.find((item) => item.value === customerProfile[applicabilityPrompt.id]);
            return (
                <FormField label={applicabilityPrompt.label.text} stretch={true}>
                    <Select
                        placeholder={applicabilityPrompt.responseSelections.selectionDescription || ''}
                        options={selectionItems}
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                            onDetailPanelChange({ customerProfile: { ...customerProfile, [applicabilityPrompt.id]: detail.selectedOption.value } })
                        }
                        expandToViewport
                        selectedAriaLabel={appLabels.user_actions.selected}
                        data-testid='assessment-target-segment-select'
                    />
                </FormField>
            );
        });
    }, [appLabels.user_actions.selected, customerProfile, detailPanelData.applicability?.prompts, onDetailPanelChange]);

    const addMetadata = useCallback(() => {
        const assessmentTemplate = assessmentTemplates?.find((t) => t.type === detailPanelData.type);
        const customMetadata = assessmentTemplate?.metadataConfig?.customMetadata;
        if (!customMetadata) {
            return null;
        }

        return customMetadata.map((metadataConfig) => {
            let metadataState = detailPanelData.metadata?.find((m) => m?.key === metadataConfig.key);
            if (!metadataState && metadataConfig.defaultValue) {
                // create a new metadata entry with default
                metadataState = { key: metadataConfig.key, value: metadataConfig.defaultValue };
            }

            const updateMetadataList = (key, newValue) => {
                if (!metadataState || !detailPanelData.metadata?.some((m) => m.key === key)) {
                    // does not have initial default value, create the new metadata
                    return detailPanelData.metadata?.concat([{ key, value: newValue }]);
                }
                return detailPanelData.metadata?.map((metadata) => {
                    if (metadata.key === key) {
                        return { key, value: newValue };
                    }
                    return metadata;
                });
            };

            switch (metadataConfig.valueType) {
                case 'text':
                    return (
                        <FormField label={metadataConfig.keyName} stretch={true} errorText={getErrorText('metadata')}>
                            <Input
                                data-testid={`assessment-${metadataConfig.key}`}
                                placeholder={metadataConfig.valueHint ?? ''}
                                value={metadataState?.value}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataConfig.key, value) })}
                                disabled={isReadOnly}
                            />
                        </FormField>
                    );
                case 'yesno':
                    return (
                        <FormField label={metadataConfig.keyName} stretch={true} errorText={getErrorText('metadata')}>
                            <RadioGroup
                                data-testid={`assessment-${metadataConfig.key}`}
                                value={metadataState?.value}
                                items={[
                                    {
                                        value: YesNoValue.YES,
                                        label: appLabels.common.yes,
                                        disabled: isReadOnly,
                                    },
                                    {
                                        value: YesNoValue.NO,
                                        label: appLabels.common.no,
                                        disabled: isReadOnly,
                                    },
                                ]}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataConfig.key, value) })}
                            />
                        </FormField>
                    );
                case 'dateValue':
                    return (
                        <FormField label={metadataConfig.keyName} stretch={true} errorText={getErrorText('metadata')}>
                            <DatePicker
                                data-testid={`assessment-${metadataConfig.key}`}
                                value={metadataState?.value}
                                openCalendarAriaLabel={(selectedDate) => {
                                    return appLabels.common.choose_date + (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '');
                                }}
                                nextMonthAriaLabel={appLabels.common.next_month}
                                placeholder={appLabels.common.date_placeholder}
                                previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                todayAriaLabel={appLabels.assessment.create.today}
                                disabled={isReadOnly}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataConfig.key, value) })}
                            />
                        </FormField>
                    );
                default:
                    rumClient.recordError(`addMetadata: invalid metadataConfig.valueType=${metadataConfig.valueType}`);
                    return null;
            }
        });
    }, [
        appLabels.assessment.create.previous_month,
        appLabels.assessment.create.today,
        appLabels.common.choose_date,
        appLabels.common.date_placeholder,
        appLabels.common.next_month,
        appLabels.common.no,
        appLabels.common.selected_date,
        appLabels.common.yes,
        assessmentTemplates,
        detailPanelData.metadata,
        detailPanelData.type,
        getErrorText,
        isReadOnly,
        onDetailPanelChange,
    ]);

    return (
        <Container header={<Header variant='h2'>{appLabels.assessment.create.details}</Header>}>
            <SpaceBetween size='l'>
                {getAssessmentTypeRecommendation()}
                <FormField label={`${appLabels.assessment.create.details_account}*`} stretch={true} errorText={getErrorText('account')}>
                    <Input
                        data-testid='assessment-account-name-input'
                        value={detailPanelData.account?.accountName || ''}
                        placeholder={''}
                        disabled={true}
                    />
                </FormField>
                {includeApplicabilitySelections()}
                <FormField label={`${appLabels.assessment.create.details_description}*`} stretch={true} errorText={getErrorText('description')}>
                    <Input
                        data-testid='assessment-description-input'
                        value={detailPanelData.description}
                        ariaRequired={true}
                        placeholder={appLabels.assessment.create.details_description_placeholder}
                        onChange={({ detail: { value } }) => onDetailPanelChange({ description: value })}
                        disabled={isReadOnly}
                    />
                </FormField>
                {metadataIsExcluded('mapProgramEngagementId') ? null : (
                    <FormField label={`${appLabels.assessment.create.map_id}*`} stretch={true} errorText={getErrorText('mapProgramEngagementId')}>
                        <Input
                            data-testid='assessment-map-id-input'
                            value={detailPanelData.mapProgramEngagementId}
                            placeholder={appLabels.assessment.create.map_id_placeholder}
                            onChange={({ detail: { value } }) => onDetailPanelChange({ mapProgramEngagementId: value })}
                            disabled={isReadOnly}
                        />
                    </FormField>
                )}
                {metadataIsExcluded('internalContact') ? null : (
                    <FormField label={`${appLabels.assessment.create.customer_contact}*`} stretch={true} errorText={getErrorText('internalContact')}>
                        <Input
                            data-testid='assessment-internal-contact-input'
                            placeholder={appLabels.assessment.create.customer_contact_placeholder}
                            value={detailPanelData.internalContact}
                            onChange={({ detail: { value } }) => onDetailPanelChange({ internalContact: value })}
                            disabled={isReadOnly}
                        />
                    </FormField>
                )}
                {metadataIsExcluded('workshopDate') && metadataIsExcluded('readoutDate') ? null : (
                    <FormField stretch={true} constraintText={appLabels.assessment.create.workshopdate_readoutdate_constraint}>
                        <ColumnLayout columns={2}>
                            {metadataIsExcluded('workshopDate') ? null : (
                                <FormField
                                    label={`${appLabels.assessment.create.workshop_date}*`}
                                    stretch={true}
                                    className='date-time-container'
                                    errorText={getErrorText('workshopDate')}
                                >
                                    <DatePicker
                                        data-testid='assessment-workshop-date-picker'
                                        onChange={({ detail: { value } }) => onDetailPanelChange({ workshopDate: value })}
                                        value={detailPanelData.workshopDate || ''}
                                        openCalendarAriaLabel={(selectedDate) => {
                                            return (
                                                appLabels.common.choose_date +
                                                (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                                            );
                                        }}
                                        nextMonthAriaLabel={appLabels.common.next_month}
                                        placeholder={appLabels.common.date_placeholder}
                                        previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                        todayAriaLabel={appLabels.assessment.create.today}
                                        disabled={isReadOnly}
                                    />
                                </FormField>
                            )}
                            {metadataIsExcluded('readoutDate') ? null : (
                                <FormField
                                    label={`${appLabels.assessment.create.readout_date}*`}
                                    stretch={true}
                                    className='date-time-container'
                                    errorText={getErrorText('readoutDate')}
                                >
                                    <DatePicker
                                        data-testid='assessment-readout-date-picker'
                                        onChange={({ detail: { value } }) => onDetailPanelChange({ readoutDate: value })}
                                        value={detailPanelData.readoutDate || ''}
                                        openCalendarAriaLabel={(selectedDate) => {
                                            return (
                                                appLabels.common.choose_date +
                                                (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                                            );
                                        }}
                                        nextMonthAriaLabel={appLabels.common.next_month}
                                        placeholder={appLabels.common.date_placeholder}
                                        previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                        todayAriaLabel={appLabels.assessment.create.today}
                                        disabled={isReadOnly}
                                    />
                                </FormField>
                            )}
                        </ColumnLayout>
                    </FormField>
                )}
                {addMetadata()}
                <FormField label={`${appLabels.assessment.create.delivered_by}*`} stretch={true} errorText={getErrorText('deliveredBy')}>
                    <Select
                        data-testid='assessment-delivered-by-select'
                        selectedOption={detailPanelData.deliveredBy}
                        onChange={({ detail }) => onDetailPanelChange({ deliveredBy: detail.selectedOption })}
                        // ToDo: delivered by options should come from template - https://i.amazon.com/issues/A2T-1755
                        // Todo: display of delivered by options should be localized - https://i.amazon.com/issues/A2T-1883
                        options={[
                            { label: DeliveredByValue.MIGRATION_BDM, value: DeliveredByValue.MIGRATION_BDM },
                            { label: DeliveredByValue.PROSERVE, value: DeliveredByValue.PROSERVE },
                            { label: DeliveredByValue.TAM, value: DeliveredByValue.TAM },
                            { label: DeliveredByValue.ACCOUNT_SA, value: DeliveredByValue.ACCOUNT_SA },
                            { label: DeliveredByValue.MIGRATION_SA, value: DeliveredByValue.MIGRATION_SA },
                            { label: DeliveredByValue.WWSO_SPECIALIST, value: DeliveredByValue.WWSO_SPECIALIST },
                            { label: DeliveredByValue.CSM, value: DeliveredByValue.CSM },
                            { label: DeliveredByValue.AWS_PARTNER_ORGANIZATION, value: DeliveredByValue.AWS_PARTNER_ORGANIZATION },
                            { label: DeliveredByValue.AWS_PARNTER_NETWORK, value: DeliveredByValue.AWS_PARNTER_NETWORK },
                            { label: DeliveredByValue.MIGRATION_COMPENTENCY_PARTNER, value: DeliveredByValue.MIGRATION_COMPENTENCY_PARTNER },
                            { label: DeliveredByValue.OTHER, value: DeliveredByValue.OTHER },
                        ]}
                        selectedAriaLabel={appLabels.assessment.create.delivered_by}
                        disabled={isReadOnly}
                    />
                </FormField>
                <FormField constraintText={appLabels.assessment.create.demo_or_test_constraint} stretch={true}>
                    <Checkbox
                        data-testid='assessment-is-demo-test-check-box'
                        checked={state.isDemoTest}
                        onChange={({ detail: { checked } }) => onDetailPanelChange({ isDemoTest: checked })}
                        disabled={isReadOnly}
                    >
                        {appLabels.assessment.create.demo_or_test}
                    </Checkbox>
                </FormField>
                <FormField constraintText={appLabels.assessment.create.optout_recommendation_constraint} stretch={true}>
                    <Checkbox
                        data-testid='assessment-opt-out-solutions-recommendation-check-box'
                        checked={state.optOutSolutionsRecommendation}
                        onChange={({ detail: { checked } }) => onDetailPanelChange({ optOutSolutionsRecommendation: checked })}
                        disabled={isReadOnly}
                    >
                        {appLabels.assessment.create.optout_recommendation}
                    </Checkbox>
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default withAppLabelsContext(DetailPanel);
