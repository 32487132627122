import { Endpoint as AuthEndpoint, Environment as AuthEnvironment, GetEndpoint as GetAuthEndpoint } from '@amzn/awscat-portal-authentication-library';

export interface RumConfig {
    appId: string;
    identityPoolId: string;
    guestRoleArn: string;
    region: string;
}
export interface FrontendEnvironment {
    region: string;
    portalClient: string;
    portalClientId: string;
    backendEnvironment: Environment;
    a2sApiEndpoint: string;
    wbsApiEndpoint: string;
    feedbackApiEndpoint: string;
    casApiEndpoint: string;
    rsApiEndpoint: string;
    summaryGenerationApiEndpoint: string;
    dataCollectionEnvironment: Environment;
    reportGenerationEnvironment: Environment;
    partnerReportCustomizationEnvironment: Environment;
    templateManagementEnvironment: Environment;
    rumConfig?: RumConfig;
}

export interface EnvironmentConfig extends FrontendEnvironment {
    environment: Environment;
    authEndpoint: AuthEndpoint;
}

export enum Environment {
    localdev = 'localdev',
    beta = 'beta',
    gamma = 'gamma',
    prod = 'prod',
}

const environmentMap: { [key in keyof typeof Environment]: FrontendEnvironment } = {
    localdev: {
        // Dev is using Beta backend normally
        region: 'us-west-2',
        portalClient: 'a2t-localdev',
        portalClientId: '57mpvk7fk3sibcpri7nvd0t8ki', // cat-users:a2t-localdev
        backendEnvironment: Environment.beta,
        a2sApiEndpoint: 'https://a2s.beta.accelerate.proserve.aws.dev/graphql',
        wbsApiEndpoint: 'https://uxagsvbd3rctraouwmlytvgbcu.appsync-api.us-west-2.amazonaws.com/graphql',
        feedbackApiEndpoint: 'https://jjm8i2fivh.execute-api.us-west-2.amazonaws.com/beta/',
        casApiEndpoint: 'https://cas.beta.accelerate.proserve.aws.dev',
        rsApiEndpoint: 'https://rs.beta.accelerate.proserve.aws.dev',
        summaryGenerationApiEndpoint: 'https://fdc50v8e6l.execute-api.us-west-2.amazonaws.com/prod/', // SGS beta
        dataCollectionEnvironment: Environment.beta,
        reportGenerationEnvironment: Environment.localdev,
        partnerReportCustomizationEnvironment: Environment.localdev,
        templateManagementEnvironment: Environment.localdev,
        // Localdev RUM config connects to a manually created app monitor in beta. Uses same identity pool /
        // role as beta
        rumConfig: {
            appId: '532e17be-2543-4f76-a9ee-d7a842d82189',
            identityPoolId: 'us-east-1:7e0954f2-b96a-4350-a270-d467c868d7cb',
            guestRoleArn: 'arn:aws:iam::702499889602:role/RUM-Monitor-us-east-1-702499889602-AssessmentTool-Unauth',
            region: 'us-east-1',
        },
    },
    beta: {
        region: 'us-west-2',
        portalClient: 'a2t',
        portalClientId: '1objr9lk10o0o67kla3qf74nci', // cat-users:a2t
        backendEnvironment: Environment.beta,
        a2sApiEndpoint: 'https://a2s.beta.accelerate.proserve.aws.dev/graphql',
        wbsApiEndpoint: 'https://uxagsvbd3rctraouwmlytvgbcu.appsync-api.us-west-2.amazonaws.com/graphql',
        feedbackApiEndpoint: 'https://jjm8i2fivh.execute-api.us-west-2.amazonaws.com/beta/',
        casApiEndpoint: 'https://cas.beta.accelerate.proserve.aws.dev',
        rsApiEndpoint: 'https://rs.beta.accelerate.proserve.aws.dev',
        summaryGenerationApiEndpoint: 'https://fdc50v8e6l.execute-api.us-west-2.amazonaws.com/prod/', // SGS beta
        dataCollectionEnvironment: Environment.beta,
        reportGenerationEnvironment: Environment.beta,
        partnerReportCustomizationEnvironment: Environment.beta,
        templateManagementEnvironment: Environment.beta,
        rumConfig: {
            appId: 'c66945a6-f8a1-4703-a806-da38bf078cf5',
            identityPoolId: 'us-east-1:7e0954f2-b96a-4350-a270-d467c868d7cb',
            guestRoleArn: 'arn:aws:iam::702499889602:role/RUM-Monitor-us-east-1-702499889602-AssessmentTool-Unauth',
            region: 'us-east-1',
        },
    },
    gamma: {
        region: 'us-west-2',
        portalClient: 'a2t',
        portalClientId: '3jhv1cdj0rkrbaatb8fsclbbqr', // cat-users:a2t
        backendEnvironment: Environment.gamma,
        a2sApiEndpoint: 'https://a2s.gamma.accelerate.proserve.aws.dev/graphql',
        wbsApiEndpoint: 'https://xp446sppdnb7xazhr44btphbqa.appsync-api.us-west-2.amazonaws.com/graphql',
        feedbackApiEndpoint: 'https://8qmx2lu7n4.execute-api.us-west-2.amazonaws.com/gamma/',
        casApiEndpoint: 'https://cas.gamma.accelerate.proserve.aws.dev',
        rsApiEndpoint: 'https://rs.gamma.accelerate.proserve.aws.dev',
        summaryGenerationApiEndpoint: 'https://lw2f2gplx2.execute-api.us-west-2.amazonaws.com/prod/', // SGS gamma
        dataCollectionEnvironment: Environment.gamma,
        reportGenerationEnvironment: Environment.gamma,
        partnerReportCustomizationEnvironment: Environment.gamma,
        templateManagementEnvironment: Environment.gamma,
        rumConfig: {
            appId: '2d5b04d2-d2fc-4bab-8cb4-e1a342467770',
            identityPoolId: 'us-east-1:9d685799-23dc-4a2f-92de-08e36b0cc968',
            guestRoleArn: 'arn:aws:iam::517625568103:role/RUM-Monitor-us-east-1-517625568103-AssessmentTool-Unauth',
            region: 'us-east-1',
        },
    },
    prod: {
        region: 'us-west-2',
        portalClient: 'a2t',
        portalClientId: '46v8jb2bdsmv5sc0pkjg0lmhrj', // cat-users:a2t
        backendEnvironment: Environment.prod,
        a2sApiEndpoint: 'https://a2s.accelerate.amazonaws.com/graphql',
        wbsApiEndpoint: 'https://stsmf3qwgfe3vh5q7mljuo42ge.appsync-api.us-west-2.amazonaws.com/graphql',
        feedbackApiEndpoint: 'https://8od13psy0k.execute-api.us-west-2.amazonaws.com/production/',
        casApiEndpoint: 'https://cas.accelerate.amazonaws.com',
        rsApiEndpoint: 'https://rs.accelerate.amazonaws.com',
        summaryGenerationApiEndpoint: 'https://qf46msqrl9.execute-api.us-west-2.amazonaws.com/prod/', // SGS prod
        dataCollectionEnvironment: Environment.prod,
        reportGenerationEnvironment: Environment.prod,
        partnerReportCustomizationEnvironment: Environment.prod,
        templateManagementEnvironment: Environment.prod,
        rumConfig: {
            appId: 'bfa05ad0-1aa3-4d68-bc2d-06e32d12f953',
            identityPoolId: 'us-east-1:bc2dac78-1496-4751-91ea-d69fccfbeee7',
            guestRoleArn: 'arn:aws:iam::687727339099:role/RUM-Monitor-us-east-1-687727339099-AssessmentTool-Unauth',
            region: 'us-east-1',
        },
    },
};

export const GetEnvironment = (): Environment => {
    switch (window.location.hostname) {
        case 'a2t.beta.accelerate.proserve.aws.dev':
            return Environment.beta;
        case 'a2t.gamma.accelerate.proserve.aws.dev':
            return Environment.gamma;
        case 'a2t.accelerate.amazonaws.com':
            return Environment.prod;
        default:
            return Environment.localdev;
    }
};

export const GetEnvironmentConfig = (): EnvironmentConfig => {
    const environment: Environment = GetEnvironment();
    const authEndpoint: AuthEndpoint = GetAuthEndpoint(environment as AuthEnvironment);
    const frontendEnvironment: FrontendEnvironment = environmentMap[environment];
    const config: EnvironmentConfig = Object.assign(frontendEnvironment, { environment, authEndpoint });
    return config;
};
