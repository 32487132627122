import { AwsAssessmentSummaryGenerationServiceClient } from '@amzn/aws-assessment-summary-generation-service-client';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';

// TODO: consume these types from the SGS client package, once created
export interface SummarizationComment {
    commentText: string;
    writtenBy: string;
    role: string;
}

export interface SummarizationQuestion {
    questionText: string;
    comments: SummarizationComment[];
    response: string;
}

export interface SummarizationAction {
    action: string;
    priority: string;
    effort: string;
}

export interface SummarizationAssessmentMetadata {
    assessmentName: string;
    assessmentTypeName: string;
    assessmentTypeDescription: string;
    assessmentTypeAbbreviation: string;
    customerName: string;
}

export interface SummarizationInput {
    questions: SummarizationQuestion[];
    recommendedActions: SummarizationAction[];
    assessmentMetadata: SummarizationAssessmentMetadata;
}

export interface SummarizationOutput {
    summary: string;
}

const envConfig = GetEnvironmentConfig();
let sgsClient: AwsAssessmentSummaryGenerationServiceClient = null;

export function getSummaryGenerationServiceClient(): AwsAssessmentSummaryGenerationServiceClient {
    if (sgsClient) {
        return sgsClient;
    }
    const client = new AwsAssessmentSummaryGenerationServiceClient({ endpoint: envConfig.summaryGenerationApiEndpoint });
    client.middlewareStack?.add(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (next: any) => async (args: any) => {
            args.request.headers['Authorization'] = await getJwtToken();
            const result = await next(args);
            return result;
        },
        {
            step: 'build',
        }
    );
    sgsClient = client;
    return sgsClient;
}
