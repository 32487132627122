export default {
    AWS_PRINCIPAL_ID: 'AWS-orgId',
    ALL_PARTNERS_PRINCIPAL_ID: 'APN_ORGS_ALL-orgId',
    METADATA_PROMPT_DEFAULT_VALUE_KEY: 'defaultValue',
    SHARE_TEMPLATE_TOKEN_GROUP_LIMIT: 4,
    QUESTIONNAIRE_ANSWERS_NAMESPACE: 'questionnaireAnswers',
    TRUE_STRING_VALUE: 'true',
    FALSE_STRING_VALUE: 'false',
    SEARCH_PARAM_LOCALE: 'locale',
    SEARCH_PARAM_VIEW_ID: 'viewId',
    SEARCH_PARAM_MODULE_ID: 'moduleId',
    SEARCH_PARAM_PROMPT_ID: 'promptId',
};
