/* eslint-disable max-len */

import LandingPage, { LandingProps } from './LandingPage';
import { AppLabels } from '../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';

const Landing = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const data: LandingProps = {
        title: appLabels.landing.title,
        serviceName: appLabels.landing.serviceName,
        majorDescription: appLabels.landing.majorDescription,
        minorDescription: appLabels.landing.minorDescription,
        whatItDoes: {
            title: appLabels.landing.whatItDoes.title,
            description: appLabels.landing.whatItDoes.description,
        },
        gettingStarted: {
            title: appLabels.landing.gettingStarted.title,
            links: [{ linkLocation: '/assessments/create', linkText: appLabels.landing.gettingStarted.links[0].linkText }],
        },
        benefitsAndFeatures: {
            title: appLabels.landing.benefitsAndFeatures.title,
            items: appLabels.landing.benefitsAndFeatures.items,
        },
        moreResources: {
            title: appLabels.landing.moreResources.title,
            items: appLabels.landing.moreResources.items,
        },
        relatedServices: {
            title: appLabels.landing.relatedServices.title,
            items: appLabels.landing.relatedServices.items,
        },
    };
    return <LandingPage {...data} />;
};

export default withAppLabelsContext(Landing);
