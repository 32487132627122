import { CookieCategory, CookieConsentManager } from '@amzn/awscat-cookie-management';
import CookieDefinitions from '@amzn/shortbread/dist/definitions/cookie-definitions';
import { FC, createContext, useEffect, useRef, useState } from 'react';

import { ShortbreadRegistry } from '../CookieRegistry';
import rumClient from '../monitoring/RumClient';

export type GetLocalStorage = (key: string, cookieCategories?: CookieCategory[] | undefined) => string;
export type SetLocalStorage = (key: string, value: string, cookieCategories?: CookieCategory[] | undefined) => void;

const defaultCookie: CookieDefinitions.ConsentCookie = {
    essential: true,
    functional: true,
    performance: true,
    advertising: true,
};

const functionNotSet = (functionName: string) => () => {
    rumClient.recordError(`${functionName} not set`);
    return '';
};

export interface ICookieConsentContext {
    cookie: CookieDefinitions.ConsentCookie;
    updateCookie: (cookie: CookieDefinitions.ConsentCookie) => void;
    customizeCookies: () => void;
    getLocalStorage: GetLocalStorage;
    setLocalStorage: SetLocalStorage;
}

export const CookieConsentContext = createContext<ICookieConsentContext>({
    cookie: defaultCookie,
    updateCookie: () => {
        // do nothing
    },
    customizeCookies: () => {
        // do nothing
    },
    getLocalStorage: functionNotSet('getLocalStorage'),
    setLocalStorage: functionNotSet('setLocalStorage'),
});

export const CookieConsentProvider: FC = ({ children }) => {
    const [cookie, setCookie] = useState<CookieDefinitions.ConsentCookie>(defaultCookie);
    const cookieManager = useRef<CookieConsentManager>();

    const onConsentChanged = (cookieChanged: CookieDefinitions.ConsentCookie) => {
        setCookie(cookieChanged);
    };

    useEffect(() => {
        /*
         * Shortbread Integration for EU Cookie compliance: https://w.amazon.com/bin/view/Shortbread/usage
         *
         * It will only be invoked when shortbread's geoip location detects traffic from EU.
         * For testing, use AnyConnect VPN and connect to an EU endoint such as 'Frankfurt, DE', and
         */
        cookieManager.current = new CookieConsentManager({
            domain: window.location.hostname,
            language: window.navigator.language as any,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parent: document.getElementById('root')!,
            registry: ShortbreadRegistry,
            onConsentChanged: onConsentChanged,
        });
        cookieManager.current.checkForCookieConsent();
    }, []);

    return (
        <CookieConsentContext.Provider
            value={{
                cookie,
                updateCookie: setCookie,
                customizeCookies:
                    cookieManager.current?.customizeCookies ??
                    (() => {
                        // do nothing
                    }),
                getLocalStorage: cookieManager.current?.getLocalStorage ?? functionNotSet('getLocalStorage'),
                setLocalStorage: cookieManager.current?.setLocalStorage ?? functionNotSet('setLocalStorage'),
            }}
        >
            {children}
        </CookieConsentContext.Provider>
    );
};

/**
 * Checks for cookie consent whenever the cookie manager changes
 * @param cookieManager the cookie manager to watch
 * @returns void
 */
export const useCookieConsentCheck = (cookieManager: CookieConsentManager): void => {
    useEffect(() => {
        cookieManager.checkForCookieConsent();
    }, [cookieManager]);
};
