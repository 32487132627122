import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
    TextFilter,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CustomerFilterContextInterface, withCustomerFilterContext } from './CustomerFilterContext';
import { CustomerViewModel } from './CustomerViewModel';
import { CustomersViewState } from './CustomersViewState';
import { AppLabels } from '../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import { isAllCustomerSelected } from '../../common/Utils';
import EmptyTable from '../common/EmptyTable';

type CustomersTableProps = {
    state: CustomersViewState;
} & CustomerFilterContextInterface;

const filterItems = (item: CustomerViewModel, filteringText: string): boolean => {
    const rowItems = [item.accountName, item.id];
    if (item.industry) {
        rowItems.push(item.industry);
    }
    if (item.territory) {
        rowItems.push(item.territory);
    }
    if (item.website) {
        rowItems.push(item.website);
    }
    const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
    return rowItems.some((rowItem) => rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase));
};

const CustomersTable = (props: CustomersTableProps & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;

    const history = useHistory();

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_CUSTOMERS,
    });
    const [selectedItems] = useState<CustomerViewModel[]>(props.state.customers.filter((i) => i.accountName === props.customer));

    const columnDefs: TableProps.ColumnDefinition<CustomerViewModel>[] = [
        {
            id: 'customerAccountName',
            header: appLabels.customer_selection.customer_account_name,
            cell: (e) => e.accountName,
        },
        {
            id: 'industry',
            header: appLabels.customer_selection.customer_industry,
            cell: (e) => e.industry,
        },
        {
            id: 'territory',
            header: appLabels.customer_selection.customer_territory,
            cell: (e) => e.territory,
        },
        {
            id: 'website',
            header: appLabels.customer_selection.customer_website,
            cell: (e) => e.website,
        },
        {
            id: 'action',
            header: appLabels.customer_selection.action,
            cell: (e) =>
                e.accountName !== props.customer ? (
                    <Button
                        variant='normal'
                        onClick={() => {
                            props.setCustomer(e.accountName);
                            history.push('/assessments');
                        }}
                    >
                        {appLabels.customer_selection.action_select}
                    </Button>
                ) : (
                    <Button variant='normal' disabled={true}>
                        {appLabels.customer_selection.selected_customer}
                    </Button>
                ),
        },
    ];

    const { items, collectionProps, filterProps, paginationProps } = useCollection(props.state.customers, {
        filtering: {
            empty: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            noMatch: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });
    const tableOptionsSetting = () => [
        { label: `10 ${appLabels.customer_selection.customers}`, value: 10 },
        { label: `25 ${appLabels.customer_selection.customers}`, value: 25 },
        { label: `50 ${appLabels.customer_selection.customers}`, value: 50 },
        { label: `100 ${appLabels.customer_selection.customers}`, value: 100 },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.customer_selection.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.customer_selection.preference_title}
        />
    );

    const tableHeader = (
        <Header counter={selectedItems.length ? `(${selectedItems.length}/${props.state.customers.length})` : `(${props.state.customers.length})`}>
            {appLabels.customer_selection.table_title}
        </Header>
    );

    return (
        <Table
            {...collectionProps}
            trackBy='id'
            header={tableHeader}
            columnDefinitions={columnDefs}
            selectedItems={selectedItems}
            items={items}
            loadingText={appLabels.customer_selection.loading_customers}
            loading={props.state.loading}
            filter={<TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.customer_selection.filter_space_holder} />}
            pagination={
                <SpaceBetween direction='horizontal' size='m'>
                    <Box float='right'>
                        <Button
                            variant='normal'
                            onClick={() => {
                                props.setCustomer(Constants.ALL_CUSTOMERS);
                                history.push('/assessments');
                            }}
                            disabled={isAllCustomerSelected(props.customer)}
                        >
                            {isAllCustomerSelected(props.customer)
                                ? appLabels.customer_selection.all_customers_selected
                                : appLabels.customer_selection.action_select_all_customers}
                        </Button>
                    </Box>
                    <Pagination {...paginationProps} />
                </SpaceBetween>
            }
            preferences={tablePreferences}
        />
    );
};

export default withAppLabelsContext(withCustomerFilterContext(CustomersTable));
