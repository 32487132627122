import { Multiselect } from '@amzn/awsui-components-react';

import { AppLabels } from '../../../../../../common/AppLabels';
import Constants from '../../../../../../common/Constants';
import { Named } from '../../../model/Assessment';

export type AssessmentQuestionVectorFilter = Named;

export interface AssessmentFacetFilter extends Named {
    vectorFilters: AssessmentQuestionVectorFilter[];
}

export const RatingsFilter = (
    appLabels: AppLabels,
    allRatings: string[],
    onRatingFilter: (detail: any) => void,
    selectedRatings: string[] | null | undefined
): JSX.Element => {
    const i18nRemove = appLabels.assessment.results.review_all.report.remove;
    const i18nChooseRating = appLabels.assessment.results.review_all.report.choose_rating;
    return (
        <Multiselect
            onChange={({ detail }) => {
                onRatingFilter(detail.selectedOptions.map((op) => op.value));
            }}
            deselectAriaLabel={(e) => `${i18nRemove} ${e.label}`}
            placeholder={i18nChooseRating}
            options={
                allRatings.map((rating) => {
                    return {
                        label: `${rating}`,
                        value: rating.toString(),
                    };
                }) || []
            }
            selectedOptions={
                selectedRatings?.map((rating) => {
                    return {
                        label: `${rating}`,
                        value: rating.toString(),
                    };
                }) || []
            }
            tokenLimit={2}
            hideTokens={true}
        ></Multiselect>
    );
};

export const QuestionVectorFilter = (
    appLabels: AppLabels,
    vectorSpaceName: string,
    allActivities: AssessmentFacetFilter[],
    onQuestionVectorFilter: (detail: any) => void,
    selectedActivities: string[] | null | undefined
): JSX.Element => {
    const i18nRemove = appLabels.assessment.results.review_all.report.remove;
    const i18nChooseVector =
        vectorSpaceName === Constants.VECTOR_SPACE_TYPE_ACTIVITIES
            ? appLabels.assessment.results.review_all.report.choose_activity
            : appLabels.assessment.results.review_all.report.choose_category;
    return (
        <Multiselect
            onChange={({ detail }) => {
                onQuestionVectorFilter(detail.selectedOptions.map((op) => op.value));
            }}
            deselectAriaLabel={(e) => `${i18nRemove} ${e.label}`}
            placeholder={i18nChooseVector}
            options={allActivities.map((topLevel) => {
                const activityFilters = topLevel.vectorFilters.map((filter) => {
                    return {
                        label: filter.name || '',
                        value: filter.name || '',
                    };
                });
                return {
                    label: topLevel.name || '',
                    options: activityFilters,
                };
            })}
            selectedOptions={
                selectedActivities?.map((activity) => {
                    return {
                        label: activity,
                        value: activity,
                    };
                }) || []
            }
            tokenLimit={2}
            hideTokens={true}
        ></Multiselect>
    );
};

export const PhaseFilter = (
    appLabels: AppLabels,
    allPhases: (string | null)[] | null | undefined,
    onPhaseFilter: (detail: any) => void,
    selectedPhases: string[] | null | undefined
): JSX.Element => {
    const i18nRemove = appLabels.assessment.results.review_all.report.remove;
    const i18nChoosePhase = appLabels.assessment.results.review_all.report.choose_phase;
    return (
        <Multiselect
            onChange={({ detail }) => {
                onPhaseFilter(detail.selectedOptions.map((op) => op.value));
            }}
            deselectAriaLabel={(e) => `${i18nRemove} ${e.label}`}
            placeholder={i18nChoosePhase}
            options={
                allPhases?.map((phase) => {
                    return {
                        label: `${phase}`,
                        value: phase || '',
                    };
                }) || []
            }
            selectedOptions={
                selectedPhases?.map((phase) => {
                    return {
                        label: `${phase}`,
                        value: phase || '',
                    };
                }) || []
            }
            tokenLimit={2}
            hideTokens={true}
        ></Multiselect>
    );
};
