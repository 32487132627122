import { FlashContextInterface, FlashType, withFlashContext } from '@amzn/awscat-react-components';
import { Box, Button, FormField, Input, Modal, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { ValidationError, validate } from 'class-validator';
import sha256 from 'crypto-js/sha256';
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react';

import { CreateSessionUserInput } from './LiveSessionCreateUserInput';
import { CREATE_SESSION, LIST_MY_SESSIONS, generateWbsReferenceId } from '../../../api/wbs/WbsClient';
import { SessionState } from '../../../api/wbs/WbsTypes';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { errorLookup } from '../../../common/ValidatorUtils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { liveSessionSelected } from '../facilitate/CurrentAssessmentSlice';

type LiveSessionCreateDialogProp = AppLabelsContextInterface &
    FlashContextInterface & {
        showDialog: boolean;
        setShowDialog: Dispatch<SetStateAction<boolean>>;
    };

const defaultCreateSessionUserInput: CreateSessionUserInput = {
    sessionTitle: '',
    sessionPasscode: '',
};

const LiveSessionCreateDialog: FunctionComponent<LiveSessionCreateDialogProp> = ({
    showDialog,
    setShowDialog,
    flash,
    appLabels,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);

    const assessmentId = currentAssessment?.id ?? null;
    const assessmentTitle = currentAssessment?.template?.applicationTitle ?? null;
    const assessmentDescription = currentAssessment?.template?.description ?? null;

    const [createSession, { data, loading, error }] = useMutation(CREATE_SESSION, {
        refetchQueries: [LIST_MY_SESSIONS],
    });
    const [createSessionUserInput, setCreateSessionUserInput] = useState<CreateSessionUserInput>(defaultCreateSessionUserInput);
    const [errorAlerted, setErrorAlerted] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const getValidationErrorText = errorLookup<CreateSessionUserInput>(validationErrors);

    const getErrorText = (attribute: keyof CreateSessionUserInput): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };

    const cleanup = useCallback(() => {
        setShowDialog(false);
        setCreateSessionUserInput(defaultCreateSessionUserInput);
    }, [setShowDialog, setCreateSessionUserInput]);

    const confirmAction = async () => {
        const userInput = Object.assign(new CreateSessionUserInput(), createSessionUserInput);
        const validationErrors = await validate(userInput);
        setValidationErrors(validationErrors);
        if (!validationErrors.length) {
            // no input validation errors
            const passcodeHash = sha256(createSessionUserInput.sessionPasscode).toString();
            const sessionMetadata = {
                sessionName: createSessionUserInput.sessionTitle,
                sessionTitle: assessmentTitle ?? '',
                sessionDescription: assessmentDescription ?? '',
            };
            const referenceId = generateWbsReferenceId(assessmentId);
            createSession({
                variables: {
                    input: {
                        sessionMetadata: JSON.stringify(sessionMetadata),
                        passcodeHash,
                        state: SessionState.ACTIVE,
                        referenceId,
                    },
                },
            });
        }
    };

    useEffect(() => {
        if (data?.createSession) {
            const sessionInfo = data.createSession;
            const sessionId = sessionInfo.sessionId;
            dispatch(liveSessionSelected(sessionId));
            cleanup();
        }

        if (!error) {
            setErrorAlerted(false);
            return;
        }

        if (!errorAlerted) {
            // alert once on api error
            flash.alert(FlashType.error, appLabels.assessment.live_session.error_api, `${error.message}`);
            setErrorAlerted(true);
        }
    }, [cleanup, flash, appLabels, errorAlerted, data, error, dispatch]);

    return (
        <Modal
            onDismiss={cleanup}
            visible={showDialog}
            size='medium'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button onClick={cleanup} variant='link'>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button loading={loading} onClick={() => confirmAction()} variant='primary'>
                            {appLabels.assessment.live_session.start}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.assessment.live_session.start_polling_session}
        >
            <SpaceBetween direction='vertical' size='xs'>
                <TextContent>{appLabels.assessment.live_session.create_session_instruction}</TextContent>
                <FormField label={`${appLabels.assessment.live_session.create_session_title_label}*`} errorText={getErrorText('sessionTitle')}>
                    <Input
                        onChange={({ detail }) => setCreateSessionUserInput({ ...createSessionUserInput, sessionTitle: detail.value })}
                        value={createSessionUserInput.sessionTitle}
                        placeholder={appLabels.assessment.live_session.create_session_title_placeholder}
                    />
                </FormField>
                <TextContent>{appLabels.assessment.live_session.create_session_title_hint}</TextContent>

                <FormField
                    label={`${appLabels.assessment.live_session.create_password_label}*`}
                    description={appLabels.assessment.live_session.create_password_description}
                    errorText={getErrorText('sessionPasscode')}
                >
                    <Input
                        onChange={({ detail }) => setCreateSessionUserInput({ ...createSessionUserInput, sessionPasscode: detail.value })}
                        value={createSessionUserInput.sessionPasscode}
                        placeholder={appLabels.assessment.live_session.create_password_placeholder}
                    />
                </FormField>
                <TextContent>{appLabels.assessment.live_session.create_password_hint}</TextContent>
            </SpaceBetween>
        </Modal>
    );
};

export default withFlashContext(withAppLabelsContext(LiveSessionCreateDialog));
