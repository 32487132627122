import {
    AssessmentTemplate,
    AssessmentTemplateDefault,
    AssessmentTemplateDefaultNamespace,
    DefaultValueType,
} from '@amzn/aws-assessment-template-management-service-typescript-client';

export type TemplateDefaultWithNamespace = AssessmentTemplateDefault & {
    namespace: string;
};

/**
 * A full list of the template defaults that A2T uses/modifies. These defaults can be passed in to `getTemplateDefault`, along with the current template,
 * to get the value of the requested default.
 * See conversion between A2S and Template Management: https://code.amazon.com/packages/AwsAssessmentTemplateManagementServiceSchema/blobs/mainline/--/graphql/schemas/types/a2sTemplateDefaults.graphql
 */
const defaults = {
    PRE_EVENT_ENABLED: {
        namespace: 'preEvent',
        key: 'enabled',
        valueType: DefaultValueType.Boolean,
    },
    LIVE_SESSION_ENABLED: {
        namespace: 'questionnaireAnswers',
        key: 'wbsEnabled',
        valueType: DefaultValueType.Boolean,
    },
    RATING_NA_ENABLED: {
        namespace: 'questionnaireAnswers',
        key: 'ratingNAEnabled',
        valueType: DefaultValueType.Boolean,
    },
    SNAPSHOTS_DISABLED: {
        namespace: 'reportSnapshots',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    RADAR_DISABLED: {
        namespace: 'reportRadar',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    HEATMAP_DISABLED: {
        namespace: 'reportHeatmap',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    SCORE_BAR_CHART_DISABLED: {
        namespace: 'reportScoreBarChart',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    PPT_DISABLED: {
        namespace: 'reportPpt',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    WORD_DISABLED: {
        namespace: 'reportWord',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
    EXCEL_DISABLED: {
        namespace: 'reportExcel',
        key: 'disabled',
        valueType: DefaultValueType.Boolean,
    },
};

/**
 * Gets a template default, given the namespace, key, and template
 * @param defaultToGet the default to retrieve
 * @param currentTemplate the current template
 * @returns the default, or undefined if the default cannot be found
 */
export const getTemplateDefault = (
    defaultToGet: TemplateDefaultWithNamespace,
    currentTemplate: AssessmentTemplate
): AssessmentTemplateDefault | undefined => {
    const currentTemplateNamespaces: AssessmentTemplateDefaultNamespace[] | undefined = currentTemplate?.config.defaultNamespaces;
    const templateDefault: AssessmentTemplateDefault | undefined = currentTemplateNamespaces
        ?.find((templateNamespace) => templateNamespace.namespace === defaultToGet.namespace)
        ?.defaults.find((templateDefault) => templateDefault.key === defaultToGet.key);

    return templateDefault;
};

type InUseTemplateDefault = keyof typeof defaults;

export default { ...defaults } as { [key in InUseTemplateDefault]: TemplateDefaultWithNamespace };
