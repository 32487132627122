import { AssessmentResponseTypes, AssessmentTemplateDefaultsAnswers } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { generateWbsSolicitId } from './WbsClient';
import { AnalysisAction, INPUT_COMMENT_MAX_LENGTH, ResponseType, SharedDoc } from './WbsTypes';
import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';
import { AssessmentPromptViewModel } from '../../components/assessments/AssessmentPromptViewModel';
import { promptToPromptText } from '../../components/assessments/Utils';

export const generateSolicit = (prompt: AssessmentPromptViewModel | null, promptConfig: AssessmentTemplateDefaultsAnswers): SharedDoc => {
    const content = [];
    const solicitId = generateWbsSolicitId(prompt?.id);
    const promptText = promptToPromptText(prompt, promptConfig);

    if (prompt) {
        if (solicitId) {
            // prompt
            content.push(generatePrompt(promptText));

            // questionInput
            switch (prompt.responseType) {
                case AssessmentResponseTypes.YES_NO: {
                    content.push(generateYesNoInput());
                    // commentInput
                    content.push(generateCommentInput());
                    break;
                }
                case AssessmentResponseTypes.NUMBER:
                case AssessmentResponseTypes.TEXT: {
                    content.push(generateTextAnswerInput());
                    break;
                }
                case AssessmentResponseTypes.RATING:
                    content.push(generateRating1to5Input(prompt, promptConfig));
                    content.push(generateCommentInput());
                    break;
                default:
                    rumClient.recordError('Question has invalid answer type');
            }
        }
    }
    const sharedDoc: SharedDoc = {
        solicitId,
        content,
    };
    return sharedDoc;
};

const generatePrompt = (prompt: string) => {
    return {
        prompt,
    };
};

const generateRating1to5Input = (prompt: AssessmentPromptViewModel, promptConfig: AssessmentTemplateDefaultsAnswers) => {
    const options = [];
    if (!promptConfig?.rating5Disabled) {
        const description = prompt.ratingGuide5.replace('5 - ', '') || promptConfig?.rating5 || '';
        const value = promptConfig?.rating5Value ? `${promptConfig?.rating5Value}` : '5';
        options.push({ label: value, value, description });
    }
    if (!promptConfig?.rating4Disabled) {
        const description = prompt.ratingGuide4?.replace('4 - ', '') || promptConfig?.rating4 || '';
        const value = promptConfig?.rating4Value ? `${promptConfig?.rating4Value}` : '4';
        options.push({ label: value, value, description });
    }
    if (!promptConfig?.rating3Disabled) {
        const description = prompt.ratingGuide3?.replace('3 - ', '') || promptConfig?.rating3 || '';
        const value = promptConfig?.rating3Value ? `${promptConfig?.rating3Value}` : '3';
        options.push({ label: value, value, description });
    }
    if (!promptConfig?.rating2Disabled) {
        const description = prompt.ratingGuide2?.replace('2 - ', '') || promptConfig?.rating2 || '';
        const value = promptConfig?.rating2Value ? `${promptConfig?.rating2Value}` : '2';
        options.push({ label: value, value, description });
    }
    if (!promptConfig?.rating1Disabled) {
        const description = prompt.ratingGuide1?.replace('1 - ', '') || promptConfig?.rating1 || '';
        const value = promptConfig?.rating1Value ? `${promptConfig?.rating1Value}` : '1';
        options.push({ label: value, value, description });
    }
    if (promptConfig?.ratingNAEnabled) {
        const description = prompt.ratingGuideNA.replace('N/A - ', '') || promptConfig?.ratingNA || '';
        const value = Constants.ASSESSMENT_DEFAULT_NA_LABEL;
        options.push({ label: value, value, description });
    }
    return {
        inputDefinition: {
            type: ResponseType.MULTIPLE_CHOICE,
            options,
            analysisActions: [AnalysisAction.DISTRIBUTION],
        },
    };
};

const generateYesNoInput = () => {
    return {
        inputDefinition: {
            type: ResponseType.MULTIPLE_CHOICE,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
            ],
            analysisActions: [AnalysisAction.DISTRIBUTION],
        },
    };
};

const generateTextAnswerInput = (placeholder = '', hint = '', maxLength = INPUT_COMMENT_MAX_LENGTH) => {
    return {
        inputDefinition: {
            type: ResponseType.TEXT,
            placeholder: placeholder || 'answer',
            hint: hint || 'Enter answer here',
            maxLength,
            analysisActions: [AnalysisAction.ARCHIVE],
        },
    };
};

const generateCommentInput = (placeholder = '', hint = '', maxLength = INPUT_COMMENT_MAX_LENGTH) => {
    return {
        inputDefinition: {
            type: ResponseType.TEXT,
            placeholder: placeholder || 'comment',
            hint: hint || 'Enter comment here',
            maxLength,
            analysisActions: [AnalysisAction.ARCHIVE],
        },
    };
};
