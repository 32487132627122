import { WizardProps } from '@amzn/awsui-components-react';
import { NonCancelableCustomEvent, NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export type ShouldNavigateFn = (event: NonCancelableCustomEvent<WizardProps.NavigateDetail>) => boolean;

/**
 * Custom hook for fundamental wizard functions
 * @param closeTools - this function is called as a side-effect when setActiveStepIndexAndCloseTools is called
 * @param shouldNavigate - an optional callback that returns whether or not hitting "next" should go to the next step
 * @returns
 *  - activeStepIndex - the current step index in the wizard
 *  - setActiveStepIndexAndCloseTools - a function to set the current step index and call `closeTools()`
 *  - onNavigate - a function that should be called when the user navigates through the wizard
 *  - onCancel - a function that should be called when the user cancels the wizard
 */
export const useWizard = (closeTools: () => void, shouldNavigate?: ShouldNavigateFn) => {
    const history = useHistory();
    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const setActiveStepIndexAndCloseTools = (index: number) => {
        setActiveStepIndex(index);
        closeTools();
    };

    const onNavigate: NonCancelableEventHandler<WizardProps.NavigateDetail> = (event: NonCancelableCustomEvent<WizardProps.NavigateDetail>) => {
        if (shouldNavigate && !shouldNavigate(event)) {
            return;
        }
        setActiveStepIndex(event.detail.requestedStepIndex);
    };

    const onCancel = () => {
        history.goBack();
    };

    return {
        activeStepIndex,
        setActiveStepIndexAndCloseTools,
        onNavigate,
        onCancel,
    };
};
