import {
    AssessmentResponseBoolean,
    AssessmentResponseDate,
    AssessmentResponseMultiSelection,
    AssessmentResponseNumber,
    AssessmentResponseRating,
    AssessmentResponseSingleSelection,
    AssessmentResponseText,
    AssessmentResponseTypes,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_OBSERVATIONS_ACTIONS } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { TextMarker } from '../../../../../common/TextMarker';
import rumClient from '../../../../../common/monitoring/RumClient';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly } from '../../../Utils';
import { createOrUpdateAssessmentObservationsSuccessful } from '../../../facilitate/CurrentAssessmentSlice';
import { filterExcludedSections } from '../../Utils';
import { AssessmentQuestionVector } from '../../model/Assessment';

interface AssessmentSummaryObservationsState {
    id: string | null;
    summaryobservations: string;
    responseSaved: boolean;
}

type SummaryObservationsProps = AuthContextInterface &
    AppLabelsContextInterface & {
        questionVector: AssessmentQuestionVector;
        textMarker: TextMarker;
    };
const SummaryObservations: FunctionComponent<SummaryObservationsProps> = ({ appLabels, questionVector, textMarker, auth }): JSX.Element => {
    let className = '';
    if (textMarker.contains(questionVector.summaryObservations)) {
        className = 'summary-observations-wrapper-marked';
    }
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const isReadOnly = assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);
    const categoryId = questionVector.categoryId;
    let updatedSummaryObservations: string | null = null;

    const workstreams = currentAssessmentOrSelectedSnapshot?.template?.workstreams;
    const templateDefaults = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.defaults);
    const excludeSections = templateDefaults?.report?.report?.excludeSections;
    const sections = filterExcludedSections(
        currentAssessmentOrSelectedSnapshot?.template?.sections,
        excludeSections,
        currentAssessmentOrSelectedSnapshot
    );

    let combinedComments = '';
    let initSummaryObservation = '';
    if (workstreams?.length > 0) {
        currentAssessmentOrSelectedSnapshot?.template?.workstreams?.forEach((workstream) => {
            workstream?.activities?.forEach((activity) => {
                if (activity.id === categoryId) {
                    initSummaryObservation = activity?.observations?.observations;
                    activity?.prompts?.forEach((prompt) => {
                        let comment = '';
                        if (prompt.response) {
                            if (prompt.responseType === AssessmentResponseTypes.YES_NO) {
                                comment = (prompt.response as AssessmentResponseBoolean).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.RATING) {
                                comment = (prompt.response as AssessmentResponseRating).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.NUMBER) {
                                comment = (prompt.response as AssessmentResponseNumber).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.TEXT) {
                                comment = (prompt.response as AssessmentResponseText).stringValue;
                            } else if (prompt.responseType === AssessmentResponseTypes.SINGLE_SELECTION) {
                                comment = (prompt.response as AssessmentResponseSingleSelection).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.MULTI_SELECTION) {
                                comment = (prompt.response as AssessmentResponseMultiSelection).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.DATE_VALUE) {
                                comment = (prompt.response as AssessmentResponseDate).comments;
                            }
                            combinedComments = combinedComments ? combinedComments + '\n' + comment : comment;
                        }
                    });
                }
            });
        });
    } else if (sections?.length > 0) {
        currentAssessmentOrSelectedSnapshot?.template?.sections?.forEach((section) => {
            section?.categories?.forEach((category) => {
                if (category.id === categoryId) {
                    initSummaryObservation = category?.observations?.observations;
                    category.prompts?.forEach((prompt) => {
                        if (prompt.response) {
                            let comment = '';
                            if (prompt.responseType === AssessmentResponseTypes.YES_NO) {
                                comment = (prompt.response as AssessmentResponseBoolean).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.RATING) {
                                comment = (prompt.response as AssessmentResponseRating).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.NUMBER) {
                                comment = (prompt.response as AssessmentResponseNumber).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.TEXT) {
                                comment = (prompt.response as AssessmentResponseText).stringValue;
                            } else if (prompt.responseType === AssessmentResponseTypes.SINGLE_SELECTION) {
                                comment = (prompt.response as AssessmentResponseSingleSelection).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.MULTI_SELECTION) {
                                comment = (prompt.response as AssessmentResponseMultiSelection).comments;
                            } else if (prompt.responseType === AssessmentResponseTypes.DATE_VALUE) {
                                comment = (prompt.response as AssessmentResponseDate).comments;
                            }
                            combinedComments = combinedComments ? combinedComments + '\n' + comment : comment;
                        }
                    });
                }
            });
        });
    }

    const initialAssessmentSummaryObservationsState: AssessmentSummaryObservationsState = {
        id: categoryId,
        summaryobservations: initSummaryObservation,
        responseSaved: false,
    };
    const [state, setState] = useState<AssessmentSummaryObservationsState>(initialAssessmentSummaryObservationsState);

    useEffect(() => {
        setState({
            responseSaved: state.responseSaved,
            id: categoryId,
            summaryobservations: initSummaryObservation,
        });
    }, [categoryId, initSummaryObservation, state.responseSaved]);

    const [CreateOrUpdateAssessmentObservationsActions, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_OBSERVATIONS_ACTIONS, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentObservationsActions;
            const updatedResponse = responses?.find((r) => !!r.observations);
            if (updatedResponse) {
                dispatch(
                    createOrUpdateAssessmentObservationsSuccessful({
                        assessmentId: assessmentId,
                        categoryId: categoryId,
                        updatedSummaryObservations: updatedResponse.observations,
                    })
                );
                setState({ ...state, responseSaved: true });
            } else {
                rumClient.recordError('Unable to retrieve observations from CreateOrUpdateAssessmentObservationsActions response.');
            }
        },
    });

    const populateSummaryObservationsHandler = () => {
        if (isReadOnly) {
            return;
        }
        if (state.summaryobservations && combinedComments) {
            updatedSummaryObservations = state.summaryobservations + '\n' + combinedComments;
        } else if (state.summaryobservations) {
            updatedSummaryObservations = state.summaryobservations;
        } else if (combinedComments) {
            updatedSummaryObservations = combinedComments;
        }
        setState({ ...state, summaryobservations: updatedSummaryObservations });
        CreateOrUpdateAssessmentObservationsActions({
            variables: {
                input: {
                    assessmentId: assessmentId,
                    observations: [
                        {
                            categoryId: categoryId,
                            observations: updatedSummaryObservations,
                        },
                    ],
                },
            },
        });
    };

    const updateStatusIndicator = useCallback(() => {
        if (isReadOnly) {
            return null;
        }
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_comments}
                updateConfirmationText={!loading && !error && state.responseSaved ? appLabels.assessment.facilitate.comments_saved : null}
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [
        isReadOnly,
        loading,
        appLabels.assessment.facilitate.updating_comments,
        appLabels.assessment.facilitate.comments_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        state.responseSaved,
    ]);

    const updateSummaryObservations = useCallback(() => {
        if (isReadOnly) {
            return;
        }
        const summaryObservations = state.summaryobservations || null; // set to null to remove comment for all falsy values. e.g. ''
        CreateOrUpdateAssessmentObservationsActions({
            variables: {
                input: {
                    assessmentId: assessmentId,
                    observations: [
                        {
                            categoryId: categoryId,
                            observations: summaryObservations,
                        },
                    ],
                },
            },
        });
    }, [isReadOnly, state.summaryobservations, CreateOrUpdateAssessmentObservationsActions, assessmentId, categoryId]);

    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <Box className='summary-observations-section'>
            <SpaceBetween size='xxs' direction='vertical'>
                <Box fontSize='heading-s' fontWeight='bold'>
                    {appLabels.assessment.results.review_all.report.summary_observations}
                </Box>
                <Box fontSize='body-s'>{appLabels.assessment.results.review_all.report.summary_observations_tip}</Box>
                {updateStatusIndicator()}
                <Box className={className}>
                    <Textarea
                        placeholder={appLabels.assessment.results.review_all.report.summary_observations_placeholder}
                        className='summary-observations'
                        readOnly={isReadOnly}
                        value={state.summaryobservations || ''}
                        onChange={({ detail }) => setState({ ...state, summaryobservations: detail.value })}
                        onBlur={updateSummaryObservations}
                    />
                </Box>
                <Box>
                    <Box float='right'>
                        <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                            <Button onClick={populateSummaryObservationsHandler} disabled={isReadOnly}>
                                {appLabels.assessment.results.review_all.report.pre_populate_summary_observations}
                            </Button>
                        </div>
                    </Box>
                    <Box float='right'>
                        {isHovering && <div>{appLabels.assessment.results.review_all.report.summary_observations_populate_hover_text}</div>}
                    </Box>
                </Box>
            </SpaceBetween>
        </Box>
    );
};

export default withAuthContext(withAppLabelsContext(SummaryObservations));
