import './RecommendedActions.scss';

import { Box, Button, Checkbox, Header, Modal, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { FunctionComponent, useState } from 'react';

import { RecommendedActionsViewState } from './RecommendedActionViewState';
import { ActionTypeRecommendedAction } from './RecommendedActions';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';
import { PrescribedAction, RecommendedAction } from '../../../model/Assessment';

type PrescribedActionsFormProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: any;
    recommendedActionsViewState: RecommendedActionsViewState;
    dispatchViewState: any;
    isReadOnly: boolean;
};

const PrescribedActionsForm: FunctionComponent<PrescribedActionsFormProps> = ({
    visible,
    setVisible,
    recommendedActionsViewState,
    dispatchViewState,
    appLabels,
    isReadOnly,
}): JSX.Element => {
    let possiblePrescribedActions: PrescribedAction[] = [];
    if (recommendedActionsViewState.recommendedActions) {
        possiblePrescribedActions = recommendedActionsViewState?.prescribedActions?.filter(function (obj) {
            return !recommendedActionsViewState.recommendedActions.some(function (recAction) {
                return obj.text === recAction.text;
            });
        });
    } else {
        possiblePrescribedActions = recommendedActionsViewState.prescribedActions;
    }
    const [checkedState, setCheckedState] = useState(new Array(possiblePrescribedActions?.length).fill(false));
    const onAddRecommendedActions = async () => {
        const newPrescribedActions = possiblePrescribedActions?.filter((item, index) => checkedState.at(index) === true);
        const newRecommendedActions = newPrescribedActions?.map((x) => {
            return {
                text: x.text,
                priority: '',
                targetDate: '',
                owner: '',
            } as RecommendedAction;
        });
        dispatchViewState({ type: ActionTypeRecommendedAction.ADD_ACTION, payLoad: newRecommendedActions });
        setCheckedState(new Array(possiblePrescribedActions?.length).fill(false));
    };
    const onAddCustomAction = () => {
        if (isReadOnly) {
            return;
        }
        const customAction = [
            {
                text: '',
                priority: '',
                targetDate: '',
                owner: '',
            } as RecommendedAction,
        ];
        dispatchViewState({ type: ActionTypeRecommendedAction.ADD_ACTION, payLoad: customAction });
    };

    const handleCheckChanged = (position: number) => {
        const initialCheckedState = checkedState.length > 0 ? checkedState : new Array(possiblePrescribedActions?.length).fill(false);
        const updatedCheckedState = initialCheckedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updatedCheckedState);
    };
    return (
        <div>
            <Modal
                onDismiss={() => {
                    setVisible(false);
                }}
                visible={visible}
                header={<Header>{appLabels.assessment.results.review_all.report.recommended_actions.add_modal.header}</Header>}
                footer={
                    <Box float='right'>
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Button onClick={() => setVisible(false)}>
                                {appLabels.assessment.results.review_all.report.recommended_actions.add_modal.button_cancel}
                            </Button>
                            <Button
                                disabled={isReadOnly}
                                onClick={() => {
                                    onAddRecommendedActions();
                                    setVisible(false);
                                }}
                                variant='primary'
                            >
                                {appLabels.assessment.results.review_all.report.recommended_actions.add_modal.button_ok}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div>
                    <TextContent>
                        <h4>
                            For: {recommendedActionsViewState.workstreamName}-{recommendedActionsViewState.activityName}
                        </h4>
                    </TextContent>
                    <TextContent>
                        <h5>{appLabels.assessment.results.review_all.report.recommended_actions.add_modal.header_2}</h5>
                    </TextContent>
                    <ul className='recommended-action-list-style'>
                        {possiblePrescribedActions?.map((action, index) => {
                            return (
                                <li key={index}>
                                    <Checkbox checked={checkedState[index]} id={index.toString()} onChange={() => handleCheckChanged(index)}>
                                        {action.text}
                                    </Checkbox>
                                </li>
                            );
                        })}
                    </ul>
                    <Button
                        onClick={() => {
                            onAddCustomAction();
                            setVisible(false);
                        }}
                    >
                        {appLabels.assessment.results.review_all.report.recommended_actions.add_modal.button_custom_action}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default withLocalizationContext(withAppLabelsContext(PrescribedActionsForm));
