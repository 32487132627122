import { Link, Wizard, WizardProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EnterTemplateDetailsPanel from './EnterTemplateDetailsPanel';
import UploadTemplatePanel from './UploadTemplatePanel';
import { templateToSampleAssessment } from './utils/Utils';
import Paths from '../../../../Paths';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import Constants from '../../../../common/Constants';
import rumClient from '../../../../common/monitoring/RumClient';
import { loadAssessmentSuccess } from '../../../assessments/facilitate/CurrentAssessmentSlice';
import { ShouldNavigateFn, useWizard } from '../../../common/Wizard';
import { clearAppHelpPanel, openAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppSelector } from '../../../redux/hooks';

interface Step {
    title: string;
    description: string;
    StepContent: FunctionComponent;
}

type CreateTemplateWizardProps = AppLabelsContextInterface;
const CreateTemplateWizard: FunctionComponent<CreateTemplateWizardProps> = ({ appLabels }): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const createTemplateState = useAppSelector((state) => state.createTemplateState);
    const [errorText, setErrorText] = useState<string>('');

    const closeTools = () => {
        return;
    };
    const shouldNavigate: ShouldNavigateFn = (event) => {
        // Forbid navigating away from upload template page, unless a template is uploaded
        if (event.detail.requestedStepIndex === 1 && !createTemplateState.template) {
            setErrorText(appLabels.manage_templates.wizard.upload_template.no_template_uploaded);
            return false;
        }
        // Reset any other error text before navigating. This applies to the "previous" button
        setErrorText('');
        return true;
    };
    const { activeStepIndex, onNavigate, onCancel } = useWizard(closeTools, shouldNavigate);

    useEffect(() => {
        // Remove any error text once the relevant field is filled out
        if (errorText === appLabels.manage_templates.wizard.upload_template.no_template_uploaded && createTemplateState.template) {
            setErrorText('');
        }
    }, [errorText, createTemplateState, appLabels.manage_templates.wizard]);

    const i18nStrings: WizardProps.I18nStrings = {
        stepNumberLabel: (stepNumber: number | string) => `${appLabels.common.step} ${stepNumber}`,
        collapsedStepsLabel: (stepNumber: number | string, stepsCount: number | string) => {
            return `${appLabels.common.step} ${stepNumber} / ${stepsCount}`;
        },
        cancelButton: appLabels.user_actions.cancel,
        previousButton: appLabels.user_actions.previous,
        nextButton: appLabels.user_actions.next,
        submitButton: appLabels.user_actions.preview,
        optional: appLabels.common.optional,
    };

    const steps: Step[] = [
        {
            title: appLabels.manage_templates.wizard.upload_template.upload_button_text,
            description: '',
            StepContent: UploadTemplatePanel,
        },
        {
            title: appLabels.manage_templates.wizard.enter_details.title,
            description: appLabels.manage_templates.wizard.enter_details.description,
            StepContent: EnterTemplateDetailsPanel,
        },
    ];

    const wizardSteps: WizardProps.Step[] = steps.map(({ title, description, StepContent }) => ({
        title,
        description,
        info: (
            <Link
                variant='info'
                onFollow={() => {
                    dispatch(openAppHelpPanel());
                }}
            >
                {appLabels.common.info}
            </Link>
        ),
        content: <StepContent />,
        errorText,
    }));

    const onSubmit = useCallback(() => {
        rumClient.recordClick(`${Paths.CREATE_TEMPLATE_PATH}/btn-preview`);

        // Ensure the last page has been filled out
        if (!(createTemplateState.templateName && createTemplateState.templateDescription && createTemplateState.templateAcronym)) {
            setErrorText(appLabels.manage_templates.wizard.enter_details.must_fill_out_all_required_fields);
            return;
        }
        setErrorText('');

        // Make a sample template and load it
        const sampleAssessment = templateToSampleAssessment(createTemplateState.template);
        dispatch(loadAssessmentSuccess(sampleAssessment));

        history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${Constants.SAMPLE_ASSESSMENT_ID}`);
    }, [createTemplateState, dispatch, appLabels.manage_templates.wizard.enter_details.must_fill_out_all_required_fields, history]);

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };

        return cleanup;
    }, [dispatch]);

    return (
        <div className='awscat-applayout-wizard awscat-assessment-wrapper'>
            <Wizard
                steps={wizardSteps}
                activeStepIndex={activeStepIndex}
                i18nStrings={i18nStrings}
                onNavigate={onNavigate}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export default withAppLabelsContext(CreateTemplateWizard);
