import {
    AssessmentDescriptor,
    AssessmentModule,
    AssessmentPrompt,
    AssessmentTemplate,
    AssessmentTemplateDefault,
    AssessmentTemplateDefaultNamespace,
    AssessmentTemplateMetadata,
} from '@amzn/aws-assessment-template-management-service-typescript-client';

import { CurrentTemplateState } from './CurrentTemplateSlice';
import TemplateConstants from './TemplateConstants';
import Constants from '../../../../common/Constants';

/**
 * Validates a template
 * @param template the template to validate
 * @throws if there's a problem with the template
 */
export const validateTemplate = (template: AssessmentTemplate): void => {
    if (template.views.length === 0) {
        throw new Error('No views in template');
    }

    template.views.forEach((view) => {
        if (view.modules.length === 0) {
            throw new Error(`No modules in view: ${view.viewId}`);
        }
    });
};

/**
 * Given a descriptor array from template management, attempts to find the desired locale. If missing, returns the descriptor for the default locale.
 * If that is also missing, returns the first locale in the array
 * @param descriptors the descriptors retrieved with a template
 * @param locale the desired locale
 * @returns either the desired locale descriptor, the default locale descriptor, or the first descriptor
 */
export const getDescriptorForLocale = (descriptors: AssessmentDescriptor[] | undefined, locale: string): AssessmentDescriptor | undefined => {
    return (
        descriptors?.find((descriptor) => descriptor.locale === locale) ||
        descriptors?.find((descriptor) => descriptor.locale === Constants.DEFAULT_LOCALE) ||
        descriptors?.at(0)
    );
};

/**
 * Given a module, returns whether or not it should be considered optional
 * @param module the module to check
 * @returns whether the module should be considered optional
 */
export const isModuleOptional = (module: AssessmentModule): boolean => {
    return !!module.metadata?.find((metadata) => metadata.key === 'showIfAnswerYes')?.value;
};

/**
 * Given a prompt, returns whether or not it should be considered optional
 * @param prompt the prompt to check
 * @returns whether the prompt should be considered optional
 */
export const isPromptOptional = (prompt: AssessmentPrompt): boolean => {
    return prompt.a2sId.startsWith('optional');
};

/**
 * This function should be used to retrieve an editable descriptor
 * @param currentTemplateState the current template state
 * @param descriptorId the ID of the editable descriptor we want to retrieve
 * @returns the editable descriptor
 */
export const getEditableDescriptor = (currentTemplateState: CurrentTemplateState, descriptorId: string): AssessmentDescriptor | undefined => {
    return currentTemplateState.descriptorIdLocaleToEditableDescriptorObject?.[`${descriptorId}${currentTemplateState.templateLocale}`];
};

/**
 * For the current prompt, extracts a display-ready prompt string from a prompt object
 * @param currentTemplateState the current template state
 * @returns a string representing the prompt, that can be displayed to the user
 */
export const getCurrentPromptText = (currentTemplateState: CurrentTemplateState): string => {
    const prompt: AssessmentPrompt = currentTemplateState.currentPromptState.currentPrompt;
    const promptIndex: number = currentTemplateState.currentPromptState.currentPromptIndex;
    const defaultNamespaces: AssessmentTemplateDefaultNamespace[] = currentTemplateState.currentTemplate.config.defaultNamespaces;
    const templateLocale: string = currentTemplateState.templateLocale;

    if (!prompt) {
        return '';
    }

    const questionnaireAnswersDefaults: AssessmentTemplateDefault[] = defaultNamespaces.find(
        (namespace) => namespace.namespace === TemplateConstants.QUESTIONNAIRE_ANSWERS_NAMESPACE
    )?.defaults;

    const questionLabelDefault: AssessmentTemplateDefault = questionnaireAnswersDefaults?.find(
        (templateDefault) => templateDefault.key === 'questionLabel'
    );
    const questionLabel: string = questionLabelDefault?.descriptors
        ? getDescriptorForLocale(questionLabelDefault.descriptors, templateLocale)?.name
        : Constants.ASSESSMENT_DEFAULT_QUESTION_LABEL;

    // As the prompt text is editable, we need to get the potentially edited descriptor from this object
    const promptTextDescriptorId: string = prompt.descriptors.at(0).descriptorId;
    const promptText: string = getEditableDescriptor(currentTemplateState, promptTextDescriptorId)?.description || '';

    // + 1 to convert from 0-indexed to 1-indexed
    return promptText ? `${questionLabel} ${promptIndex + 1}: ${promptText}` : '';
};

/** Rating-related defaults */
export interface TemplateRatingDefaults {
    ratingGuide: AssessmentDescriptor[] | undefined;
    title: AssessmentDescriptor[] | undefined;
    value: number | undefined;
    enabled: boolean;
    color: string | undefined;
}

/**
 * Given the current template and the desired rating, returns all defaults for that rating
 * @param currentTemplate the current template
 * @param rating the rating to get
 * @returns all defaults for a rating
 */
export const getDefaultsForRating = (currentTemplate: AssessmentTemplate, rating: string): TemplateRatingDefaults => {
    const defaultNamespaces: AssessmentTemplateDefaultNamespace[] = currentTemplate.config.defaultNamespaces;
    const questionnaireAnswersDefaults: AssessmentTemplateDefault[] = defaultNamespaces.find(
        (namespace) => namespace.namespace === TemplateConstants.QUESTIONNAIRE_ANSWERS_NAMESPACE
    )?.defaults;

    const ratingKey = `rating${rating}`; // e.g. ratingNA

    /**
     * Returns whether a rating is enabled
     * @param ratingKey the rating to check
     * @returns a boolean, whether the rating should be displayed
     */
    function isRatingEnabled(ratingKey: string): boolean {
        const enabledDefault = questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey + 'Enabled')?.value;
        if (enabledDefault === TemplateConstants.TRUE_STRING_VALUE) {
            // Explicitly enabled
            return true;
        } else if (enabledDefault === TemplateConstants.FALSE_STRING_VALUE) {
            // Explicitly not enabled
            return false;
        }

        const disabledDefault = questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey + 'Disabled')?.value;
        if (disabledDefault === TemplateConstants.TRUE_STRING_VALUE) {
            // Explicitly disabled
            return false;
        }

        // If no default is set, assume the rating is enabled
        return true;
    }

    return {
        ratingGuide: questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey)?.descriptors,
        title: questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey + 'Title')?.descriptors,
        // May be NaN --> undefined
        value: parseInt(questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey + 'Value')?.value) || undefined,
        enabled: isRatingEnabled(ratingKey),
        color: questionnaireAnswersDefaults?.find((templateDefault) => templateDefault.key === ratingKey + 'Color')?.value,
    };
};

/**
 * Returns whether a template is published
 * @param templateMetadata the current template's metadata
 * @returns whether the template is published
 */
export const isTemplatePublished = (templateMetadata: AssessmentTemplateMetadata): boolean => {
    return templateMetadata?.isPublishedForType || templateMetadata?.isPublishedForVersion;
};
