import { SpaceBetween } from '@amzn/awsui-components-react';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';

import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';

const AssessmentsHelpPanel = (props: AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;

    return (
        <SpaceBetween size='s'>
            <TextContent> {appLabels.assessment.list.info_panel_text1}</TextContent>
            <TextContent> {appLabels.assessment.list.info_panel_text2}</TextContent>
            <TextContent> {appLabels.assessment.list.info_panel_text3}</TextContent>
        </SpaceBetween>
    );
};

export default withAppLabelsContext(AssessmentsHelpPanel);
