import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { RouteProps, useHistory } from 'react-router-dom';

import rumClient from '../../common/monitoring/RumClient';

const SignIn = (props: RouteProps & AuthContextInterface) => {
    rumClient.recordError(`Processing token from query string. ${props}`);
    const history = useHistory();
    const signIn = async () => {
        const signInInfo = await props.auth.signInWithQueryParameters(props?.location?.search || '');

        if (signInInfo.state) {
            history.push(signInInfo.state);
        } else {
            history.push('/');
        }
    };
    signIn();
    return <div>Loading</div>;
};

export default withAuthContext(SignIn);
