import { SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { FunctionComponent, ReactNode } from 'react';

import InfoLink, { InfoLinkProps } from './InfoLink';

type TextContentWithInfoLinkProps = {
    textContent: ReactNode;
} & InfoLinkProps;

export const TextContentWithInfoLink: FunctionComponent<TextContentWithInfoLinkProps> = ({ textContent, header, content, footer }): JSX.Element => {
    return (
        <SpaceBetween direction='horizontal' size='xs'>
            <TextContent>{textContent}</TextContent>
            <InfoLink header={header} content={content} footer={footer} />
        </SpaceBetween>
    );
};
