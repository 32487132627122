import './AssessmentReportResults.scss';

import { Badge, Box, Grid, Link, SpaceBetween, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import { FormattedNumber } from 'react-intl';

import PartipantComments from './ParticipantComments';
import { QuestionCounter } from './report-filters/QuestionCounter';
import { AppLabels } from '../../../../../common/AppLabels';
import Constants from '../../../../../common/Constants';
import { TextMarker } from '../../../../../common/TextMarker';
import { notEmpty } from '../../../../../common/Utils';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { ScoredItem, getScoreLabel } from '../../../../../common/score/ScoreUtils';
import { CustomColorBadge } from '../../../../common/CustomColorBadge';
import FacilitatorComments from '../../FacilitatorComments';
import {
    AssessmentBooleanQuestion,
    AssessmentDateQuestion,
    AssessmentFreeQuestion,
    AssessmentMultiSelectQuestion,
    AssessmentNumberQuestion,
    AssessmentScoredQuestion,
    AssessmentSingleSelectQuestion,
    DateSelection,
    NumberItem,
    YesOrNo,
} from '../../model/Assessment';

const ScoredRatingBadge = (appLabels: AppLabels, scored: ScoredItem | null, questionScoreScale: ScoreScale): JSX.Element => {
    const bgColor = questionScoreScale?.getStratificationBackgroundColor(scored);
    const fontColor = questionScoreScale?.getStratificationFontColor(scored);
    const scoreLabel = getScoreLabel(scored);
    return CustomColorBadge(`${appLabels.assessment.results.review_all.report.rating}: ${scoreLabel}`, bgColor, fontColor);
};

const YesOrNoBadge = (appLabels: AppLabels, yesOrNo: YesOrNo): JSX.Element => {
    if (!notEmpty(yesOrNo.yes)) {
        // un-answered yes/no
        return <Badge color='grey'>{'?'}</Badge>;
    } else if (yesOrNo.yes) {
        return <Badge color='green'>{appLabels.common.yes}</Badge>;
    } else {
        return <Badge color='red'>{appLabels.common.no}</Badge>;
    }
};

const NumberBadge = (numberItem: NumberItem): JSX.Element => {
    return <Badge className='badge-incomplete'>{numberItem.value ?? Constants.ASSESSMENT_DEFAULT_INCOMPLETE_LABEL}</Badge>;
};

const DateBadge = (dateSelection: DateSelection): JSX.Element => {
    return <Badge className='badge-incomplete'>{dateSelection.value ?? Constants.ASSESSMENT_DEFAULT_INCOMPLETE_LABEL}</Badge>;
};

const QuestionText = (
    questionLabel: string,
    index: number,
    text: string | null | undefined,
    questionUrl: string,
    textMarker: TextMarker,
    badge: JSX.Element | null = null
): JSX.Element => {
    const indexMatches = textMarker.contains(index.toString());
    let formattedNumber = <FormattedNumber value={index} />;
    if (indexMatches) {
        formattedNumber = <mark>{formattedNumber}</mark>;
    }

    return (
        <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
            <Box className='question'>
                <span className='question-label'>
                    <Link variant='primary' href={questionUrl}>
                        {questionLabel} {formattedNumber}:
                    </Link>
                </span>{' '}
                {textMarker.markText(text)}
            </Box>
            <Box float='right' className='question-rating'>
                {badge}
            </Box>
        </Grid>
    );
};

/**
 * Given selection options in the template and the selected value(s), find the selected labels
 */
const SelectedAnswers = (question: AssessmentSingleSelectQuestion | AssessmentMultiSelectQuestion): JSX.Element => {
    if (!question.selectionOptions?.length) {
        return null;
    }
    const selectedItems: TokenGroupProps.Item[] = [];
    if (question.type === 'select' && question.value) {
        const selectedLabel = question.selectionOptions.find((option) => option.selectionId === question.value)?.selectionLabel;
        selectedLabel && selectedItems.push({ label: selectedLabel });
    } else if (question.type === 'multiSelect' && question.value?.length) {
        const selectedOptions = question.selectionOptions.filter((option) => question.value.includes(option.selectionId));
        selectedItems.push(...selectedOptions.map((option) => ({ label: option.selectionLabel })));
    }
    if (!selectedItems.length) {
        return null;
    }
    return <TokenGroup items={selectedItems} className='selected-answers'></TokenGroup>;
};

export const AssessmentQuestionBox = (
    appLabels: AppLabels,
    question:
        | AssessmentFreeQuestion
        | AssessmentScoredQuestion
        | AssessmentBooleanQuestion
        | AssessmentNumberQuestion
        | AssessmentSingleSelectQuestion
        | AssessmentMultiSelectQuestion
        | AssessmentDateQuestion
        | null,
    i: number,
    questions: (
        | AssessmentFreeQuestion
        | AssessmentScoredQuestion
        | AssessmentBooleanQuestion
        | AssessmentNumberQuestion
        | AssessmentSingleSelectQuestion
        | AssessmentMultiSelectQuestion
        | AssessmentDateQuestion
        | null
    )[],
    questionCounter: QuestionCounter,
    textMarker: TextMarker,
    questionScoreScale: ScoreScale
): JSX.Element | null => {
    if (!question?.display) {
        questionCounter.addHiddenQuestion();
        return null;
    }

    let badge = null;
    if (question?.type === 'scored') {
        const scoredQuestion = question as AssessmentScoredQuestion;
        badge = ScoredRatingBadge(appLabels, scoredQuestion, questionScoreScale);
    } else if (question?.type === 'boolean') {
        const yesOrNoQuestion = question as AssessmentBooleanQuestion;
        badge = YesOrNoBadge(appLabels, yesOrNoQuestion);
    } else if (question?.type === 'number') {
        const numberQuestion = question as AssessmentNumberQuestion;
        badge = NumberBadge(numberQuestion);
    } else if (question?.type === 'date') {
        const dateQuestion = question as AssessmentDateQuestion;
        badge = DateBadge(dateQuestion);
    }

    questionCounter.addDisplayedQuestion();
    const numberOfQuestions = questions.filter((question) => question?.display).length;
    return (
        <Box className='question-box'>
            <SpaceBetween direction='vertical' size='l'>
                {QuestionText(question.promptPrefix, question?.index, question?.promptText, question?.promptUrl, textMarker, badge)}
                {SelectedAnswers(question as AssessmentMultiSelectQuestion)}
                {<FacilitatorComments promptId={question.promptId} facilitatorComments={question?.facilitatorComments} textMarker={textMarker} />}
                {PartipantComments({ appLabels, participantComments: question.participantComments, questionNumber: i, numberOfQuestions })}
            </SpaceBetween>
        </Box>
    );
};
