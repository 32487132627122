import { CookieCategory } from '@amzn/awscat-cookie-management';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import { FunctionComponent, useContext } from 'react';

import Constants from '../../../common/Constants';
import { CookieConsentContext } from '../../../common/context/CookieConsentContext';
import { withLocalizationContext } from '../../localization/LocalizationContext';

type LanguageSelectionDropdownProps = AuthContextInterface & {
    isAuthenticated: boolean;
    selectedLanguage: string;
    allLanguages: string[];
    setLanguage: (lang: string) => void;
};

const LanguageSelectionDropdown: FunctionComponent<LanguageSelectionDropdownProps> = ({
    selectedLanguage,
    allLanguages,
    setLanguage,
    isAuthenticated,
}): JSX.Element | null => {
    // If cookies allow, set the locale selection in local storage, so it can be read in the next session
    // This needs to be done here in the component, since we need to use the CookieConsentContext
    const { setLocalStorage } = useContext(CookieConsentContext);

    const dropdownItems = allLanguages.map((l) => {
        return { id: l, text: l };
    });

    return isAuthenticated ? (
        <ButtonDropdown
            expandToViewport
            variant='normal'
            items={dropdownItems}
            onItemClick={(e) => {
                const newLanguage = e.detail.id;
                setLanguage(newLanguage);
                setLocalStorage(Constants.LOCAL_STORAGE_LANGUAGE_KEY, newLanguage, [CookieCategory.Functional]);
            }}
        >
            {selectedLanguage}
        </ButtonDropdown>
    ) : null;
};

export default withAuthContext(withLocalizationContext(LanguageSelectionDropdown));
