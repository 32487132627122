import './AssessmentPromptRating.scss';

import { AssessmentDescriptor, AssessmentTemplate, ResponseType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentResponseRating, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import RatingTile from './RatingTile';
import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import Constants from '../../../../../common/Constants';
import { CurrentTemplateState } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import {
    getDefaultsForRating,
    getDescriptorForLocale,
    getEditableDescriptor,
} from '../../../../administration/manage-templates/edit-template/TemplateUtils';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';
import { Comment } from '../CommentBox';

interface AssessmentPromptRatingState {
    id: string | null;
    ratingSelected: number | null;
    responseSaved: boolean;
}

const initialAssessmentPromptRatingState: AssessmentPromptRatingState = {
    id: null,
    ratingSelected: null,
    responseSaved: false,
};

export interface ParticipantRatingResponse {
    rating: string;
    votes: number;
    participantComments: Comment[];
}

export type ParticipantRatingResponseMap = Map<string, ParticipantRatingResponse>;

const totalVotesFromParticipantRatingResponseMap = (participantRatingResponseMap: ParticipantRatingResponseMap): number => {
    let totalVotes = 0;
    for (const rating of participantRatingResponseMap.keys()) {
        totalVotes += participantRatingResponseMap.get(rating).votes;
    }
    return totalVotes;
};

type AssessmentPromptRatingProps = AppLabelsContextInterface &
    AuthContextInterface & {
        participantRatingResponseMap: ParticipantRatingResponseMap;
        shouldDisplayTemplate: boolean;
    };

const AssessmentPromptRating: FunctionComponent<AssessmentPromptRatingProps> = ({
    auth,
    appLabels,
    participantRatingResponseMap,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const responseType = currentPrompt?.responseType ?? null;
    const rating = (currentPrompt?.response as AssessmentResponseRating)?.intValue ?? null;
    const assessmentTemplateDefaults = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot?.template?.defaults
    );

    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplate: AssessmentTemplate = useAppSelector((state) => state.currentTemplateState.currentTemplate);
    const currentTemplatePrompt: TemplatePromptViewModel = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const templateResponseType: ResponseType = currentTemplatePrompt?.responseType;
    const templateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);

    const [ratingNAValue, setRatingNAValue] = useState<number>();
    const [ratingNAEnabled, setRatingNAEnabled] = useState<boolean>();
    const [rating1Disabled, setRating1Disabled] = useState<boolean>();
    const [rating2Disabled, setRating2Disabled] = useState<boolean>();
    const [rating3Disabled, setRating3Disabled] = useState<boolean>();
    const [rating4Disabled, setRating4Disabled] = useState<boolean>();
    const [rating5Disabled, setRating5Disabled] = useState<boolean>();
    const [ratingGuideNA, setRatingGuideNA] = useState<string>();
    const [ratingGuide1, setRatingGuide1] = useState<string>();
    const [ratingGuide2, setRatingGuide2] = useState<string>();
    const [ratingGuide3, setRatingGuide3] = useState<string>();
    const [ratingGuide4, setRatingGuide4] = useState<string>();
    const [ratingGuide5, setRatingGuide5] = useState<string>();

    const [ratingGuide1Descriptor, setRatingGuide1Descriptor] = useState<AssessmentDescriptor>(null);
    const [ratingGuide2Descriptor, setRatingGuide2Descriptor] = useState<AssessmentDescriptor>(null);
    const [ratingGuide3Descriptor, setRatingGuide3Descriptor] = useState<AssessmentDescriptor>(null);
    const [ratingGuide4Descriptor, setRatingGuide4Descriptor] = useState<AssessmentDescriptor>(null);
    const [ratingGuide5Descriptor, setRatingGuide5Descriptor] = useState<AssessmentDescriptor>(null);

    // Get the rating guide values from either the template or the assessment
    useEffect(() => {
        if (shouldDisplayTemplate) {
            const naDefaults = getDefaultsForRating(currentTemplate, 'NA');
            const oneDefaults = getDefaultsForRating(currentTemplate, '1');
            const twoDefaults = getDefaultsForRating(currentTemplate, '2');
            const threeDefaults = getDefaultsForRating(currentTemplate, '3');
            const fourDefaults = getDefaultsForRating(currentTemplate, '4');
            const fiveDefaults = getDefaultsForRating(currentTemplate, '5');

            setRatingNAValue(naDefaults.value);
            setRatingNAEnabled(naDefaults.enabled);
            setRating1Disabled(!oneDefaults.enabled);
            setRating2Disabled(!twoDefaults.enabled);
            setRating3Disabled(!threeDefaults.enabled);
            setRating4Disabled(!fourDefaults.enabled);
            setRating5Disabled(!fiveDefaults.enabled);

            const guide1Descriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.ratingGuide?.guide1?.[0]?.descriptorId);
            const guide2Descriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.ratingGuide?.guide2?.[0]?.descriptorId);
            const guide3Descriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.ratingGuide?.guide3?.[0]?.descriptorId);
            const guide4Descriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.ratingGuide?.guide4?.[0]?.descriptorId);
            const guide5Descriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.ratingGuide?.guide5?.[0]?.descriptorId);

            setRatingGuide1Descriptor(guide1Descriptor);
            setRatingGuide2Descriptor(guide2Descriptor);
            setRatingGuide3Descriptor(guide3Descriptor);
            setRatingGuide4Descriptor(guide4Descriptor);
            setRatingGuide5Descriptor(guide5Descriptor);

            setRatingGuideNA(getDescriptorForLocale(naDefaults.ratingGuide, templateLocale)?.description ?? '');
            setRatingGuide1(guide1Descriptor?.description ?? '');
            setRatingGuide2(guide2Descriptor?.description ?? '');
            setRatingGuide3(guide3Descriptor?.description ?? '');
            setRatingGuide4(guide4Descriptor?.description ?? '');
            setRatingGuide5(guide5Descriptor?.description ?? '');
        } else {
            setRatingNAValue(assessmentTemplateDefaults?.questionnaireAnswers?.ratingNAValue ?? null);
            setRatingNAEnabled(assessmentTemplateDefaults?.questionnaireAnswers?.ratingNAEnabled ?? null);
            setRating1Disabled(assessmentTemplateDefaults?.questionnaireAnswers?.rating1Disabled ?? null);
            setRating2Disabled(assessmentTemplateDefaults?.questionnaireAnswers?.rating2Disabled ?? null);
            setRating3Disabled(assessmentTemplateDefaults?.questionnaireAnswers?.rating3Disabled ?? null);
            setRating4Disabled(assessmentTemplateDefaults?.questionnaireAnswers?.rating4Disabled ?? null);
            setRating5Disabled(assessmentTemplateDefaults?.questionnaireAnswers?.rating5Disabled ?? null);

            setRatingGuideNA(currentPrompt?.ratingGuideNA ?? '');
            setRatingGuide1(currentPrompt?.ratingGuide1 ?? '');
            setRatingGuide2(currentPrompt?.ratingGuide2 ?? '');
            setRatingGuide3(currentPrompt?.ratingGuide3 ?? '');
            setRatingGuide4(currentPrompt?.ratingGuide4 ?? '');
            setRatingGuide5(currentPrompt?.ratingGuide5 ?? '');
        }
    }, [
        currentTemplate,
        currentTemplatePrompt,
        assessmentTemplateDefaults,
        currentPrompt,
        shouldDisplayTemplate,
        templateLocale,
        currentTemplateState,
    ]);

    const isReadOnly = shouldDisplayTemplate ? true : assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    const totalVotes = totalVotesFromParticipantRatingResponseMap(participantRatingResponseMap);
    const iDontKnowResponse = participantRatingResponseMap.get('idk');

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse }));
                setState({ ...state, responseSaved: true });
            }
        },
    });

    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);

    const [state, setState] = useState<AssessmentPromptRatingState>(initialAssessmentPromptRatingState);

    const onToggle = useCallback(
        (rating: number) => {
            return () => {
                if (!isReadOnly) {
                    let newRating: number | null = rating;
                    if (rating === state.ratingSelected) {
                        newRating = null;
                    }
                    dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
                    createOrUpdateAssessmentResponses({
                        variables: {
                            input: {
                                assessmentId,
                                responses: [
                                    {
                                        promptId,
                                        rating: newRating,
                                    },
                                ],
                            },
                        },
                    });
                    setState({ ...state, ratingSelected: newRating });
                }
            };
        },
        [isReadOnly, state, dispatch, promptId, promptIndex, createOrUpdateAssessmentResponses, assessmentId]
    );

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_rating}
                updateConfirmationText={
                    !loading && !error && state.responseSaved ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.rating_saved}` : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                tryAgainAction={() => {
                    state.ratingSelected && onToggle(state.ratingSelected);
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_rating,
        appLabels.assessment.facilitate.rating_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        state.responseSaved,
        state.ratingSelected,
        promptIndexBeingUpdated,
        onToggle,
    ]);

    if (assessmentIdPromptId && state.id !== assessmentIdPromptId) {
        // Prompt or assessment has changed, update state
        setState({
            ...initialAssessmentPromptRatingState,
            ratingSelected: rating,
            id: assessmentIdPromptId,
        });
        return null;
    }

    const ratingTileGroup = [];
    if (rating5Disabled !== true) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === 5}
                onToggle={onToggle(5)}
                groupName={promptId}
                rating={'5'}
                ratingGuide={ratingGuide5}
                ratingGuideDescriptor={ratingGuide5Descriptor}
                voteCount={participantRatingResponseMap.get('5')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('5')?.participantComments}
            />
        );
    }
    if (rating4Disabled !== true) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === 4}
                onToggle={onToggle(4)}
                groupName={promptId}
                rating={'4'}
                ratingGuide={ratingGuide4}
                ratingGuideDescriptor={ratingGuide4Descriptor}
                voteCount={participantRatingResponseMap.get('4')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('4')?.participantComments}
            />
        );
    }
    if (rating3Disabled !== true) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === 3}
                onToggle={onToggle(3)}
                groupName={promptId}
                rating={'3'}
                ratingGuide={ratingGuide3}
                ratingGuideDescriptor={ratingGuide3Descriptor}
                voteCount={participantRatingResponseMap.get('3')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('3')?.participantComments}
            />
        );
    }
    if (rating2Disabled !== true) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === 2}
                onToggle={onToggle(2)}
                groupName={promptId}
                rating={'2'}
                ratingGuide={ratingGuide2}
                ratingGuideDescriptor={ratingGuide2Descriptor}
                voteCount={participantRatingResponseMap.get('2')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('2')?.participantComments}
            />
        );
    }
    if (rating1Disabled !== true) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === 1}
                onToggle={onToggle(1)}
                groupName={promptId}
                rating={'1'}
                ratingGuide={ratingGuide1}
                ratingGuideDescriptor={ratingGuide1Descriptor}
                voteCount={participantRatingResponseMap.get('1')?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get('1')?.participantComments}
            />
        );
    }
    if (ratingNAEnabled && ratingNAValue !== null) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={state.ratingSelected === ratingNAValue}
                onToggle={onToggle(ratingNAValue)}
                groupName={promptId}
                rating={Constants.ASSESSMENT_DEFAULT_NA_LABEL}
                ratingGuide={ratingGuideNA}
                voteCount={participantRatingResponseMap.get(Constants.ASSESSMENT_DEFAULT_NA_LABEL)?.votes ?? null}
                totalVotes={totalVotes}
                participantComments={participantRatingResponseMap.get(Constants.ASSESSMENT_DEFAULT_NA_LABEL)?.participantComments}
            />
        );
    }

    if (iDontKnowResponse) {
        ratingTileGroup.push(
            <RatingTile
                isSelected={false}
                groupName={promptId}
                rating={Constants.ASSESSMENT_DEFAULT_INCOMPLETE_LABEL}
                ratingGuide={appLabels.assessment.facilitate.i_dont_know}
                voteCount={iDontKnowResponse.votes ?? null}
                totalVotes={totalVotes}
                participantComments={iDontKnowResponse.participantComments}
            />
        );
    }

    if (shouldDisplayTemplate ? templateResponseType === ResponseType.Rating : responseType === AssessmentResponseTypes.RATING) {
        return (
            <div className='rating-tile-group-wrapper' data-testid='ratings-wrapper'>
                {ratingTileGroup}
                {updateStatusIndicator()}
            </div>
        );
    }

    return null;
};

export default withAuthContext(withAppLabelsContext(withFlashContext(AssessmentPromptRating)));
