import { DeleteModuleInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import dompurify from 'dompurify';
import { FunctionComponent, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import { DELETE_MODULE } from '../../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import { deleteTemplateModule, removeInProgressRequest } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

type DeleteCategoryModalProps = AppLabelsContextInterface & {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

const DeleteCategoryModal: FunctionComponent<DeleteCategoryModalProps> = ({ appLabels, visible, setVisible }) => {
    const dispatch = useAppDispatch();

    const currentViewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentModuleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);

    const [deleteCategoryMutation, { loading }] = useMutation(DELETE_MODULE, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (_, options) => {
            dispatch(deleteTemplateModule({ viewId: currentViewId, moduleId: currentModuleId }));
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    const onConfirm = useCallback(async () => {
        try {
            const deleteCategoryInput: DeleteModuleInput = {
                moduleId: currentModuleId,
            };

            const requestId = uuid();
            await deleteCategoryMutation({ variables: { input: deleteCategoryInput }, context: { requestId } });
        } finally {
            setVisible(false);
        }
    }, [currentModuleId, deleteCategoryMutation, setVisible]);

    // Render HTML app label
    const deleteCategoryWarning = dompurify.sanitize(appLabels.manage_templates.edit.modify_structure.delete_category_warning);

    return (
        <Modal
            data-testid='delete-category-modal'
            onDismiss={() => setVisible(false)}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' disabled={loading} onClick={() => setVisible(false)}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button variant='primary' loading={loading} onClick={onConfirm} data-testid='btn-confirm-delete-category'>
                            {appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={appLabels.manage_templates.edit.modify_structure.delete_category}
            visible={visible}
        >
            <div dangerouslySetInnerHTML={{ __html: deleteCategoryWarning }} />
        </Modal>
    );
};

export default withAppLabelsContext(DeleteCategoryModal);
