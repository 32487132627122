import { DeleteRecommendationAssignmentsCommand, RecommendationOutput } from '@amzn/aws-assessment-recommendation-service-client';
import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteRecommendationsSuccess } from './RecommendationsSlice';
import { getRecommendationServiceClient } from '../../../../../../api/recommendationService/RecommendationServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import Constants from '../../../../../../common/Constants';
import rumClient from '../../../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';

export interface DeleteRecommendationsModalProps {
    recommendationsToDelete: RecommendationOutput[];
    onDismiss: () => void;
    assessmentId: string;
    categoryId: string;
}

const DeleteRecommendationsModal = (props: DeleteRecommendationsModalProps & AppLabelsContextInterface): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const onConfirmDelete = useCallback(async () => {
        setIsLoading(true);
        const recommendationIdsToDelete = props.recommendationsToDelete.map((recommendation) => recommendation.recommendationId);
        const rsClient = getRecommendationServiceClient();
        try {
            await rsClient.send(
                new DeleteRecommendationAssignmentsCommand({
                    recommendationIds: recommendationIdsToDelete,
                    resourceId: `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${props.assessmentId}`,
                })
            );
            setErrorMessage('');
            dispatch(deleteRecommendationsSuccess({ recommendationsToDelete: props.recommendationsToDelete }));
            setIsLoading(false);
            props.onDismiss();
        } catch (err) {
            rumClient.recordError(err);
            setErrorMessage((err as Error)?.message);
            setIsLoading(false);
        }
    }, [dispatch, props]);
    return (
        <Modal
            visible
            header={props.appLabels.assessment.results.review_all.report.recommended_actions.delete_recommendations}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={props.onDismiss} loading={isLoading}>
                            {props.appLabels.user_actions.cancel}
                        </Button>
                        <Button variant='primary' onClick={onConfirmDelete} loading={isLoading}>
                            {props.appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={props.onDismiss}
        >
            {props.appLabels.assessment.results.review_all.report.recommended_actions.confirm_delete_recommendations}
            <ul>
                {props.recommendationsToDelete.map((recommendation) => (
                    <li key={recommendation.recommendationId}>{recommendation.verbiage}</li>
                ))}
            </ul>
            {errorMessage ? (
                <Alert type='error' header={props.appLabels.assessment.results.review_all.report.recommended_actions.failed_delete_recommendations}>
                    {errorMessage}
                </Alert>
            ) : null}
        </Modal>
    );
};

export default withLocalizationContext(withAppLabelsContext(DeleteRecommendationsModal));
