export default {
    A2S_MAX_POSSIBLE_RATING: 5,
    A2T_APP_ID: 'A2T',
    ALL_CUSTOMERS: 'ALL_CUSTOMERS',
    AMAZON_CORPORATE_USER_PREFIX: 'AmazonCorporate_',
    APN_USER_PREFIX: 'APN_',
    ASSESSMENT_DEFAULT_MIN_SCORE: 0,
    ASSESSMENT_DEFAULT_MAX_SCORE: 5,
    ASSESSMENT_DEFAULT_NA_SCORE: -1,
    ASSESSMENT_DEFAULT_INCOMPLETE_LABEL: '?',
    ASSESSMENT_DEFAULT_NA_LABEL: 'NA',
    ASSESSMENT_DEFAULT_QUESTION_LABEL: 'Q',
    ASSESSMENT_GOOD_SCORE: 4,
    ASSESSMENT_BAD_SCORE: 2,
    ASSESSMENT_RESULTS_TYPE_WORKSTREAMS: 'workstreams',
    ASSESSMENT_TOOL_MRA_PREFIX: 'awscat:mra:',
    AUTO_SAVE_TIMEOUT_MS: 3000,
    AWS_ORG_ID: 'AWS',
    AWS_COMPANY_NAME: 'Amazon Web Services',
    CUSTOMER_DROPDOWN_NUM: 3,
    DEFAULT_COLOR_BLACK: '#000000',
    DEFAULT_COLOR_WHITE: '#ffffff',
    DEFAULT_CUSTOMER: 'ALL_CUSTOMERS',
    DEFAULT_LANGUAGE: 'English',
    DEFAULT_LOCALE: 'en-US',
    DEFAULT_NUMBER_TEXT_FILTER_CHARS: 10,
    DEFAULT_RATING_COLOR_1: '#879596', // grey
    DEFAULT_RATING_COLOR_2: '#545B64', // dark grey
    DEFAULT_RATING_COLOR_3: '#EC7211', // orange
    DEFAULT_RATING_COLOR_4: '#1D8102', // green
    DEFAULT_RATING_COLOR_5: '#0073BB', // blue
    DEFAULT_RATING_COLOR_NA: '#D5DBDB', // light grey
    DEFAULT_RATING_COLOR_INCOMPLETE: '#D5DBDB', // light grey
    DEFAULT_STRATUM_COLOR_BAD: '#FF3333', // red
    DEFAULT_STRATUM_COLOR_CONCERN: '#FFFF66', // amber
    DEFAULT_STRATUM_COLOR_GOOD: '#00CC00', // light green
    DEFAULT_STRATUM_COLOR_INCOMPLETE: '#FFFFFF', // white
    FACET_SPACE_TYPE_PERSPECTIVES: 'Perspectives',
    FACET_SPACE_TYPE_WORKSTREAMS: 'Workstreams',
    LOCAL_STORAGE_LANGUAGE_KEY: 'language',
    MAP_ID_BACKWARD_COMPATIBLE_REGEX: /^[0-9]{5}$/,
    ORG_SUFFIX: 'orgId',
    PRC_MAX_LOGO_SIZE_KB: 400,
    RECOMMENDATIONS_RESOURCE_ID_PREFIX: 'AWSAssessmentService:assessment:',
    RECOMMENDATIONS_TARGET_ID_PREFIX: 'AWSAssessmentService:assessmentType:',
    RECOMMENDATION_VERBIAGE_MIN_LENGTH: 1,
    RECOMMENDATION_VERBIAGE_MAX_LENGTH: 3000,
    RECOMMENDATION_BENEFIT_MIN_LENGTH: 0,
    RECOMMENDATION_BENEFIT_MAX_LENGTH: 3000,
    RECOMMENDATION_ACTION_OWNER_MIN_LENGTH: 0,
    RECOMMENDATION_ACTION_OWNER_MAX_LENGTH: 50,
    SAMPLE_ASSESSMENT_ID: 'sample-assessment',
    SEE_MORE_CUSTOMERS: 'SEE_MORE_CUSTOMERS',
    TABLE_DEFAULT_PAGE_SIZE_COMPLETED_ASSESSMENTS: 25,
    TABLE_DEFAULT_PAGE_SIZE_IN_PROGRESS_ASSESSMENTS: 10,
    TABLE_DEFAULT_PAGE_SIZE_ASSESSMENT_TEMPLATES: 9,
    TABLE_DEFAULT_PAGE_SIZE_CUSTOMERS: 25,
    TABLE_DEFAULT_PAGE_SIZE_LIST_TEMPLATES: 25,
    TEXT_MAX_CHAR_LIMIT_200: 200,
    TEXT_MAX_CHAR_LIMIT_2000: 2000,
    VECTOR_SPACE_TYPE_ACTIVITIES: 'Activities',
    VECTOR_SPACE_TYPE_CATEGORIES: 'Categories',
};
