import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Button, Select, SelectProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ApolloError, useQuery } from '@apollo/client';
import { FunctionComponent, useState } from 'react';

import LiveSessionCreateDialog from './LiveSessionCreateDialog';
import { LIST_MY_SESSIONS, LIST_MY_SESSIONS_DEFAULT_LIMIT, generateWbsReferenceId, isWbsSessionActive } from '../../../api/wbs/WbsClient';
import { SessionInfo } from '../../../api/wbs/WbsTypes';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { assessmentIsReadOnly } from '../Utils';
import { liveSessionSelected } from '../facilitate/CurrentAssessmentSlice';

const findSelectedOption = (sessionId: string | null, options: SelectProps.Options): OptionDefinition | null => {
    if (sessionId) {
        return options.find((o) => (o as OptionDefinition).value === sessionId) as OptionDefinition;
    }
    return null;
};

type LiveSessionSelectionsProps = AppLabelsContextInterface &
    AuthContextInterface & {
        readOnly: boolean;
    };

const LiveSessionSelections: FunctionComponent<LiveSessionSelectionsProps> = ({ auth, appLabels, readOnly }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const selectedLiveSessionId = useAppSelector((state) => state.currentAssessmentState.selectedLiveSessionId);

    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);
    const liveSessionEnabled = currentAssessment?.template?.defaults?.questionnaireAnswers?.wbsEnabled;
    const isReadOnly = readOnly || assessmentIsReadOnly(auth?.getUserInfo()?.userId, currentAssessment, assessmentId);

    const getApiErrorText = (error: ApolloError | undefined): string => {
        return error ? `${appLabels.assessment.live_session.error_api}: ${error.message}` : '';
    };

    const CREATE_NEW_SESSION_OPTION: OptionDefinition = {
        value: 'createSession',
        label: appLabels.assessment.live_session.create_new_session,
    };

    const constructSelectOption = (sessionInfo: SessionInfo): OptionDefinition => {
        const value = sessionInfo.sessionId;
        const isActive = isWbsSessionActive(sessionInfo);
        const description = isActive ? appLabels.assessment.live_session.active : appLabels.assessment.live_session.inactive;
        const sessionMetadata = JSON.parse(sessionInfo.sessionMetadata);
        const label = sessionMetadata.sessionName;

        return { value, label, description } as OptionDefinition;
    };

    const constructSelectOptions = (mySessions: [any]): SelectProps.Options => {
        const dropdownItems = mySessions.map((session) => constructSelectOption(session));
        if (!isReadOnly) {
            dropdownItems.push({
                ...CREATE_NEW_SESSION_OPTION,
                disabled: mySessions.some((session) => isWbsSessionActive(session)),
            });
        }
        return dropdownItems;
    };

    const { loading, error, data } = useQuery(LIST_MY_SESSIONS, {
        variables: {
            input: {
                referenceId: generateWbsReferenceId(assessmentId ?? null),
                limit: LIST_MY_SESSIONS_DEFAULT_LIMIT,
            },
        },
    });

    const [showCreateSessionDialog, setShowCreateSessionDialog] = useState(false);

    const mySessions = data?.listMySessions ?? [];
    const options: SelectProps.Options = mySessions.length ? constructSelectOptions(mySessions) : [];
    if (!selectedLiveSessionId && mySessions.length) {
        dispatch(liveSessionSelected(mySessions[0].sessionId));
    }
    const selectedOption: OptionDefinition | null = selectedLiveSessionId ? findSelectedOption(selectedLiveSessionId, options) : null;

    if (!mySessions.length) {
        // no sessions associated with this assessment
        // offer to start polling session if:
        // 1) live session is enabled
        // 2) assessment is not readonly
        if (!liveSessionEnabled || isReadOnly) {
            return null;
        }

        return (
            <div className='awscat-assessment-live-session-selections'>
                <Button onClick={() => setShowCreateSessionDialog(true)}>{appLabels.assessment.live_session.start_polling_session}</Button>
                <LiveSessionCreateDialog showDialog={showCreateSessionDialog} setShowDialog={setShowCreateSessionDialog} />
            </div>
        );
    }

    return (
        <div className='awscat-assessment-live-session-selections'>
            <Select
                options={options}
                selectedOption={selectedOption}
                onChange={({ detail }) => {
                    if (detail.selectedOption.value === CREATE_NEW_SESSION_OPTION.value) {
                        setShowCreateSessionDialog(true);
                    } else {
                        detail.selectedOption.value && dispatch(liveSessionSelected(detail.selectedOption.value));
                    }
                }}
                statusType={loading ? 'loading' : 'finished'}
                errorText={getApiErrorText(error)}
            />
            <LiveSessionCreateDialog showDialog={showCreateSessionDialog} setShowDialog={setShowCreateSessionDialog} />
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(LiveSessionSelections));
