import { A2SClient, Assessment, SnapshotsSubQuery } from '@amzn/awscat-aws-assessment-service-typescript-client';

/**
 * Get all snapshots with metadata only
 * @param a2sClient
 * @param assessmentId
 * @param locale
 * @returns
 */
export const getAssessmentAllSnapshotsMetadata = async (a2sClient: A2SClient, assessmentId: string, locale: string): Promise<Assessment> => {
    let assessment: Assessment = null;
    let pageNumber = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const response = await a2sClient.getAssessments(
            {
                assessments: [{ id: assessmentId }],
                locale,
            },
            {
                customerAccount: true,
                permissions: true,
                template: true,
                snapshots: SnapshotsSubQuery.METADATA_ONLY,
                snapshotsPage: pageNumber,
            }
        );
        // get paginated snapshots
        const getAssessmentsResponse = response?.data?.getAssessments?.items?.[0] || null;
        if (!assessment) {
            assessment = getAssessmentsResponse;
        } else {
            assessment.snapshotConnection.snapshots.push(...(getAssessmentsResponse?.snapshotConnection?.snapshots || []));
        }
        pageNumber = getAssessmentsResponse?.snapshotConnection?.nextPage;

        // nextPage is set to -1 when there are no more snapshots to get
        if (!pageNumber || pageNumber < 0 || !getAssessmentsResponse?.snapshotConnection?.snapshots?.length) {
            break;
        }
    }
    return assessment;
};

/**
 * Get a single snapshot with metadata, content, and template
 * @param a2sClient
 * @param assessmentId
 * @param snapshotId
 * @param locale
 * @returns
 */
export const getAssessmentSingleSnapshotContentAndTemplate = async (
    a2sClient: A2SClient,
    assessmentId: string,
    snapshotId: string,
    locale: string
): Promise<Assessment> => {
    const response = await a2sClient.getAssessments(
        {
            assessments: [{ id: assessmentId, snapshotWithTemplate: true, snapshotIds: [snapshotId] }],
            locale,
        },
        {
            customerAccount: true,
            permissions: true,
            template: true,
            snapshots: SnapshotsSubQuery.METADATA_AND_CONTENT,
        }
    );
    return response?.data?.getAssessments?.items?.[0] || null;
};
