export const baseJsonTemplate = {
    title: 'Base Template',
    description: 'Base Template contains the basic structure of a JSON template. Most of the fields here are overwritten by ConvertExcelToJson.ts',
    deliveryGuidance: {
        description: '',
        learnMore: '',
    },
    type: 'Sample',
    version: 'V1.0',
    lastUpdated: '06/12/2023',
    metadataConfig: {
        excludeOptionalMetadataKeys: [],
        requiredMetadataKeys: [],
    },
    defaults: {
        questionnaireAnswers: {
            perspective: 'perspective1',
            rating1: '',
            rating2: '',
            rating3: '',
            rating4: '',
            rating5: '',
            ratingNA: '',
            ratingNATitle: 'N/A',
            ratingNAValue: -1,
            ratingNAEnabled: true,
            minAnswersToScore: '80',
            useQuestionIndex: true,
            questionLabel: 'Q',
            showGuidance: true,
        },
        report: {
            report: {
                title: 'Sample Assessment',
                categoryLabel: 'Category',
            },
            heatmap: {
                title: 'Sample Assessment Heatmap',
            },
            scoreBarChart: {
                title: 'Sample Assessment Scores',
            },
            radar: {
                title: 'Sample Assessment Radar Chart',
            },
        },
    },
    section: {
        label: 'Perspectives',
        perspectives: [
            {
                id: 'section1',
                label: 'Section 1',
                categories: [
                    {
                        id: 'category1',
                        abbreviation: 'Category 1',
                        name: 'Category 1',
                        ability: 'Description of the category and why it is important',
                        prescribedActions: [
                            {
                                text: 'TBD',
                            },
                        ],
                        questions: [
                            {
                                answerType: 'text',
                                id: 'section1-category1-0',
                                question: 'TBD?',
                                context: 'TBD',
                                hint: 'TBD',
                                rating5: 'TBD',
                                rating4: 'TBD',
                                rating3: 'TBD',
                                rating2: 'TBD',
                                rating1: 'TBD',
                                responseSelections: {
                                    selectionDescription: 'Select one',
                                    selections: [
                                        {
                                            selectionLabel: 'Cost reduction',
                                            selectionId: 'CostReduction',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
