import { TextContent } from '@amzn/awsui-components-react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';

interface LegendBoxProps {
    premobilize?: boolean;
    color?: string;
    description?: string;
}

const LegendBox: (props: LegendBoxProps & AppLabelsContextInterface) => JSX.Element = ({ color, description, premobilize, appLabels }) => {
    return (
        <>
            <div className='awscat-assessment-report' style={{ backgroundColor: color }}>
                {premobilize ? (
                    <TextContent>
                        <small>{appLabels.assessment.results.review_all.heatmap.pre_mobilize}</small>
                    </TextContent>
                ) : null}
            </div>
            <TextContent className='awscat-assessment-report-help-panel'>{description}</TextContent>
        </>
    );
};

export default withAppLabelsContext(LegendBox);
