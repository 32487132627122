import { Box, Button, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { FunctionComponent } from 'react';

interface CopyLineToClipboardProps {
    title: string | null;
    content: string | null;
    disabled?: boolean; // default: false
}

const CopyLineToClipboard: FunctionComponent<CopyLineToClipboardProps> = ({ title, content, disabled }): JSX.Element => {
    if (disabled) {
        return (
            <span className='awscat-copy-line-to-clipboard'>
                <Box margin={{ right: 'xxs' }} display='inline-block'>
                    <Button variant='link' iconName='copy' disabled>
                        {' '}
                        {title ?? ''}{' '}
                    </Button>
                </Box>
            </span>
        );
    } else {
        return (
            <span className='awscat-copy-line-to-clipboard'>
                <Box margin={{ right: 'xxs' }} display='inline-block'>
                    <Popover
                        size='small'
                        position='top'
                        triggerType='custom'
                        dismissButton={false}
                        content={<StatusIndicator type='success'>{content ?? ''}</StatusIndicator>}
                    >
                        <Button
                            variant='link'
                            iconName='copy'
                            onClick={() => {
                                content && navigator.clipboard.writeText(content);
                            }}
                        >
                            {title ?? ''}
                        </Button>
                    </Popover>
                </Box>
            </span>
        );
    }
};

export default CopyLineToClipboard;
