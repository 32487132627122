import './AssessmentPromptSelect.scss';

import { AssessmentDescriptor, AssessmentPrompt, ResponseType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import {
    AssessmentResponseSingleSelection,
    AssessmentResponseTypes,
    Metadata,
    MetadataDefinition,
    MetadataValueType,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Select, SelectProps, TextContent } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useState } from 'react';

import AssessmentPromptEditableSelectGroup from './AssessmentPromptEditableSelectGroup';
import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import { CurrentTemplateState } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import { getDescriptorForLocale, getEditableDescriptor } from '../../../../administration/manage-templates/edit-template/TemplateUtils';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';

interface AssessmentPromptSingleSelectState {
    id: string | null;
    metadataValueSelected: string | null;
    responseSaved: boolean;
}

const initialAssessmentPromptSingleSelectState: AssessmentPromptSingleSelectState = {
    id: null,
    metadataValueSelected: null,
    responseSaved: false,
};

type AssessmentPromptSingleSelectProps = AppLabelsContextInterface &
    AuthContextInterface & {
        metadataToCollect?: MetadataDefinition;
        templateMetadataToCollect?: AssessmentPrompt;
        shouldDisplayTemplate: boolean;
    };

/**
 * This single select component is used to collect singleSelection type of response for:
 * 1) metadata of a Prompt of singleSelection valueType if metadataToCollect is defined or
 * 2) a Prompt of singleSelection answerType
 *
 * @param metadataToCollect specifies metadata to collect for the prompt.
 * @param templateMetadataToCollect a view-only representation of `metadataToCollect`. Used when editing a template
 * @returns AssessmentPromptSingleSelect react component
 */
const AssessmentPromptSingleSelect: FunctionComponent<AssessmentPromptSingleSelectProps> = ({
    auth,
    appLabels,
    metadataToCollect,
    templateMetadataToCollect,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const responseType = currentPrompt?.responseType ?? null;
    const response = currentPrompt?.response ?? null;
    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);

    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplatePrompt: TemplatePromptViewModel = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const currentlyEditingTemplate: boolean = useAppSelector((state) => state.currentTemplateState.currentlyEditing);
    const templateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);

    // Selections either from: templateMetadataToCollect, template, metadataToCollect, or prompt definition
    let selectionDescription: string;
    let selectionDescriptionDescriptor: AssessmentDescriptor;
    let collectMetadata: boolean;
    let selectionTitle: string;

    if (shouldDisplayTemplate) {
        // "Selection title" is actually like the prompt (e.g. "Progress to next level")
        selectionTitle = getDescriptorForLocale(templateMetadataToCollect?.descriptors, templateLocale)?.description;
        if (!templateMetadataToCollect) {
            selectionDescriptionDescriptor = getEditableDescriptor(
                currentTemplateState,
                currentTemplatePrompt?.selectionDescription?.[0]?.descriptorId
            );
            selectionDescription = selectionDescriptionDescriptor?.description;
        } else {
            // Retrieving text from templateMetadataToCollect
            selectionDescription = getDescriptorForLocale(templateMetadataToCollect?.selectionDescription, templateLocale)?.description;
        }
        collectMetadata = templateMetadataToCollect?.responseType === ResponseType.SingleSelection;
    } else {
        selectionTitle = metadataToCollect?.keyName;
        selectionDescription =
            metadataToCollect?.responseSelections?.selectionDescription ?? currentPrompt?.responseSelections?.selectionDescription ?? null;
        collectMetadata = metadataToCollect?.valueType === MetadataValueType.singleSelection;
    }

    const isReadOnly = shouldDisplayTemplate ? true : assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    const [singleSelectState, setSingleSelectState] = useState<AssessmentPromptSingleSelectState>(initialAssessmentPromptSingleSelectState);
    const [selectedOption, setSelectedOption] = useState<Readonly<SelectProps.Option>>(null);

    const getMetadataSelection = useCallback(() => {
        const metadata = currentPrompt?.metadata?.find((m) => m.key === metadataToCollect?.key);
        return metadata;
    }, [currentPrompt?.metadata, metadataToCollect?.key]);

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            const metadata = collectMetadata
                ? ([{ key: metadataToCollect?.key, value: singleSelectState.metadataValueSelected }] as Metadata[])
                : undefined;
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse, metadata }));
                setSingleSelectState({ ...singleSelectState, responseSaved: true });
            }
        },
    });

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_response}
                updateConfirmationText={
                    !loading && !error && singleSelectState.responseSaved
                        ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.response_saved}`
                        : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                // TODO: Add the logic for try again action: SIM: https://issues.amazon.com/issues/A2T-OE-49
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_response,
        appLabels.assessment.facilitate.response_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        singleSelectState.responseSaved,
        promptIndexBeingUpdated,
    ]);

    const initialResponseValue = useCallback(() => {
        if (collectMetadata) {
            const metadataSelection = getMetadataSelection();
            return metadataSelection?.value;
        }
        switch (responseType) {
            case AssessmentResponseTypes.SINGLE_SELECTION:
                if (response) {
                    return (response as AssessmentResponseSingleSelection)?.singleSelectValue || null;
                }
                return null;
            default:
                return null;
        }
    }, [collectMetadata, getMetadataSelection, response, responseType]);

    const updateValue = useCallback(() => {
        const singleSelectValue = selectedOption?.value || null;
        if (isReadOnly || !singleSelectValue) {
            return;
        }
        dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
        if (singleSelectValue !== initialResponseValue()) {
            if (collectMetadata) {
                const metadata = { key: metadataToCollect?.key, value: singleSelectValue };
                createOrUpdateAssessmentResponses({
                    variables: {
                        input: {
                            assessmentId: assessmentId,
                            responses: [
                                {
                                    promptId,
                                    metadata,
                                },
                            ],
                        },
                    },
                });
                setSingleSelectState({ ...singleSelectState, metadataValueSelected: singleSelectValue });
            } else {
                createOrUpdateAssessmentResponses({
                    variables: {
                        input: {
                            assessmentId: assessmentId,
                            responses: [
                                {
                                    promptId,
                                    singleSelectValue,
                                },
                            ],
                        },
                    },
                });
            }
        }
    }, [
        isReadOnly,
        dispatch,
        promptId,
        promptIndex,
        selectedOption?.value,
        initialResponseValue,
        collectMetadata,
        metadataToCollect?.key,
        createOrUpdateAssessmentResponses,
        assessmentId,
        singleSelectState,
    ]);

    if (
        metadataToCollect?.valueType === MetadataValueType.singleSelection ||
        responseType === AssessmentResponseTypes.SINGLE_SELECTION ||
        [templateMetadataToCollect?.responseType, currentTemplatePrompt?.responseType].includes(ResponseType.SingleSelection)
    ) {
        const selectionIdsForEditing: string[] = [];
        const selectionDescriptors: AssessmentDescriptor[] = [];
        const selectionItems = [];

        if (shouldDisplayTemplate) {
            const promptSelectionItems = templateMetadataToCollect?.selections ?? currentTemplatePrompt?.selections ?? [];
            selectionIdsForEditing.push(...promptSelectionItems.map((selection) => selection.selectionId));
            selectionDescriptors.push(
                ...promptSelectionItems.map(
                    (selection) =>
                        getEditableDescriptor(currentTemplateState, selection.selectionLabel[0].descriptorId) ||
                        // Currently, metadata prompts are not editable. So need to get the descriptor from the template using this function
                        getDescriptorForLocale(selection.selectionLabel, templateLocale)
                )
            );

            selectionItems.push(
                ...promptSelectionItems.map((selectionItem, index) => ({
                    label: selectionDescriptors[index].description,
                    value: selectionItem.selectionId,
                }))
            );
        } else {
            const selections = metadataToCollect?.responseSelections?.selections ?? currentPrompt?.responseSelections?.selections ?? [];
            selectionItems.push(...selections.map((selection) => ({ label: selection.selectionLabel, value: selection.selectionId })));
            if (assessmentIdPromptId && singleSelectState.id !== assessmentIdPromptId) {
                // Prompt or assessment has changed, update state
                setSingleSelectState({
                    ...singleSelectState,
                    id: assessmentIdPromptId,
                    responseSaved: false,
                });
                setSelectedOption(selectionItems.find((item) => item.value === initialResponseValue()) || null);
                return null;
            }
        }
        return (
            <div className='awscat-assessment-select-input'>
                {shouldDisplayTemplate && currentlyEditingTemplate ? (
                    <AssessmentPromptEditableSelectGroup
                        promptId={currentTemplatePrompt.promptId}
                        selectionDescriptionDescriptor={selectionDescriptionDescriptor}
                        selectionDescriptors={selectionDescriptors}
                        selectionIds={selectionIdsForEditing}
                    />
                ) : (
                    <>
                        {selectionTitle ? <TextContent>{selectionTitle}</TextContent> : null}
                        <Select
                            placeholder={selectionDescription}
                            options={selectionItems}
                            onChange={({ detail }) => {
                                if (!isReadOnly) {
                                    setSelectedOption(detail.selectedOption);
                                }
                            }}
                            selectedOption={selectedOption}
                            expandToViewport
                            selectedAriaLabel={appLabels.user_actions.selected}
                            onBlur={updateValue}
                            data-testid='single-select-input'
                        />
                        {updateStatusIndicator()}
                    </>
                )}
            </div>
        );
    }
    return null;
};

export default withAuthContext(withAppLabelsContext(withFlashContext(AssessmentPromptSingleSelect)));
