import { AssessmentPrompt, AssessmentTemplate } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Cards,
    CardsProps,
    CollectionPreferences,
    CollectionPreferencesProps,
    FormField,
    Header,
    Pagination,
    SpaceBetween,
    TextFilter,
} from '@amzn/awsui-components-react';
import { ValidationError } from 'class-validator';
import { useCallback, useEffect, useState } from 'react';

import { AssessmentCreateState } from './AssessmentCreateState';
import AssessmentTypeCard from './AssessmentTypeCard';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import dompurify from '../../../common/DomPurify';
import { errorLookup } from '../../../common/ValidatorUtils';
import { getDescriptorForLocale } from '../../administration/manage-templates/edit-template/TemplateUtils';
import EmptyTable from '../../common/EmptyTable';
import { updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

type ChangeHandler = (data: { type?: string; version: string; applicabilityPrompts: AssessmentPrompt[] | null }) => void;

type AssessmentTypePanelProps = {
    assessmentTemplates: AssessmentTemplate[];
    selectedAssessmentType: string | null;
    onChange: ChangeHandler;
    validationErrors: ValidationError[];
} & AppLabelsContextInterface &
    LocalizationContextInterface;

const AssessmentTypePanel: React.FunctionComponent<AssessmentTypePanelProps> = ({
    appLabels,
    assessmentTemplates,
    selectedAssessmentType,
    onChange,
    validationErrors,
    locale,
}): JSX.Element => {
    const getErrorText = errorLookup<AssessmentCreateState>(validationErrors);
    const dispatch = useAppDispatch();
    const openHelp = useAppSelector((state) => state.appHelpPanelState.open);
    const { inProgressAssessments, completedAssessments } = useAppSelector((state) => state.assessmentsState);

    const findMostUsedAssessmentTemplates = useCallback(() => {
        // Initialize frequence map for available templates
        const assessmentTemplatesFrequencyMap = assessmentTemplates.reduce((map, template) => {
            map.set(template.type, [template, 0]);
            return map;
        }, new Map<string, [AssessmentTemplate, number]>());

        const allMyAssessments = inProgressAssessments.concat(completedAssessments);
        allMyAssessments.forEach((assessment) => {
            const type = assessment.type;
            const templateFrequency = assessmentTemplatesFrequencyMap.get(type);
            if (templateFrequency) {
                assessmentTemplatesFrequencyMap.set(type, [templateFrequency[0], templateFrequency[1] + 1]);
            }
        });
        const mostUsedAssessmentTypes = Array.from(assessmentTemplatesFrequencyMap.values())
            .sort((a, b) => b[1] - a[1]) // [0] => type, [1] => frequency
            .map((e) => e[0]);
        return mostUsedAssessmentTypes;
    }, [assessmentTemplates, completedAssessments, inProgressAssessments]);

    const mostUsedAssessmentTemplates = findMostUsedAssessmentTemplates();

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_ASSESSMENT_TEMPLATES,
    });

    const sectionDefs: CardsProps.SectionDefinition<AssessmentTemplate>[] = [
        {
            id: 'id',
            header: '',
            content: (e) => <AssessmentTypeCard template={e}></AssessmentTypeCard>,
        },
    ];

    const filterItems = (template: AssessmentTemplate, filteringText: string): boolean => {
        const templateDescriptorForCurrentLocale = getDescriptorForLocale(template.descriptors, locale);
        const title = templateDescriptorForCurrentLocale.name;
        const type = template.type;
        const version = template.version;
        const deliveryGuidanceDescriptorForCurrentLocale = getDescriptorForLocale(template.descriptors, locale);
        const deliveryGuidance = deliveryGuidanceDescriptorForCurrentLocale.description;
        const deliveryGuidanceHelpContext = deliveryGuidanceDescriptorForCurrentLocale.helpContext;
        const rowItems = [title, type, version, deliveryGuidance, deliveryGuidanceHelpContext];

        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItems.some((rowItem) => {
            return rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
        });
    };

    // select first one from the template list if not already selected
    const selectedAssessmentTypeOrMostUsedType = selectedAssessmentType || mostUsedAssessmentTemplates[0]?.type;
    const initialSelectedTemplates = mostUsedAssessmentTemplates.filter((t) => t.type === selectedAssessmentTypeOrMostUsedType);

    const { items, collectionProps, filterProps, paginationProps } = useCollection(mostUsedAssessmentTemplates, {
        filtering: {
            empty: <EmptyTable title={appLabels.assessment.create.template_table_empty_title} subtitle='' />,
            noMatch: <EmptyTable title={appLabels.assessment.create.template_table_empty_title} subtitle='' />,
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });

    const [selectedItems, setSelectedItems] = useState<AssessmentTemplate[]>(initialSelectedTemplates);

    const tableOptionsSetting = () => [
        {
            label: '9',
            value: 9,
        },
        {
            label: '24',
            value: 24,
        },
        {
            label: '49',
            value: 49,
        },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.common.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.common.preferences_title}
        />
    );

    const tableHeader = (
        <Header counter={`(${items.length}/${assessmentTemplates.length})`}>{appLabels.assessment.create.template_table_header}</Header>
    );

    const updateHelpPanelForSelectedTemplate = useCallback(
        (selectedTemplate: AssessmentTemplate) => {
            const deliveryGuidanceDescriptorForCurrentLocale = getDescriptorForLocale(selectedTemplate.deliveryGuidance, locale);
            const deliveryGuidanceDescription = deliveryGuidanceDescriptorForCurrentLocale.description;
            const deliveryGuidanceHelpContext = deliveryGuidanceDescriptorForCurrentLocale.helpContext;

            const header = appLabels.assessment.create.help_panel_delivery_guidance;
            const content = deliveryGuidanceHelpContext || '';

            // Template Management pentest checked for XSS potential. Found that TM/A2T handled it so there's no issue
            const purifiedHtml = dompurify.sanitize(deliveryGuidanceDescription || '');
            const footer = (
                <SpaceBetween size={'s'} direction='vertical'>
                    <div dangerouslySetInnerHTML={{ __html: purifiedHtml }}></div>
                </SpaceBetween>
            );

            dispatch(
                updateAppHelpPanel({
                    header,
                    content,
                    footer,
                })
            );
        },
        [appLabels.assessment.create.help_panel_delivery_guidance, locale, dispatch]
    );

    useEffect(() => {
        const selectedTemplate = selectedItems[0];
        if (!selectedAssessmentType && selectedTemplate) {
            updateHelpPanelForSelectedTemplate(selectedTemplate);
            onChange({
                type: selectedTemplate.type,
                version: selectedTemplate.version,
                applicabilityPrompts: selectedTemplate.config.applicabilityPrompts,
            });
        }
    }, [
        appLabels.assessment.create.select_assessment_type.description,
        appLabels.assessment.create.select_assessment_type.title,
        dispatch,
        onChange,
        openHelp,
        selectedAssessmentType,
        selectedItems,
        updateHelpPanelForSelectedTemplate,
    ]);

    return (
        <FormField stretch={true} errorText={getErrorText('type')}>
            <Cards
                {...collectionProps}
                selectionType='single'
                selectedItems={selectedItems}
                onSelectionChange={({ detail }) => {
                    const selectedTemplate = detail.selectedItems[0];
                    setSelectedItems(detail.selectedItems);
                    updateHelpPanelForSelectedTemplate(selectedTemplate);
                    onChange({
                        type: selectedTemplate.type,
                        version: selectedTemplate.version,
                        applicabilityPrompts: selectedTemplate.config.applicabilityPrompts,
                    });
                }}
                trackBy={(e) => `${e.type}:${e.version}`}
                cardDefinition={{
                    header: (e) => getDescriptorForLocale(e.descriptors, locale).name,
                    sections: sectionDefs,
                }}
                cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 3 }]}
                items={items}
                filter={
                    <SpaceBetween direction='horizontal' size='m'>
                        <TextFilter
                            {...filterProps}
                            countText={''}
                            filteringPlaceholder={appLabels.assessment.create.select_assessment_type.filter_placeholder}
                        />
                    </SpaceBetween>
                }
                header={tableHeader}
                pagination={<Pagination {...paginationProps} />}
                preferences={tablePreferences}
            />
        </FormField>
    );
};

export default withLocalizationContext(withAppLabelsContext(AssessmentTypePanel));
