export interface TextMarker {
    contains: (target: string | null | undefined, searchText?: string | null | undefined) => boolean | null | undefined;
    markText: (target: string | null | undefined, searchText?: string | null | undefined) => JSX.Element[] | null | undefined;
}

export const getTextMarker = (filter: string | null | undefined): TextMarker => {
    return {
        contains: (target: string | null | undefined, searchText: string | null | undefined = filter) => {
            const targetCopy = target?.trim().toLocaleLowerCase();
            searchText = searchText?.trim().toLocaleLowerCase();
            const searchLength = searchText?.length;
            if (searchLength && searchLength > 0 && targetCopy && searchText) {
                return targetCopy?.indexOf(searchText) > -1;
            }
            return false;
        },
        markText: (target: string | null | undefined, searchText: string | null | undefined = filter) => {
            const elements: JSX.Element[] = [];
            const searchLength = searchText?.length;
            const targetCopy = target?.trim().toLocaleLowerCase();
            searchText = searchText?.trim().toLocaleLowerCase();
            let lastFoundEndIndex = 0;
            while (target && searchText && searchLength && searchLength > 0 && targetCopy && targetCopy.indexOf(searchText, lastFoundEndIndex) > -1) {
                const foundIndex = targetCopy?.indexOf(searchText, lastFoundEndIndex);
                const nonmatched = target.substring(lastFoundEndIndex, foundIndex);
                const matched = target.substr(foundIndex, searchLength);
                if (nonmatched && nonmatched.length > 0) {
                    elements.push(<>{nonmatched}</>);
                }
                elements.push(<mark>{matched}</mark>);
                lastFoundEndIndex = foundIndex + searchLength;
            }
            if (target && target?.length > 0 && lastFoundEndIndex < target.length) {
                elements.push(<>{target.substring(lastFoundEndIndex)}</>);
            }
            return elements;
        },
    };
};
