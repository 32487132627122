import './AWSCatIcon.scss';

import React, { FunctionComponent } from 'react';

const getViewBox = () => {
    return '0 0 24 24';
};

const getPath = (name: string, props: AwsCatIconProps) => {
    switch (name) {
        case 'add':
            return (
                <path
                    {...props}
                    d='M21,11h-8V3c0-0.552-0.448-1-1-1s-1,0.448-1,1v8H3c-0.552,0-1,0.448-1,1s0.448,1,1,1h8v8c0,0.553,0.448,1,1,1s1-0.447,1-1
          v-8h8c0.552,0,1-0.448,1-1S21.552,11,21,11z'
                />
            );
        case 'add inline':
            return (
                <path
                    {...props}
                    d='M12,1C5.925,1,1,5.925,1,12c0,6.075,4.925,11,11,11s11-4.925,11-11C23,5.925,18.075,1,12,1z M17,13h-4v4
          c0,0.553-0.448,1-1,1s-1-0.447-1-1v-4H7c-0.552,0-1-0.448-1-1s0.448-1,1-1h4V7c0-0.552,0.448-1,1-1s1,0.448,1,1v4h4
          c0.552,0,1,0.448,1,1S17.552,13,17,13z'
                />
            );
        case 'arrow left':
            return (
                <path
                    {...props}
                    d='M18.482,2.143l-15.03,9c-0.604,0.361-0.604,1.354,0,1.715l15.03,9c0.309,0.186,0.695,0.19,1.01,0.013
                    C19.806,21.693,20,21.36,20,21V3c0-0.36-0.194-0.692-0.508-0.87C19.177,1.952,18.792,1.957,18.482,2.143z'
                />
            );
        case 'arrow right':
            return (
                <path
                    {...props}
                    d='M5.518,21.857l15.03-9c0.604-0.361,0.604-1.354,0-1.715l-15.03-9c-0.309-0.186-0.695-0.19-1.01-0.013
		C4.194,2.307,4,2.64,4,3v18c0,0.36,0.194,0.692,0.508,0.87C4.823,22.048,5.208,22.043,5.518,21.857z'
                />
            );
        case 'attachment':
            return (
                <path
                    {...props}
                    d='M10,22.976c-1.514,0-3.027-0.589-4.207-1.769C4.66,20.074,4.036,18.58,4.036,17s0.624-3.074,1.757-4.207l6.5-6.5
          c1.492-1.493,3.922-1.493,5.414,0c1.493,1.493,1.493,3.921,0,5.414l-6.5,6.5c-0.391,0.391-1.023,0.391-1.414,0
          s-0.391-1.023,0-1.414l6.5-6.5c0.713-0.713,0.713-1.873,0-2.586s-1.873-0.713-2.586,0l-6.5,6.5C6.452,14.963,6.036,15.954,6.036,17
          s0.416,2.037,1.171,2.793c1.566,1.566,4.02,1.566,5.586,0l6.5-6.5c2.326-2.327,2.326-6.259,0-8.586
          C18.16,3.574,16.635,2.95,15,2.95s-3.16,0.624-4.293,1.757l-8,8c-0.391,0.391-1.023,0.391-1.414,0s-0.391-1.023,0-1.414l8-8
          C10.804,1.782,12.831,0.95,15,0.95c2.17,0,4.196,0.832,5.707,2.343c3.146,3.147,3.146,8.267,0,11.414l-6.5,6.5
          C13.027,22.387,11.514,22.976,10,22.976z'
                />
            );
        case 'attend accept':
            return (
                <path
                    {...props}
                    d='M12,1C5.925,1,1,5.925,1,12c0,6.075,4.925,11,11,11s11-4.925,11-11C23,5.925,18.075,1,12,1z M17.707,9.707l-7,7
          C10.512,16.902,10.256,17,10,17s-0.512-0.098-0.707-0.293l-3-3c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L10,14.586
          l6.293-6.293c0.391-0.391,1.023-0.391,1.414,0S18.098,9.316,17.707,9.707z'
                />
            );
        case 'attend decline':
            return (
                <path
                    {...props}
                    d='M12,1C5.925,1,1,5.925,1,12s4.925,11,11,11s11-4.925,11-11S18.075,1,12,1z M16.707,15.293c0.391,0.391,0.391,1.023,0,1.414
          C16.512,16.902,16.256,17,16,17s-0.512-0.098-0.707-0.293L12,13.414l-3.293,3.293C8.512,16.902,8.256,17,8,17
          s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L10.586,12L7.293,8.707c-0.391-0.391-0.391-1.023,0-1.414
          s1.023-0.391,1.414,0L12,10.586l3.293-3.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L13.414,12L16.707,15.293z'
                />
            );
        case 'back':
            return (
                <path
                    {...props}
                    d='M16,22c-0.256,0-0.512-0.098-0.707-0.293l-9-9c-0.391-0.391-0.391-1.023,0-1.414l9-9c0.391-0.391,1.023-0.391,1.414,0
      s0.391,1.023,0,1.414L8.414,12l8.293,8.293c0.391,0.391,0.391,1.023,0,1.414C16.512,21.902,16.256,22,16,22z'
                />
            );
        case 'book':
            return (
                <g id='book' {...props}>
                    <path
                        d='M4,14v4h5.5c1.5,0,1.5,0.09,2.5,1c1-0.91,1-1,2.5-1H20v-4V2h-6c-0.738,0-1.154,0.405-1.5,1h-1c-0.346-0.595-0.762-1-1.5-1
            H4V14z M13.5,5h4C17.776,5,18,5.224,18,5.5S17.776,6,17.5,6h-4C13.224,6,13,5.776,13,5.5S13.224,5,13.5,5z M13.5,8h3
            C16.776,8,17,8.224,17,8.5S16.776,9,16.5,9h-3C13.224,9,13,8.776,13,8.5S13.224,8,13.5,8z M13.5,11h4c0.276,0,0.5,0.224,0.5,0.5
            S17.776,12,17.5,12h-4c-0.276,0-0.5-0.224-0.5-0.5S13.224,11,13.5,11z M13.5,14h3c0.276,0,0.5,0.224,0.5,0.5S16.776,15,16.5,15h-3
            c-0.276,0-0.5-0.224-0.5-0.5S13.224,14,13.5,14z M6.5,5h4C10.776,5,11,5.224,11,5.5S10.776,6,10.5,6h-4C6.224,6,6,5.776,6,5.5
            S6.224,5,6.5,5z M6.5,8h3C9.776,8,10,8.224,10,8.5S9.776,9,9.5,9h-3C6.224,9,6,8.776,6,8.5S6.224,8,6.5,8z M6.5,11h4
            c0.276,0,0.5,0.224,0.5,0.5S10.776,12,10.5,12h-4C6.224,12,6,11.776,6,11.5S6.224,11,6.5,11z M6.5,14h3c0.276,0,0.5,0.224,0.5,0.5
            S9.776,15,9.5,15h-3C6.224,15,6,14.776,6,14.5S6.224,14,6.5,14z'
                    />
                    <path
                        className='opacity-5'
                        d='M22,3h-1v16h-6c-1.459,0-2.066,0.036-2.448,0.95c-0.094,0.224-1.011,0.224-1.104,0
            C11.066,19.036,10.459,19,9,19H3V3H2C1.45,3,1,3.45,1,4v16c0,0.552,0.448,1,1,1h7c0.596,0,1.127,0.26,1.494,0.674
            C10.68,21.883,10.947,22,11.226,22h1.547c0.279,0,0.547-0.117,0.732-0.326C13.873,21.26,14.404,21,15,21h7c0.552,0,1-0.448,1-1V4
            C23,3.45,22.55,3,22,3z'
                    />
                </g>
            );
        case 'bookmark':
            return (
                <path
                    d='M19,23c-0.171,0-0.342-0.044-0.496-0.132L12,19.151l-6.504,3.717c-0.31,0.177-0.689,0.176-0.998-0.003
        C4.19,22.686,4,22.356,4,22V3c0-1.105,0.895-2,2-2h12c1.105,0,2,0.895,2,2v19c0,0.356-0.19,0.686-0.498,0.865
        C19.347,22.955,19.173,23,19,23z M12,17c0.171,0,0.342,0.044,0.496,0.132L18,20.276V3H6v17.276l5.504-3.145
        C11.658,17.044,11.829,17,12,17z'
                />
            );
        case 'bookmark on':
            return (
                <path
                    {...props}
                    d='M18,1H6C4.895,1,4,1.895,4,3v19c0,0.356,0.19,0.686,0.498,0.865c0.309,0.179,0.688,0.18,0.998,0.003L12,19.151l6.504,3.717
		C18.658,22.956,18.829,23,19,23c0.173,0,0.347-0.045,0.502-0.135C19.81,22.686,20,22.356,20,22V3C20,1.895,19.105,1,18,1z'
                />
            );
        case 'caller add':
            return (
                <g id='caller_add' {...props}>
                    <path
                        d='M23,5c0,0.55-0.45,1-1,1h-2v2c0,0.55-0.45,1-1,1s-1-0.45-1-1V6h-2c-0.55,0-1-0.45-1-1s0.45-1,1-1h2V2c0-0.55,0.45-1,1-1
              s1,0.45,1,1v2h2C22.55,4,23,4.45,23,5z'
                    />
                    <path
                        className='opacity-5'
                        d='M10,12c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4S6,5.791,6,8C6,10.209,7.791,12,10,12z M10,13
              c-3.508,0-6.851,2.391-7.949,5.684c-0.12,0.359-0.026,0.756,0.242,1.023C2.982,20.396,5.113,22,10,22s7.018-1.604,7.707-2.293
              c0.268-0.268,0.361-0.664,0.241-1.023C16.851,15.391,13.508,13,10,13z'
                    />
                </g>
            );
        case 'calender':
            return (
                <g id='calendar' {...props}>
                    <path
                        d='M20,3h-1v2c0,1.104-0.896,2-2,2s-2-0.896-2-2V3H9v2c0,1.104-0.896,2-2,2S5,6.104,5,5V3H4C2.895,3,2,3.895,2,5v14v1
                        c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2v-1V5C22,3.895,21.105,3,20,3z M7,18.5C7,18.775,6.775,19,6.5,19h-1
                        C5.225,19,5,18.775,5,18.5v-1C5,17.225,5.225,17,5.5,17h1C6.775,17,7,17.225,7,17.5V18.5z M7,14.5C7,14.775,6.775,15,6.5,15h-1
                        C5.225,15,5,14.775,5,14.5v-1C5,13.225,5.225,13,5.5,13h1C6.775,13,7,13.225,7,13.5V14.5z M7,10.5C7,10.775,6.775,11,6.5,11h-1
                        C5.225,11,5,10.775,5,10.5v-1C5,9.225,5.225,9,5.5,9h1C6.775,9,7,9.225,7,9.5V10.5z M11,18.5c0,0.275-0.225,0.5-0.5,0.5h-1
                        C9.225,19,9,18.775,9,18.5v-1C9,17.225,9.225,17,9.5,17h1c0.275,0,0.5,0.225,0.5,0.5V18.5z M11,14.5c0,0.275-0.225,0.5-0.5,0.5h-1
                        C9.225,15,9,14.775,9,14.5v-1C9,13.225,9.225,13,9.5,13h1c0.275,0,0.5,0.225,0.5,0.5V14.5z M11,10.5c0,0.275-0.225,0.5-0.5,0.5h-1
                        C9.225,11,9,10.775,9,10.5v-1C9,9.225,9.225,9,9.5,9h1C10.775,9,11,9.225,11,9.5V10.5z M15,18.5c0,0.275-0.225,0.5-0.5,0.5h-1
                        c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1c0.275,0,0.5,0.225,0.5,0.5V18.5z M15,14.5c0,0.275-0.225,0.5-0.5,0.5
                        h-1c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1c0.275,0,0.5,0.225,0.5,0.5V14.5z M15,10.5
                        c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1C13,9.225,13.225,9,13.5,9h1C14.775,9,15,9.225,15,9.5V10.5z M19,18.5
                        c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1c0.275,0,0.5,0.225,0.5,0.5V18.5z
                        M19,14.5c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1c0.275,0,0.5,0.225,0.5,0.5
                        V14.5z M19,10.5c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1C17,9.225,17.225,9,17.5,9h1C18.775,9,19,9.225,19,9.5
                        V10.5z M6,5V2c0-0.552,0.448-1,1-1s1,0.448,1,1v3c0,0.552-0.448,1-1,1S6,5.552,6,5z M16,5V2c0-0.552,0.447-1,1-1s1,0.448,1,1v3
                        c0,0.552-0.447,1-1,1S16,5.552,16,5z'
                    />
                </g>
            );
        case 'chapter':
            return (
                <path
                    {...props}
                    d='M6.5,20H20V4c0-1.105-0.895-2-2-2H6C4.895,2,4,2.895,4,4v16c0,1.657,1.343,3,3,3h10c1.105,0,2-0.895,2-2H6.5
        C6.225,21,6,20.775,6,20.5C6,20.225,6.225,20,6.5,20z M17.5,6C17.776,6,18,6.224,18,6.5C18,6.776,17.776,7,17.5,7S17,6.776,17,6.5
        C17,6.224,17.224,6,17.5,6z M17.5,9C17.776,9,18,9.224,18,9.5c0,0.276-0.224,0.5-0.5,0.5S17,9.776,17,9.5C17,9.224,17.224,9,17.5,9
        z M17.5,12c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5S17,12.776,17,12.5C17,12.224,17.224,12,17.5,12z M17.5,15
        c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5S17,15.776,17,15.5C17,15.224,17.224,15,17.5,15z M7.5,6h7
        C14.775,6,15,6.225,15,6.5C15,6.775,14.775,7,14.5,7h-7C7.225,7,7,6.775,7,6.5C7,6.225,7.225,6,7.5,6z M7.5,9h7
        C14.775,9,15,9.225,15,9.5c0,0.275-0.225,0.5-0.5,0.5h-7C7.225,10,7,9.775,7,9.5C7,9.225,7.225,9,7.5,9z M7.5,12h7
        c0.275,0,0.5,0.225,0.5,0.5c0,0.275-0.225,0.5-0.5,0.5h-7C7.225,13,7,12.775,7,12.5C7,12.225,7.225,12,7.5,12z M7.5,15h7
        c0.275,0,0.5,0.225,0.5,0.5c0,0.275-0.225,0.5-0.5,0.5h-7C7.225,16,7,15.775,7,15.5C7,15.225,7.225,15,7.5,15z'
                />
            );
        case 'chat':
            return (
                <path
                    {...props}
                    d='M13.783,2l-3.54,0C6.264,2,2.711,4.803,2.099,8.734C1.327,13.687,5.184,18,10,18h2h1.005c0.48,0.716,0.766,1.573,0.766,2.5
        c0,0.464-0.071,1.069-0.207,1.644c-0.136,0.575,0.458,1.058,0.975,0.771c3.578-1.981,7.357-7.554,7.459-12.703
        C22.088,5.696,18.3,2,13.783,2z'
                />
            );
        case 'cloud':
            return (
                <path
                    {...props}
                    d='M23,12.427C23,15.503,20.506,18,17.431,18c-0.538,0-10.988,0-11.558,0C3.182,18,1,15.796,1,13.105
        c0-2.513,1.909-4.557,4.351-4.82C6.191,5.795,8.54,4,11.312,4c2.234,0,4.192,1.168,5.309,2.923
        c0.265-0.039,0.533-0.065,0.809-0.065C20.506,6.857,23,9.351,23,12.427z'
                />
            );
        case 'cloud backed up':
            return (
                <path
                    {...props}
                    d='M17.43,7.86c-0.27,0-0.54,0.03-0.81,0.06C15.5,6.17,13.55,5,11.31,5C8.54,5,6.19,6.8,5.35,9.29C2.91,9.55,1,11.59,1,14.11
        c0,2.69,2.18,4.87,4.87,4.87c1.45,0,9.96,0.02,11.56,0.02c3.08,0,5.57-2.5,5.57-5.57C23,10.35,20.51,7.86,17.43,7.86z
        M15.71,11.71l-4.5,4.5c-0.2,0.19-0.45,0.29-0.71,0.29s-0.51-0.1-0.71-0.29l-2.5-2.5c-0.39-0.39-0.39-1.03,0-1.42
        c0.39-0.39,1.03-0.39,1.42,0l1.79,1.8l3.79-3.8c0.39-0.39,1.03-0.39,1.42,0C16.1,10.68,16.1,11.32,15.71,11.71z'
                />
            );
        case 'cloud upload':
            return (
                <path
                    {...props}
                    d='M9.076,14.234l2.561-4.007C11.728,10.084,11.829,10,12,10c0.002,0-0.002,0,0,0c0.172,0.002,0.394,0.089,0.484,0.237
        l2.439,4.006c0.095,0.155,0.098,0.348,0.009,0.505C14.844,14.906,14.677,15,14.497,15H13v6c0,0.552-0.447,1-1,1s-1-0.448-1-1v-6
        H9.497c-0.183,0-0.351-0.096-0.438-0.256S8.977,14.388,9.076,14.234z M17.431,6.857c-0.275,0-0.544,0.027-0.809,0.065
        C15.505,5.168,13.547,4,11.312,4C8.54,4,6.191,5.795,5.351,8.285C2.909,8.548,1,10.593,1,13.105C1,15.796,3.182,18,5.873,18
        c0.227,0,2.019,0,4.127,0v-2H9.497c-0.549,0-1.053-0.297-1.315-0.775c-0.264-0.481-0.244-1.068,0.052-1.529l2.561-4.008
        C11.157,9.119,11.65,9,12,9h0.006l0.006,0c0.413,0.005,1.009,0.195,1.327,0.717l2.438,4.004c0.283,0.463,0.294,1.045,0.029,1.515
        C15.542,15.707,15.04,16,14.497,16H14v2c1.801,0,3.24,0,3.431,0C20.506,18,23,15.503,23,12.427S20.506,6.857,17.431,6.857z'
                />
            );
        case 'collections':
            return (
                <g {...props} id='collections'>
                    <path
                        className='opacity-5'
                        d='M9,2.01v1.21L7.71,3.83C6.67,4.32,6,5.39,6,6.54v9.62L4.44,16.9C4.3,16.97,4.15,17,4.01,17
              C3.48,17,3,16.57,3,15.99V4.54c0-0.77,0.44-1.48,1.14-1.81L7.56,1.1C7.7,1.03,7.85,1,7.99,1C8.52,1,9,1.43,9,2.01z M11.99,3
              c-0.14,0-0.29,0.03-0.43,0.1L8.14,4.73C7.44,5.06,7,5.77,7,6.54v11.45C7,18.57,7.48,19,8.01,19c0.14,0,0.29-0.03,0.43-0.1L10,18.16
              V8.54c0-1.15,0.67-2.22,1.71-2.71L13,5.22V4.01C13,3.43,12.52,3,11.99,3z M15.99,5c-0.14,0-0.29,0.03-0.43,0.1l-3.42,1.63
              C11.44,7.06,11,7.77,11,8.54v11.45c0,0.58,0.48,1.01,1.01,1.01c0.14,0,0.29-0.03,0.43-0.1L14,20.16v-9.62
              c0-1.15,0.67-2.22,1.71-2.71L17,7.22V6.01C17,5.43,16.52,5,15.99,5z'
                    />
                    <path
                        d='M21,8.01v11.45c0,0.77-0.44,1.48-1.14,1.81l-3.42,1.63c-0.14,0.07-0.29,0.1-0.43,0.1C15.48,23,15,22.57,15,21.99V10.54
              c0-0.77,0.44-1.48,1.14-1.81l3.42-1.63C19.7,7.03,19.85,7,19.99,7C20.52,7,21,7.43,21,8.01z'
                    />
                </g>
            );
        case 'communication':
            return (
                <g {...props} id='communication'>
                    <path
                        className='opacity-6'
                        d='M11.065,13.699C11.467,11.02,13.818,9,16.533,9h0.433c0.046-0.504,0.036-1.022-0.045-1.549
            C16.431,4.269,13.588,2,10.406,2H7.573C3.96,2,0.93,4.992,1.001,8.648c0.082,4.168,3.105,8.679,5.968,10.283
            c0.414,0.232,0.889-0.159,0.78-0.624c-0.109-0.466-0.166-0.955-0.166-1.331c0-0.75,0.033-1.396,0.417-1.976h0.804h1.6
            c0.206,0,0.41-0.015,0.612-0.035C10.987,14.549,11.002,14.125,11.065,13.699z'
                    />
                    <path
                        d='M18.481,10h-1.947c-2.188,0-4.142,1.602-4.479,3.848C11.63,16.678,13.698,19,16.347,19h1.1H18
              c0.264,0.409,0.474,1.042,0.474,1.571c0,0.265-0.039,0.611-0.114,0.939c-0.075,0.329,0.252,0.604,0.536,0.441
              c1.968-1.132,4.047-4.317,4.103-7.259C23.048,12.112,20.965,10,18.481,10z'
                    />
                </g>
            );
        case 'contact':
            return (
                <path
                    {...props}
                    d='M7,6c0-2.761,2.239-5,5-5s5,2.239,5,5s-2.239,5-5,5S7,8.761,7,6z M21.948,18.684C20.868,15.443,17.015,12,12,12
        s-8.869,3.443-9.949,6.684c-0.12,0.359-0.026,0.756,0.242,1.023C4.417,21.83,7.864,23,12,23c4.137,0,7.584-1.17,9.707-3.293
        C21.975,19.439,22.068,19.043,21.948,18.684z'
                />
            );
        case 'contact add':
            return (
                <path
                    {...props}
                    d='M23,5c0,0.55-0.45,1-1,1h-2v2c0,0.55-0.45,1-1,1s-1-0.45-1-1V6h-2c-0.55,0-1-0.45-1-1s0.45-1,1-1h2V2c0-0.55,0.45-1,1-1
        s1,0.45,1,1v2h2C22.55,4,23,4.45,23,5z M10,12c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4S6,5.791,6,8C6,10.209,7.791,12,10,12z
        M10,13c-3.508,0-6.851,2.391-7.949,5.684c-0.12,0.359-0.026,0.756,0.242,1.023C2.982,20.396,5.113,22,10,22
        s7.018-1.604,7.707-2.293c0.268-0.268,0.361-0.664,0.241-1.023C16.851,15.391,13.508,13,10,13z'
                />
            );
        case 'contact group':
            return (
                <path
                    {...props}
                    d='M14,7c0-1.657,1.343-3,3-3s3,1.343,3,3s-1.343,3-3,3S14,8.657,14,7z M22.914,14.844C21.796,12.329,19.751,11,17,11
        c-1.449,0-2.696,0.379-3.721,1.095c2.13,1.112,3.858,2.991,4.618,5.271c0,0.001,0,0.001,0,0.001
        c0.065,0.194,0.094,0.392,0.099,0.588c1.964-0.166,3.547-0.834,4.712-1.998C23,15.664,23.082,15.222,22.914,14.844z M9,11
        c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4S5,4.791,5,7C5,9.209,6.791,11,9,11z M9,12c-3.508,0-6.851,2.391-7.949,5.684
        c-0.12,0.359-0.026,0.756,0.242,1.023C1.982,19.396,4.113,21,9,21s7.018-1.604,7.707-2.293c0.268-0.268,0.361-0.664,0.241-1.023
        C15.851,14.391,12.508,12,9,12z'
                />
            );
        case 'copy':
            return (
                <g id='copy' {...props}>
                    <g>
                        <path d='M14,2H9v5c0,0.552-0.448,1-1,1H3v10c0,0.552,0.448,1,1,1h10c0.552,0,1-0.448,1-1V3C15,2.448,14.552,2,14,2z' />
                    </g>
                    <g className='opacity-5'>
                        <path
                            d='M21,6v15c0,0.552-0.448,1-1,1H10c-0.552,0-1-0.448-1-1v-1h5c1.103,0,2-0.897,2-2V5h4C20.552,5,21,5.448,21,6z M3,7h5V2
              L3,7z'
                        />
                    </g>
                </g>
            );
        case 'cut':
            return (
                <path
                    {...props}
                    d='M17,15c-0.591,0-1.148,0.137-1.654,0.368l-1.367-2.051l4.533-4.986c0.956-1.052,1.361-2.514,1.082-3.91l-0.604-3.02
        c-0.04-0.198-0.194-0.353-0.394-0.393c-0.197-0.041-0.401,0.045-0.513,0.213L12,10.349L5.916,1.223
        C5.804,1.054,5.602,0.969,5.403,1.009C5.205,1.049,5.049,1.204,5.01,1.402l-0.604,3.02c-0.279,1.395,0.126,2.856,1.083,3.91
        l4.533,4.986l-1.368,2.051C8.148,15.137,7.591,15,7,15c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4
        c0-0.68-0.186-1.311-0.487-1.872L12,15.493l1.486,1.635C13.186,17.689,13,18.32,13,19c0,2.206,1.794,4,4,4s4-1.794,4-4
        S19.206,15,17,15z M7,21c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S8.103,21,7,21z M12,14c-0.552,0-1-0.448-1-1
        c0-0.552,0.448-1,1-1s1,0.448,1,1C13,13.552,12.552,14,12,14z M17,21c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2
        S18.103,21,17,21z'
                />
            );
        case 'data usage':
            return (
                <g id='data_usage' {...props}>
                    <path
                        d='M11.553,12.894L11,12.618V12V1.05C5.39,1.555,1,6.258,1,12c0,0.22,0.01,0.45,0.02,0.67c0.01,0.15,0.02,0.3,0.04,0.46
              c0.05,0.55,0.15,1.09,0.29,1.62c0.07,0.3,0.16,0.59,0.27,0.88c0.06,0.18,0.12,0.36,0.2,0.53c0.06,0.16,0.13,0.32,0.2,0.47
              c0.07,0.14,0.13,0.27,0.2,0.41c0.09,0.17,0.18,0.34,0.28,0.51c0.09,0.14,0.18,0.29,0.27,0.43c0.09,0.15,0.18,0.29,0.29,0.42
              c0.3,0.42,0.63,0.81,0.98,1.18c0.24,0.25,0.48,0.48,0.74,0.71c1.81,1.57,4.12,2.55,6.67,2.68v0.02C11.63,23,11.82,23,12,23
              c3.952,0,7.412-2.08,9.348-5.208L11.553,12.894z'
                    />
                    <path className='opacity-5' d='M23,12c0,1.77-0.42,3.44-1.16,4.92L12,12V1C18.08,1,23,5.92,23,12z' />
                </g>
            );
        case 'delete':
            return (
                <path
                    {...props}
                    d='M20,4c0,0.552-0.448,1-1,1H5C4.448,5,4,4.552,4,4c0-0.552,0.448-1,1-1h5c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1h5
        C19.552,3,20,3.448,20,4z M5,6h14l-1,15.117C17.938,22.175,17.059,23,16,23H8c-1.059,0-1.938-0.825-2-1.883L5,6z M14.5,16.5
        c0,0.275,0.225,0.5,0.5,0.5s0.5-0.225,0.5-0.5v-7C15.5,9.225,15.275,9,15,9s-0.5,0.225-0.5,0.5V16.5z M11.5,16.5
        c0,0.275,0.225,0.5,0.5,0.5s0.5-0.225,0.5-0.5v-7C12.5,9.225,12.275,9,12,9s-0.5,0.225-0.5,0.5V16.5z M8.5,16.5
        C8.5,16.775,8.725,17,9,17s0.5-0.225,0.5-0.5v-7C9.5,9.225,9.275,9,9,9S8.5,9.225,8.5,9.5V16.5z'
                />
            );
        case 'dislike':
            return (
                <g id='dislike' {...props}>
                    <path
                        className='opacity-5'
                        d='M19,1h3c0.552,0,1,0.448,1,1v12c0,0.552-0.448,1-1,1h-3c-0.552,0-1-0.448-1-1V2
            C18,1.448,18.448,1,19,1z'
                    />
                    <path
                        d='M16,2h-4.707H8H5.771C4.893,2,4.117,2.573,3.859,3.412l-2.769,9C0.694,13.698,1.656,15,3.001,15H7h0.41
            c0.72,0,1.17,0.76,0.82,1.39l-0.15,0.27c-0.54,0.99-0.85,2.1-0.8,3.22c0.04,0.75,0.18,1.49,0.42,2.21C7.88,22.63,8.39,23,8.96,23
            c0.61,0,1.14-0.42,1.29-1.01l0.36-1.44c0.26-1.02,0.73-1.97,1.39-2.79l2.29-2.88c0.45-0.55,1.13-0.88,1.84-0.88
            c0.48,0,0.87-0.39,0.87-0.87V3C17,2.45,16.55,2,16,2z'
                    />
                </g>
            );
        case 'document':
            return (
                <g {...props} id='document'>
                    <path d='M19,3v18c0,0.55-0.45,1-1,1H6c-0.55,0-1-0.45-1-1V10h7c0.55,0,1-0.45,1-1V2h5C18.55,2,19,2.45,19,3z' />
                    <polygon className='opacity-5' points='12,2 5,9 12,9 	' />
                </g>
            );
        case 'done inline':
            return (
                <path
                    {...props}
                    d='M12,1C5.925,1,1,5.925,1,12s4.925,11,11,11c6.075,0,11-4.925,11-11S18.075,1,12,1z M17.707,9.707l-7,7
        C10.512,16.902,10.256,17,10,17s-0.512-0.098-0.707-0.293l-3-3c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L10,14.586
        l6.293-6.293c0.391-0.391,1.023-0.391,1.414,0S18.098,9.316,17.707,9.707z'
                />
            );
        case 'download':
            return (
                <path
                    {...props}
                    d='M21,16v5c0,0.553-0.447,1-1,1H4c-0.552,0-1-0.447-1-1v-5c0-0.553,0.448-1,1-1s1,0.447,1,1v4h14v-4c0-0.553,0.447-1,1-1
          S21,15.447,21,16z M11.58,16.771C11.671,16.913,11.83,17,12,17s0.329-0.087,0.42-0.229l4.5-7c0.099-0.154,0.105-0.35,0.018-0.51
          C16.852,9.1,16.683,9,16.5,9H13V3c0-0.552-0.448-1-1-1s-1,0.448-1,1v6H7.5C7.317,9,7.149,9.1,7.061,9.26
          C6.974,9.421,6.98,9.617,7.08,9.771L11.58,16.771z'
                />
            );
        case 'draw':
            return (
                <g id='draw'>
                    <path className='opacity-5' d='M19,9L8,20l-4-4L15,5L19,9z' />
                    <path
                        d='M20,8l-4-4l2-2c1.089-1.089,2.926-1.074,4,0s1.089,2.911,0,4L20,8z M21.5,22h-17L7,21l-4-4l-1.948,4.97
            c-0.199,0.517,0.201,1.021,0.699,1.024L1.75,23H21.5c0.275,0,0.5-0.225,0.5-0.5C22,22.225,21.775,22,21.5,22z'
                    />
                </g>
            );
        case 'edit':
            return (
                <g id='edit' {...props}>
                    <path className='opacity-5' d='M19,9L8,20l-4-4L15,5L19,9z' />
                    <path
                        d='M20,8l-4-4l2-2c1.089-1.089,2.926-1.074,4,0s1.089,2.911,0,4L20,8z M7,21l-4-4l-1.952,5.059
            c-0.215,0.559,0.334,1.108,0.893,0.893L7,21z'
                    />
                </g>
            );
        case 'email':
            return (
                <path
                    {...props}
                    d='M2.302,4.531C2.785,4.198,3.369,4,4,4h16c0.631,0,1.215,0.198,1.698,0.531l-7.446,7.123c0,0,0,0,0,0l0,0l-1.215,1.162
        c-0.581,0.557-1.493,0.557-2.074,0L2.302,4.531z M23,7c0-0.663-0.223-1.27-0.588-1.767L15.337,12l7.075,6.767
        C22.777,18.27,23,17.663,23,17V7z M14.614,12.692l-0.885,0.847c-0.484,0.463-1.107,0.695-1.729,0.695
        c-0.622,0-1.244-0.232-1.728-0.695l-0.886-0.847l-7.085,6.777C2.785,19.802,3.369,20,4,20h16c0.631,0,1.215-0.198,1.698-0.531
        L14.614,12.692z M1.588,5.233C1.223,5.73,1,6.337,1,7v10c0,0.663,0.223,1.27,0.588,1.767L8.663,12L1.588,5.233z'
                />
            );
        case 'expand less':
            return (
                <path
                    {...props}
                    d='M20,2H4C2.895,2,2,2.895,2,4v16c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2V4C22,2.895,21.105,2,20,2z M17,13H7
        c-0.552,0-1-0.448-1-1s0.448-1,1-1h10c0.553,0,1,0.448,1,1S17.553,13,17,13z'
                />
            );
        case 'expand more':
            return (
                <path
                    {...props}
                    d='M20,2H4C2.895,2,2,2.895,2,4v16c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2V4C22,2.895,21.105,2,20,2z M17,13h-4v4
        c0,0.553-0.448,1-1,1s-1-0.447-1-1v-4H7c-0.552,0-1-0.448-1-1s0.448-1,1-1h4V7c0-0.552,0.448-1,1-1s1,0.448,1,1v4h4
        c0.553,0,1,0.448,1,1S17.553,13,17,13z'
                />
            );
        case 'external link':
            return (
                <path
                    {...props}
                    d='M22.854,1.146c-0.121-0.121-0.294-0.169-0.462-0.135l-9,2c-0.178,0.04-0.32,0.173-0.372,0.349
        c-0.051,0.175-0.002,0.364,0.127,0.493l2.793,2.793L1.293,21.293c-0.391,0.391-0.391,1.023,0,1.414C1.488,22.902,1.744,23,2,23
        s0.512-0.098,0.707-0.293L17.354,8.061l2.793,2.793C20.241,10.949,20.369,11,20.5,11c0.047,0,0.094-0.006,0.14-0.02
        c0.176-0.051,0.309-0.193,0.349-0.372l2-9C23.025,1.441,22.975,1.267,22.854,1.146z'
                />
            );
        case 'favorite':
            return (
                <path
                    {...props}
                    d='M17,4c2.206,0,4,1.794,4,4c0,0.137-0.01,0.288-0.03,0.462l-0.005,0.046l-0.003,0.046C20.618,13.545,15.576,17.94,12,19.873
        C8.425,17.94,3.382,13.545,3.038,8.554L3.035,8.508L3.03,8.462C3.01,8.288,3,8.137,3,8c0-2.206,1.794-4,4-4
        c1.34,0,2.587,0.672,3.334,1.797L12,8.306l1.666-2.509C14.413,4.672,15.66,4,17,4 M17,2c-2.086,0-3.924,1.071-5,2.691
        C10.924,3.071,9.086,2,7,2C3.692,2,1,4.692,1,8c0,0.234,0.017,0.464,0.043,0.691c0.446,6.477,6.985,11.441,10.51,13.203
        C11.694,21.965,11.847,22,12,22c0.153,0,0.306-0.035,0.447-0.106c3.525-1.762,10.064-6.726,10.51-13.203
        C22.983,8.464,23,8.234,23,8C23,4.692,20.308,2,17,2L17,2z'
                />
            );
        case 'favorite on':
            return (
                <path
                    {...props}
                    d='M23,8c0-3.308-2.692-6-6-6c-2.086,0-3.924,1.071-5,2.691C10.924,3.071,9.086,2,7,2C3.692,2,1,4.692,1,8
        c0,0.234,0.017,0.464,0.043,0.691c0.446,6.477,6.985,11.441,10.51,13.203C11.694,21.965,11.847,22,12,22
        c0.153,0,0.306-0.035,0.447-0.106c3.525-1.762,10.064-6.726,10.51-13.203C22.983,8.464,23,8.234,23,8z'
                />
            );
        case 'filter':
            return (
                <path
                    {...props}
                    d='M12,1C7.527,1,3,2.374,3,5v2c0,0.265,0.105,0.52,0.293,0.707L10,14.414V22c0,0.347,0.179,0.668,0.474,0.851
        C10.635,22.95,10.817,23,11,23c0.153,0,0.306-0.035,0.447-0.106l2-1C13.786,21.725,14,21.379,14,21v-6.586l6.707-6.707
        C20.895,7.52,21,7.265,21,7V5C21,2.374,16.473,1,12,1z M12,7C7.45,7,5,5.58,5,5c0-0.368,0.989-1.073,2.872-1.54
        c0.021-0.005,0.044-0.01,0.065-0.015c0.18-0.044,0.369-0.085,0.565-0.124c0.063-0.012,0.128-0.024,0.193-0.036
        c0.163-0.03,0.33-0.058,0.503-0.084c0.084-0.013,0.169-0.025,0.255-0.036c0.166-0.022,0.337-0.042,0.512-0.06
        c0.095-0.01,0.188-0.02,0.285-0.029c0.186-0.016,0.379-0.029,0.575-0.04c0.092-0.005,0.181-0.012,0.275-0.016
        C11.39,3.008,11.689,3,12,3s0.61,0.008,0.901,0.02c0.094,0.004,0.183,0.011,0.275,0.016c0.196,0.011,0.388,0.023,0.575,0.04
        c0.097,0.009,0.19,0.019,0.285,0.029c0.175,0.018,0.346,0.038,0.512,0.06c0.086,0.012,0.171,0.024,0.255,0.036
        c0.173,0.026,0.34,0.054,0.502,0.084c0.065,0.012,0.13,0.023,0.194,0.036c0.196,0.039,0.384,0.08,0.565,0.124
        c0.021,0.005,0.044,0.01,0.065,0.015C18.011,3.927,19,4.632,19,5C19,5.58,16.55,7,12,7z'
                />
            );
        case 'forward':
            return (
                <path
                    {...props}
                    d='M7,22c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L14.586,12L6.293,3.707
		c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l9,9c0.391,0.391,0.391,1.023,0,1.414l-9,9C7.512,21.902,7.256,22,7,22z'
                />
            );
        case 'like':
            return (
                <g {...props}>
                    <path
                        className='opacity-5'
                        d='M5,23H2c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h3c0.552,0,1,0.448,1,1v12
            C6,22.552,5.552,23,5,23z'
                    />
                    <path
                        d='M20.999,9H17h-0.41c-0.72,0-1.17-0.76-0.82-1.39l0.15-0.27c0.54-0.99,0.85-2.1,0.8-3.22c-0.04-0.75-0.18-1.49-0.42-2.21
            C16.12,1.37,15.61,1,15.04,1c-0.61,0-1.14,0.42-1.29,1.01l-0.36,1.44C13.13,4.47,12.66,5.42,12,6.24L9.71,9.12
            C9.26,9.67,8.58,10,7.87,10C7.39,10,7,10.39,7,10.87V21c0,0.55,0.45,1,1,1h4.707H16h2.229c0.878,0,1.653-0.573,1.912-1.412l2.769-9
            C23.306,10.302,22.344,9,20.999,9z'
                    />
                </g>
            );
        case 'list_bullet':
            return (
                <g {...props}>
                    <path
                        d='M5,4c0-0.552,0.448-1,1-1h15c0.553,0,1,0.448,1,1s-0.447,1-1,1H6C5.448,5,5,4.552,5,4z M6,9h13c0.553,0,1-0.448,1-1
		s-0.447-1-1-1H6C5.448,7,5,7.448,5,8S5.448,9,6,9z M21,11H6c-0.552,0-1,0.448-1,1s0.448,1,1,1h15c0.553,0,1-0.448,1-1
		S21.553,11,21,11z M6,17h12c0.553,0,1-0.447,1-1s-0.447-1-1-1H6c-0.552,0-1,0.447-1,1S5.448,17,6,17z M21,19H6
		c-0.552,0-1,0.447-1,1s0.448,1,1,1h15c0.553,0,1-0.447,1-1S21.553,19,21,19z M3,3C2.448,3,2,3.448,2,4c0,0.552,0.448,1,1,1
		s1-0.448,1-1C4,3.448,3.552,3,3,3z M3,7C2.448,7,2,7.448,2,8c0,0.552,0.448,1,1,1s1-0.448,1-1C4,7.448,3.552,7,3,7z M3,11
		c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1s1-0.448,1-1C4,11.448,3.552,11,3,11z M3,15c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1
		s1-0.448,1-1C4,15.448,3.552,15,3,15z M3,19c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1s1-0.448,1-1C4,19.448,3.552,19,3,19z'
                    />
                </g>
            );
        case 'note':
            return (
                <g {...props}>
                    <path
                        d='M17,2H5C3.895,2,3,2.895,3,4v16c0,1.105,0.895,2,2,2h8v-5c0-0.552,0.448-1,1-1h5V4C19,2.895,18.105,2,17,2z M6.5,5h5
            C11.776,5,12,5.224,12,5.5S11.776,6,11.5,6h-5C6.224,6,6,5.776,6,5.5S6.224,5,6.5,5z M11.5,12h-5C6.224,12,6,11.776,6,11.5
            S6.224,11,6.5,11h5c0.276,0,0.5,0.224,0.5,0.5S11.776,12,11.5,12z M13.5,9h-7C6.224,9,6,8.776,6,8.5S6.224,8,6.5,8h7
            C13.776,8,14,8.224,14,8.5S13.776,9,13.5,9z'
                    />
                    <path className='opacity-5' d='M19,17l-5,5v-5H19' />
                </g>
            );
        case 'note add':
            return (
                <g {...props}>
                    <path className='opacity-5' d='M19,17l-5,5v-5H19' />
                    <path
                        d='M23,5c0,0.552-0.447,1-1,1h-2v2c0,0.552-0.447,1-1,1s-1-0.448-1-1V6h-2c-0.553,0-1-0.448-1-1s0.447-1,1-1h2V2.277V2
            c0-0.552,0.447-1,1-1s1,0.448,1,1v2h2C22.553,4,23,4.448,23,5z M19,10v6h-5c-0.552,0-1,0.448-1,1v5H5c-1.105,0-2-0.895-2-2V4
            c0-1.105,0.895-2,2-2h12v1h-1c-1.103,0-2,0.897-2,2c0,1.103,0.897,2,2,2h1v1C17,9.103,17.897,10,19,10z M6,5.5
            C6,5.776,6.224,6,6.5,6h5C11.776,6,12,5.776,12,5.5S11.776,5,11.5,5h-5C6.224,5,6,5.224,6,5.5z M12,11.5c0-0.276-0.224-0.5-0.5-0.5
            h-5C6.224,11,6,11.224,6,11.5S6.224,12,6.5,12h5C11.776,12,12,11.776,12,11.5z M14,8.5C14,8.224,13.776,8,13.5,8h-7
            C6.224,8,6,8.224,6,8.5S6.224,9,6.5,9h7C13.776,9,14,8.776,14,8.5z'
                    />
                </g>
            );
        case 'notifications':
            return (
                <g {...props}>
                    <path
                        className='opacity-5'
                        d='M12,2.833c0.626,0,1.23,0.087,1.81,0.234c0.01-0.078,0.024-0.154,0.024-0.234
            C13.833,1.821,13.013,1,12,1s-1.833,0.821-1.833,1.833c0,0.08,0.014,0.157,0.024,0.234C10.77,2.92,11.374,2.833,12,2.833z M12,23
            c1.657,0,3-0.895,3-2H9C9,22.105,10.343,23,12,23z'
                    />
                    <path
                        d='M19,16l0-4.706c0-3.833-2.953-7.175-6.785-7.29C8.251,3.884,5,7.062,5,11v5c0,0.552-0.448,1-1,1h0c-0.552,0-1,0.448-1,1v1
            c0,0.552,0.448,1,1,1h16c0.552,0,1-0.448,1-1v-1c0-0.552-0.448-1-1-1h0C19.448,17,19,16.552,19,16z'
                    />
                </g>
            );
        case 'search':
            return (
                <g {...props}>
                    <circle className='opacity-5' cx='10' cy='10' r='6' />
                    <path
                        d='M22.707,21.293l-5.682-5.682C18.258,14.071,19,12.122,19,10c0-4.962-4.037-9-9-9c-4.962,0-9,4.038-9,9c0,4.963,4.038,9,9,9
            c2.122,0,4.071-0.742,5.611-1.975l5.682,5.682C21.488,22.902,21.744,23,22,23s0.512-0.098,0.707-0.293
            C23.098,22.316,23.098,21.684,22.707,21.293z M10,17c-3.86,0-7-3.141-7-7c0-3.86,3.14-7,7-7c3.859,0,7,3.14,7,7
            C17,13.859,13.859,17,10,17z'
                    />
                </g>
            );
        case 'share':
            return (
                <path
                    {...props}
                    d='M19,15c-1.202,0-2.268,0.542-3.001,1.381l-7.089-3.545C8.967,12.566,9,12.287,9,12s-0.033-0.566-0.091-0.836l7.089-3.545
		C16.732,8.458,17.798,9,19,9c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4s-4,1.791-4,4c0,0.287,0.033,0.566,0.091,0.836L8.001,9.381
		C7.268,8.542,6.202,8,5,8c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4c1.202,0,2.268-0.542,3.001-1.381l7.089,3.545
		C15.033,18.434,15,18.713,15,19c0,2.209,1.791,4,4,4s4-1.791,4-4C23,16.791,21.209,15,19,15z'
                />
            );
        case 'star half':
            return (
                <path
                    {...props}
                    d='M22.143,8.267l-1.569-0.228v0l-4.82-0.7l-2.018-4.084c0,0,0,0,0,0l-0.839-1.698C12.713,1.185,12.357,1,12,1
          s-0.713,0.186-0.896,0.557l-0.839,1.699c0,0,0,0,0,0L8.246,7.339L2.678,8.148c0,0,0,0,0,0L1.857,8.267
          c-0.82,0.119-1.148,1.128-0.554,1.706l0.594,0.579l4.028,3.923l-1.022,5.952c0,0,0,0,0,0l-0.069,0.402
          C4.724,21.475,5.238,22,5.82,22c0.154,0,0.312-0.036,0.465-0.117l0.645-0.339L12,18.882l4.59,2.41l1.125,0.591
          C17.868,21.963,18.026,22,18.18,22c0.583,0,1.096-0.525,0.985-1.171l-0.234-1.364l-0.857-4.991l3.827-3.727l0.796-0.775
          C23.291,9.394,22.963,8.386,22.143,8.267z M16.679,13.041l-0.754,0.734l0.178,1.037l0.749,4.358l-3.921-2.059L12,16.624V4.258l0,0
          l1.96,3.968l0.466,0.942l1.04,0.151l4.382,0.636L16.679,13.041z'
                />
            );
        case 'star on':
            return (
                <path
                    {...props}
                    d='M22.143,8.267l-1.569-0.228v0l-4.82-0.7l-2.018-4.084c0,0,0,0,0,0l-0.839-1.699c-0.367-0.743-1.426-0.743-1.793,0
          l-0.839,1.699c0,0,0,0,0,0L8.246,7.34L2.678,8.148c0,0,0,0,0,0L1.857,8.267c-0.82,0.119-1.148,1.127-0.554,1.706l0.594,0.579l0,0
          l4.028,3.923l-1.022,5.952c0,0,0,0,0,0l-0.069,0.402c-0.14,0.817,0.717,1.44,1.45,1.054l0.645-0.338l0,0L12,18.882l4.59,2.41l0,0
          l1.125,0.59c0.734,0.385,1.591-0.238,1.45-1.055l-0.234-1.364l0,0l-0.857-4.991l3.827-3.727l0,0l0.796-0.775
          C23.291,9.394,22.963,8.386,22.143,8.267z'
                />
            );
        case 'star off':
            return (
                <path
                    {...props}
                    d='M12,4.257l1.96,3.968l0.466,0.942l1.04,0.151l4.382,0.636l-3.17,3.086l-0.754,0.734l0.178,1.037l0.749,4.358l-3.921-2.059
          L12,16.624l-0.93,0.488l-3.921,2.059l0.748-4.358l0.178-1.037l-0.754-0.734L4.152,9.955l4.382-0.636l1.04-0.151l0.466-0.942
          L12,4.257 M12,1c-0.357,0-0.713,0.186-0.896,0.557l-0.839,1.699c0,0,0,0,0,0L8.246,7.34L2.678,8.148c0,0,0,0,0,0L1.857,8.267
          c-0.82,0.119-1.148,1.127-0.554,1.706l0.594,0.579l4.028,3.923l-1.022,5.952c0,0,0,0,0,0l-0.069,0.402
          C4.724,21.475,5.238,22,5.82,22c0.154,0,0.312-0.036,0.465-0.117l0.645-0.338L12,18.882l4.59,2.41l1.125,0.59
          C17.868,21.963,18.026,22,18.18,22c0.583,0,1.096-0.525,0.985-1.171l-0.234-1.364l-0.857-4.991l3.827-3.727l0.796-0.775
          c0.594-0.578,0.267-1.587-0.554-1.706l-1.569-0.228v0l-4.82-0.7l-2.018-4.084c0,0,0,0,0,0l-0.839-1.699C12.713,1.185,12.357,1,12,1
          L12,1z'
                />
            );

        default:
            return <path />;
    }
};

interface AwsCatIconProps {
    name: string;
    fill: string;
    viewBox?: string;
    width?: string;
    className?: string;
    height?: string;
}

const AwsCatIcon: FunctionComponent<AwsCatIconProps> = ({
    name = '',
    fill = '#000',
    viewBox = '',
    width = '100%',
    className = '',
    height = '100%',
}): JSX.Element => (
    <svg
        width={width}
        height={height}
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox || getViewBox()}
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        {getPath(name, { name, fill })}
    </svg>
);

export default AwsCatIcon;
