import './AssessmentReportResults.scss';

import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Box, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';

import { AssessmentQuestionBox } from './AssessmentQuestionBox';
import SummaryObservations from './SummaryObservations';
import RecommendationsTable from './new-recommended-actions/RecommendationsTable';
import RecommendedActionsComponent from './recommended-actions/RecommendedActions';
import { QuestionCounter } from './report-filters/QuestionCounter';
import { AppLabels } from '../../../../../common/AppLabels';
import { TextMarker } from '../../../../../common/TextMarker';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { useAppSelector } from '../../../../redux/hooks';
import { AssessmentFacet, AssessmentQuestionVector } from '../../model/Assessment';

interface AssessmentFacetQuestionVectorProps {
    appLabels: AppLabels;
    assessmentId: string;
    questionVector: AssessmentQuestionVector;
    questionCounter: QuestionCounter;
    textMarker: TextMarker;
    scoreScale: ScoreScale; // for rating facets and rating vectors
    questionScoreScale: ScoreScale; // for ratings
}

const AssessmentFacetQuestionVector = (props: AssessmentFacetQuestionVectorProps): JSX.Element | null => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const rsIsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_RS_ENABLED);
    const rsIsLoading = useAppSelector((state) => state.recommendationsState.loading);
    const refIdToRecommendationsMap = useAppSelector((state) => state.recommendationsState.refIdToRecommendationsMap);

    const appLabels: AppLabels = props.appLabels;
    const questionVector = props.questionVector;
    const questionCounter = props.questionCounter;
    const textMarker = props.textMarker;
    const scoreScale = props.scoreScale;
    const questionScoreScale = props.questionScoreScale;
    if (!questionVector.display) {
        questionVector.questions?.forEach(() => questionCounter.addHiddenQuestion());
        return null;
    }
    const vectorBackgroundColor = scoreScale.getStratificationBackgroundColor(questionVector);
    const vectorFontColor = scoreScale.getStratificationFontColor(questionVector);

    const defaultPrescribedActionsFromAWS =
        refIdToRecommendationsMap[questionVector.categoryId]?.map((recommendation) => ({ text: recommendation.verbiage })) || [];
    // If no customized prescribed actions, use the AWS default prescribed actions
    const prescribedActions = questionVector.prescribedActions?.length > 0 ? questionVector.prescribedActions : defaultPrescribedActionsFromAWS;
    return (
        <Box className='question-vector'>
            <div className='question-vector-name' style={{ backgroundColor: vectorBackgroundColor }}>
                <SpaceBetween size='xxs' direction='vertical'>
                    <div className='question-vector-title' style={{ color: vectorFontColor }}>
                        {textMarker.markText(questionVector?.name)}
                    </div>
                    {questionVector.phase ? (
                        <div className={'question-vector-phase'} style={{ color: vectorFontColor }}>
                            {questionVector.phase}
                        </div>
                    ) : null}
                </SpaceBetween>
            </div>
            <Box fontSize='body-m' fontWeight='normal' className='question-vector-questions'>
                {questionVector?.questions?.map((question, index, questions) => {
                    return AssessmentQuestionBox(appLabels, question, index, questions, questionCounter, textMarker, questionScoreScale);
                })}
            </Box>
            <Box className='question-vector-summary-observations'>
                {<SummaryObservations questionVector={questionVector} textMarker={textMarker} />}
            </Box>
            {rsIsEnabled ? (
                <RecommendationsTable
                    assessmentId={props.assessmentId}
                    categoryId={questionVector.categoryId}
                    recommendations={refIdToRecommendationsMap[questionVector.categoryId]}
                    loading={rsIsLoading}
                ></RecommendationsTable>
            ) : (
                <Box className='question-vector-recommended-actions'>
                    <RecommendedActionsComponent categoryId={questionVector.categoryId} prescribedActions={prescribedActions} />
                </Box>
            )}
        </Box>
    );
};

const AssessmentFacetSection = (
    appLabels: AppLabels,
    assessmentId: string,
    assessmentFacet: AssessmentFacet,
    questionCounter: QuestionCounter,
    textMarker: TextMarker,
    scoreScale: ScoreScale,
    questionScoreScale: ScoreScale
): JSX.Element | null => {
    if (!assessmentFacet.display) {
        assessmentFacet.questionVectors?.forEach((questionVector) => {
            questionVector?.questions?.forEach(() => questionCounter.addHiddenQuestion());
        });
        return null;
    }

    const CountedAssessmentFacetQuestionVector = (questionVector: AssessmentQuestionVector): JSX.Element | null => {
        return (
            <AssessmentFacetQuestionVector
                appLabels={appLabels}
                assessmentId={assessmentId}
                questionVector={questionVector}
                questionCounter={questionCounter}
                textMarker={textMarker}
                scoreScale={scoreScale}
                questionScoreScale={questionScoreScale}
            />
        );
    };
    const assessmentFacetQuestionVectors = assessmentFacet.questionVectors?.map(CountedAssessmentFacetQuestionVector);
    return (
        <ExpandableSection
            defaultExpanded={true}
            variant='container'
            header={
                <Box fontSize='heading-m' fontWeight='bold' className='assessment-facet-name'>
                    {textMarker.markText(assessmentFacet.name)}
                </Box>
            }
            className='assessment-facet-section'
        >
            <SpaceBetween size='l' direction='vertical'>
                {assessmentFacetQuestionVectors}
            </SpaceBetween>
        </ExpandableSection>
    );
};

export const AssessmentFacetSections = (
    appLabels: AppLabels,
    assessmentId: string,
    assessmentFacets: AssessmentFacet[],
    displayedQuestionCounter: QuestionCounter,
    textMarker: TextMarker,
    scoreScale: ScoreScale,
    questionScoreScale: ScoreScale
): JSX.Element[] => {
    const assessmentFacetSections = [];
    for (const assessmentFacet of assessmentFacets) {
        if (assessmentFacet) {
            const facetSection = AssessmentFacetSection(
                appLabels,
                assessmentId,
                assessmentFacet,
                displayedQuestionCounter,
                textMarker,
                scoreScale,
                questionScoreScale
            );
            if (facetSection) {
                assessmentFacetSections.push(facetSection);
            }
        }
    }
    return assessmentFacetSections;
};
