import './AssessmentPromptDate.scss';

import { ResponseType } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentResponseDate, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { DatePicker, FormField } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useState } from 'react';

import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import rumClient from '../../../../../common/monitoring/RumClient';
import { TemplatePromptViewModel } from '../../../../administration/manage-templates/edit-template/TemplateModels';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';

interface AssessmentPromptDateState {
    id: string | null;
    value: string | null;
    responseSaved: boolean;
}

const initialAssessmentPromptDateState: AssessmentPromptDateState = {
    id: null,
    value: '',
    responseSaved: false,
};

type AssessmentPromptDateProps = AppLabelsContextInterface &
    AuthContextInterface & {
        shouldDisplayTemplate: boolean;
    };

const AssessmentPromptDate: FunctionComponent<AssessmentPromptDateProps> = ({ auth, appLabels, shouldDisplayTemplate }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const responseType = currentPrompt?.responseType ?? null;
    const response = currentPrompt?.response ?? null;

    // Template state
    const currentTemplatePrompt: TemplatePromptViewModel = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const templateResponseType: ResponseType = currentTemplatePrompt?.responseType;

    const isReadOnly = shouldDisplayTemplate ? true : assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse }));
                setDateState({ ...dateState, responseSaved: true });
            }
        },
    });

    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);

    const [dateState, setDateState] = useState<AssessmentPromptDateState>(initialAssessmentPromptDateState);

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_response}
                updateConfirmationText={
                    !loading && !error && dateState.responseSaved
                        ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.response_saved}`
                        : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                // TODO: Add the logic for try again action: SIM: https://issues.amazon.com/issues/A2T-OE-49
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_response,
        appLabels.assessment.facilitate.response_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        dateState.responseSaved,
        promptIndexBeingUpdated,
    ]);

    const getResponseValue = useCallback(() => {
        switch (responseType) {
            case AssessmentResponseTypes.DATE_VALUE:
                return (response as AssessmentResponseDate)?.dateValue;
            default:
                return null;
        }
    }, [response, responseType]);

    const initialResponseValue = getResponseValue();

    const updateValue = useCallback(() => {
        if (isReadOnly) {
            return;
        }
        dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
        const dateValue = dateState.value || null; // set to null to remove for all falsy values. e.g. ''
        if (dateValue !== initialResponseValue) {
            createOrUpdateAssessmentResponses({
                variables: {
                    input: {
                        assessmentId: assessmentId,
                        responses: [
                            {
                                promptId,
                                dateValue,
                            },
                        ],
                    },
                },
            });
        }
    }, [isReadOnly, dispatch, promptId, promptIndex, dateState.value, initialResponseValue, createOrUpdateAssessmentResponses, assessmentId]);

    if (assessmentIdPromptId && dateState.id !== assessmentIdPromptId) {
        // Prompt or assessment has changed, update state
        setDateState({
            ...initialAssessmentPromptDateState,
            id: assessmentIdPromptId,
            value: initialResponseValue ? `${initialResponseValue}` : '',
        });
        return null;
    }

    if (shouldDisplayTemplate ? templateResponseType === ResponseType.DateValue : responseType === AssessmentResponseTypes.DATE_VALUE) {
        return (
            <div className='awscat-assessment-date-input'>
                <FormField constraintText={appLabels.common.date_format_constraint_text}>
                    <DatePicker
                        disabled={isReadOnly}
                        value={dateState.value}
                        onChange={({ detail }) => setDateState({ ...dateState, value: detail.value })}
                        openCalendarAriaLabel={(selectedDate) =>
                            appLabels.common.choose_date + (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                        }
                        nextMonthAriaLabel={appLabels.common.next_month}
                        placeholder={appLabels.common.date_placeholder}
                        previousMonthAriaLabel={appLabels.assessment.pre_event.previous_month}
                        todayAriaLabel={appLabels.assessment.pre_event.today}
                        onBlur={updateValue}
                        data-testid='date-input'
                    />
                </FormField>
                {updateStatusIndicator()}
            </div>
        );
    }

    return null;
};

export default withAuthContext(withAppLabelsContext(withFlashContext(AssessmentPromptDate)));
