import React from 'react';

import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';

export interface LocalizationContextInterface {
    selectedLanguage: string;
    allLanguages: string[];
    locale: string;
    setLanguage: (lang: string) => void;
}
export const LocalizationContext = React.createContext<LocalizationContextInterface>({
    selectedLanguage: Constants.DEFAULT_LANGUAGE,
    allLanguages: [],
    locale: Constants.DEFAULT_LOCALE,
    setLanguage: (lang: string) => {
        rumClient.recordError(`updateLanguage called with ${lang}; no setLanguage function set!`);
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withLocalizationContext = (Component: any) => {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (props: any): JSX.Element => {
        return (
            <LocalizationContext.Consumer>
                {(ctx) => (
                    <Component
                        {...props}
                        selectedLanguage={ctx.selectedLanguage}
                        allLanguages={ctx.allLanguages}
                        locale={ctx.locale}
                        setLanguage={ctx.setLanguage}
                    />
                )}
            </LocalizationContext.Consumer>
        );
    };
};
