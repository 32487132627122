import { UpdateAssessmentInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, BoxProps, SpaceBetween } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router';

import { AssessmentViewModel } from './AssessmentViewModel';
import { updateFavoriteSuccess } from './list/AssessmentsSlice';
import a2sClient from '../../api/a2s/A2SClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import AssessmentPermissionManager from '../../common/auth/AssessmentPermissionManager';
import rumClient from '../../common/monitoring/RumClient';
import AwsCatIcon from '../common/Icons/AwsCatIcon';
import { useAppDispatch } from '../redux/hooks';

export enum ActionType {
    ASSESSMENTS_LOADED,
    RELOAD_ASSESSMENTS,
    SHARE_ASSESSMENT,
    DELETE_ASSESSMENT,
    CANCEL,
}

// This is a Discriminated Union.  Inspiration: https://dev.to/torbenrahbekkoch/comment/n3oj
export type Action =
    | {
          type: ActionType.ASSESSMENTS_LOADED;
      }
    | {
          type: ActionType.RELOAD_ASSESSMENTS;
      }
    | {
          type: ActionType.SHARE_ASSESSMENT;
          assessment: AssessmentViewModel;
      }
    | {
          type: ActionType.DELETE_ASSESSMENT;
          assessment: AssessmentViewModel;
      }
    | {
          type: ActionType.CANCEL;
      };

type AssessmentActionsProps = AuthContextInterface &
    AppLabelsContextInterface & {
        assessment: AssessmentViewModel;
        assessmentActionDispatch: React.Dispatch<Action>;
        actionsFloat?: BoxProps.Float;
    };

const AssessmentActions = ({ auth, appLabels, assessment, assessmentActionDispatch, actionsFloat }: AssessmentActionsProps): JSX.Element => {
    const history = useHistory();
    const myUserId = auth.getUserInfo().userId;
    const assessmentPermissionManager = new AssessmentPermissionManager(myUserId, assessment.assessmentPermissions);
    const dispatch = useAppDispatch();

    const onEditClick = () => {
        history.push(`/assessments/${assessment.id}/update`);
    };

    const onCloseClick = () => {
        assessmentActionDispatch({
            type: ActionType.DELETE_ASSESSMENT,
            assessment: assessment,
        });
    };

    const onShareClick = () => {
        assessmentActionDispatch({
            type: ActionType.SHARE_ASSESSMENT,
            assessment: assessment,
        });
    };

    const onFavoritesClick = async () => {
        try {
            const updateAssessmentInput: UpdateAssessmentInput = {
                isfavorite: !assessment?.isfavorite,
            };
            const apiResult = await a2sClient?.updateAssessment(assessment.id, updateAssessmentInput);
            if (apiResult?.errors) {
                throw new Error(apiResult.errors.toString());
            } else {
                const updatedIsfavorite = apiResult?.data?.updateAssessment?.isfavorite;
                dispatch(updateFavoriteSuccess({ assessmentId: assessment.id, isfavorite: updatedIsfavorite }));
            }
        } catch (e: any) {
            rumClient.recordError(e);
        }
    };

    const displayEditButton = (): JSX.Element | undefined => {
        return assessmentPermissionManager.hasUpdatePemission ? (
            <div onClick={onEditClick} className='icon-button' title={appLabels.user_actions.edit_settings}>
                <AwsCatIcon name='edit' width='20' height='20' fill='#000' />
            </div>
        ) : undefined;
    };

    const displayShareButton = (): JSX.Element | undefined => {
        return assessmentPermissionManager.hasFullPemission ? (
            <div onClick={onShareClick} className='icon-button' title={appLabels.user_actions.share}>
                <AwsCatIcon name='share' width='20' height='20' fill='#000' />
            </div>
        ) : undefined;
    };

    function displayFavoriteButton(): JSX.Element | undefined {
        return assessmentPermissionManager.hasViewPemission ? (
            <div onClick={onFavoritesClick} className='icon-button'>
                {assessment?.isfavorite ? (
                    <AwsCatIcon name='favorite on' width='20' height='20' fill='#D13212' />
                ) : (
                    <AwsCatIcon name='favorite' width='20' height='20' fill='#000' />
                )}
            </div>
        ) : undefined;
    }

    const displayDeleteButton = (): JSX.Element | undefined => {
        return assessmentPermissionManager.hasFullPemission ? (
            <div onClick={onCloseClick} className='icon-button' title={appLabels.user_actions.delete}>
                <AwsCatIcon name='delete' width='20' height='20' fill='#000' />
            </div>
        ) : undefined;
    };

    return (
        <div className='awscat-assessment-actions'>
            <Box float={actionsFloat}>
                <SpaceBetween direction='horizontal' size='xs'>
                    {displayEditButton()}
                    {displayShareButton()}
                    {displayFavoriteButton()}
                    {displayDeleteButton()}
                </SpaceBetween>
            </Box>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(AssessmentActions));
