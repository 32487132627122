import '../ResultsReviewAll.scss';

import { AppLabels } from '../../../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import dompurify from '../../../../../common/DomPurify';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { useAppSelector } from '../../../../redux/hooks';
import LegendBoxes from '../legend/LegendBoxes';

const AssessmentScoresInfoPanel = (props: AppLabelsContextInterface & { scoreScale: ScoreScale }): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const contextHelp = currentAssessment?.template?.defaults?.report?.scoreBarChart?.context;
    const sanitizedContextHelp = dompurify.sanitize(contextHelp || appLabels.assessment.results.review_all.scores.info_panel_text);

    return (
        <div>
            <div className='awscat-assessment-report-help-panel' dangerouslySetInnerHTML={{ __html: sanitizedContextHelp }}></div>
            <div>
                <h4>{appLabels.common.legend}</h4>
                <LegendBoxes scoreScale={props.scoreScale}></LegendBoxes>
            </div>
        </div>
    );
};

export default withAppLabelsContext(AssessmentScoresInfoPanel);
