import '../rating/RatingTile.scss';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { generatePercentageString } from '../../../../../common/Utils';
import { Comment } from '../CommentBox';
import CommentsThread from '../CommentsThread';
import RatingPercentageBar from '../rating/RatingTilePercentageBar';

export type YesNoTileProps = AppLabelsContextInterface & {
    groupName: string;
    yesNoLabel: string;
    voteCount?: number | null;
    totalVotes?: number | null;
    error?: boolean;
    participantComments?: Array<Comment>;
    onToggle?: (event: any) => void;
    isSelected?: boolean;
};

const YesNoTile = (props: YesNoTileProps): JSX.Element => {
    const votePercentage = generatePercentageString(props.voteCount, props.totalVotes);

    return (
        <div className='tile-wrapper yes-no-rating'>
            <div
                onClick={props.onToggle}
                className={`tile-${props.isSelected ? 'selected' : 'unselected'}`}
                data-testid={`${props.yesNoLabel.toLowerCase()}-tile`}
            >
                <div className='rating'>
                    <RatingPercentageBar
                        voteCount={props.voteCount}
                        barWidth={votePercentage}
                        barLabel={props.appLabels.assessment.facilitate.votes}
                    />
                    <div className='rating-align-middle'>
                        <div>{props.yesNoLabel}</div>
                    </div>
                </div>
            </div>
            <CommentsThread
                commentHeader={props.appLabels.assessment.facilitate.participant_comments}
                comments={props.participantComments}
                usePopover={true}
            />
        </div>
    );
};

export default withAppLabelsContext(YesNoTile);
