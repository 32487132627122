import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { baseJsonTemplate } from './utils/BaseTemplate';

export interface CreateTemplateDetails {
    templateName: string;
    templateDescription: string;
    templateAcronym: string;
}

export type Template = typeof baseJsonTemplate;

export interface CreateTemplateState extends CreateTemplateDetails {
    template: Template;
}

export const initialCreateTemplateState: CreateTemplateState = {
    templateName: '',
    templateDescription: '',
    templateAcronym: '',
    template: null,
};

export const createTemplateSlice = createSlice({
    name: 'createTemplateState',
    initialState: initialCreateTemplateState,
    reducers: {
        loadTemplate: (state, action: PayloadAction<Template>) => {
            state.template = action.payload;

            // Might have already entered template details; update the newly-uploaded template with these details
            // by calling this other reducer
            createTemplateSlice.caseReducers.updateTemplateWithLatestDetails(state);
        },
        unloadTemplate: (state) => {
            state.template = null;
        },
        updateTemplateDetails: (state, action: PayloadAction<Partial<CreateTemplateDetails>>) => {
            const newDetails = action.payload;
            if (newDetails.templateName !== undefined) {
                state.templateName = newDetails.templateName;
            }
            if (newDetails.templateDescription !== undefined) {
                state.templateDescription = newDetails.templateDescription;
            }
            if (newDetails.templateAcronym !== undefined) {
                state.templateAcronym = newDetails.templateAcronym;
            }

            // Update the current template with the new details
            createTemplateSlice.caseReducers.updateTemplateWithLatestDetails(state);
        },
        updateTemplateWithLatestDetails: (state) => {
            // Intentionally not exported. This is meant as a side effect of updating template/details
            const { template, templateName, templateAcronym, templateDescription } = state;

            if (template) {
                Object.assign(template, {
                    title: templateName,
                    type: templateAcronym,
                    description: templateDescription,
                    defaults: {
                        ...state.template.defaults,
                        report: {
                            report: { title: templateName },
                            heatmap: { title: templateName + ' Heatmap' },
                            scoreBarChart: { title: templateName + ' Scores' },
                            radar: { title: templateName + ' Radar Chart' },
                        },
                    },
                });
            }
        },
    },
});

export const { loadTemplate, unloadTemplate, updateTemplateDetails } = createTemplateSlice.actions;

export default createTemplateSlice.reducer;
