import { Colorizer } from './Colorizer';
import { ScoreScale } from '../../common/score/ScoreScale';
import { ScoredItem } from '../../common/score/ScoreUtils';
class StratumColorizer implements Colorizer {
    scoreScale: ScoreScale;
    constructor(scoreScale: ScoreScale) {
        this.scoreScale = scoreScale;
    }

    colorize = (scored: ScoredItem | null) => {
        const stratumColor = this.scoreScale.getStratificationBackgroundColor(scored);
        return stratumColor.replace('#', '0x');
    };
}

export default StratumColorizer;
