import { AssessmentResourceAction, ResourcePermission } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { AssessmentPermissionViewModel } from '../../components/assessments/PermissionViewModel';
import { extractAmazonEmailFromUserId, isAmazonUser } from '../Utils';

export class AssessmentPermissionManager {
    public readonly userId: string;
    public readonly permissions: Array<AssessmentPermissionViewModel>;
    public readonly hasViewPemission: boolean;
    public readonly hasUpdatePemission: boolean;
    public readonly hasFullPemission: boolean;

    private readonly topPermission: AssessmentResourceAction | null;

    constructor(
        userId: string,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        permissions: (ResourcePermission | null)[]
    ) {
        this.userId = userId;
        this.permissions = AssessmentPermissionManager.transformToAssessmentPermissionViewModel(permissions);
        this.topPermission = AssessmentPermissionManager.getTopPermission(userId, this.permissions);
        this.hasFullPemission = this.allowFullPemission();
        this.hasUpdatePemission = this.allowUpdatePemission();
        this.hasViewPemission = this.allowViewPemission();
    }

    private allowFullPemission(): boolean {
        return this.topPermission === AssessmentResourceAction.FULL;
    }

    private allowUpdatePemission(): boolean {
        return this.allowFullPemission() || this.topPermission === AssessmentResourceAction.UPDATE;
    }

    private allowViewPemission(): boolean {
        return this.allowUpdatePemission() || this.topPermission === AssessmentResourceAction.VIEW;
    }

    private static transformToAssessmentPermissionViewModel(permissions: (ResourcePermission | null)[]): AssessmentPermissionViewModel[] {
        return permissions
            ? permissions.reduce((accumulator, currentValue) => {
                  const action = currentValue?.action;
                  if (action) {
                      const userId = currentValue?.userId || '';
                      const inviteeEmail = currentValue?.inviteeEmail || '';
                      const userEmail = isAmazonUser(userId) ? extractAmazonEmailFromUserId(userId) : undefined;
                      accumulator.push({
                          userId,
                          userEmail,
                          action,
                          inviteeEmail,
                      });
                  }
                  return accumulator;
              }, new Array<AssessmentPermissionViewModel>())
            : [];
    }

    public static getTopPermission(userId: string, permissions: AssessmentPermissionViewModel[]): AssessmentResourceAction | null {
        let topPermission: AssessmentResourceAction | null = null;
        permissions
            .filter((p) => p.userId === userId)
            .every((p) => {
                if (p.action === AssessmentResourceAction.FULL) {
                    topPermission = AssessmentResourceAction.FULL;
                    return false;
                } else if (p.action === AssessmentResourceAction.UPDATE) {
                    topPermission = AssessmentResourceAction.UPDATE;
                } else if (!topPermission && p.action === AssessmentResourceAction.VIEW) {
                    topPermission = AssessmentResourceAction.VIEW;
                }
                return true;
            });
        return topPermission;
    }
}

export default AssessmentPermissionManager;
