import { GetEnvironment } from '../../../../Environments';

const simBaseUrl = 'https://i.amazon.com/issues/create?template=57863ad1-dd9c-444d-8b65-05455eddbb5c';
const simTitle = 'A2T Feature Request - Onboard {templateName}';
const templateLocationCustomFieldId = 'template_location';

/**
 * Creates a SIM template url that:
 * 1. Creates a ticket in the right folder
 * 2. Has a custom title with the template name
 * 3. Specifies where the developer can find the template in a hidden field (e.g. beta AmazonCorporate_samehdi/1-2-3-4, found in "Information" tab)
 * @param templateId the ID of the template that was just uploaded
 * @param templateName the name of the template (for display in SIM)
 * @param username the user who just uploaded the template
 * @returns {string} the SIM URL that the user should navigate to
 */
export function getOnboardTemplateSimUrl(templateId: string, templateName: string, username: string): string {
    const templateLocation = `${GetEnvironment()} ${username}/${templateId}.json`;
    // Filling in custom fields: https://refresh.sage.amazon.dev/posts/1297919
    return `${simBaseUrl}&title=${simTitle.replace('{templateName}', templateName)}
        &customFields[string][0][id]=${templateLocationCustomFieldId}
        &customFields[string][0][value]=${templateLocation}`;
}
