import {
    CreateRecommendationAssignmentsCommand,
    RecommendationOutput,
    RecommendationResourceAssignment,
    RecommendationType,
} from '@amzn/aws-assessment-recommendation-service-client';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Alert, Box, Button, Modal, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPrescribedRecommendationTableColumnDefs } from './RecommendationConstants';
import { createRecommendationsSuccess } from './RecommendationsSlice';
import { getRecommendationServiceClient } from '../../../../../../api/recommendationService/RecommendationServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../../common/AppLabelsContext';
import Constants from '../../../../../../common/Constants';
import rumClient from '../../../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../../../localization/LocalizationContext';

export interface SelectPrescribedRecommendationsModalProps {
    assessmentId: string;
    assessmentType: string;
    categoryId: string;
    prescribedRecommendations: RecommendationOutput[];
    onDismiss: () => void;
}

const SelectPrescribedRecommendationsModal = (props: SelectPrescribedRecommendationsModalProps & AppLabelsContextInterface): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const columnDefinitions = getPrescribedRecommendationTableColumnDefs(props.appLabels);

    const { items, collectionProps, actions } = useCollection<RecommendationOutput>(props.prescribedRecommendations, {
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[0], // By default, sort by Verbiage
                isDescending: true,
            },
        },
        selection: {},
    });
    const setSelectedItems = actions.setSelectedItems;
    const selectedItems = collectionProps.selectedItems;
    const setSorting = actions.setSorting;

    const onConfirmAdd = useCallback(async () => {
        setIsLoading(true);
        const rsClient = getRecommendationServiceClient();
        const recommendationsToCreate: RecommendationResourceAssignment[] = selectedItems.map((item) => ({
            referenceId: props.categoryId,
            recommendationId: item.recommendationId,
            recommendationType: RecommendationType.IN_TOOL,
            resourceId: `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${props.assessmentId}`,
        }));
        try {
            await rsClient.send(
                new CreateRecommendationAssignmentsCommand({
                    recommendationAssignments: recommendationsToCreate,
                    resourceId: `${Constants.RECOMMENDATIONS_RESOURCE_ID_PREFIX}${props.assessmentId}`,
                    targetId: `${Constants.RECOMMENDATIONS_TARGET_ID_PREFIX}${props.assessmentType}`,
                })
            );
            dispatch(
                createRecommendationsSuccess({
                    recommendationsToCreate: recommendationsToCreate as RecommendationOutput[],
                })
            );
            setErrorMessage('');
            setIsLoading(false);
            props.onDismiss();
        } catch (err) {
            rumClient.recordError(err);
            setErrorMessage((err as Error)?.message);
            setIsLoading(false);
        }
    }, [dispatch, props, selectedItems]);
    return (
        <Modal
            onDismiss={props.onDismiss}
            size='max'
            visible
            header={props.appLabels.assessment.results.review_all.report.recommended_actions.select_prescribed_recommendations}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={props.onDismiss} loading={isLoading}>
                            {props.appLabels.user_actions.cancel}
                        </Button>
                        <Button variant='primary' onClick={onConfirmAdd} loading={isLoading} disabled={!selectedItems.length}>
                            {props.appLabels.user_actions.confirm}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Table
                columnDefinitions={columnDefinitions}
                items={items}
                onSortingChange={({ detail }) => {
                    setSorting(detail);
                }}
                sortingColumn={collectionProps.sortingColumn}
                sortingDescending={collectionProps.sortingDescending}
                selectionType='multi'
                selectedItems={selectedItems}
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
            />
            {errorMessage ? (
                <Alert
                    type='error'
                    header={props.appLabels.assessment.results.review_all.report.recommended_actions.failed_select_prescribed_recommendations}
                >
                    {errorMessage}
                </Alert>
            ) : null}
        </Modal>
    );
};

export default withLocalizationContext(withAppLabelsContext(SelectPrescribedRecommendationsModal));
