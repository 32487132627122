import { Box, Button, ExpandableSection, Grid, SpaceBetween, TextFilter, TokenGroup } from '@amzn/awsui-components-react';
import { FormattedMessage } from 'react-intl';

import { QuestionCounter } from './QuestionCounter';
import { AssessmentFacetFilter, PhaseFilter, QuestionVectorFilter, RatingsFilter } from './ReportFilters';
import { AppLabels } from '../../../../../../common/AppLabels';
import Constants from '../../../../../../common/Constants';

export const FilteringBox = (
    appLabels: AppLabels,
    questionCounter: QuestionCounter,
    onTextFilter: (detail: any) => void,
    vectorSpaceName: string,
    filteringText: string | null,
    allRatings: string[],
    onRatingFilter: (detail: any) => void,
    selectedRatings: string[] | null | undefined,
    allActivities: AssessmentFacetFilter[],
    onActivityFilter: (detail: any) => void,
    selectedActivities: string[] | null | undefined,
    allPhases: (string | null)[] | null | undefined,
    onPhaseFilter: (detail: any) => void,
    selectedPhases: string[] | null | undefined,
    onClearFilter: (detail: any) => void
): JSX.Element => {
    const i18nQuestions = appLabels.assessment.results.review_all.report.questions;
    const i18nFilteringTextPlaceholder = appLabels.assessment.results.review_all.report.filtering_text_placeholder;
    const isActivities = vectorSpaceName === Constants.VECTOR_SPACE_TYPE_ACTIVITIES;
    let gridDefs = null;
    if (isActivities) {
        gridDefs = [{ colspan: { xs: 3, xxs: 3 } }, { colspan: { xs: 3, xxs: 3 } }, { colspan: { xs: 3, xxs: 3 } }, { colspan: { xs: 3, xxs: 3 } }];
    } else {
        gridDefs = [{ colspan: 6 }, { colspan: 3 }, { colspan: 3 }];
    }
    return (
        <Box className='filter-box'>
            <SpaceBetween size='m' direction='vertical'>
                <Box>
                    <Box fontSize='heading-l' fontWeight='bold' display='inline-block'>{`${i18nQuestions} `}</Box>{' '}
                    <Box
                        className='filter-box-num-questions'
                        fontSize='heading-l'
                        fontWeight='normal'
                        display='inline-block'
                    >{`(${questionCounter.getTotalQuestionCount()})`}</Box>
                </Box>
                <Grid gridDefinition={gridDefs}>
                    <TextFilter
                        onChange={({ detail }) => {
                            onTextFilter(detail.filteringText);
                        }}
                        filteringPlaceholder={i18nFilteringTextPlaceholder}
                        filteringText={filteringText || ''}
                    ></TextFilter>
                    {RatingsFilter(appLabels, allRatings, onRatingFilter, selectedRatings)}
                    {QuestionVectorFilter(appLabels, vectorSpaceName, allActivities, onActivityFilter, selectedActivities)}
                    {isActivities && allPhases?.length > 1 ? PhaseFilter(appLabels, allPhases, onPhaseFilter, selectedPhases) : null}
                </Grid>
                {FiltersExpandableTokenGroup(
                    appLabels,
                    questionCounter,
                    onTextFilter,
                    filteringText,
                    onRatingFilter,
                    selectedRatings,
                    onActivityFilter,
                    selectedActivities,
                    onPhaseFilter,
                    selectedPhases,
                    onClearFilter
                )}
            </SpaceBetween>
        </Box>
    );
};

/**
 * This component displays all the filter tokens for selected values from all the filters
 * @param appLabels
 * @param questionCounter
 * @param onTextFilter
 * @param filteringText
 * @param onRatingFilter
 * @param selectedRatings
 * @param onActivityFilter
 * @param selectedActivities
 * @param onPhaseFilter
 * @param selectedPhases
 * @param onClearFilter
 * @returns
 */
const FiltersExpandableTokenGroup = (
    appLabels: AppLabels,
    questionCounter: QuestionCounter,
    onTextFilter: (detail: any) => void,
    filteringText: string | null | undefined,
    onRatingFilter: (detail: any) => void,
    selectedRatings: string[] | null | undefined,
    onActivityFilter: (detail: any) => void,
    selectedActivities: string[] | null | undefined,
    onPhaseFilter: (detail: any) => void,
    selectedPhases: string[] | null | undefined,
    onClearFilter: (detail: any) => void
) => {
    const i18nRemove = appLabels.assessment.results.review_all.report.remove;
    const i18nText = appLabels.assessment.results.review_all.report.text;
    const i18nRating = appLabels.assessment.results.review_all.report.rating;
    const i18nActivity = appLabels.assessment.results.review_all.report.activity;
    const i18nPhase = appLabels.assessment.results.review_all.report.phase;
    let filteringTextTokenText = filteringText?.substring(0, Constants.DEFAULT_NUMBER_TEXT_FILTER_CHARS);
    if (filteringText && filteringTextTokenText && filteringText.length > filteringTextTokenText.length) {
        filteringTextTokenText = filteringTextTokenText + '...';
    }

    // Below we are determining the tokens from each filter: text search, ratings, activities, phases
    const textTokens = filteringText
        ? [
              {
                  label: `${i18nText}: ${filteringTextTokenText}`,
                  dismissLabel: `${i18nRemove} ${i18nText}`,
              },
          ]
        : [];
    const ratingTokens = selectedRatings?.map((rating) => {
        return {
            label: `${i18nRating}: ${rating.toString()}`,
            dismissLabel: `${i18nRemove} ${rating.toString()}`,
        };
    });
    const activityTokens = selectedActivities?.map((activity) => {
        return {
            label: `${i18nActivity}: ${activity}`,
            dismissLabel: `${i18nRemove} ${activity}`,
        };
    });
    const phaseTokens = selectedPhases?.map((phase) => {
        return {
            label: `${i18nPhase}: ${phase}`,
            dismissLabel: `${i18nRemove} ${phase}`,
        };
    });
    // concatenate in precise order so that the onDismiss function works correctly
    const allTokens = textTokens.concat(ratingTokens || [], activityTokens || [], phaseTokens || []);
    const filtersPresent = allTokens.length > 0;

    const matches = (
        <FormattedMessage id='assessment_results_review_all_report_matches' values={{ count: questionCounter.getDisplayedQuestionCount() }} />
    );
    const header = (
        <Box>
            <Box className='filter-box-filters-applied' fontWeight='bold' fontSize='body-m' display='inline-block'>
                <FormattedMessage id='assessment_results_review_all_report_filters_applied' values={{ count: allTokens?.length }} />
            </Box>
            {' - '}
            <Box className='filter-box-matches' display='inline-block' fontSize='body-m'>
                {matches}
            </Box>
        </Box>
    );
    return filtersPresent ? (
        <>
            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                <ExpandableSection defaultExpanded={true} header={header}>
                    <TokenGroup
                        items={allTokens}
                        onDismiss={({ detail }) => {
                            // allTokens was constructed in a certain order so that we could definitively determine source for each token (this onDismiss
                            // event only provides access to array index, so we have no alternative)
                            const textLength = textTokens?.length;
                            const ratingsLength = ratingTokens?.length;
                            const categoriesLength = activityTokens?.length;
                            const phaseLength = phaseTokens?.length;
                            let itemIndex = detail.itemIndex;
                            if (textLength && itemIndex < textLength) {
                                onTextFilter('');
                            } else {
                                itemIndex = itemIndex - (textLength || 0);
                                if (ratingsLength && itemIndex < ratingsLength) {
                                    onRatingFilter([
                                        ...(selectedRatings?.slice(0, itemIndex) || []),
                                        ...(selectedRatings?.slice(itemIndex + 1) || []),
                                    ]);
                                } else {
                                    itemIndex = itemIndex - (ratingsLength || 0);
                                    if (categoriesLength && itemIndex < categoriesLength) {
                                        onActivityFilter([
                                            ...(selectedActivities?.slice(0, itemIndex) || []),
                                            ...(selectedActivities?.slice(itemIndex + 1) || []),
                                        ]);
                                    } else {
                                        itemIndex = itemIndex - (categoriesLength || 0);
                                        if (phaseLength && itemIndex < phaseLength) {
                                            onPhaseFilter([
                                                ...(selectedPhases?.slice(0, itemIndex) || []),
                                                ...(selectedPhases?.slice(itemIndex + 1) || []),
                                            ]);
                                        }
                                    }
                                }
                            }
                        }}
                    ></TokenGroup>
                </ExpandableSection>
                <Box float='right'>
                    <SpaceBetween size='l' direction='horizontal'>
                        <Box display='inline-block'>
                            <Button onClick={onClearFilter}>Clear all filters</Button>
                        </Box>
                    </SpaceBetween>
                </Box>
            </Grid>
        </>
    ) : (
        <></>
    );
};
