import { BooleanDistribution } from '@amzn/awscat-aws-assessment-service-typescript-client';

import Constants from '../Constants';

/**
 * An item with score and response distribution
 */
export interface ScoredItem {
    score: number | null | undefined;
    booleanDistribution?: BooleanDistribution;
}

/**
 * Get label to display in the report page for a given scored item
 * @param scored
 * @returns
 */
export const getScoreLabel = (scored: ScoredItem | null | undefined): number | string => {
    const score = scored?.score ?? Constants.ASSESSMENT_DEFAULT_INCOMPLETE_LABEL;
    if (score === Constants.ASSESSMENT_DEFAULT_NA_SCORE) {
        return Constants.ASSESSMENT_DEFAULT_NA_LABEL;
    }
    return score;
};

/**
 * Comparator for two `ScoredItem`s
 * @param a the first `ScoredItem`
 * @param b the second `ScoredItem`
 * @returns a positive number if a.score > b.score, a negative number if b.score > a.score, or 0 if the scores are equal/nullish
 */
export const compareScores = (a: ScoredItem | null | undefined, b: ScoredItem | null | undefined): number => {
    if (a?.score !== null && a?.score !== undefined && b?.score !== null && b?.score !== undefined) {
        if (a.score < b.score) return -1;
        if (a.score === b.score) return 0;
        else return 1;
    } else if (!a?.score && b?.score) return -1;
    else if (a?.score && !b?.score) return 1;
    else return 0;
};
