import {
    AssessmentTemplateMetadata,
    PRODUCT_OWNER_POSIX_GROUP,
    PrincipalIdToTemplateActorMapping,
    ShareTemplateInput,
    ShareableTemplateActor,
    UnshareTemplateInput,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Button, FormField, Grid, Input, Link, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useState } from 'react';

import { SpecialPrincipalCheckbox } from './SpecialPrincipalCheckbox';
import TemplatePublicationInfoModal from './TemplatePublicationInfoModal';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import { addProviderIfAmazonOrPartner, removeProviderFromPrincipalId } from '../../../../../common/Utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateTemplatePermissions } from '../CurrentTemplateSlice';
import TemplateConstants from '../TemplateConstants';

type ShareWithCoownersProps = AppLabelsContextInterface & {
    canShare: boolean;
    shareTemplate: (options: { variables: { input: ShareTemplateInput } }) => Promise<void>;
    unshareTemplate: (options: { variables: { input: UnshareTemplateInput } }) => Promise<void>;
    saveClickCount: number;
    resetClickCount: number;
    setArePermissionsChanged: (val: boolean) => void;
};
const ShareWithCoowners: FunctionComponent<ShareWithCoownersProps> = ({
    appLabels,
    canShare,
    shareTemplate,
    unshareTemplate,
    saveClickCount,
    resetClickCount,
    setArePermissionsChanged,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);

    const [productTeamChecked, setProductTeamChecked] = useState<boolean>(currentTemplateMetadata.coowners.includes(PRODUCT_OWNER_POSIX_GROUP));
    const [coownerToAdd, setCoownerToAdd] = useState<string>('');
    const [coownersToAdd, setCoownersToAdd] = useState<string[]>([]);
    const [coownersToRemove, setCoownersToRemove] = useState<string[]>([]);

    const [templatePublicationInfoModalVisible, setTemplatePublicationInfoModalVisible] = useState(false);

    // Exclude special principals from list (i.e. product owner group)
    const [coownerItems, setCoownerItems] = useState<TokenGroupProps.Item[]>(
        currentTemplateMetadata.coowners
            .filter((coowner) => coowner !== PRODUCT_OWNER_POSIX_GROUP)
            .map(
                (coownerUserId): TokenGroupProps.Item => ({
                    // Don't show `catportal-`
                    label: removeProviderFromPrincipalId(coownerUserId),
                })
            )
    );

    const [lastResetClickCount, setLastResetClickCount] = useState<number>(0);
    const [lastSavedClickCount, setLastSavedClickCount] = useState<number>(0);

    // If there are any added/removed items, indicate to the parent component that the permissions have changed
    useEffect(() => {
        if (coownersToAdd.length || coownersToRemove.length) {
            setArePermissionsChanged(true);
        } else {
            setArePermissionsChanged(false);
        }
    }, [coownersToAdd, coownersToRemove, setArePermissionsChanged]);

    // This is an "on reset" useEffect
    useEffect(() => {
        if (lastResetClickCount === resetClickCount) {
            // Reset wasn't clicked
            return;
        }
        setCoownerToAdd('');
        setCoownersToAdd([]);
        setCoownersToRemove([]);

        // Un-delete any items and remove any items "pending save"
        setCoownerItems((coownerItems) =>
            coownerItems
                .map((item) => ({ ...item, disabled: undefined }))
                .filter((item) => item.description !== appLabels.manage_templates.share.pending_save)
        );

        setLastResetClickCount(resetClickCount);
    }, [appLabels.manage_templates.share.pending_save, lastResetClickCount, resetClickCount]);

    // This is the "on save" useEffect
    useEffect(() => {
        if (lastSavedClickCount === saveClickCount) {
            // Save wasn't clicked
            return;
        }

        const templatePermissionsToRemove: PrincipalIdToTemplateActorMapping[] = coownersToRemove.map(
            (coownerToRemove): PrincipalIdToTemplateActorMapping => ({
                // Backend expects that users have `catportal-` as a prefix. Add if this is a user ID
                principalId: addProviderIfAmazonOrPartner(coownerToRemove),
                templateActor: ShareableTemplateActor.CoOwner,
            })
        );

        if (templatePermissionsToRemove.length) {
            const unshareTemplateInput: UnshareTemplateInput = {
                templateId: currentTemplateMetadata.templateId,
                permissionsToRemove: templatePermissionsToRemove,
            };

            unshareTemplate({
                variables: {
                    input: unshareTemplateInput,
                },
            });

            setCoownersToRemove([]);

            // Also remove from the current items
            setCoownerItems(coownerItems.filter(({ disabled }) => !disabled));
        }

        // Now handle added permissions
        const templatePermissionsToAdd: PrincipalIdToTemplateActorMapping[] = coownersToAdd.map(
            (coownerToAdd): PrincipalIdToTemplateActorMapping => ({
                principalId: addProviderIfAmazonOrPartner(coownerToAdd),
                templateActor: ShareableTemplateActor.CoOwner,
            })
        );

        if (templatePermissionsToAdd.length) {
            const shareTemplateInput: ShareTemplateInput = {
                templateId: currentTemplateMetadata.templateId,
                newPermissions: templatePermissionsToAdd,
            };

            shareTemplate({
                variables: {
                    input: shareTemplateInput,
                },
            });

            setCoownersToAdd([]);

            // New co-owners are already in this list. Remove the "pending save" text
            setCoownerItems((coownerItems) =>
                coownerItems.map(
                    ({ label }): TokenGroupProps.Item => ({
                        label,
                    })
                )
            );
        }

        // Update the Redux state
        dispatch(updateTemplatePermissions({ addedPermissions: templatePermissionsToAdd, removedPermissions: templatePermissionsToRemove }));

        setLastSavedClickCount(saveClickCount);
    }, [
        coownerItems,
        coownersToAdd,
        coownersToRemove,
        currentTemplateMetadata.templateId,
        shareTemplate,
        unshareTemplate,
        dispatch,
        saveClickCount,
        lastSavedClickCount,
    ]);

    return (
        <>
            <FormField>
                <Grid>
                    <SpecialPrincipalCheckbox
                        identifier='product-owner'
                        setPrincipalsToAdd={setCoownersToAdd}
                        setPrincipalsToRemove={setCoownersToRemove}
                        specialPrincipalId={PRODUCT_OWNER_POSIX_GROUP}
                        text={appLabels.manage_templates.share.a2t_product_team}
                        checked={productTeamChecked}
                        setChecked={setProductTeamChecked}
                        principalsArray={currentTemplateMetadata.coowners}
                    />
                    <Link variant='info' onFollow={() => setTemplatePublicationInfoModalVisible(true)}>
                        {appLabels.common.info}
                    </Link>
                </Grid>
                <Grid gridDefinition={[{ colspan: 7 }, { colspan: 3 }]}>
                    <Input
                        data-testid='coowner-textbox'
                        value={coownerToAdd}
                        onChange={({ detail: { value } }) => setCoownerToAdd(value)}
                        placeholder={appLabels.manage_templates.share.add_coowner}
                        readOnly={!canShare}
                    />
                    <Button
                        data-testid='coowner-add-button'
                        disabled={!canShare || !coownerToAdd}
                        onClick={() => {
                            setCoownerToAdd('');

                            // If this item is already present, ignore
                            if (coownerItems.some(({ label }) => label === coownerToAdd)) {
                                return;
                            }

                            setCoownersToAdd((coownersToAdd) => coownersToAdd.concat(coownerToAdd));
                            // Add this coowner to the list. Indicate that it's pending save
                            setCoownerItems((coownerItems) =>
                                coownerItems.concat({
                                    label: coownerToAdd,
                                    description: appLabels.manage_templates.share.pending_save,
                                })
                            );
                        }}
                    >
                        {appLabels.user_actions.add}
                    </Button>
                </Grid>
                <TokenGroup
                    data-testid='coowner-token-group'
                    items={coownerItems}
                    limit={TemplateConstants.SHARE_TEMPLATE_TOKEN_GROUP_LIMIT}
                    onDismiss={({ detail: { itemIndex } }) => {
                        if (!canShare) {
                            return;
                        }

                        if (coownerItems[itemIndex].description === appLabels.manage_templates.share.pending_save) {
                            // If this item is pending save, remove it from the list
                            setCoownersToAdd(coownersToAdd.filter((coownerToAdd) => coownerToAdd !== coownerItems[itemIndex].label));
                            setCoownerItems(coownerItems.filter((_, index) => index !== itemIndex));
                        } else {
                            setCoownersToRemove(coownersToRemove.concat(coownerItems[itemIndex].label));
                            setCoownerItems((coownerItems) => {
                                coownerItems[itemIndex].disabled = true;
                                return coownerItems;
                            });
                        }
                    }}
                />
            </FormField>
            <TemplatePublicationInfoModal visible={templatePublicationInfoModalVisible} setVisible={setTemplatePublicationInfoModalVisible} />
        </>
    );
};

export default withAppLabelsContext(ShareWithCoowners);
