/* eslint-disable @typescript-eslint/no-empty-interface */

import { AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Box, PieChart, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useMemo } from 'react';

import ResponseDistributionInfoPanel from './ResponseDistributionInfoPanel';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import dompurify from '../../../../../common/DomPurify';
import { toFixedDigits } from '../../../../../common/Utils';
import { ScoreScale } from '../../../../../common/score/ScoreScale';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { filterExcludedSections } from '../../Utils';
import { AssessmentFacet, convertAssessmentSectionToAssessmentFacet, getAssessmentMetadataProvider } from '../../model/Assessment';

/**
 * Present response distribution (yes/no, selection...).
 * If workstreams are present, present them. Otherwise, present the sections.
 * Workstreams or sections will be converted into 'AssessmentFacets' for code reuse purposes
 * @param props
 * @returns
 */
const ResponseDistributionResults: FunctionComponent<AppLabelsContextInterface> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const assessmentId = currentAssessment?.id;
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const title = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.report?.responseDistribution?.title);
    const description = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.report?.responseDistribution?.description
    );
    const purifiedHtmlDescription = dompurify.sanitize(description || '');
    const scoreScale = useMemo(() => {
        return new ScoreScale(currentAssessment?.template?.defaults?.questionnaireAnswers, appLabels);
    }, [appLabels, currentAssessment?.template?.defaults?.questionnaireAnswers]);
    let assessmentFacets: AssessmentFacet[] | null | undefined = [];

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.results.review_all.responseDistribution.responseDistribution,
                content: <ResponseDistributionInfoPanel scoreScale={scoreScale} />,
                footer: '',
            })
        );
        return cleanup;
    }, [appLabels.assessment.results.review_all.responseDistribution.responseDistribution, dispatch, scoreScale]);

    // Show ResponseDistribution component content only if the assessment has at least one yes/no type question
    if (!currentAssessment?.template?.booleanDistribution) {
        return null;
    }
    // eslint doesn't recognize that the below is actually safe, since we checked existence above
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { numYes, numNo, numUnknown } = currentAssessment?.template?.booleanDistribution;
    const countOfBooleanQuestions = numYes + numNo + numUnknown;
    if (countOfBooleanQuestions === 0) {
        return null;
    }
    const promptMetadataProvider = getAssessmentMetadataProvider(currentAssessment);

    const excludeSections = currentAssessment?.template?.defaults?.report?.responseDistribution?.excludeSections || [];
    const sections = currentAssessment?.template?.sections;
    assessmentFacets =
        filterExcludedSections(sections, excludeSections, currentAssessment, [AssessmentResponseTypes.YES_NO])?.map((section) =>
            convertAssessmentSectionToAssessmentFacet(assessmentId, selectedSnapshotId, section, promptMetadataProvider)
        ) || [];

    const items = [];
    assessmentFacets.forEach((facet) => {
        if (!facet.booleanDistribution) {
            return;
        }
        // a facet is a workstream or a section
        facet.questionVectors.forEach((questionVector) => {
            // a questionVector is a category
            const { booleanDistribution } = questionVector;
            if (!booleanDistribution) {
                return;
            }
            const { numYes, numNo, numUnknown } = booleanDistribution;
            if (numYes + numNo + numUnknown > 0) {
                // Show questionVector only if it has at least one yes/no type question
                items.push({
                    numYes,
                    numNo,
                    numUnknown,
                    categoryName: questionVector.name,
                    facetName: facet.name,
                });
            }
        });
    });
    items.push({
        numYes,
        numNo,
        numUnknown,
        categoryName: '-',
        facetName: appLabels.common.total,
    });

    return (
        <SpaceBetween size='m'>
            <SpaceBetween size='xs' direction='horizontal'>
                <Box variant='h3'>{title}</Box>
            </SpaceBetween>
            <div dangerouslySetInnerHTML={{ __html: purifiedHtmlDescription }}></div>
            <PieChart
                hideFilter
                hideLegend
                data={[
                    {
                        totalCount: numUnknown,
                        title: appLabels.common.unknown,
                        value: numUnknown / countOfBooleanQuestions,
                    },
                    {
                        totalCount: numNo,
                        title: appLabels.common.no,
                        value: numNo / countOfBooleanQuestions,
                    },
                    {
                        totalCount: numYes,
                        title: appLabels.common.yes,
                        value: numYes / countOfBooleanQuestions,
                    },
                ]}
                segmentDescription={(datum) => `${toFixedDigits(datum.value * 100)}%, ${datum.totalCount}`}
                detailPopoverContent={(datum) => [
                    { key: appLabels.common.percentage, value: `${toFixedDigits(datum.value * 100)}%` },
                    { key: appLabels.common.count, value: datum.totalCount },
                ]}
            ></PieChart>
            <Table
                items={items}
                columnDefinitions={[
                    { id: 'facet', header: appLabels.assessment.results.generate_report.parameters.perspective, cell: (item) => item.facetName },
                    { id: 'category', header: appLabels.assessment.results.generate_report.parameters.category, cell: (item) => item.categoryName },
                    { id: 'yes', header: appLabels.common.yes, cell: (item) => item.numYes },
                    { id: 'no', header: appLabels.common.no, cell: (item) => item.numNo },
                    { id: 'unknown', header: appLabels.common.unknown, cell: (item) => item.numUnknown },
                ]}
            ></Table>
        </SpaceBetween>
    );
};

export default withAppLabelsContext(ResponseDistributionResults);
