import { AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AssigneeAnswer, QuestionAnswer, QuestionnaireAndAnswers } from '@amzn/awscat-data-collection-service-typescript-client';

import { AssessmentAction } from './ReportHelpers';
import {
    SummarizationAction,
    SummarizationAssessmentMetadata,
    SummarizationComment,
    SummarizationInput,
    SummarizationQuestion,
} from '../../../api/summaryGeneration/SummaryGenerationClient';
import { AnalysisDoc, PromptIdToAnalysisDocMap } from '../../../api/wbs/WbsTypes';
import { AppLabels } from '../../../common/AppLabels';
import { transformWbsAnalysisDocToComments } from '../../../common/Utils';
import { AssessmentPromptViewModel } from '../../../components/assessments/AssessmentPromptViewModel';
import { consideredResponded, getResponseComments, getResponseValue } from '../../../components/assessments/Utils';
import { CurrentAssessmentState } from '../../../components/assessments/facilitate/CurrentAssessmentSlice';
import { Comment } from '../../../components/assessments/facilitate/body/CommentBox';
import { createAnswerMapFromQuestionnaireAnswers } from '../../../components/assessments/pre-event/util';

/**
 * Picks out data from an assessment that can be useful in a summary. Returns input for Summary Generation Service
 * @param assessmentState the assessment state stored in Redux
 * @param appLabels the localized labels in A2T
 * @returns the input that should be passed to SGS for summarization
 */
export const convertAssessmentToSummarizationInput = (assessmentState: CurrentAssessmentState, appLabels: AppLabels): SummarizationInput => {
    // Data that will be used to generate the input
    const assessmentToSummarize = assessmentState.currentAssessmentOrSelectedSnapshot;
    const prompts: AssessmentPromptViewModel[] = assessmentState.currentPromptState.prompts;
    const preEventInfo: QuestionnaireAndAnswers = assessmentState.preEventInfo;
    const liveSessionResponses: PromptIdToAnalysisDocMap = assessmentState.liveSessionResponses;

    const questionIdToPreEventResponses: Map<string, QuestionAnswer> | undefined =
        preEventInfo && createAnswerMapFromQuestionnaireAnswers(preEventInfo);

    // Include only prompts with responses. This can save many tokens
    const promptsWithResponses = prompts.filter((prompt) => consideredResponded(prompt.responseType, prompt.response));

    const questionsToSummarize: SummarizationQuestion[] = promptsWithResponses.map((prompt) => {
        // Get pre-event and live session comments, if present
        const comments: SummarizationComment[] = [];

        const preEventAnswerForPrompt: QuestionAnswer | undefined = questionIdToPreEventResponses?.get(prompt.id);
        if (preEventAnswerForPrompt) {
            // Filter out answers that don't have comments
            const preEventAnswersWithComments: AssigneeAnswer[] = preEventAnswerForPrompt.assigneeAnswers.filter(({ comment }) => !!comment);
            if (preEventAnswersWithComments) {
                comments.push(
                    ...preEventAnswersWithComments.map(
                        (preEventAnswer): SummarizationComment => ({
                            commentText: preEventAnswer.comment,
                            role: 'Customer', // MDC does not collect role. If this becomes a full feature, this should be an app label
                            writtenBy: preEventAnswer.userEmail, // for now: use pre-event email. Might not be able to get name
                        })
                    )
                );
            }
        }

        const analysisDocForPrompt: AnalysisDoc | undefined = liveSessionResponses?.get(prompt.id);
        if (analysisDocForPrompt) {
            const liveSessionComments: Comment[] = transformWbsAnalysisDocToComments(
                appLabels.assessment.facilitate.live_event,
                analysisDocForPrompt
            );
            if (liveSessionComments) {
                comments.push(
                    ...liveSessionComments.map((comment) => ({
                        commentText: comment.content,
                        role: 'Customer',
                        writtenBy: comment.author,
                    }))
                );
            }
        }

        // Get comments written by facilitator. Don't include for text questions, since those have just one text box
        const facilitatorComments = getResponseComments(prompt.response, prompt.responseType);
        if (facilitatorComments && prompt.responseType !== AssessmentResponseTypes.TEXT) {
            comments.push({
                commentText: facilitatorComments,
                role: 'Assessment facilitator',
                writtenBy: prompt.response.updatedBy, // for now: use Amazon alias instead of name
            });
        }

        const promptResponse = getResponseValue(prompt, appLabels, { includeRatingGuide: true, includeTextComment: true }).toString();

        return {
            ...(comments.length && { comments }), // include comments only if present
            questionText: prompt.label.text,
            response: promptResponse,
        };
    });

    // Get all the actions recommended in the assessment, from sections and categories
    const allActions: AssessmentAction[] = [
        ...assessmentToSummarize.template.sections
            .map((section) => section.categories.map((category) => category.actions?.actions))
            .flat()
            .flat()
            .flat(),
        ...assessmentToSummarize.template.workstreams
            .map((workstream) => workstream.activities.map((activity) => activity.actions?.actions))
            .flat()
            .flat()
            .flat(),
    ].filter((action) => !!action);
    const recommendedActionsToSummarize: SummarizationAction[] = allActions.map((assessmentAction) => ({
        action: assessmentAction.text,
        effort: assessmentAction.effort,
        priority: assessmentAction.priority,
    }));

    const assessmentMetadataToSummarize: SummarizationAssessmentMetadata = {
        assessmentName: assessmentToSummarize.description,
        assessmentTypeName: assessmentToSummarize.template.title,
        assessmentTypeDescription: assessmentToSummarize.template.description,
        assessmentTypeAbbreviation: assessmentToSummarize.type,
        customerName: assessmentToSummarize.accountCustomerName,
    };

    return { questions: questionsToSummarize, recommendedActions: recommendedActionsToSummarize, assessmentMetadata: assessmentMetadataToSummarize };
};
