import { Button, Grid, Textarea } from '@amzn/awsui-components-react';
import { FC, useCallback, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { editGridDefinition, readOnlyGridDefinition } from './PrescribedActionsList';
import { PrescribedAction } from '../../assessments/results/model/Assessment';

export type PrescribedRecommendedActionProps = {
    actionIndex: number;
    action: PrescribedAction;
    isReadOnly: boolean;
    deleteAction: (action: PrescribedAction) => void;
    updateAction: (updatedIndex: number, updatedAction: PrescribedAction) => void;
};

export const PrescribedRecommendedAction: FC<PrescribedRecommendedActionProps> = ({
    actionIndex,
    action,
    isReadOnly,
    deleteAction,
    updateAction,
}): JSX.Element => {
    const [actionState, setActionState] = useState<PrescribedAction>(action);

    const DragHandle = SortableHandle(() => <span data-testid='prescribed-action-drag-handle'>::</span>);

    const onActionChange = useCallback(() => {
        updateAction(actionIndex, actionState);
    }, [actionIndex, actionState, updateAction]);

    if (isReadOnly) {
        return (
            <Grid gridDefinition={readOnlyGridDefinition}>
                <Textarea name='prescribedAction' value={actionState.text || ''} readOnly={isReadOnly} data-testid='prescribed-action' />
            </Grid>
        );
    }
    return (
        <Grid gridDefinition={editGridDefinition}>
            <Textarea
                name='actionName'
                value={actionState.text || ''}
                readOnly={isReadOnly}
                onChange={({ detail }) => setActionState({ ...actionState, text: detail.value })}
                onBlur={onActionChange}
                data-testid='prescribed-action'
            />
            <Button
                variant='icon'
                iconName='close'
                disabled={isReadOnly}
                data-testid='prescribed-action-delete-btn'
                onClick={() => deleteAction(action)}
            ></Button>
            {<DragHandle />}
        </Grid>
    );
};
