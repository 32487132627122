import { Button } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { goToNextPromptOrModule, goToPreviousPromptOrModule } from '../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import TemplateConstants from '../../../administration/manage-templates/edit-template/TemplateConstants';
import { generateTemplateModuleUrl, generateTemplatePromptUrl } from '../../../administration/manage-templates/edit-template/TemplateUrlUtils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { jumpToNextPrompt, jumpToNextUnansweredPrompt, jumpToPreviousPrompt, jumpToPreviousUnansweredPrompt } from '../CurrentAssessmentSlice';

export enum AssessmentPromptNavButtonDirection {
    NEXT,
    PREVIOUS,
}

export enum AssessmentPromptNavButtonTarget {
    SEQUENTIAL,
    UNANSWERED,
}

type AssessmentPromptNavButtonProps = AppLabelsContextInterface & {
    verbose: boolean;
    direction: AssessmentPromptNavButtonDirection;
    target: AssessmentPromptNavButtonTarget;
    disabled: boolean;
    shouldDisplayTemplate: boolean;
};

const AssessmentPromptNavButton: FunctionComponent<AssessmentPromptNavButtonProps> = ({
    appLabels,
    verbose,
    direction,
    target,
    disabled = false,
    shouldDisplayTemplate = false,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const numberOfPrompts = useAppSelector((state) => state.currentAssessmentState.currentPromptState.prompts.length);
    const currentPromptIndex = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPromptIndex);

    // Template state
    const currentTemplateId = useAppSelector((state) => state.currentTemplateState.currentTemplateId);
    const templateLocale = useAppSelector((state) => state.currentTemplateState.templateLocale);
    const viewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const moduleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);
    const promptId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt?.promptId);

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    /**
     * Updates the current URL to point to the current template prompt
     */
    useEffect(() => {
        if (!shouldDisplayTemplate) {
            return;
        }

        // First render will always try to set URL to be the first prompt. After this, another component may try to
        // jump to a different prompt. This results in a loop, where each component is trying to "catch up"
        // with the other. To short circuit the loop, don't change the URL on the first render
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }

        // Prefer promptId in url, but if there's no prompt, use moduleId
        const url = promptId
            ? generateTemplatePromptUrl(currentTemplateId, templateLocale, viewId, promptId)
            : generateTemplateModuleUrl(currentTemplateId, templateLocale, viewId, moduleId);

        const isPathnameOutdated = !url.includes(history.location.pathname);
        // Search is outdated if no viewId is specified or if search params are different
        // A URL with no viewId is outdated, because it does not indicate where in the template the user currently is
        // We want to keep the URL in sync with the current view/module/prompt
        const isSearchOutdated = !history.location.search?.includes(TemplateConstants.SEARCH_PARAM_VIEW_ID) || !url.includes(history.location.search);
        const isUrlOutdated = isPathnameOutdated || isSearchOutdated;

        if (isUrlOutdated) {
            history.push(url);
        }
    }, [currentTemplateId, templateLocale, viewId, promptId, moduleId, history, shouldDisplayTemplate, isFirstRender]);

    if (direction === AssessmentPromptNavButtonDirection.NEXT && target === AssessmentPromptNavButtonTarget.SEQUENTIAL) {
        return (
            <Button
                onClick={() => {
                    if (shouldDisplayTemplate) {
                        dispatch(goToNextPromptOrModule());
                        return;
                    }

                    // If at the end of an assessment, go to review all page
                    if (currentPromptIndex === numberOfPrompts - 1) {
                        history.push(history.location.pathname + '/reviewall');
                    } else {
                        dispatch(jumpToNextPrompt());
                    }
                }}
                iconName={'angle-right'}
                iconAlign='right'
                variant='normal'
                disabled={disabled}
                data-testid={'btn-next-prompt'}
            >
                {verbose ? appLabels.assessment.facilitate.next : ''}
            </Button>
        );
    } else if (direction === AssessmentPromptNavButtonDirection.NEXT && target === AssessmentPromptNavButtonTarget.UNANSWERED) {
        return (
            <Button
                onClick={() => dispatch(jumpToNextUnansweredPrompt())}
                iconName={'angle-right-double'}
                iconAlign='right'
                variant='normal'
                disabled={disabled}
                data-testid={'btn-next-unanswered-prompt'}
            >
                {verbose ? appLabels.assessment.facilitate.next : ''}
            </Button>
        );
    } else if (direction === AssessmentPromptNavButtonDirection.PREVIOUS && target === AssessmentPromptNavButtonTarget.SEQUENTIAL) {
        return (
            <Button
                onClick={() => {
                    if (shouldDisplayTemplate) {
                        dispatch(goToPreviousPromptOrModule());
                    } else {
                        dispatch(jumpToPreviousPrompt());
                    }
                }}
                iconName={'angle-left'}
                iconAlign='right'
                variant='normal'
                disabled={disabled}
                data-testid={'btn-previous-prompt'}
            >
                {verbose ? appLabels.assessment.facilitate.previous : ''}
            </Button>
        );
    } else if (direction === AssessmentPromptNavButtonDirection.PREVIOUS && target === AssessmentPromptNavButtonTarget.UNANSWERED) {
        return (
            <Button
                onClick={() => dispatch(jumpToPreviousUnansweredPrompt())}
                iconName={'angle-left-double'}
                iconAlign='left'
                variant='normal'
                disabled={disabled}
                data-testid={'btn-previous-unanswered-prompt'}
            >
                {verbose ? appLabels.assessment.facilitate.previous : ''}
            </Button>
        );
    }

    return null;
};

export default withAppLabelsContext(AssessmentPromptNavButton);
