import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

import LiveSessionInfo from './LiveSessionInfo';
import LiveSessionSelections from './LiveSessionSelections';
import LiveSessionSuspendResume from './LiveSessionSuspendResume';
import { useAppSelector } from '../../redux/hooks';

interface LiveSessionHeaderBarProps {
    readOnly: boolean;
}

const LiveSessionHeaderBar: FunctionComponent<LiveSessionHeaderBarProps> = ({ readOnly }): JSX.Element | null => {
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const liveSessionEnabled = useAppSelector(
        (state) => state.currentAssessmentState.currentAssessment?.template?.defaults?.questionnaireAnswers?.wbsEnabled
    );

    if (liveSessionEnabled && currentAssessmentOrSelectedSnapshot && !selectedSnapshotId) {
        return (
            <div className='awscat-assessment-header-bar-components' data-testid='live-session-header-bar'>
                <Box float={'right'}>
                    <SpaceBetween direction='horizontal' size='xxs'>
                        <LiveSessionInfo />
                        <LiveSessionSelections readOnly={readOnly} />
                        {!readOnly ? <LiveSessionSuspendResume /> : null}
                    </SpaceBetween>
                </Box>
            </div>
        );
    }
    return null;
};

export default LiveSessionHeaderBar;
