import { AssessmentDescriptor, AssessmentTemplate } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Container, FormField, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import dompurify from 'dompurify';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { EditableDescriptorKey } from '../../../../../api/templateManagement/TemplateManagementClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import AssessmentDetailsHeader from '../../../../assessments/facilitate/header/AssessmentDetailsHeader';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../../common/RequestStatusFlashbar';
import TemplateEditableText from '../../../../common/TemplateEditableText';
import { openAppHelpPanel, updateAppHelpPanel } from '../../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { CurrentTemplateState } from '../CurrentTemplateSlice';
import { canEditLocale } from '../TemplatePermissionsUtils';
import { getEditableDescriptor, isTemplatePublished } from '../TemplateUtils';

type UpdateTemplateInfoProps = AppLabelsContextInterface & AuthContextInterface & {};
const UpdateTemplateInfo: FunctionComponent<UpdateTemplateInfoProps> = ({ appLabels, auth }) => {
    const dispatch = useAppDispatch();
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplate: AssessmentTemplate = useAppSelector((state) => state.currentTemplateState.currentTemplate);
    const currentTemplateDescriptor: AssessmentDescriptor = getEditableDescriptor(currentTemplateState, currentTemplate.descriptors[0].descriptorId);
    const deliveryGuidanceDescriptor: AssessmentDescriptor = getEditableDescriptor(
        currentTemplateState,
        currentTemplate.deliveryGuidance[0].descriptorId
    );

    // If the user can't edit or the template is published, forbid editing
    const currentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);
    const templateLocale = useAppSelector((state) => state.currentTemplateState.templateLocale);
    const canEdit = useMemo(
        () => canEditLocale(templateLocale, currentTemplateMetadata, auth) && !isTemplatePublished(currentTemplateMetadata),
        [templateLocale, currentTemplateMetadata, auth]
    );

    // Set help panel
    useEffect(() => {
        const purifiedContent = dompurify.sanitize(appLabels.manage_templates.update_template_info.help_panel.content);
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.update_template_info.help_panel.header,
                content: <div dangerouslySetInnerHTML={{ __html: purifiedContent }} />,
            })
        );
    }, [dispatch, appLabels.manage_templates.update_template_info.help_panel]);

    return (
        <div className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} shouldDisplayTemplate={true} />
            {/* Header that says "Edit metadata". This is shared among all template metadata forms */}
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.manage_templates.edit.metadata}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            {/* Form that allows editing template name and delivery guidance */}
            <div className='assessment-form'>
                <SpaceBetween direction='vertical' size='m'>
                    <Container header={<Header variant='h2'>{appLabels.manage_templates.update_template_info.link_text}</Header>}>
                        <SpaceBetween direction='vertical' size='l'>
                            <FormField>
                                <TemplateEditableText
                                    fieldName={appLabels.manage_templates.update_template_info.template_name}
                                    descriptor={currentTemplateDescriptor}
                                    keyToEdit={EditableDescriptorKey.Name}
                                    alwaysEditable={true}
                                    numRows={1}
                                    disabled={!canEdit}
                                />
                            </FormField>
                            <FormField>
                                <TemplateEditableText
                                    fieldName={appLabels.manage_templates.update_template_info.delivery_guidance_link}
                                    descriptor={deliveryGuidanceDescriptor}
                                    keyToEdit={EditableDescriptorKey.Description}
                                    alwaysEditable={true}
                                    doesFieldSupportHtml={true}
                                    numRows={2}
                                    disabled={!canEdit}
                                />
                            </FormField>
                            <FormField>
                                <TemplateEditableText
                                    fieldName={appLabels.manage_templates.update_template_info.delivery_guidance_help_context}
                                    descriptor={deliveryGuidanceDescriptor}
                                    keyToEdit={EditableDescriptorKey.HelpContext}
                                    alwaysEditable={true}
                                    doesFieldSupportHtml={true}
                                    disabled={!canEdit}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(UpdateTemplateInfo));
