import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import React from 'react';

export interface AppHelpPanelState {
    header?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    hide?: boolean; // hide help panel from page
    open?: boolean; // state of current help panel, open or closed
}

export const initialState: AppHelpPanelState = {
    header: undefined,
    content: undefined,
    footer: undefined,
    hide: false,
    open: true, // help panel open by default
};

export const appHelpPanelSlice = createSlice({
    name: 'appHelpPanelState',
    initialState,
    reducers: {
        hideAppHelpPanel: (state) => {
            state.hide = true;
            state.open = false;
        },
        openAppHelpPanel: (state) => {
            state.hide = false;
            state.open = true;
        },
        closeAppHelpPanel: (state) => {
            state.open = false;
        },
        toggleAppHelpPanel: (state) => {
            Object.assign(state, {
                ...state,
                open: !state.open,
            });
        },
        updateAppHelpPanel: (state, action: PayloadAction<AppHelpPanelState>) => {
            // by default unhide help panel unless specified in payload
            Object.assign(state, { hide: false, ...action.payload });
        },
        clearAppHelpPanel: (state) => {
            // only clears content. Let user control open/close
            Object.assign(state, { ...initialState, open: state.open });
        },
    },
});

export const { hideAppHelpPanel, openAppHelpPanel, closeAppHelpPanel, toggleAppHelpPanel, clearAppHelpPanel, updateAppHelpPanel } =
    appHelpPanelSlice.actions;

export default appHelpPanelSlice.reducer;
