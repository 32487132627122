import { AssessmentPrompt, CreatePromptInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import templateManagementClient from '../../../../../api/templateManagement/TemplateManagementClient';
import { CREATE_PROMPT } from '../../../../../api/templateManagement/TemplateManagementMutations';
import rumClient from '../../../../../common/monitoring/RumClient';
import { createTemplatePrompt, removeInProgressRequest } from '../../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

export const useAddQuestionCallback = () => {
    const dispatch = useAppDispatch();
    const currentViewId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentModuleId = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);

    const [createPromptMutation] = useMutation(CREATE_PROMPT, {
        client: templateManagementClient,
        onError: (error, options) => {
            rumClient.recordError(error);
            dispatch(removeInProgressRequest({ requestId: options.context.requestId, didRequestFail: true }));
        },
        onCompleted: (data, options) => {
            const newPrompt = data.createPrompt as AssessmentPrompt;
            dispatch(createTemplatePrompt({ newPrompt, containingModuleId: currentModuleId, viewId: currentViewId }));
            dispatch(removeInProgressRequest({ requestId: options.context.requestId }));
        },
    });

    return useCallback(() => {
        const createPromptInput: CreatePromptInput = {
            moduleId: currentModuleId,
        };

        const requestId = uuid();
        createPromptMutation({ variables: { input: createPromptInput }, context: { requestId } });
    }, [currentModuleId, createPromptMutation]);
};
