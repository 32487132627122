import { AssessmentTemplateMetadata } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentStatus } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Badge, Box, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import Paths from '../../../../Paths';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { truncateTextWithEllipsis } from '../../../../common/Utils';
import { getDescriptorForLocale, isTemplatePublished } from '../../../administration/manage-templates/edit-template/TemplateUtils';
import AwsCatIcon from '../../../common/Icons/AwsCatIcon';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../common/RequestStatusFlashbar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { generateAssessmentSnapshotReviewAllUrl } from '../../Utils';
import CreateSnapshotDialog from '../../results/snapshots/CreateSnapshotDialog';
import { updateCreateSnapshotModal } from '../CurrentAssessmentSlice';

const MAX_ASSESSMENT_HEADER_LENGTH = 125;

type AssessmentDetailsStatusBarProps = AppLabelsContextInterface & {
    shouldDisplayTemplate: boolean;
};

const AssessmentDetailsStatusBar: FunctionComponent<AssessmentDetailsStatusBarProps> = ({
    appLabels,
    shouldDisplayTemplate = false,
}): JSX.Element => {
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessment);
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const numberOfNonOptionalPrompts = useAppSelector((state) => state.currentAssessmentState.currentPromptState.numberOfNonOptionalPrompts);
    const numberOfResponses = useAppSelector((state) => state.currentAssessmentState.currentPromptState.numberOfResponses);
    const accountCustomerName = currentAssessment?.accountCustomerName || '';
    const assessmentType = currentAssessment?.template?.title || '';
    const history = useHistory();
    const dispatch = useAppDispatch();
    const radarDisabled = currentAssessment?.template?.defaults?.report?.radar?.disabled;
    const snapshots = useAppSelector((state) => state.currentAssessmentState.currentAssessment?.snapshots);
    const snapshotsMetaData = snapshots?.map((s) => {
        return {
            id: s.id,
            description: s.description,
            createdAt: s.createdAt,
            createdBy: s.createdBy,
        };
    });

    // Template state
    const currentTemplateMetadata: AssessmentTemplateMetadata = useAppSelector((state) => state.currentTemplateState.currentTemplateMetadata);
    const currentTemplatePromptIndex: number = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPromptIndex);
    const currentTemplateViewId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentTemplateViewPromptNumMap: Map<string, number> = useAppSelector(
        (state) => state.currentTemplateState.currentPromptState.viewIdToNumberOfPrompts
    );
    const currentTemplateNumberOfPrompts: number = currentTemplateViewPromptNumMap?.get(currentTemplateViewId);
    const currentTemplateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);

    const displayStatusBarMessage = useCallback(() => {
        if (shouldDisplayTemplate) {
            // + 1 to convert to 1-indexed
            return `${appLabels.assessment.status_bar.current_prompt_number}${currentTemplatePromptIndex + 1}${
                appLabels.assessment.status_bar.of
            }${currentTemplateNumberOfPrompts}`;
        }

        return `${currentAssessmentOrSelectedSnapshot?.description} | ${numberOfResponses} of ${numberOfNonOptionalPrompts} ${appLabels.assessment.status_bar.answered}`;
    }, [
        appLabels.assessment.status_bar,
        currentAssessmentOrSelectedSnapshot?.description,
        numberOfNonOptionalPrompts,
        numberOfResponses,
        shouldDisplayTemplate,
        currentTemplatePromptIndex,
        currentTemplateNumberOfPrompts,
    ]);

    const dropdownItems: SelectProps.Options = useMemo(() => {
        if (shouldDisplayTemplate) {
            return [];
        }

        let assessmentDropdownItems: SelectProps.Options = [
            {
                label: appLabels.assessment.snapshot.add_snapshot,
                value: 'addsnapshot',
                iconSvg: <AwsCatIcon name='add' fill='#123' width='20' height='20' />,
                iconName: undefined,
            },
        ];
        if (!radarDisabled) {
            assessmentDropdownItems = assessmentDropdownItems.concat([
                {
                    label: appLabels.assessment.snapshot.compare_snapshots,
                    value: 'comparesnapshots',
                    iconSvg: <AwsCatIcon name='data usage' width='20' height='20' fill='#123' />,
                    iconName: undefined,
                },
            ]);
        }
        assessmentDropdownItems = assessmentDropdownItems.concat([
            {
                value: assessmentId,
                label: currentAssessment?.description,
                iconSvg: undefined,
                iconName: currentAssessment?.status === AssessmentStatus.COMPLETED ? 'status-positive' : 'status-in-progress',
            },
        ]);

        const snapshotItems = snapshotsMetaData
            ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0))
            ?.map((ss) => {
                return {
                    label: ss.description,
                    description: ss.createdAt ? new Date(ss.createdAt * 1000).toLocaleDateString() : '',
                    value: ss.id ? ss.id : '',
                    iconSvg: <AwsCatIcon name='calender' fill='#fff' width='10' height='10' />,
                    iconName: undefined,
                };
            });

        if (snapshotItems) {
            assessmentDropdownItems = assessmentDropdownItems.concat(snapshotItems);
        }

        return assessmentDropdownItems;
    }, [shouldDisplayTemplate, appLabels.assessment.snapshot, assessmentId, currentAssessment, radarDisabled, snapshotsMetaData]);

    const [selectedOption, setSelectedOption] = useState({
        label: displayStatusBarMessage(),
        value: currentAssessmentOrSelectedSnapshot?.id,
        description: '',
    });

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const statusBadge = useMemo(() => {
        if (shouldDisplayTemplate) {
            if (isTemplatePublished(currentTemplateMetadata)) {
                return <Badge color='green'>{appLabels.manage_templates.template.statuses.published}</Badge>;
            }
            return <Badge color='blue'>{appLabels.manage_templates.template.statuses.draft}</Badge>;
        }

        switch (currentAssessmentOrSelectedSnapshot?.status) {
            case AssessmentStatus.IN_PROGRESS:
                return <Badge color='blue'>{appLabels.assessment.status_bar.in_progress}</Badge>;

            case AssessmentStatus.COMPLETED:
                return <Badge color='green'>{appLabels.assessment.status_bar.completed}</Badge>;
        }
        return undefined;
    }, [
        shouldDisplayTemplate,
        currentTemplateMetadata,
        currentAssessmentOrSelectedSnapshot,
        appLabels.assessment.status_bar,
        appLabels.manage_templates.template.statuses,
    ]);

    useEffect(() => {
        let snapshotDate = '';
        if (currentAssessmentOrSelectedSnapshot?.id !== assessmentId) {
            snapshotDate = currentAssessmentOrSelectedSnapshot?.createdAt
                ? new Date(currentAssessmentOrSelectedSnapshot.createdAt * 1000).toLocaleDateString()
                : '';
        }

        const message: string = displayStatusBarMessage();

        setSelectedOption({
            label: snapshotDate ? `${snapshotDate} ${message}` : message,
            value: currentAssessmentOrSelectedSnapshot?.id,
            description: '',
        });
    }, [
        assessmentId,
        currentAssessmentOrSelectedSnapshot?.id,
        currentAssessmentOrSelectedSnapshot?.createdAt,
        currentAssessmentOrSelectedSnapshot?.description,
        displayStatusBarMessage,
    ]);

    const headingText: string = useMemo(() => {
        if (shouldDisplayTemplate) {
            return `${getDescriptorForLocale(currentTemplateMetadata.descriptors, currentTemplateLocale)?.name} - ${currentTemplateMetadata.version}`;
        }

        return `${truncateTextWithEllipsis(accountCustomerName ?? '', MAX_ASSESSMENT_HEADER_LENGTH)} - ${assessmentType ?? ''}`;
    }, [shouldDisplayTemplate, currentTemplateMetadata, currentTemplateLocale, accountCustomerName, assessmentType]);

    return (
        <hgroup data-testid='assessment-details-status-bar'>
            <Box margin={'xxs'}>
                <SpaceBetween direction='horizontal' size='xs' className='awscat-assessment-progress'>
                    <Box variant='h1'>{headingText}</Box>
                    {statusBadge}
                </SpaceBetween>
            </Box>
            <div style={{ width: '400px' }}>
                <Select
                    selectedOption={selectedOption}
                    options={dropdownItems}
                    onChange={({ detail }) => {
                        if (detail.selectedOption.value === 'addsnapshot') {
                            dispatch(updateCreateSnapshotModal({ showFlag: true }));
                        } else if (detail.selectedOption.value === 'comparesnapshots') {
                            history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/comparesnapshots`);
                        } else if (detail.selectedOption.value === assessmentId) {
                            setSelectedOption({
                                label: detail.selectedOption.label,
                                value: detail.selectedOption.value,
                                description: detail.selectedOption.description,
                            });
                            history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${assessmentId}/reviewall`);
                        } else {
                            setSelectedOption({
                                label: detail.selectedOption.label,
                                value: detail.selectedOption.value,
                                description: detail.selectedOption.description,
                            });
                            history.push(generateAssessmentSnapshotReviewAllUrl(assessmentId, detail.selectedOption.value));
                        }
                    }}
                />
            </div>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <CreateSnapshotDialog requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Box color='text-body-secondary' variant='p'></Box>
        </hgroup>
    );
};

export default withAppLabelsContext(AssessmentDetailsStatusBar);
