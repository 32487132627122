import { getLanguageName } from './Utils';
import enUsLabels from '../resources/en-US/text/labels';
import jaJpLabels from '../resources/ja-JP/text/labels';

/**
 * Language is "English" or "日本語". Locale is "en-US" or "ja-JP"
 */

export const LOCALIZED_APP_LABELS: Record<string, Record<string, string>> = {
    'en-US': enUsLabels,
    'ja-JP': jaJpLabels,
    'fr-FR': enUsLabels,
    'it-IT': enUsLabels,
    'es-ES': enUsLabels,
    'de-DE': enUsLabels,
    'ko-KR': enUsLabels,
};
export const SUPPORTED_LOCALES = Object.keys(LOCALIZED_APP_LABELS);

/**
 * Checks if a locale is supported
 * @param locale the locale to check
 * @returns whether that locale has labels in A2T
 */
export const isLocaleSupported = (locale: string): boolean => SUPPORTED_LOCALES.includes(locale);

// Need to lookup locale --> language and vice versa
const localeToLanguageMap: Record<string, string> = SUPPORTED_LOCALES.reduce((localeToLanguageMap, currentLocale) => {
    // Calling `getLanguageName(currentLocale, currentLocale)` gets the name of the language in that language. E.g. English, 日本語, Deutsch
    localeToLanguageMap[currentLocale] = getLanguageName(currentLocale, currentLocale);
    return localeToLanguageMap;
}, {} as Record<string, string>);
const languageToLocaleMap: Record<string, string> = Object.keys(localeToLanguageMap).reduce((languageToLocaleMap, currentLocale) => {
    languageToLocaleMap[localeToLanguageMap[currentLocale]] = currentLocale;
    return languageToLocaleMap;
}, {} as Record<string, string>);

/**
 * Maps a supported locale to its language name. E.g. "en-US" -> "English"
 * @param locale the locale
 * @returns the language
 */
export const supportedLocaleToLanguage = (locale: string): string => localeToLanguageMap[locale];

/**
 * Maps a supported language to its locale. E.g. "English" -> "en-US"
 * @param language the language
 * @returns the locale
 */
export const supportedLanguageToLocale = (language: string): string => languageToLocaleMap[language];

export const SUPPORTED_LANGUAGES = SUPPORTED_LOCALES.map((locale) => localeToLanguageMap[locale]);
