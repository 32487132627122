import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { FunctionComponent, useEffect, useMemo } from 'react';

import { setAdministrationSectionInAppSideNavigationPanel } from './AppSideNavigationSlice';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const allAdminFeatures: AssessmentFeature[] = [
    AssessmentFeature.ADMIN_CUSTOMER_ACCOUNTS,
    AssessmentFeature.ADMIN_OVERVIEW,
    AssessmentFeature.ADMIN_RECOMMENDED_ACTIONS,
    AssessmentFeature.ADMIN_ORGANIZATION_CUSTOMIZATION,
    AssessmentFeature.ADMIN_TEMPLATE_MANAGEMENT,
];

type AdminSideNavigationSectionProp = AuthContextInterface & AppLabelsContextInterface;
const AdminSideNavigationSection: FunctionComponent<AdminSideNavigationSectionProp> = ({ appLabels }): JSX.Element => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const dispatch = useAppDispatch();
    const adminItems = useMemo((): SideNavigationProps.Item[] => {
        const featureItems = allAdminFeatures.reduce((featureItems, feature) => {
            if (authorizedFeatures.includes(feature)) {
                switch (feature) {
                    case AssessmentFeature.ADMIN_CUSTOMER_ACCOUNTS: {
                        featureItems.push({
                            type: 'link',
                            text: appLabels.side_navigation.administration.customer_accounts,
                            href: '/admin-customer-accounts',
                        });
                        break;
                    }
                    case AssessmentFeature.ADMIN_OVERVIEW: {
                        featureItems.push({
                            type: 'link',
                            text: appLabels.side_navigation.administration.overview,
                            href: '/admin-overview',
                        });
                        break;
                    }
                    case AssessmentFeature.ADMIN_RECOMMENDED_ACTIONS: {
                        featureItems.push({
                            type: 'link',
                            text: appLabels.side_navigation.administration.recommended_actions,
                            href: '/admin-recommended-actions',
                        });
                        break;
                    }
                    case AssessmentFeature.ADMIN_ORGANIZATION_CUSTOMIZATION: {
                        featureItems.push({
                            type: 'link',
                            text: appLabels.side_navigation.administration.report_templates,
                            href: '/admin-report-templates',
                        });
                        break;
                    }
                    case AssessmentFeature.ADMIN_TEMPLATE_MANAGEMENT: {
                        featureItems.push({
                            type: 'link',
                            text: appLabels.side_navigation.administration.manage_templates,
                            href: '/admin-manage-templates',
                        });
                        break;
                    }
                }
            }
            return featureItems;
        }, new Array<SideNavigationProps.Item>());

        if (featureItems?.length === 0) {
            return [];
        }

        const adminItem: SideNavigationProps.Item = {
            type: 'section',
            text: appLabels.side_navigation.administration.administration,
            items: featureItems,
        };
        const separator: SideNavigationProps.Item = { type: 'divider' };
        return [adminItem, separator];
    }, [
        appLabels.side_navigation.administration.administration,
        appLabels.side_navigation.administration.customer_accounts,
        appLabels.side_navigation.administration.overview,
        appLabels.side_navigation.administration.recommended_actions,
        appLabels.side_navigation.administration.report_templates,
        appLabels.side_navigation.administration.manage_templates,
        authorizedFeatures,
    ]);

    useEffect(() => {
        dispatch(setAdministrationSectionInAppSideNavigationPanel(adminItems));
    }, [adminItems, dispatch]);

    return null;
};

export default withAuthContext(withAppLabelsContext(AdminSideNavigationSection));
