import './AssessmentTimelineNav.scss';

import { AssessmentModule } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Box } from '@amzn/awsui-components-react';
import { FunctionComponent, MouseEventHandler, useCallback, useMemo } from 'react';

import { EditableDescriptorKey } from '../../../../api/templateManagement/TemplateManagementClient';
import {
    CurrentTemplateState,
    getSiblingModulesOfModule,
    jumpToModule,
} from '../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { getEditableDescriptor } from '../../../administration/manage-templates/edit-template/TemplateUtils';
import TemplateEditableText from '../../../common/TemplateEditableText';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { jumpToCategoryPrompt } from '../CurrentAssessmentSlice';

interface AssessmentTimelineNavProps {
    shouldDisplayTemplate: boolean;
}

const AssessmentTimelineNav: FunctionComponent<AssessmentTimelineNavProps> = ({ shouldDisplayTemplate = false }): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const currentCategoryName = currentPrompt?.categoryName ?? null;
    const allCategoryNames = currentPrompt?.allCategoryNames ?? null;

    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentViewId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentViewId);
    const currentModuleId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);
    const currentSiblingModules: AssessmentModule[] = useMemo(() => {
        return getSiblingModulesOfModule(currentModuleId);
    }, [currentModuleId]);

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
        (event) => {
            event.preventDefault();
            const categoryNameOrModuleName = event.currentTarget.innerText;

            if (shouldDisplayTemplate) {
                // Match module name with HTML text
                const targetModule = currentSiblingModules.find(
                    (module) => getEditableDescriptor(currentTemplateState, module.descriptors[0].descriptorId)?.name === categoryNameOrModuleName
                );
                // Jump if we're going to a new module
                if (targetModule && targetModule.moduleId !== currentModuleId) {
                    dispatch(jumpToModule({ viewId: currentViewId, moduleId: targetModule.moduleId }));
                }

                return;
            }

            dispatch(jumpToCategoryPrompt(categoryNameOrModuleName));
        },
        [dispatch, shouldDisplayTemplate, currentViewId, currentModuleId, currentSiblingModules, currentTemplateState]
    );

    const trailingPipe = useMemo(
        () => (
            <li>
                &nbsp;<span> | </span>&nbsp;
            </li>
        ),
        []
    );

    const buildAssessmentItems = useCallback((): JSX.Element => {
        return (
            <ul data-testid='assessment-timeline-nav-items'>
                {allCategoryNames?.map((categoryName: string, i: number, { length }) => {
                    const isLastItem = i === length - 1;
                    return (
                        <span>
                            <li className={categoryName === currentCategoryName ? 'active' : 'in-active'}>
                                <a
                                    onClick={(event) => {
                                        categoryName !== currentCategoryName && handleOnClick(event);
                                    }}
                                >
                                    {categoryName}
                                </a>
                            </li>
                            {!isLastItem ? trailingPipe : null}
                        </span>
                    );
                })}
            </ul>
        );
    }, [handleOnClick, currentCategoryName, allCategoryNames, trailingPipe]);

    const buildTemplateItems = useCallback((): JSX.Element => {
        return (
            <ul data-testid='assessment-timeline-nav-items'>
                {currentSiblingModules?.map((module: AssessmentModule, index: number, { length }) => {
                    const isLastItem = index === length - 1;
                    const isCurrentModule = module.moduleId === currentModuleId;
                    const moduleDescriptor = getEditableDescriptor(currentTemplateState, module.descriptors[0].descriptorId);
                    const moduleName = moduleDescriptor?.name || '';
                    return (
                        <span>
                            <li className={isCurrentModule ? 'active' : 'in-active'}>
                                <a
                                    onClick={(event) => {
                                        handleOnClick(event);
                                    }}
                                    data-testid={`timeline-item-${index}`}
                                >
                                    {/* Make the current module name editable */}
                                    {isCurrentModule ? (
                                        <TemplateEditableText descriptor={moduleDescriptor} keyToEdit={EditableDescriptorKey.Name} numRows={1}>
                                            {moduleName}
                                        </TemplateEditableText>
                                    ) : (
                                        moduleName
                                    )}
                                </a>
                            </li>
                            {!isLastItem ? trailingPipe : null}
                        </span>
                    );
                })}
            </ul>
        );
    }, [trailingPipe, currentSiblingModules, currentModuleId, handleOnClick, currentTemplateState]);

    const buildItems = useCallback((): JSX.Element => {
        if (shouldDisplayTemplate) {
            return buildTemplateItems();
        } else {
            return buildAssessmentItems();
        }
    }, [shouldDisplayTemplate, buildTemplateItems, buildAssessmentItems]);

    return (
        <nav className='assessment-timeline-nav' data-testid='assessment-timeline-nav'>
            <Box padding={'l'}>{buildItems()}</Box>
        </nav>
    );
};

export default AssessmentTimelineNav;
