import { FC } from 'react';

import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { PrescribedAction } from '../../assessments/results/model/Assessment';
import ActionConfirmationDialog from '../../common/ActionConfirmationDialog';

type DeletePrescribedActionConfirmationDialogProps = {
    prescribedAction: PrescribedAction;
    confirmAction: () => Promise<any>;
    cancelAction: () => void;
} & AppLabelsContextInterface;

const DeletePrescribedActionConfirmationDialog: FC<DeletePrescribedActionConfirmationDialogProps> = ({
    appLabels,
    prescribedAction,
    confirmAction,
    cancelAction,
}): JSX.Element => {
    if (!prescribedAction) {
        return null;
    }

    return (
        <ActionConfirmationDialog
            header={appLabels.prescribed_actions_customization.confirm_delete_customized_prescribed_action}
            message={prescribedAction.text}
            actionLabel={appLabels.user_actions.delete}
            action={confirmAction}
            cancelAction={cancelAction}
        />
    );
};

export default withAppLabelsContext(DeletePrescribedActionConfirmationDialog);
