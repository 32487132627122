import { ASSESSMENT_TYPE_ACRONYM_REGEX, IsAcronymAvailableInput } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Alert, Button, Container, FormField, Header, Input, SpaceBetween, StatusIndicator, Textarea } from '@amzn/awsui-components-react';
import { useLazyQuery } from '@apollo/client';
import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateTemplateDetails } from './CreateTemplateSlice';
import templateManagementClient from '../../../../api/templateManagement/TemplateManagementClient';
import { IS_ACRONYM_AVAILABLE } from '../../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import rumClient from '../../../../common/monitoring/RumClient';
import { useAppSelector } from '../../../redux/hooks';

const TEMPLATE_NAME_MAX_CHARACTERS = 50;
const TEMPLATE_DESCRIPTION_MAX_CHARACTERS = 200;
const TEMPLATE_ACRONYM_MAX_CHARACTERS = 10;

type EnterTemplateDetailsPanelProps = AppLabelsContextInterface;

const EnterTemplateDetailsPanel: FunctionComponent<EnterTemplateDetailsPanelProps> = ({ appLabels }): JSX.Element => {
    const dispatch = useDispatch();
    const [acronym, setAcronym] = useState('');
    const [isAcronymInvalid, setIsAcronymInvalid] = useState(false);
    const [isAcronymAvailable, setIsAcronymAvailable] = useState<boolean>(true);

    const [isAcronymAvailableQuery, { loading: loadingIsAcronymAvailable }] = useLazyQuery(IS_ACRONYM_AVAILABLE, {
        client: templateManagementClient,
        onError: (error) => {
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            setIsAcronymAvailable(data.isAcronymAvailable);
        },
    });

    const onCheckAcronymAvailability = useCallback(() => {
        if (!isAcronymInvalid) {
            setIsAcronymAvailable(null);

            const input: IsAcronymAvailableInput = {
                acronym,
            };
            isAcronymAvailableQuery({ variables: { input } });
        }
    }, [acronym, isAcronymAvailableQuery, isAcronymInvalid]);

    const initialTemplateDetailsState = useAppSelector((state) => state.createTemplateState);

    const [templateName, setTemplateName] = useState<string>(initialTemplateDetailsState.templateName);
    const [templateDescription, setTemplateDescription] = useState<string>(initialTemplateDetailsState.templateDescription);
    const [templateAcronym, setTemplateAcronym] = useState<string>(initialTemplateDetailsState.templateAcronym);

    const [templateNameErrorText, setTemplateNameErrorText] = useState<string>('');
    const [templateDescriptionErrorText, setTemplateDescriptionErrorText] = useState<string>('');
    const [templateAcronymErrorText, setTemplateAcronymErrorText] = useState<string>('');

    // Each field has its own onChange handler. This enables:
    // 1. Instant validation feedback
    // 2. Field-specific logic, like "unable to change acronym in future edits"
    // It has the downside of being verbose and not reusable
    const onTemplateNameChange = useCallback(
        (value: string) => {
            setTemplateNameErrorText('');
            if (!value?.length) {
                setTemplateNameErrorText(appLabels.manage_templates.wizard.enter_details.cannot_be_empty);
            } else if (value?.length > TEMPLATE_NAME_MAX_CHARACTERS) {
                setTemplateNameErrorText(appLabels.manage_templates.wizard.enter_details.template_name_error_text);
            }
            setTemplateName(value);
            dispatch(updateTemplateDetails({ templateName: value }));
        },
        [setTemplateNameErrorText, appLabels.manage_templates.wizard.enter_details, dispatch]
    );

    const onTemplateDescriptionChange = useCallback(
        (value: string) => {
            setTemplateDescriptionErrorText('');
            if (!value?.length) {
                setTemplateDescriptionErrorText(appLabels.manage_templates.wizard.enter_details.cannot_be_empty);
            } else if (value?.length > TEMPLATE_DESCRIPTION_MAX_CHARACTERS) {
                setTemplateDescriptionErrorText(appLabels.manage_templates.wizard.enter_details.template_description_error_text);
            }
            setTemplateDescription(value);
            dispatch(updateTemplateDetails({ templateDescription: value }));
        },
        [setTemplateDescriptionErrorText, appLabels.manage_templates.wizard.enter_details, dispatch]
    );

    const onTemplateAcronymChange = useCallback(
        (value: string) => {
            setTemplateAcronymErrorText('');
            if (!value?.length) {
                setTemplateAcronymErrorText(appLabels.manage_templates.wizard.enter_details.cannot_be_empty);
            } else if (value?.length > TEMPLATE_ACRONYM_MAX_CHARACTERS) {
                setTemplateAcronymErrorText(appLabels.manage_templates.wizard.enter_details.template_acronym_error_text_too_long);
            }
            // Ensure is alphanumeric
            else if (value && !value.match(/^[a-zA-Z0-9]+$/)) {
                setTemplateAcronymErrorText(appLabels.manage_templates.wizard.enter_details.template_acronym_error_text_not_alphanumeric);
            }
            setTemplateAcronym(value);
            // Reset availability indicator
            setIsAcronymAvailable(true);
            setAcronym(value);

            // Check if input is valid
            if (!ASSESSMENT_TYPE_ACRONYM_REGEX.test(value)) {
                setIsAcronymInvalid(true);
            } else {
                setIsAcronymInvalid(false);
            }
            dispatch(updateTemplateDetails({ templateAcronym: value }));
        },
        [setTemplateAcronymErrorText, appLabels.manage_templates.wizard.enter_details, dispatch]
    );

    const acronymAlreadyExistAlert = useCallback(() => {
        return !isAcronymAvailable ? (
            <Alert type='info' data-testid='template-already-exist-alert'>
                {appLabels.manage_templates.wizard.enter_details.template_acronym_text_already_exist}
            </Alert>
        ) : null;
    }, [isAcronymAvailable, appLabels.manage_templates.wizard.enter_details.template_acronym_text_already_exist]);

    return (
        <Container header={<Header variant='h2'>{appLabels.manage_templates.wizard.enter_details.details}</Header>}>
            <SpaceBetween size='l'>
                <FormField
                    label={`${appLabels.manage_templates.wizard.enter_details.template_name}*`}
                    constraintText={appLabels.manage_templates.wizard.enter_details.template_name_constraint_text}
                    errorText={templateNameErrorText}
                >
                    <Input
                        placeholder={appLabels.manage_templates.wizard.enter_details.template_name_placeholder}
                        value={templateName}
                        onChange={({ detail: { value } }) => onTemplateNameChange(value)}
                        ariaRequired={true}
                        autoFocus={true}
                        data-testid='enter-template-details-panel-name'
                    />
                </FormField>
                <FormField
                    label={`${appLabels.manage_templates.wizard.enter_details.template_description}*`}
                    constraintText={appLabels.manage_templates.wizard.enter_details.template_description_constraint_text}
                    errorText={templateDescriptionErrorText}
                >
                    <Textarea
                        placeholder={appLabels.manage_templates.wizard.enter_details.template_description_placeholder}
                        value={templateDescription}
                        onChange={({ detail: { value } }) => onTemplateDescriptionChange(value)}
                        ariaRequired={true}
                        data-testid='enter-template-details-panel-description'
                    />
                </FormField>
                <FormField
                    label={`${appLabels.manage_templates.wizard.enter_details.template_acronym}*`}
                    constraintText={appLabels.manage_templates.wizard.enter_details.template_acronym_constraint_text}
                    errorText={templateAcronymErrorText}
                >
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Input
                            placeholder={appLabels.manage_templates.wizard.enter_details.template_acronym_placeholder}
                            value={templateAcronym}
                            onChange={({ detail: { value } }) => onTemplateAcronymChange(value)}
                            ariaRequired={true}
                            data-testid='enter-template-details-panel-acronym'
                        />
                        <Button
                            data-testid='btn-check-acronym-availability'
                            onClick={onCheckAcronymAvailability}
                            disabled={isAcronymInvalid === true || isAcronymInvalid === null}
                            loading={loadingIsAcronymAvailable}
                        >
                            {appLabels.user_actions.check_availability}
                        </Button>
                        {isAcronymAvailable !== null && (
                            <StatusIndicator type={isAcronymAvailable ? 'success' : 'error'}>
                                {isAcronymAvailable
                                    ? appLabels.manage_templates.create_assessment_type.available
                                    : appLabels.manage_templates.create_assessment_type.not_available}
                            </StatusIndicator>
                        )}
                    </SpaceBetween>
                    {acronymAlreadyExistAlert()}
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default withAppLabelsContext(EnterTemplateDetailsPanel);
