import './UpdateStatusIndicator.scss';

import { Alert, Box, Button, Icon } from '@amzn/awsui-components-react';
import { FunctionComponent } from 'react';

export type TryAgainAction = () => void;

type UpdateStatusIndicatorProps = {
    loading: boolean;
    loadingText: string;
    updateConfirmationText: string | null;
    errorMessageSummary: string | null;
    errorMessageDetail: string | null;
    tryAgainText: string | null;
    tryAgainAction: TryAgainAction;
};

const UpdateStatusIndicator: FunctionComponent<UpdateStatusIndicatorProps> = ({
    loading,
    loadingText,
    updateConfirmationText,
    errorMessageSummary,
    errorMessageDetail,
    tryAgainText,
    tryAgainAction,
}): JSX.Element | null => {
    const loadingIndicator = (
        <Button loading={true} variant='link'>
            {loadingText}
        </Button>
    );
    const successfulIndicator = (
        <span>
            <Icon name='status-positive' size='normal' variant='success' />
            <span className='indicator-text'>{updateConfirmationText}</span>
        </span>
    );
    const errorIndicator = (
        <Alert type='error' visible={true} action={<Button onClick={tryAgainAction}>{tryAgainText ?? ''}</Button>} header={errorMessageSummary ?? ''}>
            {errorMessageDetail ?? ''}
        </Alert>
    );
    return (
        <Box textAlign='center'>
            {loading ? loadingIndicator : null}
            {!loading && !!updateConfirmationText ? successfulIndicator : null}
            {!loading && !!errorMessageSummary ? errorIndicator : null}
        </Box>
    );
};

export default UpdateStatusIndicator;
