import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';

import { GetEnvironmentConfig } from '../../Environments';
import { getJwtToken } from '../../common/auth/Authentication';
import rumClient from '../../common/monitoring/RumClient';

const envConfig = GetEnvironmentConfig();
const region = 'us-west-2';

const myAppConfig = {
    graphqlEndpoint: envConfig.a2sApiEndpoint,
    region,
    authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as 'AMAZON_COGNITO_USER_POOLS' | 'OPENID_CONNECT',
};

const url = myAppConfig.graphqlEndpoint;
const authOptions = {
    type: myAppConfig.authenticationType,
    jwtToken: async () => await getJwtToken(),
};

// https://www.apollographql.com/docs/react/data/error-handling#on-graphql-errors
const retryOnAuthErrorLink: ApolloLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        // If unauthenticated, retry the request. This will forward the request back to the AuthLink, which will refresh token
        // However, infinite retry loop won't occur. From docs: "If your retried operation also results in errors, those errors
        // are not passed to your onError link to prevent an infinite loop of operations"
        for (const err of graphQLErrors) {
            if (err.message.includes('401')) {
                rumClient.recordError('Token expired in transit. Re-authorizing and retry the request');
                // Retry the request
                return forward(operation);
            }
        }
    }

    // Intentional lack of return value. Indicates to Apollo that it shouldn't retry
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return;
});

// Set up the Apollo link. When the user gets a response from the HttpLink, the response will first reach the retryOnAuthErrorLink.
// More about ApolloLink: https://www.apollographql.com/docs/react/api/link/introduction/
const link = ApolloLink.from([retryOnAuthErrorLink, createAuthLink({ url, region, auth: authOptions }), createHttpLink({ uri: url })]);
const a2sApolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
export default a2sApolloClient;
