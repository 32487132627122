import { Alert, AlertProps, Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useState } from 'react';

import rumClient from '../../common/monitoring/RumClient';

export interface ActionConfirmationDialogProps {
    header: string;
    message: string;
    messageAlertType?: AlertProps.Type;
    frictionPrompt?: string;
    frictionExpected?: string;
    actionLabel: string;
    action: () => Promise<any>;
    errorAlertType?: AlertProps.Type;
    errorAlertHeader?: string;
    cancelLabel?: string;
    cancelAction?: () => void;
    closeLabel?: string;
}

const ActionConfirmationDialog: FunctionComponent<ActionConfirmationDialogProps> = (props): JSX.Element => {
    interface ComponentState {
        visible: boolean;
        actionConfirmed: boolean;
        frictionInputText: string;
        actionInProgress: boolean;
        errorAlertVisible: boolean;
        errorMessage: string;
    }

    const [state, setState] = useState<ComponentState>({
        visible: true,
        actionConfirmed: props.frictionExpected ? false : true,
        frictionInputText: '',
        actionInProgress: false,
        errorAlertVisible: false,
        errorMessage: '',
    });

    const actionFriction = () => {
        if (props.frictionPrompt && props.frictionExpected) {
            return (
                <Box margin={{ top: 'xxs' }}>
                    <FormField label={props.frictionPrompt} stretch={true}>
                        <Input
                            placeholder={props.frictionExpected}
                            onChange={(event) => {
                                const value = event.detail.value;
                                const isInputValid = (typed: string) => typed.toUpperCase() === props.frictionExpected?.toUpperCase();
                                isInputValid(value)
                                    ? setState({ ...state, actionConfirmed: true, frictionInputText: value })
                                    : setState({ ...state, actionConfirmed: false, frictionInputText: value });
                            }}
                            value={state.frictionInputText}
                            ariaRequired={true}
                        />
                    </FormField>
                </Box>
            );
        }
        return null;
    };

    const performAction = async () => {
        try {
            setState({ ...state, actionInProgress: true, errorAlertVisible: false });
            await props.action();
            setState({ ...state, actionInProgress: false, errorAlertVisible: false });
        } catch (e) {
            rumClient.recordError(e);
            setState({ ...state, actionInProgress: false, errorAlertVisible: true, errorMessage: `${e}` });
        }
    };

    const cancelAction = () => {
        setState({ ...state, visible: false });
        props.cancelAction && props.cancelAction();
    };

    return (
        <Modal
            visible={state.visible}
            header={props.header}
            closeAriaLabel={props.closeLabel || 'Close'}
            onDismiss={() => {
                cancelAction();
            }}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button data-testid='btn-cancel' variant='link' onClick={() => cancelAction()}>
                            {props.cancelLabel || 'Cancel'}
                        </Button>
                        <Button
                            data-testid='btn-action'
                            variant='primary'
                            loading={state.actionInProgress}
                            onClick={() => performAction()}
                            disabled={!state.actionConfirmed}
                        >
                            {props.actionLabel}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <ColumnLayout borders='horizontal'>
                {props.messageAlertType ? (
                    <Alert data-testid='alert-message' visible type={props.messageAlertType ?? 'info'}>
                        {props.message}
                    </Alert>
                ) : (
                    <div data-testid='alert-message'>
                        <Box variant='p'>{props.message}</Box>
                    </div>
                )}
                {actionFriction()}
                {state.errorAlertVisible ? (
                    <Alert
                        data-testid='alert-error-message'
                        onDismiss={() => setState({ ...state, errorAlertVisible: false })}
                        visible={state.errorAlertVisible}
                        dismissAriaLabel={props.closeLabel || 'Close'}
                        dismissible
                        type={props.errorAlertType ?? 'error'}
                        header={props.errorAlertHeader || 'Error'}
                    >
                        {state.errorMessage}
                    </Alert>
                ) : null}
            </ColumnLayout>
            {props.children}
        </Modal>
    );
};

export default ActionConfirmationDialog;
