import { AssessmentResponseBoolean } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { UserInfo } from '@amzn/awscat-portal-authentication-library';
import { DateTime } from 'luxon';

import { AppLabels } from './AppLabels';
import Constants from './Constants';
import { getProvider } from './auth/Authentication';
import { getAllParticipantsContent } from '../api/wbs/WbsClient';
import { AnalysisDoc } from '../api/wbs/WbsTypes';
import { Comment } from '../components/assessments/facilitate/body/CommentBox';
import { CustomerViewModel } from '../components/customers/CustomerViewModel';

export const displayDateString = (date?: Date): string => {
    return date?.toDateString().slice(4) ?? '';
};

export const displayUSDateString = (date?: string): string => {
    return date ? new Date(date).toDateString().slice(4) : '';
};

export const epochToDate = (epochInSec: number | null): Date | undefined => {
    return epochInSec ? new Date(epochInSec * 1000) : undefined;
};

export const truncateTextWithEllipsis = (text: string, maxLength: number): string => {
    return text.length <= maxLength ? text : text.substring(0, maxLength - 3) + '...';
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}
export const customerNameCompare = (a: CustomerViewModel, b: CustomerViewModel): number => {
    if (!b.accountName) {
        // sort a before null b
        return -1;
    } else if (!a.accountName) {
        // keep same a, b order
        return 0;
    }
    // alpha sort
    return a.accountName.localeCompare(b.accountName);
};

export const deduplicateArray = <Type>(arr: Type[], equalityFunc: (e1: Type, e2: Type) => boolean = (e1, e2) => e1 === e2): Type[] => {
    const uniqueArr: Type[] = [];
    for (const element of arr) {
        const existing = uniqueArr.find((e) => equalityFunc(e, element));
        if (existing === undefined) {
            uniqueArr.push(element);
        }
    }
    return uniqueArr;
};

export const mean = (arr: number[]): number => {
    const len = arr.length;
    if (len === 0) {
        return 0;
    }
    return arr.reduce((s, n) => s + n, 0) / len;
};

/**
 * Clamp input between min and max
 * @param min
 * @param max
 * @param input
 * @returns
 */
export const clamp = (min: number, max: number, input: number): number => {
    if (min > max) {
        throw new Error(`Clamp min may not be greater than max. min (${min}) > max (${max})`);
    }
    return Math.max(Math.min(input, max), min);
};

export const isoDateToLocaleDate = (inputDate: string): string => {
    const date = DateTime.fromISO(inputDate);
    if (!date.isValid) {
        throw new Error('Invalid date format from date picker');
    } else {
        return date.toFormat('MM/dd/yyyy');
    }
};

export const generatePercentageString = (share: number, total: number): string => {
    if (!total) {
        return '';
    }
    const percent = ((share / total) * 100).toFixed(0);
    return `${percent}%`;
};

export const isAllCustomerSelected = (selectedCustomer: string | null | undefined): boolean => {
    return !selectedCustomer || selectedCustomer === Constants.ALL_CUSTOMERS;
};

export const isCustomerSelected = (selectedCustomer: string, customer: string): boolean => {
    if (!isAllCustomerSelected(selectedCustomer)) {
        return customer.toLocaleLowerCase().trim() === selectedCustomer.toLocaleLowerCase().trim();
    }
    return true;
};

export const isApnUser = (userId: string): boolean => {
    // If an ID ends in "orgId", then it's a partner org
    if (userId?.endsWith(Constants.ORG_SUFFIX)) {
        return false;
    }

    return userId ? userId.startsWith(Constants.APN_USER_PREFIX) : false;
};

export const isAmazonUser = (userId: string): boolean => {
    return userId ? userId.startsWith(Constants.AMAZON_CORPORATE_USER_PREFIX) : false;
};

/**
 * User principals always begin with `catportal-`. Checks if the provided principal is a user,
 * and adds `catportal-` if so. If not, returns the principal ID as is
 * @param principalId the principal ID to modify
 * @returns the modified principal ID
 */
export const addProviderIfAmazonOrPartner = (principalId: string): string => {
    if (isAmazonUser(principalId) || isApnUser(principalId)) {
        return `${getProvider()}-${principalId}`;
    }

    // Likely a POSIX group. Doesn't have `catportal-` as the prefix
    return principalId;
};

/**
 * Removes `catportal-` from the principal ID if present. Useful for displaying user IDs
 * @param principalId the principal ID to modify
 * @returns the principal ID without `catportal-`
 */
export const removeProviderFromPrincipalId = (principalId: string): string => {
    return principalId.replace(`${getProvider()}-`, '');
};

export const getUserIdFromAmazonUserEmail = (userEmail: string): string | null => {
    if (!userEmail) {
        return null;
    }
    const userEmailParts = userEmail.split('@');
    if (userEmailParts?.length === 2) {
        const userAlias = userEmailParts[0];
        const emailDomain = userEmailParts[1];
        // https://merlin.corp.amazon.com/questions/140486#547752
        const AMAZON_USERNAME_PATTERN = '^[a-z]{0,64}$'; // 0-64 lower case. Treat empty username as valid
        if (emailDomain.startsWith('amazon.') && userAlias.match(AMAZON_USERNAME_PATTERN)) {
            return `${Constants.AMAZON_CORPORATE_USER_PREFIX}${userAlias}`;
        }
    }
    return null;
};

export const extractAmazonAliasFromUserId = (userId: string): string => {
    return userId ? userId.replace(Constants.AMAZON_CORPORATE_USER_PREFIX, '') : '';
};

export const extractAmazonEmailFromUserId = (userId: string): string => {
    if (isAmazonUser(userId)) {
        return `${extractAmazonAliasFromUserId(userId)}@amazon.com`;
    }
    return userId;
};

/**
 * To display live session comments in the assessment facilitator view
 * @param eventType event type: e.g. live event/pre event
 * @param responseAnalysisDoc analysis doc for a solicitId
 * @returns list of comments
 */
export const transformWbsAnalysisDocToComments = (eventType: string, responseAnalysisDoc: AnalysisDoc): Comment[] => {
    const participantComments = getAllParticipantsContent(responseAnalysisDoc).map((c) => {
        const authorName = c.username ?? '';
        const authorRole = c.role ?? '';
        return {
            content: c.value ?? '',
            author: c.role ? `${authorName} (${authorRole})` : authorName,
            date: displayUSDateString(c.updatedAt),
            eventType,
        };
    });
    return participantComments;
};

export const dateISOToUSFormat = (isoDateString: string): string | null => {
    // DateTime.fromISO('2022-05-30')
    const date = DateTime.fromISO(isoDateString);
    if (date.invalidReason) {
        return null;
    }
    return date.toFormat('MM/dd/yyyy');
};

export const getResponseYesNoLabel = (yesNoResponse: AssessmentResponseBoolean): string => {
    if (!yesNoResponse || !notEmpty(yesNoResponse.booleanValue)) {
        return '';
    }
    return yesNoResponse.booleanValue ? 'Yes' : 'No';
};

export const getResponseYesNoLabelLocalized = (yesNoResponse: AssessmentResponseBoolean, appLabels: AppLabels): string => {
    if (!yesNoResponse || !notEmpty(yesNoResponse.booleanValue) || !appLabels) {
        return '';
    }
    return yesNoResponse.booleanValue ? appLabels.common.yes : appLabels.common.no;
};

export const isAWSUser = (userinfo: UserInfo | undefined | null): boolean => {
    if (!userinfo) {
        throw new Error('isAWSUser() - invalid userinfo');
    }
    return userinfo?.organizationId === Constants.AWS_ORG_ID;
};
/**
 * Returns appId used for Feedback Service
 * @returns string appId
 */
export const getAppId = (): string => {
    return 'a2t';
};

/**
 * Format the input number to be `numDigits` digits after decimal point. Default is 2
 * @param input the number to format
 * @returns the number with `numDigits` digits after the decimal point
 */
export const toFixedDigits = (input: number, numDigits = 2): string => {
    return input?.toFixed(numDigits) || '';
};

/**
 * Displays the language name of `localeOfLanguageName` in `uiLocale`.
 *
 * @example getLanguageName('en-US', 'de-DE') => 'Englisch'
 * @param localeOfLanguageName locale whose name should be retrieved (e.g. en-US)
 * @param uiLocale locale that the user has their client/browser in
 * @returns the name of the language, in `uiLocale`
 */
export const getLanguageName = (localeOfLanguageName: string, uiLocale: string): string => {
    // https://stackoverflow.com/questions/25131388/how-do-you-convert-a-language-code-to-the-language-name-in-javascript
    const languageNames = new Intl.DisplayNames([uiLocale], {
        type: 'language',
    });

    // `'en-US'` would return "American English". Bit awkward. So just do `'en'`, which will return simply "English"
    return languageNames.of(localeOfLanguageName.substring(0, localeOfLanguageName.indexOf('-')));
};
